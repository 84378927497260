<div [ngClass]="{ inactive: isDeactivated }" data-testid="metricTableDetails">
  <div class="table-title">
    <span class="heading">{{ metricTableGroup.title }}</span>
  </div>

  <div class="table-container">
    <table data-testid="metricTable" class="metric-table mat-elevation-z1">
      <thead>
        <tr class="metric-table-row">
          <th class="sticky-column header">
            <div class="grid-column">
              <p class="fx-row align-center" *ngFor="let value of contextHeaderValues">{{ value.label }}</p>
            </div>
          </th>
          <th class="metric-table-row--header-cell" *ngFor="let value of inputHeaderValues">
            {{ value.label }} {{ value.type_details?.units | unitSymbol: true }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let group of tableDataSource" class="metric-table-row">
          <td class="sticky-column">
            <div class="grid-column context">
              <p *ngFor="let contextVD of group.value_definitions | filter: filterLabelTypeValue">
                {{ contextVD.type_details.value }}
              </p>
            </div>
          </td>
          <td
            *ngFor="let inputVD of group.value_definitions | filter: filterValuesExceptLabelType"
            class="metric-table-row--cell cell-container"
          >
            <div class="input-column-field">{{ " " }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
