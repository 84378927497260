import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormControlOptions,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  FiscalYear,
  Frequency,
  UpdatedValueDefinitionFrequencyRequest,
  ValueDefinitionFrequency,
  YearToDateCalculation,
} from '../../../../models';
import { DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface FrequencyModel {
  frequency: FrequencyFormControl;
}

interface MetricStructureFieldFrequencyFormModel {
  yearToDateCalculation?: FormControl<YearToDateCalculation | null>;
  frequencies: FormArray<FormGroup<FrequencyModel>>;
}

class FrequencyFormControl extends FormControl {
  private _fiscalYear: FiscalYear;
  private _valueDefinitionFrequency: ValueDefinitionFrequency;

  constructor(
    valueDefinitionFrequency: ValueDefinitionFrequency,
    fiscalYear: FiscalYear,
    disable: boolean,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super({ value: valueDefinitionFrequency.frequency, disabled: disable }, validatorOrOpts, asyncValidator);

    this._fiscalYear = fiscalYear;
    this._valueDefinitionFrequency = valueDefinitionFrequency;
  }

  public get frequencyCode(): string {
    return String(this._fiscalYear.year);
  }

  public get valueDefinitionFrequencyId(): string {
    return this._valueDefinitionFrequency.id;
  }
}

export class FrequencyForm extends FormGroup<FrequencyModel> {
  constructor(
    valueDefinitionFrequency: ValueDefinitionFrequency,
    fiscalYears: FiscalYear[],
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    const fiscalYear = fiscalYears.find((fiscalYear) => fiscalYear.id === valueDefinitionFrequency.fiscal_year_id);

    if (!fiscalYear) {
      // This should theoretically never happen, but if it does, will know what is the issue.
      throw Error(`Value definition frequency: "${valueDefinitionFrequency.id}" has no matching fiscal year.`);
    }

    super({
      frequency: new FrequencyFormControl(valueDefinitionFrequency, fiscalYear, !!valueDefinitionFrequency.has_values, [
        Validators.required,
      ]),
    });
  }
}

export class MetricStructureFieldFrequencyForm extends FormGroup<MetricStructureFieldFrequencyFormModel> {
  constructor(
    readonly valueDefinitionFrequencies: ValueDefinitionFrequency[],
    readonly fiscalYears: FiscalYear[],
    readonly destroyRef: DestroyRef,
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      frequencies: fb.array<FrequencyForm>(
        valueDefinitionFrequencies.map(
          (valueDefinitionFrequency: ValueDefinitionFrequency) =>
            new FrequencyForm(valueDefinitionFrequency, fiscalYears),
        ),
        MetricStructureFieldFrequencyForm.validateFrequencyInputs,
      ),
    });

    if (this.hasHigherFrequencyThanYearly()) {
      this.addYearToDateCalculationControl();
    }

    this.controls.frequencies.controls.forEach((formGroupFrequency: FormGroup<FrequencyModel>) => {
      formGroupFrequency.controls.frequency.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => {
        if (this.hasHigherFrequencyThanYearly()) {
          this.addYearToDateCalculationControl();
        } else {
          this.removeControl('yearToDateCalculation');
        }
      });
    });
  }

  public toModel(): UpdatedValueDefinitionFrequencyRequest[] {
    return this.controls.frequencies.controls.map(
      (formGroup: FormGroup<FrequencyModel>): UpdatedValueDefinitionFrequencyRequest => ({
        frequency: formGroup.controls.frequency.value,
        id: formGroup.controls.frequency.valueDefinitionFrequencyId,
        year_to_date_calculation: this.controls.yearToDateCalculation?.value ?? null,
      }),
    );
  }

  public disableYearToDateCalculationControlIfSet(): void {
    if (this.controls.yearToDateCalculation?.value) {
      this.controls.yearToDateCalculation.disable();
    }
  }

  public get sortedAscFrequencyControls() {
    return this.controls.frequencies.controls.sort(
      (vdf1, vdf2) => Number(vdf2.controls.frequency.frequencyCode) - Number(vdf1.controls.frequency.frequencyCode),
    );
  }

  private addYearToDateCalculationControl(): void {
    this.addControl(
      'yearToDateCalculation',
      this.fb.control(this.yearToDateCalculation, {
        validators: [Validators.required],
      }),
    );
    if (this.yearToDateCalculation) {
      this.controls.yearToDateCalculation?.disable();
    }
  }

  private hasHigherFrequencyThanYearly(): boolean {
    return this.controls.frequencies.controls.some(
      (frequencyModelFromGroup: FormGroup<FrequencyModel>) =>
        frequencyModelFromGroup.controls.frequency.value !== Frequency.YEARLY,
    );
  }

  private get yearToDateCalculation(): YearToDateCalculation | null {
    // Since all VDF of a given VD share all the same YTDC, we can pick to first one for reference.
    return this.valueDefinitionFrequencies[0].year_to_date_calculation ?? null;
  }

  private static validateFrequencyInputs(control: AbstractControl): ValidationErrors | null {
    // This validation runs through the list of frequency assuming it is sorted by fiscal year descending
    // When validating from newest frequency to oldest frequency, the frequency cannot increase in granularity
    // e.g. if the frequency for 2024 is Yearly, 2023 cannot be Monthly nor Quarterly

    const frequencyToNum = {
      [Frequency.YEARLY]: 1,
      [Frequency.QUARTERLY]: 2,
      [Frequency.MONTHLY]: 3,
    };
    let lastYearFrequency: Frequency = control.value[0].frequency;

    for (const frequencyValue of control.value) {
      if (frequencyToNum[frequencyValue.frequency as Frequency] > frequencyToNum[lastYearFrequency]) {
        return { invalidFrequencyState: true };
      }
      lastYearFrequency = frequencyValue.frequency;
    }
    return null;
  }

  public get isInvalidFrequencyState(): boolean {
    return this.controls.frequencies.errors?.['invalidFrequencyState'];
  }
}
