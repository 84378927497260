import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  ActionItem,
  BaseTemplateReport,
  EmptyResults,
  Metric,
  MoveDirection,
  Permission,
  Status,
  TemplateReportSection,
  TemplateReportSectionMetric,
  TemplateReportSectionNode,
} from '../../models';
import { TranslateService } from '../../services/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { ConfirmationDialogComponent, DialogsService } from '../../dialogs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { TemplateReportsApiService } from '../../services/api-services';
import { TemplateReportStructureStateService } from '../services/template-report-structure-state.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'lib-template-report-structure-sections',
  templateUrl: './template-report-structure-sections.component.html',
  styleUrls: ['./template-report-structure-sections.component.scss'],
})
export class TemplateReportStructureSectionsComponent {
  @Input() managePermissions: Permission[] = [];
  @Input() sections: TemplateReportSectionNode[] = [];
  @Input() templateReport?: BaseTemplateReport;
  @Input() templateReportId?: string;
  @Input() templateReportVersionId?: string | null;

  @Output() editSection: EventEmitter<TemplateReportSection> = new EventEmitter<TemplateReportSection>();
  @Output() addSubSection: EventEmitter<TemplateReportSection> = new EventEmitter<TemplateReportSection>();
  @Output() deleteSection: EventEmitter<TemplateReportSection> = new EventEmitter<TemplateReportSection>();
  @Output() createMetric: EventEmitter<TemplateReportSectionMetric> = new EventEmitter<TemplateReportSectionMetric>();
  @Output() editMetric: EventEmitter<TemplateReportSectionMetric> = new EventEmitter<TemplateReportSectionMetric>();
  @Output() previewMetric: EventEmitter<Metric> = new EventEmitter<Metric>();

  @ViewChild('sectionTrigger') sectionMenu?: MatMenuTrigger;

  public readonly noMetrics: EmptyResults = {
    title: '',
    subtitle: this.translateService.instant('Start by dragging a metric over here'),
    image: 'laptop-neutral',
  };

  public menuPosition = { x: '0px', y: '0px' };

  constructor(
    private readonly translateService: TranslateService,
    private readonly dialogsService: DialogsService,
    private readonly templateReportsApiService: TemplateReportsApiService,
    private readonly templateReportStructureStateService: TemplateReportStructureStateService,
  ) {}

  public selectSection(section: TemplateReportSectionNode) {
    section.isExpanded = !section.isExpanded;
  }

  public onSectionContextMenu(
    event: MouseEvent,
    item: TemplateReportSectionNode | Metric,
    isFirst: boolean,
    isLast: boolean,
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.menuPosition.x = `${event.clientX}px`;
    this.menuPosition.y = `${event.clientY}px`;
    if (this.sectionMenu) {
      this.sectionMenu.menuData = { item, isFirst, isLast };
      this.sectionMenu.menu?.focusFirstItem('mouse');
      this.sectionMenu.openMenu();
    }
  }

  public onDeleteSection(section: TemplateReportSectionNode) {
    this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Delete section {sectionLabel}', { sectionLabel: section.label }),
          warningMsg: this.translateService.instant(
            'Are you sure you wish to delete this section? All the sub-sections and metrics will be removed from the questionnaire.',
          ),
        },
      })
      .afterClosed()
      .pipe(
        takeWhile((result) => result?.status === Status.CONFIRMED),
        switchMap(() =>
          this.templateReportsApiService.deleteTemplateReportSection(
            this.templateReportId as string,
            section.id,
            this.templateReportVersionId ?? undefined,
          ),
        ),
      )
      .subscribe({
        next: () => this.deleteSection.emit(section),
        complete: () => this.templateReportStructureStateService.loadTemplateReportSections(),
      });
  }

  public moveSectionUp(section: TemplateReportSectionNode) {
    this.moveSection(section, MoveDirection.up);
  }

  public moveSectionDown(section: TemplateReportSectionNode) {
    this.moveSection(section, MoveDirection.down);
  }

  public dropMetric(event: CdkDragDrop<ActionItem<Metric>[]> | CustomEvent, section: TemplateReportSection) {
    if (!('container' in event) && 'detail' in event) {
      // For unit test only. We can't send any properties directly to custom events, it needs to go through detail.
      event = (event as unknown as CustomEvent).detail as CdkDragDrop<ActionItem<Metric>[]>;
    }

    const metric: Metric | undefined = event.previousContainer.data[event.previousIndex].item;
    if (!metric) {
      return;
    }

    this.createMetric.emit({
      section,
      metric,
    });
  }

  public trackById(index: number, item: TemplateReportSection | Metric): string {
    return item.id;
  }

  private moveSection(section: TemplateReportSectionNode, direction: MoveDirection): void {
    if (this.templateReportId && this.templateReportVersionId) {
      this.templateReportsApiService
        .moveTemplateReportSection(
          this.templateReportId,
          this.templateReportVersionId,
          section.id,
          section.position + (direction === MoveDirection.up ? -1 : 1),
        )
        .subscribe({
          next: (res) => {
            this.templateReportStructureStateService.updateTemplateReportSections(res.data);
          },
        });
    }
  }
}
