<div class="notice-container" [ngClass]="type + '-notice'">
  <div class="notice-icon-container">
    <div class="notice-icon">
      <mat-icon class="icon" [svgIcon]="icon"></mat-icon>
    </div>
  </div>
  <div class="notice-text-container">
    <div class="main-notice-text">{{ primaryMessage }}</div>
    <div *ngIf="secondaryMessage" class="secondary-notice-text">{{ secondaryMessage }}</div>
  </div>
</div>
