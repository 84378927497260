import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionItem } from '../../../models';
import { ButtonProps, OptionProps } from '../../models';

@Component({
  selector: 'lib-primary-filter',
  templateUrl: './primary-filter.component.html',
  styleUrls: ['./primary-filter.component.scss'],
})
export class PrimaryFilterComponent implements OnInit {
  buttonTitle: string = '';
  defaultValueTitle: string = '';
  @Input() optionProps?: OptionProps;
  @Input() buttonProps?: ButtonProps;

  @Output() optionSelected = new EventEmitter<ActionItem>();

  ngOnInit(): void {
    this.buttonTitle = this.buttonProps?.title ?? this.optionProps?.activeOption?.title ?? '';
    this.defaultValueTitle = this.optionProps?.defaultValueTitle ?? this.optionProps?.defaultValue?.title ?? '';
  }
}
