import { Pipe, PipeTransform } from '@angular/core';
import { ValueDefinition } from '../../../../../models';

@Pipe({
  name: 'filterUncalculableVds',
})
export class FilterUncalculableVdsPipe implements PipeTransform {
  transform(
    valueDefinitions: ValueDefinition[] = [],
    visibleVdIds: string[] = [],
    tableId: string | undefined | null = '',
    isAdmin: boolean = false,
    isCurrentMetric: boolean = false,
  ): ValueDefinition[] {
    if (tableId || isAdmin || !isCurrentMetric) {
      return valueDefinitions;
    }
    return valueDefinitions.filter((vd) => visibleVdIds.includes(vd.id));
  }
}
