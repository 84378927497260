import { HttpBackend, HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

export abstract class AbstractApiService {
  readonly API_PREFIX: string = 'api';
  readonly HIGHCHARTS_EXPORT_PREFIX = 'highcharts';
  readonly httpBackendClient: HttpClient;
  defaultHeaders = { headers: { 'Content-type': 'application/json' } };

  constructor(httpBackend: HttpBackend) {
    this.httpBackendClient = new HttpClient(httpBackend);
  }

  abstract delete<T>(
    path: string,
    payload?: any,
    params?: HttpParams,
    bypassInterceptors?: boolean,
    context?: HttpContext,
  ): Observable<T>;

  abstract get<T>(path: string, options?: object, bypassInterceptors?: boolean): Observable<T>;

  abstract getServicePath(apiName?: string): string;

  abstract patch<T>(path: string, payload: any, bypassInterceptors?: boolean): Observable<T>;

  abstract post<T>(
    path: string,
    payload?: any,
    params?: HttpParams,
    bypassInterceptors?: boolean,
    context?: HttpContext,
    headers?: HttpHeaders,
  ): Observable<T>;

  abstract put<T>(
    path: string,
    payload: any,
    params?: HttpParams,
    bypassInterceptors?: boolean,
    context?: HttpContext,
  ): Observable<T>;
}
