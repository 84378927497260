import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FiscalYearStatus } from '../../models';

export { FiscalYearsStatusTranslation };

const FiscalYearsStatusTranslation = {
  [FiscalYearStatus.CLOSED]: _('Closed'),
  [FiscalYearStatus.OPEN]: _('Open'),
  [FiscalYearStatus.DRAFT]: _('Draft'),
};
