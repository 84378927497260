import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { DialogConfig, DialogResult, DialogSize } from '../../models';
import { ComponentType } from '@angular/cdk/overlay';
import { TranslateService } from '../../services/common';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { UnsavedChangesDialogComponent } from '../unsaved-changes-dialog/unsaved-changes-dialog.component';
import { InfoDialogV2Component } from '../info-dialog-v2/info-dialog-v2.component';

export interface BaseDialogConfig {
  size?: DialogSize;
  title?: string;
  body?: any;
  modal?: boolean;
  button?: string;
}

type DataDialogConfig<T = any> = T & BaseDialogConfig;

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) {}

  open<T, D = any, R = DialogResult>(
    component: ComponentType<T>,
    config?: MatDialogConfig<DataDialogConfig<D>>,
  ): MatDialogRef<T, R> {
    if (!config) {
      config = new MatDialogConfig<DataDialogConfig<D>>();
    }

    switch (config.data?.size) {
      case DialogSize.small:
        config.width = DialogConfig.small.width;
        config.position = DialogConfig.small.position;
        break;
      case DialogSize.large:
        config.maxWidth = DialogConfig.large.maxWidth;
        break;
      case DialogSize.full:
        config.maxWidth = DialogConfig.large.maxWidth;
        config.width = '100%';
        break;
      case DialogSize.fullscreen:
        config.height = '100%';
        config.width = '100%';
        config.maxWidth = 'none';
        break;
      case DialogSize.auto:
        config.height = 'auto';
        config.width = 'auto';
        config.position = DialogConfig.small.position;
        break;
      default:
        config.width = DialogConfig.medium.width;
        config.position = DialogConfig.medium.position;
    }

    config.autoFocus = false;

    return this.dialog.open<T, D, R>(component, config);
  }

  info(body: string, title?: string, button?: string): MatDialogRef<InfoDialogComponent> {
    const config = new MatDialogConfig();

    config.data = {
      title,
      body,
      button,
      size: DialogSize.small,
    };

    return this.open<InfoDialogComponent, BaseDialogConfig>(InfoDialogComponent, config);
  }

  error(error: string, title?: string) {
    const config = new MatDialogConfig();

    config.data = {
      title: title ?? this.translateService.instant('Attention!'),
      body: error,
      modal: true,
      button: this.translateService.instant('OK'),
      size: DialogSize.small,
    };

    return this.open<InfoDialogComponent, BaseDialogConfig>(InfoDialogComponent, config);
  }

  unsavedChanges(): MatDialogRef<any> {
    const config = new MatDialogConfig();

    config.disableClose = true;
    config.backdropClass = 'dialog-blur';

    return this.open<UnsavedChangesDialogComponent>(UnsavedChangesDialogComponent, config);
  }

  modalConfirmation(body: string, title?: string, button?: string) {
    const config = new MatDialogConfig();

    config.data = {
      title,
      body,
      button,
      modal: true,
      size: DialogSize.auto,
    };
    config.disableClose = true;
    config.backdropClass = 'dialog-blur';

    return this.open<InfoDialogV2Component>(InfoDialogV2Component, config);
  }

  modalError(error: string, title?: string) {
    const config = new MatDialogConfig();

    config.data = {
      title,
      error,
      modal: true,
      size: DialogSize.small,
    };
    config.disableClose = true;
    config.backdropClass = 'dialog-blur';

    return this.open<InfoDialogComponent>(InfoDialogComponent, config);
  }

  offlineError() {
    const config = new MatDialogConfig();

    config.data = {
      body: this.translateService.instant(
        'No network detected. The Novisto app requires an active internet connection. The application will become available when a connection is detected.',
      ),
      modal: true,
      size: DialogSize.small,
      isOfflineDialog: true,
    };
    config.disableClose = true;
    config.backdropClass = 'dialog-blur';

    return this.open<InfoDialogComponent, BaseDialogConfig>(InfoDialogComponent, config);
  }
}
