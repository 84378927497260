import { Pipe, PipeTransform } from '@angular/core';
import { ValueGroupFormGroup } from '../../models/valueGroupFormGroup';
import { ValueDefinitionType } from '../../../models';
import { ValueFormControl } from '../../models/valueFormControl';

@Pipe({
  name: 'isCalculatedFieldInRepeatableGroup',
})
export class IsCalculatedFieldInRepeatableGroup implements PipeTransform {
  transform(valueFormControl: ValueFormControl, valueGroupFormGroup: ValueGroupFormGroup): boolean {
    return valueGroupFormGroup.repeatable && valueFormControl.valueRef.type === ValueDefinitionType.calculated;
  }
}
