import { ActionItem, FrameworkTaxonomyCategory, MetricCategory, Taxonomy } from '../../../models';

export class TaxonomyFilterByCategory {
  public static filterTaxonomies(item: ActionItem<Taxonomy>, category?: FrameworkTaxonomyCategory): boolean {
    const scCategory = item.item?.framework?.standard_code_category;
    const isQuestionnaireFramework =
      category === FrameworkTaxonomyCategory.QUESTIONNAIRE_FRAMEWORK &&
      item.item?.framework?.metric_category === MetricCategory.THIRD_PARTY;

    if (!scCategory && isQuestionnaireFramework) {
      return true;
    }
    return scCategory === category;
  }
}
