import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateDefaultParser,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { Injectable, NgModule } from '@angular/core';

import { LANGUAGES } from '../models';
import { TranslateService } from '../services/common';
import MessageFormat from '@messageformat/core';

const mf = new MessageFormat('en');

@Injectable()
class TestingTranslateParser extends TranslateDefaultParser {
  templateMatcher: RegExp = /{\s?([^{}\s]*)\s?}/g;
}

@Injectable()
class TestingTranslateService extends TranslateService {
  instant(key: string, interpolateParams?: Record<string, unknown>): string {
    const msg = mf.compile(key);
    return msg(interpolateParams);
  }
}

@Injectable()
class TestingMissingTranslationHandler extends MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    const msg = mf.compile(params.key);
    return msg(params.interpolateParams as Record<string, unknown>);
  }
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      parser: {
        provide: TranslateParser,
        useClass: TestingTranslateParser,
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: TestingMissingTranslationHandler },
    }),
  ],
  exports: [TranslateModule],
  providers: [{ provide: TranslateService, useClass: TestingTranslateService }],
})
export class TestingCommonTranslateModule {
  constructor(private readonly translateService: TranslateService) {
    this.translateService.addLangs(Object.values(LANGUAGES));
    this.translateService.setDefaultLang(LANGUAGES.EN);
    this.translateService.use(LANGUAGES.EN);
  }
}
