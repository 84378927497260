import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TooltipComponent } from './tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject, takeUntil } from 'rxjs';

const UNBOUNDED_ANCHOR_GAP = 8;

@Directive({
  selector: '[libTooltip]',
})
export class TooltipDirective implements OnDestroy, OnInit {
  @Input() tooltip: string | TemplateRef<any> = '';
  @Input() showToolTip: boolean = true;

  private overlayRef?: OverlayRef;
  private tooltipRef: ComponentRef<TooltipComponent> | null = null;

  /** Emits when the component is destroyed. */
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.showTooltip();
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent): void {
    const newTarget = event.relatedTarget as Node | null;

    /** Checks if the cursor target is in the tooltip element. If not, close the tooltip */
    if (!newTarget || !this.overlayRef?.overlayElement.contains(newTarget)) {
      this.closeToolTip();
    }
  }

  ngOnInit(): void {
    if (!this.showToolTip) {
      return;
    }
  }

  private showTooltip() {
    if (this.isTooltipVisible()) {
      return;
    }

    /** Position is hard coded to display the tooltip above the anchor element. */
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -UNBOUNDED_ANCHOR_GAP,
      },
    ]);

    this.overlayRef = this.overlay.create({ positionStrategy });

    this.overlayRef
      .detachments()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.detach());

    /** If the overlay element doesn't have content attached, we attach new one with the provided proerties. */
    if (!this.overlayRef.hasAttached()) {
      this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
      this.setTooltipProperties();
    }

    /** Listen on tooltip instance to know if it was hidden to know when we need to detach from it. */
    this.tooltipRef?.instance
      .afterHidden()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.detach());

    /** Display the tooltip element */
    this.tooltipRef?.instance.show();
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** Access tooltip reference instance to know it is still visible. */
  private isTooltipVisible(): boolean {
    return !!this.tooltipRef?.instance && this.tooltipRef.instance.visible;
  }

  private detach() {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
    this.tooltipRef = null;
  }

  private closeToolTip() {
    this.detach();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private setTooltipProperties() {
    if (!this.tooltipRef) {
      return;
    }

    if (typeof this.tooltip === 'string') {
      this.tooltipRef.instance.tooltipText = this.tooltip;
    } else {
      this.tooltipRef.instance.tooltipTemplate = this.tooltip;
    }
    this.tooltipRef.instance.triggerElement = this.elementRef.nativeElement as HTMLElement;
  }
}
