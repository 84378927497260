import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MetricTableCalculationTypeDetailFormulaFilter } from '../../../../../../models';

export class MetricTableTotalContextColumnForm extends UntypedFormGroup {
  readonly columnControl = this.get('column') as UntypedFormControl;
  readonly valuesControl = this.get('values') as UntypedFormControl;

  constructor(
    contextColumn?: MetricTableCalculationTypeDetailFormulaFilter,
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
  ) {
    super(
      fb.group({
        column: fb.control(contextColumn?.column),
        values: fb.control(contextColumn?.values, Validators.required),
      }).controls,
    );
  }

  public toModel(): MetricTableCalculationTypeDetailFormulaFilter {
    return {
      column: String(this.columnControl.value),
      values: this.valuesControl.value,
    };
  }
}
