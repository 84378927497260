<div class="panel-container">
  <div class="panel-header underline">
    <div class="fx-row justify-space-between w-100">
      <h4 class="mat-subtitle-2">{{ "Guidance" | translate }}</h4>
    </div>
  </div>
  <div class="panel-sub-header">
    <lib-filter
      [buttonProps]="{
        icon: 'guidance',
        active: !!sortedStandardCodeOptions.length,
        disabled: sortedStandardCodeOptions.length === 0,
        title: selectedCode ? selectedCode.code : indicator?.code ?? '',
      }"
      [optionProps]="{
        title: 'Compatible Standard Codes' | translate,
        options: sortedStandardCodeOptions,
        defaultValue: { id: indicator?.id ?? '', title: indicator?.code ?? '' },
        activeOption: selectedCode ? { id: selectedCode.id, title: selectedCode.code } : undefined,
      }"
      (optionSelected)="setGuidance($event)"
    >
    </lib-filter>
  </div>
  <div class="panel-content overlay-container">
    <div *ngIf="guidance">
      <div class="text mt-2" [innerHTML]="guidance | sanitizeHtml"></div>
    </div>
    <lib-empty-results *ngIf="!guidance" class="h-100" [displayData]="guidanceEmptyResults"></lib-empty-results>
  </div>
</div>
