import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss'],
})
export class UserBadgeComponent {
  @Input({ required: true }) fullName!: string;
  @Input() size: 'medium' | 'large' | 'very-large' = 'medium';
}
