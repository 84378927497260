/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';

import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import {
  ApiResponse,
  ApplicationApiDefinition,
  Indicator,
  Metric,
  ValueGroupSet,
  GetIndicatorPayload,
  SearchOptions,
} from '../../../models';
import { IndicatorsApiService } from '../../api-services';
import { AdminMetricService } from '../admin-metric/admin-metric.service';

@Injectable({
  providedIn: 'root',
})
export class AdminIndicatorsService extends IndicatorsApiService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
    private adminMetricService: AdminMetricService,
  ) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
  }

  getIndicator(): Observable<ApiResponse<Indicator>> {
    throw 'not implemented';
  }

  getIndicatorByMetricIds(): Observable<ApiResponse<Indicator[]>> {
    throw 'not implemented';
  }

  listMinimalIndicators(frameworkId?: string): Observable<ApiResponse<Metric[]>> {
    let params = new HttpParams();
    if (frameworkId) {
      params = params.append('framework_id', frameworkId);
    }

    // TODO Change the resource for the indicators one when the indicator concept is added in the backend
    //  and the call is moved from metrics to indicators
    //  https://novisto.atlassian.net/browse/NF-2775
    return this.apiService.get(
      `${this.servicePath}${this.apiService.apiConfig.apis.admin.resources.metrics}/metrics/minimal`,
      { params },
    );
  }

  listValueProgressSummary(): Observable<ApiResponse<ValueGroupSet[]>> {
    throw 'not implemented';
  }

  listValueProgressSummaryGroup(): Observable<ApiResponse<ValueGroupSet[]>> {
    throw 'not implemented';
  }

  public search(searchOptions: SearchOptions): Observable<ApiResponse<Indicator[]>> {
    return this.adminMetricService.search(searchOptions);
  }
}
