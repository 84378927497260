import { Pipe, PipeTransform } from '@angular/core';
import { ValueGroupFormGroup } from '../models/valueGroupFormGroup';

@Pipe({
  name: 'isShowAddGroupButton',
})
export class IsShowAddGroupButtonPipe implements PipeTransform {
  transform(valueGroupFormGroup: ValueGroupFormGroup, disabled: boolean, repeatableGroupCount: number): boolean {
    if (disabled || !valueGroupFormGroup.repeatable) {
      return false;
    }

    const groupMaxRepetition = valueGroupFormGroup.groupMaxRepetition;
    const isLast = valueGroupFormGroup.subposition === repeatableGroupCount;

    const hasValuesToShow = Boolean(valueGroupFormGroup.displayedValueFormControls().length);

    return (groupMaxRepetition ? repeatableGroupCount < groupMaxRepetition && isLast : isLast) && hasValuesToShow;
  }
}
