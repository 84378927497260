import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | undefined, limit = 25, trail = '...') {
    return value && value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
