import {
  DataRequestIndicatorLink,
  DataRequestSourceStatus,
  DataRequestType,
  DataRequestUser,
  DataRequestWithSource,
  FiscalYearPeriod,
  Frequency,
} from '../models';
import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';
import { INPUT_DATE_FORMAT } from './builder-utils';

export class DataRequestWithSourceBuilder {
  private readonly _dataRequestWithSource: DataRequestWithSource;

  constructor() {
    const dateInTheFuture: Date = faker.date.future(5);

    this._dataRequestWithSource = {
      business_unit_id: 'business unit id',
      data_request_users: [],
      frequency_code: '123',
      frequency: Frequency.YEARLY,
      indicator_links: [],
      last_updated: new Date(),
      name: 'a name',
      source: 'source',
      started_with_reviewer: false,
      status: DataRequestSourceStatus.NOT_STARTED,
      show_historical_data: true,
      show_recommendations: true,
      show_audit_trail_panel: true,
      show_consolidation_panel: true,
      allow_reviewers_to_edit: true,
      allow_approvers_to_edit: true,
      active: true,
      hide_metric_codes: false,
      type: DataRequestType.REGULAR,
      due_date: dayjs(dateInTheFuture).format(INPUT_DATE_FORMAT),
    };
  }

  startedWithReviewer(startedWithReviewer: boolean): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.started_with_reviewer = startedWithReviewer;
    return this;
  }

  name(name: string): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.name = name;
    return this;
  }

  source(source: string): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.source = source;
    return this;
  }

  status(status: DataRequestSourceStatus): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.status = status;
    return this;
  }

  indicatorLinks(indicatorLinks: DataRequestIndicatorLink[]): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.indicator_links = indicatorLinks;
    return this;
  }

  frequencyCode(frequencyCode: string): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.frequency_code = frequencyCode;
    return this;
  }

  frequency(frequency: Frequency): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.frequency = frequency;
    return this;
  }

  fiscal_year_period_id(fiscal_year_period_id: string | undefined): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.fiscal_year_period_id = fiscal_year_period_id;
    return this;
  }

  fiscal_year_period(fiscal_year_period: FiscalYearPeriod | undefined): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.fiscal_year_period = fiscal_year_period;
    return this;
  }

  businessUnitId(businessUnitId: string): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.business_unit_id = businessUnitId;
    return this;
  }

  introduction(introduction: string): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.introduction = introduction;
    return this;
  }

  dataRequestUsers(dataRequestUsers: DataRequestUser[]): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.data_request_users = dataRequestUsers;
    return this;
  }

  active(active: boolean): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.active = active;
    return this;
  }

  allowReviewersToEdit(allowReviewersToEdit: boolean): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.allow_reviewers_to_edit = allowReviewersToEdit;
    return this;
  }

  allowApproversToEdit(allowApproversToEdit: boolean): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.allow_approvers_to_edit = allowApproversToEdit;
    return this;
  }

  dueDate(dueDate: string): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.due_date = dueDate;
    return this;
  }

  conclusion(conclusion: string): DataRequestWithSourceBuilder {
    this._dataRequestWithSource.conclusion = conclusion;
    return this;
  }

  build(): DataRequestWithSource {
    return this._dataRequestWithSource;
  }
}
