import { CONDITIONAL_TRIGGER_SOURCE_TYPES, ConditionalTrigger, ValueDefinitionType } from '../../models';
import { ValueGroupSetForm } from '../models/valueGroupSetForm';
import { ValueFormControl } from '../models/valueFormControl';
import { Injectable } from '@angular/core';
import { ConditionalTriggerUtils } from '../../classes/ConditionalTriggerUtils/conditional-trigger-utils';

@Injectable({
  providedIn: 'root',
})
export class ConditionalTriggerService {
  public isSupportedType(type: ValueDefinitionType): boolean {
    return CONDITIONAL_TRIGGER_SOURCE_TYPES.includes(type);
  }

  public isElementDisplayed(triggers: ConditionalTrigger[] | undefined, valueGroupSetForm: ValueGroupSetForm): boolean {
    if (triggers && triggers.length > 0) {
      return this.hasTriggerTriggeredForValueGroupSetForm(triggers, valueGroupSetForm);
    }

    return true;
  }

  public getUntriggeredFilledValueFormControls(
    updatedValueFormControl: ValueFormControl,
    valueGroupSetForm: ValueGroupSetForm,
  ): ValueFormControl[] {
    const untriggeredGroups = valueGroupSetForm
      .groupFormGroups()
      .filter((vgf) =>
        this.hasUntriggeredTriggerForValue(vgf.valueGroupRef.conditional_triggers ?? [], updatedValueFormControl),
      );
    const filledValuesFromUntriggeredGroups = untriggeredGroups
      .reduce((vfcs: ValueFormControl[], groupForm) => [...vfcs, ...groupForm.valueFormControls()], [])
      .filter((vfc) => vfc.value != null && vfc.value != '');

    const untriggeredFilledValues =
      valueGroupSetForm
        .groupFormGroups()
        .flatMap((vgf) => vgf.valueFormControls())
        .filter((vfc) =>
          this.hasUntriggeredTriggerForValue(vfc.valueRef.conditional_triggers ?? [], updatedValueFormControl),
        )
        .filter((vfc) => vfc.value != null && vfc.value != '') ?? [];

    return [...filledValuesFromUntriggeredGroups, ...untriggeredFilledValues];
  }

  private hasUntriggeredTriggerForValue(triggers: ConditionalTrigger[], value: ValueFormControl): boolean {
    return triggers
      .filter((t) => t.source_value_definition_id === value.valueRef.value_definition_id)
      .some((t) => !this.isTriggerTriggered(t, value));
  }

  private hasTriggerTriggeredForValueGroupSetForm(
    triggers: ConditionalTrigger[],
    valueGroupSetForm: ValueGroupSetForm,
  ): boolean {
    const allValues = valueGroupSetForm
      .groupFormGroups()
      .reduce(
        (valueFormControls: ValueFormControl[], valueGroupForm) => [
          ...valueFormControls,
          ...valueGroupForm.valueFormControls(),
        ],
        [],
      );
    return this.hasTriggerTriggered(triggers, allValues);
  }

  private hasTriggerTriggered(triggers: ConditionalTrigger[], valueFormControls: ValueFormControl[]): boolean {
    return triggers.some((t) =>
      this.isTriggerTriggered(
        t,
        valueFormControls.find((vfc) => vfc.valueRef.value_definition_id === t.source_value_definition_id),
      ),
    );
  }

  private isTriggerTriggered(trigger: ConditionalTrigger, valueFormControl?: ValueFormControl): boolean {
    if (valueFormControl) {
      switch (valueFormControl.valueRef.type) {
        case ValueDefinitionType.boolean:
          const booleanValue = valueFormControl.value?.value as boolean;
          return (trigger.values as boolean[]).includes(booleanValue);

        case ValueDefinitionType.number:
          const controlValue = valueFormControl.value as string | undefined;
          return ConditionalTriggerUtils.compareValues(controlValue, String(trigger.values), trigger.operator);

        case ValueDefinitionType.choice:
        default:
          const choiceValues = valueFormControl.value?.values as string[] | string | undefined;
          return Array.isArray(choiceValues)
            ? (trigger.values as string[]).some((value) => choiceValues.includes(value))
            : (trigger.values as string[]).includes(String(choiceValues));
      }
    }
    return false;
  }
}
