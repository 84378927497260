import { SortingTranslation } from '../sorting.translation';

export { UnitGroupSort, UnitGroupItemSort };

const UnitGroupSort = {
  updated: SortingTranslation.last_updated,
  name: SortingTranslation.alphabetical,
};

const UnitGroupItemSort = {
  position: SortingTranslation.position,
  name: SortingTranslation.alphabetical,
};
