import { Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ValidationMessageService } from '../../../services/common/validation-message/validation-message.service';
import {
  ApiResponse,
  ConfirmationDialogConfig,
  DataRequestToolPanelVisibilityType,
  DialogResult,
  Doc,
  FileDocumentInterface,
  FileTypeDetails,
  ItemType,
  RequestDocMetadata,
  Status,
} from '../../../models';
import { ValueFormControl } from '../../models/valueFormControl';
import { ClientDocumentsService } from '../../../services/client/client-documents/client-documents.service';

import { ClientAssetsService } from '../../../services/client/client-assets/client-assets.service';
import {
  MetricEditorAttachFileDialogComponent,
  MetricEditorAttachFileDialogConfig,
  MetricEditorAttachFileDialogData,
} from './metric-editor-attach-file-dialog/metric-editor-attach-file-dialog.component';
import { auditTime, catchError, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { DownloadDocumentService } from '../../../services/common/document/download-document.service';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
import { ObservableUtils, FileUtils } from '../../../classes';
import isEqual from 'lodash/isEqual';
import { FileUploadSelectorService, TranslateService } from '../../../services/common';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorManagerService } from '../../../error-manager';
import { FileExtensionErrors } from '../../../translations';

@Component({
  selector: 'lib-metric-editor-file-attachment',
  templateUrl: './metric-editor-file-attachment.component.html',
  styleUrls: ['./metric-editor-file-attachment.component.scss'],
})
export class MetricEditorFileAttachmentComponent implements OnInit {
  @Input({ required: true }) valueFormControl!: ValueFormControl<FileTypeDetails>;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() messages?: ValidationErrors;

  @ViewChild('focusElement') focusElement!: ElementRef;
  uploading = false;
  customBrowseDialog = false;
  fileDocumentsList: FileDocumentInterface[] = [];
  docIdsDownloading: string[] = [];
  hint = '';
  filesLoaded = false;
  maxFilesLimit: number = 1;
  dataRequestId?: string;

  private valueUpdateSubject$ = new Subject<string[] | null>();

  constructor(
    private translateService: TranslateService,
    private validationMessageService: ValidationMessageService,
    private documentsService: ClientDocumentsService,
    private dialogsService: DialogsService,
    private assetsService: ClientAssetsService,
    private downloadDocumentService: DownloadDocumentService,
    private viewContainerRef: ViewContainerRef,
    private errorManagerService: ErrorManagerService,
    private fileUploadSelectorService: FileUploadSelectorService,
  ) {}

  ngOnInit(): void {
    this.setupCustomBrowseDialog();

    this.messages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };

    this.valueFormControl.registerOnChange(() => {
      this.valueUpdateSubject$.next(this.valueFormControl.value as string[]);
    });

    this.valueUpdateSubject$
      .pipe(
        startWith(this.valueFormControl.value as string[] | null),
        distinctUntilChanged(isEqual),
        auditTime(300),
        switchMap((documentIds: string[] | null) =>
          documentIds != null && documentIds.length ? this.requestFileMetaData(documentIds) : of([]),
        ),
        tap((documentInterfaces) => {
          this.fileDocumentsList = documentInterfaces;
          this.filesLoaded = true;
        }),
      )
      .subscribe();

    this.hint = this.valueFormControl.valueRef.hint ?? '';
    this.maxFilesLimit = this.valueFormControl.valueRef.type_details.max_files || 1;
  }

  public setFocus(): void {
    this.focusElement.nativeElement.focus();
  }

  public getLabel(): string {
    if (this.valueFormControl.invalid && this.valueFormControl.touched) {
      return this.translateService.instant('File upload error');
    } else if (this.uploading) {
      return this.translateService.instant('File upload in progress');
    }
    return this.valueFormControl.valueRef.label ?? '';
  }

  public selectFileFromBrowse(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      this.handleFile(files[0], event);
    }
  }

  public dropFile(event: DragEvent): void {
    if (event.dataTransfer) {
      this.handleFile(event.dataTransfer.files[0], event);
    }
  }

  public preventEventDefault(evt: Event): void {
    evt.preventDefault();
    evt.stopImmediatePropagation();
  }

  private handleFile(file: File, event: Event): void {
    if (this.valueFormControl.enabled) {
      this.preventEventDefault(event);
      this.uploadFile(file);
    }
  }

  public launchCustomBrowseDialog(file?: File): void {
    const canShowStatusFilter = this.fileUploadSelectorService.canShowStatusFilter(
      this.documentContext.dataRequestInfo?.allow_users_to_attach_public_files ??
        DataRequestToolPanelVisibilityType.NONE,
      this.documentContext.dataRequestInfo?.allow_users_to_attach_private_files ??
        DataRequestToolPanelVisibilityType.NONE,
      this.documentContext.itemType,
    );
    this.dialogsService
      .open<
        MetricEditorAttachFileDialogComponent,
        MetricEditorAttachFileDialogConfig,
        DialogResult<MetricEditorAttachFileDialogData>
      >(MetricEditorAttachFileDialogComponent, {
        viewContainerRef: this.viewContainerRef,
        data: {
          file,
          dataRequestId: this.documentContext.dataRequestInfo?.id,
          canShowStatusFilter,
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result?.status === Status.SUCCESS),
        map((result) => result?.data),
        ObservableUtils.filterNullish(),
        switchMap((attachedFileData) => {
          this.uploading = true;
          if (attachedFileData.doc) {
            this.addADocIdAndUpdate(
              attachedFileData.doc.id,
              attachedFileData.doc.name,
              attachedFileData.doc.format,
              attachedFileData.doc,
            );
            return EMPTY;
          } else if (attachedFileData.formData) {
            const file = <File>attachedFileData.formData.get('document');
            const fileInfo = FileUtils.getFileDetails(file);

            if (this.documentContext.itemType == ItemType.data_requests_request) {
              return this.requestUploadDocument(file).pipe(
                tap((fileDocument) => {
                  this.addADocIdAndUpdate(fileDocument.id, fileInfo.name, fileInfo.format);
                }),
              );
            }

            return this.assetsService.uploadDocument(attachedFileData.formData, false, true).pipe(
              tap((doc) => this.addADocIdAndUpdate(doc.data.document_uploaded_id, fileInfo.name, fileInfo.format)),
              catchError((httpError: unknown) => {
                this.handleUploadDocumentError(httpError as HttpErrorResponse);
                return EMPTY;
              }),
            );
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }

  private addADocIdAndUpdate(id: string, name = 'no-name', format = 'no-format', doc?: Doc | RequestDocMetadata): void {
    this.fileDocumentsList.push({
      id,
      doc,
      name,
      format,
    });
    const updatedDocumentIds = [...((this.valueFormControl.value as string[] | null) ?? []), id];
    this.valueFormControl.setValue(updatedDocumentIds);
    this.uploading = false;
  }

  private removeADocIdAndUpdate(id: string): void {
    this.fileDocumentsList = this.fileDocumentsList.filter((file) => file.id !== id);
    const updatedDocumentIds = ((this.valueFormControl.value as string[] | null) ?? []).filter((docId) => docId !== id);
    this.valueFormControl.setValue(updatedDocumentIds);
  }

  private uploadFile(file: File): void {
    switch (this.documentContext.itemType) {
      case ItemType.data_requests_request:
      case ItemType.public_data_requests_request:
        this.uploading = true;
        this.requestUploadDocument(file).subscribe((doc: FileDocumentInterface) => {
          this.addADocIdAndUpdate(doc.id, doc.name, doc.format);
        });
        break;
      case ItemType.metrics_preview:
        this.launchUnsupportedTypeErrorDialog();
        break;
      default:
        this.launchCustomBrowseDialog(file);
    }
  }

  public deleteDoc(doc: FileDocumentInterface): void {
    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Delete file'),
          warningMsg: this.translateService.instant('Are you sure you wish to delete the file?'),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.status === Status.CONFIRMED) {
          this.removeADocIdAndUpdate(doc.id);
        }
      });
  }

  public downloadDoc(docInfo: FileDocumentInterface): void {
    this.docIdsDownloading.push(docInfo.id);
    this.requestDownloadDocument(docInfo).subscribe((doc: Blob) => {
      this.downloadAction(docInfo, doc);
    });
  }

  public isDocumentDownloading(docId: string): boolean {
    return this.docIdsDownloading.includes(docId);
  }

  private downloadAction(docInfo: FileDocumentInterface, blob: Blob): void {
    const blobOctetStream: Blob = new Blob([blob], { type: 'application/octet-stream' });
    this.downloadDocumentService.downloadAction(`${docInfo.name}.${docInfo.format}`, blobOctetStream);
    this.docIdsDownloading.splice(this.docIdsDownloading.indexOf(docInfo.id), 1);
  }

  private launchUnsupportedTypeErrorDialog(): void {
    this.dialogsService.error(
      this.translateService.instant('Uploading a file is not supported in preview mode.'),
      this.translateService.instant('Action not supported'),
    );
  }

  // TODO: Move these methods to the service that will be injected in the future.

  private requestFileMetaData(documentIds: string[]): Observable<FileDocumentInterface[]> {
    switch (this.documentContext.itemType) {
      case ItemType.data_requests_request:
        return this.getRequestDocumentMetaData(documentIds);
      case ItemType.public_data_requests_request:
        return this.getPublicRequestDocumentMetaData(documentIds);
      default:
        return this.getDocumentFromPlatform(documentIds);
    }
  }

  private requestUploadDocument(file: File): Observable<FileDocumentInterface> {
    switch (this.documentContext.itemType) {
      case ItemType.data_requests_request:
        return this.uploadRequestDocument(file);
      case ItemType.public_data_requests_request:
        return this.uploadPublicRequestDocument(file);
      default:
        throw new Error(`Item type ${this.documentContext.itemType} not supported`);
    }
  }

  private requestDownloadDocument(docInfo: FileDocumentInterface): Observable<Blob> {
    switch (this.documentContext.itemType) {
      case ItemType.data_requests_request:
        return this.documentsService.getRequestDocument(docInfo.id);
      case ItemType.public_data_requests_request:
        if (this.documentContext.dataRequestInfo) {
          return this.documentsService.getPublicRequestDocument(this.documentContext.dataRequestInfo, docInfo.id);
        }
        throw new Error('Data request information is missing');
      default:
        const doc = <Doc>docInfo.doc;
        return this.assetsService.getDoc(ItemType.docs_doc, doc.storage_filename);
    }
  }

  // Documents from platform
  private getDocumentFromPlatform(documentIds: string[]): Observable<FileDocumentInterface[]> {
    return this.documentsService.getDocuments(documentIds).pipe(
      map((res) => res.data),
      map((docs) =>
        docs.map((doc) => ({
          id: doc.id,
          name: doc.name,
          format: doc.format,
          doc,
        })),
      ),
    );
  }

  // Documents from Requests
  private uploadRequestDocument(file: File): Observable<FileDocumentInterface> {
    const formData = new FormData();
    const fileDetails = file.name.split(' ').join('_').split('.');
    formData.append('name', fileDetails[0]);
    formData.append('document', file, file.name);
    return this.documentsService.uploadRequestDocument(formData).pipe(
      map((res) => res.data),
      map((doc) => ({
        id: doc.document_id,
        name: fileDetails[0],
        format: fileDetails[1],
      })),
    );
  }

  private getRequestDocumentMetaData(documentIds: string[]): Observable<FileDocumentInterface[]> {
    return this.documentsService.getRequestDocumentMetaData(documentIds).pipe(
      map((res) => res.data),
      map((docs) =>
        docs.map((doc) => ({
          id: doc.id,
          name: doc.name,
          format: doc.extension,
          doc,
        })),
      ),
    );
  }

  // Documents from Requests public
  private uploadPublicRequestDocument(file: File): Observable<FileDocumentInterface> {
    if (this.documentContext.dataRequestInfo) {
      const formData = new FormData();
      const fileDetails = file.name.split(' ').join('_').split('.');
      formData.append('name', fileDetails[0]);
      formData.append('document', file, file.name);
      return this.documentsService
        .uploadPublicRequestDocument(
          this.documentContext.dataRequestInfo.id,
          this.documentContext.dataRequestInfo.sourceId,
          formData,
        )
        .pipe(
          map((res: ApiResponse<{ document_id: string }>) => res.data),
          map((doc: { document_id: string }) => ({
            id: doc.document_id,
            name: fileDetails[0],
            format: fileDetails[1],
          })),
        );
    } else {
      throw new Error('Data request information is missing');
    }
  }

  private getPublicRequestDocumentMetaData(documentIds: string[]): Observable<FileDocumentInterface[]> {
    if (this.documentContext.dataRequestInfo) {
      return this.documentsService
        .getPublicRequestDocumentMetaData(
          this.documentContext.dataRequestInfo.id,
          this.documentContext.dataRequestInfo.sourceId,
          documentIds,
        )
        .pipe(
          map((res: ApiResponse<RequestDocMetadata[]>) => res.data),
          map((docs: RequestDocMetadata[]) =>
            docs.map((doc: RequestDocMetadata) => ({
              id: doc.id,
              name: doc.name,
              format: doc.extension,
              doc,
            })),
          ),
        );
    } else {
      throw new Error('Data request information is missing');
    }
  }

  private setupCustomBrowseDialog() {
    const publicFileVisibility: DataRequestToolPanelVisibilityType =
      this.documentContext.dataRequestInfo?.allow_users_to_attach_public_files ??
      DataRequestToolPanelVisibilityType.NONE;
    const privateFileVisibility: DataRequestToolPanelVisibilityType =
      this.documentContext.dataRequestInfo?.allow_users_to_attach_private_files ??
      DataRequestToolPanelVisibilityType.NONE;

    if (this.documentContext.itemType === ItemType.metrics_indicator) {
      this.customBrowseDialog = true;
    }

    if (
      [ItemType.data_requests_request, ItemType.public_data_requests_request].includes(this.documentContext.itemType)
    ) {
      this.customBrowseDialog = this.fileUploadSelectorService.canShowDocumentSelector(
        publicFileVisibility,
        privateFileVisibility,
        this.documentContext.itemType,
      );
    }
  }

  private handleUploadDocumentError(httpError: HttpErrorResponse): void {
    this.uploading = false;
    if (httpError.error?.errors?.[0]?.type === 'risky_extension') {
      this.dialogsService.error(FileExtensionErrors.unsupported);
    } else {
      this.errorManagerService.handleError(httpError);
    }
  }
}
