import { UntypedFormBuilder } from '@angular/forms';
import { Frequency, Value, ValueGroup } from '../../models';
import { ValueFormControl } from './valueFormControl';
import { ConditionalTriggerService } from '../services/conditional-trigger.service';
import { ValueGroupSetForm } from './valueGroupSetForm';
import { UpsertValue, UpsertValueGroup } from './upsertValue';
import { MetricEditorFormGroup } from './metricEditorFormGroup';
import { UpdateOnType } from './updateOnType';

export class ValueGroupFormGroup extends MetricEditorFormGroup {
  public valueGroupRef: ValueGroup;

  get id(): string {
    return this.valueGroupRef.id;
  }

  get definitionId(): string {
    return this.valueGroupRef.value_definition_group_id;
  }

  get position(): number {
    return this.valueGroupRef.position;
  }

  get subposition(): number {
    return this.valueGroupRef.subposition ?? 1;
  }

  get repeatable(): boolean {
    return this.valueGroupRef.repeatable ?? false;
  }

  get groupMaxRepetition(): number {
    return this.valueGroupRef.group_max_repetition;
  }

  override waitForNextUpdate(): void {
    super.waitForNextUpdate();
    this.valueFormControls().forEach((vfc) => vfc.waitForNextUpdate());
  }

  private readonly _updateOn: UpdateOnType;

  constructor(
    initialValueGroupRef: ValueGroup,
    updateOn: UpdateOnType = 'blur',
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
    readonly conditionalTriggerService: ConditionalTriggerService = new ConditionalTriggerService(),
  ) {
    super(fb.group({}).controls, { updateOn });
    this.valueGroupRef = initialValueGroupRef;
    this._updateOn = updateOn;

    this.addValueControls(this.valueGroupRef);
  }

  public valueFormControls(): ValueFormControl[] {
    return (Object.values(this.controls) as ValueFormControl[]).sort(
      ValueGroupFormGroup.sortValueFormControlsByPosition,
    );
  }

  public displayedValueFormControls(): ValueFormControl[] {
    return this.valueFormControls().filter(
      (valueFormControl) =>
        this.conditionalTriggerService.isElementDisplayed(
          valueFormControl.valueRef.conditional_triggers,
          this.root as ValueGroupSetForm,
        ) && valueFormControl.valueRef.fiscal_year_period == null,
    );
  }

  public getFrequenciesControlForValueFormControl(bobValueFormControl: ValueFormControl): ValueFormControl[] {
    return this.valueFormControls().filter(
      (valueFormControl) =>
        valueFormControl.valueRef.fiscal_year_period != null &&
        valueFormControl.valueRef.value_definition_id == bobValueFormControl.valueRef.value_definition_id,
    );
  }

  public updateGroup(valueGroup: ValueGroup): void {
    this.valueGroupRef = valueGroup;
    this._isWaitingForNextUpdate$.next(false);
    this.valueGroupRef.values?.forEach((value) => {
      const existingValueFormControl = this.valueFormControls().find(
        (vfc) => vfc.valueRef.value_definition_id === value.value_definition_id,
      );
      if (existingValueFormControl) {
        existingValueFormControl.updateValue(value);
      } else {
        this.addValueControl(value);
      }

      // The frequency breakdown for each value needs to be manually updated if it exists
      if (value.value_breakdown_frequency !== null) {
        value.value_breakdown_frequency?.forEach((valueBreakdownFrequency) => {
          const existingValueBreakdownFrequencyFormControl = this.valueFormControls().find(
            (valueBreakdownFrequencyFormControl) =>
              valueBreakdownFrequencyFormControl.valueRef.value_definition_id === value.value_definition_id &&
              valueBreakdownFrequencyFormControl.valueRef.fiscal_year_period?.period ===
                valueBreakdownFrequency.fiscal_year_period?.period,
          );

          if (existingValueBreakdownFrequencyFormControl) {
            existingValueBreakdownFrequencyFormControl.updateValue(valueBreakdownFrequency as Value);
          }
        });
      }
    });
  }

  public toUpsertValueGroup(values: UpsertValue[]): UpsertValueGroup {
    return {
      id: this.valueGroupRef.id,
      value_definition_group_id: this.valueGroupRef.value_definition_group_id,
      subposition: this.valueGroupRef.subposition,
      values,
    };
  }

  private addValueControls(initialValueGroup: ValueGroup): void {
    initialValueGroup.values?.forEach((value: Value) => this.addValueControl(value));
  }

  private addValueControl(value: Value): void {
    this.addControl(`value-${value.value_definition_id}`, new ValueFormControl(value, this._updateOn));

    if (value.value_definition_frequency && value.value_definition_frequency.frequency != Frequency.YEARLY) {
      value.value_breakdown_frequency?.forEach((value_breakdown) => {
        this.addControl(
          `value-${value.value_definition_id}-${value_breakdown.fiscal_year_period?.period}`,
          new ValueFormControl(value_breakdown as Value, this._updateOn),
        );
      });
    }
  }

  private static sortValueFormControlsByPosition(a: ValueFormControl, b: ValueFormControl): number {
    return a.valueRef.position - b.valueRef.position;
  }
}
