import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-info-banner',
  templateUrl: './lib-info-banner.component.html',
  styleUrls: ['./lib-info-banner.component.scss'],
})
export class LibInfoBannerComponent {
  @Input({ required: true }) message!: string;
  @Input() type: 'warning' | 'info' | 'error' | 'warning-transparent' = 'info';
  @Input() isHtmlText = false;
}
