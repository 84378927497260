import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ActionItem,
  BooleanTypeDetails,
  ConditionalTriggerTarget,
  ConditionalTriggerUpsertPayload,
  DialogResult,
  DocumentTypeDetails,
  Status,
  SubtitleTypeDetails,
  TipTypeDetails,
  TriggerTypeDetails,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionType,
  isVdBooleanType,
  isVdChoiceType,
  isVdDocumentType,
  isVdSubtitleType,
  isVdTipType,
} from '../../../models';
import { ConditionalTriggerForm } from './conditional-trigger-form';
import { TranslateService, HtmlToStringService } from '../../../services/common';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConditionalTriggerDialogData, TargetOption, TargetType } from './models';
import { IsDeactivatedPipe } from '../../pipe/is-deactivated/is-deactivated.pipe';
import { DeactivationUtils } from '../../../classes/DeactivationUtils/deactivation-utils';
import cloneDeep from 'lodash/cloneDeep';
import { OptionListsApiService } from '../../../services/api-services';
import { MetricStructureUtils } from '../../classes/MetricStructureUtils/metric-structure-utils';

@Component({
  selector: 'lib-conditional-trigger-add-edit-dialog',
  templateUrl: './conditional-trigger-add-edit-dialog.component.html',
  styleUrls: ['./conditional-trigger-add-edit-dialog.component.scss'],
  providers: [IsDeactivatedPipe],
})
export class ConditionalTriggerAddEditDialogComponent implements OnInit {
  triggerValueChoiceList$: Observable<string[] | boolean[]> = of([]);
  targetChoiceList: TargetOption[] = [];
  conditionalTriggerForm: ConditionalTriggerForm;

  sourceValueDefinition: ValueDefinition<TriggerTypeDetails>;
  valueDefinitionGroups: ValueDefinitionGroup[];
  eValueDefinitionType = ValueDefinitionType;
  isEditing: boolean = false;
  isAdmin: boolean = false;

  get dialogTitle(): string {
    return this.translateService.instant(this.isEditing ? 'Edit a Conditional Trigger' : 'Add a Conditional Trigger');
  }

  get submitButtonText(): string {
    return this.translateService.instant(this.isEditing ? 'Save' : 'Add trigger');
  }

  get sourceGroup(): ValueDefinitionGroup | undefined {
    return this.valueDefinitionGroups.find(
      (group) => group.id === this.sourceValueDefinition.value_definition_group_id,
    );
  }

  public numberFieldOperators: ActionItem<string>[] = [
    {
      id: '<',
      title: this.translateService.instant('Trigger display if value is less than (<)'),
    },
    {
      id: '≤',
      title: this.translateService.instant('Trigger display if value is less than or equal to (≤)'),
    },
    {
      id: '=',
      title: this.translateService.instant('Trigger display if value is equal to (=)'),
    },
    {
      id: '≥',
      title: this.translateService.instant('Trigger display if value is greater than or equal to (≥)'),
    },
    {
      id: '>',
      title: this.translateService.instant('Trigger display if value is greater than (>)'),
    },
  ];

  constructor(
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ConditionalTriggerDialogData,
    private dialogRef: MatDialogRef<
      ConditionalTriggerAddEditDialogComponent,
      DialogResult<ConditionalTriggerUpsertPayload>
    >,
    private htmlToStringService: HtmlToStringService,
    private optionListsApiService: OptionListsApiService,
  ) {
    this.isEditing = data.conditionalTrigger != null;
    this.sourceValueDefinition = data.sourceValueDefinition;
    this.valueDefinitionGroups = data.valueDefinitionGroups;
    this.isAdmin = data.isAdmin;
    const triggerClone = data.conditionalTrigger ? cloneDeep(data.conditionalTrigger) : undefined;
    if (triggerClone && isVdBooleanType(this.sourceValueDefinition) && data.conditionalTrigger?.values) {
      triggerClone.values = this.mapBooleanValues(
        data.conditionalTrigger.values as boolean[],
        this.sourceValueDefinition.type_details,
      );
    }
    this.conditionalTriggerForm = new ConditionalTriggerForm(triggerClone, data.sourceValueDefinition);
  }

  ngOnInit(): void {
    this.initializeTriggerValueChoiceList();
    this.initializeTargetChoiceList();
  }

  private getBoolValue = (value: boolean, type_details?: BooleanTypeDetails): string =>
    (value ? type_details?.label_true : type_details?.label_false) || String(value);

  private mapBooleanValues = (values: boolean[], type_details?: BooleanTypeDetails): string[] =>
    values.map((v) => this.getBoolValue(v, type_details));

  private initializeTriggerValueChoiceList(): void {
    if (isVdChoiceType(this.sourceValueDefinition)) {
      const choiceTypeDetails = this.sourceValueDefinition.type_details;
      const selectionSetId = choiceTypeDetails?.selection_set_id;
      if (selectionSetId != null) {
        this.triggerValueChoiceList$ = this.optionListsApiService
          .listOptionListItemsFromSource(
            selectionSetId,
            { order_by: 'position', order_by_direction: 'asc' },
            choiceTypeDetails?.selection_set_source,
          )
          .pipe(map((res) => res.data.map((item) => item.name)));
      } else {
        this.triggerValueChoiceList$ = of([]);
      }
    } else if (isVdBooleanType(this.sourceValueDefinition)) {
      const booleanTypeDetails = this.sourceValueDefinition.type_details;
      const trueLabel = booleanTypeDetails?.label_true;
      const falseLabel = booleanTypeDetails?.label_false;
      this.triggerValueChoiceList$ = of(trueLabel && falseLabel ? [trueLabel, falseLabel] : [true, false]);
    }
  }

  private initializeTargetChoiceList(): void {
    this.targetChoiceList = this.sourceGroup ? this.getAvailableTargetOptions(this.sourceGroup) : [];
  }

  private getAvailableTargetOptions(sourceGroup: ValueDefinitionGroup): TargetOption[] {
    return [...this.sameGroupOptions(sourceGroup), ...this.otherGroupsOptions(sourceGroup)];
  }

  private sameGroupOptions(vdg: ValueDefinitionGroup): TargetOption[] {
    const visibleVdIds = MetricStructureUtils.getFieldIdsVisibleInAtLeastOneFy(
      this.data.fieldVisibility,
    ).valueDefinitionIds;
    return (
      vdg.value_definitions
        ?.filter(
          (vd) =>
            vd.position > this.sourceValueDefinition.position &&
            ((!this.isAdmin && visibleVdIds.includes(vd.id)) || this.isAdmin),
        )
        .map((vd) => this.targetOption(vdg, vd)) ?? []
    );
  }

  private otherGroupsOptions(sourceVdg: ValueDefinitionGroup): TargetOption[] {
    return this.valueDefinitionGroups
      .filter((vdg) => vdg.position > sourceVdg.position && vdg.table_id == null)
      .flatMap((vdg) => [
        this.targetOption(vdg),
        ...(vdg.value_definitions?.map((vd) => this.targetOption(vdg, vd)) ?? []),
      ]);
  }

  private targetOption(vdg: ValueDefinitionGroup, vd?: ValueDefinition): TargetOption {
    return {
      id: vd?.id ?? vdg.id,
      type: vd ? TargetType.ValueDefinition : TargetType.ValueDefinitionGroup,
      name: this.getOptionDisplayName(this.optionLabel(vdg, vd), vd || vdg),
    };
  }

  private getOptionDisplayName(label: string, option: ValueDefinition | ValueDefinitionGroup): string {
    if (!DeactivationUtils.isDeactivated(option)) {
      return label;
    }
    return `${label} ${this.translateService.instant('(Inactive from Fiscal Year {fiscalYear})', {
      fiscalYear: option.end_fiscal_year_frequency_code,
    })}`;
  }

  private optionLabel(vdg: ValueDefinitionGroup, vd?: ValueDefinition): string {
    return `${this.vdgLabel(vdg)}${this.fieldOptionLabel(vd)}`;
  }

  private vdgLabel(vdg: ValueDefinitionGroup): string {
    return this.translateService.instant('Group: [{label}]', {
      label: vdg.label || this.translateService.instant('Group {iteration}', { iteration: vdg.position }),
    });
  }

  private fieldOptionLabel(vd?: ValueDefinition): string {
    return vd ? this.translateService.instant(', Field: [{label}]', { label: this.vdLabel(vd) }) : '';
  }

  private vdLabel(vd: ValueDefinition): string {
    if (isVdTipType(vd)) {
      return this.tipLabel(vd);
    }
    if (isVdSubtitleType(vd)) {
      return this.subtitleLabel(vd);
    }
    if (isVdDocumentType(vd)) {
      return this.docLabel(vd);
    }
    return vd.label ?? this.translateService.instant('Unknown');
  }

  private tipLabel(vd: ValueDefinition<TipTypeDetails>) {
    return this.translateService.instant('Instructions: {instructionLabel}', {
      instructionLabel: this.htmlToStringService.convertTipRichTextToPlain(vd.type_details?.value ?? '', 25),
    });
  }

  private subtitleLabel(vd: ValueDefinition<SubtitleTypeDetails>): string {
    return this.translateService.instant('Subtitle: {subtitleLabel}', {
      subtitleLabel: vd.type_details?.value ?? '',
    });
  }

  private docLabel(vd: ValueDefinition<DocumentTypeDetails>): string {
    return this.translateService.instant('Document Link: {docLabel}', {
      docLabel: vd.document?.name ?? this.translateService.instant('Unknown'),
    });
  }

  targetOptionToConditionTriggerTarget(targetOption: TargetOption): Partial<ConditionalTriggerTarget> {
    if (targetOption.type == TargetType.ValueDefinition) {
      return { value_definition_id: targetOption.id };
    } else {
      return { value_definition_group_id: targetOption.id };
    }
  }

  compareConditionalTriggerTarget(target1: ConditionalTriggerTarget, target2: ConditionalTriggerTarget): boolean {
    return (
      target1.value_definition_id == target2.value_definition_id &&
      target1.value_definition_group_id == target2.value_definition_group_id
    );
  }

  submit(): void {
    this.dialogRef.close({ status: Status.SUCCESS, data: this.conditionalTriggerForm.toModel() });
  }

  closeDialog(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }
}
