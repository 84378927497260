import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ValueDefinitionFieldType } from '../../models';

export const ValueDefinitionFieldTypeToText: Record<ValueDefinitionFieldType, string> = {
  [ValueDefinitionFieldType.number]: _('Number'),
  [ValueDefinitionFieldType.calculated]: _('Calculated'),
  [ValueDefinitionFieldType.boolean]: _('Boolean'),
  [ValueDefinitionFieldType.choice]: _('Choice'),
  [ValueDefinitionFieldType.table]: _('Table'),
  [ValueDefinitionFieldType.table_total]: _('Table Total'),
  [ValueDefinitionFieldType.text]: _('Text'),
  [ValueDefinitionFieldType.textarea]: _('Text Area'),
  [ValueDefinitionFieldType.rich_text]: _('Rich Text'),
  [ValueDefinitionFieldType.date]: _('Date'),
  [ValueDefinitionFieldType.file]: _('File'),
  [ValueDefinitionFieldType.file_v2]: _('File V2'),
  [ValueDefinitionFieldType.repeatable_group]: _('Repeatable Group'),
  [ValueDefinitionFieldType.tip]: _('Tip'),
  [ValueDefinitionFieldType.subtitle]: _('Subtitle'),
  [ValueDefinitionFieldType.document]: _('Document'),
  [ValueDefinitionFieldType.metric]: _('Metric'),
};
