<div
  *ngIf="control"
  libFormField
  [size]="size"
  [required]="required"
  [control]="control"
  [readonly]="readonly"
  [removePadding]="removePadding"
  [labelPosition]="labelPosition"
  [dataTestId]="'text-input'"
>
  <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
  <mat-form-field appearance="fill" [subscriptSizing]="subscriptSizing">
    <span *ngIf="prefix" class="color-grey-800 mr-1" matPrefix>{{ prefix }}</span>
    <input
      #input
      matInput
      [type]="type"
      [placeholder]="placeholder"
      [formControl]="control"
      [id]="_inputId"
      [name]="_inputId"
      [attr.aria-label]="_inputId"
      #focusElement
      libFocusState
      libStripNonPrintableCharacters
      [libTrimWhitespace]="trimWhitespaceMethod"
      [readonly]="readonly"
      [libAutoFocusInput]="autofocus"
      data-testid="text-input-input"
      (blur)="handleBlur.emit()"
      [autocomplete]="autocomplete"
    />
    <span *ngIf="suffix" matSuffix data-testid="inputSuffix">{{ suffix }}</span>
    <a data-testid="clear-text" matSuffix *ngIf="clearText && control.value" (click)="control.setValue('')">
      <mat-icon class="icon-sm" svgIcon="close"> </mat-icon>
    </a>
    <a
      data-testid="toggle-password"
      matSuffix
      *ngIf="togglePassword && control.status !== 'PENDING'"
      (click)="togglePwdType()"
      class="mat-icon-formfield"
    >
      <mat-icon *ngIf="type === 'password'" svgIcon="show"></mat-icon>
      <mat-icon *ngIf="type === 'text'" svgIcon="hide"></mat-icon>
    </a>
    <mat-hint *ngIf="hint || maxCharacterCount" class="fx-row justify-space-between align-baseline">
      <span *ngIf="hint">{{ hint }}</span>
      <span *ngIf="maxCharacterCount"> {{ control.value?.length ?? 0 }}/{{ maxCharacterCount }}</span>
    </mat-hint>
    <lib-spinner *ngIf="control.status === 'PENDING'" class="pv-0" [diameter]="16" matSuffix></lib-spinner>
    <mat-error *ngFor="let error of control.errors | errorKeys">
      {{ errorMessages[error] }}
      {{
        error === "maxlength" && maxCharacterCount
          ? ("({length}/{maxLength})" | translate: { length: control.value?.length ?? 0, maxLength: maxCharacterCount })
          : ""
      }}
    </mat-error>
  </mat-form-field>
</div>
