import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
})
export class SimpleListComponent {
  @Input() listTitle?: string;
  @Input({ required: true }) listItems!: string[];
}
