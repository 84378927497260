import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, finalize, map, switchMap, throwError } from 'rxjs';
import { AuthApiService, CacheService, CookieService, LocalStorageUtils, ObservableUtils } from '@novisto/common';

import { addTokenHeader } from '../../utilities/auth-utils';
import { AuthPathResolverService } from '../../../auth/auth-path-resolver.service';
import { OauthToken, LocalClientCodes, OauthUser, PublicUser, Permission } from '../../models';
import { TokenService } from '../token/token.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends AuthApiService {
  private static readonly LOCAL_CLIENT_CODE = 'novisto';
  private static readonly STORAGE_KEY = 'user';

  private isRefreshingToken: boolean = false;

  private _token$: BehaviorSubject<OauthToken | null> = new BehaviorSubject<OauthToken | null>(null);
  private _user$: BehaviorSubject<OauthUser | null> = new BehaviorSubject<OauthUser | null>(null);

  constructor(
    private authPathResolverService: AuthPathResolverService,
    private cookieService: CookieService,
    private cacheService: CacheService,
    private router: Router,
    private tokenService: TokenService,
  ) {
    super();
  }

  public get user(): OauthUser | null {
    if (!this._user$.getValue()) {
      this._user$.next(LocalStorageUtils.getFromStorage<OauthUser>(AuthService.STORAGE_KEY) || null);
    }

    return this._user$.getValue();
  }

  public get token(): OauthToken | null {
    return this._token$.getValue();
  }

  public getUserPermissions(): Observable<Permission[] | null> {
    return this._user$.asObservable().pipe(map((user) => user?.permissions || null));
  }

  public isLoggedIn(): boolean {
    if (this.accessTokenExpired()) {
      this.logout();
    }

    return Boolean(this.user);
  }

  public logout(): void {
    if (this.user) {
      this.tokenService.invalidateTokens(this.user.access_token).subscribe();
    }

    this.cacheService.clearAll();
    LocalStorageUtils.removeFromStorage(AuthService.STORAGE_KEY);
    this.cookieService.deleteCookie('auth');
    this._token$.next(null);
    this._user$.next(null);
    this.router.navigateByUrl(this.authPathResolverService.auth());
  }

  public refreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this._token$.next(null);

      if (!this.user) {
        this.logout();
      } else {
        this.tokenService
          .refreshToken({ refresh_token: this.user.refresh_token })
          .pipe(
            finalize(() => {
              this.isRefreshingToken = false;
            }),
          )
          .subscribe({
            next: (response: OauthToken) => {
              this.saveToken(response);

              return next.handle(addTokenHeader(request, response));
            },
            error: (error: unknown) => {
              this.logout();
              return throwError(() => error);
            },
          });
      }
    }

    return this._token$.pipe(
      ObservableUtils.filterNullish(),
      switchMap((token: OauthToken) => next.handle(addTokenHeader(request, token))),
    );
  }

  public saveToken(token: OauthToken): void {
    const tokenParts = token.access_token.split('.');

    if (tokenParts.length < 2) {
      throw new Error('Invalid token');
    }

    this._token$.next(token);

    if (this.user) {
      this.setOauthUser({ ...this.user, ...token, exp: Math.floor(Date.now() / 1000) + token.expires_in });
    }
  }

  public saveUser(user: PublicUser): void {
    const token = this._token$.value;

    if (!token) {
      throw new Error('Token not found');
    }

    this.setOauthUser({
      ...token,
      ...user,
      permissions: [],
      client_code: user.client_code === AuthService.LOCAL_CLIENT_CODE ? LocalClientCodes.NOVISTO : user.client_code,
      exp: Math.floor(Date.now() / 1000) + token.expires_in,
      roles: [],
    });
  }

  public setUserPermissions(permissions: Permission[]): boolean {
    const user = this._user$.value;

    if (!user || !permissions.includes(Permission.ADD_IN_VIEW)) {
      this.logout();
      return false;
    }

    this.setOauthUser({ ...user, permissions });

    return true;
  }

  public accessTokenExpired(): boolean {
    return this.user ? this.user.exp < Math.floor(Date.now() / 1000) : false;
  }

  private setOauthUser(oauthUser: OauthUser): void {
    LocalStorageUtils.addToStorage(AuthService.STORAGE_KEY, oauthUser);
    this.cookieService.setCookie('auth', 'true', 1, 'novisto.net');
    this._user$.next(oauthUser);
  }
}
