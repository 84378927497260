import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabStepperStep } from '../../../models';

@Component({
  selector: 'lib-tab-stepper',
  templateUrl: './tab-stepper.component.html',
  styleUrls: ['./tab-stepper.component.scss'],
})
export class TabStepperComponent {
  @Input() selectedTab = 0;
  @Input() steps: TabStepperStep[] = [];

  @Output() selectedIndexChange = new EventEmitter<number>();
}
