import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from '../../models';
import { ObservableUtils } from '../../classes';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthApiService } from '../../services/api-services';

@Directive({
  selector: '[libPermission]',
})
export class PermissionDirective implements OnInit, OnDestroy {
  @Input() libPermission?: Permission[];
  @Input() libPermissionElse?: TemplateRef<unknown>;

  private isHidden: boolean = true;
  private permissionDirective$?: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthApiService,
  ) {}

  ngOnInit() {
    this.permissionDirective$ = this.authService
      .getUserPermissions()
      .pipe(
        ObservableUtils.filterNullish(),
        map((permissions: Permission[]) => this.updateView(permissions)),
      )
      .subscribe();
  }

  private hasPermission(userPermissions: Permission[]): boolean {
    if (!this.libPermission?.length) {
      return true;
    }

    return this.libPermission.some((requiredPermission: Permission) => userPermissions.includes(requiredPermission));
  }

  private updateView(userPermissions: Permission[]) {
    if (this.hasPermission(userPermissions)) {
      if (this.isHidden) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else if (this.libPermissionElse) {
      this.viewContainerRef.createEmbeddedView(this.libPermissionElse);
    } else {
      this.viewContainerRef.clear();
      this.isHidden = true;
    }
  }

  ngOnDestroy(): void {
    this.permissionDirective$?.unsubscribe();
  }
}
