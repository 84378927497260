export class OAUTHStateExpiredOrInvalidException extends Error {
  constructor() {
    super('OAuth request has timed out or is invalid, please try again.');
  }
}

export class OAUTHMissingEmailException extends Error {
  constructor() {
    super('Could not find the user email, may be caused by an incorrect configuration of the provider');
  }
}

export class OAUTHUserNotFoundException extends Error {
  constructor() {
    super(`No user found for the user's provider account`);
  }
}

export class OAUTHInvalidGrantException extends Error {
  constructor() {
    super('OAuth invalid grant, code already used or expired, please try to login again');
  }
}

export class OAUTHInvalidClientException extends Error {
  constructor() {
    super('OAuth invalid client, client is incorrectly configured or secret is expired');
  }
}

export class OAUTHFetchUserInfoException extends Error {
  constructor() {
    super('Could not fetch user info due to a provider error');
  }
}

export class OAUTHUserInfoFormatException extends Error {
  constructor() {
    super('User info received from provider is invalid');
  }
}

export class SAMLProviderNotFoundException extends Error {
  constructor() {
    super('Could not find a SAML provider for the provided ID');
  }
}

export class SAMLProviderDisabledException extends Error {
  constructor() {
    super('The SAML provider is disabled');
  }
}

export class SAMLUserNotFoundException extends Error {
  constructor() {
    super(`No user found for the user's provider account`);
  }
}

export class SPInvalidCredentialsException extends Error {
  constructor() {
    super(`Username and password do not match S&P Portal credentials.`);
  }
}
