<a
  *ngIf="routerLink; else externalLink"
  [routerLink]="routerLink"
  [fragment]="fragment"
  [target]="openInNewTab ? '_blank' : '_self'"
  [attr.aria-label]="text"
  class="link"
  >{{ text }}
  <mat-icon *ngIf="openInNewTab" svgIcon="external" data-testid="openInNewTabIcon"></mat-icon>
</a>

<ng-template #externalLink>
  <a class="link" [attr.aria-label]="text" [href]="url" [target]="openInNewTab ? '_blank' : '_self'">
    {{ text }}
    <mat-icon *ngIf="openInNewTab" svgIcon="external" data-testid="openInNewTabIcon"></mat-icon>
  </a>
</ng-template>
