import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActionItem } from '../../../models';

@Component({
  selector: 'lib-card-primary',
  templateUrl: './card-primary.component.html',
  styleUrls: ['./card-primary.component.scss'],
})
export class CardPrimaryComponent {
  @Input() item?: ActionItem;
  @Output() action: EventEmitter<void> = new EventEmitter<void>();
}
