import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ActionItem, ApiResponse, Metric, MetricLinkTypeDetails, Source } from '../../../models';
import { ValueFormControl } from '../../models/valueFormControl';
import { MetricApiService } from '../../../services/types';

@Component({
  selector: 'lib-metric-editor-metric-link',
  templateUrl: './metric-editor-metric-link.component.html',
  styleUrls: ['./metric-editor-metric-link.component.scss'],
})
export class MetricEditorMetricLinkComponent implements OnInit {
  @Input() valueFormControl?: ValueFormControl<MetricLinkTypeDetails>;

  @Output() metricLinkEdit: EventEmitter<string> = new EventEmitter<string>();

  metric?: Metric;
  source?: ActionItem<Source>;
  constructor(private metricsService: MetricApiService) {}

  ngOnInit(): void {
    this.getMetric();
  }

  onEdit(): void {
    this.metricLinkEdit.emit(this.valueFormControl?.valueRef.type_details.indicator_id);
  }

  private getMetric(): void {
    if (this.valueFormControl) {
      this.metricsService
        .getMetric(this.valueFormControl.valueRef.type_details.metric_id)
        .pipe(
          tap((metric: ApiResponse<Metric>) => {
            this.metric = metric.data;
          }),
        )
        .subscribe();
    }
  }
}
