import { Component, OnInit, Input } from '@angular/core';
import { Icon } from '../../../icons/icons.model';
import { Value, TipTypeDetailsIcon, TipIcon } from '../../../models';

@Component({
  selector: 'lib-data-formats-tip',
  templateUrl: './data-formats-tip.component.html',
  styleUrls: ['./data-formats-tip.component.scss'],
})
export class DataFormatsTipComponent implements OnInit {
  @Input() value?: Value;
  iconInfo = TipIcon.none;

  ngOnInit(): void {
    if (this.value?.type_details?.icon) {
      this.iconInfo = this.getIcon(String(this.value?.type_details.icon));
    }
  }

  private getIcon(iconType: string): { icon: Icon; color: string } {
    if (iconType in TipTypeDetailsIcon) {
      return TipIcon[<TipTypeDetailsIcon>iconType];
    } else {
      throw Error(`${iconType} is not part of TipIcon`);
    }
  }
}
