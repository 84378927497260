import { Component, OnInit, Input } from '@angular/core';
import { ValueFormControl } from '../../models/valueFormControl';
import { TipIcon, TipIconConfig, TipTypeDetails } from '../../../models';

@Component({
  selector: 'lib-metric-editor-tip',
  templateUrl: './metric-editor-tip.component.html',
  styleUrls: ['./metric-editor-tip.component.scss'],
})
export class MetricEditorTipComponent implements OnInit {
  @Input({ required: true }) valueFormControl!: ValueFormControl<TipTypeDetails>;

  iconConfig: TipIconConfig = TipIcon.none;
  tipText: string = '';

  ngOnInit(): void {
    this.iconConfig = TipIcon[this.valueFormControl.valueRef.type_details.icon];
    this.tipText = this.valueFormControl.valueRef.type_details.value;
  }
}
