import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { ActionItem, DataRequestStatus, PageActions, ViewerActionType } from '../../models';
import { AlertsService, TranslateService, EventsService } from '../../services/common';

@Component({
  selector: 'lib-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements AfterViewInit, OnChanges {
  @Input() title: string = '';
  @Input() subtitles?: string[] | string;
  @Input() useChipsSubtitles: boolean = false;
  @Input() editable: boolean = false;
  @Input() actions: PageActions<any> = {};
  @Input() tabs: ActionItem[] = [];
  @Input() disableHelp: boolean = true;
  @Input() preserveQueryParams: boolean = false;
  @Input() class: string = '';
  @Input() set levelsUp(i: number) {
    this.parentLink = '../'.repeat(i);
  }
  @Input() progress?: { current: number; total: number; percent?: number };
  @Input() characterLimit?: number;
  @Input() withNavigation: boolean = true;

  @Output() action = new EventEmitter<ActionItem>();

  @ViewChild('titleInput') titleInput?: ElementRef;
  @ViewChild(MatMenuTrigger) menu?: MatMenuTrigger;
  menuOpened: boolean = false;

  currentTitle: string = '';
  titleEditing: boolean = false;
  parentLink: string = '../';
  viewerAction: typeof ViewerActionType = ViewerActionType;
  eStatus = DataRequestStatus;

  constructor(
    private eventsService: EventsService,
    private alertsService: AlertsService,
    private translateService: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    if (this.menu) {
      this.menu.menuOpened.subscribe(() => {
        this.menuOpened = true;
      });
      this.menu.menuClosed.subscribe(() => {
        this.menuOpened = false;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title) {
      this.currentTitle = changes.title.currentValue.toString();
    }
    if (this.progress) {
      this.progress.percent = (this.progress.current / this.progress.total) * 100;
    }
  }

  openMenu(): void {
    this.eventsService.setMessage('main_menu', 'open');
  }

  edit(): void {
    if (this.editable) {
      this.titleEditing = true;
      setTimeout(() => {
        const inputElement: HTMLInputElement = this.titleInput!.nativeElement as HTMLInputElement;
        inputElement.focus();
      });
    }
  }

  keyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Escape':
        this.cancel(event);
        break;
      case 'Enter':
        this.save(event);
        break;
      default:
        break;
    }
  }

  cancel(event: MouseEvent | KeyboardEvent): void {
    this.currentTitle = this.title;
    this.titleEditing = false;
    event.preventDefault();
    event.stopPropagation();
  }

  save(event: MouseEvent | KeyboardEvent): void {
    if (this.characterLimit && this.currentTitle.length > this.characterLimit) {
      this.currentTitle = this.title;
      this.alertsService.error(
        this.translateService.instant('Report name cannot have more than {limit} characters.', {
          limit: this.characterLimit,
        }),
      );
    } else {
      if (this.currentTitle) {
        this.title = this.currentTitle;
        this.action.emit({
          id: 'title',
          title: this.title,
        });
      } else {
        this.currentTitle = this.title;
        this.action.emit({
          id: 'title',
          title: '',
        });
      }
      this.titleEditing = false;
      event.preventDefault();
      event.stopPropagation();
    }
  }

  setAction(action?: ActionItem): void {
    if (action) {
      this.action.emit(action);
    }
  }
}
