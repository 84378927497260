import { Pipe, PipeTransform } from '@angular/core';
import DOMPurify from 'dompurify';
import { StringUtils } from '../../classes/StringUtils/string-utils';

@Pipe({
  name: 'sanitizeHtml',
})
export class SanitizeHtmlPipe implements PipeTransform {
  transform(htmlString?: string | null): string {
    if (!htmlString) {
      return '';
    }

    return StringUtils.htmlDecode(DOMPurify.sanitize(htmlString));
  }
}
