import { NgModule } from '@angular/core';
import { TemplateReportStructureComponent } from './template-report-structure.component';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directives';
import { DialogsModule } from '../dialogs';
import { PanelsModule } from '../panels';
import { MaterialModule } from '../material/material.module';
import { SearchModule } from '../search';
import { TemplateReportStructureHeaderComponent } from './template-report-structure-header/template-report-structure-header.component';
import { TemplateReportStructurePanelComponent } from './template-report-structure-panel/template-report-structure-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateReportPanelSettingsComponent } from './template-report-structure-panel/template-report-panel-settings/template-report-panel-settings.component';
import { TemplateReportStructureSectionsComponent } from './template-report-structure-sections/template-report-structure-sections.component';
import { TemplateReportPanelSectionFormComponent } from './template-report-structure-panel/template-report-panel-section-form/template-report-panel-section-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TemplateReportStructureLeftPanelComponent } from './template-report-structure-left-panel/template-report-structure-left-panel.component';
import { TemplateNamePipe } from './pipes/template-name.pipe';
import { CdkModule } from '../material/cdk.module';
import { TemplateReportStructureSectionMetricComponent } from './template-report-structure-sections/template-report-structure-section-metric/template-report-structure-section-metric.component';
import { TemplateReportPanelSectionMetricFormComponent } from './template-report-structure-panel/template-report-panel-section-metric-form/template-report-panel-section-metric-form.component';
import { TemplateReportStructureDisplayedValuesDisplayComponent } from './template-report-structure-panel/template-report-structure-displayed-values/template-report-structure-displayed-values-display/template-report-structure-displayed-values-display.component';
import { TemplateReportStructureDisplayedValuesDialogComponent } from './template-report-structure-panel/template-report-structure-displayed-values/template-report-structure-displayed-values-form/template-report-structure-displayed-values-dialog.component';

@NgModule({
  declarations: [
    TemplateReportStructureComponent,
    TemplateReportStructureHeaderComponent,
    TemplateReportStructurePanelComponent,
    TemplateReportPanelSettingsComponent,
    TemplateReportPanelSectionFormComponent,
    TemplateReportStructureSectionsComponent,
    TemplateReportStructureLeftPanelComponent,
    TemplateReportStructureSectionMetricComponent,
    TemplateReportPanelSectionMetricFormComponent,
    TemplateReportStructureDisplayedValuesDisplayComponent,
    TemplateReportStructureDisplayedValuesDialogComponent,
    TemplateNamePipe,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    DialogsModule,
    PanelsModule,
    MaterialModule,
    SearchModule,
    ReactiveFormsModule,
    CdkModule,
  ],
  exports: [TemplateReportStructureComponent],
})
export class TemplateReportStructureModule {}
