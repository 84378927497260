import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';

import { DataTableExpandedRowConfig } from '../models';
import { DynamicComponentLoaderDirective } from '../../../directives';

@Component({
  selector: 'lib-data-table-expanded-row',
  templateUrl: './data-table-expanded-row.component.html',
})
export class DataTableExpandedRowComponent extends DynamicComponentLoaderDirective implements AfterViewInit, OnDestroy {
  @Input() row: any;
  @Input() config?: DataTableExpandedRowConfig;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.loadExpandedRowComponent();
  }

  ngOnDestroy(): void {
    this.destroyDynamicComponent();
  }

  public loadExpandedRowComponent(): void {
    if (this.config?.component) {
      const component = this.config.component;
      const componentProperties: { [key: string]: unknown } = {};

      this.config.componentProperties.forEach((property) => {
        if (property.inputName) {
          if (property.rowPropertyName) {
            componentProperties[property.inputName] = this.row[property.rowPropertyName];
          } else if ('value' in property) {
            componentProperties[property.inputName] = property.value;
          } else {
            componentProperties[property.inputName] = this.row;
          }
        }
      });

      setTimeout(() => {
        this.loadDynamicComponent(component, componentProperties);
      });
    }
  }
}
