import { Pipe, PipeTransform } from '@angular/core';
import { ValueDefinitionGroup, ValueDefinitionType } from '../../../../../models';

@Pipe({
  name: 'filterUncalculableGroups',
})
export class FilterUncalculableGroupsPipe implements PipeTransform {
  transform(
    groups?: ValueDefinitionGroup[],
    visibleMetricTableIds: string[] = [],
    isAdmin = false,
    isCurrentMetric = false,
  ): ValueDefinitionGroup[] {
    return (
      groups?.filter((group) => {
        if (
          group.repeatable ||
          (!isAdmin && isCurrentMetric && group.table_id && !visibleMetricTableIds.includes(group.table_id))
        ) {
          return false;
        }

        return group.table_id
          ? group.value_definitions?.some((valueDef) => valueDef.type === ValueDefinitionType.calculated)
          : group;
      }) ?? []
    );
  }
}
