import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiResponse, ApplicationApiDefinition, Topic, TopicCategory } from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';
import { TopicsApiService } from '../../api-services/topics-api-service/topics-api.service';

@Injectable({ providedIn: 'root' })
export class AdminTopicService extends TopicsApiService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.topics;
  }

  public listTopicCategories(): Observable<ApiResponse<TopicCategory[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/topics`);
  }

  public listTopics(): Observable<ApiResponse<Topic[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/topics`);
  }
}
