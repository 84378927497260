import { Pipe, PipeTransform } from '@angular/core';
import { Metric, MetricCategory, ValueDefinitionGroup } from '../../models';

@Pipe({
  name: 'canAddField',
})
export class CanAddFieldPipe implements PipeTransform {
  constructor() {}

  transform(isCore: boolean, metric?: Metric): boolean {
    if (metric?.category === MetricCategory.THIRD_PARTY && !isCore) {
      return false;
    }

    if (metric?.category === MetricCategory.REFERENCE && metric.reference_v2 && !isCore) {
      // is custom group
      return Boolean(
        metric.value_definition_groups?.some(
          (group: ValueDefinitionGroup) => group.table_id === null && group.core_value_definition_group_id === null,
        ),
      );
    }

    // is there any group be it in core or platform
    return Boolean(metric?.value_definition_groups?.some((group: ValueDefinitionGroup) => group.table_id === null));
  }
}
