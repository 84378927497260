import { Pipe, PipeTransform } from '@angular/core';
import { DeactivationUtils } from '../../../classes/DeactivationUtils/deactivation-utils';
import { ValueDefinition, ValueDefinitionGroup } from '../../../models';
import { MetricTableGroup } from '../../models';

@Pipe({
  name: 'isDeactivated',
})
export class IsDeactivatedPipe implements PipeTransform {
  transform(element?: ValueDefinition | ValueDefinitionGroup | MetricTableGroup): boolean {
    return DeactivationUtils.isDeactivated(element);
  }
}
