import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

import { DateUtils } from '../../../classes';
import { Org, OrgFiscalYearInfo } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class PeersService {
  constructor() {}

  public getOrgDescription(org: Org): string {
    let description = '';

    if (org.industries?.length) {
      description += org.industries.map((i) => i.name).join(', ');
    }

    if (org.fiscal_year_information) {
      description += ' — ';
      description += this.getFiscalYearDescription(org.fiscal_year_information);
    }

    return description;
  }

  public getFiscalYearDescription(fiscalYearInformation: OrgFiscalYearInfo): string {
    let description = '';
    switch (fiscalYearInformation.regular) {
      case null:
        description += 'No fiscal year information';
        break;
      case true:
        const startDate = DateUtils.dateParse(fiscalYearInformation.start);
        const endDate = this.getPeriodEnd(fiscalYearInformation.start);
        description += `${formatDate(startDate, 'MMM d', 'en-CA')} to ${formatDate(endDate, 'MMM d', 'en-CA')}`;
        break;
      case false:
        description += 'Irregular fiscal year';
        break;
    }
    return description;
  }

  getPeriodEnd(startDateString: string): Date {
    const end = DateUtils.dateParse(startDateString);
    end.setFullYear(end.getFullYear() + 1);
    end.setDate(end.getDate() - 1);
    return end;
  }
}
