import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, startWith, catchError } from 'rxjs/operators';

interface Loading<T> {
  loading: boolean;
  value?: T;
  error?: unknown;
}

@Pipe({
  name: 'withLoading',
})
export class WithLoadingPipe implements PipeTransform {
  transform<T>(obs: Observable<T>): Observable<Loading<T>> {
    return obs.pipe(
      map((value: T) => ({ loading: false, value })),
      startWith({ loading: true }),
      catchError((error: unknown) => of({ loading: false, error })),
    );
  }
}
