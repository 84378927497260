import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BooleanTypeDetails,
  ConditionalTrigger,
  ConditionalTriggerTarget,
  DocumentTypeDetails,
  SubtitleTypeDetails,
  TipTypeDetails,
  TriggerTypeDetails,
  ValueDefinition,
  ValueDefinitionGroup,
  isVdBooleanType,
  isVdDocumentType,
  isVdNumberType,
  isVdSubtitleType,
  isVdTipType,
} from '../../../../models';
import { HtmlToStringService, TranslateService } from '../../../../services/common';

@Component({
  selector: 'lib-conditional-trigger-card',
  templateUrl: './conditional-trigger-card.component.html',
  styleUrls: ['./conditional-trigger-card.component.scss'],
})
export class ConditionalTriggerCardComponent {
  @Input({ required: true }) conditionalTrigger!: ConditionalTrigger;
  @Input({ required: true }) valueDefinitionGroups!: ValueDefinitionGroup[];
  @Input({ required: true }) sourceValueDefinition!: ValueDefinition<TriggerTypeDetails>;
  @Input() disabled: boolean = false;
  @Output() menuClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  get conditionalTriggerValuesText(): string {
    return this.triggerValuesText();
  }

  get conditionalTriggerTargetLabels(): string {
    return this.conditionalTrigger.targets.map((target) => this.getTargetLabel(target)).join(', ');
  }

  get allValueDefinitions(): ValueDefinition[] {
    return this.valueDefinitionGroups.flatMap((vdg) => vdg.value_definitions ?? []);
  }

  constructor(
    private translateService: TranslateService,
    private htmlToStringService: HtmlToStringService,
  ) {}

  private triggerValuesText(): string {
    if (Array.isArray(this.conditionalTrigger.values)) {
      return this.conditionalTrigger.values
        .map((v) => {
          if (isVdBooleanType(this.sourceValueDefinition)) {
            return this.getBoolValue(Boolean(v), this.sourceValueDefinition.type_details);
          }
          return v;
        })
        .join(', ');
    }
    if (isVdNumberType(this.sourceValueDefinition)) {
      return `${String(this.conditionalTrigger.operator)} ${String(this.conditionalTrigger.values)}`;
    }

    return this.conditionalTrigger.values;
  }

  private getBoolValue(value: boolean, type_details?: BooleanTypeDetails): string {
    return (value ? type_details?.label_true : type_details?.label_false) || String(value);
  }

  private getTargetLabel(target: ConditionalTriggerTarget): string {
    if (target.value_definition_group_id) {
      const group = this.findTargetGroup(target.value_definition_group_id);
      if (group) {
        return this.groupLabel(group);
      }
    } else if (target.value_definition_id) {
      const targetVd = this.findTargetVd(target.value_definition_id);
      if (targetVd) {
        return this.groupFieldLabel(targetVd.value_definition_group, targetVd);
      }
    }
    return this.translateService.instant('Unknown');
  }

  private findTargetGroup(targetId: string): ValueDefinitionGroup | undefined {
    return this.valueDefinitionGroups.find((vdg) => vdg.id === targetId);
  }

  private findTargetVd(
    targetId: string,
  ): (ValueDefinition & { value_definition_group: ValueDefinitionGroup }) | undefined {
    for (const vdg of this.valueDefinitionGroups) {
      const vd = vdg.value_definitions?.find((v) => v.id === targetId);
      if (vd) {
        return { ...vd, value_definition_group: vdg };
      }
    }
    return undefined;
  }

  private groupFieldLabel(vdg: ValueDefinitionGroup, vd?: ValueDefinition): string {
    return `${this.groupLabel(vdg)}${this.fieldLabel(vd)}`;
  }

  private groupLabel(vdg: ValueDefinitionGroup): string {
    return this.translateService.instant('Group: [{label}]', {
      label: vdg.label || this.translateService.instant('Group {iteration}', { iteration: vdg.position }),
    });
  }

  private fieldLabel(vd?: ValueDefinition): string {
    return vd ? this.translateService.instant(', Field: [{label}]', { label: this.vdLabel(vd) }) : '';
  }

  private vdLabel(vd: ValueDefinition): string {
    if (isVdTipType(vd)) {
      return this.tipLabel(vd);
    }
    if (isVdSubtitleType(vd)) {
      return this.subtitleLabel(vd);
    }
    if (isVdDocumentType(vd)) {
      return this.docLabel(vd);
    }
    return vd.label ?? this.translateService.instant('Unknown');
  }

  private tipLabel(vd: ValueDefinition<TipTypeDetails>) {
    return this.translateService.instant('Instructions: {instructionLabel}', {
      instructionLabel: this.htmlToStringService.convertTipRichTextToPlain(vd.type_details?.value ?? '', 25),
    });
  }

  private subtitleLabel(vd: ValueDefinition<SubtitleTypeDetails>): string {
    return this.translateService.instant('Subtitle: {subtitleLabel}', {
      subtitleLabel: vd.type_details?.value ?? '',
    });
  }

  private docLabel(vd: ValueDefinition<DocumentTypeDetails>): string {
    return this.translateService.instant('Document Link: {docLabel}', {
      docLabel: vd.document?.name ?? this.translateService.instant('Unknown'),
    });
  }
}
