import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { OptionListItemForm } from './option-list-item-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-option-list-item-form',
  templateUrl: './option-list-item-form.component.html',
  styleUrls: ['./option-list-item-form.component.scss'],
})
export class OptionListItemFormComponent implements OnInit, OnDestroy {
  @Input({ required: true }) form!: OptionListItemForm;
  @Input() withTagFields: boolean = false;

  private subscription?: Subscription;

  public ngOnInit(): void {
    this.subscription = this.form.controls.displayExplanation.valueChanges.subscribe((displayExplanation) =>
      this.handleExplanationRequiredField(displayExplanation),
    );
    this.handleExplanationRequiredField();
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private handleExplanationRequiredField(
    displayExplanation: boolean = this.form.controls.displayExplanation.value,
  ): void {
    if (displayExplanation) {
      this.form.controls.explanationRequired.enable();
    } else {
      this.form.controls.explanationRequired.disable();
      this.form.controls.explanationRequired.setValue(false);
    }
  }
}
