import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';

import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { LANGUAGES } from '../models';
import { TranslateService, CustomTranslateLoader } from '../services/common';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: { provide: TranslateLoader, useClass: CustomTranslateLoader },
    }),
  ],
  exports: [TranslateModule],
})
export class CommonTranslateModule {
  constructor(private readonly translateService: TranslateService) {
    this.translateService.addLangs(Object.values(LANGUAGES));
    this.translateService.setDefaultLang(LANGUAGES.EN);
    this.translateService.use(LANGUAGES.EN);
  }
}
