<div mat-dialog-actions *ngIf="shouldDisplayActions()">
  <a
    role="button"
    class="btn btn-success"
    [ngClass]="{ 'disabled btn-disabled': disable }"
    (click)="confirmEvent.emit()"
  >
    {{ buttonText }}
  </a>
</div>
