import { AfterViewInit, DoCheck, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Icon } from '../../icons/icons.model';
import { Observable, Subscription } from 'rxjs';
import { EmptyResults, TableColumn } from '../../models';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, AfterViewInit, DoCheck {
  @Input() tableColumns: TableColumn<any>[] = [];
  @Input() set tableData(data: unknown[]) {
    this.setTableDataSource(data);
  }
  @Input() isPageable = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() rowActionIcon: Icon = '';
  @Input() paginationSizes: number[] = [5, 10, 15];
  @Input() defaultPageSize: number = this.paginationSizes[1];
  @Input() set minDataToBeLoaded(min: number) {
    this.minData = min;
    this.setupEmptyRows(true);
  }
  @Input() fetchTableRows?: (event: PageEvent) => Observable<any[]>;
  @Input() cacheRows = false;
  @Input() emptyResult: EmptyResults = {
    title: this.translateService.instant('No result found'),
  };
  @Input() isItemSelected: boolean = false;

  @Output() sort: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectRow: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, { static: false }) matPaginator?: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort?: MatSort;

  public tableDataSource = new MatTableDataSource<any>([]);
  public displayedColumns: string[] = [];
  public canDisplay: boolean = false;

  private minData = 0;
  private rowsSubscription: Subscription | undefined;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const columnNames = this.tableColumns.map((tableColumn: TableColumn<any>) => tableColumn.name);
    if (this.rowActionIcon) {
      this.displayedColumns = [this.rowActionIcon, ...columnNames];
    } else {
      this.displayedColumns = columnNames;
    }
  }

  ngAfterViewInit(): void {
    if (this.matPaginator) {
      this.tableDataSource.paginator = this.matPaginator;
    }
  }

  ngDoCheck(): void {
    if (this.isPageable && !this.tableDataSource.paginator) {
      return;
    }

    this.canDisplay = true;
  }

  setTableDataSource(data: unknown[]): void {
    this.tableDataSource = new MatTableDataSource<unknown>(data);

    if (this.matPaginator) {
      this.tableDataSource.paginator = this.matPaginator;
      this.tableDataSource.paginator.pageSize = this.defaultPageSize;
      this.tableDataSource.paginator.pageIndex = 0;
    }
    if (this.matSort) {
      this.tableDataSource.sort = this.matSort;
    }
    if (this.minData) {
      this.setupEmptyRows(true);
    }
    this.rowsSubscription?.unsubscribe();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  sortTable(sortParameters: Sort): void {
    sortParameters.active =
      (this.tableColumns.find((column: TableColumn<any>) => column.name === sortParameters.active)
        ?.dataKey as string) ?? '';
    this.sort.emit(sortParameters);
  }

  pageChanged(pageEvent: PageEvent): void {
    if (this.displayingUndefinedData(pageEvent)) {
      this.loadMissingData(pageEvent);
    }
  }

  emitRowAction(row: any): void {
    this.rowAction.emit(row);
  }

  emitSelectRow(row: any): void {
    this.selectRow.emit(row);
  }

  private loadMissingData(pageEvent: PageEvent): void {
    if (!this.fetchTableRows) {
      return;
    }

    this.rowsSubscription?.unsubscribe();
    this.rowsSubscription = this.fetchTableRows(pageEvent).subscribe((data: unknown[]) => {
      const start = DataTableComponent._getCurrentPageIndexFromPageEvent(pageEvent);
      this.setupEmptyRows(this.cacheRows);
      this.tableDataSource.data.splice(start, data.length, ...data);
      this.tableDataSource.data = [...this.tableDataSource.data];
    });
  }

  private displayingUndefinedData(event: PageEvent) {
    const start = DataTableComponent._getCurrentPageIndexFromPageEvent(event);
    const end = DataTableComponent._getCurrentLastPageIndexFromPageEvent(event);

    return this.tableDataSource.data.slice(start, end).includes(undefined);
  }

  private setupEmptyRows(cacheRows: boolean) {
    const trimmedData = DataTableComponent._trimLastUndefinedData([...this.tableDataSource.data]);

    const placeholder = Array(this.minData).fill(undefined);
    if (cacheRows) {
      placeholder.splice(0, trimmedData.length, ...trimmedData);
    }

    this.tableDataSource.data = placeholder;
  }

  private static _trimLastUndefinedData(data: unknown[]) {
    let firstDefinedIndex = [...data].reverse().findIndex(Boolean);
    firstDefinedIndex = firstDefinedIndex < 0 ? data.length : firstDefinedIndex;
    const lastDefinedIndex = data.length - firstDefinedIndex;
    return data.slice(0, lastDefinedIndex);
  }

  private static _getCurrentPageIndexFromPageEvent(event: PageEvent) {
    return event.pageIndex * event.pageSize;
  }

  private static _getCurrentLastPageIndexFromPageEvent(event: PageEvent) {
    return event.pageIndex * event.pageSize + event.pageSize;
  }
}
