import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeFr from '@angular/common/locales/fr';
import localeFrCa from '@angular/common/locales/fr-CA';
import localeFrCaExtra from '@angular/common/locales/extra/fr-CA';
import localeDe from '@angular/common/locales/de';
import localeEu from '@angular/common/locales/eu';
import localeEs from '@angular/common/locales/es';
import localeCa from '@angular/common/locales/ca';
import localeZh from '@angular/common/locales/zh';
import localeAr from '@angular/common/locales/ar';
import localeCy from '@angular/common/locales/cy';
import localeNl from '@angular/common/locales/nl';
import localeNds from '@angular/common/locales/nds';
import localeFy from '@angular/common/locales/fy';

registerLocaleData(localePt);
registerLocaleData(localeFr);
registerLocaleData(localeFrCa, localeFrCaExtra);
registerLocaleData(localeDe);
registerLocaleData(localeEu);
registerLocaleData(localeEs);
registerLocaleData(localeCa);
registerLocaleData(localeZh);
registerLocaleData(localeAr);
registerLocaleData(localeCy);
registerLocaleData(localeNl);
registerLocaleData(localeNds);
registerLocaleData(localeFy);

@NgModule({
  imports: [],
  exports: [],
})
export class LocaleModule {}
