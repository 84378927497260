import { Injectable } from '@angular/core';
import { AuthService, TranslateService } from '../services/common';
import { Observable, throwError } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { AUTH_ERRORS_EXCEPTION_MAP, ERRORS_EXCEPTION_MAP, ERRORS_EXCEPTION_TYPE_TO_IGNORE } from './errors-to-bypass';
import { DialogsService } from '../dialogs';
import { ApiError, ApiResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ErrorManagerService {
  currentErrorDialog?: MatDialogRef<unknown>;
  constructor(
    private authService: AuthService,
    private dialogsService: DialogsService,
    private translateService: TranslateService,
  ) {}

  handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status) {
      const errorType = String(error.error?.errors?.[0]?.type);

      if (ERRORS_EXCEPTION_MAP.has(errorType)) {
        return throwError(() => ERRORS_EXCEPTION_MAP.get(errorType));
      }

      if (ERRORS_EXCEPTION_TYPE_TO_IGNORE.includes(errorType)) {
        return throwError(() => error);
      }

      switch (error.status) {
        case 403:
          if (errorType === 'user_not_active') {
            this.authService.logout(this.translateService.instant('User is not active.'));
          }
          if (errorType === 'missing_permissions') {
            this.showMissingPermissionsError();
          }
          break;
        case 404:
          break;
        case 409:
          break;
        case 422:
          try {
            this.showError422(error);
          } catch (_) {
            this.showGenericError();
          }

          break;
        default:
          this.showGenericError();
          break;
      }
    } else {
      this.showGenericError();
    }
    return throwError(() => error);
  }

  bypassErrorHandling(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error);
  }

  handleAuthError(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (error.status) {
      const errorType = String(error.error?.errors?.[0]?.type);

      if (AUTH_ERRORS_EXCEPTION_MAP.has(errorType)) {
        return throwError(() => AUTH_ERRORS_EXCEPTION_MAP.get(errorType));
      }
    }

    return this.authService.tokenRefresh(request, next);
  }

  private showError422(error: HttpErrorResponse): void {
    const customError = error.error as ApiResponse<void, string>;
    if (customError.errors.length && customError.errors[0]?.detail) {
      const errorDetails = customError.errors[0]?.detail;
      const errors = ErrorManagerService.extractErrorsFromDetails<string>(errorDetails);
      const formattedErrorMessage = ErrorManagerService.formatErrorMessages(errors);
      this.dialogsService.error(formattedErrorMessage, this.translateService.instant('Validation Error'));
    } else {
      throw `Can't extract error`;
    }
  }

  private showGenericError(): void {
    if (!this.currentErrorDialog) {
      this.currentErrorDialog = this.dialogsService.error(
        this.translateService.instant(
          'An error has occurred! Please try again later. If the error persists, try to logout and sign in again.',
        ),
      );
      this.currentErrorDialog.afterClosed().subscribe(() => {
        this.currentErrorDialog = undefined;
      });
    }
  }

  private showMissingPermissionsError(): void {
    this.dialogsService.error(
      this.translateService.instant(
        'You don’t have the permission to perform this action. Please contact your Administrator.',
      ),
      this.translateService.instant('Missing Permissions Error'),
    );
  }

  private static formatErrorMessages(errors: ApiError<string>[]): string {
    return errors.reduce(
      // eslint-disable-next-line no-return-assign
      (formattedErrorMessage: string, error: ApiError<string>) =>
        (formattedErrorMessage += `${
          typeof error.detail === 'object' ? JSON.stringify(error.detail) : error.detail ?? ''
        }`),
      '',
    );
  }

  public static extractErrorsFromDetails<T>(errorDetails: string): ApiError<T>[] {
    const formattedErrorDetails = errorDetails
      .split(']: ')[1]
      .replaceAll(`'`, '"')
      .replaceAll('None', 'null')
      .replaceAll(/True|False/g, (s) => s.toLowerCase());
    return JSON.parse(`${formattedErrorDetails}]`) as ApiError<T>[];
  }
}
