<ng-container *ngIf="sectionMetricForm">
  <form class="form" [formGroup]="sectionMetricForm">
    <div class="formfield large">
      <lib-text-input
        [label]="'Original code' | translate"
        [control]="sectionMetricForm.controls.originalCode"
        [readonly]="true"
      >
      </lib-text-input>
    </div>

    <div class="formfield large">
      <lib-text-input
        [label]="'Placeholder code' | translate"
        [hint]="'For Display' | translate"
        [control]="sectionMetricForm.controls.placeholderCode"
      >
      </lib-text-input>
    </div>

    <div class="formfield large">
      <lib-text-input
        [label]="'Placeholder Description' | translate"
        [hint]="'For Display' | translate"
        [control]="sectionMetricForm.controls.placeholderDescription"
      >
      </lib-text-input>
    </div>

    <div class="formfield large">
      <lib-select-input
        [label]="'Section' | translate"
        [control]="sectionMetricForm.controls.sectionId"
        [options]="sectionOptions"
      >
      </lib-select-input>
    </div>
  </form>

  <lib-divider class="mt-4"></lib-divider>
  <ng-container *ngIf="template$ | async as template">
    <ng-container *ngIf="template.category === eReportCategoryType.template_framework">
      <ng-container *ngIf="templateReportVersionId && templateReportSectionMetric && editingMetric">
        <lib-template-report-structure-displayed-values-display
          data-testid="displayedValuesComponent"
          [template]="template"
          [templateReportVersionId]="templateReportVersionId"
          [templateReportSectionMetric]="templateReportSectionMetric"
          [sourceConfiguration]="sourceConfiguration"
        >
        </lib-template-report-structure-displayed-values-display>
      </ng-container>
    </ng-container>
  </ng-container>

  <div *libPermission="managePermissions" class="mt-4">
    <div class="form-submit">
      <button
        lib-button
        buttonType="success"
        (click)="saveMetric()"
        [disabled]="sectionMetricForm.invalid || (editingMetric && sectionMetricForm.pristine) || !hasFormDataChanged()"
        [isLoading]="isLoading"
        [label]="'Save' | translate"
      ></button>
      <button lib-button [label]="'Cancel' | translate" (click)="closePanel.emit()"></button>
    </div>
  </div>
</ng-container>
