import { Pipe, PipeTransform } from '@angular/core';
import { ValueDefinition } from '../../../../../../models';

@Pipe({
  name: 'disableContextSwitch',
})
export class DisableContextSwitchPipe implements PipeTransform {
  transform(valueDefinition: ValueDefinition, valueDefinitions: ValueDefinition[]): boolean {
    if (valueDefinitions.every((vd) => vd.context)) {
      return false;
    }
    return !valueDefinitions.filter((vd) => vd.id !== valueDefinition.id).some((vd) => !vd.context);
  }
}
