import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import {
  ActionItem,
  ApiResponse,
  ApplicationApiDefinition,
  CreatePublicUserPayload,
  CreateUser,
  LimitedUser,
  ListLimitedUsersSearchOptions,
  ListUsersSearchOptions,
  ProfileInfo,
  SearchOptions,
  User,
  UserEmailCheck,
  UserInfo,
  UserRoles,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { CacheService } from '../../common/cache/cache.service';
import { AuthService } from '../../common/auth/auth.service';

const AllowedUserRolesInSearch: string[] = [
  UserRoles.viewer,
  UserRoles.editor,
  UserRoles.collaborator,
  UserRoles.admin,
  UserRoles.manager,
];

@Injectable({
  providedIn: 'root',
})
export class ClientUsersService {
  apiName: keyof ApplicationApiDefinition = 'auth';
  resource: string;
  servicePath: string;

  private cachedGetLimitedUserRequests: Map<string, ReplaySubject<ApiResponse<LimitedUser>>> = new Map();

  constructor(
    private auth: AuthService,
    private apiService: ApiService,
    private cacheService: CacheService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.auth.resources.users;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): HttpParams {
    let params = new HttpParams();
    if (searchOptions.query.keywords) {
      params = params.append('query', searchOptions.query.keywords);
    }
    if (searchOptions.filters.role) {
      params = params.append('roles', searchOptions.filters.role.id);
    } else {
      AllowedUserRolesInSearch.forEach((role) => {
        params = params.append('roles', role);
      });
    }
    if (searchOptions.filters.status) {
      params = params.append('active', searchOptions.filters.status.id === 'active');
    }
    if (searchOptions.sort?.id === 'name') {
      params = params.append('order_by', 'first_name');
      params = params.append('order_by_direction', 'asc');
    } else {
      params = params.append('order_by', 'updated');
      params = params.append('order_by_direction', 'desc');
    }
    return params;
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<User[]>> {
    const params = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.get(`${this.servicePath}${this.resource}/users`, { params });
  }

  listUsers(searchArguments?: ListUsersSearchOptions): Observable<ApiResponse<User[]>> {
    return this.fetchUsers(searchArguments, false);
  }

  listLimitedUsers(searchArguments: ListLimitedUsersSearchOptions = {}): Observable<ApiResponse<LimitedUser[]>> {
    const cacheKey: string = `listLimitedUsers:${JSON.stringify(searchArguments)}`;
    const cacheValue = this.cacheService.get<ApiResponse<LimitedUser[]>>(cacheKey);

    if (cacheValue) {
      return of(cacheValue);
    }

    return this.fetchUsers(searchArguments).pipe(
      tap((res) => {
        this.cacheService.set(cacheKey, res, '1h');
      }),
    );
  }

  listDataRequestAvailableParticipantsUsers(): Observable<ApiResponse<LimitedUser[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/users/data_request_available_participants`);
  }

  getUser(userId: string): Observable<ApiResponse<User>> {
    const users = <ActionItem<User>[]>this.cacheService.getCategory('user');
    const user = users.find((x) => x.id === userId)?.item;
    if (user) {
      return of({ data: user, errors: [], meta: {} });
    } else {
      return this.apiService.get(`${this.servicePath}${this.resource}/users/${userId}`);
    }
  }

  getLimitedUser(userId: string): Observable<ApiResponse<LimitedUser>> {
    if (this.cachedGetLimitedUserRequests.has(userId)) {
      return this.cachedGetLimitedUserRequests.get(userId)?.asObservable().pipe(take(1)) as Observable<
        ApiResponse<LimitedUser>
      >;
    }
    const subject = new ReplaySubject<ApiResponse<LimitedUser>>(1);
    this.cachedGetLimitedUserRequests.set(userId, subject);
    this.apiService
      .get<ApiResponse<LimitedUser>>(`${this.servicePath}${this.resource}/users/${userId}/limited`)
      .subscribe((res) => {
        subject.next(res);
      });

    return this.cachedGetLimitedUserRequests.get(userId)?.asObservable().pipe(take(1)) as Observable<
      ApiResponse<LimitedUser>
    >;
  }

  getUserByEmail(email: string): Observable<ApiResponse<User>> {
    const users = <ActionItem<User>[]>this.cacheService.getCategory('user');
    const user = users.find((x) => x.item?.email === email)?.item;
    if (user) {
      return of({ data: user, errors: [], meta: {} });
    } else {
      return this.apiService.get(`${this.servicePath}${this.resource}/users/emails/${email}`);
    }
  }

  getSelf(): Observable<ApiResponse<User>> {
    const self = <ActionItem<User>>this.cacheService.getCategory('self');
    if (self.item) {
      return of({ data: self.item, errors: [], meta: {} });
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/users/self`);
  }

  getCurrentUser(): Observable<ApiResponse<User> | null> {
    if (!this.auth.user) {
      return of(null);
    } else {
      return this.getSelf();
    }
  }

  getUserInfo(user: User): string {
    const role = user.roles[0];
    let info = role.charAt(0).toUpperCase() + role.slice(1);
    if (user.title) {
      info = `${info} - ${user.title}`;
    }
    if (user.department) {
      info = `${info} - ${user.department}`;
    }
    return info;
  }

  updateCurrentUser(profileInfo: ProfileInfo): Observable<ApiResponse<User>> {
    this.cacheService.clearCategory('user');
    return this.apiService.put(`${this.servicePath}${this.resource}/users/self`, profileInfo);
  }

  createUser(payload: CreateUser): Observable<ApiResponse<User>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users`, payload).pipe(
      tap(() => {
        this.cacheService.clearCategory('users_user');
      }),
    );
  }

  updateUser(userId: string, payload: UserInfo): Observable<ApiResponse<User>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/users/${userId}`, payload).pipe(
      tap((userResponse: ApiResponse<User>) => {
        this.cacheService.clearCategory('users_user');
        this.updateUserCache(userResponse.data);
      }),
    );
  }

  activateUser(userId: string): Observable<ApiResponse<User>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users/${userId}/activate`, {}).pipe(
      tap(() => {
        this.cacheService.clearCategory('users_user');
        this.cacheService.clearCategory('user'); // clear user resources cache
      }),
    );
  }

  deactivateUser(userId: string): Observable<ApiResponse<User>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users/${userId}/deactivate`, {}).pipe(
      tap(() => {
        this.cacheService.clearCategory('users_user');
        this.cacheService.clearCategory('user'); // clear user resources cache
      }),
    );
  }

  createPublicUser(payload: CreatePublicUserPayload): Observable<ApiResponse<User>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/public_users`, payload);
  }

  userEmailCheck(email: string): Observable<ApiResponse<UserEmailCheck>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users/email_check`, { email });
  }

  private fetchUsers<T extends LimitedUser>(
    searchArguments: ListLimitedUsersSearchOptions | ListUsersSearchOptions = {},
    limited = true,
  ): Observable<ApiResponse<T[]>> {
    let params = new HttpParams();

    params = searchArguments.active ? params.append('active', searchArguments.active) : params;
    params = searchArguments.order_by ? params.append('order_by', searchArguments.order_by) : params;
    params = searchArguments.type ? params.append('type', searchArguments.type) : params;
    searchArguments.user_ids?.forEach((userId: string) => {
      params = params.append('user_ids', userId);
    });

    return this.apiService.get(`${this.servicePath}${this.resource}/users${limited ? '/limited' : ''}`, { params });
  }

  private updateUserCache(user: User): void {
    const users = <ActionItem<User>[]>this.cacheService.getCategory('user');
    const userIndex = users.findIndex((u) => u.id === user.id);
    users[userIndex] = { id: user.id, title: `${user.first_name} ${user.last_name}`, item: user };
    this.cacheService.set('user', users);
  }

  disableUser2FA(userId: string): Observable<ApiResponse> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users/${userId}/2fa/disable`);
  }
}
