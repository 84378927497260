import { Pipe, PipeTransform } from '@angular/core';
import { VGSetStatusContext } from '../../models';
import { TranslateService } from '../../services/common';
import { VGSetStatusContextDisplayText } from '../../translations';

@Pipe({
  name: 'vgsetStatusContext',
})
export class VgsetStatusContextPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(status_context?: VGSetStatusContext): string {
    return status_context ? this.translateService.instant(VGSetStatusContextDisplayText[status_context]) : '';
  }
}
