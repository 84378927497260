import { Pipe, PipeTransform } from '@angular/core';
import { Metric, MetricTableColumn, MetricTableColumnDefinition } from '../../../models';
import { MetricStructureUtils } from '../../classes/MetricStructureUtils/metric-structure-utils';

@Pipe({
  name: 'isCoreTableColumnInPlatform',
})
export class IsCoreTableColumnInPlatformPipe implements PipeTransform {
  transform(
    column: MetricTableColumnDefinition | MetricTableColumn | undefined,
    metric: Metric,
    isCore: boolean,
  ): boolean {
    return MetricStructureUtils.isCoreTableColumnOfRefV2OrTPMInPlatform(metric, column, isCore);
  }
}
