import { CreatedUpdatedEntity } from '../entities';
import { IndicatorType, ValueDefinitionType } from '../metrics';
import { ValueType } from '../values';

export interface FieldAuditEventsRequest {
  frequency_code: string;
  business_unit_id: string;
  value_group_id: string;
  value_id: string;
  indicator_type: IndicatorType;
}

export interface FieldAuditEvent extends CreatedUpdatedEntity {
  id: string;
  metric_id: string;
  indicator_id: string;
  indicator_type: IndicatorType;
  value_group_set_id: string;
  business_unit_id: string;
  frequency_code: string;
  value_group_id: string;
  value_definition_group_id: string;
  value_id: string;
  event_type: FieldAuditEventType;
  payload: FieldAuditEventPayload;
  origin?: FieldAuditEventOrigin;
  user_info: FieldAuditEventUserInfo;
  variables: FieldAuditEventVariable[];
  formula?: string;
}

export interface FieldAuditEventOrigin {
  context: FieldAuditEventOriginContext;
  data_request_id?: string;
  data_request_source_id: string;
  old_metric_id?: string;
  old_metric_code?: string;
}

export interface FieldAuditEventUserInfo {
  first_name: string;
  last_name: string;
}

export interface FieldAuditEventPayload {
  new_value: ValueType;
  old_value: ValueType;
}

export enum FieldAuditEventType {
  value_create = 'value_create',
  value_edit = 'value_edit',
  value_reset = 'value_reset',
}

export enum FieldAuditEventOriginContext {
  imported_via_data_request = 'imported_via_data_request',
  prefill_data_request = 'prefill_data_request',
  imported_from_green_project = 'imported_from_green_project',
  imported_from_minimum = 'imported_from_minimum',
  some_values_entered_via_green_project_api = 'some_values_entered_via_green_project_api',
  some_values_entered_via_minimum_api = 'some_values_entered_via_minimum_api',
  consolidation_parameters_change = 'consolidation_parameters_change',
  metric_migration = 'metric_migration',
}

export interface FieldAuditEventVariable extends CreatedUpdatedEntity {
  id: string;
  field_audit_trail_id: string;
  indicator_id: string;
  mandatory: boolean;
  metric_code: string;
  metric_description: string;
  tag: string;
  unit: string;
  value: string;
  value_definition_id: string;
  value_definition_label: string;
  value_definition_type: ValueDefinitionType;
  user_info: FieldAuditEventUserInfo;
  value_updated?: Date | string;
}
