import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActionItem, Doc } from '../../../../../models';
import { TranslateService } from '../../../../../services/common/translate/translate.service';

@Component({
  selector: 'lib-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent {
  @Input({ required: true }) item!: ActionItem<Doc>;

  @Output() action: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();

  constructor(private translateService: TranslateService) {}

  public selectItem(item: ActionItem<Doc>): void {
    this.action.emit({
      id: 'select',
      title: this.translateService.instant('Select'),
      item,
    });
  }
}
