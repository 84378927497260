<div
  class="filter-bar-container"
  [ngClass]="{ 'filter-bar-container-with-search': withSearchBar, 'p-0': !withPadding }"
>
  <div class="left-section">
    <lib-filter-bar-search
      *ngIf="withSearchBar"
      [initialSearch]="initialSearch"
      (searchChanged)="onSearchChanged.emit($event)"
      (searchTriggered)="searchTriggered.emit($event)"
      [searchBarPlaceholder]="searchBarPlaceholder"
    ></lib-filter-bar-search>

    <ng-content></ng-content>

    <div class="primary-options" *ngIf="primaryOptions.length">
      <div *ngFor="let primaryFilteringConfig of primaryFilteringOptions">
        <!-- Multi-Select Filter -->
        <ng-container *ngIf="primaryFilteringConfig.multiSelect; else cascadeSelectBlock">
          <lib-multi-select-filter
            class="filtering-option"
            [optionProps]="primaryFilteringConfig.filterOption"
            [buttonProps]="primaryFilteringConfig.buttonOption"
            (optionsSelected)="optionsSelected($event, primaryFilteringConfig)"
          ></lib-multi-select-filter>
        </ng-container>

        <!-- Cascade Select Filter -->
        <ng-template #cascadeSelectBlock>
          <ng-container *ngIf="primaryFilteringConfig.cascadeSelect; else defaultBlock">
            <lib-cascade-select
              class="filtering-option"
              *ngIf="primaryFilteringConfig.filterOption.cascadeProps"
              [control]="primaryFilteringConfig.filterOption.cascadeProps.formControl"
              [options]="primaryFilteringConfig.filterOption.cascadeProps.options"
              [groupLabel]="primaryFilteringConfig.filterOption.cascadeProps.groupLabel"
              [optionLabel]="primaryFilteringConfig.filterOption.cascadeProps.optionLabel"
            ></lib-cascade-select>
          </ng-container>
        </ng-template>

        <!-- Default Filter (list type) -->
        <ng-template #defaultBlock>
          <lib-primary-filter
            *ngIf="primaryFilteringConfig.optionType === FilterType.list"
            class="filtering-option"
            [optionProps]="primaryFilteringConfig.filterOption"
            [buttonProps]="primaryFilteringConfig.buttonOption"
            (optionSelected)="optionSelected($event, primaryFilteringConfig)"
          ></lib-primary-filter>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="filter-bar-options" data-testid="secondary-filter-container" *ngIf="secondaryFilteringOptions.length">
    <a class="btn btn-warn" [title]="'Reset' | translate" (click)="resetFilters()" *ngIf="hasSelectedFilters">
      <mat-icon class="icon-sm" svgIcon="frequency"></mat-icon>
      <span>{{ "Reset" | translate }}</span>
    </a>
    <div *ngIf="hasSelectedFilters" class="divider divider-vertical"></div>

    <div *ngFor="let filteringConfig of secondaryFilteringOptions">
      <lib-filter-search
        *ngIf="filteringConfig.optionType === FilterType.searchList"
        class="filtering-option"
        [optionProps]="filteringConfig.filterOption"
        [buttonProps]="filteringConfig.buttonOption"
        [sourceConfiguration]="sourceConfiguration"
        (optionSelected)="optionSelected($event, filteringConfig)"
      ></lib-filter-search>

      <lib-filter
        *ngIf="filteringConfig.optionType === FilterType.list"
        class="filtering-option"
        [optionProps]="filteringConfig.filterOption"
        [buttonProps]="filteringConfig.buttonOption"
        [sourceConfiguration]="sourceConfiguration"
        (optionSelected)="optionSelected($event, filteringConfig)"
      ></lib-filter>
    </div>
  </div>
</div>
