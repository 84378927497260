import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Permission } from '../../models';
import { first, map } from 'rxjs/operators';
import { ObservableUtils } from '../../classes';
import { AuthApiService } from '../../services/api-services';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  private defaultRedirect: string = '/app';

  constructor(
    private authService: AuthApiService,
    private router: Router,
  ) {}

  private hasPermission(userPermissions: Permission[], requiredPermissions: Permission[]): boolean {
    return requiredPermissions.some((requiredPermission: Permission) => userPermissions.includes(requiredPermission));
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const requiredPermissions: Permission[] = route.data['requiredPermissions'];
    const unauthorizedRedirect: string = (route.data['permissionRedirect'] as string) || this.defaultRedirect;

    return this.authService.getUserPermissions().pipe(
      ObservableUtils.filterNullish(),
      first(),
      map((permissions: Permission[]) =>
        this.hasPermission(permissions, requiredPermissions) ? true : this.router.createUrlTree([unauthorizedRedirect]),
      ),
    );
  }
}
