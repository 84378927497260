import { Pipe, PipeTransform } from '@angular/core';
import { Metric, MetricTableDefinition } from '../../../models';
import { MetricTableGroup } from '../../models';
import { MetricStructureUtils } from '../../classes/MetricStructureUtils/metric-structure-utils';

@Pipe({
  name: 'isCoreTableInPlatform',
})
export class IsCoreTableInPlatformPipe implements PipeTransform {
  transform(table: MetricTableGroup | MetricTableDefinition | undefined, metric: Metric, isCore: boolean): boolean {
    return MetricStructureUtils.isCoreTableOfRefV2OrTPMInPlatform(metric, table, isCore);
  }
}
