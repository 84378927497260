import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionItem, SearchBarFilterResourceType } from '../../../models';

@Component({
  selector: 'lib-search-bar-menu-item',
  templateUrl: './search-bar-menu-item.component.html',
  styleUrls: ['./search-bar-menu-item.component.scss'],
})
export class SearchBarMenuItemComponent {
  @Input({ required: true }) menuTitle: string = '';
  @Input({ required: true }) resourceType!: SearchBarFilterResourceType;
  @Input() filterItems: ActionItem[] = [];
  @Input() selectedFilterItemId: string = '';

  @Output() setItem = new EventEmitter<ActionItem>();
}
