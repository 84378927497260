import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

import { Observable, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../../common/base/base.service';
import { ContextService } from '../../common/context/context.service';
import { ClientIndicatorsService } from '../client-indicators/client-indicators.service';
import { ApiResponse, Source, Dataset, SearchOptions, Metric, Indicator, Value } from '../../../models';
import { TranslateService } from '../../common/translate/translate.service';
import { SourcesService } from '../../common/sources/sources.service';

@Injectable({
  providedIn: 'root',
})
export class ClientDatasetsService {
  _datasetValues: { [key: string]: any } = {};

  constructor(
    private baseService: BaseService,
    private indicatorsService: ClientIndicatorsService,
    private sourcesService: SourcesService,
    private contextService: ContextService,
    private translateService: TranslateService,
  ) {}

  // Values

  public get data(): any {
    return this._datasetValues;
  }

  getData(id: string): any {
    return this._datasetValues[id];
  }

  setData(id: string, data: any): void {
    this._datasetValues[id] = data;
  }

  clearData(id: string): void {
    delete this._datasetValues[id];
  }

  formatValue(value: Value): string {
    switch (value.type) {
      case 'boolean':
        return value.value ? 'Yes' : 'No';
      case 'text':
      case 'label':
      case 'tip':
      case 'subtitle':
      case 'number':
      case 'calculated':
        return value.value;
      case 'date':
        return value.value ? formatDate(String(value.value), 'YYYY-MM-dd', 'en') : '';
      case 'datetime':
        return value.value ? formatDate(String(value.value), 'YYYY-MM-dd hh:mm:ss', 'en') : '';
      case 'choice':
        return value.value && value.type_details.multi_choices ? value.value.join(', ') : value.value;
      case 'file':
        let countFiles = 1;
        if (value.value instanceof Array) {
          countFiles = value.value.length;
        }
        return this.translateService.instant('{countFiles} file(s) attached', {
          countFiles,
        });
      default:
        throw new Error(`Value type format not supported for: ${value.type}`);
    }
  }

  loadData(id: string): Observable<any> {
    const datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      throw new Error(`Dataset not found for id: ${id}`);
    } else {
      const dataset: Dataset | undefined = datasets.find((x) => x.id === id);
      if (!dataset) {
        throw new Error(`Dataset not found for id: ${id}`);
      } else {
        const requests = [];
        requests.push(this.sourcesService.listSources());
        for (let year = dataset.from_year; year <= dataset.to_year; year++) {
          requests.push(
            this.indicatorsService.getDatasetValues(
              year,
              dataset.metrics.map((x) => x.id),
            ),
          );
        }
        return forkJoin(requests).pipe(
          map((results) => {
            const data: any[] = [];
            let current_year = dataset.from_year - 1;
            const sources = (results[0] as ApiResponse<any[]>).data;
            for (let i = 1; i < results.length; i++) {
              const result = results[i] as ApiResponse<Indicator[]>;
              current_year++;
              for (const indicator of result.data) {
                for (const value_group_set of indicator.value_group_sets || []) {
                  const consolidated = value_group_set.consolidated;
                  const source = sources.find((x) => x.id == value_group_set.business_unit_id);
                  for (const value_group of value_group_set.value_groups || []) {
                    for (const value of value_group.values || []) {
                      let isData: boolean = false;
                      if (dataset.sources == undefined) {
                        if (!value_group_set.business_unit_id) {
                          isData = true;
                        }
                      } else if (dataset.sources.length == 0) {
                        if (value_group_set.business_unit_id) {
                          isData = true;
                        }
                      } else {
                        if (dataset.sources.find((x) => x.id == value_group_set.business_unit_id)) {
                          isData = true;
                        }
                      }
                      if (isData && ['number', 'date', 'datetime', 'calculated'].indexOf(value.type) >= 0) {
                        data.push({
                          metric_id: indicator.id,
                          metric_code: indicator.code,
                          metric_description: indicator.description,
                          year: current_year,
                          source_id: source?.id,
                          source_name: source?.name,
                          consolidated: this.translateService.instant(consolidated ? 'Yes' : 'No'),
                          value_label: value.label,
                          position: value_group.subposition,
                          value: this.formatValue(value),
                          value_required: this.translateService.instant(value.required ? 'Yes' : 'No'),
                          unit: value.unit,
                          updated: value.updated ? formatDate(value.updated, 'YYYY-MM-dd hh:mm:ss', 'en') : '',
                        });
                      }
                    }
                  }
                }
              }
            }
            return data;
          }),
        );
      }
    }
  }

  // Search

  searchDatasets(searchOptions: SearchOptions): Observable<ApiResponse<Dataset[]>> {
    let datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      datasets = [];
    }
    if (searchOptions.query.keywords) {
      datasets = datasets.filter(
        (x) => x.name.trim().toLowerCase().indexOf(searchOptions.query.keywords.trim().toLowerCase()) >= 0,
      );
    }
    if (searchOptions.sort) {
      switch (searchOptions.sort.id) {
        case 'name':
          datasets.sort((a, b) => (a.name < b.name ? -1 : 1));
          break;
        default:
          datasets.sort((a, b) => {
            if (a.updated && b.updated) {
              return new Date(a.updated).getTime() < new Date(b.updated).getTime() ? 1 : -11;
            } else {
              return 0;
            }
          });
          break;
      }
    } else {
      datasets.sort((a, b) => {
        if (a.updated && b.updated) {
          return new Date(a.updated).getTime() < new Date(b.updated).getTime() ? 1 : -11;
        } else {
          return 0;
        }
      });
    }
    if (searchOptions.size) {
      datasets = datasets.slice(0, searchOptions.size);
    }
    return of({ meta: { total_count: datasets.length }, errors: [], data: datasets });
  }

  // CRUD

  createDataset(name: string, from_year: number, to_year: number): Observable<ApiResponse<Dataset>> {
    const dataset: Dataset = {
      id: this.baseService.newGuid(),
      name,
      created: new Date(),
      updated: new Date(),
      metrics: [],
      from_year,
      to_year,
    };
    let datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      datasets = [];
    }
    datasets.push(dataset);
    this.contextService.upsertData('datasets', datasets);
    return of({ meta: {}, errors: [], data: dataset });
  }

  duplicateDataset(id: string): Observable<ApiResponse<Dataset>> {
    const datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      throw new Error(`Source dataset not found for id: ${id}`);
    } else {
      const dataset: Dataset | undefined = datasets.find((x) => x.id === id);
      if (!dataset) {
        throw new Error(`Source dataset not found for id: ${id}`);
      } else {
        const newDataset: Dataset = {
          id: this.baseService.newGuid(),
          name: `Copy of ${dataset.name}`,
          metrics: dataset.metrics,
          from_year: dataset.from_year,
          to_year: dataset.to_year,
          sources: dataset.sources,
          created: new Date(),
          updated: new Date(),
        };
        datasets.push(newDataset);
        this.contextService.upsertData('datasets', datasets);
        return of({ meta: {}, errors: [], data: newDataset });
      }
    }
  }

  deleteDataset(id: string): Observable<ApiResponse<boolean>> {
    const datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (datasets) {
      const dataset = datasets.find((x) => x.id === id);
      if (dataset) {
        datasets.splice(datasets.indexOf(dataset), 1);
      }
      this.contextService.upsertData('datasets', datasets);
    }
    return of({ meta: {}, errors: [], data: true });
  }

  getDataset(id: string): Observable<ApiResponse<Dataset>> {
    const datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      throw new Error(`Dataset not found for id: ${id}`);
    } else {
      const dataset: Dataset | undefined = datasets.find((x) => x.id === id);
      if (!dataset) {
        throw new Error(`Dataset not found for id: ${id}`);
      } else {
        return of({ meta: {}, errors: [], data: dataset });
      }
    }
  }

  updateDataset(dataset: Dataset): Observable<ApiResponse<boolean>> {
    const datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      throw new Error(`Dataset not found for id: ${dataset.id}`);
    } else {
      const ds: Dataset | undefined = datasets.find((x) => x.id === dataset.id);
      if (!ds) {
        throw new Error(`Dataset not found for id: ${dataset.id}`);
      } else {
        dataset.updated = new Date();
        const index = datasets.indexOf(ds);
        datasets.splice(index, 1, dataset);
        this.contextService.upsertData('datasets', datasets);
        return of({ meta: {}, errors: [], data: true });
      }
    }
  }

  updateDatasetProperties(
    id: string,
    name: string,
    from_year: number,
    to_year: number,
    sources?: Source[],
  ): Observable<ApiResponse<boolean>> {
    const datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      throw new Error(`Dataset not found for id: ${id}`);
    } else {
      const dataset: Dataset | undefined = datasets.find((x) => x.id === id);
      if (!dataset) {
        throw new Error(`Dataset not found for id: ${id}`);
      } else {
        dataset.name = name;
        dataset.from_year = from_year;
        dataset.to_year = to_year;
        dataset.sources = sources;
        dataset.updated = new Date();
        this.contextService.upsertData('datasets', datasets);
        return of({ meta: {}, errors: [], data: true });
      }
    }
  }

  // Dataset metrics

  public addMetric(id: string, metric: Metric, position?: number): Observable<ApiResponse<Dataset>> {
    const datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      throw new Error(`Dataset not found for id: ${id}`);
    } else {
      const dataset: Dataset | undefined = datasets.find((x) => x.id === id);
      if (!dataset) {
        throw new Error(`Dataset not found for id: ${id}`);
      } else {
        if (!position) {
          position = dataset.metrics.length;
        }
        dataset.metrics.splice(position, 0, metric);
        dataset.updated = new Date();
        this.contextService.upsertData('datasets', datasets);
        return of({ meta: {}, errors: [], data: dataset });
      }
    }
  }

  public deleteMetric(id: string, metric: Metric): Observable<ApiResponse<Dataset>> {
    const datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      throw new Error(`Dataset not found for id: ${id}`);
    } else {
      const dataset: Dataset | undefined = datasets.find((x) => x.id === id);
      if (!dataset) {
        throw new Error(`Dataset not found for id: ${id}`);
      } else {
        const datasetMetric: Metric | undefined = dataset.metrics.find((x) => x.id === metric.id);
        if (datasetMetric) {
          dataset.metrics.splice(dataset.metrics.indexOf(datasetMetric), 1);
          dataset.updated = new Date();
          this.contextService.upsertData('datasets', datasets);
        }
        return of({ meta: {}, errors: [], data: dataset });
      }
    }
  }

  public moveMetric(id: string, metric: Metric, position: number): Observable<ApiResponse<Dataset>> {
    const datasets: Dataset[] | undefined = this.contextService.getData('datasets');
    if (!datasets) {
      throw new Error(`Dataset not found for id: ${id}`);
    } else {
      const dataset: Dataset | undefined = datasets.find((x) => x.id === id);
      if (!dataset) {
        throw new Error(`Dataset not found for id: ${id}`);
      } else {
        const datasetMetric: Metric | undefined = dataset.metrics.find((x) => x.id === metric.id);
        if (datasetMetric) {
          dataset.metrics.splice(dataset.metrics.indexOf(datasetMetric), 1);
          dataset.metrics.splice(position, 0, datasetMetric);
          dataset.updated = new Date();
          this.contextService.upsertData('datasets', datasets);
        }
        return of({ meta: {}, errors: [], data: dataset });
      }
    }
  }
}
