import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFileGroupDeletable',
})
export class IsFileGroupDeletablePipe implements PipeTransform {
  constructor() {}

  transform(index: number, isFileAdded: boolean, fileId?: string): boolean {
    if (fileId) {
      return false;
    }

    if (isFileAdded) {
      return true;
    }

    return index != 0;
  }
}
