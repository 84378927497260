import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-brand-nav',
  templateUrl: './brand-nav.component.html',
  styleUrls: ['./brand-nav.component.scss'],
})
export class BrandNavComponent {
  @Input() title: string = '';
  @Input() link?: string;
}
