import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurtainStateService {
  private _curtainIsOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _curtainMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public readonly curtainIsOpen$ = this._curtainIsOpen$.asObservable();
  public readonly curtainMessage$ = this._curtainMessage$.asObservable();

  public openCurtain(message?: string): void {
    this._curtainIsOpen$.next(true);
    if (message) {
      this._curtainMessage$.next(message);
    }
  }

  public closeCurtain(): void {
    this._curtainIsOpen$.next(false);
    this._curtainMessage$.next('');
  }
}
