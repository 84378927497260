import { Component, Input } from '@angular/core';
import { TagAssociations } from '../../../models';

@Component({
  selector: 'lib-tag-associations',
  templateUrl: './tag-associations.component.html',
  styleUrls: ['./tag-associations.component.scss'],
})
export class TagAssociationsComponent {
  @Input() tagAssociations?: TagAssociations;
  @Input() containerClass: string | string[] = '';

  constructor() {}

  hasNoAssociations(): boolean {
    if (this.tagAssociations) {
      return (
        this.tagAssociations.report.count === 0 &&
        this.tagAssociations.benchmark.count === 0 &&
        this.tagAssociations.metric.count === 0 &&
        this.tagAssociations.document.count === 0
      );
    }
    return false;
  }
}
