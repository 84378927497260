import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { ActionItem, ApiResponse, ApplicationApiDefinition, Unit } from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';
import { HttpParams } from '@angular/common/http';
import { CacheService } from '../../common';

@Injectable({
  providedIn: 'root',
})
export class AdminUnitService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
    private cacheService: CacheService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.units;
  }

  public listUnitFamilies(): Observable<ApiResponse<Unit[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/units/families`);
  }

  public listUnits(family?: string): Observable<ApiResponse<Unit[]>> {
    let params = new HttpParams();
    if (family) {
      params = family ? params.append('family', family) : params;
      const allUnits = <ActionItem<Unit>[]>this.cacheService.getCategory('unit:undefined');
      const units = allUnits.filter((x) => x.item?.family === family);
      if (units.length) {
        return of({ data: units.map((x) => x.item!), errors: [], meta: {} });
      }
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/units`, { params });
  }
}
