import { Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit, tapResponse } from '@ngrx/component-store';
import { MetricApiService } from '../../../../services/types';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import {
  ApiResponse,
  Metric,
  MetricTableDefinition,
  RelatedField,
  Taxonomy,
  ValueDefinition,
} from '../../../../models';
import { MetricStructureSelectable, MetricTableGroup } from '../../../models';
import { filter, map } from 'rxjs/operators';
import { ObservableUtils } from '../../../../classes';

export interface SelectedItem {
  metric: Metric;
  item: MetricStructureSelectable | undefined;
}

export interface DetailsInfo {
  id?: string;
  coreVdId?: string;
  fieldPosition?: number;
  published?: string;
  published_by?: string;
  hiddenByTaxonomy?: boolean;
  isValueDefinition?: boolean;
}

export interface FieldInformationState {
  selectedItem: SelectedItem | undefined;
  tableDefinition: MetricTableDefinition | undefined;
  relatedFields: RelatedField[];
  taxonomies: Taxonomy[];
  detailsInfo: DetailsInfo | undefined;
}

@Injectable()
export class FieldInformationStore extends ComponentStore<FieldInformationState> implements OnStoreInit {
  public static readonly TAXONOMY_ORDER_BY: keyof Taxonomy = 'code';
  private static readonly DEFAULT_STATE: FieldInformationState = {
    selectedItem: undefined,
    tableDefinition: undefined,
    relatedFields: [],
    taxonomies: [],
    detailsInfo: undefined,
  };

  public readonly selectedItem$: Observable<SelectedItem | undefined> = this.select(
    (state) => state.selectedItem,
  ).pipe();

  public readonly selectedMetricTableGroup$: Observable<MetricTableGroup> = this.select(
    this.selectedItem$,
    (selectedItem) => selectedItem,
  ).pipe(
    ObservableUtils.filterNullish(),
    filter((selectedItem: SelectedItem) => !!selectedItem.item && 'table_id' in selectedItem.item),
    // TODO fix the following lint error
    // eslint-disable-next-line @ngrx/avoid-mapping-component-store-selectors
    map((selectedItem: SelectedItem) => selectedItem.item as MetricTableGroup),
  );

  public readonly tableDefinition$: Observable<MetricTableDefinition | undefined> = this.select(
    (state) => state.tableDefinition,
  );

  public readonly relatedFields$: Observable<RelatedField[]> = this.select((state) => state.relatedFields);
  public readonly taxonomies$: Observable<Taxonomy[]> = this.select((state) => state.taxonomies);
  public readonly detailsInfo$: Observable<DetailsInfo | undefined> = this.select((state) => state.detailsInfo);

  constructor(private readonly metricsService: MetricApiService) {
    super(FieldInformationStore.DEFAULT_STATE);
  }

  ngrxOnStoreInit(): void {
    this.tableDefinitionEffect();
    this.relatedFieldsEffect();
    this.taxonomiesEffect();
  }

  public readonly updateSelectedItem = this.updater(
    (state: FieldInformationState, selectedItem: SelectedItem | undefined): FieldInformationState => ({
      ...state,
      selectedItem,
    }),
  );

  private readonly updateTableDefinition = this.updater(
    (state: FieldInformationState, tableDefinition: MetricTableDefinition | undefined): FieldInformationState => ({
      ...state,
      tableDefinition,
    }),
  );

  public readonly updateRelatedFields = this.updater(
    (state: FieldInformationState, relatedFields: RelatedField[]): FieldInformationState => ({
      ...state,
      relatedFields,
    }),
  );

  public readonly updateTaxonomies = this.updater(
    (state: FieldInformationState, taxonomies: Taxonomy[]): FieldInformationState => ({
      ...state,
      taxonomies,
    }),
  );

  public readonly updateDetailsInfo = this.updater(
    (state: FieldInformationState, detailsInfo: DetailsInfo): FieldInformationState => ({
      ...state,
      detailsInfo: { ...state.detailsInfo, ...detailsInfo },
    }),
  );

  private readonly tableDefinitionEffect = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() => this.selectedMetricTableGroup$),
      switchMap((metricTableGroup: MetricTableGroup) =>
        this.metricsService.getMetricTable(metricTableGroup.metric_id, metricTableGroup.table_id),
      ),
      tapResponse(
        (tableDefinition: ApiResponse<MetricTableDefinition>) => {
          this.updateTableDefinition(tableDefinition.data);
        },
        (_err) => {
          this.updateTableDefinition(undefined);
          return EMPTY;
        },
      ),
    ),
  );

  private readonly relatedFieldsEffect = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() => this.selectedItem$),
      ObservableUtils.filterNullish(),
      switchMap((selectedItem: SelectedItem) => {
        if (selectedItem.item) {
          if ('table_id' in selectedItem.item) {
            return this.metricsService
              .getMetricTableRelatedFields(selectedItem.metric.id, (selectedItem.item as MetricTableGroup).table_id)
              .pipe(map((response: ApiResponse<RelatedField[]>) => response.data));
          } else {
            return this.metricsService
              .getFieldRelatedFields(
                selectedItem.metric.id,
                (selectedItem.item as ValueDefinition).value_definition_group_id,
                (selectedItem.item as ValueDefinition).id,
              )
              .pipe(map((response: ApiResponse<RelatedField[]>) => response.data));
          }
        } else {
          return of([]);
        }
      }),
      tapResponse(
        (relatedFields: RelatedField[]) => {
          this.updateRelatedFields(relatedFields);
        },
        (_err) => {
          this.updateRelatedFields([]);
          return EMPTY;
        },
      ),
    ),
  );

  private readonly taxonomiesEffect = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() => this.selectedItem$),
      ObservableUtils.filterNullish(),
      switchMap((selectedItem: SelectedItem) => {
        if (selectedItem.item) {
          if ('table_id' in selectedItem.item) {
            return this.metricsService
              .getMetricTableTaxonomies(
                selectedItem.metric.id,
                (selectedItem.item as MetricTableGroup).table_id,
                false,
                undefined,
                FieldInformationStore.TAXONOMY_ORDER_BY,
              )
              .pipe(map((response: ApiResponse<Taxonomy[]>) => response.data));
          } else {
            return this.metricsService
              .getFieldTaxonomies(
                selectedItem.metric.id,
                (selectedItem.item as ValueDefinition).value_definition_group_id,
                (selectedItem.item as ValueDefinition).id,
                false,
                undefined,
                FieldInformationStore.TAXONOMY_ORDER_BY,
              )
              .pipe(map((response: ApiResponse<Taxonomy[]>) => response.data));
          }
        } else {
          return of([]);
        }
      }),
      tapResponse(
        (taxonomies: Taxonomy[]) => {
          this.updateTaxonomies(taxonomies);
        },
        (_err) => {
          this.updateTaxonomies([]);
          return EMPTY;
        },
      ),
    ),
  );
}
