// Reference: https://github.com/bill-long/angular-rich-text-diff
// The css for marker tags is in markers.scss global css file
import { Directive, ElementRef, Input } from '@angular/core';
import { DiffMatchPatch } from 'diff-match-patch-typescript';

@Directive({
  selector: '[libTextCompare]',
})
export class TextCompareDirective {
  @Input() libTextCompare: string = '';
  @Input() comparisonText: string = '';

  tagMap: { [key: string]: string } = {};
  mapLength = 0;
  diffOutput: string = '';
  dmp: DiffMatchPatch;

  private readonly unicodeRangeStart = 0xe000;
  private readonly INS_OPEN_TAG = '<ins>';
  private readonly INS_CLOSE_TAG = '</ins>';
  private readonly DEL_OPEN_TAG = '<del>';
  private readonly DEL_CLOSE_TAG = '</del>';

  constructor(private elementRef: ElementRef) {
    this.dmp = new DiffMatchPatch();
    const unicodeCharacter = String.fromCharCode(this.unicodeRangeStart + this.mapLength);
    this.tagMap['&nbsp;'] = unicodeCharacter;
    this.tagMap[unicodeCharacter] = '&nbsp;';
    this.mapLength++;
  }

  ngOnChanges(): void {
    this.doDiff();
  }

  private doDiff(): void {
    const diffableLeft = this.convertHtmlToDiffableString(this.convertToHtmlString(this.libTextCompare));
    const diffableRight = this.convertHtmlToDiffableString(this.convertToHtmlString(this.comparisonText));
    const diffs = this.dmp.diff_main(diffableLeft, diffableRight);
    this.dmp.diff_cleanupSemantic(diffs);
    let diffOutput = '';
    diffs.forEach((diff, i) => {
      diffs[i][1] = this.insertTagsForOperation(diffs[i][1], diffs[i][0]);
      diffOutput += this.convertDiffableBackToHtml(diffs[i][1]);
    });
    this.elementRef.nativeElement.innerHTML = diffOutput;
  }

  private convertToHtmlString(text: string): string {
    return text.replaceAll('\n', '<br>');
  }

  private insertTagsForOperation(diffableString: string, operation: number): string {
    // Don't insert anything if these are all tags
    let n = -1;
    do {
      n++;
    } while (diffableString.charCodeAt(n) >= this.unicodeRangeStart + 1);

    if (n >= diffableString.length) {
      return diffableString;
    }

    let openTag = '';
    let closeTag = '';
    if (operation === 1) {
      openTag = this.INS_OPEN_TAG;
      closeTag = this.INS_CLOSE_TAG;
    } else if (operation === -1) {
      openTag = this.DEL_OPEN_TAG;
      closeTag = this.DEL_CLOSE_TAG;
    } else {
      return diffableString;
    }

    let outputString = openTag;
    let isOpen = true;
    for (let index = 0; index < diffableString.length; index++) {
      if (diffableString.charCodeAt(index) < this.unicodeRangeStart) {
        if (!isOpen) {
          outputString += openTag;
          isOpen = true;
        }
        outputString += diffableString[index];
      } else {
        if (isOpen) {
          outputString += closeTag;
          isOpen = false;
        }
        outputString += diffableString[index];
      }
    }
    if (isOpen) {
      outputString += closeTag;
    }
    return outputString;
  }

  private convertHtmlToDiffableString(htmlString: string): string {
    htmlString = htmlString.replace(/&nbsp;/g, this.tagMap['&nbsp;']);
    let diffableString = '';
    let offset = 0;
    while (offset < htmlString.length) {
      const tagStart = htmlString.indexOf('<', offset);
      if (tagStart < 0) {
        diffableString += htmlString.slice(offset);
        break;
      } else {
        const tagEnd = htmlString.indexOf('>', tagStart);
        if (tagEnd < 0) {
          diffableString += htmlString.substring(offset, tagStart);
          break;
        }
        const tagString = htmlString.substring(tagStart, tagEnd + 1);
        let unicodeCharacter = this.tagMap[tagString];
        if (unicodeCharacter === undefined) {
          unicodeCharacter = String.fromCharCode(this.unicodeRangeStart + this.mapLength);
          this.tagMap[tagString] = unicodeCharacter;
          this.tagMap[unicodeCharacter] = tagString;
          this.mapLength++;
        }
        diffableString += htmlString.substring(offset, tagStart);
        diffableString += unicodeCharacter;
        offset = tagEnd + 1;
      }
    }
    return diffableString;
  }

  private convertDiffableBackToHtml(diffableString: string): string {
    let htmlString = '';
    for (let index = 0; index < diffableString.length; index++) {
      const charCode = diffableString.charCodeAt(index);
      if (charCode < this.unicodeRangeStart) {
        htmlString += diffableString[index];
        continue;
      }
      const tagString = this.tagMap[diffableString[index]];
      if (tagString === undefined) {
        htmlString += diffableString[index];
      } else {
        htmlString += tagString;
      }
    }
    return htmlString;
  }
}
