<div
  *ngIf="control"
  libFormField
  [size]="size"
  [required]="required"
  [control]="control"
  [labelPosition]="labelPosition"
  [dataTestId]="'tree-select-input'"
  [class]="'p-field'"
>
  <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>

  <p-treeSelect
    [ariaLabelledBy]="_labelId"
    [options]="options"
    [formControl]="control"
    [id]="_labelId"
    [filter]="filter"
    [filterBy]="filterBy"
    [showClear]="showClear"
    [required]="required"
    [propagateSelectionDown]="false"
    [propagateSelectionUp]="false"
    [selectionMode]="selectionMode"
    [metaKeySelection]="false"
    [appendTo]="'body'"
    [ngClass]="{ invalid: control.invalid && (control.dirty || control.touched), disabled: control.disabled }"
    (onHide)="setToTouched()"
  ></p-treeSelect>

  <div class="subscript-wrapper">
    <ng-container *ngIf="(hint && control.valid) || control.untouched">
      <p class="message">
        {{ hint }}
      </p>
    </ng-container>

    <ng-container *ngIf="control.dirty || control.touched">
      <p *ngFor="let error of control.errors | errorKeys" id="error-message" class="p-error message">
        {{ errorMessages[error] }}
      </p>
    </ng-container>
  </div>
</div>
