import { Component, DoCheck, Input, ViewChild } from '@angular/core';

import { DocumentEditorContainer } from '@syncfusion/ej2-angular-documenteditor';
import { PdfBitmap, PdfDocument, PdfPageSettings, PdfSection, SizeF } from '@syncfusion/ej2-pdf-export';
import { DocumentEditorContainerTypes } from '../../../models';

@Component({
  selector: 'lib-file-export',
  templateUrl: './file-export.component.html',
})
export class FileExportComponent implements DoCheck {
  @ViewChild('ejsDocumentEditorContainer') public documentEditorContainer?: DocumentEditorContainer;

  // value must represent the sfdt file format
  @Input()
  set sfdtDocumentStructure(structure: string | undefined) {
    this.innerDocumentStructure = structure;
    this.shouldReload = true;
  }

  @Input()
  documentSizeFactor: number = 1;

  private shouldReload = false;
  private innerDocumentStructure?: string;

  get canSave(): boolean {
    return !!this.documentEditorContainer?.documentEditor && !this.shouldReload;
  }

  ngDoCheck(): void {
    if (this.innerDocumentStructure && this.shouldReload && this.documentEditorContainer?.documentEditor) {
      try {
        this.documentEditorContainer.documentEditor.open(this.innerDocumentStructure);
        this.shouldReload = false;
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  saveAsFormat(name: string, type: DocumentEditorContainerTypes): void {
    if (!this.canSave) {
      throw new Error('Document editor does not exist yet');
    }

    switch (type) {
      case DocumentEditorContainerTypes.DOCX:
      case DocumentEditorContainerTypes.SFDT:
        return this.documentEditorContainer?.documentEditor.save(name, type);
      case DocumentEditorContainerTypes.PDF:
        return this.setupPdf().save(`${name}.pdf`);
    }
  }

  blobAsFormat(type: DocumentEditorContainerTypes): Promise<Blob> | undefined {
    if (!this.canSave) {
      throw new Error('Document editor does not exist yet');
    }

    switch (type) {
      case DocumentEditorContainerTypes.DOCX:
      case DocumentEditorContainerTypes.SFDT:
        return this.documentEditorContainer?.documentEditor.saveAsBlob(type);
      case DocumentEditorContainerTypes.PDF:
        return this.setupPdf()
          .save()
          .then((content) => content.blobData);
      default:
        return;
    }
  }

  private setupPdf(): PdfDocument {
    if (!this.documentEditorContainer?.documentEditor) {
      throw new Error('Document editor does not exist yet');
    }
    const DATA_URI_SCHEME = 'data:image/jpeg;base64,';
    const pdfDocument = new PdfDocument();

    for (let i = 1; i <= this.documentEditorContainer.documentEditor.pageCount; i++) {
      const img = this.documentEditorContainer.documentEditor.exportAsImage(i, 'Jpeg');
      const imageHeight = parseInt(img.style.height.toString().replace('px', '')) * this.documentSizeFactor;
      const imageWidth = parseInt(img.style.width.toString().replace('px', '')) * this.documentSizeFactor;

      const PdfSection: PdfSection = pdfDocument.sections.add() as PdfSection;
      const settings = new PdfPageSettings(0);
      settings.size = new SizeF(imageWidth, imageHeight);
      PdfSection.setPageSettings(settings);
      const page = PdfSection.pages.add();
      const imageStr = img.src.replace(DATA_URI_SCHEME, '');
      const pdfImage = new PdfBitmap(imageStr);
      page.graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
    }

    return pdfDocument;
  }
}
