import { Pipe, PipeTransform } from '@angular/core';
import { Metric, ValueDefinitionDisplayType, ValueDefinitionGroup } from '../../../models';
import { ValueDefinitionUtils } from '../../classes/ValueDefinitionUtils/value-definition-utils';
import { MetricStructureSelectable, MetricStructureSelectableType } from '../../models';
import { MetricStructureUtils } from '../../classes/MetricStructureUtils/metric-structure-utils';
import { MetricUtils } from '../../../classes/MetricUtils/metric-utils';

const SUPPORTED_TYPES_FOR_CONDITIONAL_TRIGGERS = [
  ValueDefinitionDisplayType.choice,
  ValueDefinitionDisplayType.choice_multiple,
  ValueDefinitionDisplayType.choice_searchable,
  ValueDefinitionDisplayType.choice_radio,
  ValueDefinitionDisplayType.boolean,
  ValueDefinitionDisplayType.number,
];

@Pipe({
  name: 'canHaveConditionalTrigger',
})
export class CanHaveConditionalTriggerPipe implements PipeTransform {
  transform(
    selectedItem?: MetricStructureSelectable,
    selectedItemType?: MetricStructureSelectableType,
    valueDefinitionGroup?: ValueDefinitionGroup,
    metric?: Metric,
    isCore: boolean = false,
  ): boolean {
    if (!selectedItem || !metric) {
      return false;
    }
    return (
      selectedItemType === MetricStructureSelectableType.valueDefinition &&
      ValueDefinitionUtils.isValueDefinitionOfType(selectedItem, SUPPORTED_TYPES_FOR_CONDITIONAL_TRIGGERS) &&
      !valueDefinitionGroup?.repeatable &&
      !MetricStructureUtils.isEntityFromCoreRefV2Metric(selectedItem, metric) &&
      !(isCore && MetricUtils.isRefV2Metric(metric))
    );
  }
}
