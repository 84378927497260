import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';
import { PaginatorModule } from 'primeng/paginator';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../directives';
import { PipesModule } from '../pipes';
import { TableGridComponent } from './table-grid/table-grid.component';
import { TableGridExpandedRowComponent } from './table-grid/table-grid-expanded-row/table-grid-expanded-row.component';
import { ButtonModule } from 'primeng/button';
import { TableHeaderRowComponent } from './table-grid/table-header-row/table-header-row.component';
import { TableBodyRowComponent } from './table-grid/table-body-row/table-body-row.component';
import { TablePaginatorComponent } from './table-paginator/table-paginator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { FeatureFlagModule } from '../feature-flag';
import { TableBodyRowActionCellComponent } from './table-grid/table-body-row/table-body-row-action-cell/table-body-row-action-cell.component';
import { TreeTableComponent } from './tree-table/tree-table.component';
import { TreeTableModule } from 'primeng/treetable';
import { DoesActionMenuHasItemsPipe } from './table-grid/table-body-row/table-body-row-action-cell/does-action-menu-has-items.pipe';

@NgModule({
  declarations: [
    TableGridComponent,
    TableGridExpandedRowComponent,
    TableHeaderRowComponent,
    TableBodyRowComponent,
    TablePaginatorComponent,
    TableBodyRowActionCellComponent,
    TreeTableComponent,
    DoesActionMenuHasItemsPipe,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    TranslateModule,
    DirectivesModule,
    FeatureFlagModule,
    PipesModule,
    ReactiveFormsModule,
    // PrimeNg
    TableModule,
    RippleModule,
    PaginatorModule,
    ButtonModule,
    MenuModule,
    TreeTableModule,
  ],
  exports: [TableGridComponent, TreeTableComponent],
})
export class TablesModule {}
