import { Component, TemplateRef } from '@angular/core';
import { EventEmitter, Input, Output } from '@angular/core';

import { DataListContent } from '../models';
import { EmptyResults } from '../../models';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-data-list-remote-data',
  templateUrl: './data-list-remote-data.component.html',
  styleUrls: ['./data-list-remote-data.component.scss'],
  animations: [],
})
export class DataListRemoteDataComponent<T> {
  @Input() emptyResult: EmptyResults = {
    title: this.translateService.instant('No result found'),
  };
  @Input() content: DataListContent<T> = { all: [], data: [], loading: false, selected: [], total: 0 };
  @Input() dataFooterTemplate: TemplateRef<{ element: T }> | null = null;
  @Input() dataSubtitleKey?: string;
  @Input() dataTitleKey?: string;

  @Output() loadMore: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectRow: EventEmitter<T> = new EventEmitter<T>();

  constructor(private translateService: TranslateService) {}

  public loadMoreOptions(): void {
    if (!this.content.loading && this.content.all.length < this.content.total) {
      this.loadMore.emit();
    }
  }

  public onSelectRow(row: T): void {
    this.selectRow.emit(row);
  }
}
