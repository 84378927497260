import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateTemplateReportSectionPayload, TemplateReportSection } from '../../../../models';

export class TemplateReportSectionForm extends UntypedFormGroup {
  public readonly code = this.get('code') as UntypedFormControl;
  public readonly label = this.get('label') as UntypedFormControl;
  public readonly parentId = this.get('parentId') as UntypedFormControl;

  constructor(
    templateReportSection?: TemplateReportSection,
    sectionParentId?: string,
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
  ) {
    super({
      code: fb.control(templateReportSection?.code),
      label: fb.control(templateReportSection?.label, Validators.required),
      parentId: fb.control(sectionParentId),
    });
  }

  toModel(): CreateTemplateReportSectionPayload {
    return {
      code: this.code.value as string,
      label: this.label.value as string,
      parent_id: this.parentId.value ? (this.parentId.value as string) : undefined,
    };
  }
}
