import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, tap } from 'rxjs/operators';
import { TranslateService } from '../../../services/common';

@Component({
  selector: 'lib-filter-bar-search',
  templateUrl: './filter-bar-search.component.html',
  styleUrls: ['./filter-bar-search.component.scss'],
})
export class FilterBarSearchComponent implements OnInit, OnDestroy {
  private readonly DEBOUNCE_TIME: number = 500;

  @Input() initialSearch?: string;
  @Input() searchBarPlaceholder: string = this.translateService.instant('Search');

  @Output() searchChanged: EventEmitter<string> = new EventEmitter();
  @Output() searchTriggered: EventEmitter<string> = new EventEmitter();

  searchQuery: string = '';

  searchQueryChanged$: Subject<{ value?: string; debounceTime: number; enterKeyup?: boolean }> = new Subject();
  searchQueryChangedSubscription: Subscription | undefined;

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.searchQuery = this.initialSearch ?? '';
    this.searchQueryChangedSubscription = this.searchQueryChanged$
      .pipe(
        tap((queryData) => {
          if (typeof queryData.value === 'string') {
            this.searchQuery = queryData.value;
          }
        }),
        debounce((queryData) => timer(queryData.debounceTime)),
        distinctUntilChanged(),
      )
      .subscribe((queryData) => {
        if (queryData.enterKeyup) {
          this.searchTriggered.emit(this.searchQuery);
        } else {
          this.searchChanged.emit(this.searchQuery);
        }
      });
  }

  ngOnDestroy(): void {
    this.searchQueryChangedSubscription?.unsubscribe();
  }

  clearSearch(): void {
    this.searchQueryChanged$.next({
      value: '',
      debounceTime: 0,
    });
  }

  onSearchQueryChanged(query?: string, enterKeyup = false): void {
    this.searchQueryChanged$.next({ value: query, debounceTime: this.DEBOUNCE_TIME, enterKeyup });
  }
}
