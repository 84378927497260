/* eslint-disable no-control-regex */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StripNonPrintableCharactersService {
  // regex char range is split to allow Line Feed (\n) to pass
  private readonly NON_PRINTABLE_CHARS_REGEX = /[\x00-\x09\x0B-\x1F\x7F\xA0]/g;
  private readonly SPACE_CHAR = String.fromCharCode(32);
  private readonly NOT_SUPPORTED_SPACE_CHARS_REGEX =
    /[\u00A0\u1680\u180E\u2000-\u200B\u202F\u205F\u3000\uFEFF\xa0\u2007\u2060]/g;

  public containsNonPrintableCharacters(value: string): boolean {
    if (!value) {
      return false;
    }
    return new RegExp(this.NON_PRINTABLE_CHARS_REGEX).test(value);
  }

  /**
   * Strips control characters or replace them with spaces if no spaces are found in the vicinity of it
   */
  public sanitize(value: string): string {
    if (this.containsNonPrintableCharacters(value)) {
      return value.replace(this.NON_PRINTABLE_CHARS_REGEX, (_, index: number) => {
        const hasSpaceLeft = value.charAt(index - 1) == this.SPACE_CHAR;
        const hasSpaceRight = value.charAt(index + 1) == this.SPACE_CHAR;
        return !hasSpaceLeft && !hasSpaceRight ? this.SPACE_CHAR : '';
      });
    }

    return value;
  }

  // Replaces non-break space characters into a normal space character (space bar in the keyboard)
  public sanitizeWhitespaces(numberToFormat: string): string {
    return numberToFormat.replace(this.NOT_SUPPORTED_SPACE_CHARS_REGEX, this.SPACE_CHAR);
  }
}
