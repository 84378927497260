<lib-rich-text-input
  *ngIf="!showDummyControl; else dummyControl"
  [control]="valueFormControl"
  [hint]="hint"
  [label]="label"
  [placeholder]="placeholder"
  [autoSaveInterval]="autoSaveInterval"
  [autofocus]="true"
  (blur)="onBlur()"
></lib-rich-text-input>

<ng-template #dummyControl>
  <lib-form-field-label *ngIf="valueFormControl" [label]="label" [control]="valueFormControl"></lib-form-field-label>
  <div (click)="switchToActualControl()" class="dummy-container" data-testid="dummyControl" *ngIf="valueFormControl">
    <div
      class="editor"
      [ngClass]="{
        'error-border': valueFormControl.invalid && valueFormControl.touched,
        disabled: valueFormControl.disabled,
      }"
    >
      <div class="actions"></div>
      <div class="textarea pl-1">
        <p *ngIf="valueFormControl.value; else rtePlaceholder" [innerHTML]="valueFormControl.value"></p>
      </div>
    </div>
    <div class="footer">
      <p *ngIf="(valueFormControl.valid || valueFormControl.untouched) && hint">{{ hint }}</p>
      <ng-container *ngIf="valueFormControl.invalid && valueFormControl.touched">
        <p class="dummy-error m-0" *ngFor="let error of valueFormControl.errors | errorKeys">
          {{ errorMessages[error] }}
        </p>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #rtePlaceholder>
  <p class="placeholder">{{ placeholder }}</p>
</ng-template>
