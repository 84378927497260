import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  ActionItem,
  DataRequestStatus,
  DataRequestStatusIcon,
  ItemType,
  Org,
  Presentation,
  Source,
  User,
} from '../../../models';
import { TranslateService, PeersService, UsersService } from '../../../services/common';
import { DataRequestsRequestStatus, SourcesSourceTypes } from '../../../translations';

@Component({
  selector: 'lib-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input({ required: true }) presentation?: Presentation;
  @Input() item?: ActionItem;
  @Input() path: string = '';
  @Input() pathPosition: 'before' | 'after' = 'before';
  @Input() mode: 'nav' | 'drag' | 'select' | 'view' = 'nav';
  @Input() limitedCardSize: boolean = false;
  @Input() canClickItem: boolean = true;

  @Output() action: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();

  eItemType = ItemType;
  ePresentation = Presentation;
  eDataRequestStatus = DataRequestStatus;

  dataRequestStatusIcon = DataRequestStatusIcon;
  readonly dataRequestsRequestStatus = DataRequestsRequestStatus;
  readonly sourcesSourceTypes = SourcesSourceTypes;

  constructor(
    private translateService: TranslateService,
    private peersService: PeersService,
    private usersService: UsersService,
  ) {}

  getQueryParams(highlight: any): any {
    if (highlight) {
      return {
        h: highlight
          .replace(/<\/?[^>]+(>|$)/g, '')
          .replace(/\r?\n|\r/g, ' ')
          .replace(/\s+/g, ' '),
      };
    }
    return { h: null };
  }

  link(item?: ActionItem): string | null {
    if (this.canClickItem && item) {
      if (this.path) {
        switch (this.pathPosition) {
          case 'before':
            return this.path + '/' + item.id;
          case 'after':
            return item.id + '/' + this.path;
        }
      } else {
        return item.id;
      }
    }

    return null;
  }

  public selectItem(item: ActionItem | undefined): void {
    this.action.emit({
      id: 'select',
      title: this.translateService.instant('Select'),
      item,
    });
  }

  public selectButton(event: MouseEvent, item: ActionItem | undefined, id: string, title: string): void {
    event.preventDefault();
    event.stopPropagation();
    this.emitSelectButtonAction({ id, title, item });
  }

  public emitSelectButtonAction(actionItem: ActionItem<ActionItem>): void {
    this.action.emit({
      id: actionItem.id,
      title: actionItem.title,
      item: actionItem.item,
      action: 'no_highlight',
    });
  }

  public showCardValue(itemTypes: ItemType[]): boolean {
    return itemTypes.includes(<ItemType>this.item?.item_type);
  }

  public formatFooterDescription(item?: ActionItem): string {
    if (!item) {
      return '';
    }
    switch (item.item_type) {
      case 'peers_peer':
        return this.peersService.getOrgDescription(item.item as Org);
      case 'library_peers_peer':
        return this.peersService.getOrgDescription(item.item as Org);
      case 'users_user':
        return this.usersService.getDescription(item.item as User);
      default:
        throw Error(`Unsupported item type: ${item.item_type}`);
    }
  }

  public getByKey<T>(cls: Record<string, T>, key: string): T {
    return cls[key];
  }

  public isDefault(source: Source): boolean {
    return source.is_default;
  }
}
