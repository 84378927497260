import { Injectable } from '@angular/core';
import { ValueGroup } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ValueGroupService {
  private static nextId = 0;

  public static getUniqueIdentifier(valueGroup: ValueGroup): string {
    return `${valueGroup.value_definition_group_id}-${valueGroup.position}-${this.nextId++}`;
  }
}
