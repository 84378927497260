import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, ApplicationApiDefinition, SearchOptions, UnitGroup, UnitGroupItem } from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class AdminUnitGroupService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.unit_groups;
  }

  public search(searchOptions: SearchOptions): Observable<ApiResponse<UnitGroup[]>> {
    let params = new HttpParams().append('load_unit_group_items', false);
    if (searchOptions.sort) {
      params = params.append('order_by', searchOptions.sort.id);
      if (searchOptions.sort.id === 'updated') {
        params = params.append('order_by_direction', 'desc');
      }
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/unit_groups`, { params }).pipe(
      map((result: ApiResponse<UnitGroup[]>) => {
        if (result.data.length) {
          if (searchOptions.query.keywords) {
            result.data = result.data.filter((item) =>
              item.name.toLowerCase().includes(searchOptions.query.keywords.toLowerCase()),
            );
            result.meta.count = result.data.length;
          }
        }
        return result;
      }),
    );
  }

  public searchItems(searchOptions: SearchOptions): Observable<ApiResponse<UnitGroupItem[]>> {
    let params = new HttpParams();
    if (searchOptions.sort) {
      params = params.append('order_by', searchOptions.sort.id);
    }
    return this.apiService
      .get(`${this.servicePath}${this.resource}/unit_groups/${searchOptions.custom_filters?.id}/unit_group_items`, {
        params,
      })
      .pipe(
        map((result: ApiResponse<UnitGroupItem[]>) => {
          if (result.data.length) {
            if (searchOptions.query.keywords) {
              result.data = result.data.filter((item) =>
                item.name.toLowerCase().includes(searchOptions.query.keywords.toLowerCase()),
              );
              result.meta.count = result.data.length;
            }
          }
          return result;
        }),
      );
  }

  public createUnitGroup(name: string, description: string, visibility: boolean): Observable<ApiResponse<UnitGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/unit_groups`, {
      name,
      description,
      public: visibility,
    });
  }

  public updateUnitGroup(
    unitGroupId: string,
    name: string,
    description: string,
    visibility: boolean,
  ): Observable<ApiResponse<UnitGroup>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/unit_groups/${unitGroupId}`, {
      name,
      description,
      public: visibility,
    });
  }

  public getUnitGroup(unitGroupId: string): Observable<ApiResponse<UnitGroup>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/unit_groups/${unitGroupId}`);
  }

  public getUnitGroupItem(unitGroupId: string, unitGroupItemId: string): Observable<ApiResponse<UnitGroupItem>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/unit_groups/${unitGroupId}/unit_group_items/${unitGroupItemId}`,
    );
  }

  public moveUnitGroupItem(
    unitGroupId: string,
    unitGroupItemId: string,
    position: number,
  ): Observable<ApiResponse<UnitGroupItem>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/unit_groups/${unitGroupId}/unit_group_items/${unitGroupItemId}/move/${position}`,
      {},
    );
  }

  public deactivateUnitGroupItem(unitGroupId: string, unitGroupItemId: string): Observable<ApiResponse<UnitGroupItem>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/unit_groups/${unitGroupId}/unit_group_items/${unitGroupItemId}/deactivate`,
      {},
    );
  }

  public activateUnitGroupItem(unitGroupId: string, unitGroupItemId: string): Observable<ApiResponse<UnitGroupItem>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/unit_groups/${unitGroupId}/unit_group_items/${unitGroupItemId}/activate`,
      {},
    );
  }

  public deactivateUnitGroup(unitGroupId: string): Observable<ApiResponse<UnitGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/unit_groups/${unitGroupId}/deactivate`, {});
  }

  public activateUnitGroup(unitGroupId: string): Observable<ApiResponse<UnitGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/unit_groups/${unitGroupId}/activate`, {});
  }

  public createUnitGroupItem(
    unitGroupId: string,
    name: string,
    symbol: string,
    tooltip: string,
  ): Observable<ApiResponse<UnitGroupItem>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/unit_groups/${unitGroupId}/unit_group_items`, {
      name,
      symbol,
      tooltip,
      public: true,
    });
  }

  public updateUnitGroupItem(
    unitGroupId: string,
    unitGroupItemId: string,
    name: string,
    symbol: string,
    tooltip: string,
  ): Observable<ApiResponse<UnitGroupItem>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/unit_groups/${unitGroupId}/unit_group_items/${unitGroupItemId}`,
      {
        name,
        symbol,
        tooltip,
        public: true,
      },
    );
  }
}
