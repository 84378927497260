import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { WaitableForNextUpdate } from './waitableForNextUpdate';

export abstract class MetricEditorFormGroup extends UntypedFormGroup implements WaitableForNextUpdate {
  protected _isWaitingForNextUpdate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isWaitingForNextUpdate$ = this._isWaitingForNextUpdate$.asObservable();

  abstract get id(): string;
  abstract get definitionId(): string;
  abstract get position(): number;
  abstract get subposition(): number;

  abstract updateGroup(group: unknown): void;

  public waitForNextUpdate(): void {
    this._isWaitingForNextUpdate$.next(true);
  }
}
