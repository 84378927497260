import { Pipe, PipeTransform } from '@angular/core';
import { ValueDefinition, ValueDefinitionDisplayType } from '../../models';
import { MetricStructureSelectable } from '../models';
import { ValueDefinitionUtils } from '../classes/ValueDefinitionUtils/value-definition-utils';

@Pipe({
  name: 'isValueDefinitionOfType',
})
export class IsValueDefinitionOfTypePipe implements PipeTransform {
  transform(
    valueDefinition: MetricStructureSelectable | ValueDefinition,
    types: ValueDefinitionDisplayType[],
  ): boolean {
    return ValueDefinitionUtils.isValueDefinitionOfType(valueDefinition, types);
  }
}
