import { DataTableComparisonType, StringCase, StringForm } from '../../models';
import { SortingTranslation } from '../sorting.translation';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const DataTableSort = {
  updated: SortingTranslation.last_updated,
  name: SortingTranslation.alphabetical,
};

export const DataTableTranslations: Record<DataTableComparisonType, Record<StringForm, Record<StringCase, string>>> = {
  [DataTableComparisonType.fiscal_year]: {
    [StringForm.plural]: {
      [StringCase.lower]: _('fiscal year comparisons'),
      [StringCase.sentense]: _('Fiscal year comparisons'),
      [StringCase.title]: _('Fiscal Year Comparisons'),
    },
    [StringForm.singular]: {
      [StringCase.lower]: _('fiscal year comparison'),
      [StringCase.sentense]: _('Fiscal year comparison'),
      [StringCase.title]: _('Fiscal Year Comparison'),
    },
  },
  [DataTableComparisonType.source]: {
    [StringForm.plural]: {
      [StringCase.lower]: _('source comparisons'),
      [StringCase.sentense]: _('Source comparisons'),
      [StringCase.title]: _('Source Comparisons'),
    },
    [StringForm.singular]: {
      [StringCase.lower]: _('source comparison'),
      [StringCase.sentense]: _('Source comparison'),
      [StringCase.title]: _('Source Comparison'),
    },
  },
};
