import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, Source } from '../../../models';
import { ClientSourcesV2Service } from '../../client/client-sources/client-sources-v2.service';

@Injectable({
  providedIn: 'root',
})
export class SourcesService {
  constructor(private clientSourcesV2Service: ClientSourcesV2Service) {}

  public listSources(): Observable<ApiResponse<Source[]>> {
    return this.clientSourcesV2Service.listSources({});
  }
}
