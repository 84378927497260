import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiResponse, ApplicationApiDefinition, Doc, DocMetaData, SearchOptions, ItemType } from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class AdminDocumentsService {
  searchApiName: keyof ApplicationApiDefinition = 'search';
  documentApiName: keyof ApplicationApiDefinition = 'documents';
  searchServicePath: string;
  documentServicePath: string;
  documentsSearchResource: string;
  documentsResource: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.searchServicePath = apiService.getServicePath(this.searchApiName);
    this.documentServicePath = apiService.getServicePath(this.documentApiName);
    this.documentsSearchResource = this.apiService.apiConfig.apis.search.resources.documents_search;
    this.documentsResource = this.apiService.apiConfig.apis.documents.resources.resources;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): any {
    if (!searchOptions.custom_filters) {
      searchOptions.custom_filters = {};
    }
    searchOptions.filters = this.baseService.renameObjectProperty(searchOptions.filters, 'source', 'org_id');
    switch (searchOptions.item_type) {
      case ItemType.library_peers_report:
        searchOptions.custom_filters.scope = 'report';
        break;
      default:
        break;
    }
    const payload: any = this.baseService.esPayloadFromSearchOptions(searchOptions);
    return payload;
  }

  getDocumentMetaData(docId: string): Observable<ApiResponse<DocMetaData>> {
    return this.apiService.get(`${this.documentServicePath}${this.documentsResource}/${docId}/metadata`);
  }

  updateDocument(docId: string, payload: any): Observable<ApiResponse<any>> {
    return this.apiService.post(`${this.documentServicePath}${this.documentsResource}/${docId}/metadata`, payload);
  }

  deleteDocument(docId: string): Observable<ApiResponse<Doc>> {
    return this.apiService.delete(`${this.documentServicePath}${this.documentsResource}/${docId}`);
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Doc[]>> {
    const payload: any = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.searchServicePath}${this.documentsSearchResource}/search`, payload);
  }
}
