import { CreatedUpdatedEntity } from './entities';
import { Metric, MetricTableDefinition, ValueDefinition, ValueDefinitionGroup } from './metrics';
import { Value } from './values';

export enum RelatedFieldType {
  PLATFORM = 'platform',
  CORE = 'core',
}

export interface RelatedField extends CreatedUpdatedEntity {
  core_field_relation_id?: string;
  id: string;
  from_metric: Metric;
  from_metric_table_definition?: MetricTableDefinition;
  from_metric_table_definition_id?: string;
  from_value_definition?: ValueDefinition;
  from_value_definition_id?: string;
  to_metric: Metric;
  to_metric_table_definition?: MetricTableDefinition;
  to_metric_table_definition_id?: string;
  to_value_definition?: ValueDefinition;
  to_value_definition_id?: string;
}

export interface FieldRelatedFieldWithValue {
  metric_id: string;
  metric: Metric;
  value_definition_group: ValueDefinitionGroup;
  value_definition: ValueDefinition;
  values: Value[];
}

export interface AddFieldRelationsRequestItem {
  to_metric_table_definition_id?: string;
  to_value_definition_id?: string;
}

export interface AddFieldRelationsRequest {
  field_relations: AddFieldRelationsRequestItem[];
}
