import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ActionItem, ItemType, Layout, Metric, MetricCategory, Presentation, ReportCategoryType } from '../../models';
import { SearchResultsActionItem } from '../../search';
import { TemplateReportsApiService } from '../../services/api-services';
import { TranslateService } from '../../services/common';
import { TemplateReportStructureStateService } from '../services/template-report-structure-state.service';

@Component({
  selector: 'lib-template-report-structure-left-panel',
  templateUrl: './template-report-structure-left-panel.component.html',
  styleUrls: ['./template-report-structure-left-panel.component.scss'],
})
export class TemplateReportStructureLeftPanelComponent implements OnInit {
  @Input({ required: true }) templateReportId!: string;

  @Output() previewMetric: EventEmitter<Metric> = new EventEmitter<Metric>();

  searchFilters$?: Observable<{ framework_ids?: string[]; categories?: string[]; reference_v2?: boolean }>;
  public templateType: ReportCategoryType = ReportCategoryType.questionnaire;

  readonly eLayout = Layout;
  readonly ePresentation = Presentation;
  readonly itemTypeAdminTemplatesMetrics: ItemType = ItemType.admin_templates_metrics;
  readonly sortOrder = { id: 'code', title: this.translateService.instant('Alphabetical') };

  constructor(
    private readonly translateService: TranslateService,
    private readonly templateReportsApiService: TemplateReportsApiService,
    private templateReportStructureStateService: TemplateReportStructureStateService,
  ) {}

  ngOnInit(): void {
    this.searchFilters$ = this.templateReportStructureStateService.templateReport$.pipe(
      map((template) => {
        this.templateType = template.category;
        const filters: { framework_ids?: string[]; categories?: string[]; reference_v2?: boolean } = {};
        if (this.templateType === ReportCategoryType.template_framework) {
          filters.categories = [MetricCategory.REFERENCE];
          filters.reference_v2 = template.reference_v2;
        } else {
          filters.framework_ids = template.frameworks.map((framework) => framework.id);
        }
        return filters;
      }),
    );
  }

  handleSearchAction(action: ActionItem<Metric>): void {
    if (action.id === SearchResultsActionItem.preview) {
      this.previewMetric.emit(action.item);
    }
  }
}
