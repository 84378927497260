import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationGroup, NavigationMenu } from '../models';
import { Permission, User } from '../../models';
import { AuthService } from '../../services/common';
import { Observable } from 'rxjs';
import { ObservableUtils } from '../../classes';
import { map } from 'rxjs/operators';
import { FeatureFlagKeys } from '../../feature-flag';

@Component({
  selector: 'lib-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss', '../navigation.scss'],
})
export class NavigationMenuComponent implements OnInit {
  @Input() navigationGroups: NavigationGroup[] = [];
  @Input() directoryRootPath: string = '';
  @Input() permissions?: Permission[];
  @Input() featureFlags?: FeatureFlagKeys | FeatureFlagKeys[];

  filteredNavigationGroups$?: Observable<NavigationGroup[]>;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    if (!this.navigationGroups.length && this.directoryRootPath === '') {
      throw Error('For a single action navigation menu you must provide a directory root path');
    }

    this.filteredNavigationGroups$ = this.authService.getUserInfo().pipe(
      ObservableUtils.filterNullish(),
      map((user: User) => user.permissions),
      map((userPermissions: Permission[]) => this.getFilteredOutGroupsWithoutRequiredPermissions(userPermissions)),
    );
  }

  public isDirectoryPath() {
    return this.router.isActive(this.directoryRootPath, false);
  }

  private hasPermission(userPermissions: Permission[], requiredPermissions: Permission[]): boolean {
    return requiredPermissions.length > 0
      ? requiredPermissions.some((requiredPermission: Permission) => userPermissions.includes(requiredPermission))
      : true;
  }

  private getFilteredOutGroupsWithoutRequiredPermissions(userPermissions: Permission[]): NavigationGroup[] {
    return this.navigationGroups.filter((navigationGroup: NavigationGroup) => {
      const groupPermissions = NavigationMenuComponent.getGroupRequiredPermissions(navigationGroup);
      return this.hasPermission(userPermissions, groupPermissions);
    });
  }

  private static getGroupRequiredPermissions(navigationGroup: NavigationGroup): Permission[] {
    return navigationGroup.options.reduce((groupPermissions: Permission[], navigationMenu: NavigationMenu) => {
      if (navigationMenu.permissions) {
        return [...groupPermissions, ...navigationMenu.permissions];
      }
      return groupPermissions;
    }, []);
  }
}
