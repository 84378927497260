import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Directive({
  selector: '[libRightClickMenuTrigger]',
})
export class RightClickMenuTriggerDirective {
  @Input() matMenuTrigger?: MatMenuTrigger;
  @Input() menuData: unknown;
  @Output() position = new EventEmitter<{ x: string; y: string }>();

  @HostListener('contextmenu', ['$event']) onRightClick(event: MouseEvent) {
    event.preventDefault();
    this.position.emit({ x: event.clientX + 'px', y: event.clientY + 'px' });
    if (this.matMenuTrigger) {
      this.matMenuTrigger.menuData = { menuData: this.menuData };
      this.matMenuTrigger?.openMenu();
    }
  }
}
