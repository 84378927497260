import { Component, Input } from '@angular/core';
import { ActionItem, ValueDefinitionTemplate } from '../../../models';
import { MetricStructureStateService } from '../../services/metric-structure-state.service';
import { Observable } from 'rxjs';
import { ValueDefinitionTemplateType } from '../../models';
import { TranslateService } from '../../../services/common';

@Component({
  selector: 'lib-metric-structure-fields-selection',
  templateUrl: './metric-structure-fields-selection.component.html',
  styleUrls: ['./metric-structure-fields-selection.component.scss'],
})
export class MetricStructureFieldsSelectionComponent {
  @Input() disableFields: boolean = false;
  @Input() valueDefinitionTemplates: ValueDefinitionTemplate[] = [];
  @Input() disableSpecialTypes: boolean = false;

  isCreatingField$: Observable<boolean> = this.metricStructureService.isCreatingField$;

  formRequirementsItems: ActionItem[] = [
    {
      id: ValueDefinitionTemplateType.category,
      title: this.translateService.instant('Form requirements'),
      disabled: false,
      icon: 'category',
    },
    {
      id: ValueDefinitionTemplateType.group,
      title: this.translateService.instant('Group +'),
      disabled: false,
      icon: 'group',
    },
  ];

  specialActionItems: ActionItem[] = [
    {
      id: ValueDefinitionTemplateType.table,
      title: this.translateService.instant('Table +'),
      disabled: false,
      icon: 'table',
    },
  ];

  constructor(
    private metricStructureService: MetricStructureStateService,
    private translateService: TranslateService,
  ) {}

  public create(elementType: string): void {
    switch (elementType) {
      case ValueDefinitionTemplateType.group:
        this.metricStructureService.createGroup();
        break;
      case ValueDefinitionTemplateType.table:
        this.metricStructureService.createMetricTable();
        break;
      default:
        throw new Error('special element type is not group or table');
    }
  }
}
