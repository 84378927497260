import { Injectable } from '@angular/core';
import { BaseClientLicenseeSettingsService } from './base-client-licensee-settings.service';
import { ApiResponse, ApplicationApiDefinition, ClientDataRequestSettings } from '../../../models';
import { ApiService } from '../../common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicLicenseeSettingsService extends BaseClientLicenseeSettingsService {
  private publicApiName: keyof ApplicationApiDefinition = 'public';
  private publicResource: string;
  private publicServicePath: string;

  constructor(private readonly apiService: ApiService) {
    super();

    this.publicResource = apiService.getServicePath(this.publicApiName);
    this.publicServicePath = this.apiService.apiConfig.apis.public.resources.platform;
  }

  getClientDataRequestSettings(): Observable<ApiResponse<ClientDataRequestSettings>> {
    return this.apiService.get(`${this.publicResource}${this.publicServicePath}/data_requests_settings`);
  }
}
