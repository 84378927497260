import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActionItem, ButtonTypeEnum } from '../../../models';

type ButtonType = keyof typeof ButtonTypeEnum;
@Component({
  selector: 'lib-options-button',
  templateUrl: './options-button.component.html',
  styleUrls: ['./options-button.component.scss'],
})
export class OptionsButtonComponent {
  @Input() type?: ButtonType;
  @Input() disabled: boolean = false;
  @Input({ required: true }) options: ActionItem[] = [];
  @Input() label: string = '';

  @Output() handleSelection = new EventEmitter<ActionItem>();
}
