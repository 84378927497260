import { Injectable } from '@angular/core';
import DOMPurify from 'dompurify';

@Injectable({
  providedIn: 'root',
})
export class HtmlToStringService {
  convertTipRichTextToPlain(html: string, len: number): string {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = DOMPurify.sanitize(html);

    if (tempDivElement.textContent) {
      if (tempDivElement.textContent.trim().length >= len && len > 0) {
        return tempDivElement.textContent.trim().substring(0, len).concat('...');
      }
      return tempDivElement.textContent.trim().substring(0, tempDivElement.textContent.trim().length).concat('...');
    } else if (tempDivElement.innerText) {
      if (tempDivElement.innerText.length >= len && len > 0) {
        tempDivElement.innerText.trim().substring(0, len).concat('...');
      }
      tempDivElement.innerText.trim().substring(0, tempDivElement.innerText.trim().length).concat('...');
    }
    return '';
  }
}
