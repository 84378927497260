import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../services/common';
import { VGSetStatusContext } from '../../models';
import { UpdatedByUserPipe } from './updated-by-user.pipe';

@Pipe({
  name: 'updatedByGHGIntegrationSystem',
})
export class UpdatedByGHGIntegrationSystemPipe implements PipeTransform {
  static readonly CARBON_MANAGEMENT_AUTOMATION_USER_NAME: string = 'Carbon Management Automation';

  constructor(private translateService: TranslateService) {}

  transform(updatedByUser: string, vgsStatusContext?: VGSetStatusContext | null): string {
    if (
      [UpdatedByUserPipe.UNKNOWN_USER_NAME, UpdatedByUserPipe.NOVISTO_SYSTEM_ADMIN_USER_NAME].includes(updatedByUser) &&
      vgsStatusContext &&
      [
        VGSetStatusContext.imported_from_green_project,
        VGSetStatusContext.some_values_entered_via_green_project_api,
        VGSetStatusContext.imported_from_minimum,
        VGSetStatusContext.some_values_entered_via_minimum_api,
      ].includes(vgsStatusContext)
    ) {
      return this.translateService.instant(UpdatedByGHGIntegrationSystemPipe.CARBON_MANAGEMENT_AUTOMATION_USER_NAME);
    }
    return updatedByUser;
  }
}
