import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionItem } from '../../models';

@Component({
  selector: 'lib-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrls: ['./dialog-title.component.scss'],
})
export class DialogTitleComponent implements OnInit {
  @Input() title: string = '';
  @Input() modal: boolean = false;
  @Input() error: string = '';
  @Input() hasGoBackButton: boolean = false;
  @Input() removeMargin: boolean = false;
  @Input() tabs: ActionItem[] = [];
  @Input() defaultSelectedTab?: ActionItem;
  @Input() maxWidth: string = 'none';
  @Input() isOfflineDialog: boolean = false;

  @Output() closeEvent = new EventEmitter<void>();
  @Output() goBackEvent = new EventEmitter<void>();
  @Output() tabSwitchEvent = new EventEmitter<ActionItem>();

  selectedTab?: ActionItem;

  ngOnInit(): void {
    if (this.tabs.length) {
      this.selectedTab = this.defaultSelectedTab ?? this.tabs[0];
    }
  }

  switchTab(tab: ActionItem): void {
    this.selectedTab = tab;
    this.tabSwitchEvent.emit(tab);
  }

  onClose(): void {
    this.closeEvent.emit();
  }

  onGoBack(): void {
    this.goBackEvent.emit();
  }
}
