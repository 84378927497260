<lib-panel-details-items
  *ngIf="templateReportSectionsLabelById$ | async as templateReportSectionsLabelById"
  [panelActions]="panelActions"
  [(activeTab)]="activeTab"
  [navigateBackOnPanelClose]="false"
  [loaded]="!!selectedItem || (activeTab === +eTemplateReportPanels.SECTION && !isEditing)"
  (isOpened)="closePanel.emit()"
  [ngSwitch]="activeTab"
>
  <lib-template-report-panel-settings
    *ngSwitchCase="+eTemplateReportPanels.SETTINGS"
    [managePermissions]="managePermissions"
    [templateReport]="$any(selectedItem)"
    [templateReportId]="templateReportId"
    (cancelAction)="closePanel.emit()"
    (updateVersion)="updateVersion.emit($event)"
  ></lib-template-report-panel-settings>

  <lib-template-report-panel-section-form
    *ngSwitchCase="+eTemplateReportPanels.SECTION"
    [managePermissions]="managePermissions"
    [templateReportId]="(templateReportId$ | async) ?? undefined"
    [templateReportVersionId]="(templateReportVersionId$ | async) ?? undefined"
    [templateReportSection]="$any(selectedItem)"
    [editingSection]="isEditing"
    [sectionsLabelById]="templateReportSectionsLabelById"
    (closePanel)="closePanel.emit()"
  ></lib-template-report-panel-section-form>

  <lib-template-report-panel-section-metric-form
    *ngSwitchCase="+eTemplateReportPanels.SECTION_METRIC"
    [managePermissions]="managePermissions"
    [templateReportId]="(templateReportId$ | async) ?? undefined"
    [templateReportVersionId]="(templateReportVersionId$ | async) ?? undefined"
    [templateReportSectionMetric]="$any(selectedItem)"
    [editingMetric]="isEditing"
    [sectionsLabelById]="templateReportSectionsLabelById"
    [sourceConfiguration]="sourceConfiguration"
    (closePanel)="closePanel.emit()"
  ></lib-template-report-panel-section-metric-form>
</lib-panel-details-items>
