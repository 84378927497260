import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { OptionListType } from '../../models';

export { OptionListStatusTranslations, OptionListTypeTranslations };

const OptionListStatusTranslations = {
  active: _('Active'),
  inactive: _('Inactive'),
};

const OptionListTypeTranslations = {
  [OptionListType.third_party_metrics]: _('Third-Party Metrics'),
  [OptionListType.reference]: _('Reference'),
  [OptionListType.resource_list]: _('Resource List'),
};
