import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../common/api/api.service';
import {
  ApiResponse,
  ApplicationApiDefinition,
  Framework,
  SearchOptions,
  TaxonomiesPayload,
  Taxonomy,
} from '../../../models';
import { BaseService } from '../../common/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class AdminFrameworkService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.frameworks;
  }

  public searchFrameworks(searchOptions: SearchOptions): Observable<ApiResponse<Framework[]>> {
    const params = new HttpParams().append('category', searchOptions.custom_filters!.category as string);
    return this.apiService.get(`${this.servicePath}${this.resource}/frameworks`, { params }).pipe(
      map((result: ApiResponse<Framework[]>) => {
        if (result.data.length) {
          // filter by keyword
          if (searchOptions.query.keywords) {
            result.data = result.data.filter((item) =>
              item.name.toLowerCase().includes(searchOptions.query.keywords.toLowerCase()),
            );
            result.meta.count = result.data.length;
          }

          // sort by name
          result.data = result.data.sort((a, b) => a.name.localeCompare(b.name));
        }
        return result;
      }),
    );
  }

  public listFrameworks(): Observable<ApiResponse<Framework[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/frameworks`);
  }

  public getFramework(frameworkId: string): Observable<ApiResponse<Framework>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/frameworks/${frameworkId}`);
  }

  public listFrameworkTaxonomies(frameworkId: string, listLeafs = false): Observable<ApiResponse<Taxonomy[]>> {
    const params = new HttpParams().append('list_leafs', listLeafs);
    return this.apiService.get(`${this.servicePath}${this.resource}/frameworks/${frameworkId}/taxonomies`, {
      params,
    });
  }

  public createFrameworkTaxonomy(frameworkId: string, payload: TaxonomiesPayload): Observable<ApiResponse<Taxonomy>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/frameworks/${frameworkId}/taxonomies`, payload);
  }

  public deleteFrameworkTaxonomy(frameworkId: string, taxonomyId: string): Observable<undefined> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/frameworks/${frameworkId}/taxonomies/${taxonomyId}`,
    );
  }

  public editFrameworkTaxonomy(
    frameworkId: string,
    taxonomyId: string,
    payload: TaxonomiesPayload,
  ): Observable<ApiResponse<Taxonomy>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/frameworks/${frameworkId}/taxonomies/${taxonomyId}`,
      payload,
    );
  }

  public getFrameworkTaxonomy(frameworkId: string, taxonomyId: string): Observable<ApiResponse<Taxonomy>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/frameworks/${frameworkId}/taxonomies/${taxonomyId}`,
    );
  }
}
