import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BooleanTypeDetails, BooleanValue } from '../../../../models';
import { ValueFormControl } from '../../../models/valueFormControl';
import {
  DisplayAdditionalText,
  MetricEditorBooleanService,
} from '../../../services/metric-editor-boolean/metric-editor-boolean.service';
import { ObservableUtils } from '../../../../classes';
import { ValueDefinitionConstants } from '../../../../constants';

type BooleanFieldFormModel = {
  value: FormControl<string | null>;
  additionalText: FormControl<string | null>;
};

export class BooleanFieldFormGroup extends FormGroup<BooleanFieldFormModel> {
  public readonly valueFormControl: ValueFormControl<BooleanTypeDetails>;
  public displayAdditionalText?: DisplayAdditionalText;

  public subscriptions: Subscription[] = [];

  oldValue?: BooleanValue;
  oldStatus = '';
  oldTouchedStatus = false;

  constructor(
    valueFormControl: ValueFormControl<BooleanTypeDetails>,
    private metricEditorBooleanService: MetricEditorBooleanService,
    private readonly fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      value: fb.control(valueFormControl.value?.value != null ? String(valueFormControl.value?.value) : null, {
        validators: valueFormControl.validator,
        updateOn: 'change',
      }),
      additionalText: fb.control(null),
    });
    this.valueFormControl = valueFormControl;
    this.initTextFormControl();
    this.setValueFormControlSubscriptions();
  }

  public destroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public toModel(): BooleanValue {
    let booleanValue: boolean | null = null;
    if (this.controls.value.value === 'true') {
      booleanValue = true;
    } else if (this.controls.value.value === 'false') {
      booleanValue = false;
    }
    return {
      value: booleanValue,
      additional_text: this.controls.additionalText.value ?? '',
    };
  }

  public blurAdditionalTextControl(): void {
    this.updateValueFormControl(true);
  }

  private setValueFormControlSubscriptions() {
    const formSubscription = this.controls.value.valueChanges
      .pipe(ObservableUtils.filterNullish())
      .subscribe((booleanValue?: string) => {
        this.initTextFormControl(booleanValue);
        this.controls.additionalText.setValue('');
        this.updateValueFormControl();
      });

    const statusSubscription = this.statusChanges.subscribe((booleanFormGroupStatus) => {
      if (booleanFormGroupStatus !== this.valueFormControl.status) {
        if (this.controls.additionalText.hasError('required') || this.controls.value.hasError('required')) {
          this.valueFormControl.setErrors({ required: true });
        }
        if (this.controls.additionalText.hasError('max_length')) {
          this.valueFormControl.setErrors({ maxLength: true });
        }
      }
    });

    this.subscriptions = [formSubscription, statusSubscription];
  }

  private initBooleanFormControl(): void {
    this.controls.value.setValidators(this.valueFormControl.validator);
    this.controls.value.setValue(
      this.valueFormControl.value?.value != null ? String(this.valueFormControl.value?.value) : null,
      { emitEvent: false },
    );
  }

  private initTextFormControl(booleanValue?: string | null): void {
    this.controls.additionalText.clearValidators();
    this.displayAdditionalText = this.metricEditorBooleanService.getAdditionalTextDisplayInfo(
      this.valueFormControl,
      String(booleanValue),
    );

    if (this.displayAdditionalText.required) {
      this.controls.additionalText.setValidators(Validators.required);
    } else {
      this.controls.additionalText.setValidators(null);
    }

    this.controls.additionalText.addValidators(
      Validators.maxLength(ValueDefinitionConstants.DEFAULT_EXPLANATION_MAX_LENGTH),
    );

    this.controls.additionalText.setValue(
      this.displayAdditionalText.show
        ? (this.valueFormControl.value as BooleanValue | null)?.additional_text ?? ''
        : '',
      {
        emitEvent: false,
      },
    );
  }

  public updateValueFormControl(checkForIsDirty = false): void {
    this.updateValueAndValidity();
    if (this.valid) {
      const valueToUpdate = this.toModel();
      if (!checkForIsDirty) {
        this.valueFormControl.setValue(valueToUpdate);
      } else if ((this.controls.value.dirty || this.controls.additionalText.dirty) && valueToUpdate.value != null) {
        this.valueFormControl.setValue(this.toModel());
      }
    }
  }

  public handleValueChange(): void {
    if (this.oldValue !== this.valueFormControl.value) {
      this.oldValue = this.valueFormControl.value;
      this.initBooleanFormControl();
      this.initTextFormControl(this.controls.value.value);
    }
  }

  public handleEnableDisableStatus(): void {
    const vfcStatus = this.valueFormControl.status;
    if (this.oldStatus !== vfcStatus) {
      this.oldStatus = vfcStatus;
      this.onControlStatusChange();
    }
  }

  public handleTouchedStatusChange(): void {
    if (this.oldTouchedStatus !== this.valueFormControl.touched) {
      this.oldTouchedStatus = this.valueFormControl.touched;
      if (this.valueFormControl.touched) {
        this.controls.value.markAsTouched();
      } else {
        this.controls.value.markAsUntouched();
        this.controls.additionalText.markAsUntouched();
      }
    } else if (this.controls.value.touched || this.controls.additionalText.touched) {
      this.valueFormControl.markAsTouched();
      this.oldTouchedStatus = true;
    }
  }

  private onControlStatusChange(): void {
    if (this.valueFormControl.disabled) {
      this.disable({ emitEvent: false });
    } else {
      this.enable({ emitEvent: false });
    }
  }
}
