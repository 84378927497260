import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MetricTableColumnDefinition } from '../../../../../../models';

@Component({
  selector: 'lib-metric-table-total-columns',
  templateUrl: './metric-table-total-columns.component.html',
  styleUrls: ['./metric-table-total-columns.component.scss'],
})
export class MetricTableTotalColumnsComponent {
  @Input() public columnTypeLabel?: string;
  @Input() public columnsForm?: UntypedFormArray;
  @Input() public isContext: boolean = false;
  @Input() public metricTableContextColumns: MetricTableColumnDefinition[] = [];

  @Output() public removeColumn: EventEmitter<number> = new EventEmitter<number>();

  get columnFormsList(): UntypedFormGroup[] {
    return this.columnsForm?.controls as UntypedFormGroup[];
  }

  getContextValueControl(formGroup: UntypedFormGroup): UntypedFormControl {
    return formGroup.controls.values as UntypedFormControl;
  }
}
