import { Observable } from 'rxjs';
import {
  ApiResponse,
  GetIndicatorPayload,
  Indicator,
  Metric,
  MetricCategory,
  MinimalIndicator,
  ValueGroupSet,
} from '../../../models';
import { MetricSearchApiService } from '../metric-search-api-service/metric-search-api.service';

export abstract class IndicatorsApiService extends MetricSearchApiService {
  abstract getIndicator(id: string, payload?: GetIndicatorPayload): Observable<ApiResponse<Indicator>>;

  abstract listValueProgressSummary(
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    frequencyCode: string,
    consolidatedBusinessUnitId: string,
    offset?: number,
    limit?: number,
  ): Observable<ApiResponse<ValueGroupSet[]>>;

  abstract listValueProgressSummaryGroup(
    valueDefinitionGroupId: string,
    frequencyCode: string,
    consolidatedBusinessUnitId: string,
    offset?: number,
    limit?: number,
  ): Observable<ApiResponse<ValueGroupSet[]>>;

  abstract getIndicatorByMetricIds(
    metric_ids: string[],
    fiscal_year?: string,
    business_unit_id?: string,
  ): Observable<ApiResponse<Indicator[]>>;

  abstract listMinimalIndicators(
    frameworkId?: string,
    payload?: {
      categories?: MetricCategory[];
      metric_ids?: string[];
      indicator_ids?: string[];
    },
  ): Observable<ApiResponse<(Metric | MinimalIndicator)[]>>;
}
