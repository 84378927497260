import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  MetricTableDefinition,
  NOT_APPLICABLE_NUMERIC_VALUE,
  SOURCE_CONFIGURATION,
  ValueDefinitionSize,
  DEFAULT_SOURCE_CONFIGURATION,
  FiscalYearPeriod,
  isDBConsolidationEnabled,
  ValueDefinitionType,
  isFieldBypassConsolidation,
  ConsolidationRules,
  getConsolidatedInfo,
} from '../../../models';
import { ValidationMessageService } from '../../../services/common';
import { NumberMaskConfig, NumberMaskService } from '../../../number-mask';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { NumberUtils } from '../../../classes';
import { MatMenuTrigger } from '@angular/material/menu';
import { ValidatorsUtils } from '../../../classes/FormUtils/validators-utils';
import { ValueFormControl } from '../../../metric-editor-form/models/valueFormControl';
import { TableTotalFormulaPipe } from '../../../metric-editor-form/pipes/table-total-formula/table-total-formula.pipe';
import { MetricTableTotalFormulaPipe } from '../../../pipes';
import { FrequencyFieldPeriodPipe } from '../../../metric-editor-form/components/metric-editor-frequency-field/pipes/frequency-field-period.pipe';

let nextId = 0;

export enum NumericInputType {
  notApplicable = 'NOT_APPLICABLE',
  readonly = 'READONLY',
  full = 'FULL',
}

@Component({
  selector: 'lib-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss'],
})
export class NumericInputComponent implements OnChanges, OnDestroy, OnInit {
  readonly NOT_APPLICABLE = NOT_APPLICABLE_NUMERIC_VALUE;

  public readonly INTEGER_MASK = 'separator.0';
  private readonly DECIMAL_MASK = 'separator';

  @Input() label = '';
  @Input() control?: FormControl;
  @Input() messages: ValidationErrors = {};
  @Input() parentControl?: FormControl;
  @Input() hint? = '';
  @Input() placeholder = '';
  @Input() suffix? = '';
  @Input() size = ValueDefinitionSize.large;
  @Input() readonly = false;
  @Input() forceAllowDecimals = false;
  @Input() maxDecimals?: number;
  @Input() isCalculated: boolean = false;
  @Input() calculationErrorMsg: string = '';
  @Input() metricTableDefinition?: MetricTableDefinition;
  @Input() mask: string = '';
  @Input() sourceConfiguration: SOURCE_CONFIGURATION = DEFAULT_SOURCE_CONFIGURATION;
  @Input() labelPosition: 'top' | 'left' = 'top';
  @Input() sameSizeLabels: boolean = false;
  @Input() fiscalYearPeriod?: FiscalYearPeriod;

  controlNumberDisplay: FormControl = new FormControl();
  required = false;
  errorMessages: ValidationErrors = {};
  errorStateMatcher: ErrorStateMatcher = { isErrorState: () => Boolean(this.control?.touched && this.control.invalid) };
  numberMaskConfig$: Observable<NumberMaskConfig> = this.numberMaskService.numberMaskConfig$;
  controlSubscription?: Subscription;
  maskInput: string = this.mask ?? this.INTEGER_MASK;
  maskNumberDisplay: string = this.mask ?? this.INTEGER_MASK;
  numericInputChange$: Subject<string | number> = new Subject<string | number>();
  eNumericInputType: typeof NumericInputType = NumericInputType;
  fieldInfo?: string;
  isBypassConsolidation: boolean = false;
  tableTotalFormulaPipe = new TableTotalFormulaPipe();
  metricTableTotalFormulaPipe = new MetricTableTotalFormulaPipe();
  frequencyFieldPeriodPipe = new FrequencyFieldPeriodPipe();
  // Reflecting the native blur event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<FocusEvent>();

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;
  @ViewChild('errorDetailsMenuTrigger') errorDetailsMenuTrigger?: MatMenuTrigger;

  readonly _inputId = `numeric-input-${nextId++}`;

  constructor(
    private validationMessageService: ValidationMessageService,
    private readonly numberMaskService: NumberMaskService,
  ) {}

  ngOnInit(): void {
    this.control?.registerOnChange((value: string | number) => {
      this.numericInputChange$.next(value);
    });
  }

  ngOnChanges(): void {
    this.initializeInput();
  }

  ngOnDestroy(): void {
    this.controlSubscription?.unsubscribe();
  }

  setFocus(): void {
    this.input?.nativeElement.focus();
  }

  setBlur(): void {
    this.input?.nativeElement.blur();
  }

  private initializeInput(): void {
    // Used only to input data
    this.maskInput = this.mask
      ? this.mask
      : (this.maxDecimals ?? 0 > 0) || this.forceAllowDecimals
        ? this.DECIMAL_MASK
        : this.INTEGER_MASK;

    // Used only to display data (read-only)
    this.maskNumberDisplay = `${this.DECIMAL_MASK}.${this.maxDecimals ?? 0}`;

    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };

    this.control?.addValidators([ValidatorsUtils.systemMaxNumber, ValidatorsUtils.isANumberValidator()]);
    this.setSpecialValidations();
    this.setIsBypassConsolidation();
    this.fieldInfo = this.setFieldInfo();
  }

  private setSpecialValidations(): void {
    this.controlSubscription?.unsubscribe();
    this.controlSubscription = this.numericInputChange$
      .pipe(
        startWith(this.control?.value as string),
        filter((value) => value !== this.NOT_APPLICABLE && (value != null || this.control?.disabled || this.readonly)),
        map((value) => (typeof value === 'string' ? value.replace(/[A-Za-z]/, '') : value)),
      )
      .subscribe((formattedValue) => {
        if (!isNaN(formattedValue as number) && String(this.control?.value) !== String(formattedValue)) {
          const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: this.maxDecimals });
          this.control?.setValue(formatter.format(Number(formattedValue)), {
            emitEvent: false,
          });
        }
        this.controlNumberDisplay.setValue(
          this.control?.value != null ? this.roundNumber(this.control.value as number) : null,
        );
      });
  }

  private roundNumber(numericValue: string | number): string {
    return NumberUtils.round(+numericValue, this.maxDecimals || 0);
  }

  openErrorDetailsmenu(): void {
    this.errorDetailsMenuTrigger?.openMenu();
  }
  private setIsBypassConsolidation(): void {
    if (!(this.control instanceof ValueFormControl)) {
      this.isBypassConsolidation = false;
      return;
    }

    const businessUnitLevel = this.control.businessUnitLevel();
    this.isBypassConsolidation = isFieldBypassConsolidation(
      this.control.valueRef.bypass_consolidation_levels as number[] | null,
      businessUnitLevel,
    );
  }

  private setFieldInfo(): string | undefined {
    if (this.isConsolidatedInfo()) {
      return this.formatConsolidatedInfo();
    }
    if (this.isTableCalculatedInfo()) {
      return this.formatMetricTableTotalInfo();
    }
    return undefined;
  }

  private isConsolidatedInfo(): boolean {
    if (!(this.control instanceof ValueFormControl)) {
      return false;
    }

    const isConsolidated = this.control.isConsolidated();
    const isCalculatedField = this.control.valueRef.type === ValueDefinitionType.calculated;
    if (
      isConsolidated &&
      !isCalculatedField &&
      !this.isBypassConsolidation &&
      this.control.valueRef.consolidation_rule !== ConsolidationRules.manual &&
      isDBConsolidationEnabled(this.sourceConfiguration) &&
      !this.fiscalYearPeriod
    ) {
      return true;
    }

    return false;
  }

  private isTableCalculatedInfo(): boolean {
    return this.control instanceof ValueFormControl && !!this.metricTableDefinition;
  }

  private formatMetricTableTotalInfo(): string {
    if (!(this.control instanceof ValueFormControl) || !this.metricTableDefinition) {
      return '';
    }

    const calculationDefinitions = this.metricTableDefinition.calculation_definitions;
    const tableTotalFormula = this.tableTotalFormulaPipe.transform(calculationDefinitions, this.control.valueRef);
    return this.metricTableTotalFormulaPipe.transform(tableTotalFormula);
  }

  private formatConsolidatedInfo(): string {
    if (!(this.control instanceof ValueFormControl)) {
      return '';
    }
    return getConsolidatedInfo(this.control.valueRef);
  }
}
