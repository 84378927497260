import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CdkModule } from '../material/cdk.module';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directives';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MetricViewComponent } from './metric-view/metric-view.component';
import { MetricViewDocumentLinkComponent } from './metric-view/metric-view-document-link/metric-view-document-link.component';
import { DataFormatsComponent } from './data-formats/data-formats.component';
import { DataFormatsChoiceFieldComponent } from './data-formats/data-formats-choice-field/data-formats-choice-field.component';
import { DataFormatSearchableSelectFieldComponent } from './data-formats/data-formats-choice-field/data-format-searchable-select-field/data-format-searchable-select-field.component';
import { DataFormatsLabelComponent } from './data-formats/data-formats-label/data-formats-label.component';
import { DataFormatsDocumentComponent } from './data-formats/data-formats-document/data-formats-document.component';
import { DataFormatsTipComponent } from './data-formats/data-formats-tip/data-formats-tip.component';
import { MetricSelectorComponent } from './metric-selector/metric-selector.component';
import { MetricSelectorBarComponent } from './metric-selector/metric-selector-bar/metric-selector-bar.component';
import { MetricSelectorItemsComponent } from './metric-selector/metric-selector-items/metric-selector-items.component';
import { SearchModule } from '../search';
import { ComponentsModule } from '../components';
import { NumberMaskModule } from '../number-mask';
import { MetricViewFileV2Component } from './metric-view/metric-view-file-v2/metric-view-file-v2.component';
import { ShowGroupLabelPipe } from './metric-view/pipes/show-group-label.pipe';

@NgModule({
  declarations: [
    MetricViewComponent,
    MetricViewDocumentLinkComponent,
    DataFormatsComponent,
    DataFormatsChoiceFieldComponent,
    DataFormatSearchableSelectFieldComponent,
    DataFormatsLabelComponent,
    DataFormatsDocumentComponent,
    DataFormatsTipComponent,
    MetricSelectorComponent,
    MetricSelectorBarComponent,
    MetricSelectorItemsComponent,
    MetricViewFileV2Component,
    ShowGroupLabelPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CdkModule,
    MaterialModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SearchModule,
    ComponentsModule,
    NumberMaskModule,
  ],
  providers: [provideNgxMask()],
  exports: [
    MetricViewComponent,
    MetricViewDocumentLinkComponent,
    DataFormatsComponent,
    DataFormatsChoiceFieldComponent,
    DataFormatSearchableSelectFieldComponent,
    DataFormatsLabelComponent,
    DataFormatsDocumentComponent,
    DataFormatsTipComponent,
    MetricSelectorComponent,
    MetricSelectorBarComponent,
    MetricSelectorItemsComponent,
  ],
})
export class MetricsModule {}
