import { ApiResponse, StandardCodes, Unit } from '../../models';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BaseApiService } from '../types';
import { SearchStandardCodesPayload } from '../../standard-codes/standard-codes.service';
import { AbstractApiService } from '../common/api/abstract-api.service';

export abstract class CoreApiService extends BaseApiService {
  abstract apiService: AbstractApiService;

  public listUnitFamilies(): Observable<ApiResponse<Unit[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/units/families`);
  }

  public listUnits(family?: string): Observable<ApiResponse<Unit[]>> {
    let params = new HttpParams();
    if (family) {
      params = params.append('family', family);
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/units`, { params });
  }

  public searchStandardCodes(payload: SearchStandardCodesPayload): Observable<ApiResponse<StandardCodes[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/standard_codes/list`, payload);
  }
}
