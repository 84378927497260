import { Buffer } from 'buffer';

export class EncryptUtils {
  static encoded(obj: any): string {
    const objJsonStr = JSON.stringify(obj);
    return Buffer.from(objJsonStr).toString('base64');
  }

  static decoded(textEncoded: string): any {
    return JSON.parse(Buffer.from(textEncoded, 'base64').toString('utf8'));
  }
}
