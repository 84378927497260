import * as Highcharts from 'highcharts';

export enum ChartAlignValue {
  center = 'center',
  left = 'left',
  right = 'right',
}

export enum ChartAxisAlignValue {
  high = 'high',
  low = 'low',
  middle = 'middle',
}

export enum ChartColor {
  green = '#00FF00',
  grey = '#DDDDDD',
  orange = '#F79009',
  primary = '#004FAC',
  red = '#FF0000',
  subtitle = '#101828',
  title = '#475467',
  white = '#FFFFFF',
}

export enum ChartFontSizeValue {
  common = '1.4rem',
  small = '1.2rem',
}

export enum ChartFontWeightValue {
  common = '400',
}

export enum ChartLineHeightValue {
  common = '1.6rem',
}

export enum ChartSymbol {
  circle = 'symbol',
}

export enum ChartType {
  column = 'column',
  scatter = 'scatter',
  spline = 'spline',
}

export enum ChartColumnPlotOptionStackingType {
  normal = 'normal',
  percent = 'percent',
}

export interface ChartColumnPlotOptions {
  stacking?: ChartColumnPlotOptionStackingType;
  dataLabels?: {
    enabled?: boolean;
    style?: ChartStyleConfig;
  };
}

interface ChartPlotConfig {
  series?: {
    pointWidth?: number;
    borderRadius?: string;
    events?: {
      legendItemClick?: (event: Highcharts.SeriesLegendItemClickEventObject) => void;
    };
  };
  column?: ChartColumnPlotOptions;
  [ChartType.scatter]?: {
    marker: {
      radius: number;
      symbol: ChartSymbol;
    } & ChartMarkerConfig;
  };
  spline?: {
    connectNulls?: boolean;
  } & ChartMarkerConfig;
}

interface ChartStyleConfig {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  margin?: string;
  transform?: string;
  whiteSpace?: string;
  textOverflow?: string;
  overflow?: string;
}

export interface ChartAxisConfig {
  categories?: string[];
  labels?: { style?: ChartStyleConfig };
  title?: ChartTitleConfig<ChartAxisAlignValue>;
  stackLabels?: {
    enabled?: boolean;
    style?: ChartStyleConfig;
  };
}

interface ChartMarkerConfig {
  lineWidth?: number;
  lineColor?: string;
  fillColor?: string;
}

export interface ChartTitleConfig<T = ChartAlignValue> {
  align?: T;
  text?: string;
  style?: ChartStyleConfig;
  useHTML?: boolean;
}

export type ChartSubtitleConfig = ChartTitleConfig;

export interface ChartTooltipConfig {
  format?: string;
  pointFormat?: string;
  valueSuffix?: string;
  style?: ChartStyleConfig;
}

interface CommonChartConfig<T extends ChartType> {
  type: T;
  name: string;
  tooltip?: ChartTooltipConfig;
}

interface SimpleChartConfig<T extends ChartType> extends CommonChartConfig<T> {
  id?: string;
  data: (null | number)[] | (null | number[])[];
  showInLegend?: boolean;
}

type ColumnChartConfig = SimpleChartConfig<ChartType.column>;

type ScatterChartConfig = SimpleChartConfig<ChartType.scatter>;

interface ChartLegendConfig {
  itemStyle?: ChartStyleConfig;
}

interface StackedColumnChartConfig extends SimpleChartConfig<ChartType.column> {
  stack: string;
  color: Highcharts.PatternObject;
}

interface SplineChartConfig extends SimpleChartConfig<ChartType.spline> {
  marker?: ChartMarkerConfig;
}

interface ChartNavigationButtonOptionsConfig {
  enabled: boolean;
}

interface ChartNavigationConfig {
  buttonOptions: ChartNavigationButtonOptionsConfig;
}

interface ContextButton {
  menuItems: Array<string>;
}

interface ChartButton {
  contextButton: ContextButton;
}

interface ChartButton {
  contextButton: ContextButton;
}

interface ChartGeneralConfig {
  marginLeft?: number;
}

interface ChartExportOption {
  url?: string;
  sourceWidth?: number;
  sourceHeight?: number;
  chartOptions?: ChartConfig;
  buttons?: ChartButton;
  enabled?: boolean;
}

export interface ChartConfig {
  colors?: Array<string>;
  series?: Array<ColumnChartConfig | ScatterChartConfig | SplineChartConfig | StackedColumnChartConfig>;
  tooltip?: ChartTooltipConfig;
  title?: ChartTitleConfig;
  subtitle?: ChartSubtitleConfig;
  xAxis?: ChartAxisConfig;
  yAxis?: ChartAxisConfig;
  plotOptions?: ChartPlotConfig;
  navigation?: ChartNavigationConfig;
  legend?: ChartLegendConfig;
  exporting?: ChartExportOption;
  chart?: ChartGeneralConfig;
}

export const COMPANY_COLOURS: Array<string> = [
  '#004FAC',
  '#1C98F6',
  '#02BEF7',
  '#5EDDF7',
  '#98EFF4',
  '#CEE0DF',
  '#DAE6E7',
  '#A7CACE',
  '#9AB1B2',
  '#529A9F',
];

export const BAR_WIDTH: number = 75;

export const BAR_TOP_BORDER_RADIUS: string = '5px';

export const MARKER_LINE_COLOR: string = 'orange';

export const MARKER_FILL_COLOR: string = 'white';

export const MARKER_RADIUS: number = 5;

export const TOOLTIP_FONT_SIZE: string = '12px';

export const TITLE_FONT_SIZE: string = '14px';

export const SUBTITLE_FONT_SIZE: string = '10px';

export const LINE_WIDTH: number = 2;

export const MARKER_LINE_WIDTH: number = 4;

export const DEFAULT_CHART_CONFIG: ChartConfig = {
  colors: [ChartColor.primary, ChartColor.grey, ChartColor.orange],
  tooltip: {
    style: {
      fontSize: TOOLTIP_FONT_SIZE,
    },
  },
  plotOptions: {
    [ChartType.scatter]: {
      marker: {
        radius: MARKER_RADIUS,
        symbol: ChartSymbol.circle,
        fillColor: MARKER_FILL_COLOR,
        lineColor: MARKER_LINE_COLOR,
        lineWidth: MARKER_LINE_WIDTH,
      },
    },
    series: {
      pointWidth: BAR_WIDTH,
      borderRadius: BAR_TOP_BORDER_RADIUS,
    },
    spline: { connectNulls: true, lineWidth: LINE_WIDTH },
  },
  exporting: {
    enabled: true,
    chartOptions: {
      title: {
        style: {
          fontSize: TITLE_FONT_SIZE,
          lineHeight: '4px',
          transform: 'translate(0, 0px)',
        },
      },
      subtitle: {
        style: {
          fontSize: SUBTITLE_FONT_SIZE,
          transform: 'translate(0, 0px)',
        },
      },
    },
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'separator',
          'downloadCSV',
          'downloadXLS',
        ],
      },
    },
  },
};
