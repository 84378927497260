import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDocumentInterface } from '../../models';

@Injectable()
export abstract class BaseStandardDocumentsService {
  abstract searchPath: string;
  abstract resourcePath: string;
  abstract documentsPath: string;

  abstract getDocument(docId: string): Observable<Blob>;
  abstract getDocumentMetadata(docId: string): Observable<FileDocumentInterface>;
  abstract listDocumentMetadata(docIds: string[]): Observable<FileDocumentInterface[]>;
  // TODO: implement upload and search
  //abstract upload(name: string, formData: FormData ):Observable<any>
  //abstract search(): Observable<any>
}
