import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  MoveValueGroupEvent,
  ResetValueEvent,
  UpsertValue,
  UpsertValueGroup,
  UpsertValueGroupSet,
} from '../../../metric-editor-form';
import {
  Value,
  ValueGroup,
  ValueGroupSet,
  Metric,
  ValueDefinitionType,
  BooleanValue,
  ValueBreakdownFrequency,
} from '../../../models';
import { AuthService } from '../../../services/common';
import { v4 as uuidv4 } from 'uuid';
import { MetricPreviewConditionalTriggerService } from '../metric-preview-conditional-trigger/metric-preview-conditional-trigger.service';

@Injectable()
export class MetricPreviewStateService {
  private _valueGroupSet$: BehaviorSubject<ValueGroupSet | undefined> = new BehaviorSubject<ValueGroupSet | undefined>(
    undefined,
  );

  public readonly valueGroupSet$: Observable<ValueGroupSet | undefined>;
  private _metric?: Metric;

  constructor(
    private authService: AuthService,
    private metricPreviewConditionalTriggerService: MetricPreviewConditionalTriggerService,
  ) {
    this.valueGroupSet$ = this._valueGroupSet$.asObservable();
  }

  initializeVgsetAndMetric(vgset: ValueGroupSet, metric: Metric): void {
    this._valueGroupSet$.next(vgset);
    this._metric = metric;
  }

  updateVgset(updateVGSEvent: UpsertValueGroupSet): void {
    const vgset: ValueGroupSet = {
      ...(this._valueGroupSet$.value as ValueGroupSet),
      id: updateVGSEvent.id ?? uuidv4(),
      frequency_code: updateVGSEvent.frequency_code,
      business_unit_id: updateVGSEvent.business_unit_id,
    };
    vgset.value_groups = this.upsertValueGroup(updateVGSEvent.value_groups, vgset);
    vgset.value_groups = this.metricPreviewConditionalTriggerService.handleConditionalTrigger(
      vgset.value_groups ?? [],
      updateVGSEvent,
    );
    vgset.value_groups = vgset.value_groups?.sort((a, b) => a.position - b.position);
    this.updateValueGroupSet(vgset);
  }

  deleteGroup(groupId: string): void {
    const vgset = { ...this._valueGroupSet$.value } as ValueGroupSet;
    const grpToDelete = this._valueGroupSet$.value?.value_groups?.find((grp) => grp.id === groupId);
    if (grpToDelete) {
      vgset.value_groups = vgset.value_groups?.filter((grp) => grp.id !== grpToDelete.id);
      vgset.value_groups = this.reorderGroups(vgset.value_groups ?? [], grpToDelete.value_definition_group_id ?? '');
    } else {
      throw new Error(`group with id ${groupId} not found`);
    }
    this.updateValueGroupSet(vgset);
  }

  resetValue(resetValueEvent: ResetValueEvent): void {
    const vgset = { ...this._valueGroupSet$.value } as ValueGroupSet;
    const value = vgset.value_groups
      ?.find((grp) => grp.id === resetValueEvent.valueGroupId)
      ?.values?.find((val) => val.id === resetValueEvent.valueId);
    if (value) {
      value.id = '';
      value.updated = '';
      value.updated_by = '';
      value.value = null;
    }
    this.updateValueGroupSet(vgset);
  }

  moveGroup(moveValueGroupEvent: MoveValueGroupEvent): void {
    const vgset = { ...this._valueGroupSet$.value } as ValueGroupSet;
    const grpToMove = vgset.value_groups?.find((grp) => grp.id === moveValueGroupEvent.value_group_id);
    const isMoveUpOperation = (grpToMove?.subposition ?? 1) > moveValueGroupEvent.position;
    vgset.value_groups = this.getReorderedGrpsAfterMove(
      vgset.value_groups ?? [],
      grpToMove?.value_definition_group_id ?? '',
      moveValueGroupEvent.position,
      isMoveUpOperation,
    );
    if (grpToMove) {
      grpToMove.subposition = moveValueGroupEvent.position;
    }
    vgset.value_groups = this.reorderGroups(vgset.value_groups ?? [], grpToMove?.value_definition_group_id ?? '');
    this.updateValueGroupSet(vgset);
  }

  private upsertValueGroup(upsertValueGrps: UpsertValueGroup[], vgset: ValueGroupSet): ValueGroup[] | undefined {
    upsertValueGrps.forEach((upsertValueGrp) => {
      let matchingGrp = upsertValueGrp.id ? vgset.value_groups?.find((vg) => vg.id === upsertValueGrp.id) : undefined;
      if (!matchingGrp) {
        if (this.isGroupRepeatable(upsertValueGrp.value_definition_group_id) && upsertValueGrp.id === undefined) {
          vgset.value_groups = this.createAndGetRepeatedGroups(vgset.value_groups ?? [], upsertValueGrp);
        } else {
          matchingGrp = vgset.value_groups?.find(
            (vg) => vg.value_definition_group_id === upsertValueGrp.value_definition_group_id,
          );
        }
      }

      if (matchingGrp) {
        matchingGrp.id = matchingGrp.id || upsertValueGrp.id || uuidv4();
        matchingGrp.values = this.upsertValue(upsertValueGrp.values, matchingGrp);
      }
    });

    return vgset.value_groups;
  }

  private isGroupRepeatable(valueDefGroupId: string): boolean {
    return this._metric?.value_definition_groups?.find((vdg) => vdg.id === valueDefGroupId)?.repeatable ?? false;
  }

  private upsertValue(upsertValues: UpsertValue[], matchingGrp?: ValueGroup): Value[] {
    upsertValues.forEach((value) => {
      if (value.fiscal_year_period_id) {
        const matchingParentVal = value.year_to_date_value_id
          ? matchingGrp?.values?.find((val) => val.id === value.year_to_date_value_id)
          : matchingGrp?.values?.find((val) => val.value_definition_id === value.value_definition_id);

        if (matchingParentVal) {
          if (matchingParentVal.value_breakdown_frequency) {
            matchingParentVal.id = matchingParentVal.id || uuidv4();
            matchingParentVal.value_breakdown_frequency = matchingParentVal.value_breakdown_frequency.map(
              (vbf: ValueBreakdownFrequency): ValueBreakdownFrequency =>
                vbf.fiscal_year_period?.id === value.fiscal_year_period_id
                  ? {
                      ...vbf,
                      value: value.value as any,
                      id: vbf.id || value.id || uuidv4(),
                      value_group_id: matchingGrp?.id,
                      year_to_date_value_id: matchingParentVal.id,
                    }
                  : vbf,
            );
          }
        }
      } else {
        const matchingVal = value.id
          ? matchingGrp?.values?.find((val) => val.id === value.id)
          : matchingGrp?.values?.find((val) => val.value_definition_id === value.value_definition_id);

        if (matchingVal) {
          matchingVal.value = value.value;

          if (matchingVal.type === ValueDefinitionType.boolean && value.value) {
            matchingVal.value = {
              ...(value.value as BooleanValue),
              value: String((value.value as any).value) === 'true',
            };
          }

          matchingVal.id = value.id || matchingVal.id || uuidv4();
          matchingVal.value_group_id = matchingGrp?.id;
        }
      }
    });

    return matchingGrp?.values ?? [];
  }

  private createAndGetRepeatedGroups(valueGroups: ValueGroup[], upsertValueGroup: UpsertValueGroup): ValueGroup[] {
    let repeatedGroups = valueGroups?.filter(
      (vg) => vg.value_definition_group_id === upsertValueGroup.value_definition_group_id,
    );
    repeatedGroups = repeatedGroups.map((grp) => ({
      ...grp,
      id: uuidv4(),
      created: new Date(),
      created_by: this.authService.user?.id,
      updated: new Date().toISOString(),
      updated_by: this.authService.user?.id,
    }));

    valueGroups.push({
      ...repeatedGroups[0],
      id: uuidv4(),
      subposition: repeatedGroups.length + 1,
      values:
        repeatedGroups[0].values?.map((val) => ({ ...val, id: '', updated: '', updated_by: '', value: null })) ?? [],
    });
    return valueGroups;
  }

  private reorderGroups(valueGroups: ValueGroup[], valueGroupDefId: string): ValueGroup[] {
    let index = 1;
    const repeatableGrps = valueGroups
      .filter((grp) => grp.value_definition_group_id === valueGroupDefId)
      .sort((a, b) => (a.subposition ?? 1) - (b.subposition ?? 1))
      .map((grp) => {
        grp.subposition = index++;
        return grp;
      });

    return valueGroups
      .filter((grp) => grp.value_definition_group_id !== valueGroupDefId)
      .concat(repeatableGrps)
      .sort((a, b) => a.position - b.position);
  }

  private getReorderedGrpsAfterMove(
    valueGroups: ValueGroup[],
    vdgId: string,
    position: number,
    isMoveUpOperation: boolean,
  ): ValueGroup[] {
    const grpsToReorder = valueGroups.filter(
      (grp) => grp.value_definition_group_id === vdgId && (grp.subposition ?? 0) >= position,
    );
    grpsToReorder?.forEach((grp) => {
      if (isMoveUpOperation) {
        grp.subposition = (grp.subposition ?? 1) + 1;
      } else {
        grp.subposition = (grp.subposition ?? 1) - 1;
      }
    });
    return valueGroups;
  }

  private updateValueGroupSet(vgset: ValueGroupSet): void {
    this._valueGroupSet$.next({
      ...vgset,
      id: vgset.id || uuidv4(),
      updated_by: this.authService.user?.user_id,
      updated: new Date().toISOString(),
    });
  }
}
