export class TimeUtils {
  /**
   * Synchronous sleep
   * @param time time to sleep in milliseconds
   */
  static sleep(time: number): void {
    const start: number = new Date().getTime();
    while (new Date().getTime() < start + time) {
      /* empty */
    }
  }
}
