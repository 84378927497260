import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class WhitespaceValidators {
  public static hasOnlyWhitespace(fieldName?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = fieldName ? control.get(fieldName)?.value : control.value;
      if (value !== undefined && value !== null && typeof value === 'string') {
        const trimmedValue = (value || '').trim();
        if (trimmedValue.length === 0 && value !== '') {
          return { whiteSpace: true };
        }
      }
      return null;
    };
  }
}
