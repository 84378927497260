<div *ngIf="control" libFormField [required]="required" [control]="control" [labelPosition]="labelPosition">
  <lib-form-field-label [label]="label" [control]="control" [id]="_inputId"></lib-form-field-label>

  <mat-form-field *ngIf="control" class="w-100" floatLabel="auto">
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
    <mat-select
      [id]="_inputId"
      [aria-labelledby]="_inputId"
      [attr.name]="_inputId"
      [compareWith]="$any(compareFn)"
      [formControl]="control"
      panelClass="form-select-panel"
      (opened)="setFocusOnSearch()"
      (openedChange)="openedChange.emit($event)"
      #focusElement
      libFocusState
    >
      <div
        class="select-search fx-row align-center"
        (click)="$event.stopPropagation()"
        *ngIf="searchable && (inputControl.value || (options.length || 0) >= minMenuScrollItems)"
      >
        <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
        <input
          #matSelectPanelInput
          tabIndex="1"
          autocomplete="off"
          [placeholder]="'Search' | translate"
          [formControl]="inputControl"
        />
        <button class="search–clear-button" *ngIf="inputControl.value" (click)="inputControl.setValue('')">
          <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
        </button>
      </div>

      <div class="options-container" (libScrollToBottom)="loadMoreOptions()">
        <ng-container *ngFor="let option of options">
          <mat-option
            class="multiline-mat-option"
            [value]="option"
            [ngClass]="{ active: control.value === option, category: $any(option)?.isLabel ?? false }"
            [disabled]="$any(option)?.disabled ?? $any(option)?.isLabel ?? false"
          >
            {{ option | executeFunctionOnValue: bindOptionLabelFn }}
          </mat-option>
          <ng-container *ngFor="let child of $any(option).children">
            <mat-option
              class="multiline-mat-option pl-5"
              [value]="child"
              [ngClass]="{ active: control.value === child }"
            >
              {{ child | executeFunctionOnValue: bindOptionLabelFn }}
            </mat-option>
            <mat-option
              *ngFor="let subChild of child['children']"
              class="multiline-mat-option pl-10"
              [value]="subChild"
              [ngClass]="{ active: control.value === subChild }"
            >
              {{ subChild | executeFunctionOnValue: bindOptionLabelFn }}
            </mat-option>
          </ng-container>
        </ng-container>

        <mat-option *ngIf="!options.length && !isLoadingOptions">{{ "No results found" | translate }}</mat-option>
        <mat-option *ngIf="isLoadingOptions" data-testid="is-loading-options-spinner">
          <lib-spinner></lib-spinner>
        </mat-option>
      </div>
    </mat-select>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-hint class="mat-error" *ngFor="let errorKey of parentControl?.errors ?? null | errorKeys">{{
      errorMessages[errorKey]
    }}</mat-hint>
    <mat-error *ngFor="let error of control.errors | errorKeys"> {{ errorMessages[error] }}</mat-error>
  </mat-form-field>
</div>
