import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetricTableColumnDefinition, MetricTableDefinition, TableColumn, ValueDefinitionType } from '../../models';

import { TranslateService } from '../../services/common';

interface TableInputRow {
  metricTableColumnDefinition: MetricTableColumnDefinition;
  inputColumnLabel: string;
}

@Component({
  selector: 'lib-select-metric-table-column-definition',
  templateUrl: './select-metric-table-column-definition.component.html',
  styleUrls: ['./select-metric-table-column-definition.component.scss'],
})
export class SelectMetricTableColumnDefinitionComponent implements OnInit {
  @Input({ required: true }) metricTableDefinition!: MetricTableDefinition;

  @Output() metricTableColumnDefinitionSelected = new EventEmitter<MetricTableColumnDefinition>();
  @Output() cancel = new EventEmitter<void>();

  public tableInputRows: TableInputRow[] = [];
  public tableContextColumns = '';
  public tableInputColumns: TableColumn<TableInputRow>[] = [
    {
      name: this.translateService.instant('Select a table input column'),
      dataKey: 'inputColumnLabel',
    },
  ];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.tableInputRows = this.metricTableDefinition.column_definitions
      .filter((columnDefinition: MetricTableColumnDefinition) => columnDefinition.type != ValueDefinitionType.label)
      .map((columnDefinition: MetricTableColumnDefinition) => ({
        metricTableColumnDefinition: columnDefinition,
        inputColumnLabel: columnDefinition.label,
      }));

    this.tableContextColumns = this.metricTableDefinition.column_definitions
      .filter((columnDefinition: MetricTableColumnDefinition) => columnDefinition.type === ValueDefinitionType.label)
      .map((columnDefinition: MetricTableColumnDefinition) => columnDefinition.label)
      .join(', ');
  }

  public selectMetricTableColumnDefinition(tableInputRow: TableInputRow) {
    this.metricTableColumnDefinitionSelected.emit(tableInputRow.metricTableColumnDefinition);
  }

  public backToFields() {
    this.cancel.emit();
  }
}
