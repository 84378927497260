import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { DueDateAutomaticReminderForm, DueDateAutomaticReminderFormModel } from './due-date-automatic-reminder-form';
import {
  ActionItem,
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  DueDateNotificationRelativeToType,
  DueDateNotificationScheduleType,
  DueDateNotificationTimeframeType,
  TipIcon,
  TipIconConfig,
  TipTypeDetailsIcon,
} from '../../../models';
import { TranslateService, ValidationMessageService } from '../../../services/common';
import {
  DataRequestSourceStatusToText,
  DataRequestUserResponsibilityTranslation,
  DueDateNotificationRelativeToTypeTranslation,
  DueDateNotificationScheduleTypeTranslation,
  DueDateNotificationTimeframeTypeTranslation,
} from './due-date-automatic-reminder.translation';

const DATA_REQUEST_SOURCE_STATUSES: DataRequestSourceStatus[] = [
  DataRequestSourceStatus.STARTED,
  DataRequestSourceStatus.IN_REVIEW,
  DataRequestSourceStatus.IN_APPROVAL,
];

@Component({
  selector: 'lib-due-date-automatic-reminder',
  templateUrl: './due-date-automatic-reminder.component.html',
  styleUrls: ['./due-date-automatic-reminder.component.scss'],
})
export class DueDateAutomaticReminderComponent implements AfterViewInit {
  @Input() label: string = '';
  @Input() control?: FormArray<FormGroup<DueDateAutomaticReminderFormModel>>;
  @Input() maxItems: number = 5;

  readonly eDueDateNotificationScheduleType = DueDateNotificationScheduleType;
  readonly tipIcons: Record<TipTypeDetailsIcon, TipIconConfig> = TipIcon;
  readonly errorMessages: ValidationErrors = {};

  isSmallScreen: boolean = false;

  public sourceStatusItems: ActionItem[] = [
    { id: 'any', title: this.translateService.instant('Any status') },
    ...DATA_REQUEST_SOURCE_STATUSES.map((status) => ({
      id: status,
      title: this.translateService.instant(DataRequestSourceStatusToText[status]),
    })),
  ];

  public participantItems: ActionItem[] = [
    { id: 'all', title: this.translateService.instant('Participants in active step') },
    { id: 'custom', title: this.translateService.instant('Custom') },
  ];

  public scheduleItems: ActionItem[] = Object.entries(DueDateNotificationScheduleType).map(([key, value]) => ({
    id: value,
    title: DueDateNotificationScheduleTypeTranslation[key as keyof typeof DueDateNotificationScheduleTypeTranslation],
  }));

  public customParticipantItems: ActionItem[] = Object.entries(DataRequestUserResponsibility).map(([key, value]) => ({
    id: value,
    title: DataRequestUserResponsibilityTranslation[key as keyof typeof DataRequestUserResponsibilityTranslation],
  }));

  public scheduleTimeframeItems: ActionItem[] = Object.entries(DueDateNotificationTimeframeType).map(
    ([key, value]) => ({
      id: value,
      title:
        DueDateNotificationTimeframeTypeTranslation[key as keyof typeof DueDateNotificationTimeframeTypeTranslation],
    }),
  );

  public scheduleRelativeToItems: ActionItem[] = Object.entries(DueDateNotificationRelativeToType)
    .filter(([_, value]) => value != DueDateNotificationRelativeToType.ON_DUE_DATE)
    .map(([key, value]) => ({
      id: value,
      title:
        DueDateNotificationRelativeToTypeTranslation[key as keyof typeof DueDateNotificationRelativeToTypeTranslation],
    }));

  constructor(
    private el: ElementRef,
    private readonly translateService: TranslateService,
    private readonly validationMessageService: ValidationMessageService,
  ) {
    this.errorMessages = {
      min: validationMessageService.validationMessages.min,
      max: validationMessageService.validationMessages.max,
    };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSmallScreen = this.el.nativeElement.parentElement.clientWidth < 400;
    }, 0);
  }

  removeRow(index: number): void {
    this.control?.removeAt(index);
    this.control?.markAsDirty();
  }

  addRow(): void {
    this.control?.push(new DueDateAutomaticReminderForm());
    this.control?.markAsDirty();
  }
}
