import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'lib-multi-checkbox-input',
  templateUrl: './multi-checkbox-input.component.html',
  styleUrls: ['./multi-checkbox-input.component.scss'],
})
export class MultiCheckboxInputComponent {
  @Input() options: string[] = [];
  @Input({ required: true }) control!: UntypedFormControl;

  public onCheckboxToggle(event: MatCheckboxChange, option: string): void {
    const currentValues = this.control.value ?? [];
    if (event.checked && currentValues.indexOf(option) < 0) {
      this.control.setValue([...currentValues, option]);
    } else {
      this.control.setValue(currentValues.filter((value: string) => value != option));
    }
    this.control.markAsDirty();
    this.control.markAsTouched();
  }
}
