import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '../../services/common';
import { DialogResult, Status } from '../../models';

@Component({
  selector: 'lib-passkey-name-dialog',
  templateUrl: './passkey-name-dialog.component.html',
  styleUrls: ['./passkey-name-dialog.component.scss'],
})
export class PasskeyNameDialogComponent implements OnInit {
  title: string = this.translateService.instant('Enter a name for the passkey');
  passkeyForm = new FormGroup({
    name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(
    public dialogRef: MatDialogRef<PasskeyNameDialogComponent, DialogResult<string>>,
    @Inject(MAT_DIALOG_DATA) public data: string | undefined,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.title = this.translateService.instant(`Enter a name for ${this.data} passkey`);
      this.passkeyForm.get('name')?.setValue(this.data);
    }
  }

  public close(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }

  public submit(): void {
    if (this.passkeyForm.valid) {
      this.dialogRef.close({ status: Status.CONFIRMED, data: this.passkeyForm.value.name });
    } else {
      this.passkeyForm.markAllAsTouched();
    }
  }
}
