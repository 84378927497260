<lib-metric-editor-form
  *ngIf="valueGroupSet"
  [valueGroupSet]="valueGroupSet"
  [indicator]="indicator"
  [documentContext]="documentContext"
  [sourceConfiguration]="sourceConfiguration"
  [disableFrequencyFields]="!frequencyEnabled"
  (update)="update($event)"
  (deleteGroup)="deleteGroup($event)"
  (resetValue)="resetValue($event)"
  (moveGroup)="moveGroup($event)"
></lib-metric-editor-form>
