import { AfterViewInit, Directive, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ActionItem, EmptyResults, Layout, Permission, Presentation } from '../models';
import { Subject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { EventsService } from '../services/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'lib-base-search-items',
})
export class BaseSearchItemsDirective implements AfterViewInit {
  @Input({ required: true }) layout?: Layout;
  @Input({ required: true }) presentation?: Presentation;
  @Input() grid: string = '';
  @Input() searchOptions: string = '';
  @Input() mode: 'nav' | 'drag' | 'select' = 'nav';
  @Input() showActions: boolean = false;
  @Input() cache?: string;
  @Input() refresh: Subject<boolean> = new Subject<boolean>();

  @Input() clickItemPermissions: Permission[] = [];

  @Output() action: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();

  // Context manu
  @ViewChild(MatMenuTrigger) contextMenu?: MatMenuTrigger;
  menuOpened: boolean = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  selectedItem?: ActionItem = undefined;
  activeItem?: ActionItem = undefined;
  noData: boolean = false;
  selectedItemID?: string;
  loaded: boolean = false;

  readonly dummies = new Array(12);
  readonly emptyResults: EmptyResults = {
    title: 'No results',
    subtitle: 'No search results available for this combination of filters.',
    image: 'laptop-neutral',
  };

  constructor(
    public renderer: Renderer2,
    public eventsService: EventsService,
  ) {}

  ngAfterViewInit(): void {
    if (!this.contextMenu) {
      return;
    }

    this.contextMenu.menuOpened.subscribe(() => {
      this.menuOpened = true;
    });
    this.contextMenu.menuClosed.subscribe(() => {
      this.selectedItem = undefined;
      this.menuOpened = false;
    });
  }

  onMouseOver(item: any): void {
    this.activeItem = item;
  }

  onMouseOut(): void {
    if (!this.menuOpened) {
      this.activeItem = undefined;
    }
  }

  onContextMenu(event: MouseEvent, item: any): void {
    if (this.showActions && item.children) {
      event.preventDefault();
      event.stopPropagation();
      this.activeItem = item;
      this.contextMenuPosition.x = String(event.clientX) + 'px';
      this.contextMenuPosition.y = String(event.clientY) + 'px';
      if (this.contextMenu) {
        this.contextMenu.menuData = { item };
        this.contextMenu.menu?.focusFirstItem('mouse');
        this.contextMenu.openMenu();
      }
    }
  }

  applyAction(action: ActionItem, item: ActionItem): void {
    action.item = item.item;
    this.action.emit(action);
  }

  selectItem(action: ActionItem): void {
    this.selectedItem = action.action === 'no_highlight' ? undefined : action.item;
    this.action.emit(action);
  }
}
