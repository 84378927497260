<div class="metric-table-container">
  <div class="table-title">{{ tableTitle }}</div>
  <div class="table-container">
    <table data-testid="metricTable" class="metric-table mat-elevation-z1" [formGroup]="tableFormGroup">
      <thead>
        <tr class="metric-table-row">
          <th class="sticky-column header">
            <div class="grid-column">
              <p *ngFor="let value of contextHeaderValues" class="fx-row align-center m-0">{{ value.label }}</p>
            </div>
          </th>
          <th class="metric-table-row--header-cell" *ngFor="let value of headerValues">
            {{ value.label }} {{ value.type_details?.units | unitSymbol: true }}
            <ng-container *ngIf="isConsolidationEnabled(sourceConfiguration, value)">
              <div class="icon-container" *ngIf="consolidatedInfoMap.get(value.value_definition_id)">
                <mat-icon
                  data-testid="consolidationInfoIcon"
                  svgIcon="info"
                  class="icon-md color-primary-900 ml-1"
                  libTooltip
                  [tooltip]="tooltip"
                ></mat-icon>
              </div>
            </ng-container>
            <ng-template #tooltip>
              <div class="tooltip">
                <div class="h-13 w-13">
                  <mat-icon svgIcon="info" class="tip-icon color-primary-900 ml-2"></mat-icon>
                </div>
                <div class="tooltip-text">{{ consolidatedInfoMap.get(value.value_definition_id) }}</div>
              </div>
            </ng-template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let groupFormGroup of tableFormGroup.getGroupFormGroupsExceptTableTotals(); let i = index"
          class="metric-table-row"
          [formGroup]="groupFormGroup"
        >
          <td class="sticky-column bkg-white">
            <div class="grid-column">
              <p *ngFor="let control of groupFormGroup.valueFormControls() | filter: filterLabelTypeValue">
                <lib-metric-editor-table-label-field [control]="control"></lib-metric-editor-table-label-field>
              </p>
            </div>
          </td>
          <td
            *ngFor="let control of groupFormGroup.valueFormControls() | filter: filterValuesExceptLabelType"
            class="metric-table-row--cell cell-container"
            [libIsActiveField]="focusedField$ | async"
            [focusFieldUniqueId]="focusFieldUniqueId$ | async"
            [value]="control?.valueRef"
            [focusId]="groupFormGroup.valueGroupRef | formatFieldUniqueId: control.valueRef"
            [ngClass]="{ editable: isFocusEnabled }"
            (click)="selectFieldForFocus(groupFormGroup, control.valueRef)"
            data-testid="field-focus"
          >
            <lib-numeric-input
              [attr.name]="control.valueRef.label"
              [maxDecimals]="control.valueRef.type_details?.max_decimals"
              [control]="control"
              [sourceConfiguration]="sourceConfiguration"
              [readonly]="control.disabled"
            ></lib-numeric-input>
            <div
              lib-field-comment-counter
              *ngIf="
                control.valueRef.unresolved_regular_comments_and_replies ||
                !!control.valueRef.unresolved_rejection_comments_and_replies
              "
              class="comments-count"
              [unresolved_regular_comments_and_replies]="control.valueRef.unresolved_regular_comments_and_replies"
              [unresolved_rejection_comments_and_replies]="control.valueRef.unresolved_rejection_comments_and_replies"
              (actionSelected)="selectFieldForFocus(groupFormGroup, control.valueRef, $event)"
            ></div>
            <div
              lib-field-actions
              data-testid="fieldActions"
              *ngIf="displayFieldActions"
              [valueDefinitionType]="control.valueRef.type"
              [unresolvedRejectionCommentsAndReplies]="control.valueRef.unresolved_rejection_comments_and_replies"
              [valueGroupSetStatus]="valueGroupSetStatus"
              [dataRequestSourceStatus]="dataRequestSourceStatus"
              [collaboratorResponsibility]="userResponsibility"
              (actionSelected)="selectFieldForFocus(groupFormGroup, control.valueRef, $event)"
            ></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="tableTotalFormGroup">
    <div class="mt-5 table-totals-container">
      <div *ngFor="let control of tableTotalFormGroup.valueFormControls()" data-testid="table-total-card">
        <div
          *ngIf="metricTableDefinition"
          class="table-total-card-title"
          [title]="
            metricTableDefinition.calculation_definitions
              | tableTotalFormula: control.valueRef
              | metricTableTotalFormula
          "
        ></div>
        <div
          [class]="'field-focus ' + (control.valueRef | valueDefinitionFieldSize)"
          [libIsActiveField]="focusedField$ | async"
          [focusFieldUniqueId]="focusFieldUniqueId$ | async"
          [value]="control.valueRef"
          [focusId]="tableTotalFormGroup.valueGroupRef | formatFieldUniqueId: control.valueRef"
          [ngClass]="{
            editable: isFocusEnabled,
            'has-comments':
              this.control.valueRef.unresolved_regular_comments_and_replies ||
              !!this.control.valueRef.unresolved_rejection_comments_and_replies,
          }"
          (click)="selectFieldForFocus(tableTotalFormGroup, control.valueRef)"
          data-testid="field-focus"
        >
          <lib-metric-editor-numeric-field
            [valueFormControl]="control"
            [indicatorId]="indicatorId"
            [vgsetId]="vgsetId"
            [groupId]="tableTotalFormGroup.valueGroupRef.id"
            [itemType]="documentContext.itemType"
            [metricTableDefinition]="metricTableDefinition"
            [sourceConfiguration]="sourceConfiguration"
            #valueFieldRef
          ></lib-metric-editor-numeric-field>
        </div>
      </div>
    </div>
  </div>
</div>
