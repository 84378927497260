import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[libExpandedRow]',
})
export class ExpandedRowDirective {
  @Input() isExpandable = true;
  @Input() rowItem: any;
  @Input() isRowExpanded: boolean = false;
  @Output() rowExpanded: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isExpandable) {
      this.isExpanded = !this.isRowExpanded;
      this.rowItem['isExpanded'] = this.isExpanded;
    }
  }

  isExpanded: boolean = false;

  ngOnInit() {
    this.isExpanded = 'isExpanded' in this.rowItem ? this.rowItem['isExpanded'] : this.isExpanded;
  }
}
