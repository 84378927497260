export const OAUTH_STATE_EXPIRED_OR_INVALID_ERROR: string = 'oauth_state_expired_or_invalid';
export const OAUTH_MISSING_EMAIL_ERROR: string = 'oauth_missing_email';
export const OAUTH_USER_NOT_FOUND_ERROR: string = 'oauth_user_not_found';
export const OAUTH_INVALID_GRANT_ERROR: string = 'oauth_invalid_grant_error';
export const OAUTH_INVALID_CLIENT_ERROR: string = 'oauth_invalid_client_error';
export const OAUTH_FETCH_USER_INFO_ERROR: string = 'oauth_fetch_user_info_error';
export const OAUTH_USER_INFO_FORMAT_ERROR: string = 'oauth_user_info_format_error';
export const SAML_PROVIDER_NOT_FOUND_ERROR: string = 'saml_provider_not_found';
export const SAML_PROVIDER_DISABLED_ERROR: string = 'saml_provider_disabled';
export const SAML_USER_NOT_FOUND_ERROR: string = 'saml_user_not_found';
export const SP_INVALID_CREDENTIALS_ERROR: string = 'sp_invalid_credentials_error';
export const SP_UNAUTHORIZED_ERROR: string = 'sp_unauthorized_error';
