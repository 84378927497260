import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BtnTypeCssClass, EmptyResults, ViewerActionType } from '../../models';

@Component({
  selector: 'lib-data-table-empty-results',
  templateUrl: './data-table-empty-results.component.html',
  styleUrls: ['./data-table-empty-results.component.scss'],
})
export class DataTableEmptyResultsComponent {
  @Input() displayData?: EmptyResults;
  @Input() isChildren: boolean = false;

  @Output() action: EventEmitter<void> = new EventEmitter<void>();

  viewerActionType: typeof ViewerActionType = ViewerActionType;
  btnClass: typeof BtnTypeCssClass = BtnTypeCssClass;

  public onBtnClick(): void {
    this.action.emit();
  }
}
