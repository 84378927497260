import { Pipe, PipeTransform } from '@angular/core';
import { ReportStatusEnum } from '../../models';
import { TranslateService } from '../../services/common';

@Pipe({ name: 'statusTransition' })
export class StatusTransitionPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(to: ReportStatusEnum, from?: ReportStatusEnum): string {
    if (!from) {
      return this.translateService.instant('Questionnaire started');
    } else if (from === ReportStatusEnum.draft && to === ReportStatusEnum.completed) {
      return this.translateService.instant('Questionnaire complete');
    } else if (from === ReportStatusEnum.completed && to === ReportStatusEnum.draft) {
      return this.translateService.instant('Questionnaire in progress');
    } else if (from === ReportStatusEnum.completed && to === ReportStatusEnum.archived) {
      return this.translateService.instant('Questionnaire closed');
    } else {
      return this.translateService.instant('Questionnaire reopened');
    }
  }
}
