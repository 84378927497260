import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '../../services/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  isNovistoService(requestUrl: string): boolean {
    return requestUrl.includes('api.') || requestUrl.includes('localhost');
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Filter Authenticated API requests
    if (!this.isNovistoService(request.url) || !this.authService.user) {
      return next.handle(request);
    }
    // Add Authorization header
    request = request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + this.authService.user.access_token),
    });

    return next.handle(request);
  }
}
