import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  DialogResult,
  FilterBarOption,
  FilterBarSelection,
  FilterType,
  Presentation,
  ResourceType,
  Status,
  TableColumn,
  Taxonomy,
} from '../../../models';
import { AddTaxonomiesDialogForm } from './add-taxonomies-dialog-form';
import { DataTablePaginatorConfiguration } from '../../../data-table';
import { Observable, combineLatestWith, map } from 'rxjs';
import { provideComponentStore } from '@ngrx/component-store';
import { AddTaxonomiesDialogStore } from './add-taxonomies-dialog.store';
import { TranslateService } from '../../../services/common';
import { TaxonomySort } from '../../../translations';
import { MetricApiService } from '../../../services/types';
import { ActionItemUtils } from '../../../classes';

export interface AddTaxonomiesDialogComponentConfig {
  valueDefinitionOrTableId?: string;
  metric_id: string;
  taxonomies: Taxonomy[];
  value_definition_group_id?: string;
}

export type AddTaxonomiesDialogComponentResult = DialogResult<{ taxonomies: Taxonomy[] }>;

interface Data {
  count: number;
  isLoading: boolean;
  isLoadingNextPage: boolean;
  paginationConfig: DataTablePaginatorConfiguration;
  tableFilters: FilterBarOption[];
  taxonomies: Taxonomy[];
}

@Component({
  selector: 'lib-add-taxonomies-dialog',
  templateUrl: './add-taxonomies-dialog.component.html',
  styleUrls: ['./add-taxonomies-dialog.component.scss'],
  providers: [provideComponentStore(AddTaxonomiesDialogStore)],
})
export class AddTaxonomiesDialogComponent implements OnInit, AfterContentInit {
  public readonly ePresentation = Presentation;

  public data$?: Observable<Data>;
  public form: AddTaxonomiesDialogForm;
  public selections: Record<string, boolean> = {};
  public tableColumns: TableColumn<Taxonomy>[] = [];

  constructor(
    private readonly addTaxonomiesDialogStore: AddTaxonomiesDialogStore,
    private readonly metricsService: MetricApiService,
    private readonly translateService: TranslateService,
    private dialogRef: MatDialogRef<AddTaxonomiesDialogComponentConfig, AddTaxonomiesDialogComponentResult>,
    @Inject(MAT_DIALOG_DATA) public readonly data: AddTaxonomiesDialogComponentConfig,
  ) {
    this.form = new AddTaxonomiesDialogForm();
  }

  public ngOnInit(): void {
    this.addTaxonomiesDialogStore.initialize(this.data.taxonomies.map((t) => t.id));
    this.data$ = this.addTaxonomiesDialogStore.taxonomies$.pipe(
      combineLatestWith(
        this.addTaxonomiesDialogStore.clientSelectableTaxonomies$,
        this.addTaxonomiesDialogStore.loadingState$,
        this.addTaxonomiesDialogStore.paginationState$,
        this.addTaxonomiesDialogStore.count$,
        this.addTaxonomiesDialogStore.total$,
      ),
      map(([taxonomies, clientSelectableTaxonomies, loadingState, paginationState, count, total]) => ({
        count,
        isLoading: loadingState.isLoading,
        isLoadingNextPage: loadingState.isLoadingNextPage,
        paginationConfig: {
          currentPage: paginationState.currentPage,
          pageSize: paginationState.pageSize,
          total,
        },
        tableFilters: this.getFilterOptions(clientSelectableTaxonomies),
        taxonomies,
      })),
    );
  }

  public ngAfterContentInit(): void {
    this.setupColumns();
  }

  public closeDialog(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }

  public onCheckChanged({ selection }: { selection: string }): void {
    const selections = [...this.form.controls.taxonomies.value];
    const newSelections = selections.filter((s) => s !== selection);
    if (selections.length === newSelections.length) {
      newSelections.push(selection);
      this.selections[selection] = true;
    } else {
      this.selections[selection] = false;
    }
    this.form.controls.taxonomies.setValue(newSelections);
  }

  public onFilterChange(filters: FilterBarSelection[]): void {
    this.addTaxonomiesDialogStore.updateFilters(filters);
  }

  public onLoadNextPage(isLoading: boolean, total: number, count: number): void {
    if (!isLoading && count < total) {
      this.addTaxonomiesDialogStore.loadNextPage();
    }
  }

  public onSearchChange(searchTerm?: string): void {
    this.addTaxonomiesDialogStore.updateSearchTermState(searchTerm);
  }

  public save(): void {
    const { valueDefinitionOrTableId, metric_id, value_definition_group_id } = this.data;
    const payload = this.form.toModel();
    let obs;

    if (!valueDefinitionOrTableId) {
      obs = this.metricsService.addTaxonomies(metric_id, payload);
    } else if (value_definition_group_id) {
      obs = this.metricsService.addFieldTaxonomies(
        metric_id,
        value_definition_group_id,
        valueDefinitionOrTableId,
        payload,
      );
    } else {
      obs = this.metricsService.addMetricTableTaxonomies(metric_id, valueDefinitionOrTableId, payload);
    }

    obs.subscribe((res) => {
      this.dialogRef.close({ status: Status.SUCCESS, data: { taxonomies: res.data } });
    });
  }

  private getFilterOptions(clientSelectableTaxonomies: Taxonomy[]): FilterBarOption[] {
    if (!clientSelectableTaxonomies.length) {
      return [];
    }

    return [
      {
        icon: 'filter',
        title: this.translateService.instant('Framework Taxonomy'),
        id: 'taxonomyId',
        optionType: FilterType.searchList,
        displayAll: true,
        options: ActionItemUtils.resourcesToActionItem(clientSelectableTaxonomies, ResourceType.taxonomy_code),
      },
      {
        icon: 'sort',
        title: this.translateService.instant('Sort'),
        id: 'orderBy',
        optionType: FilterType.list,
        displayAll: false,
        options: this.translateService.listResources(TaxonomySort),
        defaultValue: this.translateService.instant(TaxonomySort.code),
      },
    ];
  }

  private setupColumns(): void {
    this.tableColumns = [
      { name: this.translateService.instant('Framework'), dataKey: 'framework.name' },
      { name: this.translateService.instant('Taxonomy'), dataKey: 'label' },
      { name: this.translateService.instant('Code'), dataKey: 'code' },
    ];
  }
}
