<div class="tip">
  <div class="fx-row align-center mb-0">
    <mat-icon
      *ngIf="iconConfig.icon"
      [svgIcon]="iconConfig.icon"
      data-testid="icon"
      class="tip-icon color-{{ iconConfig.color }} mr-2"
    ></mat-icon>
    <div class="text tip-text tip-text-{{ iconConfig.color }} mat-body pl-3" [innerHTML]="tipText | sanitizeHtml"></div>
  </div>
</div>
