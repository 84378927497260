import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'td[lib-vertical-resizable]',
  templateUrl: './vertical-resizable.component.html',
  styleUrls: ['./vertical-resizable.component.scss'],
})
export class VerticalResizableComponent implements OnInit {
  @Input('lib-vertical-resizable') isResizable: boolean = false;

  private readonly td?: HTMLElement;
  private tr?: HTMLElement;

  constructor(
    protected readonly renderer: Renderer2,
    protected readonly el: ElementRef,
  ) {
    this.td = this.el.nativeElement;
  }

  public ngOnInit(): void {
    if (this.isResizable) {
      this.tr = this.renderer.parentNode(this.td);
    }
  }

  public onResize(height: number) {
    const cells = this.tr?.querySelectorAll('.mat-cell') ?? [];
    for (const cell of cells) {
      this.renderer.setStyle(cell, 'height', `${height}px`);
    }
  }
}
