import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ValidationMessageService } from '../../../services/common';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface SearchableSelectInputParameters {
  from: number;
  searchValue: string;
}

let nextId = 0;

@Component({
  selector: 'lib-searchable-select-input',
  templateUrl: './searchable-select-input.component.html',
  styleUrls: ['./searchable-select-input.component.scss'],
})
export class SearchableSelectInputComponent<T> implements OnChanges, OnDestroy {
  private _compareWith = (o1: T, o2: T) => o1 === o2;

  @Input() options: T[] = [];
  @Input() bindOptionLabelFn: (option: T) => string = (option: T) => String(option);
  @Input() label = '';
  @Input() control?: UntypedFormControl;
  @Input() hint?: string;
  @Input() messages: ValidationErrors = {};
  @Input() placeholder?: string;
  @Input() searchable: boolean = true;
  @Input() isLoadingOptions: boolean = false;
  @Input() reachedEnd: boolean = false;
  @Input() minMenuScrollItems: number = 10;
  @Input() parentControl?: FormGroup | UntypedFormControl;
  @Input() autoFocusValue?: T;
  @Input() compareFn?: (optionsA: T, optionsB: T) => boolean = this._compareWith;
  @Input() labelPosition: 'top' | 'left' = 'top';

  @Output() loadOptions: EventEmitter<SearchableSelectInputParameters> =
    new EventEmitter<SearchableSelectInputParameters>();
  @Output() openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('matSelectPanelInput') matSelectPanelInput!: ElementRef<HTMLInputElement>;
  @ViewChild('focusElement') focusElement!: MatSelect;

  readonly _inputId = `searchable-select-input-${nextId++}`;
  readonly DEBOUNCE_TIME = 500;

  public inputControl: UntypedFormControl = new UntypedFormControl('');
  public required: boolean = false;
  public errorMessages: ValidationErrors = {};

  private searchInputSubscription?: Subscription;
  private searchParameters: SearchableSelectInputParameters = {
    from: 0,
    searchValue: '',
  };

  constructor(private validationMessageService: ValidationMessageService) {}

  public ngOnChanges(): void {
    this.initializeInput();
  }

  public ngOnDestroy(): void {
    this.searchInputSubscription?.unsubscribe();
  }

  public setFocus(): void {
    this.focusElement.focus();
  }

  public setFocusOnSearch(): void {
    if (this.searchable && this.options.length >= this.minMenuScrollItems) {
      this.matSelectPanelInput.nativeElement.focus();
    }

    const valueToFocus = this.control?.value ?? this.autoFocusValue;
    if (valueToFocus) {
      for (const option of this.focusElement.options) {
        if (option.value === valueToFocus) {
          option.setActiveStyles();
          option.focus();
        } else {
          option.setInactiveStyles();
        }
      }
    }
  }

  public resetSearch(): void {
    if (this.inputControl.value) {
      this.inputControl.reset();
      this.searchParameters.searchValue = String(this.inputControl.value || '');
      this.loadOptions.emit(this.searchParameters);
    }
  }

  public loadMoreOptions(): void {
    if (!this.isLoadingOptions && !this.reachedEnd) {
      this.searchParameters.from = this.options.length;
      this.loadOptions.emit(this.searchParameters);
    }
  }

  private initializeInput(): void {
    this.required = Boolean(this.control?.hasValidator(Validators.required));
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };

    this.searchInputSubscription?.unsubscribe();
    this.searchInputSubscription = this.inputControl.valueChanges
      .pipe(debounceTime(this.DEBOUNCE_TIME))
      .subscribe((value) => {
        if (value !== undefined) {
          this.searchParameters.searchValue = String(value);
          this.searchParameters.from = 0;
          this.options = [];
          this.loadOptions.emit(this.searchParameters);
        }
      });
  }
}
