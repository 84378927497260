import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SourceType } from '../../models';
import { SortingTranslation } from '../sorting.translation';

export { SourcesSourceSort, SourcesSourceTypes, SourceCategories };

const SourcesSourceSort = {
  name: SortingTranslation.alphabetical,
  updated: SortingTranslation.last_updated,
};

const SourcesSourceTypes = {
  [SourceType.business_unit]: _('Business Unit'),
  [SourceType.facility]: _('Facility'),
  [SourceType.office]: _('Office'),
  [SourceType.store]: _('Store'),
  [SourceType.subsidiary]: _('Subsidiary'),
  [SourceType.location]: _('Location'),
  [SourceType.region]: _('Region'),
  [SourceType.market]: _('Market'),
  [SourceType.country]: _('Country'),
  [SourceType.province]: _('Province'),
  [SourceType.state]: _('State'),
  [SourceType.division]: _('Division'),
};

const SourceCategories = {
  consolidated: _('Consolidated'),
  regular: _('Regular'),
};
