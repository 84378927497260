import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-indicator-dummy',
  templateUrl: './indicator-dummy.component.html',
  styleUrls: ['./indicator-dummy.component.scss'],
})
export class IndicatorDummyComponent {
  @Input() header: boolean = true;
  @Input() body: boolean = true;
}
