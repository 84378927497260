import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SelectMetricValueDefinitionComponent } from './select-metric-value-definition/select-metric-value-definition.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableModule } from '../data-table';
import { SearchModule } from '../search';
import { SelectMetricTableColumnDefinitionComponent } from './select-metric-table-column-definition/select-metric-table-column-definition.component';
import { SelectMetricTableCellValueDefinitionComponent } from './select-metric-table-cell-value-definition/select-metric-table-cell-value-definition.component';
import { ComponentsModule } from '../components';

@NgModule({
  declarations: [
    SelectMetricValueDefinitionComponent,
    SelectMetricTableColumnDefinitionComponent,
    SelectMetricTableCellValueDefinitionComponent,
  ],
  imports: [
    CommonModule,
    DataTableModule,
    MaterialModule,
    FormsModule,
    PipesModule,
    TranslateModule,
    SearchModule,
    ComponentsModule,
  ],
  exports: [SelectMetricValueDefinitionComponent],
})
export class SelectMetricValueDefinitionModule {}
