import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResult, Status, Tag, TagCannotBeDeletedDialogConfig } from '../../models';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-tag-cannot-be-deleted-dialog',
  templateUrl: './tag-cannot-be-deleted-dialog.component.html',
  styleUrls: ['./tag-cannot-be-deleted-dialog.component.scss'],
})
export class TagCannotBeDeletedDialogComponent {
  title: string = this.translateService.instant('Attention!');
  warningMsg: string = '';
  tag: Tag;
  associationsListClasses = ['label', 'associationsList'];

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<TagCannotBeDeletedDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: TagCannotBeDeletedDialogConfig,
  ) {
    this.tag = data.tag;
    this.warningMsg = this.translateService.instant(
      'The tag "{tag}" cannot be deleted because it is currently being used by:',
      { tag: data.tag.name },
    );
  }

  public close(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }

  public confirm(): void {
    this.dialogRef.close({ status: Status.CONFIRMED });
  }
}
