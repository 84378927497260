import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderStateService, LoaderStates } from './services/loader-state.service';

@Component({
  selector: 'lib-app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements AfterViewInit {
  public readonly eLoaderStates = LoaderStates;

  loaderState$: Observable<string>;
  loaderMessage$: Observable<string>;
  @ViewChild('loader') loaderRef!: ElementRef;

  @Input() opacity: number = 1;

  constructor(private readonly loaderStateService: LoaderStateService) {
    this.loaderState$ = this.loaderStateService.loaderState$;
    this.loaderMessage$ = this.loaderStateService.loaderMessage$;
  }

  ngAfterViewInit() {
    this.loaderRef.nativeElement.addEventListener('transitionend', () => this.loaderStateService.closeloader());
  }
}
