<lib-dialog-title [title]="'Configure Total' | translate" (closeEvent)="closeDialog()"></lib-dialog-title>

<div mat-dialog-content class="panel-dialog full-width mt-1 total-form-dialog">
  <form [formGroup]="tableTotalForm" class="form">
    <section class="large table-total-form-header">
      <lib-text-input [label]="'Total label' | translate" [control]="tableTotalForm.labelControl"></lib-text-input>

      <div class="total-formula">
        <lib-select-input
          class="operation-selector"
          [control]="tableTotalForm.operationControl"
          [options]="operationChoices"
          data-testid="operatorSelectInput"
        ></lib-select-input>
        <lib-text-area-input class="formula-display" [control]="tableTotalForm.formulaControl"></lib-text-area-input>
      </div>
      <div class="formula-operator-description mv-2">
        {{
          (tableTotalForm.operationControl.value === eMetricTableCalculationTypeDetailFormulaOperation.SUM
            ? "Sums values as they are entered"
            : "Averages the values entered (does not average against all options, only those that are populated)"
          ) | translate
        }}
      </div>

      <div class="fx-row total-units">
        <lib-select-input
          *ngIf="unitFamilies$ | async as unitFamilyOptions"
          [label]="'Unit family' | translate"
          [control]="tableTotalForm.familyControl"
          [options]="unitFamilyOptions"
          (selectionChangeEvent)="tableTotalForm.unitsControl.reset()"
        ></lib-select-input>
        <lib-select-input
          *ngIf="unitOptions$ | async as unitOptions"
          [label]="'Unit default' | translate"
          [control]="tableTotalForm.unitsControl"
          [options]="tableTotalForm.familyControl.value | filterUnitsByFamily: unitOptions"
        ></lib-select-input>
      </div>
      <div class="fx-row mb-3">
        <lib-select-input
          class="decimal-select-width"
          [label]="'Decimals' | translate"
          [control]="tableTotalForm.decimalValueControl"
          [options]="numericDecimalOptions"
        ></lib-select-input>
      </div>
    </section>

    <section class="large input-columns">
      <lib-metric-table-total-columns
        class="formfield large"
        [columnTypeLabel]="'Input Columns' | translate"
        [columnsForm]="tableTotalForm.inputColumnsFormArray"
        (removeColumn)="onColumnDelete($event, eMetricTableColumnType.INPUT)"
      ></lib-metric-table-total-columns>

      <div class="add-column-button">
        <button lib-button buttonType="primary" [matMenuTriggerFor]="inputColumnMenu" aria-label="Add Input Column">
          {{ "Add Input Column" | translate }}
          <mat-icon svgIcon="arrow-head-down"></mat-icon>
        </button>
        <mat-menu #inputColumnMenu="matMenu">
          <div *ngFor="let inputColumn of metricTableInputColumns">
            <button mat-menu-item (click)="tableTotalForm.addInputColumnControl(inputColumn.label)">
              {{ inputColumn.label }}
            </button>
          </div>
        </mat-menu>
      </div>
    </section>

    <section class="large">
      <lib-metric-table-total-columns
        class="formfield large"
        [columnTypeLabel]="'Context Columns' | translate"
        [columnsForm]="tableTotalForm.contextColumnsFormArray"
        [isContext]="true"
        [metricTableContextColumns]="metricTableContextColumns"
        (removeColumn)="onColumnDelete($event, eMetricTableColumnType.CONTEXT)"
      ></lib-metric-table-total-columns>

      <div class="add-column-button">
        <button
          lib-button
          buttonType="primary"
          aria-label="Add Context Column"
          [disabled]="!tableTotalForm.inputColumnsFormArray.length || selectableMetricTableContextColumns.length <= 0"
          [matMenuTriggerFor]="contextColumnMenu"
        >
          {{ "Add Context Column" | translate }}
          <mat-icon svgIcon="arrow-head-down"></mat-icon>
        </button>
        <mat-menu #contextColumnMenu="matMenu">
          <div *ngFor="let contextColumn of selectableMetricTableContextColumns">
            <button mat-menu-item (click)="selectContextColumn(contextColumn)">
              {{ contextColumn.label }}
            </button>
          </div>
        </mat-menu>
      </div>
    </section>
  </form>
</div>

<div mat-dialog-actions>
  <button
    lib-button
    buttonType="success"
    (click)="addTableTotal()"
    [disabled]="tableTotalForm.invalid || tableTotalForm.pristine"
  >
    {{ "Add" | translate }}
  </button>
  <button lib-button buttonType="link" class="ml-2" (click)="closeDialog()">{{ "Cancel" | translate }}</button>
</div>
