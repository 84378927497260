import { Observable } from 'rxjs';

import {
  ApiResponse,
  AttributeCheckResponse,
  BulkUpsertOptionListRequest,
  ChoiceFieldSource,
  ListOptionListItemBaseRequest,
  ListOptionListItemRequest,
  ListOptionListRequest,
  OptionList,
  OptionListCategory,
  OptionListItem,
  OptionListItemValidation,
  OptionListType,
  UpsertOptionListItemRequest,
  UpsertOptionListRequest,
  ValueDefinition,
} from '../../../models';
import { Router } from '@angular/router';

export abstract class OptionListsApiService {
  constructor(protected readonly router: Router) {}

  public abstract activateOptionList(optionListId: string): Observable<ApiResponse<OptionList>>;

  public abstract activateOptionListItem(
    optionListId: string,
    optionListItemId: string,
  ): Observable<ApiResponse<OptionListItem>>;

  public abstract addOptionList(payload: UpsertOptionListRequest): Observable<ApiResponse<OptionList>>;

  public abstract addOptionListItem(
    optionListId: string,
    payload: UpsertOptionListItemRequest,
  ): Observable<ApiResponse<OptionListItem>>;

  public abstract bulkAddOptionListItems(
    optionListId: string,
    payload: BulkUpsertOptionListRequest,
  ): Observable<ApiResponse<OptionListItem[]>>;

  public abstract checkOptionListItemName(
    optionListId: string,
    name: string,
  ): Observable<ApiResponse<AttributeCheckResponse>>;

  public abstract checkOptionListName(name: string): Observable<ApiResponse<AttributeCheckResponse>>;

  public abstract deactivateOptionList(optionListId: string): Observable<ApiResponse<OptionList>>;

  public abstract deactivateOptionListItem(
    optionListId: string,
    optionListItemId: string,
  ): Observable<ApiResponse<OptionListItem>>;

  public abstract deleteOptionList(optionListId: string): Observable<void>;

  public abstract deleteOptionListItem(optionListId: string, optionListItemId: string): Observable<void>;

  public abstract editOptionList(
    optionListId: string,
    payload: UpsertOptionListRequest,
  ): Observable<ApiResponse<OptionList>>;

  public abstract editOptionListItem(
    optionListId: string,
    optionListItemId: string,
    payload: UpsertOptionListItemRequest,
  ): Observable<ApiResponse<OptionListItem>>;

  public abstract fetchOptionListCategory(valueDefinition: ValueDefinition): Observable<string | null>;

  public abstract getOptionList(optionListId: string): Observable<ApiResponse<OptionList>>;

  public abstract listOptionListCategories(): Observable<ApiResponse<OptionListCategory[]>>;

  public abstract listOptionListItems(
    optionListId: string,
    payload: ListOptionListItemRequest,
  ): Observable<ApiResponse<OptionListItem[]>>;

  public abstract listOptionListItemsFromSource(
    optionListId: string,
    payload?: ListOptionListItemBaseRequest,
    source?: ChoiceFieldSource | null,
    isPublic?: boolean,
    applyCDPTagsFiltering?: boolean,
  ): Observable<ApiResponse<OptionListItem[]>>;

  public abstract listOptionLists(payload: ListOptionListRequest): Observable<ApiResponse<OptionList[]>>;

  public abstract listResourceListItems(
    optionListId: string,
    payload?: ListOptionListItemBaseRequest,
    applyCDPTagsFiltering?: boolean,
  ): Observable<ApiResponse<OptionListItem[]>>;

  public abstract listResourceLists(
    categoryId?: string | null,
    type?: OptionListType | null,
  ): Observable<ApiResponse<OptionList[]>>;

  public abstract makeOptionListCategoryDefault(
    optionListId: string,
    categoryId: string,
  ): Observable<ApiResponse<OptionList>>;

  public abstract moveOptionListItem(
    optionListId: string,
    optionListItemId: string,
    position: number,
  ): Observable<ApiResponse<OptionListItem>>;

  public abstract validateEditOptionListItem(
    optionListId: string,
    optionListItemId: string,
    payload: UpsertOptionListItemRequest,
  ): Observable<OptionListItemValidation>;

  protected abstract getOptionListPath(optionListId: string): string;

  public redirectToOptionList(optionListId: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([this.getOptionListPath(optionListId)]));
    window.open(url, '_blank');
  }
}
