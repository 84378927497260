<mat-menu #moreMenu="matMenu">
  <ng-template matMenuContent>
    <div class="ph-3 pt-1 menu-view">
      <ng-container *ngTemplateOutlet="chips; context: { items: remainingChipItems }"></ng-container>
    </div>
  </ng-template>
</mat-menu>

<div class="chip-card-container fx-row">
  <div class="chip-cards" [ngClass]="{ 'limited-view': maxItems === 1 }">
    <ng-container *ngTemplateOutlet="chips; context: { items: chipsToShow }"></ng-container>
  </div>
  <a
    class="btn btn-xs bkg-grey-200 btn-more-link"
    *ngIf="remainingChipItems.length"
    [ngClass]="{ active: trigger.menuOpen }"
    [matMenuTriggerFor]="moreMenu"
    #trigger="matMenuTrigger"
    (click)="stopDefaultClickAction($event)"
    data-testid="additionalResources"
  >
    + {{ remainingChipItems.length }}
  </a>
</div>

<ng-template #chips let-items="items">
  <a
    *ngFor="let chip of items"
    class="btn btn-xs mr-1"
    [ngClass]="{
      'btn-primary': chip.item_type === eChipType.primary,
      'btn-stroked': chip.item_type === eChipType.stroked,
      'bkg-gray-200 color-grey-600': chip.item_type === eChipType.light,
      'disabled-pointer': disableChipAction,
    }"
    (click)="onChipsClick($event, chip)"
  >
    <span [ngClass]="{ 'color-grey-900': chip.item_type === eChipType.light }">
      {{ chip.title }}
    </span>
  </a>
</ng-template>
