import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FieldActionsErrorType } from './field-actions.validator';

export const FieldActionsErrorTypeTranslation: Record<FieldActionsErrorType, string> = {
  [FieldActionsErrorType.INVALID_VD_TYPE]: _('Reject input values'),
  [FieldActionsErrorType.METRIC_ALREADY_PROCESSED]: _('Metric already processed'),
  [FieldActionsErrorType.INVALID_REJECTION_COMMENTS_COUNT]: _(
    'To reject this field again, resolve the previous rejection',
  ),
};
