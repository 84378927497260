import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../../common/api/api.service';
import { ApiResponse, Org, Metric, ApplicationApiDefinition } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ClientGlobalService {
  apiName: keyof ApplicationApiDefinition = 'platform';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.platform.resources.global;
  }

  public listOrgs(): Observable<ApiResponse<Org[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/favorite_orgs`);
  }

  public listMinMetrics(): Observable<ApiResponse<Metric[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/minimal`);
  }
}
