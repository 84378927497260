import { Pipe, PipeTransform } from '@angular/core';
import { Metric, MetricCategory, ValueDefinition } from '../../../models';

@Pipe({
  name: 'canDeleteValueDefinition',
})
export class CanDeleteValueDefinitionPipe implements PipeTransform {
  transform(valueDefinition: ValueDefinition, metric?: Metric, isCore?: boolean): boolean {
    if (isCore) {
      const isValueDefinitionOfRefV2Published: boolean = Boolean(
        metric?.category === MetricCategory.REFERENCE && metric.reference_v2 && valueDefinition.published,
      );
      return !isValueDefinitionOfRefV2Published;
    }

    const isThirdParty: boolean = metric?.category === MetricCategory.THIRD_PARTY;
    const isCoreValueDefinitionOfRefV2: boolean = Boolean(
      metric?.reference_v2 && valueDefinition.core_value_definition_id != null,
    );

    return !(isThirdParty || isCoreValueDefinitionOfRefV2);
  }
}
