import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../../services/common';
import { ValueFormControl } from '../../models/valueFormControl';

@Pipe({
  name: 'fileLabel',
})
export class FileLabelPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(valueFormControl: ValueFormControl, isFileUploading: boolean): string {
    if (valueFormControl.invalid && valueFormControl.touched) {
      return this.translateService.instant('File upload error');
    } else if (isFileUploading) {
      return this.translateService.instant('File upload in progress');
    }
    return valueFormControl.valueRef.label ?? '';
  }
}
