<section class="section section-dynamic" [ngClass]="{ loaded: loaded }">
  <div class="section-header">
    <h4 class="mat-subtitle-2 color-grey-900">{{ title }}</h4>
    <lib-link class="mat-body-1" [text]="linkText" [routerLink]="link"></lib-link>
  </div>
  <div class="section-content" [ngClass]="{ loaded: loaded }">
    <div class="collection collection-dummies">
      <lib-dummy *ngFor="let dummy of dummies" [presentation]="presentation"></lib-dummy>
    </div>
    <div class="collection collection-items">
      <lib-card-empty
        [presentation]="presentation"
        [link]="emptyLink"
        [linkText]="emptyLinkText"
        *ngIf="!itemCollection || itemCollection?.items?.length === 0"
      ></lib-card-empty>
      <ng-container *ngFor="let item of itemCollection?.items">
        <lib-card
          *libFeatureFlag="{ featureFlags: item.featureFlag }"
          [item]="item"
          [presentation]="presentation"
          [path]="link"
          [pathPosition]="linkPosition"
          [limitedCardSize]="true"
        >
        </lib-card>
      </ng-container>
    </div>
  </div>
</section>
