<!-- Menus -->

<mat-menu #filterMenu="matMenu">
  <!-- <ng-template matMenuContent> -->
  <ng-container *ngIf="filterCount">
    <a mat-menu-item class="color-error" title="{{ 'Reset' | translate }}" (click)="resetFilters()">
      <mat-icon class="icon-sm" svgIcon="frequency"></mat-icon>
      {{ "Reset" | translate }}
    </a>
    <div class="divider divider-horizontal"></div>
  </ng-container>
  <a
    mat-menu-item
    *ngIf="category === eMetricCategory.THIRD_PARTY"
    [ngClass]="{ active: searchOptions.filters.framework }"
    title="{{ 'Frameworks' | translate }}"
    [matMenuTriggerFor]="frameworkMenu"
  >
    <mat-icon class="icon-sm" svgIcon="table"></mat-icon>
    <span *ngIf="!searchOptions.filters.framework">{{ "Framework" | translate }}</span>
    <span *ngIf="searchOptions.filters.framework">
      {{ searchOptions.filters.framework.title }}
    </span>
  </a>
  <a
    mat-menu-item
    [ngClass]="{ active: searchOptions.filters.topic }"
    title="{{ 'Topics' | translate }}"
    [matMenuTriggerFor]="topicMenu"
  >
    <mat-icon class="icon-sm" svgIcon="category"></mat-icon>
    <span *ngIf="!searchOptions.filters.topic">{{ "Topic" | translate }}</span>
    <span *ngIf="searchOptions.filters.topic">
      {{ searchOptions.filters.topic.title }}
    </span>
  </a>
  <a
    mat-menu-item
    [ngClass]="{ active: searchOptions.filters.industry }"
    title="{{ 'Industries' | translate }}"
    [matMenuTriggerFor]="industryMenu"
  >
    <mat-icon class="icon-sm" svgIcon="industry"></mat-icon>
    <span *ngIf="!searchOptions.filters.industry">{{ "Industry" | translate }}</span>
    <span *ngIf="searchOptions.filters.industry">
      {{ searchOptions.filters.industry.title }}
    </span>
  </a>
  <!-- </ng-template> -->
</mat-menu>

<mat-menu #frameworkMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Framework" | translate }}</a>
    <hr />
    <a mat-menu-item (click)="setItem('framework')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <a
      *ngFor="let item of filters.framework.items"
      mat-menu-item
      [ngClass]="{ active: searchOptions.filters.framework === item }"
      (click)="setItem('framework', item)"
    >
      {{ item.title }}
    </a>
  </ng-template>
</mat-menu>

<mat-menu #topicMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Topic" | translate }}</a>
    <div
      class="menu-search"
      (click)="$event.stopPropagation()"
      *ngIf="filters.topic.control && filters.topic.items.length > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="filters.topic.control"
      />
      <a class="clear-search" *ngIf="filters.topic.control.value" (click)="filters.topic.control.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    </div>
    <hr />
    <a mat-menu-item (click)="setItem('topic')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filters.topic.items.length > minMenuScrollItems }">
      <a
        mat-menu-item
        *ngFor="let item of filters.topic.filteredItems | async"
        [title]="item.title"
        (click)="setItem('topic', item)"
        [ngClass]="{ active: searchOptions.filters.topic?.id === item.id }"
      >
        <span [ngClass]="{ 'pl-2': item.action === 'group', 'pl-4': item.action === 'topic' }">{{ item.title }}</span>
      </a>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #industryMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Industry" | translate }}</a>
    <div
      class="menu-search"
      (click)="$event.stopPropagation()"
      *ngIf="filters.industry.control && filters.industry.items.length > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="filters.industry.control"
      />
      <a class="clear-search" *ngIf="filters.industry.control.value" (click)="filters.industry.control.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    </div>
    <hr />
    <a mat-menu-item (click)="setItem('industry')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filters.industry.items.length > minMenuScrollItems }">
      <a
        mat-menu-item
        *ngFor="let item of filters.industry.filteredItems | async"
        [title]="item.title"
        (click)="setItem('industry', item)"
        [ngClass]="{ active: searchOptions.filters.industry?.id === item.id }"
      >
        <span>{{ item.title | titlecase }}</span>
      </a>
    </div>
  </ng-template>
</mat-menu>

<!-- Layout -->

<div class="search-wrapper">
  <div class="search-keywords" [ngClass]="{ active: searchOptions.query.keywords }">
    <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
    <input
      type="text"
      placeholder="{{ 'Search' | translate }}"
      [(ngModel)]="searchOptions.query.keywords"
      [formControl]="inputControl"
      (keyup.enter)="search()"
    />
    <a *ngIf="searchOptions.query.keywords" class="clear-search" (click)="clearSearch()">
      <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
    </a>
  </div>
  <div class="search-options">
    <div class="divider divider-vertical"></div>
    <ng-container>
      <a
        class="btn btn-filter mr-1"
        title="{{ 'Filter' | translate }}"
        [ngClass]="{ active: filterCount }"
        [matMenuTriggerFor]="filterMenu"
      >
        <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
        <span>
          {{ "Filter" | translate }}
          <ng-container *ngIf="filterCount"> ({{ filterCount }}) </ng-container>
        </span>
      </a>
    </ng-container>
    <!-- <div class="search-filters">
      <ng-container *ngIf="(searchOptions.filters | json) != '{}'">
        <a class="btn btn-warn" title="{{ 'Reset' | translate }}" (click)="resetFilters()">
          <mat-icon class="icon-sm" svgIcon="frequency"></mat-icon>
          <span>{{ "Reset" | translate }}</span>
        </a>
        <div class="divider divider-vertical"></div>
      </ng-container>
      <a *ngIf="category === 'third_party'" [ngClass]="{'active': searchOptions.filters.framework}"
        class="btn btn-filter" title="{{ 'Frameworks' | translate }}" [matMenuTriggerFor]="frameworkMenu">
        <mat-icon class="icon-sm" svgIcon="table"></mat-icon>
        <span *ngIf="!searchOptions.filters.framework">{{ "Framework" | translate }}</span>
        <span *ngIf="searchOptions.filters.framework">
          {{ searchOptions.filters.framework.title }}
        </span>
      </a>
      <a [ngClass]="{'active': searchOptions.filters.topic}"
        class="btn btn-filter" title="{{ 'Topics' | translate }}" [matMenuTriggerFor]="topicMenu">
        <mat-icon class="icon-sm" svgIcon="category"></mat-icon>
        <span *ngIf="!searchOptions.filters.topic">{{ "Topic" | translate }}</span>
        <span *ngIf="searchOptions.filters.topic">
          {{ searchOptions.filters.topic.title }}
        </span>
      </a>
      <a [ngClass]="{'active': searchOptions.filters.industry}"
        class="btn btn-filter" title="{{ 'Industries' | translate }}" [matMenuTriggerFor]="industryMenu">
        <mat-icon class="icon-sm" svgIcon="industry"></mat-icon>
        <span *ngIf="!searchOptions.filters.industry">{{ "Industry" | translate }}</span>
        <span *ngIf="searchOptions.filters.industry">
          {{ searchOptions.filters.industry.title }}
        </span>
      </a>
    </div> -->
  </div>
</div>
