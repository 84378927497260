import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-info-notice',
  templateUrl: './info-notice.component.html',
  styleUrls: ['./info-notice.component.scss'],
})
export class InfoNoticeComponent {
  @Input({ required: true }) icon!: string;
  @Input({ required: true }) primaryMessage!: string;
  @Input() secondaryMessage?: string;
  @Input() type: 'warning' | 'info' | 'error' = 'info';
}
