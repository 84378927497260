import { Pipe, PipeTransform } from '@angular/core';

import cloneDeep from 'lodash/cloneDeep';
import assignIn from 'lodash/assignIn';

@Pipe({ name: 'objectAssign' })
export class ObjectAssignPipe implements PipeTransform {
  transform(mutableObject: object, assignObject?: object): object {
    const clone = cloneDeep(mutableObject);
    return assignIn(clone, assignObject);
  }
}
