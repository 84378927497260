<mat-checkbox
  color="primary"
  [disabled]="disabled"
  [checked]="defaultValue"
  [value]="label"
  (change)="onCheckboxToggle($event)"
  class="mat-body-1"
  (click)="$event.stopPropagation()"
>
  {{ label }}
</mat-checkbox>
