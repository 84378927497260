import { Pipe, PipeTransform } from '@angular/core';
import { Metric } from '../../../models';
import { MetricStructureSelectable } from '../../models';
import { MetricStructureUtils } from '../../classes/MetricStructureUtils/metric-structure-utils';

@Pipe({
  name: 'isEntityFromCoreRefV2Metric',
})
export class IsEntityFromCoreRefV2MetricPipe implements PipeTransform {
  transform(metricStructureSelectable?: MetricStructureSelectable, metric?: Metric): boolean {
    return MetricStructureUtils.isEntityFromCoreRefV2Metric(metricStructureSelectable, metric);
  }
}
