import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export { CommonCategories, CommonTypes };

const CommonCategories = {
  'Academic research': _('Academic research'),
  'Annual report': _('Annual report'),
  Article: _('Article'),
  Code: _('Code'),
  'Credit rating': _('Credit rating'),
  'Customer questionnaire': _('Customer questionnaire'),
  'Data table': _('Data table'),
  'Engagement activity report': _('Engagement activity report'),
  'External assurance': _('External assurance'),
  Framework: _('Framework'),
  Guidelines: _('Guidelines'),
  'Investor deck': _('Investor deck'),
  Laws: _('Laws'),
  Minutes: _('Minutes'),
  Podcast: _('Podcast'),
  Policy: _('Policy'),
  Presentation: _('Presentation'),
  'Proxy voting report': _('Proxy voting report'),
  Regulation: _('Regulation'),
  'Regulatory filing': _('Regulatory filing'),
  'Release or letter': _('Release or letter'),
  'Responsible investment report': _('Responsible investment report'),
  RFP: _('RFP'),
  Standard: _('Standard'),
  'Sustainability report': _('Sustainability report'),
  'Third-party questionnaire': _('Third-party questionnaire'),
  'Third-party report': _('Third-party report'),
  Video: _('Video'),
  Webinar: _('Webinar'),
  'White paper': _('White paper'),
  Other: _('Other'),
};

const CommonTypes = {
  'Annual Information Form': _('Annual Information Form'),
  'B-Corp': _('B-Corp'),
  'Bloomberg Gender Equality Index': _('Bloomberg Gender Equality Index'),
  CDP: _('CDP'),
  'Code of conduct': _('Code of Conduct'),
  'Code of ethics': _('Code of Ethics'),
  'Corporate Knights': _('Corporate Knights'),
  DBRS: _('DBRS'),
  EcoVadis: _('EcoVadis'),
  Equileap: _('Equileap'),
  ESRS: _('ESRS'),
  Factset: _('Factset'),
  'Financial statements - Balance sheet': _('Financial statements - Balance sheet'),
  'Financial statements - Cash flow statement': _('Financial statements - Cash flow statement'),
  'Financial statements - Income statement': _('Financial statements - Income statement'),
  'Financial statements - Other': _('Financial statements - Other'),
  Fitch: _('Fitch'),
  'Form 8-K': _('Form 8-K'),
  'Form 10-K, 10-Q': _('Form 10-K, 10-Q'),
  'Form 20-F, 40-F': _('Form 20-F, 40-F'),
  FTSE: _('FTSE'),
  GRESB: _('GRESB'),
  GRI: _('GRI'),
  HKSE: _('HKSE'),
  IFRS: _('IFRS'),
  'IFRS-SDS': _('IFRS-SDS'),
  IIRC: _('IIRC'),
  ISO: _('ISO'),
  ISS: _('ISS'),
  'Management Information Circular': _('Management Information Circular'),
  'MD&A or Management Commentary': _('MD&A or Management Commentary'),
  "Moody's": _("Moody's"),
  MSCI: _('MSCI'),
  PRI: _('PRI'),
  'Proxy Circular': _('Proxy Circular'),
  PSI: _('PSI'),
  'Public Accountability Statement': _('Public Accountability Statement'),
  Refinitiv: _('Refinitiv'),
  'S&P Global': _('S&P Global'),
  SASB: _('SASB'),
  SDG: _('SDG'),
  Sustainalytics: _('Sustainalytics'),
  TCFD: _('TCFD'),
  TNFD: _('TNFD'),
  Trucost: _('Trucost'),
  'Truvalue Labs': _('Truvalue Labs'),
  'UN Global Compact': _('UN Global Compact'),
  'World Economic Forum': _('World Economic Forum'),
};
