import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { StepperStep } from '../../../models';

@Component({
  selector: 'lib-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input() selectedIndex = 0;
  @Input() steps: (StepperStep | null)[] = [];

  @Output() selectedIndexChange = new EventEmitter<number>();

  public selectionChange(event: StepperSelectionEvent): void {
    this.selectedIndexChange.emit(event.selectedIndex);
  }
}
