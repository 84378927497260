import { Component, Input, OnChanges } from '@angular/core';
import {
  DialogResult,
  Metric,
  MetricTableCalculationDefinition,
  MetricTableColumnDefinition,
  MetricTableDefinition,
  MoveDirection,
  Status,
  ValueDefinitionType,
} from '../../../../../models';
import { MetricStructureStateService } from '../../../../services/metric-structure-state.service';
import { Observable, map, of, takeWhile, finalize } from 'rxjs';
import {
  MetricTableTotalData,
  MetricTableTotalDialogComponent,
} from '../metric-table-total-dialog/metric-table-total-dialog.component';
import { DialogsService } from '../../../../../dialogs';
import { MetricTableForm } from '../metric-table-form';
import { MetricTableGroup } from '../../../../models';
import { FeatureFlagService } from '../../../../../feature-flag';

@Component({
  selector: 'lib-metric-table-total-tab',
  templateUrl: './metric-table-total-tab.component.html',
  styleUrls: ['./metric-table-total-tab.component.scss'],
})
export class MetricTableTotalTabComponent implements OnChanges {
  metricTableDefinition?: MetricTableDefinition;
  @Input({ required: true }) metricTableGroup!: MetricTableGroup;
  @Input({ required: true }) metric!: Metric;

  isUpdating$: Observable<boolean> = of(false);
  tableTotalForm?: MetricTableForm;
  calculationDefinitions: MetricTableCalculationDefinition[] = [];
  metricTableContextColumns: MetricTableColumnDefinition[] = [];
  metricTableInputColumns: MetricTableColumnDefinition[] = [];
  isAdmin: boolean = false;

  constructor(
    private metricStructureStateService: MetricStructureStateService,
    private dialogsService: DialogsService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.isUpdating$ = this.metricStructureStateService.isMetricUpdating$;
    this.isAdmin = this.metricStructureStateService.isAdmin;
  }

  ngOnChanges(): void {
    this.tableTotalForm = new MetricTableForm(this.metricTableGroup, this.metric, this.isAdmin);
    if (
      this.featureFlagService.areAnyFeatureFlagsEnabled(['metric_structure_deactivation_enabled']) &&
      !this.metricTableGroup.active
    ) {
      this.tableTotalForm.disable();
    }
    this._fetchMetricTableDefinition();
  }

  public openTableTotalDialog(tableTotal?: MetricTableCalculationDefinition): void {
    const tableTotalPosition = tableTotal?.position ?? this.calculationDefinitions.length + 1;
    this.dialogsService
      .open<MetricTableTotalDialogComponent, MetricTableTotalData, DialogResult<MetricTableCalculationDefinition>>(
        MetricTableTotalDialogComponent,
        {
          data: {
            position: tableTotalPosition,
            model: tableTotal,
            metricTableInputColumns: this.metricTableInputColumns,
            metricTableContextColumns: this.metricTableContextColumns,
          },
        },
      )
      .afterClosed()
      .pipe(
        takeWhile((result) => result?.status === Status.SUCCESS),
        map((result) => result?.data),
      )
      .subscribe((totalCalculationDefinition) => {
        if (!tableTotal) {
          this.createTableTotal(totalCalculationDefinition);
        } else {
          if (totalCalculationDefinition) {
            const tableTotalToUpdate: MetricTableCalculationDefinition = this.calculationDefinitions.find(
              (calc_def) => calc_def.position === tableTotalPosition,
            ) as MetricTableCalculationDefinition;
            tableTotalToUpdate.label = totalCalculationDefinition.label;
            tableTotalToUpdate.type_details = totalCalculationDefinition.type_details;
            totalCalculationDefinition.id = tableTotal.id;
            this.updateTableTotal(totalCalculationDefinition);
          }
        }
        this.tableTotalForm?.markAsDirty();
      });
  }

  public createTableTotal(tableTotal?: MetricTableCalculationDefinition): void {
    if (tableTotal) {
      this.metricStructureStateService.updateIsMetricUpdating(true);
      this.metricStructureStateService
        .createMetricTableTotal(this.metricTableGroup.metric_id, this.metricTableGroup.table_id, tableTotal)
        .pipe(finalize(() => this.metricStructureStateService.updateIsMetricUpdating(false)))
        .subscribe(() => {
          this.metricStructureStateService.updateIsMetricUpdating(false);
          this.calculationDefinitions.push(tableTotal);
          this._fetchMetricTableDefinition();
        });
    }
  }

  public updateTableTotal(tableTotal?: MetricTableCalculationDefinition): void {
    this.metricStructureStateService.updateIsMetricUpdating(true);
    this.metricStructureStateService
      .updateMetricTableTotal(
        this.metricTableGroup.metric_id,
        this.metricTableGroup.table_id,
        tableTotal as MetricTableCalculationDefinition,
      )
      .subscribe(() => {
        this.metricStructureStateService.updateIsMetricUpdating(false);
        this._fetchMetricTableDefinition();
      });
  }

  public deleteTableTotal(totalToDelete: MetricTableCalculationDefinition): void {
    if (totalToDelete.id) {
      this.metricStructureStateService.updateIsMetricUpdating(true);
      this.metricStructureStateService
        .deleteMetricTableTotal(this.metricTableGroup.metric_id, this.metricTableGroup.table_id, totalToDelete.id)
        .pipe(finalize(() => this.metricStructureStateService.updateIsMetricUpdating(false)))
        .subscribe(() => {
          this.metricStructureStateService.updateIsMetricUpdating(false);
          this._fetchMetricTableDefinition();
        });
    }
    this.tableTotalForm?.markAsDirty();
  }

  public moveTableTotalCard(card: { tableTotal: MetricTableCalculationDefinition; direction: MoveDirection }) {
    const newPosition =
      card.direction === MoveDirection.up ? card.tableTotal.position - 1 : card.tableTotal.position + 1;

    if (card.tableTotal.id) {
      this.metricStructureStateService.updateIsMetricUpdating(true);
      this.metricStructureStateService
        .moveMetricTableTotal(
          this.metricTableGroup.metric_id,
          this.metricTableGroup.table_id,
          card.tableTotal.id,
          newPosition,
        )
        .pipe(finalize(() => this.metricStructureStateService.updateIsMetricUpdating(false)))
        .subscribe(() => {
          this.metricStructureStateService.updateIsMetricUpdating(false);
          this._fetchMetricTableDefinition();
        });
    }

    this.tableTotalForm?.markAsDirty();
  }

  private _fetchMetricTableDefinition(): void {
    this.metricStructureStateService
      .getMetricTableDefinition(this.metricTableGroup.metric_id, this.metricTableGroup.id)
      .subscribe((metricTableDefinition) => {
        this.metricTableDefinition = metricTableDefinition;
        this.calculationDefinitions = metricTableDefinition.calculation_definitions;
        this.metricTableContextColumns = this.metricTableDefinition.column_definitions.filter(
          (column) => column.type === ValueDefinitionType.label,
        );

        this.metricTableInputColumns = this.metricTableDefinition.column_definitions.filter(
          (column) => column.type !== ValueDefinitionType.label,
        );
      });
  }
}
