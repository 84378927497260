import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [],
  imports: [A11yModule, CdkTableModule, DragDropModule, PortalModule, ScrollingModule],
  exports: [A11yModule, CdkTableModule, DragDropModule, PortalModule, ScrollingModule],
})
export class CdkModule {}
