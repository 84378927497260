import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AssociateTaxonomiesRequest } from '../../../models';

type AddTaxonomiesDialogFormModel = {
  taxonomies: FormControl<string[]>;
};

export class AddTaxonomiesDialogForm extends FormGroup<AddTaxonomiesDialogFormModel> {
  constructor(fb: FormBuilder = new FormBuilder()) {
    super({ taxonomies: fb.control([], { nonNullable: true, validators: [Validators.required] }) });
  }

  public toModel(): AssociateTaxonomiesRequest {
    return { taxonomies: this.controls.taxonomies.value };
  }
}
