import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagService } from './feature-flag.service';
import { FeatureFlagKeys } from './models';

interface FeatureFlagConfig {
  featureFlags?: FeatureFlagKeys | FeatureFlagKeys[];
  hiddenWhenEnabled?: boolean;
}

@Directive({ selector: '[libFeatureFlag]' })
export class FeatureFlagDirective implements OnInit {
  @Input('libFeatureFlag') libFeatureFlagConfig?: FeatureFlagConfig;
  @Input() libFeatureFlagElse?: TemplateRef<unknown>;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit() {
    this.featureFlagService.areFeatureFlagsLoaded$().subscribe((isReady: boolean) => {
      if (isReady) {
        this.updateView();
      }
    });
  }

  private updateView() {
    this.viewContainerRef.clear();
    if (this.isEnabled()) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.libFeatureFlagElse) {
      this.viewContainerRef.createEmbeddedView(this.libFeatureFlagElse);
    }
  }

  private isEnabled() {
    const featureFlags = this.libFeatureFlagConfig?.featureFlags;

    if (featureFlags) {
      const featureFlagEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled(
        Array.isArray(featureFlags) ? featureFlags : [featureFlags],
      );
      return this.libFeatureFlagConfig?.hiddenWhenEnabled ? !featureFlagEnabled : featureFlagEnabled;
    }
    return true;
  }
}
