import { Pipe, PipeTransform } from '@angular/core';
import { DashboardWidget, DashboardWidgetType } from '../../models';
import { GridsterItem } from 'angular-gridster2';

@Pipe({
  name: 'dashboardWidgetToGridsterItem',
})
export class DashboardWidgetToGridsterItemPipe implements PipeTransform {
  transform(dashboardWidget: DashboardWidget): GridsterItem {
    return {
      cols: dashboardWidget.width,
      rows: dashboardWidget.height,
      y: dashboardWidget.row_location,
      x: dashboardWidget.column_location,
      resizeEnabled: dashboardWidget.widget_type === DashboardWidgetType.DATA_POINT_WIDGET ? false : true,
      id: dashboardWidget.id,
    };
  }
}
