import { Pipe, PipeTransform } from '@angular/core';
import { ValueFormControl } from '../../../models/valueFormControl';
import { Frequency } from '../../../../models';

@Pipe({
  name: 'frequencyEnabled',
})
export class FrequencyEnabledPipe implements PipeTransform {
  transform(disableFrequencyFields: boolean, valueFormControl: ValueFormControl): boolean {
    return !!(
      !disableFrequencyFields &&
      valueFormControl.valueRef.value_definition_frequency &&
      valueFormControl.valueRef.value_definition_frequency.frequency != Frequency.YEARLY
    );
  }
}
