import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DocumentType } from '../../documents';

export { DocsDocStatus, DocsDocStatusFilter, MetricDataExportTypes };

const DocsDocStatus = {
  public: _('Public Document'),
  private: _('Internal Document'),
};

const DocsDocStatusFilter = {
  true: DocsDocStatus.public,
  false: DocsDocStatus.private,
};

const MetricDataExportTypes = {
  'Request Data Export': _('Request Data Export'),
  'Metric Data Export': _('Metric Data Export'),
  [DocumentType.sp_xml]: _('S&P XML'),
  [DocumentType.cdp_json]: _('CDP JSON'),
  [DocumentType.custom_template_export]: _('Custom Template Export'),
  [DocumentType.framework_template_export]: _('Framework Template Export'),
  [DocumentType.questionnaire_export]: _('Questionnaire Export'),
  [DocumentType.benchmark_v2_export]: _('Benchmark Export'),
  [DocumentType.source_comparison_v1_export]: _('Source Comparison Export'),
  [DocumentType.data_table_v2_export]: _('Fiscal Year Comparison Export'),
};
