import { Component, Input, OnChanges, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { ActionItem, ValueDefinitionSize } from '../../../models';
import { Dropdown } from 'primeng/dropdown';
import { ValidationMessageService } from '../../../services/common';

let nextId = 0;

@Component({
  selector: 'lib-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
})
export class DropdownInputComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @Input() control?: UntypedFormControl;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() hint: string = '';
  @Input() options: T[] = [];
  @Input() label: string = '';
  @Input() tooltip: string = '';
  @Input() filter: boolean = false;
  @Input() filterBy?: string;
  @Input() showClear: boolean = true;
  @Input() autoDisplayFirst: boolean = false;
  @Input() optionLabel?: string;
  @Input() optionValue?: string;
  @Input() optionDisabled?: string;
  @Input() group: boolean = false;
  @Input() optionGroupLabel?: string;
  @Input() optionGroupChildren: string = '';
  @Input() messages: ValidationErrors = {};
  @Input() labelPosition: 'top' | 'left' = 'top';

  @ViewChild('focusElement') focusElement!: Dropdown;
  @ViewChild('dropdownSelect') dropdownSelect!: Dropdown;

  readonly _labelId: string = `dropdown-input-${nextId++}`;

  public required: boolean = false;
  public errorMessages: ValidationErrors = {};
  inputWidth?: string;

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnInit(): void {
    if (this.isActionItem(this.options[0])) {
      this.optionLabel = 'title';
      this.optionValue = 'id';
      this.optionDisabled = 'deactivated';

      if (this.group) {
        this.optionGroupLabel = 'title';
        this.optionGroupChildren = 'children';
      }
    }
  }

  ngOnChanges(): void {
    this.initializeInput();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.dropdownSelect) {
        this.inputWidth = `${this.dropdownSelect.el.nativeElement.offsetWidth}px`;
      }
    });
  }

  public setFocus(): void {
    this.focusElement.focus();
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  private isActionItem(obj: any): obj is ActionItem {
    return typeof obj === 'object' && obj !== null && typeof obj.id === 'string' && typeof obj.title === 'string';
  }
}
