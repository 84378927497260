import { Component, Input, Optional } from '@angular/core';

import { Metric, MetricCategory, RelatedMetric, RelatedMetricSource } from '../../../../../models';

@Component({
  selector: 'lib-metric-settings-details',
  templateUrl: './metric-settings-details.component.html',
  styleUrls: ['./metric-settings-details.component.scss'],
})
export class MetricSettingsDetailsComponent {
  @Input({ required: true }) metric!: Metric;
  @Input() @Optional() refMetricsV2EnabledFF: boolean = false;

  public readonly eRelatedMetricSource = RelatedMetricSource;
  public readonly eMetricCategory = MetricCategory;

  public filterRelatedMetrics = (relatedMetric: RelatedMetric): boolean =>
    relatedMetric.original_metric_ids.includes(this.metric.id);
}
