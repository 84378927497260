<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <header>
      <a mat-menu-item disabled>{{ optionProps?.title }}</a>
    </header>

    <hr />

    <mat-checkbox
      *ngFor="let option of optionProps?.options"
      class="mat-menu-item checkbox-override checkbox-color w-100"
      [(ngModel)]="option.selected"
      (click)="$event.stopPropagation()"
      (change)="onValueSelect()"
    >
      {{ option.title }}
    </mat-checkbox>
  </ng-template>
</mat-menu>

<div
  class="btn btn-filter filter-container"
  [matMenuTriggerFor]="menu"
  [ngClass]="[buttonProps?.disabled ? 'btn-disabled disabled' : '', buttonProps?.active ? 'active' : '']"
  (menuClosed)="onMenuClosed()"
  data-testid="filter-button-container"
>
  <span *ngIf="buttonProps && buttonProps.icon">
    <mat-icon class="icon-sm mr-1" [svgIcon]="buttonProps.icon"></mat-icon>
  </span>
  <div class="mat-body-1">{{ "Filtered by:" | translate }} {{ buttonTitle }}</div>
</div>
