<lib-search
  *ngIf="searchFilters$ | async as searchFilters"
  [layout]="eLayout.list"
  [presentation]="ePresentation.card"
  [itemType]="itemTypeAdminTemplatesMetrics"
  mode="drag"
  [sortOrder]="sortOrder"
  [filters]="searchFilters"
  [showActions]="true"
  (action)="handleSearchAction($event)"
>
</lib-search>
