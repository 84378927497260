import {
  DueDateNotificationRelativeToType,
  DueDateNotificationSchedule,
  DueDateNotificationTimeframeType,
} from '../../../models';

export const EVERY_DAY = -1;

export const ONE_DAY_AFTER: DueDateNotificationSchedule = {
  frequency: 1,
  timeframe: DueDateNotificationTimeframeType.CALENDAR_DAYS,
  relative_to: DueDateNotificationRelativeToType.AFTER,
};

export const ONE_DAY_BEFORE: DueDateNotificationSchedule = {
  frequency: 1,
  timeframe: DueDateNotificationTimeframeType.CALENDAR_DAYS,
  relative_to: DueDateNotificationRelativeToType.BEFORE,
};

export const ONE_WEEK_BEFORE: DueDateNotificationSchedule = {
  frequency: 1,
  timeframe: DueDateNotificationTimeframeType.WEEKS,
  relative_to: DueDateNotificationRelativeToType.BEFORE,
};

export const ON_DUE_DATE: DueDateNotificationSchedule = {
  frequency: 0,
  timeframe: DueDateNotificationTimeframeType.CALENDAR_DAYS,
  relative_to: DueDateNotificationRelativeToType.ON_DUE_DATE,
};

export const EVERY_DAY_PAST_DUE_DATE: DueDateNotificationSchedule = {
  frequency: EVERY_DAY,
  timeframe: DueDateNotificationTimeframeType.CALENDAR_DAYS,
  relative_to: DueDateNotificationRelativeToType.AFTER,
};

export const DEFAULT_SCHEDULE: DueDateNotificationSchedule = {
  frequency: 1,
  timeframe: DueDateNotificationTimeframeType.WEEKS,
  relative_to: DueDateNotificationRelativeToType.BEFORE,
};
