<lib-filter-bar-menu
  [control]="sourceFormControl"
  [minMenuScrollItems]="minMenuScrollItems"
  [initialItems]="sources"
  [filteredItems]="filteredSources$ | async"
  [selectedItemId]="selectedSource?.id"
  [menuTitle]="'Source' | translate"
  [categories]="categories"
  [filterItemsFunction]="filterItemsFunction"
  [allOptionItem]="allSourcesItem"
  (setItem)="setSource($event)"
>
</lib-filter-bar-menu>
