import { Metric, ValueDefinition, ValueDefinitionGroup } from '../../models';
import { ValueDefinitionDisplayType, ValueDefinitionSize } from '../../models';

export enum ValueDefinitionTemplateType {
  category = 'category',
  template = 'template',
  group = 'group',
  table = 'table',
}

export interface ValueDefinitionFormat {
  type: ValueDefinitionDisplayType;
  size: ValueDefinitionSize;
  readonly: boolean;
}

export interface MetricTableGroup {
  id: string;
  title: string;
  table_id: string;
  metric_id: string;
  position: number;
  active: boolean;
  end_fiscal_year_frequency_code?: string;
  core_metric_table_definition_id?: string;
  valueDefinitionGroups: ValueDefinitionGroup[];
  published?: string;
  published_by?: string;
  ignore_taxonomies?: boolean | null;
  hidden_by_taxonomy?: boolean | null;
}

export type MetricStructureSelectable = (Metric | ValueDefinitionGroup | MetricTableGroup | ValueDefinition) & {
  selectedTab?: number;
};

export enum MetricStructureSelectableType {
  metric = 'metric',
  group = 'group',
  table = 'table',
  valueDefinition = 'valueDefinition',
}

export type ValueDefinitionGroupOrMetricTableGroup = ValueDefinitionGroup | MetricTableGroup;

export interface DefinitionValidationError {
  business_unit_id: string;
  frequency_code: string;
  indicator_id: string;
  value?: string;
  value_definition_group_id: string;
  value_definition_id?: string;
  contradicted_validation: string;
}

export enum ColumnSelection {
  INPUT = 'input',
  CONTEXT_COLUMN = 'label',
}
