import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { SortingTranslation } from '../sorting.translation';

export const BenchmarkSort = {
  updated: SortingTranslation.last_updated,
  name: SortingTranslation.alphabetical,
};

export const BenchmarkTypeFilter = {
  reference: _('Reference metrics'),
  third_party: _('Questionnaire'),
};
