<lib-dialog-title
  [title]="title"
  (closeEvent)="close()"
  [error]="error"
  [isOfflineDialog]="isOfflineDialog"
></lib-dialog-title>

<div mat-dialog-content class="pb-1">
  <div class="warning-message-container">
    <div *ngIf="!isOfflineDialog" class="warning-icon-container">
      <mat-icon class="warning-icon" svgIcon="warning"></mat-icon>
    </div>
    <div class="warning-message" *ngIf="body" [innerHTML]="body | sanitizeHtml"></div>
  </div>

  <div *ngIf="error" [innerHTML]="error | sanitizeHtml"></div>
</div>

<lib-dialog-info-actions (confirmEvent)="confirm()"></lib-dialog-info-actions>
