import { Injectable } from '@angular/core';
import { UpsertValueGroupSet } from '../../../metric-editor-form';
import { ConditionalTrigger, Value, ValueGroup } from '../../../models';

@Injectable()
export class MetricPreviewConditionalTriggerService {
  public handleConditionalTrigger(valueGroups: ValueGroup[], updateVGSEvent: UpsertValueGroupSet): ValueGroup[] {
    const conditionalTriggerGroups = valueGroups.filter((grp) => grp.conditional_triggers?.length);
    conditionalTriggerGroups.forEach((group) => {
      if (this.checkIfCTGroupShouldBeCleared(group, updateVGSEvent)) {
        group.values = this.switchToClearedValues(group.values ?? []);
      }
    });

    valueGroups
      .filter((grp) => grp.values?.some((val) => val.conditional_triggers?.length))
      .map((grp) => this.handleClearingCTValuesWithinAGroup(grp, updateVGSEvent));

    return valueGroups;
  }

  private handleClearingCTValuesWithinAGroup(group: ValueGroup, updateVGSEvent: UpsertValueGroupSet): ValueGroup {
    const clearedValues = this.switchToClearedValues(this.getValuesToBeCleared(group, updateVGSEvent));
    const unclearedValues =
      group.values?.filter((val) => !clearedValues.find((v) => v.value_definition_id === val.value_definition_id)) ??
      [];
    group.values = [...unclearedValues, ...clearedValues];
    group.values.sort((a, b) => a.position - b.position);
    return group;
  }

  private checkIfCTGroupShouldBeCleared(ctGroup: ValueGroup, updateVGSEvent: UpsertValueGroupSet): boolean {
    return !!ctGroup.conditional_triggers?.some((trigger) =>
      this.isValueChangedAndSourceOptionExist(updateVGSEvent, trigger),
    );
  }

  private getValuesToBeCleared(group: ValueGroup, updateVGSEvent: UpsertValueGroupSet): Value[] {
    return (
      group.values?.filter((value) =>
        value.conditional_triggers?.some((trigger) => this.isValueChangedAndSourceOptionExist(updateVGSEvent, trigger)),
      ) ?? []
    );
  }

  private switchToClearedValues(values: Value[]): Value[] {
    return values.map((val) => ({ ...val, id: '', updated: '', updated_by: '', value: null }));
  }

  private isValueChangedAndSourceOptionExist(
    updateVGSEvent: UpsertValueGroupSet,
    trigger: ConditionalTrigger,
  ): boolean {
    return updateVGSEvent.value_groups.some((vg) =>
      vg.values.some((val) => {
        const sourceValue = Array.isArray(val.value) ? val.value : [val.value];
        if (Array.isArray(trigger.values)) {
          return (
            val.value_definition_id === trigger.source_value_definition_id ||
            (trigger.values as string[]).some((tv) => sourceValue.includes(tv))
          );
        } else {
          // TODO
          return val.value_definition_id === trigger.source_value_definition_id;
        }
      }),
    );
  }
}
