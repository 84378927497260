import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ValueFormControl } from '../../../metric-editor-form/models/valueFormControl';
import { FormUtils } from '../../../classes';
import { isQuantitativeRule } from '../../../models';

@Component({
  selector: 'lib-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.scss'],
})
export class FormFieldLabelComponent implements OnInit, OnChanges {
  @Input({ required: true }) control!: UntypedFormControl | ValueFormControl;
  @Input() id: string = '';
  @Input() for: string = '';
  @Input() label: string = '';
  @Input() displayReset: boolean = true;
  @Input() sameSizeLabels: boolean = false; // When using multiple fields in the same input
  @Input() fieldInfo?: string;
  @Input() isBypassConsolidation: boolean = false;

  required: boolean = false;
  combinedClasses: any;

  constructor() {}

  ngOnInit(): void {
    this.combinedClasses = {
      required: this.required,
      disabled: this.control.disabled,
      'force-same-size-labels': this.sameSizeLabels,
    };
  }

  ngOnChanges(): void {
    this.required = this.control.hasValidator(Validators.required);
  }

  displayDeleteIcon(): boolean {
    if (!this.isValueFormControl(this.control) || !this.displayReset || this.control.disabled) {
      return false;
    }

    const hasValueAndId = this.hasValue() && !FormUtils.isNullOrEmpty(this.control.valueRef.id);

    if (this.control.isConsolidated()) {
      const isConsolidationInactive =
        !isQuantitativeRule(this.control.valueRef.consolidation_rule) || this.isBypassConsolidation;
      return hasValueAndId && isConsolidationInactive;
    }
    return hasValueAndId;
  }

  deleteValue(): void {
    if (this.isValueFormControl(this.control)) {
      this.control.resetValue();
    }
  }

  private hasValue(): boolean {
    const value = this.control.value as unknown;
    return Array.isArray(value) ? value.length > 0 : !!value || value === 0;
  }

  private isValueFormControl(control: UntypedFormControl): control is ValueFormControl {
    return 'valueRef' in control;
  }
}
