import { Pipe, PipeTransform } from '@angular/core';
import { LimitedUser, User, ValueGroupSetStatusTransition, VGSetStatusContext } from '../../models';
import { UpdatedByUserPipe } from '../updated-by/updated-by-user.pipe';
import { TranslateService } from '../../services/common';
import { UpdatedByGHGIntegrationSystemPipe } from '../updated-by/updated-by-ghg-integration-system.pipe';

@Pipe({
  name: 'valueGroupSetStatusTransitionUser',
})
export class ValueGroupSetStatusTransitionUserPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly updatedByUserPipe: UpdatedByUserPipe,
  ) {}

  transform(statusTransition: ValueGroupSetStatusTransition, users: (LimitedUser | User)[]): string {
    if (
      !statusTransition.created_by &&
      statusTransition.to_status_context &&
      [
        VGSetStatusContext.imported_from_green_project,
        VGSetStatusContext.some_values_entered_via_green_project_api,
        VGSetStatusContext.imported_from_minimum,
        VGSetStatusContext.some_values_entered_via_minimum_api,
      ].includes(statusTransition.to_status_context)
    ) {
      return this.translateService.instant(UpdatedByGHGIntegrationSystemPipe.CARBON_MANAGEMENT_AUTOMATION_USER_NAME);
    } else {
      return this.updatedByUserPipe.transform(statusTransition.created_by, users);
    }
  }
}
