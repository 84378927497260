import { Injectable } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { TranslateService as BaseTranslateService } from '@ngx-translate/core';

import { ActionItem } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class TranslateService extends BaseTranslateService {
  listResources(keyValue: Record<string, string>, omit: string[] = []): ActionItem[] {
    return Object.entries(keyValue)
      .filter((entry) => !omit.includes(entry[0]))
      .map(([key, value]) => ({ id: key, title: this.instant(value) }));
  }

  instant(key: string, interpolateParams?: Record<string, unknown>): string {
    return super.instant(key, interpolateParams) as string;
  }
}
