<div *ngFor="let section of sections; trackBy: trackById; let first = first; let last = last">
  <div class="card card-noborders bkg-grey-50 section-card" role="row" (click)="selectSection(section)">
    <div class="fx-row align-center w-100" [ngClass]="{ active: section.isExpanded }">
      <mat-icon [svgIcon]="section.isExpanded ? 'arrow-head-down' : 'arrow-head-right'" class="icon-sm"></mat-icon>

      <div class="w-100 ml-1">
        <div class="color-grey-800 section-code">
          {{ section.code }}
        </div>
        <div class="color-primary-900">
          {{ section.label }}
        </div>
      </div>

      <button class="card-menu context-menu-button" (click)="onSectionContextMenu($event, section, first, last)">
        <mat-icon svgIcon="more" class="icon-md"></mat-icon>
      </button>
    </div>
  </div>

  <div class="expanded-section" *ngIf="section.isExpanded">
    <div
      *ngIf="section.metrics?.length; else emptyMetrics"
      cdkDropList
      id="itemList"
      (cdkDropListDropped)="dropMetric($event, section)"
    >
      <lib-template-report-structure-section-metric
        *ngFor="let metric of section.metrics; index as i; trackBy: trackById; let first = first; let last = last"
        [managePermissions]="managePermissions"
        [metric]="metric"
        [position]="i + 1"
        [isFirst]="first"
        [isLast]="last"
        [templateReportId]="templateReportId"
        [templateReportSectionId]="section.id"
        [templateReportVersionId]="templateReportVersionId"
        (editMetric)="editMetric.emit({ metric: metric, section: section })"
        (previewMetric)="previewMetric.emit(metric)"
      ></lib-template-report-structure-section-metric>
    </div>

    <ng-template #emptyMetrics>
      <div
        class="card card-dashed section-metrics"
        cdkDropList
        id="itemList"
        data-testid="empty-metrics"
        (cdkDropListDropped)="dropMetric($event, section)"
      >
        <lib-empty-results class="h-100" [displayData]="noMetrics"></lib-empty-results>
      </div>
    </ng-template>

    <lib-template-report-structure-sections
      [sections]="section.children!"
      [templateReport]="templateReport"
      [templateReportId]="templateReportId"
      [templateReportVersionId]="templateReportVersionId"
      (editSection)="editSection.emit($event)"
      (addSubSection)="addSubSection.emit($event)"
      (createMetric)="createMetric.emit($event)"
      (editMetric)="editMetric.emit($event)"
      (previewMetric)="previewMetric.emit($event)"
    ></lib-template-report-structure-sections>
  </div>
</div>

<div
  class="context-menu-trigger"
  [style.left]="menuPosition.x"
  [style.top]="menuPosition.y"
  #sectionTrigger="matMenuTrigger"
  [matMenuTriggerFor]="sectionMenu"
></div>
<mat-menu #sectionMenu="matMenu">
  <ng-template matMenuContent let-item="item" let-isFirst="isFirst" let-isLast="isLast">
    <button mat-menu-item (click)="editSection.emit(item)">
      <mat-icon class="icon-sm" svgIcon="edit"></mat-icon>
      <span>{{ "Edit section" | translate }}</span>
    </button>

    <ng-container *libPermission="managePermissions">
      <button mat-menu-item (click)="addSubSection.emit(item)">
        <mat-icon class="icon-sm" svgIcon="group"></mat-icon>
        <span>{{ "Add sub-section" | translate }}</span>
      </button>

      <lib-divider></lib-divider>

      <button mat-menu-item (click)="moveSectionUp(item)" *ngIf="!isFirst">
        <mat-icon class="icon-sm" svgIcon="arrow-full-up"></mat-icon>
        <span>{{ "Move up" | translate }}</span>
      </button>

      <button mat-menu-item (click)="moveSectionDown(item)" *ngIf="!isLast">
        <mat-icon class="icon-sm" svgIcon="arrow-full-down"></mat-icon>
        <span>{{ "Move down" | translate }}</span>
      </button>

      <lib-divider></lib-divider>

      <button mat-menu-item class="color-error" (click)="onDeleteSection(item)">
        <mat-icon svgIcon="trash" class="icon-sm"></mat-icon>
        <span>{{ "Delete" | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
