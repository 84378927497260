import { Status } from './ui';
import { Tag } from './entities';

export interface TagCannotBeDeletedDialogConfig {
  tag: Tag;
}

export interface ConfirmationDialogConfig {
  body?: any;
  title?: string;
  removeTitleMargin?: boolean;
  warningMsg?: string;
  primaryBtn?: string;
  status?: 'success' | 'error';
  checked?: boolean;
  type?: ConfirmationDialogType;
  allowAction?: boolean;
}

export interface ConfirmedItem {
  content: string;
  checked: boolean;
  item: any;
}

export interface DialogResult<T = any> {
  status: Status;
  data?: T;
}

export enum DialogSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  full = 'full',
  fullscreen = 'fullscreen',
  auto = 'auto',
}

export enum ConfirmationDialogType {
  delete = 'delete',
  discard = 'discard',
}
