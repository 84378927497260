import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionItem, ResourceType, Source, SOURCE_CONFIGURATION } from '../../../models';
import { ButtonProps, OptionProps } from '../../models';
import { FilterService } from '../../../services/common';

@Component({
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Input() optionProps?: OptionProps;
  @Input() buttonProps?: ButtonProps;
  @Input() sourceConfiguration?: SOURCE_CONFIGURATION;

  @Output() optionSelected = new EventEmitter<ActionItem>();

  sortResourceType = ResourceType.sort;
  readonly allSourcesItem: ActionItem<Source> = this.filterService.filterListDefaultValue;

  getDefaultValueTitle(): string {
    return this.optionProps?.defaultValueTitle ?? this.optionProps?.defaultValue?.title ?? '';
  }

  getButtonTitle(): string {
    return this.buttonProps?.title ?? this.optionProps?.activeOption?.title ?? '';
  }

  constructor(private readonly filterService: FilterService) {}
}
