<a
  class="card presentation-doc no-highlight"
  (click)="selectItem(item)"
  role="button"
  [attr.aria-label]="'card-' + item.id"
>
  <div class="card-content">
    <div class="pr-2 doc-content-text-wrapper">
      <div [title]="item.title" class="caption2 color-grey-800 mb-1 line-clamp" *ngIf="item.subtitle">
        {{ item.subtitle }}
      </div>
      <div [title]="item.title" class="mat-body-2 color-primary-900 text-clamp text-clamp-3 break-word">
        {{ item.title }}
      </div>
    </div>
    <div class="file-icon" [ngClass]="'file-' + item.icon">
      <mat-icon data-testid="doc-icon" [svgIcon]="item.icon || ''"></mat-icon>
    </div>
  </div>
  <div class="card-footer">
    <div [title]="item.text" class="caption2 color-grey-800 line-clamp card-footer-default">
      {{
        item.updated
          ? ("Updated {timeAgo}" | translate: { timeAgo: item.updated | timeAgo })
          : ("Never updated" | translate)
      }}
      {{ item.updated_by }}
    </div>
    <div [title]="item.text" class="caption2 color-grey-800 line-clamp card-footer-hover">
      {{ (item.updated | date: "medium") || "Never updated" }}
    </div>
    <div [title]="item.text" class="caption2 color-grey-800 line-clamp card-footer-active">
      {{ (item.updated | date: "medium") || "Never updated" }}
    </div>
  </div>
</a>
