import { MESSAGE_TYPES } from './message-types';
import { DataRequestIndicator, DataRequestIndicatorLink, DataRequestSource, ReportCategoryType } from '../models';

enum MessageContextType {
  background_task = 'background_task',
}

export interface MessageContext {
  type: MessageContextType;
  user_id?: string;
}

export interface UpdateDataRequestIndicatorPayload {
  user_id: string;
  indicator: DataRequestIndicator;
}

export interface UpdateDataRequestSourcePayload {
  user_id: string;
  data_request_source: DataRequestSource;
}

export interface DataRequestIndicatorLinkDeactivatedMessage extends DataRequestIndicatorLink {
  metric_description: string;
}

export interface UpdateDataRequestIndicatorLinkDeactivatedPayload {
  user_id: string;
  data_request_indicator_link: DataRequestIndicatorLinkDeactivatedMessage;
}

export interface ReportCompletePayload {
  report_name: string;
  report_id: string;
  report_category: ReportCategoryType;
}

export interface DataRequestPrefillPayload {
  data_request_id: string;
}

export interface DataRequestSourceImportPayload {
  data_request_name: string;
}

export interface DataRequestSourcePrefillPayload {
  data_request_id: string;
  data_request_source_id: string;
}

export interface ExportPayload {
  document_id: string;
  document_name: string;
  error_message?: string;
  error_details?: string;
}

export interface IntegrationDataImportPayload {
  error_message?: string;
  error_details?: string;
}

export interface SPReportPayload {
  report_id: string;
  report_name: string;
  sp_upload_instance_id?: string;
  filename?: string;
  url?: string;
  error_message?: string;
  error_details?: string;
}

export interface CDPReportPayload {
  report_id: string;
  report_name: string;
  error_message?: string;
  error_details?: string;
}

export interface TemplateReportExportMessagePayload {
  document_name: string;
  document_id?: string;
  error_message?: string;
  error_details?: string;
}

export interface ConsolidationPayload {
  type: string;
  frequency_codes: string[];
}

export interface CalculateValuesPayload {
  metric_code: string;
  value_definition_label: string | null;
}

export type MessagePayload =
  | UpdateDataRequestIndicatorPayload
  | UpdateDataRequestSourcePayload
  | UpdateDataRequestIndicatorLinkDeactivatedPayload;

export interface Message<T> {
  id: string;
  type: MESSAGE_TYPES;
  version: string;
  source: string;
  context?: MessageContext;
  time: string;
  data: T;
}

export function isUpdateDataRequestIndicatorMessage(
  message: Message<unknown>,
  currentUserId: string,
): message is Message<UpdateDataRequestIndicatorPayload> {
  const updateDataRequestIndicatorMessage = message as Message<UpdateDataRequestIndicatorPayload>;
  return (
    updateDataRequestIndicatorMessage.type === MESSAGE_TYPES.PLATFORM_DATA_REQUEST_INDICATOR_VALUE_GROUP_SET_UPDATE &&
    updateDataRequestIndicatorMessage.data.user_id != currentUserId
  );
}

export function isUpdateDataRequestSourceMessage(
  message: Message<unknown>,
  currentUserId: string,
): message is Message<UpdateDataRequestSourcePayload> {
  const updateDataRequestSourceMessage = message as Message<UpdateDataRequestSourcePayload>;

  return (
    updateDataRequestSourceMessage.type === MESSAGE_TYPES.PLATFORM_DATA_REQUEST_SOURCE_UPDATE &&
    updateDataRequestSourceMessage.data.user_id != currentUserId
  );
}

export function isUpdateDataRequestIndicatorLinkDeactivatedMessage(
  message: Message<unknown>,
): message is Message<UpdateDataRequestIndicatorLinkDeactivatedPayload> {
  const updateDataRequestIndicatorLinkDeactivatedMessage =
    message as Message<UpdateDataRequestIndicatorLinkDeactivatedPayload>;

  return (
    updateDataRequestIndicatorLinkDeactivatedMessage.type ===
    MESSAGE_TYPES.PLATFORM_DATA_REQUEST_INDICATOR_LINK_DEACTIVATED
  );
}
