import { Observable } from 'rxjs';
import { ApiResponse, DataRequestIndicator } from '../../../models';
import { UpsertValueGroupSet } from '../../../metric-editor-form';

export abstract class BaseClientDataRequestIndicatorsService {
  abstract getDataRequestIndicator(
    dataRequestIndicatorId: string,
    dataRequestId?: string,
    businessUnitId?: string,
  ): Observable<ApiResponse<DataRequestIndicator>>;
  abstract upsertDataRequestIndicatorValues(
    dataRequestIndicatorId: string,
    upsertValueGroupSet: UpsertValueGroupSet,
  ): Observable<ApiResponse<DataRequestIndicator>>;
  abstract moveDataRequestIndicatorValueGroup(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string,
    position: number,
  ): Observable<ApiResponse<DataRequestIndicator>>;
  abstract deleteDataRequestIndicatorValueGroup(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string,
  ): Observable<ApiResponse<DataRequestIndicator>>;
  abstract deleteDataRequestIndicatorValue(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string,
    valueId: string,
  ): Observable<ApiResponse<DataRequestIndicator>>;
  abstract getCollectIndicatorInDataRequest(
    dataRequestId: string,
    dataRequestSourceId: string,
    indicatorId: string,
    frequencyCode: string,
  ): Observable<ApiResponse<DataRequestIndicator>>;
}
