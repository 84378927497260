import { Pipe, PipeTransform } from '@angular/core';
import { ValueGroup } from '../../../models';

@Pipe({
  name: 'showGroupLabel',
})
export class ShowGroupLabelPipe implements PipeTransform {
  transform(group: ValueGroup, groups: ValueGroup[]): boolean {
    return Boolean(group.table_id) && groups.filter((grp) => grp.table_id === group.table_id).indexOf(group) === 0;
  }
}
