import { Metric, MetricCategory, ValueGroupSet, ValueGroup, Value, ValueDefinitionType } from '../../models';

export class MetricUtils {
  public static isRefV2Metric(metric: Metric): boolean {
    return Boolean(metric.reference_v2) && metric.category === MetricCategory.REFERENCE;
  }

  public static isRefV2OrTPM(metric: Metric): boolean {
    return MetricUtils.isRefV2Metric(metric) || metric.category === MetricCategory.THIRD_PARTY;
  }

  public static findMatchingGroup(valueGroupSet: ValueGroupSet, group_to_find: ValueGroup): ValueGroup | undefined {
    return valueGroupSet.value_groups?.find(
      (vg) =>
        `${vg.value_definition_group_id}-${vg.position}-${vg.subposition ?? 1}` ===
        `${group_to_find.value_definition_group_id}-${group_to_find.position}-${group_to_find.subposition ?? 1}`,
    );
  }

  public static findMatchingValue(valueGroup: ValueGroup, value_to_find: Value): Value | undefined {
    return valueGroup.values?.find((val) => val.position === value_to_find.position);
  }

  public static isValueReadOnly(value_definition_type: ValueDefinitionType, isAutomaticCalculation: boolean): boolean {
    return (
      isAutomaticCalculation ||
      [
        ValueDefinitionType.calculated,
        ValueDefinitionType.document,
        ValueDefinitionType.label,
        ValueDefinitionType.subtitle,
        ValueDefinitionType.tip,
      ].includes(value_definition_type)
    );
  }

  public static setValueGroupSetValuesReadOnly(vgs: ValueGroupSet): void {
    vgs.value_groups?.forEach((vg) => {
      vg.values?.forEach((value) => {
        value.is_read_only = MetricUtils.isValueReadOnly(value.type, Boolean(value.is_automatically_calculated));
      });
    });
  }
}
