import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

const elipsifyme: Record<string, string> = {
  'white-space': 'nowrap',
  'text-overflow': 'ellipsis',
  'max-width': '75ch',
  overflow: 'hidden',
};

@Directive({
  selector: '[libEllipsifyMe]',
  providers: [MatTooltip],
})
export class EllipsifyMeDirective implements AfterViewInit {
  domElement: HTMLElement;
  @Input() maxCharCount: number | undefined;

  @HostListener('mouseover') mouseover() {
    if (!this.tooltip.disabled) {
      this.tooltip.message = this.domElement.textContent as string;
      this.tooltip.show();
    }
  }
  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private tooltip: MatTooltip,
  ) {
    this.domElement = this.elementRef.nativeElement;
    Object.keys(elipsifyme).forEach((newStyle: string) => {
      this.renderer.setStyle(this.domElement, `${newStyle}`, elipsifyme[newStyle]);
    });
  }

  public ngAfterViewInit(): void {
    if (this.maxCharCount) {
      this.renderer.setStyle(this.domElement, 'max-width', `${this.maxCharCount}ch`);
    }
    this.renderer.setProperty(this.domElement, 'scrollTop', 1);
    this.toogleTooltip();
  }

  private toogleTooltip() {
    this.tooltip.disabled = !(this.domElement.offsetWidth < this.domElement.scrollWidth);
  }
}
