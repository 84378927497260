import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FullNameService {
  formatFullName(firstName?: string, lastName?: string): string {
    return [firstName, lastName]
      .map((name) => this.transformName(name))
      .filter((name) => name)
      .join(' ');
  }

  private transformName(name?: string): string {
    if (!name) {
      return '';
    }

    return name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})|(-\w{1})|('\w{1})/g, (letter) => letter.toUpperCase());
  }
}
