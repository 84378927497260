import { Component, Input, OnInit } from '@angular/core';
import { ChartConfig, ChartType, DEFAULT_CHART_CONFIG, ChartColor, BAR_WIDTH } from '../models';
import { ValueDefinitionTarget } from '../../models';
import keyBy from 'lodash/keyBy';
import { UnitSymbolPipe } from '../../pipes';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-target-chart',
  templateUrl: './target-chart.component.html',
  styleUrls: ['./target-chart.component.scss'],
  providers: [UnitSymbolPipe],
})
export class TargetChartComponent implements OnInit {
  private readonly MAX_CHART_COLUMNS_BEFORE_THEY_STACK: number = 14;
  private readonly CHART_WIDTH: number = 1300;
  private readonly Y_AXIS_WIDTH: number = 70;
  private readonly SPACE_BETWEEN_BARS: number = 15;

  @Input({ required: true }) valueDefinitionTarget!: ValueDefinitionTarget;
  @Input() showChartMenu: boolean = true;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() metricDetailsUrl?: string;
  @Input() dynamicWidth: boolean = false;

  public chartConfig: ChartConfig = {};

  constructor(
    private readonly translateService: TranslateService,
    private readonly unitSymbolPipe: UnitSymbolPipe,
  ) {}

  public ngOnInit(): void {
    const unit = this.unitSymbolPipe.transform(this.valueDefinitionTarget.value_definition.type_details?.units);
    const baselineValue = this.valueDefinitionTarget.most_recent_baseline_value ?? 0;

    const numberYears = this.valueDefinitionTarget.target_steps.length
      ? Number(this.valueDefinitionTarget.target_steps[0].frequency_code) -
        Number(this.valueDefinitionTarget.baseline_frequency_code)
      : 0;
    const years = Array(numberYears + 1)
      .fill(0)
      .map((item, index) => `${Number(this.valueDefinitionTarget.baseline_frequency_code) + index}`);

    const stepsByFrequencyCode = keyBy(this.valueDefinitionTarget.target_steps, 'frequency_code');
    const valuesByFrequencyCode = keyBy(this.valueDefinitionTarget.values, 'frequency_code');

    const columnValues = [this.valueDefinitionTarget.most_recent_baseline_value ?? baselineValue];
    const interimTargetStepsValues: (null | number)[] = [null];
    const allInterimTargetsValues: (number | null)[] = [baselineValue];
    const targetValue: (null | number)[] = [null];

    years.forEach((year: string, index: number) => {
      if (index > 0) {
        const step = stepsByFrequencyCode[year];
        const stepValue = step?.value ?? null;

        columnValues.push(valuesByFrequencyCode[year]?.value ?? null);
        allInterimTargetsValues.push(stepValue);

        if (index === years.length - 1) {
          interimTargetStepsValues.push(null);
          targetValue.push(stepValue);
        } else if (index > 0) {
          interimTargetStepsValues.push(stepValue);
          targetValue.push(null);
        }
      }
    });

    const targetInfoId = 'targetInfo';
    this.chartConfig = {
      ...DEFAULT_CHART_CONFIG,
      plotOptions: {
        ...DEFAULT_CHART_CONFIG.plotOptions,
        series: {
          ...DEFAULT_CHART_CONFIG.plotOptions?.series,
          pointWidth: this.dynamicWidth ? undefined : this.calculateBarWidth(years.length),
        },
      },
      colors: [ChartColor.primary, ChartColor.grey, ChartColor.orange, ChartColor.orange, ChartColor.orange],
      title: {
        useHTML: true,
        text: this.generateTitle(this.title, this.metricDetailsUrl),
      },
      subtitle: { text: this.subtitle },
      xAxis: { categories: years },
      yAxis: {
        title: { text: unit },
      },
      navigation: {
        buttonOptions: {
          enabled: this.showChartMenu,
        },
      },
      series: [
        {
          type: ChartType.column,
          name: this.translateService.instant('Field value'),
          data: columnValues,
        },

        {
          id: targetInfoId,
          type: ChartType.spline,
          name: this.translateService.instant('Target information'),
          data: allInterimTargetsValues,
          showInLegend: false,
        },
        {
          id: targetInfoId,
          type: ChartType.scatter,
          name: this.translateService.instant('Baseline'),
          data: [baselineValue],
          tooltip: { pointFormat: '{point.y}' },
          showInLegend: false,
        },
        {
          id: targetInfoId,
          type: ChartType.scatter,
          name: this.translateService.instant('Interim target'),
          data: interimTargetStepsValues,
          tooltip: { pointFormat: '{point.y}' },
          showInLegend: false,
        },
        {
          id: targetInfoId,
          type: ChartType.scatter,
          name: this.translateService.instant('Target'),
          data: targetValue,
          tooltip: { pointFormat: '{point.y}' },
          showInLegend: false,
        },
        {
          id: targetInfoId,
          type: ChartType.scatter,
          name: this.translateService.instant('Target information'),
          data: [],
          showInLegend: true,
        },
      ],
      exporting: {
        ...DEFAULT_CHART_CONFIG.exporting,
        sourceHeight: 400,
        sourceWidth: 1300,
        chartOptions: {
          ...DEFAULT_CHART_CONFIG.exporting!.chartOptions,
          subtitle: {
            ...DEFAULT_CHART_CONFIG.exporting!.chartOptions!.subtitle,
          },
          chart: {
            marginLeft: 80,
          },
        },
      },
    };
  }

  private generateTitle(title?: string, link?: string): string | undefined {
    if (title && link) {
      return `<a href="${link}" target="_blank">${title}</a>`;
    } else if (title) {
      return title;
    }

    return;
  }

  private calculateBarWidth(numberColumns: number): number {
    if (numberColumns > this.MAX_CHART_COLUMNS_BEFORE_THEY_STACK) {
      const calculatedBarWidth = Math.round(
        (this.CHART_WIDTH - this.Y_AXIS_WIDTH) / numberColumns - this.SPACE_BETWEEN_BARS,
      );
      return calculatedBarWidth > 0 ? calculatedBarWidth : 1;
    }
    return BAR_WIDTH;
  }
}
