<button
  *ngIf="unresolved_regular_comments_and_replies"
  lib-button
  class="btn btn-sm comments-count-button"
  (click)="handleFocusedFieldAction($event, eFocusedFieldAction.regular_comments_counter)"
  data-testid="regularCommentsCounter"
  tabindex="-1"
>
  <mat-icon svgIcon="comments" class="icon-md"></mat-icon>
  {{ unresolved_regular_comments_and_replies }}
</button>
<button
  *ngIf="unresolved_rejection_comments_and_replies"
  lib-button
  class="btn btn-sm comments-count-button"
  (click)="handleFocusedFieldAction($event, eFocusedFieldAction.rejection_comments_counter)"
  data-testid="rejectionCommentsCounter"
  tabindex="-1"
>
  <mat-icon svgIcon="rejection-comments" class="icon-md"></mat-icon>
  {{ unresolved_rejection_comments_and_replies }}
</button>
