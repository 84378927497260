import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import {
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  DataRequestValueGroupSetStatus,
  FocusedFieldAction,
  PlatformValueGroupSetStatus,
  ValueDefinitionType,
} from '../../../../models';
import { BaseMetricEditorFormStateService } from '../../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import { TranslateService } from '../../../../services/common';
import { FieldActionError, FieldActionsValidator } from './field-actions.validator';
import { FieldActionsErrorTypeTranslation } from './field-actions.translation';

interface ActionButtonState {
  disabled: boolean;
  hide?: boolean;
  tooltip?: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[lib-field-actions]',
  templateUrl: './field-actions.component.html',
  styleUrls: ['./field-actions.component.scss'],
})
export class FieldActionsComponent implements OnChanges {
  @Input({ required: true }) valueDefinitionType!: ValueDefinitionType;
  @Input({ required: true }) valueGroupSetStatus!: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus;
  @Input({ required: true }) dataRequestSourceStatus!: DataRequestSourceStatus;
  @Input() collaboratorResponsibility?: DataRequestUserResponsibility;
  @Input() unresolvedRejectionCommentsAndReplies?: number;

  @Output() actionSelected: EventEmitter<FocusedFieldAction> = new EventEmitter<FocusedFieldAction>();

  @HostBinding('class.actions') actionsClass: boolean = true;

  readonly eFocusedFieldAction = FocusedFieldAction;
  readonly rejectButtonState: ActionButtonState = {
    disabled: false,
    hide: false,
  };

  constructor(
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(): void {
    this.validateRejectButton(
      this.valueDefinitionType,
      this.unresolvedRejectionCommentsAndReplies,
      this.dataRequestSourceStatus,
      this.valueGroupSetStatus as DataRequestValueGroupSetStatus,
      this.collaboratorResponsibility,
    );
  }

  public handleFocusedFieldAction(clickEvent: MouseEvent, focusedFieldAction: FocusedFieldAction): void {
    clickEvent.stopPropagation();
    this.actionSelected.emit(focusedFieldAction);
  }

  private validateRejectButton(
    valueDefinitionType: ValueDefinitionType,
    unresolvedRejectionCommentsAndReplies: number | undefined,
    dataRequestSourceStatus: DataRequestSourceStatus,
    valueGroupSetStatus: DataRequestValueGroupSetStatus,
    collaboratorResponsibility?: DataRequestUserResponsibility,
  ): void {
    const fieldActionError: FieldActionError | undefined = FieldActionsValidator.validateRejectButton(
      valueDefinitionType,
      unresolvedRejectionCommentsAndReplies,
      dataRequestSourceStatus,
      valueGroupSetStatus,
      collaboratorResponsibility,
    );

    this.rejectButtonState.disabled = !!fieldActionError;
    this.rejectButtonState.hide = !!fieldActionError?.hideButton;
    this.rejectButtonState.tooltip =
      fieldActionError && fieldActionError.errorType
        ? this.translateService.instant(FieldActionsErrorTypeTranslation[fieldActionError.errorType])
        : undefined;
  }
}
