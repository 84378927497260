import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-template-report-structure-header',
  templateUrl: './template-report-structure-header.component.html',
  styleUrls: ['./template-report-structure-header.component.scss'],
})
export class TemplateReportStructureHeaderComponent {
  @Input() templateName?: string;

  @Output() headerClick: EventEmitter<void> = new EventEmitter<void>();
}
