import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApplicationApiDefinition,
  ExportMetricPayload,
  ExportMetricsCurrentViewPayload,
  ExportMetricsStructureCurrentViewPayload,
  ExportMetricAuditTrailPayload,
} from '../../../models';
import { Observable } from 'rxjs';
import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientDataExportService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  servicePath: string;

  resourceIndicators: string;
  resourceMetrics: string;
  resourceMetricGroups: string;
  resourceDataRequests: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resourceIndicators = this.apiService.apiConfig.apis.collect.resources.indicators;
    this.resourceMetrics = this.apiService.apiConfig.apis.collect.resources.metrics;
    this.resourceMetricGroups = this.apiService.apiConfig.apis.collect.resources.metricGroups;
    this.resourceDataRequests = this.apiService.apiConfig.apis.collect.resources.dataRequests;
  }

  public exportMetrics(payload: ExportMetricPayload): Observable<ApiResponse> {
    return this.apiService.post(`${this.servicePath}${this.resourceIndicators}/indicators/export`, payload);
  }

  public exportMetricsCurrentView(payload: ExportMetricsCurrentViewPayload): Observable<ApiResponse> {
    return this.apiService.post(`${this.servicePath}${this.resourceIndicators}/indicators/search/export`, payload);
  }

  public exportMetricsStructureCurrentView(payload: ExportMetricsStructureCurrentViewPayload): Observable<ApiResponse> {
    return this.apiService.post(`${this.servicePath}${this.resourceMetrics}/metrics/search/export`, payload);
  }

  public exportMetricGroup(metricGroupId: string, payload: ExportMetricPayload): Observable<ApiResponse> {
    return this.apiService.post(
      `${this.servicePath}${this.resourceMetricGroups}/metric_groups/${metricGroupId}/export`,
      payload,
    );
  }

  public exportDataRequest(dataRequestId: string, payload: ExportMetricPayload): Observable<ApiResponse> {
    return this.apiService.post(
      `${this.servicePath}${this.resourceDataRequests}/data_requests/${dataRequestId}/export`,
      payload,
    );
  }

  public exportMetricAuditTrail(payload: ExportMetricAuditTrailPayload): Observable<ApiResponse> {
    return this.apiService.post(`${this.servicePath}${this.resourceIndicators}/indicators/audit/export`, payload);
  }
}
