import { NgModule } from '@angular/core';
import { CurtainComponent } from './curtain.component';
import { MaterialModule } from '../material/material.module';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components';

@NgModule({
  declarations: [CurtainComponent],
  imports: [CommonModule, MaterialModule, ComponentsModule],
  exports: [CurtainComponent],
})
export class CurtainModule {}
