import { Pipe, PipeTransform } from '@angular/core';
import { ActionItem, Unit } from '../../models';

@Pipe({
  name: 'filterUnitsByFamily',
})
export class FilterUnitsByFamilyPipe implements PipeTransform {
  transform(family: string, units: ActionItem<Unit>[]): ActionItem<Unit>[] {
    return units.filter((unit) => unit.item?.family === family);
  }
}
