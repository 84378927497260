import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { TimeUtils } from '../../classes';

@Directive({
  selector: '[libBeforeUnload]',
})
export class BeforeUnloadDirective {
  private readonly UNLOADING_TIMER = 50;

  @Output('libBeforeUnload') beforeUnload: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(_: BeforeUnloadEvent): boolean {
    this.beforeUnload.emit();

    // Add a synchronous timer of 50ms to be sure that the emit has time to complete.
    // This is seen by the user (tab takes 50ms to close)
    // This is fast enough to not cause any problem and slow enough to be sure the emitter is completed 99% of the time.
    TimeUtils.sleep(this.UNLOADING_TIMER);
    return true;
  }
}
