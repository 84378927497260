import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-dialog-info-actions',
  templateUrl: './dialog-info-actions.component.html',
  styleUrls: ['./dialog-info-actions.component.scss'],
})
export class DialogInfoActionsComponent {
  @Input() modal: boolean = false;
  @Input() buttonText: string = this.translateService.instant('OK');
  @Input() disable: boolean = false;

  @Output() confirmEvent = new EventEmitter<void>();

  constructor(private translateService: TranslateService) {}

  shouldDisplayActions(): boolean {
    return !this.modal || this.buttonText !== '';
  }
}
