import { Directive, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';
import {
  ActionItem,
  Collection,
  EmptyResults,
  ItemType,
  Presentation,
  SearchBarFilterResourceArgs,
} from '../../../../models';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'lib-document-search',
})
export abstract class DocumentSearchDirective {
  @Input() filters?: Record<string, string | string[] | boolean | undefined>;
  @Input() properties?: Record<string, string | undefined>;
  @Input() lockedFilters?: { action: 'lock' | 'hide'; filters: { [field: string]: ActionItem } } = {
    action: 'lock',
    filters: {},
  };
  @Input() filterArgs: SearchBarFilterResourceArgs = {};
  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  @Input() searchOptions: string = '';

  @Output() action: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();

  @ViewChild('spinner') spinner!: ElementRef<HTMLElement>;
  scrollObserver: IntersectionObserver = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      this.loadMore();
    }
  });

  itemCollection?: Collection<any>;
  selectedItem?: ActionItem = undefined;
  activeItem?: ActionItem = undefined;
  noData: boolean = false;
  selectedItemID?: string;
  loaded: boolean = false;
  searchFilterConfig: { [key: string]: { [key: string]: boolean } } = {
    docs_doc: {
      year: true,
      source: true,
      category: true,
      type: true,
      tag: true,
      status: true,
    },
  };

  readonly dummies = new Array(12);
  readonly emptyResults: EmptyResults = {
    title: 'No results',
    subtitle: 'No search results available for this combination of filters.',
    image: 'laptop-neutral',
  };
  readonly ePresentation = Presentation;
  readonly eItemType = ItemType;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(public renderer: Renderer2) {}

  ngOnInit(): void {
    this.refresh.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response) {
        this.search();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  change(searchOptions: string): void {
    this.searchOptions = searchOptions;

    this.search();
  }

  abstract search(): void;

  abstract loadMore(): void;

  showSpinner(): void {
    setTimeout(() => {
      this.renderer.removeClass(this.spinner.nativeElement, 'invisible');
    }, 500);
  }

  hideSpinner(): void {
    setTimeout(() => {
      this.renderer.addClass(this.spinner.nativeElement, 'invisible');
    }, 500);
  }

  onMouseOver(item: any): void {
    this.activeItem = item;
  }

  onMouseOut(): void {
    this.activeItem = undefined;
  }

  selectItem(action: ActionItem): void {
    this.selectedItem = action.item;
    this.action.emit(action);
  }
}
