import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentHostEnv, FileDocumentInterface } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseMetricStructureService {
  abstract documentLinkHostEnvironment: DocumentHostEnv;

  abstract getCoreDocumentMetadata(docId: string): Observable<FileDocumentInterface>;
  abstract getCoreDocument(docStorageFilename: string): Observable<Blob>;
}
