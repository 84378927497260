<form *ngIf="form" [formGroup]="form" class="form">
  <lib-text-input
    class="formfield large"
    [control]="form.name"
    [label]="'Questionnaire Name' | translate"
    [messages]="{ isUnique: 'Name must be unique.' | translate }"
  ></lib-text-input>

  <lib-text-input
    class="formfield large"
    [control]="form.version_number"
    [label]="'Questionnaire Version' | translate"
    [readonly]="true"
  ></lib-text-input>

  <lib-date-input
    class="formfield medium"
    [control]="form.start"
    [label]="'Start Period Date' | translate"
    placeholder="YYYY-MM-DD"
    [messages]="{ invalidDate: 'Invalid date' | translate }"
  ></lib-date-input>

  <lib-date-input
    class="formfield medium"
    [control]="form.end"
    [label]="'End Period Date' | translate"
    placeholder="YYYY-MM-DD"
    [messages]="{ invalidDate: 'Invalid date' | translate }"
  ></lib-date-input>

  <div class="formfield large">
    <mat-error *ngIf="form.hasError('endRangeError')">{{ "End date must be after start date" | translate }}</mat-error>
    <mat-error *ngIf="form.hasError('previousEndRangeError')">{{
      "Start date must be after previous version end date" | translate
    }}</mat-error>
    <mat-error *ngIf="form.hasError('subsequentStartRangeError')">{{
      "End date must be before the start date of the subsequent version" | translate
    }}</mat-error>
  </div>

  <div class="formfield large">
    <lib-divider></lib-divider>
  </div>
  <div *libPermission="managePermissions" class="formfield large">
    <div>
      <button (click)="onSubmit()" [disabled]="!form.valid" lib-button buttonType="success">
        {{ "Save" | translate }}
      </button>
      <button class="ml-4" (click)="cancelAction.emit()" lib-button buttonType="link">
        {{ "Cancel" | translate }}
      </button>
    </div>
  </div>
</form>
