import { Component, HostListener, OnDestroy, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'lib-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnDestroy {
  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent): void {
    this.handleMouseLeave(event);
  }

  /** Element that caused the tooltip to open. */
  triggerElement: HTMLElement | null = null;

  tooltipText: string = '';
  tooltipTemplate: TemplateRef<any> | null = null;

  tooltip = '';
  visible = false;

  /** Subject for notifying that the tooltip has been hidden from the view */
  private readonly onHide: Subject<void> = new Subject();

  public show(): void {
    this.visible = true;
  }

  private hide(): void {
    this.visible = false;
    this.onHide.next();
  }

  /** On mouseleave the tooltip element, check if the cursor's target is the element that triggered the tooltip.
   * If it is, don't hide the tooltip */
  private handleMouseLeave({ relatedTarget }: MouseEvent) {
    if (!relatedTarget || !this.triggerElement?.contains(relatedTarget as Node)) {
      this.hide();
    }
  }

  /** Returns an observable that notifies when the tooltip has been hidden from view. */
  afterHidden(): Observable<void> {
    return this.onHide;
  }

  ngOnDestroy() {
    this.onHide.complete();
    this.triggerElement = null;
  }
}
