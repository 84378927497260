import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { ActionItem, Framework } from '../../../models';
import { StandardCodeCategoryTranslations } from '../../../translations';
import { StandardCodesFilterByCategory } from '../../classes/StandardCodesFilterByCategory/StandardCodesFilterByCategory';

@Component({
  selector: 'lib-search-bar-standard-code-menu',
  templateUrl: './search-bar-standard-code-menu.component.html',
  styleUrls: ['./search-bar-standard-code-menu.component.scss'],
})
export class SearchBarStandardCodeMenuComponent {
  @Input() control?: UntypedFormControl;
  @Input() filteredItems?: Observable<ActionItem<Framework>[]>;
  @Input() items: ActionItem<Framework>[] = [];
  @Input() minMenuScrollItems: number = 10;
  @Input() selectedItemId?: string;

  @Output() setItem = new EventEmitter<ActionItem<Framework> | undefined>();

  public readonly eStandardCodeCategoryTranslations = StandardCodeCategoryTranslations;
  filterStandardCodes = StandardCodesFilterByCategory.filterStandardCodes;
}
