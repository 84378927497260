import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SortingTranslation } from '../sorting.translation';

export { FrameworkMetricSort, FrameworkMetricStatus };

const FrameworkMetricSort = {
  score: SortingTranslation.best_match,
  code: SortingTranslation.alphabetical,
  updated: SortingTranslation.last_updated,
};

const FrameworkMetricStatus = {
  true: _('Active'),
  false: _('Inactive'),
};
