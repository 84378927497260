import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomSelectionListDialogConfig, CustomSelectionListDialogResult } from './models';
import { OptionListsApiService } from '../../../services/api-services';
import { ApiResponse, OptionList, OptionListOption, UpsertOptionListItemRequest } from '../../../models';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'lib-custom-selection-list-dialog',
  templateUrl: './custom-selection-list-dialog.component.html',
  styleUrls: ['./custom-selection-list-dialog.component.scss'],
})
export class CustomSelectionListDialogComponent implements OnInit {
  public choices: OptionListOption[] = [];
  public isTouched: boolean = false;

  private optionListId = '';

  constructor(
    private dialogRef: MatDialogRef<CustomSelectionListDialogComponent, CustomSelectionListDialogResult>,
    private readonly optionListsApiService: OptionListsApiService,
    @Inject(MAT_DIALOG_DATA) private data: CustomSelectionListDialogConfig,
  ) {}

  public ngOnInit(): void {
    let obs: Observable<[string, OptionListOption[]]>;

    if (this.data.selectionSetId && this.data.selectionSetId !== 'custom') {
      obs = forkJoin([
        this.createLegacyOptionList(),
        this.optionListsApiService.listResourceListItems(this.data.selectionSetId),
      ]).pipe(
        switchMap(([optionListRes, itemsRes]) =>
          forkJoin([
            of(optionListRes.data.id),
            this.optionListsApiService.bulkAddOptionListItems(optionListRes.data.id, {
              option_list_items: itemsRes.data.map((i) => this.fetchOptionListItemPayload({ label: i.name })),
            }),
          ]),
        ),
        map(([optionListId, res]) => [optionListId, res.data.map((i) => ({ id: i.id, label: i.name }))]),
      );
    } else if (this.data.optionListId && this.data.customChoiceAnswers?.length) {
      obs = of([this.data.optionListId, this.data.customChoiceAnswers.map((i) => ({ id: i.id, label: i.title }))]);
    } else {
      obs = this.createLegacyOptionList().pipe(map((res) => [res.data.id, []]));
    }

    obs.subscribe(([optionListId, optionListItems]) => {
      this.optionListId = optionListId;
      this.choices = optionListItems;
    });
  }

  public onAdd(option: OptionListOption): void {
    this.optionListsApiService
      .addOptionListItem(this.optionListId, this.fetchOptionListItemPayload(option))
      .subscribe((res) => {
        this.choices.push({ label: res.data.name, id: res.data.id });
        this.isTouched = true;
      });
  }

  public onEdit(option: OptionListOption): void {
    this.optionListsApiService
      .editOptionListItem(this.optionListId, String(option.id), this.fetchOptionListItemPayload(option))
      .subscribe((res) => {
        const editingChoiceIndex = this.choices.findIndex((o) => o.id === option.id);
        const newChoices = this.choices.slice();
        newChoices[editingChoiceIndex] = { id: res.data.id, label: res.data.name };
        this.choices = newChoices;
        this.isTouched = true;
      });
  }

  public onDelete(option: OptionListOption): void {
    this.optionListsApiService.deleteOptionListItem(this.optionListId, String(option.id)).subscribe(() => {
      this.choices = this.choices.filter((o) => o.id !== option.id);
      this.isTouched = true;
    });
  }

  public onMove(option: OptionListOption): void {
    this.optionListsApiService
      .moveOptionListItem(this.optionListId, String(option.id), Number(option.position))
      .subscribe((res) => {
        const currentIndex = this.choices.findIndex((o) => o.id === option.id);
        const newChoices = this.choices.slice();
        newChoices.splice(currentIndex, 1);
        newChoices.splice(res.data.position - 1, 0, option);
        this.choices = newChoices;
        this.isTouched = true;
      });
  }

  public closeDialog(): void {
    this.dialogRef.close({
      choices: this.choices.map((c) => ({ id: String(c.id), title: c.label })),
      optionListId: this.optionListId,
    });
  }

  private createLegacyOptionList(): Observable<ApiResponse<OptionList>> {
    return this.optionListsApiService.addOptionList({ name: uuidv4(), active: true });
  }

  private fetchOptionListItemPayload(option: OptionListOption): UpsertOptionListItemRequest {
    return {
      name: option.label,
      active: true,
      display_explanation: false,
      explanation_required: false,
    };
  }
}
