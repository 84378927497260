import { NgModule } from '@angular/core';

import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes';
import { TranslateModule } from '@ngx-translate/core';
import { GridsterModule } from 'angular-gridster2';
import { ChartsModule } from '../charts';
import { CommonModule, NgForOf } from '@angular/common';
import { LayoutModule } from '../layout';
import { DashboardDummyLoaderComponent } from './dashboard-dummy-loader/dashboard-dummy-loader.component';
import { DashboardViewerComponent } from './dashboard-viewer/dashboard-viewer.component';

@NgModule({
  declarations: [DashboardDummyLoaderComponent, DashboardViewerComponent],
  imports: [
    MaterialModule,
    FormsModule,
    PipesModule,
    TranslateModule,
    ChartsModule,
    GridsterModule,
    NgForOf,
    LayoutModule,
    CommonModule,
  ],
  exports: [DashboardDummyLoaderComponent, DashboardViewerComponent],
})
export class DashboardsModule {}
