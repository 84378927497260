export enum ButtonTypeEnum {
  success = 'success',
  primary = 'primary',
  link = 'link',
  danger = 'danger',
  warning = 'warning',
  medium = 'medium',
  color_link = 'color_link',
  cancel = 'cancel',
  cancel_with_border = 'cancel_with_border',
  light = 'light',
  stroked = 'stroked',
  stroked_light = 'stroked-light',
  browse = 'browse',
}
