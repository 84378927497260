import { Injectable, Optional } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { AppConfig } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class SelectivePreloadingStrategy implements PreloadingStrategy {
  private _config: AppConfig | null = null;
  preloadedModules: string[] = [];

  constructor(@Optional() config?: AppConfig) {
    if (config) {
      this._config = config;
    } else {
      throw new Error('Environment configuration must be provides on app.module');
    }
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      // add the route path to our preloaded module array if not already added
      if (route.path && !this.isAlreadyPreloaded(route.path)) {
        this.preloadedModules.push(route.path);
        // log the route path to the console
        if (this._config && !this._config.production) {
          console.info('Preloaded: ' + route.path);
        }
      }
      return load();
    } else {
      return EMPTY;
    }
  }

  isAlreadyPreloaded(module: string | undefined): boolean {
    for (const preloadedModule of this.preloadedModules) {
      if (module === preloadedModule) {
        return true;
      }
    }
    return false;
  }
}
