import { Component, Input } from '@angular/core';

import { Doc, DocumentPayloadOptions, ItemType, Value } from '../../../models';
import { ClientDocumentsService } from '../../../services/client';
import { DocumentContext } from '../../../metric-editor-form';
import { MetricViewFileDocumentInterface } from '../metric-view.component';

@Component({
  selector: 'lib-metric-view-document-link',
  templateUrl: './metric-view-document-link.component.html',
  styleUrls: ['./metric-view-document-link.component.scss'],
})
export class MetricViewDocumentLinkComponent {
  @Input() value?: Value;
  @Input() itemType: ItemType = ItemType.metrics_metric;
  @Input({ required: true }) documentPayloadOptions!: DocumentPayloadOptions;
  @Input() documents: MetricViewFileDocumentInterface[] = [];
  constructor(private documentService: ClientDocumentsService) {}

  downloadDocument(data: MetricViewFileDocumentInterface): void {
    if (this.value) {
      const documentContext = this.getDocumentContext();
      this.documentService.downloadDocumentByItemType(this.value, data.name, documentContext, <Doc>data.doc);
    }
  }

  getDocumentContext(): DocumentContext {
    return { itemType: this.itemType };
  }
}
