import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseValue, DataFormatTemplate, ValueDefinitionDisplayType } from '../../../models';

const NOT_RESETTABLE_FORMAT: ValueDefinitionDisplayType[] = [
  ValueDefinitionDisplayType.calculated,
  ValueDefinitionDisplayType.subtitle,
  ValueDefinitionDisplayType.tip,
];

@Component({
  selector: 'lib-data-formats-label',
  templateUrl: './data-formats-label.component.html',
  styleUrls: ['./data-formats-label.component.scss'],
})
export class DataFormatsLabelComponent {
  @Input({ required: true })
  set data(dataFormatTemplate: DataFormatTemplate<BaseValue>) {
    if (dataFormatTemplate) {
      this._data = dataFormatTemplate;
    }
  }

  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  get data(): DataFormatTemplate<BaseValue> {
    return this._data;
  }

  private _data!: DataFormatTemplate<BaseValue>;

  private hasValue(value: any): boolean {
    return value || value?.length;
  }

  showResetBtn(): boolean {
    return (
      !!this.data.data.id &&
      this.hasValue(this.data.data.value) &&
      !NOT_RESETTABLE_FORMAT.includes(this.data.dataFormatType)
    );
  }

  deleteValue(): void {
    this.delete.emit();
  }
}
