<lib-dialog-title (closeEvent)="close()" [title]="title"></lib-dialog-title>
<div mat-dialog-content class="pt-4 pb-1">
  <form [formGroup]="passkeyForm" novalidate>
    <lib-text-input
      [control]="passkeyForm.controls['name']"
      [label]="'Passkey name'"
      placeholder="{{ 'Enter a name' | translate }}"
      hint="{{ 'We recommend using your device\'s or password manager\'s name' | translate }}"
    ></lib-text-input>
  </form>
</div>
<lib-dialog-info-actions (confirmEvent)="submit()" buttonText="Confirm" [disable]="passkeyForm.invalid">
</lib-dialog-info-actions>
