import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const LoaderStates = {
  displayedLoader: 'displayed-loader',
  hiddenLoader: 'hidden-loader',
};

@Injectable({
  providedIn: 'root',
})
export class LoaderStateService {
  private _loaderState$: BehaviorSubject<string> = new BehaviorSubject<string>(LoaderStates.hiddenLoader);
  private _loaderMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public readonly loaderState$ = this._loaderState$.asObservable();
  public readonly loaderMessage$ = this._loaderMessage$.asObservable();

  public openloader(message?: string): void {
    this._loaderState$.next(LoaderStates.displayedLoader);
    if (message) {
      this._loaderMessage$.next(message);
    }
  }

  public closeloader(): void {
    this._loaderState$.next(LoaderStates.hiddenLoader);
    this._loaderMessage$.next('');
  }
}
