import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActionItem, ToolsTabType } from '../../models';

@Component({
  selector: 'lib-panel-tool',
  templateUrl: './panel-tool.component.html',
  styleUrls: ['./panel-tool.component.scss'],
})
export class PanelToolComponent implements OnInit {
  @Input() tabTools: ActionItem[] = [];
  @Input() panelToolsRef?: TemplateRef<any>;
  @Input() expandedTabView: boolean = false;
  @Input() defaultSelection?: ToolsTabType;

  @Output() expandedTabViewChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public selectedTool?: ActionItem;

  ngOnInit(): void {
    if (this.defaultSelection) {
      this.selectedTool = this.tabTools.find((t: ActionItem) => t.id === this.defaultSelection);
    }
  }

  toggleSelectTool(): void {
    if (!this.selectedTool) {
      this.selectTool(this.tabTools[0]);
    } else {
      this.selectTool();
    }
  }

  selectTool(tool?: ActionItem): void {
    this.selectedTool = tool;
  }

  public toggleExpandTabView(): void {
    this.expandedTabView = !this.expandedTabView;
    this.expandedTabViewChange.emit(this.expandedTabView);
  }
}
