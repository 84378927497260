<mat-menu #moreMenu="matMenu">
  <ng-template matMenuContent let-items="items">
    <div class="ph-3 pv-2">
      <div class="mb-1" *ngFor="let item of items">{{ item.name }}</div>
    </div>
  </ng-template>
</mat-menu>

<div class="fx-row justify-space-between" [ngClass]="{ 'limited-view': limitedCardSize, 'hide-date mt-1': hideDate }">
  <div class="fx-row color-grey-800 date-footer">
    <span class="card-footer-default line-clamp">
      {{ actionItem?.start ? (actionItem?.start | date: "YYYY") : ("Never updated" | translate) }}
    </span>
    <span class="card-footer-hover">
      {{ (actionItem?.start | date: "YYYY") || ("Never updated" | translate) }}
    </span>
    <span class="card-footer-active">
      {{ (actionItem?.start | date: "YYYY") || ("Never updated" | translate) }}
    </span>
    <span class="standard-codes-details">
      <ng-container *ngTemplateOutlet="defaultData"></ng-container>
    </span>
  </div>
</div>

<ng-template #defaultData>
  <span *ngIf="actionItem?.item?.topics?.length">
    &nbsp;| {{ (actionItem?.item?.topics)![0].name }}
    <ng-container *ngTemplateOutlet="showMoreButton; context: { items: remainingTopics }"></ng-container>
  </span>
  <span *ngIf="actionItem?.item?.industries?.length">
    &nbsp;| {{ (actionItem?.item?.industries)![0].name }}
    <ng-container *ngTemplateOutlet="showMoreButton; context: { items: remainingIndustries }"></ng-container>
  </span>
</ng-template>

<ng-template #showMoreButton let-items="items">
  <a
    class="btn btn-xs bkg-grey-200 btn-more-link"
    [ngClass]="{ active: trigger.menuOpen }"
    *ngIf="items.length"
    [matMenuTriggerFor]="moreMenu"
    #trigger="matMenuTrigger"
    [matMenuTriggerData]="{ items: items }"
    (click)="stopDefaultClickAction($event)"
  >
    + {{ items.length }}
  </a>
</ng-template>
