<div class="panel-details">
  <div class="panel-collection" [ngClass]="{ 'panel-collapsed': collectionCollapsed }">
    <div class="panel-collection-content">
      <ng-content select="[slot=collection]"></ng-content>
    </div>
    <a class="panel-toggle" (click)="toggleCollapse()">
      <lib-panel-toggle>
        <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
        {{ title }}
      </lib-panel-toggle>
    </a>
  </div>
  <div class="panel-item">
    <ng-content select="[slot=item]"></ng-content>
  </div>
</div>
