import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public getCookie(name: string): string {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  public deleteCookie(name: string): void {
    this.setCookie(name, '', -1);
  }

  public setCookie(name: string, value: string, expireDays: number, domain: string = '', path: string = '') {
    let cookie: string = `${name}=${value}; Max-Age=${expireDays * 24 * 60 * 60}`;
    if (domain) {
      cookie += `; Domain=${domain};`;
    }
    if (path) {
      cookie += `; Path=${path}`;
    }
    this.document.cookie = cookie;
  }
}
