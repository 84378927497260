import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  DisplayedValueForm,
  TemplateReportStructureDisplayedValueForm,
} from './template-report-structure-displayed-value-form';
import { DisplayedValue, ValueDefinitionDisplayType } from '../../../../models';
import { DisplayedValuePayload } from '../../../../models';

export type DisplayedValuesForm = {
  displayedValues: FormArray<FormGroup<DisplayedValueForm>>;
};
export class TemplateReportStructureDisplayedValuesForm extends FormGroup<DisplayedValuesForm> {
  hasCheckedValue: boolean = false;

  private defaultValues: DisplayedValue[];

  constructor(
    readonly displayedValues: DisplayedValue[] = [],
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      displayedValues: fb.array<FormGroup<DisplayedValueForm>>([]),
    });
    this.defaultValues = displayedValues;
    this.displayedValues.forEach((displayedValue) => {
      this.controls.displayedValues.push(new TemplateReportStructureDisplayedValueForm(displayedValue));
    });
  }

  computeIfCheckedValue() {
    const checked = this.controls.displayedValues.value.find(
      (displayedValue) =>
        displayedValue.isChecked &&
        displayedValue.type != ValueDefinitionDisplayType.subtitle &&
        displayedValue.type != ValueDefinitionDisplayType.tip,
    );
    this.hasCheckedValue = Boolean(checked);
  }

  public resetToCheckedAndEmpty() {
    this.controls.displayedValues.controls.forEach((control) => {
      control.patchValue({ isChecked: true, overriddenLabel: '' });
    });
    this.hasCheckedValue = true;
  }

  toModel(): DisplayedValuePayload[] {
    const data = this.value.displayedValues?.map<DisplayedValuePayload>((displayedValue) => ({
      value_definition_id: displayedValue.valueDefinitionId,
      is_checked: displayedValue.isChecked,
      overridden_label: displayedValue.overriddenLabel,
      type: displayedValue.type,
    }));
    return data || [];
  }
}
