import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslateService } from '../../services/common';

@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number | null = null;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private translateService: TranslateService,
  ) {}

  transform(val: Date | string | undefined): string {
    if (!val) {
      return '';
    }
    const value = val.toString();
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = Number.isNaN(seconds) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return this.translateService.instant('a few seconds ago');
    } else if (seconds <= 90) {
      return this.translateService.instant('a minute ago');
    } else if (minutes <= 45) {
      return this.translateService.instant('{minutes} minutes ago', { minutes });
    } else if (minutes <= 90) {
      return this.translateService.instant('an hour ago');
    } else if (hours <= 22) {
      return this.translateService.instant('{hours} hours ago', { hours });
    } else if (hours <= 36) {
      return this.translateService.instant('a day ago');
    } else if (days <= 25) {
      return this.translateService.instant('{days} days ago', { days });
    } else if (days <= 45) {
      return this.translateService.instant('a month ago');
    } else if (days <= 345) {
      return this.translateService.instant('{months} months ago', { months });
    } else if (days <= 545) {
      return this.translateService.instant('a year ago');
    } else {
      // (days > 545)
      return this.translateService.instant('{years} years ago', { years });
    }
  }
  ngOnDestroy(): void {
    this.removeTimer();
  }
  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }
  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}
