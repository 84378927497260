import { Pipe, PipeTransform } from '@angular/core';
import { FileDocumentInterface, FileValue } from '../../../models';

@Pipe({
  name: 'findFileDocument',
})
export class FindFileDocumentPipe implements PipeTransform {
  transform(fileValue: FileValue, fileDocumentsList: FileDocumentInterface[]): FileDocumentInterface | undefined {
    return fileDocumentsList.find((doc) => doc.id === fileValue.file_id);
  }
}
