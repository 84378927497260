import { Pipe, PipeTransform } from '@angular/core';
import { FullNameService } from '../../services/common';
import { LimitedUser, User } from '../../models';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  constructor(private readonly fullNameService: FullNameService) {}

  transform(user?: User | LimitedUser): string {
    if (!user) {
      return '';
    }
    return this.fullNameService.formatFullName(user.first_name, user.last_name);
  }
}
