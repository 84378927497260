import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItemConfig } from '../../../models';
import { DynamicComponentLoaderDirective } from '../../../directives';
import isEqual from 'lodash/isEqual';

@Component({
  selector: 'lib-right-side-menu',
  templateUrl: './right-side-menu.component.html',
  styleUrls: ['./right-side-menu.component.scss'],
})
export class RightSideMenuComponent extends DynamicComponentLoaderDirective {
  @Input() set menuItems(items: MenuItemConfig[]) {
    const oldItems = [...this.items];

    this.items = items;
    this.preserveCurrentState();

    if (!isEqual(oldItems, items)) {
      this.updateSelectedTabState();
      this.setDefaultSelectedTab();
    }
  }

  @Output() menuItemClick: EventEmitter<MenuItemConfig> = new EventEmitter<MenuItemConfig>();
  @Output() clearCurrentState: EventEmitter<void> = new EventEmitter<void>();

  public selectedMenuItem?: MenuItemConfig;
  public isOpened: boolean = true;
  public defaultMenuItemsOpened: boolean = false;
  public items: MenuItemConfig[] = [];

  constructor() {
    super();
  }

  private updateSelectedTabState(): void {
    if (!this.selectedMenuItem) {
      return;
    }

    const itemExists = this.items.some((item: MenuItemConfig) => item.id === this.selectedMenuItem?.id);
    if (!itemExists) {
      this.goBackToMenu();
    }
  }

  private setDefaultSelectedTab(): void {
    const itemConfig: MenuItemConfig | undefined = this.items.find((item: MenuItemConfig) => item.defaultSelectedTab);
    if (itemConfig) {
      if (this.selectedMenuItem && this.selectedMenuItem.id !== itemConfig.id) {
        return;
      }
      if (this.defaultMenuItemsOpened) {
        return;
      }

      this.loadItemComponent(itemConfig);
      this.defaultMenuItemsOpened = true;
    }
  }

  public openTab(tabId: string): void {
    const itemConfig: MenuItemConfig | undefined = this.items.find((item: MenuItemConfig) => item.id === tabId);

    if (itemConfig) {
      if (!this.isOpened) {
        this.isOpened = true;
      }
      if (this.selectedMenuItem) {
        if (this.selectedMenuItem.id === itemConfig.id) {
          return;
        } else {
          this.destroyDynamicComponent();
          this.selectedMenuItem = undefined;
        }
      }

      this.loadItemComponent(itemConfig);
    }
  }

  private preserveCurrentState(): void {
    if (this.selectedMenuItem) {
      const selectedMenuItem = this.items.find((item) => item.id === this.selectedMenuItem?.id);
      if (selectedMenuItem?.reloadOnChange) {
        this.destroyDynamicComponent();
        this.loadItemComponent(selectedMenuItem);
      }
    }
  }

  public toggleSideMenu(): void {
    this.isOpened = !this.isOpened;
  }

  public loadItemComponent(itemConfig: MenuItemConfig): void {
    if (itemConfig.component || itemConfig.componentTemplate) {
      this.selectedMenuItem = itemConfig;
      if (itemConfig.component) {
        const component = itemConfig.component;
        setTimeout(() => {
          this.loadDynamicComponent(component, itemConfig.componentProperties);
        });
      }
    }
  }

  public goBackToMenu(): void {
    this.clearCurrentState.emit();
    this.destroyDynamicComponent();
    this.selectedMenuItem = undefined;
  }
}
