import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'executeFunctionOnValue',
})
export class ExecuteFunctionOnValuePipe<T, R> implements PipeTransform {
  transform(value: T, functionToExecute: (value: T) => R): R {
    return functionToExecute(value);
  }
}
