import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RadioButtonOption } from './radio';

let nextId = 0;

@Component({
  selector: 'lib-radio-buttons',
  templateUrl: './radio-buttons.component.html',
})
export class RadioButtonsComponent {
  @Input() control?: UntypedFormControl;
  @Input() label: string = '';
  @Input() choices: RadioButtonOption[] = [];
  @Input() column: boolean = false;

  @Output() choiceSelection: EventEmitter<string> = new EventEmitter();

  readonly _inputId = `radio-button-${nextId++}`;
}
