import { Injectable } from '@angular/core';
import { User, UserRoles } from '../../../models';
import { UserRoleLabel } from '../../../translations';
import { TranslateService } from '../translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private translateService: TranslateService) {}

  public getDescription(user: User) {
    const footerStrings = [];

    if (user.roles.length) {
      const role = user.roles[0] as UserRoles;
      if (role in UserRoleLabel) {
        const roleLabel = UserRoleLabel[role];
        footerStrings.push(this.translateService.instant(roleLabel));
      }
    }

    if (user.title) {
      footerStrings.push(user.title);
    }
    if (user.department) {
      footerStrings.push(user.department);
    }

    return footerStrings.join(' — ');
  }
}
