import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricPreviewComponent } from './metric-preview.component';
import { MetricEditorFormModule } from '../metric-editor-form';

@NgModule({
  declarations: [MetricPreviewComponent],
  imports: [CommonModule, MetricEditorFormModule],
  exports: [MetricPreviewComponent],
})
export class MetricPreviewModule {}
