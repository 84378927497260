import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ActionItem, Indicator, StandardCodes, Topic, Industry } from '../../../../models';

@Component({
  selector: 'lib-metric-card-footer',
  templateUrl: './metric-card-footer.component.html',
  styleUrls: ['./metric-card-footer.component.scss'],
})
export class MetricCardFooterComponent implements OnInit {
  @Input() metricActionItem?: ActionItem<Indicator>;
  @Input() limitedCardSize: boolean = false;
  @Input() hideDate: boolean = false;
  @Output() footerSelect: EventEmitter<ActionItem<Indicator | StandardCodes>> = new EventEmitter();

  remainingTopics: Topic[] = [];
  remainingIndustries: Industry[] = [];

  ngOnInit(): void {
    this.getRemainingDefaultItems();
  }

  public onChipsClick(event: ActionItem<Indicator | StandardCodes>): void {
    this.footerSelect.emit(event);
  }

  public stopDefaultClickAction(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private getRemainingDefaultItems(): void {
    if (this.metricActionItem?.item?.topics?.length) {
      this.remainingTopics = JSON.parse(JSON.stringify(this.metricActionItem.item.topics));
      this.remainingTopics.splice(0, 1);
    }
    if (this.metricActionItem?.item?.industries?.length) {
      this.remainingIndustries = JSON.parse(JSON.stringify(this.metricActionItem.item.industries)) ?? [];
      this.remainingIndustries.splice(0, 1);
    }
  }
}
