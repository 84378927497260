<div
  class="multi-select choice-checkbox-field"
  tabindex="0"
  data-testid="checkboxFormField"
  [ngClass]="{ required: required, disabled: control.disabled, error: control.touched && control.invalid }"
  #focusElement
>
  <div class="choice-checkbox-field-wrapper">
    <mat-hint *ngIf="(control.valid || control.untouched) && hint">{{ hint }}</mat-hint>
    <ng-container *ngIf="control.touched && control.invalid">
      <mat-error *ngFor="let error of control.errors | errorKeys"> {{ errorMessages[error] }}</mat-error>
    </ng-container>
    <lib-multi-checkbox-input [options]="options" [control]="control"></lib-multi-checkbox-input>
  </div>
</div>
