import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

import { map, switchMap } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';

type UniquenessCheck = (value: string) => Observable<boolean>;

export class UniquenessValidator {
  public static delay = 500;

  static validate(
    uniquenessCheck: UniquenessCheck,
    omit: string[] = [],
    delay = UniquenessValidator.delay,
    encode = true,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const value = String(control.value);

      if (omit.includes(value)) {
        return of(null);
      }

      return timer(delay).pipe(
        switchMap(() => {
          const value = String(control.value).trim();
          return uniquenessCheck(encode ? encodeURIComponent(value) : value);
        }),
        map((available) => (available ? null : { isUnique: true })),
      );
    };
  }
}
