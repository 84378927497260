<div
  class="choice-radio-field"
  tabindex="0"
  data-testid="radioFormField"
  [ngClass]="{
    disabled: control.disabled,
    error: control.touched && control.invalid,
  }"
  #focusElement
>
  <div class="choice-radio-field-wrapper">
    <mat-hint *ngIf="(control.valid || control.untouched) && hint">{{ hint }}</mat-hint>
    <ng-container *ngIf="control.touched && control.invalid">
      <mat-error *ngFor="let error of control.errors | errorKeys"> {{ errorMessages[error] }}</mat-error>
    </ng-container>
    <lib-radio-buttons
      data-testid="choiceRadio"
      class="mat-body-2"
      [control]="control"
      [choices]="radioChoicesList"
      [column]="true"
    ></lib-radio-buttons>
  </div>
</div>
