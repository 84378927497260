<div class="card-container">
  <section class="card-header" (click)="toggleExpand()">
    <div class="card-title">
      <span>{{ cardTitle }}</span>
      <mat-icon data-testid="expandAllIcon" [svgIcon]="isExpanded ? 'arrow-head-up' : 'arrow-head-down'"> </mat-icon>
    </div>
    <div class="card-subtitle pt-3" *ngIf="cardSubtitle">
      <span>{{ cardSubtitle }}</span>
    </div>
  </section>

  <section class="card-expanding" [ngStyle]="{ 'max-height': contentMaxHeight }" data-testid="expanding-section">
    <div #cardContentElement class="card-element-separator card-content" [ngStyle]="{ 'max-height': maxHeight + 'px' }">
      <ng-content select="[content]"></ng-content>
    </div>

    <div #cardActionsElement class="card-element-separator card-actions">
      <ng-content select="[actions]"></ng-content>
    </div>
  </section>
</div>
