import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { ApiResponse, ClientLoginInfo } from '../../../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TabTitleService {
  constructor(
    private titleService: Title,
    private authService: AuthService,
  ) {}

  public updateTabTitle(): void {
    this.authService
      .getClientLoginInfo()
      .pipe(map((response: ApiResponse<ClientLoginInfo>) => response.data))
      .subscribe((clientLoginInfo: ClientLoginInfo) => {
        const title: string = clientLoginInfo.name === 'Novisto' ? '' : `${clientLoginInfo.name} - `;
        this.titleService.setTitle(`${title}${this.titleService.getTitle()}`);
      });
  }
}
