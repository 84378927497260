<div class="status-accordion" data-testid="status-accordion" (click)="togglePanel()">
  <header class="accordion-header">
    <div class="left-side">
      <span class="arrow-icon" [ngClass]="{ expanded: isPanelExpanded }">
        <mat-icon svgIcon="arrow-head-right"></mat-icon>
      </span>
      <lib-tag [value]="status" [tagType]="statusType"></lib-tag>
      <span class="title">{{ title }}</span>
    </div>
    <div data-testid="status-accordion-button-area" class="right-side" (click)="$event.stopPropagation()">
      <ng-content select="[accordion-button]"></ng-content>
    </div>
  </header>
  <section
    *ngIf="isPanelExpanded"
    class="accordion-content"
    data-testid="status-accordion-panel"
    [ngClass]="{ expanded: isPanelExpanded, collapsed: !isPanelExpanded }"
    (click)="$event.stopPropagation()"
  >
    <ng-content></ng-content>
  </section>
</div>
