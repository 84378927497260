import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'lib-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input() color?: ThemePalette;
  @Input() discrete: boolean = false;
  @Input({ required: true }) endFormControl!: FormControl<number | null>;
  @Input({ required: true }) max!: number;
  @Input({ required: true }) min!: number;
  @Input() showTickMarks: boolean = false;
  @Input({ required: true }) step!: number;
  @Input({ required: true }) startFormControl!: FormControl<number | null>;
}
