import { Injectable } from '@angular/core';
import { CopyToClipboardUtils } from '../../../classes';
import { TextTypeDetails, TypeDetails, ValueDefinitionType } from '../../../models';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root',
})
export class CopyMetricValuesService {
  constructor(private clipboard: Clipboard) {}

  copyValuesBasedOnType(valueToCopy: string, type: ValueDefinitionType, typeDetails: TypeDetails): boolean {
    let result = false;
    if (type === ValueDefinitionType.text && (typeDetails as TextTypeDetails).rich_text) {
      result = CopyToClipboardUtils.copyRichTextToClipboard(valueToCopy);
    } else {
      result = this.clipboard.copy(valueToCopy);
    }
    return result;
  }
}
