import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionItem, ResourceType, SOURCE_CONFIGURATION } from '../../../models';
import { ButtonProps, SearchableOptionProps } from '../../models';
import { UntypedFormControl } from '@angular/forms';
import { FrameworkTaxonomyCategoryTranslations, StandardCodeCategoryTranslations } from '../../../translations';
import { TaxonomyFilterByCategory } from '../../classes/TaxonomyFilterByCategory/TaxonomyFilterByCategory';
import { StandardCodesFilterByCategory } from '../../classes/StandardCodesFilterByCategory/StandardCodesFilterByCategory';

@Component({
  selector: 'lib-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
})
export class FilterSearchComponent implements OnInit {
  @Input() optionProps?: SearchableOptionProps;
  @Input() buttonProps?: ButtonProps;
  @Input() sourceConfiguration?: SOURCE_CONFIGURATION;

  @Output() optionSelected = new EventEmitter<ActionItem>();

  searchInputControl = new UntypedFormControl();
  filteredOptions: ActionItem[] = [];
  minMenuScrollItems: number = 10;
  standardCodeResourceType = ResourceType.standard_codes;
  topicResourceType = ResourceType.topic;
  frameworkTaxonomyResourceType = ResourceType.taxonomy;

  public readonly frameworkTaxonomyCategoryTranslations = FrameworkTaxonomyCategoryTranslations;
  public readonly standardCodeCategoryTranslations = StandardCodeCategoryTranslations;
  filterStandardCodes = StandardCodesFilterByCategory.filterStandardCodes;
  filterFrameworkTaxonomyCodes = TaxonomyFilterByCategory.filterTaxonomies;

  ngOnInit(): void {
    this.filteredOptions = this.optionProps?.options ?? [];

    if (this.optionProps?.minMenuScrollItems) {
      this.minMenuScrollItems = this.optionProps.minMenuScrollItems;
    }

    this.searchInputControl.valueChanges.subscribe((query: string) => {
      this.filteredOptions = this.filterItems(this.optionProps?.options ?? [], query);
    });
  }

  resetInput(): void {
    this.searchInputControl.setValue('');
  }

  filterItems(input: ActionItem[], value: string): ActionItem[] {
    const filterValue = value.toLowerCase();
    return input.filter((item) => item.title.toLowerCase().includes(filterValue));
  }

  isSearchable(): boolean {
    if (this.optionProps?.options?.length) {
      return this.optionProps.options.length > this.minMenuScrollItems;
    }
    return false;
  }

  getDefaultValueTitle(): string {
    return this.optionProps?.defaultValueTitle ?? this.optionProps?.defaultValue?.title ?? '';
  }
}
