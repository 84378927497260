import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatNumberBrowserLocale' })
export class FormatNumberBrowserLocalePipe implements PipeTransform {
  transform(value: number | string, decimals: number = 0): string {
    const locale = navigator.language || 'en-US';
    const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits: decimals });

    return formatter.format(Number(value));
  }
}
