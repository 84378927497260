import { TranslateLoader } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LANGUAGES } from '../../../models';

import en from '../../../i18n/en.json';
import fr from '../../../i18n/fr.json';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateLoader extends TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(this.retrieveJsonFromLanguage(lang));
  }

  retrieveJsonFromLanguage(lang: string): Record<string, string> {
    switch (lang) {
      case LANGUAGES.FR:
        return fr;
      case LANGUAGES.EN:
      default:
        return en;
    }
  }
}
