<lib-expandable-card
  [cardTitle]="'Novisto Framework Taxonomy ({taxonomiesCount})' | translate: { taxonomiesCount: taxonomies.length }"
  [maxHeight]="expandableCardMaxContentHeight"
  data-testid="novisto-framework-taxonomies"
>
  <div content class="expandable-card-sub-elements">
    <lib-simple-text-card
      *ngFor="let taxonomy of taxonomies"
      [cardTitle]="taxonomy.label"
      [text]="taxonomy.description ?? ''"
      [actionIcon]="enableDelete ? 'trash' : ''"
      (actionClick)="handleDelete.emit(taxonomy)"
    ></lib-simple-text-card>

    <span *ngIf="taxonomies.length <= 0">
      {{ "There is no framework taxonomy to display" | translate }}
    </span>
  </div>

  <button *ngIf="enableAdd" actions lib-button buttonType="primary" (click)="handleAdd.emit()">
    + {{ "Add" | translate }}
  </button>
</lib-expandable-card>
