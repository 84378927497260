<ng-container *ngIf="isChildren; else defaultEmptyResult">
  <div class="children">
    <div class="empty-results-text">
      {{ displayData?.title }}
    </div>
  </div>
</ng-container>

<ng-template #defaultEmptyResult>
  <lib-empty-results [displayData]="displayData"></lib-empty-results>
</ng-template>
