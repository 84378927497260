import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BooleanTypeDetails,
  ConditionalTrigger,
  ConditionalTriggerTarget,
  ConditionalTriggerTargetUpsert,
  ConditionalTriggerUpsertPayload,
  ValueDefinition,
  ValueDefinitionType,
} from '../../../models';

type ConditionalTriggerValueType = string[] | boolean[] | string | null;
type ConditionalTriggerFormModel = {
  triggerValue: FormControl<ConditionalTriggerValueType>;
  targets: FormControl<ConditionalTriggerTarget[] | null>;
  operator: FormControl<string | null>;
};

export class ConditionalTriggerForm extends FormGroup<ConditionalTriggerFormModel> {
  constructor(
    readonly model?: ConditionalTrigger,
    private readonly valueDefinition?: ValueDefinition,
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      triggerValue: fb.control(model?.values ?? null, Validators.required),
      targets: fb.control(model?.targets ?? null, Validators.required),
      operator: fb.control(
        model?.operator ?? null,
        valueDefinition?.type == ValueDefinitionType.number ? Validators.required : undefined,
      ),
    });
  }

  public toModel(): ConditionalTriggerUpsertPayload {
    let valueList: ConditionalTriggerValueType;
    switch (this.valueDefinition?.type) {
      case ValueDefinitionType.boolean:
        valueList = [] as boolean[];
        const triggerValues = this.controls.triggerValue.value;
        if (triggerValues instanceof Array) {
          for (const triggerValue of triggerValues) {
            valueList.push(triggerValue === (this.valueDefinition.type_details as BooleanTypeDetails).label_true);
          }
        }
        break;

      case ValueDefinitionType.number:
        valueList = String(this.controls.triggerValue.value);
        break;

      case ValueDefinitionType.choice:
      default:
        valueList = (this.controls.triggerValue.value as string[] | null) ?? [];
    }

    return {
      values: valueList,
      operator: this.controls.operator.value ?? undefined,
      targets:
        ((this.controls.targets.value as Partial<ConditionalTriggerTarget>[] | null)?.map((t) => ({
          value_definition_id: t.value_definition_id,
          value_definition_group_id: t.value_definition_group_id,
        })) as ConditionalTriggerTargetUpsert[]) ?? [],
    };
  }
}
