import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SortingTranslation } from '../sorting.translation';

export { LibraryPeersPeerSort, LibraryPeersPeerStatus };

const LibraryPeersPeerSort = {
  score: SortingTranslation.best_match,
  name: SortingTranslation.alphabetical,
};

const LibraryPeersPeerStatus = {
  true: _('Active'),
  false: _('Inactive'),
};
