import { Pipe, PipeTransform } from '@angular/core';

import lodashFilter from 'lodash/filter';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform<T, V>(
    options: T[] | Record<string, T> | null | undefined,
    filter?: string | [string, unknown] | ((option: T, value?: V) => boolean),
    value?: V,
  ): T[] {
    return lodashFilter(
      Object.values(options || []),
      typeof filter === 'function' ? (option) => filter(option, value) : filter,
    );
  }
}
