import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChartComponent } from './chart/chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { PipesModule } from '../pipes';
import { TargetChartComponent } from './target-chart/target-chart.component';
import { ChartWidgetComponent } from './chart-widget/chart-widget.component';
import { MaterialModule } from '../material/material.module';
import { ComponentsModule } from '../components';
import { TranslateModule } from '@ngx-translate/core';
import { NumberMaskModule } from '../number-mask';
import { DirectivesModule } from '../directives';
import { SingleDataPointWidgetComponent } from './single-data-point-widget/single-data-point-widget.component';
import { ValueDefinitionTargetWidgetComponent } from './value-definition-target-widget/value-defnition-target-widget.component';

@NgModule({
  declarations: [
    ChartComponent,
    TargetChartComponent,
    ChartWidgetComponent,
    SingleDataPointWidgetComponent,
    ValueDefinitionTargetWidgetComponent,
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    PipesModule,
    MaterialModule,
    ComponentsModule,
    TranslateModule,
    NumberMaskModule,
    DirectivesModule,
  ],
  exports: [
    ChartComponent,
    TargetChartComponent,
    ChartWidgetComponent,
    SingleDataPointWidgetComponent,
    ValueDefinitionTargetWidgetComponent,
  ],
})
export class ChartsModule {}
