import { Component, Input } from '@angular/core';
import { TipIconConfig } from '../../../models';

@Component({
  selector: 'lib-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss'],
})
export class TipComponent {
  @Input({ required: true }) iconConfig!: TipIconConfig;
}
