import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PanelMenuItem } from '../../../models';

@Component({
  selector: 'lib-panel-menu',
  templateUrl: './panel-menu.component.html',
  styleUrls: ['./panel-menu.component.scss'],
})
export class PanelMenuComponent {
  public panelMenuItems: PanelMenuItem[] = [];
  @Input() set items(items: PanelMenuItem[]) {
    this.fixupItems(items);
  }

  @Input({ required: false }) multiple: boolean = false;
  @Input() selectedItems: string[] = [];

  @Output() activeItem: EventEmitter<PanelMenuItem> = new EventEmitter<PanelMenuItem>();

  private fixupItems(items: PanelMenuItem[]): void {
    items.forEach((item: PanelMenuItem) => {
      item.command = (e) => this.activeItem.emit(e.item);
      this.fixupItems(item.items || []);
    });
    this.panelMenuItems = items;
  }
}
