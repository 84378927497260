import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MetricCategory } from '../../models';
import { SortingTranslation } from '../sorting.translation';

export { PeerMetricCategories, PeerMetricSort, PeersPeerSort, PeerSort, PeerBoolean };

const PeerMetricCategories = {
  standard: _('Standard'),
  [MetricCategory.REFERENCE]: _('Reference'),
  [MetricCategory.CUSTOM]: _('Custom'),
  rfp: _('RFP'),
  [MetricCategory.THIRD_PARTY]: _('3rd-party answer'),
};

const PeerMetricSort = {
  score: SortingTranslation.best_match,
  code: SortingTranslation.alphabetical,
  updated: SortingTranslation.last_updated,
};

const PeersPeerSort = {
  name: SortingTranslation.alphabetical,
};

const PeerSort = {
  updated: SortingTranslation.last_updated,
  name: SortingTranslation.alphabetical,
};

const PeerBoolean = {
  true: _('True'),
  false: _('False'),
};
