import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TextAreaInputComponent } from '../../../components';
import { ValueFormControl } from '../../models/valueFormControl';
import { METRIC_EDITOR_AUTO_SAVE_INTERVAL_MS } from '../../../constants';

@Component({
  selector: 'lib-metric-editor-text-area-field',
  templateUrl: './metric-editor-text-area-field.component.html',
  styleUrls: ['./metric-editor-text-area-field.component.scss'],
})
export class MetricEditorTextAreaFieldComponent implements OnInit, OnDestroy {
  @Input() valueFormControl?: ValueFormControl;

  @ViewChild(TextAreaInputComponent) inputFieldRef!: TextAreaInputComponent;

  hint = '';
  label = '';
  placeholder = '';
  maxLength: number | null = null;
  autoSaveInterval: number = METRIC_EDITOR_AUTO_SAVE_INTERVAL_MS;

  public ngOnInit(): void {
    if (this.valueFormControl) {
      this.hint = this.valueFormControl.valueRef.hint ?? '';
      this.label = this.valueFormControl.valueRef.label ?? '';
      this.placeholder = this.valueFormControl.valueRef.type_details.placeholder ?? '';

      const maxLength = this.valueFormControl.valueRef.validators?.find(
        (validator) => validator.validator_type === 'max_length',
      )?.instructions as string;
      if (maxLength) {
        this.maxLength = +maxLength;
      }
    }
  }

  public ngOnDestroy(): void {
    this.updateValue();
  }

  public setFocus(): void {
    this.inputFieldRef.setFocus();
  }

  public updateValue(): void {
    this.inputFieldRef.setBlur();
  }
}
