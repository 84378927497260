import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, ListFavoriteOrgsRequest, Org } from '../../../models';
import { ApiService } from '../../common';
import { Observable } from 'rxjs';
import { BaseClientPeersService } from './base-client-peers.service';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicPeersService extends BaseClientPeersService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    super();

    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.platform;
  }

  public listFavoriteOrgs(listFavoriteOrgsRequest: ListFavoriteOrgsRequest): Observable<ApiResponse<Org[]>> {
    const params = this.getlistFavoriteOrgsHttpParams(listFavoriteOrgsRequest);

    return this.apiService.get(`${this.servicePath}${this.resource}/favorite_orgs`, { params });
  }
}
