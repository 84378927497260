import { Pipe, PipeTransform } from '@angular/core';
import { CategoryRouteMap, ReportCategoryType } from '../../models';
@Pipe({
  name: 'templateLink',
})
export class TemplateLinkPipe implements PipeTransform {
  transform(value: any): string {
    const category: ReportCategoryType = value.item?.category;
    return `/app/templates/${CategoryRouteMap[category]}`;
  }
}
