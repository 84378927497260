import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FrameworkTaxonomyCategory } from '../../models';

export { FrameworkTaxonomyCategoryTranslations };

const FrameworkTaxonomyCategoryTranslations: Record<FrameworkTaxonomyCategory, string> = {
  [FrameworkTaxonomyCategory.RATING]: _('Ratings'),
  [FrameworkTaxonomyCategory.REGULATION]: _('Regulations'),
  [FrameworkTaxonomyCategory.REPORTING_FRAMEWORK]: _('Reporting Frameworks'),
  [FrameworkTaxonomyCategory.REPORTING_STANDARD]: _('Reporting Standards'),
  [FrameworkTaxonomyCategory.QUESTIONNAIRE_FRAMEWORK]: _('Questionnaire Frameworks'),
};
