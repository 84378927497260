<div
  *ngIf="content.data.length || content.loading; else emptyResultTemplate"
  class="data-list-wrapper mb-2 mt-2"
  data-testid="data-list-remote-scrollable-div"
  (libScrollToBottom)="loadMoreOptions()"
>
  <div
    class="card-container mb-1"
    data-testid="data-list-remote-row"
    *ngFor="let item of content.data"
    (click)="onSelectRow(item)"
    [ngClass]="{ active: content.selected | includes: item }"
  >
    <a class="card presentation-card">
      <div class="card-definition">
        <div class="card-definition-content">
          <div *ngIf="dataSubtitleKey" class="mat-small color-grey-800">{{ item | get: dataSubtitleKey }}</div>
          <div *ngIf="dataTitleKey" class="mat-body-1 color-primary-900">{{ item | get: dataTitleKey }}</div>
          <ng-container *ngTemplateOutlet="dataFooterTemplate; context: { element: item }"></ng-container>
        </div>
      </div>
    </a>
  </div>
  <div *ngIf="content.loading" class="spinner" data-testid="data-list-remote-spinner">
    <lib-spinner [diameter]="20"></lib-spinner>
  </div>
</div>

<ng-template class="empty-wrapper" #emptyResultTemplate>
  <lib-empty-results [displayData]="emptyResult"></lib-empty-results>
</ng-template>
