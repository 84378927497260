<button mat-menu-item class="menu-title" disabled>{{ "Standard codes" | translate }}</button>
<div class="menu-search" (click)="$event.stopPropagation()" *ngIf="control && items.length > minMenuScrollItems">
  <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
  <input
    matInput
    type="text"
    [placeholder]="'Search' | translate"
    autocomplete="off"
    (keydown)="$event.stopPropagation()"
    [formControl]="control"
  />
  <button class="clear-search" *ngIf="control.value" (click)="control.setValue('')">
    <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
  </button>
</div>
<hr />
<button mat-menu-item (click)="setItem.emit()">{{ "All" | translate }}</button>
<div [ngClass]="{ 'menu-items-container': items.length > minMenuScrollItems }">
  <lib-filter-with-category
    *ngIf="filteredItems | async as standardCodes"
    [filterCategoryTranslations]="eStandardCodeCategoryTranslations"
    [filteredOptions]="standardCodes"
    [filterFunction]="filterStandardCodes"
    [activeOptionId]="selectedItemId"
    (optionSelected)="setItem.emit($event)"
  ></lib-filter-with-category>
</div>
