<div class="page">
  <div class="header">
    <mat-icon class="novsito-logo" svgIcon="logo"></mat-icon>
  </div>
  <lib-info-notice
    icon="error"
    [primaryMessage]="errorMessage"
    [secondaryMessage]="'Please contact the person who shared the link with you.' | translate"
    type="error"
  ></lib-info-notice>
</div>
