import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, Report, ReportHistoricalValue } from '../../../models';
import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common';
import { Observable } from 'rxjs';
import pickBy from 'lodash/pickBy';
import { HttpContext } from '@angular/common/http';
import { BYPASS_PUBLIC_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicReportsService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.reports;
  }

  public getReport(id: string, payload?: Record<string, unknown>): Observable<ApiResponse<Report>> {
    const params: Record<string, unknown> = {
      ['load_sections']: payload?.load_sections,
      ['load_indicators']: payload?.load_indicators,
      ['load_value_group_sets']: payload?.load_value_group_sets,
      ['load_value_groups']: payload?.load_value_groups,
      ['load_values']: payload?.load_values,
    };

    return this.apiService.get(`${this.servicePath}${this.resource}/reports/${id}`, {
      params: pickBy(params, (param: unknown) => param != null),
    });
  }

  public getReportHistoricalData(
    reportId: string,
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<ReportHistoricalValue[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/reports/${reportId}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/historical_data`,
      {
        context: new HttpContext().set(BYPASS_PUBLIC_INTERCEPTOR_ERROR_MANAGING, true),
      },
    );
  }

  public getReportHistoricalGroupData(
    reportId: string,
    metricId: string,
    valueDefinitionGroupId: string,
  ): Observable<ApiResponse<ReportHistoricalValue[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/reports/${reportId}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/historical_data"`,
      {
        context: new HttpContext().set(BYPASS_PUBLIC_INTERCEPTOR_ERROR_MANAGING, true),
      },
    );
  }
}
