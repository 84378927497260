import { Injectable } from '@angular/core';
import { ApiResponse, Doc, SearchOptions } from '../../../../models';
import { Observable } from 'rxjs';
import { BaseService } from '../../../common/base/base.service';
import { BaseClientDataRequestsDocumentsService } from './base-client-data-requests-documents.service';

@Injectable({
  providedIn: 'root',
})
export class ClientMetricLibraryDocumentsService extends BaseClientDataRequestsDocumentsService {
  constructor(readonly baseService: BaseService) {
    super(baseService);
  }

  public searchDataRequestDocuments(
    _dataRequestId: string,
    _searchOptions: SearchOptions,
  ): Observable<ApiResponse<Doc[]>> {
    throw new Error('Method not implemented.');
  }
}
