import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NOT_APPLICABLE_NUMERIC_VALUE } from '../../models';

export class ValidatorsUtils {
  static SYSTEM_MAX_NUMBER = 999999999999999;

  static isNotOnlySpaces(control: AbstractControl): ValidationErrors | null {
    return control.value?.length && control.value.trim() === ''
      ? {
          required: true,
        }
      : null;
  }

  static systemMaxNumber(control: AbstractControl): ValidationErrors | null {
    return Number(control.value) > ValidatorsUtils.SYSTEM_MAX_NUMBER ? { systemMax: true } : null;
  }

  static isANumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      isNaN(Number(control.value)) && control.value != NOT_APPLICABLE_NUMERIC_VALUE
        ? { nonNumericError: { value: control.value as number } }
        : null;
  }

  static stringToIntegerValidator(): ValidatorFn {
    return (c: AbstractControl<string>) => (c.value && !/^\d+$/.test(c.value) ? { isNumeric: true } : null);
  }

  static validateUrl(control: AbstractControl<string>): ValidationErrors | null {
    if (control.value && !(control.value.startsWith('https://') || control.value.startsWith('http://'))) {
      return { urlError: true };
    }

    return null;
  }

  static removeHtmlTagsFromString(htmlString: string): string {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || '';
  }

  static validateRichTextMaxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        if (ValidatorsUtils.removeHtmlTagsFromString(String(control.value)).length > maxLength) {
          return { maxlength: true };
        }
      }
      return null;
    };
  }

  static validateRichTextMinLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        if (ValidatorsUtils.removeHtmlTagsFromString(String(control.value)).length < minLength) {
          return { minlength: true };
        }
      }
      return null;
    };
  }
}
