import { Observable } from 'rxjs';
import { FeatureFlagKeys } from './models';

export abstract class FeatureFlagService {
  public abstract init(): void;

  public abstract areAnyFeatureFlagsEnabled(flags: FeatureFlagKeys[]): boolean;

  public abstract areFeatureFlagsLoaded$(): Observable<boolean>;

  public abstract getEnabledFeatureFlags(flags: FeatureFlagKeys[]): FeatureFlagKeys[];
}
