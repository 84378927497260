<div class="card-container simple-text-card-container">
  <div class="simple-text-header">
    {{ cardTitle }}

    <button lib-button *ngIf="actionIcon" (click)="actionClick.emit()" data-testid="card-action-button">
      <mat-icon class="simple-text-icon" [svgIcon]="actionIcon"></mat-icon>
    </button>
  </div>

  <lib-expandable-text [text]="text" [numberOfLineToShow]="numberOfLineToShow"></lib-expandable-text>
</div>
