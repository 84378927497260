import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ApplicationApiDefinition, FieldAuditEventsRequest, FieldAuditEvent } from '../../../models';
import { ApiService } from '../../common/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientFieldAuditEventsService {
  apiName: keyof ApplicationApiDefinition = 'platform';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.platform.resources.global;
  }

  public getEventsForField(fieldAuditRequest: FieldAuditEventsRequest): Observable<ApiResponse<FieldAuditEvent[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/field_audit_events`, fieldAuditRequest);
  }
}
