<ng-container *ngIf="documents$ | async as docs">
  <span class="status-chip" *ngIf="valueDefinition | isDeactivated">{{ "Inactive" | translate }}</span>
  <ng-container *ngTemplateOutlet="documentCard; context: { document: docs | getItemById: documentId }"> </ng-container>
</ng-container>

<ng-template #documentCard let-document="document">
  <a class="card" data-testid="doc-card" [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }">
    <div class="card-content align-center">
      <div class="pr-2 h-100">
        <div class="caption2 color-grey-800 line-clamp">
          <span *ngIf="document?.doc?.category"> {{ document?.doc?.category }}</span>
          <span *ngIf="valueDefinition?.id === eValueDefinitionTemplateType.template">
            {{ "Category" | translate }}</span
          >
        </div>
        <div class="mat-body-1 color-primary-900 text-clamp text-clamp-3 mt-1">
          {{ valueDefinition | documentTitle: document?.name }}
        </div>
      </div>
      <div class="file-icon ml-1" [ngClass]="'file-' + (getDocumentIcon(document?.format) || 'no-fill')">
        <mat-icon [svgIcon]="getDocumentIcon(document?.format)"></mat-icon>
      </div>
    </div>
  </a>
</ng-template>
