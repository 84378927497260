<div class="h-100 fx-col justify-center align-center empty-results">
  <mat-icon
    data-testid="icon"
    aria-hidden="false"
    [attr.aria-label]="displayData?.image ?? 'laptop-neutral'"
    class="icon-xxl color-primary-900"
    [svgIcon]="displayData?.image ?? 'laptop-neutral'"
  ></mat-icon>
  <div class="shadow mt-6"></div>

  <div *ngIf="displayData?.title" class="headline1 color-grey-900 mt-4 title">
    {{ displayData?.title }}
  </div>
  <lib-divider class="w-100 bkg-grey-600 mv-4"></lib-divider>
  <div
    *ngIf="displayData?.subtitle"
    class="headline2 color-grey-900 mt-2 subtitle"
    [libViewerAction]="displayData?.viewerAction"
  >
    {{ displayData?.subtitle }}
  </div>
  <div *ngIf="displayData?.linkText" class="text caption2 color-grey-800 mt-2">
    <lib-link [routerLink]="displayData?.linkAddress" [text]="displayData?.linkText ?? ''"></lib-link>
  </div>
  <a
    *ngIf="displayData?.button"
    data-testid="emptyResultsBtn"
    class="btn mt-3"
    [ngClass]="displayData?.btnTypeCssClass ?? btnClass.success"
    (click)="onBtnClick()"
    [libViewerAction]="displayData?.viewerAction"
    >{{ displayData?.button }}</a
  >
</div>
