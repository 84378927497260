export enum DocumentCategory {
  custom_template_export = 'Custom Template Export',
  framework_template_export = 'Framework Template Export',
  metric_data_export = 'Metric Data Export',
  questionnaire_export = 'Questionnaire Export',
  questionnaire_submission = 'Questionnaire Submission',
  cdp_automated_transfer = 'CDP Automated Transfer',
  benchmark_v2_export = 'Benchmark Export',
  source_comparison_v1_export = 'Source Comparison Export',
  data_table_v2_export = 'Fiscal Year Comparison Export',
}

export enum DocumentType {
  custom_template_export = 'Custom Template Export',
  framework_template_export = 'Framework Template Export',
  questionnaire_export = 'Questionnaire Export',
  sp_xml = 'S&P XML',
  cdp_json = 'CDP JSON',
  benchmark_v2_export = 'Benchmark Export',
  source_comparison_v1_export = 'Source Comparison Export',
  data_table_v2_export = 'Fiscal Year Comparison Export',
}

export enum PlaformDocumentCategory {
  metric_attachment = 'Metric Attachment',
}
