import { Injectable } from '@angular/core';
import { Indicator, ValueGroupSet } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ValueGroupSetService {
  getMatchingValueGroupSet(indicator: Indicator, sourceId?: string): ValueGroupSet {
    if (sourceId) {
      return (
        indicator.value_group_sets?.find((vgset) => vgset.business_unit_id == sourceId) ??
        this.getDefaultVgset(indicator)
      );
    } else {
      return indicator.value_group_sets?.find((vgset) => vgset.consolidated) ?? this.getDefaultVgset(indicator);
    }
  }

  private getDefaultVgset(indicator: Indicator): ValueGroupSet {
    if (indicator.value_group_sets?.length === 1) {
      return indicator.value_group_sets[0];
    }
    throw new Error('No value group sets found');
  }
}
