import { FormUtils } from '../../classes';
import { isChoiceValue } from '../../models';

export function isNullishValue(value: unknown): boolean {
  return (
    value == null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0) ||
    (isChoiceValue(value) && FormUtils.isNullOrEmpty(value.values) && !value.additional_text)
  );
}
