import { Component, AfterViewInit } from '@angular/core';

import { transitions } from '../../animations';

@Component({
  selector: 'lib-module-page',
  templateUrl: './module-page.component.html',
  styleUrls: ['./module-page.component.scss'],
  animations: [transitions],
})
export class ModulePageComponent implements AfterViewInit {
  isVisible: boolean = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isVisible = true;
    });
  }

  getVisibleState() {
    return this.isVisible ? 'visible' : 'invisible';
  }
}
