import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api/api.service';
import { ApplicationApiDefinition, Token } from '../../../models';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TokensService {
  apiName: keyof ApplicationApiDefinition = 'auth';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.auth.resources.auth_tokens;
  }

  refreshToken(token: string): Observable<Token> {
    const headers: HttpHeaders = new HttpHeaders({ 'Content-type': 'application/json' }).append(
      'x-refresh-token',
      token,
    );

    return this.apiService.post(
      `${this.servicePath}${this.resource}/refresh`,
      undefined,
      undefined,
      false,
      undefined,
      headers,
    );
  }

  invalidateTokens(invalidateAllSessions: boolean = false, refreshToken: string): Observable<unknown> {
    const params: object = {
      ['invalidate_all_sessions']: invalidateAllSessions,
    };

    const headers: HttpHeaders = new HttpHeaders({ 'Content-type': 'application/json' }).append(
      'x-refresh-token',
      refreshToken,
    );

    return this.apiService.get(`${this.servicePath}${this.resource}/logout`, {
      params,
      headers,
    });
  }
}
