<div class="grid grid-2 grid-auto-rows">
  <div class="color-grey-800">{{ "Code" | translate }}</div>
  <div class="color-grey-900">{{ metric.code }}</div>
  <ng-container *ngIf="metric?.category === eMetricCategory.THIRD_PARTY">
    <div class="color-grey-800">{{ "Name" | translate }}</div>
    <div class="color-grey-900">{{ metric.description }}</div>
  </ng-container>
  <div class="color-grey-800">{{ "Topics" | translate }}</div>
  <div class="color-grey-900">
    <span *ngFor="let topic of metric.topics; let isLast = last">{{ topic.name }}{{ isLast ? "" : ", " }}</span>
  </div>
  <div *ngIf="!refMetricsV2EnabledFF" class="color-grey-800">{{ "Related to" | translate }}</div>
  <div *ngIf="!refMetricsV2EnabledFF" class="color-grey-900">
    <a
      class="btn btn-xs mr-1 mb-1"
      *ngFor="let related_metric of metric.related_metrics | filter: filterRelatedMetrics"
      [ngClass]="{
        'btn-primary': related_metric.source === eRelatedMetricSource.platform,
        'btn-stroked': related_metric.source === eRelatedMetricSource.shared,
        'btn-primary btn-disabled': !related_metric.source || related_metric.source === eRelatedMetricSource.core,
      }"
    >
      {{ related_metric.equivalent_metric.code }}
    </a>
  </div>
  <div *ngIf="!refMetricsV2EnabledFF" class="color-grey-800">{{ "Compatible with" | translate }}</div>
  <div *ngIf="!refMetricsV2EnabledFF" class="color-grey-900">
    <a
      class="btn btn-xs btn-stroked btn-disabled mr-1 mb-1 enable-pointer-events"
      *ngFor="let code of metric.standard_codes"
    >
      <span libEllipsifyMe [maxCharCount]="35">{{ code.code }}</span>
    </a>
  </div>
</div>
