import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'lib-page-header-subtitles',
  templateUrl: './page-header-subtitles.component.html',
  styleUrls: ['./page-header-subtitles.component.scss'],
})
export class PageHeaderSubtitlesComponent implements OnChanges {
  @Input() subtitles?: string[] | string;
  @Input() useChips: boolean = false;

  innerSubtitles: string[] = [];

  ngOnChanges(): void {
    if (!this.subtitles) {
      this.innerSubtitles = [];
      return;
    }

    this.innerSubtitles = Array.isArray(this.subtitles) ? [...this.subtitles] : [this.subtitles];
  }
}
