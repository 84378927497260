import { Entity, Source } from '../entities';
import { PaginationData } from '../pagination';
import { DataRequestIndicatorLink } from './requests';

export {
  DataRequestSource,
  DataRequestSourceStatus,
  DataRequestSourceWithIndicatorLinks,
  DataRequestUser,
  DataRequestUserResponsibility,
  DataRequestSourceSubmitAction,
  DataRequestSourceSubmission,
  DataRequestParticipantsSelection,
  DataRequestSourceFilteringData,
  DataRequestSourceOrderingData,
  DataRequestSourceFilteringOptions,
  DataRequestSourceSearchData,
  DataRequestSourceSummary,
};

interface DataRequestSource extends Entity {
  status: DataRequestSourceStatus;
  active: boolean;
  business_unit: Source;
  data_request_users: DataRequestUser[];
  prefill_frequency?: string;
  is_prefilling?: boolean;
}

interface DataRequestSourceWithIndicatorLinks extends DataRequestSource {
  indicator_links: DataRequestIndicatorLink[];
}

enum DataRequestSourceStatus {
  NOT_STARTED = 'not_started',
  STARTED = 'started',
  IN_REVIEW = 'in_review',
  IN_APPROVAL = 'in_approval',
  IMPORTED = 'imported',
  IMPORTING = 'importing',
}

enum DataRequestSourceSubmitAction {
  REVIEW = 'review',
  APPROVAL = 'approval',
  IMPORT = 'import',
  REJECT = 'reject',
}

interface DataRequestUser {
  id: string;
  user_id: string;
  responsibility: DataRequestUserResponsibility;
  show_historical_data: boolean;
  active: boolean;
}

enum DataRequestUserResponsibility {
  CONTRIBUTOR = 'contributor',
  REVIEWER = 'reviewer',
  APPROVER = 'approver',
}

interface DataRequestSourceSubmission {
  dataRequestSourceSubmitAction: DataRequestSourceSubmitAction;
  shouldNavigateAfterSubmit: boolean;
  note?: string;
  emailResponsibilities?: DataRequestUserResponsibility[];
  isAutomaticApprove?: boolean;
  signOffMessage?: string;
}

interface DataRequestParticipantsSelection {
  contributors: boolean;
  reviewers: boolean;
  approvers: boolean;
}

interface DataRequestSourceFilteringData {
  fiscal_year?: string;
  source_status?: DataRequestSourceStatus | null;
}

interface DataRequestSourceOrderingData {
  order_by?: 'name' | 'updated';
  order_by_direction?: 'asc' | 'desc';
}
interface DataRequestSourceSearchData {
  search_term?: string;
}

interface DataRequestSourceFilteringOptions {
  search?: DataRequestSourceSearchData;
  filters?: DataRequestSourceFilteringData;
  ordering?: DataRequestSourceOrderingData;
  pagination?: PaginationData;
}

type DataRequestSourceSummary = Pick<
  DataRequestSource,
  | 'id'
  | 'name'
  | 'active'
  | 'status'
  | 'created'
  | 'created_by'
  | 'data_request_users'
  | 'prefill_frequency'
  | 'updated'
  | 'updated_by'
> & {
  indicator_links: DataRequestIndicatorLink[];
};
