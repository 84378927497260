<ng-container *ngIf="presentation === ePresentation.card">
  <a
    *ngIf="mode === 'nav'"
    class="card presentation-card"
    [ngClass]="{ 'with-image': item?.image, disabled: item?.disabled, deactivated: item?.deactivated }"
    [routerLink]="link(item)"
    routerLinkActive="active"
    queryParamsHandling="merge"
    [state]="{ item: item }"
    role="presentation"
  >
    <div *ngIf="item?.msg_count" class="tip">
      <div
        class="tip-text"
        [ngClass]="{ 'tip-text-error': item?.msg_count! > 0, 'tip-text-success': item?.msg_count! === -1 }"
      ></div>
    </div>
    <div class="card-definition">
      <div class="card-definition-content">
        <div class="pr-2">
          <div [ngSwitch]="true">
            <div *ngSwitchCase="item?.item_type === eItemType.sources_source">
              <span [title]="item?.title" class="item-title mat-body-1 color-primary-900 mb-1">{{ item?.title }}</span>
              <span class="item-title mat-body-1 color-grey-800">
                <span class="color-grey-800 ml-1 mr-1">—</span>
                <span>
                  {{ getByKey(sourcesSourceTypes, item?.item.type) | translate }}
                </span>
              </span>
            </div>
            <div *ngSwitchDefault>
              <div [title]="item?.subtitle" class="item-subtitle caption2 color-grey-800">{{ item?.subtitle }}</div>
              <div [title]="item?.title" class="item-title mat-body-1 color-primary-900 mb-1">{{ item?.title }}</div>
            </div>
          </div>
        </div>
        <div
          *ngIf="item?.image"
          [class]="'card-image pattern pattern-' + item?.image!.split(':')[0] + ' color-' + item?.image!.split(':')[1]"
        ></div>
      </div>

      <div [ngSwitch]="true">
        <div *ngSwitchCase="item?.item_type === eItemType.reports_report">
          <div class="card-list-footer-row">
            <div [ngSwitch]="item?.item.status" class="mr-2">
              <div *ngSwitchCase="'draft'">
                <a
                  class="btn bkg-warning-accent color-white btn-sm"
                  *ngIf="item?.item_type === eItemType.reports_report"
                >
                  {{ "In progress" | translate }}
                </a>
              </div>
              <a *ngSwitchCase="'incomplete'" class="btn bkg-warning-accent color-white btn-sm">
                {{ "In progress" | translate }}
              </a>
              <a
                *ngIf="item?.item?.status === 'complete' || item?.item?.status === 'completed'"
                class="btn btn-success disabled fx-row justify-center btn-sm"
                >{{ "Completed" | translate }}</a
              >
              <a *ngSwitchCase="'imported'" class="btn bkg-primary-500 color-white btn-sm">
                {{ "Imported" | translate }}
              </a>
              <a *ngSwitchCase="'deactivated'" class="btn bkg-grey-200 color-grey-900 fx-row justify-center btn-sm">
                {{ "Deactivated" | translate }}
              </a>
              <a *ngSwitchCase="'archived'" class="btn bkg-primary-500 color-white btn-sm">
                {{ "Archived" | translate }}
              </a>
            </div>
            <div class="color-grey-800 mt-1 card-footer-default line-clamp">
              {{ item?.updated ? "Updated " + (item?.updated | timeAgo) : "Never updated" }}
              {{ item?.updated_by }}
            </div>
            <div class="color-grey-800 mt-1 card-footer-hover">
              {{ (item?.updated | date: "medium") || ("Never updated" | translate) }}
            </div>
            <div class="color-grey-800 mt-1 card-footer-active">
              {{ (item?.updated | date: "medium") || ("Never updated" | translate) }}
            </div>
          </div>
        </div>
        <div *ngSwitchCase="item?.item_type === eItemType.reports_template">
          <a class="btn btn-primary mb-1" (click)="selectButton($event, item, 'button', 'Button')">
            {{ "Use template" | translate }}
          </a>
          <div class="color-grey-800">{{ item?.item?.category | titlecase }}</div>
        </div>
        <div
          *ngSwitchCase="
            item?.item_type === eItemType.metrics_indicator ||
            item?.item_type === eItemType.metrics_metric ||
            item?.item_type === eItemType.framework_metric ||
            item?.item_type === eItemType.peer_metric
          "
        >
          <lib-metric-card-footer
            [metricActionItem]="item"
            [limitedCardSize]="limitedCardSize"
            (footerSelect)="emitSelectButtonAction($event)"
          ></lib-metric-card-footer>
        </div>
        <div *ngSwitchCase="item?.item_type === eItemType.peers_peer">
          <div class="color-grey-800 mt-1">
            {{ formatFooterDescription(item) }}
          </div>
        </div>
        <div *ngSwitchCase="item?.item_type === eItemType.users_user">
          <div class="color-grey-800 mt-1">
            {{ formatFooterDescription(item) }}
          </div>
        </div>
        <div *ngSwitchCase="item?.item_type === eItemType.library_peers_peer">
          <div class="color-grey-800 mt-1">
            {{ formatFooterDescription(item) }}
          </div>
        </div>
        <div *ngSwitchCase="item?.item_type === eItemType.data_requests_request">
          <div class="color-grey-800 mt-1">
            <div class="color-{{ getByKey(dataRequestStatusIcon, item?.item.status).color }}">
              <span>{{ getByKey(dataRequestsRequestStatus, item?.item.status) | translate }}</span>
            </div>

            <div class="color-grey-800 mt-2">
              <div class="card-footer-default line-clamp">
                {{
                  item?.updated
                    ? ("Updated {timeAgo}" | translate: { timeAgo: (item?.updated | timeAgo) })
                    : ("Never updated" | translate)
                }}
                {{ item?.updated_by }}
              </div>
              <div class="card-footer-hover">
                {{ (item?.updated | date: "medium") || ("Never updated" | translate) }}
              </div>
              <div class="card-footer-active">
                {{ (item?.updated | date: "medium") || ("Never updated" | translate) }}
              </div>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="item?.item_type === eItemType.sources_source"></div>
        <div
          *ngSwitchCase="
            item?.item_type === eItemType.admin_standard_codes || item?.item_type === eItemType.standard_codes
          "
        >
          <lib-standard-codes-card-footer
            [actionItem]="item"
            [limitedCardSize]="limitedCardSize"
            (footerSelect)="emitSelectButtonAction($event)"
          ></lib-standard-codes-card-footer>
        </div>
        <div *ngSwitchDefault>
          <div class="color-grey-800 mt-1 card-footer-default line-clamp">
            {{
              item?.updated
                ? ("Updated {timeAgo}" | translate: { timeAgo: (item?.updated | timeAgo) })
                : ("Never updated" | translate)
            }}
            {{ item?.updated_by }}
          </div>
          <div class="color-grey-800 mt-1 card-footer-hover">
            {{ (item?.updated | date: "medium") || ("Never updated" | translate) }}
          </div>
          <div class="color-grey-800 mt-1 card-footer-active">
            {{ (item?.updated | date: "medium") || ("Never updated" | translate) }}
          </div>
        </div>
      </div>
    </div>
  </a>
  <div *ngIf="mode === 'drag'" class="card presentation-card" [ngClass]="{ disabled: item?.disabled }">
    <div class="card-drag">
      <div [title]="item?.subtitle" class="item-subtitle mat-small color-grey-800">{{ item?.subtitle }}</div>
      <div [title]="item?.title" class="item-title mat-body-1 color-primary-900">{{ item?.title }}</div>

      <div [ngSwitch]="true">
        <div *ngSwitchCase="item?.item_type === eItemType.selection_set_item"></div>
        <div *ngSwitchCase="item?.item_type === eItemType.unit_group_item"></div>
        <div *ngSwitchCase="item?.item_type === eItemType.metrics_indicator">
          <lib-metric-card-footer
            [metricActionItem]="item"
            [limitedCardSize]="true"
            [hideDate]="true"
          ></lib-metric-card-footer>
        </div>
        <div *ngSwitchDefault>
          <div class="color-grey-800 card-footer-default mt-1">
            {{
              item?.updated
                ? ("Updated {timeAgo}" | translate: { timeAgo: item?.updated | timeAgo })
                : ("Never updated" | translate)
            }}
          </div>
          <div class="color-grey-800 card-footer-hover mt-1">
            {{ (item?.updated | date: "medium") || ("Never updated" | translate) }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <a
    *ngIf="mode === 'select'"
    class="card presentation-card"
    [ngClass]="{ disabled: item?.disabled }"
    (click)="selectItem(item)"
  >
    <div class="card-definition">
      <div [title]="item?.subtitle" class="item-subtitle mat-small color-grey-800">{{ item?.subtitle }}</div>
      <div [title]="item?.title" class="item-title mat-body-1 color-primary-900">{{ item?.title }}</div>
    </div>
  </a>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.doc">
  <a
    class="card presentation-doc"
    *ngIf="mode === 'nav'"
    [routerLink]="link(item)"
    routerLinkActive="active"
    queryParamsHandling="merge"
  >
    <div class="card-content">
      <div class="pr-2 doc-content-text-wrapper">
        <div [title]="item?.title" class="caption2 color-grey-800 mb-1 line-clamp" *ngIf="item?.subtitle">
          {{ item?.subtitle }}&nbsp;
        </div>
        <div [title]="item?.title" class="mat-body-1 color-primary-900 text-clamp text-clamp-3 break-word">
          {{ item?.title }}
        </div>
      </div>
      <div class="file-icon" [ngClass]="'file-' + item?.icon">
        <mat-icon [svgIcon]="item?.icon || ''"></mat-icon>
      </div>
    </div>
    <div class="card-footer">
      <div [title]="item?.text" class="caption2 color-grey-800 line-clamp card-footer-default">
        {{
          item?.updated
            ? ("Updated {timeAgo}" | translate: { timeAgo: (item?.updated | timeAgo) })
            : ("Never updated" | translate)
        }}
        {{ item?.updated_by }}
      </div>
      <div [title]="item?.text" class="caption2 color-grey-800 line-clamp card-footer-hover">
        {{ (item?.updated | date: "medium") || ("Never updated" | translate) }}
      </div>
      <div [title]="item?.text" class="caption2 color-grey-800 line-clamp card-footer-active">
        {{ (item?.updated | date: "medium") || ("Never updated" | translate) }}
      </div>
    </div>
  </a>
  <a
    class="card presentation-doc no-highlight"
    *ngIf="mode === 'select'"
    (click)="selectItem(item)"
    role="button"
    [attr.aria-label]="'card-' + item?.id"
  >
    <div class="card-content">
      <div class="pr-2 doc-content-text-wrapper">
        <div [title]="item?.title" class="caption2 color-grey-800 mb-1 line-clamp" *ngIf="item?.subtitle">
          {{ item?.subtitle }}&nbsp;
        </div>
        <div [title]="item?.title" class="mat-body-1 color-primary-900 text-clamp text-clamp-3 break-word">
          {{ item?.title }}
        </div>
      </div>
      <div class="file-icon" [ngClass]="'file-' + item?.icon">
        <mat-icon [svgIcon]="item?.icon || ''"></mat-icon>
      </div>
    </div>
    <div class="card-footer">
      <div [title]="item?.text" class="caption2 color-grey-800 line-clamp card-footer-default">
        {{
          item?.updated
            ? ("Updated {timeAgo}" | translate: { timeAgo: item?.updated | timeAgo })
            : ("Never updated" | translate)
        }}
        {{ item?.updated_by }}
      </div>
      <div [title]="item?.text" class="caption2 color-grey-800 line-clamp card-footer-hover">
        {{ (item?.updated | date: "medium") || "Never updated" }}
      </div>
      <div [title]="item?.text" class="caption2 color-grey-800 line-clamp card-footer-active">
        {{ (item?.updated | date: "medium") || "Never updated" }}
      </div>
    </div>
  </a>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.highlights">
  <div class="presentation-highlights">
    <a
      class="mat-h3 mb-1"
      [routerLink]="link(item)"
      routerLinkActive="active"
      queryParamsHandling="merge"
      [queryParams]="getQueryParams('')"
    >
      {{ item?.title }}
    </a>
    <div class="mat-small color-grey-800">{{ item?.subtitle }}</div>
    <div class="mt-2">
      <a
        class="card-highlight"
        *ngFor="let highlight of item?.item?.highlight"
        [routerLink]="link(item)"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="getQueryParams(highlight)"
      >
        <div class="highlight" [innerHTML]="highlight | sanitizeHtml"></div>
      </a>
    </div>
  </div>
  <hr />
</ng-container>
