import {
  ConditionalTrigger,
  MetricFieldVisibilityPerFiscalYear,
  TriggerTypeDetails,
  ValueDefinition,
  ValueDefinitionGroup,
} from '../../../models';

export interface ConditionalTriggerDialogData {
  metricId: string;
  sourceValueDefinition: ValueDefinition<TriggerTypeDetails>;
  valueDefinitionGroups: ValueDefinitionGroup[];
  conditionalTrigger?: ConditionalTrigger;
  fieldVisibility: MetricFieldVisibilityPerFiscalYear[];
  isAdmin: boolean;
}

export enum TargetType {
  ValueDefinition = 'ValueDefinition',
  ValueDefinitionGroup = 'ValueDefinitionGroup',
}

export interface TargetOption {
  id: string;
  type: TargetType;
  name: string;
}
