<ng-container>
  <div class="table-info-container">
    <button lib-button buttonType="cancel" class="btn return-to-fields" (click)="backToFields()">
      <mat-icon svgIcon="arrow-head-left"></mat-icon>
      {{ "Fields" | translate }}
    </button>
    <p class="table-title">{{ metricTableDefinition.title }}</p>
    <div class="table-context-wrapper">
      <p class="table-context-columns-title">{{ "Context columns: " | translate }}</p>
      <p class="table-context-columns-content">{{ tableContextColumns }}</p>
    </div>
  </div>
  <lib-data-table-remote-data
    [tableColumns]="tableInputColumns"
    [tableData]="tableInputRows"
    [isPaginable]="false"
    (selectRow)="selectMetricTableColumnDefinition($event)"
    [showRowSelection]="true"
  >
  </lib-data-table-remote-data>
</ng-container>
