import { HttpBackend, HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiService } from '../api/abstract-api.service';

export abstract class PublicApiService extends AbstractApiService {
  constructor(
    private http: HttpClient,
    httpBackend: HttpBackend,
  ) {
    super(httpBackend);
  }

  getProtocol(): string {
    return 'https://';
  }

  getUrl(path: string): string {
    return path;
  }

  get<T = any>(path: string, options?: object): Observable<T> {
    const url = this.getUrl(path);
    return options ? this.http.get<T>(url, options) : this.http.get<T>(url);
  }

  post<T = any>(
    path: string,
    payload?: any,
    params?: HttpParams,
    _bypassInterceptors?: boolean,
    _context?: HttpContext,
    headers?: HttpHeaders,
  ): Observable<T> {
    return this.http.post<T>(this.getUrl(path), payload ?? null, {
      params,
      headers: headers ?? this.defaultHeaders.headers,
    });
  }

  put<T>(
    _path: string,
    _payload: any,
    _params?: HttpParams | undefined,
    _bypassInterceptors?: boolean | undefined,
    _context?: HttpContext | undefined,
  ): Observable<T> {
    throw new Error('Method not implemented.');
  }
  patch<T>(_path: string, _payload: any, _bypassInterceptors?: boolean | undefined): Observable<T> {
    throw new Error('Method not implemented.');
  }

  delete<T>(
    _path: string,
    _payload?: any,
    _params?: HttpParams | undefined,
    _bypassInterceptors?: boolean | undefined,
    _context?: HttpContext | undefined,
  ): Observable<T> {
    throw new Error('Method not implemented.');
  }
}
