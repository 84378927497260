import { Pipe, PipeTransform } from '@angular/core';
import { Metric, MetricCategory, ValueDefinition } from '../../../models';

@Pipe({
  name: 'isValueDefinitionDragDisabled',
})
export class IsValueDefinitionDragDisabledPipe implements PipeTransform {
  transform(valueDefinition: ValueDefinition, metric?: Metric, isCore?: boolean | null): boolean {
    if (isCore) {
      return false;
    } else {
      return (
        metric?.category === MetricCategory.THIRD_PARTY ||
        valueDefinition.active === false ||
        Boolean(
          metric?.category === MetricCategory.REFERENCE &&
            metric.reference_v2 &&
            valueDefinition.core_value_definition_id,
        )
      );
    }
  }
}
