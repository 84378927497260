export class ConsolidationUtils {
  static areBypassLevelsChanged(initialBypassLevels?: number[] | null, currentBypassLevels?: number[] | null): boolean {
    if (
      (!initialBypassLevels || initialBypassLevels.length === 0) &&
      (!currentBypassLevels || currentBypassLevels.length === 0)
    ) {
      return false;
    }
    if (JSON.stringify(initialBypassLevels?.sort()) !== JSON.stringify(currentBypassLevels?.sort())) {
      return true;
    }
    return false;
  }
}
