<ng-container *ngIf="form">
  <lib-slide-toggle
    *ngIf="form.controls.isEditing.value"
    class="formfield mb-3"
    [control]="form.controls.active"
    [label]="'Active' | translate"
  ></lib-slide-toggle>

  <lib-select-input
    class="formfield"
    [label]="'Category' | translate"
    [control]="form.controls.categoryId"
    [options]="optionListCategories"
  ></lib-select-input>

  <lib-select-input
    *ngIf="withTypeField"
    class="formfield"
    [label]="'Type' | translate"
    [control]="form.controls.type"
    [options]="optionListTypes"
  ></lib-select-input>

  <lib-slide-toggle
    *ngIf="withPublicField"
    class="formfield mb-3"
    [control]="form.controls.public"
    [label]="'Public' | translate"
  ></lib-slide-toggle>

  <lib-text-input
    class="formfield"
    [control]="form.controls.name"
    [label]="'Name' | translate"
    [messages]="{ isUnique: 'Name must be unique.' | translate }"
  ></lib-text-input>

  <lib-text-area-input
    class="formfield mb-2"
    [control]="form.controls.description"
    [label]="'Description' | translate"
  ></lib-text-area-input>

  <lib-text-area-input
    class="formfield"
    [control]="form.controls.explanationLabel"
    [label]="'Explanation text field' | translate"
    [placeholder]="'Please explain' | translate"
  ></lib-text-area-input>
</ng-container>
