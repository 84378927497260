import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableColumn, TableActionMenuItemEvent, TableHorizontalPaginatorConfiguration } from '../../../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[lib-table-header-row]',
  templateUrl: './table-header-row.component.html',
  styleUrls: ['../table-grid.component.scss'],
})
export class TableHeaderRowComponent<T> {
  @Input({ required: true }) columns: TableColumn<T>[] = [];
  @Input() isExpandable = false;
  @Input() isResizable = false;
  @Input() isRowReorderable = false;
  @Input() withHeader: boolean = true;
  @Input() actionMenuItems?: TableActionMenuItemEvent<T>[];
  @Input() horizontalPaginationConfig?: TableHorizontalPaginatorConfiguration;

  @Output() horizontalPageChanged: EventEmitter<number> = new EventEmitter<number>();

  public horizontalPageChange(change: 1 | -1): void {
    if (this.horizontalPaginationConfig) {
      this.horizontalPageChanged.emit(this.horizontalPaginationConfig.currentIndex + change);
    }
  }
}
