<div mat-dialog-title class="mb-0 fx-col">
  <div class="fx-row justify-space-between">
    <h3 class="mat-h3 mat-primary mb-2">{{ "Option list" | translate }}</h3>
    <a (click)="closeDialog()" data-testid="closeIcon">
      <mat-icon svgIcon="close"></mat-icon>
    </a>
  </div>
</div>

<div mat-dialog-content class="panel-dialog full-width bkg-grey-50">
  <lib-options-list-edit-table
    [editable]="true"
    [options]="choices"
    (add)="onAdd($event)"
    (edit)="onEdit($event)"
    (delete)="onDelete($event)"
    (move)="onMove($event)"
  ></lib-options-list-edit-table>
</div>

<div mat-dialog-actions>
  <button
    lib-button
    [buttonType]="'success'"
    class="btn btn-success"
    (click)="closeDialog()"
    [disabled]="!isTouched || !choices.length"
  >
    {{ "Done" | translate }}
  </button>
</div>
