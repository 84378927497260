import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActionItem, DashboardDataPoint, DashboardWidget, FiscalYear } from '../../models';
import { FiscalYearsUtilService, TranslateService } from '../../services/common';

@Component({
  selector: 'lib-single-data-point-widget',
  templateUrl: './single-data-point-widget.component.html',
  styleUrls: ['./single-data-point-widget.component.scss'],
})
export class SingleDataPointWidgetComponent implements OnInit, OnChanges {
  @Input({ required: true }) widget?: DashboardWidget;
  @Input({ required: true }) fiscalYearsItem: ActionItem<FiscalYear>[] = [];
  @Input() showEditMenu?: boolean = false;
  @Input() metricDetailsUrl: string = '';

  @Output() remove: EventEmitter<DashboardWidget> = new EventEmitter<DashboardWidget>();
  @Output() edit: EventEmitter<DashboardWidget> = new EventEmitter<DashboardWidget>();

  dataPoint?: DashboardDataPoint;
  variation: number = 0;
  sourceName: string = '';
  year: string = '';

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.dataPoint = this.widget?.data_point_widget?.data_points[0];

    if (this.dataPoint?.show_previous_year) {
      this.variation = this.calculateVariation(this.dataPoint);
    }
  }

  ngOnChanges(): void {
    this.setYear();
    this.setSourceName();
  }

  setSourceName(): void {
    if (!this.widget) {
      return;
    }

    this.sourceName = this.widget.widget_sources[0].source.name;
  }

  setYear(): void {
    if (this.widget?.data_point_widget?.fiscal_year_id) {
      this.year =
        this.fiscalYearsItem.find(
          (fiscalYearItem) => fiscalYearItem.item?.id === this.widget?.data_point_widget?.fiscal_year_id,
        )?.title ?? this.translateService.instant('Unknown fiscal year');
    } else {
      this.year = FiscalYearsUtilService.getLatestFiscalYear(this.fiscalYearsItem).title;
    }
  }

  removeItem(item: DashboardWidget): void {
    this.remove.emit(item);
  }

  editItem(item: DashboardWidget): void {
    this.edit.emit(item);
  }

  calculateVariation(dataPoint: DashboardDataPoint): number {
    if (dataPoint.value && dataPoint.previous_year_value) {
      const value = Number(dataPoint.value.value);
      const previousYearValue = Number(dataPoint.previous_year_value.value);

      // dont want a division by 0
      if (previousYearValue === 0) {
        return 0;
      }

      const difference = value - previousYearValue;

      // if they are the same value, there is no variation
      if (difference === 0) {
        return 0;
      }

      const calculatedVariation = (difference / previousYearValue) * 100;

      return Number(calculatedVariation.toFixed(2));
    }

    return 0;
  }
}
