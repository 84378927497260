import { Directive, HostListener, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ValueFormControl } from '../../metric-editor-form/models/valueFormControl';

@Directive({
  selector: '[libFocusState]',
})
export class FocusStateDirective {
  @Input() formControl?: UntypedFormControl | ValueFormControl;
  @Input() focusStateControl?: UntypedFormControl | ValueFormControl;

  @HostListener('focus') onFocusMaterial() {
    this.onFocus();
  }

  @HostListener('blur') onBlurMaterial() {
    this.onBlur();
  }

  @HostListener('onFocus') onFocusPrimeNg() {
    this.onFocus();
  }

  @HostListener('onBlur') onBlurPrimeNg() {
    this.onBlur();
  }

  private onFocus() {
    if (this.activeControl instanceof ValueFormControl) {
      this.activeControl.markAsFocused();
    }
  }

  private onBlur() {
    if (this.activeControl instanceof ValueFormControl) {
      this.activeControl.markAsUnfocused();
    }
  }

  private get activeControl(): UntypedFormControl | ValueFormControl | undefined {
    return this.focusStateControl != null ? this.focusStateControl : this.formControl;
  }
}
