import { trigger, transition, style, query, animate, animateChild, group } from '@angular/animations';

const animationDuration: string = '500ms';

export const routeAnimations = trigger('routeAnimations', [
  transition('1 => 2, 1 => 3, 1 => 4, 2 => 3, 2 => 4, 3 => 4', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }),
    ]),
    query(':enter', [style({ left: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate(`${animationDuration} ease-out`, style({ left: '-100%' }))]),
      query(':enter', [animate(`${animationDuration} ease-out`, style({ left: '0%' }))]),
    ]),
    query(':enter', animateChild()),
  ]),
  transition('4 => 1, 4 => 2, 4 => 3, 3 => 1, 3 => 2, 2 => 1', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [style({ left: '-100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate(`${animationDuration} ease-out`, style({ left: '100%' }))]),
      query(':enter', [animate(`${animationDuration} ease-out`, style({ left: '0%' }))]),
    ]),
    query(':enter', animateChild()),
  ]),
]);
