import { Pipe, PipeTransform } from '@angular/core';
import { MetricUtils } from '../../../../../classes';
import { Metric } from '../../../../../models';

@Pipe({
  name: 'showPublishButton',
})
export class ShowPublishButtonPipe implements PipeTransform {
  transform(metric: Metric, isAdmin: boolean, published?: string): boolean {
    return isAdmin && MetricUtils.isRefV2OrTPM(metric) && !published;
  }
}
