import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'areSelected',
})
export class AreSelectedPipe implements PipeTransform {
  transform<T>(items: T[], selections: Record<string, boolean | T>, key: keyof T, onlySome: boolean = false): boolean {
    let numberSelected: number = 0;

    for (const item of items) {
      if (selections[String(item[key])]) {
        numberSelected += 1;
      }
    }

    if (onlySome) {
      return numberSelected > 0 && numberSelected < items.length;
    }
    return numberSelected >= items.length;
  }
}
