<div class="value-definition-target-widget-card" *ngIf="widget">
  <button
    *ngIf="showEditMenu"
    lib-button
    class="action-button"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ item: widget }"
    data-testid="widgetContextMenu"
  >
    <mat-icon svgIcon="more"></mat-icon>
  </button>

  <lib-target-chart
    [valueDefinitionTarget]="widget.value_definition_target_widget!.value_definition_target!"
    [title]="widget.metric.code"
    [metricDetailsUrl]="metricDetailsUrl"
    [subtitle]="widget.label"
    [showChartMenu]="!showEditMenu"
    [dynamicWidth]="true"
  ></lib-target-chart>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="removeItem(item)">
      <mat-icon svgIcon="trash"></mat-icon>
      <span>{{ "Remove" | translate }}</span>
    </button>
    <button mat-menu-item (click)="editItem(item)">
      <mat-icon svgIcon="edit"></mat-icon>
      <span>{{ "Edit" | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
