<button
  lib-button
  buttonType="color_link"
  class="btn btn-sm"
  tabindex="-1"
  (click)="handleFocusedFieldAction($event, eFocusedFieldAction.add_comment)"
>
  {{ "Comment" | translate }}
</button>

<div
  *ngIf="!rejectButtonState.hide"
  [matTooltip]="rejectButtonState.tooltip ?? ''"
  [matTooltipDisabled]="!rejectButtonState.tooltip"
  matTooltipPosition="above"
>
  <button
    lib-button
    buttonType="danger"
    class="btn btn-sm"
    tabindex="-1"
    [disabled]="rejectButtonState.disabled"
    (click)="handleFocusedFieldAction($event, eFocusedFieldAction.reject_value)"
  >
    {{ "Reject" | translate }}
  </button>
</div>
