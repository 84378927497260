import { AbstractControl, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import {
  READONLY_VALUE_DEFINITION_TYPES,
  TextTypeDetails,
  TypeDetails,
  Validator,
  Value,
  ValueDefinitionType,
} from '../../models';
import { UpsertValue } from './upsertValue';
import { BehaviorSubject, Subject } from 'rxjs';
import { UpdateOnType } from './updateOnType';
import { WaitableForNextUpdate } from './waitableForNextUpdate';
import { ValueGroupSetForm } from './valueGroupSetForm';
import { DateValidators } from '../../validators';
import { ValidatorsUtils } from '../../classes';

export interface ResetValueOptions {
  shouldPrompt?: boolean;
}

export class ValueFormControl<T extends TypeDetails = any> extends UntypedFormControl implements WaitableForNextUpdate {
  public valueRef: Value<T>;
  private _resetValue$: Subject<ResetValueOptions | undefined> = new Subject<ResetValueOptions | undefined>();
  private _isFocused: boolean = false;
  private _isWaitingForNextUpdate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _focusValue$: BehaviorSubject<Value | undefined> = new BehaviorSubject<Value | undefined>(undefined);
  readonly valueResets = this._resetValue$.asObservable();

  public readonly isWaitingForNextUpdate$ = this._isWaitingForNextUpdate$.asObservable();
  public readonly focusValue$ = this._focusValue$.asObservable();

  constructor(initialValueRef: Value<T>, updateOn: UpdateOnType = 'blur') {
    super(initialValueRef.value, { validators: ValueFormControl.getValidators(initialValueRef), updateOn });
    this.valueRef = initialValueRef;
  }

  public get isFocused(): boolean {
    return this._isFocused;
  }

  public updateValue(value: Value): void {
    this.handleFocusOnNewId(this.valueRef, value);
    this.valueRef = value;
    this._isWaitingForNextUpdate$.next(false);
    if (!this.isFocused) {
      this.setValue(this.valueRef.value, { onlySelf: true, emitEvent: false });
    }
  }

  private handleFocusOnNewId(oldValue: Value, newValue: Value): void {
    if (!oldValue.id && !!newValue.id) {
      this.setFocusValue(newValue);
    }
  }

  public resetValue(options?: ResetValueOptions): void {
    this._resetValue$.next({ shouldPrompt: true, ...options });
  }

  public toUpsertValue(value?: unknown, parentValueRef?: Value): UpsertValue {
    return {
      id: this.valueRef.id,
      value_definition_id: this.valueRef.value_definition_id,
      value: value ?? this.value,
      fiscal_year_period_id: this.valueRef.fiscal_year_period?.id,
      year_to_date_value_id: parentValueRef ? parentValueRef.id : undefined,
    };
  }

  public markAsFocused(): void {
    this._isFocused = true;
    this.setFocusValue(this.valueRef);
  }

  private setFocusValue(value: Value): void {
    this._focusValue$.next(value);
  }

  public markAsUnfocused(): void {
    this._isFocused = false;
  }

  public waitForNextUpdate(): void {
    this._isWaitingForNextUpdate$.next(true);
  }

  public isConsolidated(): boolean {
    const rootControl = this.root;

    if (this.isValueGroupSetForm(rootControl)) {
      return rootControl.isConsolidated();
    }
    return false;
  }

  public businessUnitLevel(): number | undefined {
    const rootControl = this.root;

    if (this.isValueGroupSetForm(rootControl)) {
      return rootControl.businessUnitLevel();
    }
    return undefined;
  }

  private isValueGroupSetForm(control: AbstractControl): control is ValueGroupSetForm {
    return 'valueGroupSet' in control;
  }

  private static getValidators(value: Value): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (READONLY_VALUE_DEFINITION_TYPES.includes(value.type)) {
      return validators;
    }

    if (value.required) {
      validators.push(Validators.required);
    }

    value.validators?.forEach((validator: Validator) => {
      switch (validator.validator_type) {
        case 'max_length':
          if (value.type === ValueDefinitionType.text && (value.type_details as TextTypeDetails).rich_text) {
            validators.push(ValidatorsUtils.validateRichTextMaxLength(parseInt(String(validator.instructions))));
          } else {
            validators.push(Validators.maxLength(parseInt(String(validator.instructions))));
          }
          break;

        case 'min_length':
          if (value.type === ValueDefinitionType.text && (value.type_details as TextTypeDetails).rich_text) {
            validators.push(ValidatorsUtils.validateRichTextMinLength(parseInt(String(validator.instructions))));
          } else {
            validators.push(Validators.minLength(parseInt(String(validator.instructions))));
          }
          break;

        case 'min_val':
          validators.push(Validators.min(parseFloat(String(validator.instructions))));
          break;

        case 'max_val':
          validators.push(Validators.max(parseFloat(String(validator.instructions))));
          break;

        case 'max_date_range':
          validators.push(DateValidators.maxDateRange(String(validator.instructions)));
          break;

        case 'min_date_range':
          validators.push(DateValidators.minDateRange(String(validator.instructions)));
          break;
      }
    });
    return validators;
  }
}
