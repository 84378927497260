import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DisplayedValue, ValueDefinitionDisplayType } from '../../../../models';

export type DisplayedValueForm = {
  valueDefinitionId: FormControl<string>;
  label: FormControl<string | null>;
  overriddenLabel: FormControl<string | null>;
  isChecked: FormControl<boolean>;
  type: FormControl<ValueDefinitionDisplayType>;
};

export class TemplateReportStructureDisplayedValueForm extends FormGroup<DisplayedValueForm> {
  constructor(
    displayedValue: DisplayedValue,
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      overriddenLabel: fb.control(displayedValue.overridden_label, { nonNullable: false }),
      label: fb.control(displayedValue.label, { nonNullable: false }),
      isChecked: fb.control(displayedValue.is_checked, { nonNullable: true }),
      valueDefinitionId: fb.control(displayedValue.value_definition_id, { nonNullable: true }),
      type: fb.control(displayedValue.type, { nonNullable: true }),
    });
  }
}
