import { Component, Input, Renderer2 } from '@angular/core';
import { map, take } from 'rxjs';
import { Doc, ResourceType, SearchOptions } from '../../../../../models';
import { ActionItemUtils } from '../../../../../classes';
import { BaseClientDataRequestsDocumentsService } from '../../../../../services/client/client-data-requests/data-requests-documents/base-client-data-requests-documents.service';
import { DocumentSearchDirective } from '../document-search.directive';
import { ClientPublicDataRequestsDocumentsService } from '../../../../../services/client/client-data-requests/data-requests-documents/public-client-data-requests-documents.service';
import { BaseService } from '../../../../../services/common';

@Component({
  selector: 'lib-data-requests-doc-search',
  templateUrl: './data-requests-document-search.component.html',
  styleUrls: ['./data-requests-document-search.component.scss'],
})
export class DataRequestsDocumentSearchComponent extends DocumentSearchDirective {
  @Input() dataRequestId?: string;
  @Input() canShowStatusFilter?: boolean;

  constructor(
    readonly renderer: Renderer2,
    private dataRequestsDocumentService: BaseClientDataRequestsDocumentsService,
    public baseService: BaseService,
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    // TODO Will be refactor with NF-12611
    const isPublic = this.dataRequestsDocumentService instanceof ClientPublicDataRequestsDocumentsService;

    if (this.dataRequestId) {
      if (isPublic) {
        this.searchFilterConfig = {
          docs_doc: {
            year: false,
            source: false,
            category: true,
            type: true,
            tag: false,
            status: this.canShowStatusFilter ?? false,
          },
        };
      } else {
        this.searchFilterConfig = {
          docs_doc: {
            year: true,
            source: true,
            category: true,
            type: true,
            tag: true,
            status: this.canShowStatusFilter ?? false,
          },
        };
      }
    }
  }

  search(): void {
    if (!this.dataRequestId) {
      return;
    }

    if (this.searchOptions) {
      const searchOptions = <SearchOptions>JSON.parse(this.searchOptions);

      this.noData = !Object.keys(searchOptions.filters).length && !searchOptions.query.keywords;
      this.loaded = false;
      this.dataRequestsDocumentService
        .searchDataRequestDocuments(this.dataRequestId, searchOptions)
        .pipe(
          take(1),
          map((x) => ActionItemUtils.responseToCollection<Doc>(x, ResourceType.docs_doc)),
        )
        .subscribe((result) => {
          this.itemCollection = result;
          if (!this.itemCollection.items.length || this.itemCollection.items.length >= this.itemCollection.count) {
            this.hideSpinner();
          } else {
            // wait 0.5s for the dummies to fade out
            // spinner element is not available for observer before items become visible
            setTimeout(() => {
              this.scrollObserver.observe(this.spinner.nativeElement);
            }, 500);
          }
          this.loaded = true;
        });
    }
  }

  loadMore(): void {
    if (!this.dataRequestId || !this.dataRequestsDocumentService) {
      return;
    }

    const searchOptions = <SearchOptions>JSON.parse(this.searchOptions);
    searchOptions.from = this.itemCollection!.items.length;
    this.dataRequestsDocumentService
      .searchDataRequestDocuments(this.dataRequestId, searchOptions)
      .pipe(
        take(1),
        map((x) => ActionItemUtils.responseToCollection<Doc>(x, ResourceType.docs_doc)),
      )
      .subscribe((result) => {
        this.itemCollection!.items = this.itemCollection!.items.concat(result.items);
        if (this.itemCollection!.items.length >= this.itemCollection!.count) {
          this.scrollObserver.disconnect();
          this.hideSpinner();
        }
      });
  }
}
