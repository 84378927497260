import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { ApiService } from '../services/common/api/api.service';
import { ApplicationApiDefinition } from '../models';
import { Message } from './models';

@Injectable({
  providedIn: 'root',
})
export class WebSocketChannelService {
  apiName: keyof ApplicationApiDefinition = 'messaging';
  baseUrl: string;

  constructor(private readonly apiService: ApiService) {
    const servicePath = apiService.getServicePath(this.apiName);
    const resource = apiService.apiConfig.apis.messaging.resources.websockets;
    this.baseUrl = `${apiService.getWebsocketBaseUrl()}${servicePath}${resource}`;
  }

  createWebSocketFromTicket(ticket: string): WebSocketSubject<Message<unknown>> {
    const url = `${this.baseUrl}/channel?channel_ticket=${ticket}`;

    return this.createWebSocket(url);
  }

  private createWebSocket(url: string): WebSocketSubject<Message<unknown>> {
    return webSocket(url);
  }
}
