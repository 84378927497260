<div class="table-paginator">
  <p-paginator
    [first]="firstPageItemRelativeIndex"
    [rows]="_pageSize"
    [totalRecords]="_totalItemCount ?? 0"
    [rowsPerPageOptions]="PAGINATION_ROWS_PER_PAGE"
    (onPageChange)="changePage($event)"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="'Showing {first} - {last} of {total} entries' | translate: paginationTranslationParams"
    [showPageLinks]="true"
    [showFirstLastIcon]="true"
  >
  </p-paginator>
</div>
