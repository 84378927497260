import { Component, Input, OnInit } from '@angular/core';
import { LimitedUser, User } from '../../../models';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertsService, TranslateService } from '../../../services/common';

@Component({
  selector: 'lib-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['../cards.component.scss', './user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
  @Input({ required: true }) user!: User | LimitedUser;

  public jobPosition: string = '';

  constructor(
    private clipboard: Clipboard,
    private alertsService: AlertsService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setJobPosition();
  }

  public copyEmail(userEmail: string): void {
    this.clipboard.copy(userEmail);
    this.alertsService.success(this.translateService.instant("The participant's email was copied to the clipboard"));
  }

  private setJobPosition(): void {
    let jobPosition = '';

    if (this.user.title) {
      jobPosition = jobPosition.concat(this.user.title);
    }

    if (this.user.title && this.user.department) {
      jobPosition = jobPosition.concat(' | ');
    }

    if (this.user.department) {
      jobPosition = jobPosition.concat(this.user.department);
    }
    this.jobPosition = jobPosition;
  }
}
