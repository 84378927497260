import { Pipe, PipeTransform } from '@angular/core';
import { ValueGroup } from '../../models';

@Pipe({
  name: 'displayValueGroupLabel',
})
export class DisplayValueGroupLabelPipe implements PipeTransform {
  transform(valueGroupRef: ValueGroup): boolean {
    return (valueGroupRef.values ?? []).length > 0 && !!valueGroupRef.show_label_as_subtitle;
  }
}
