import { Pipe, PipeTransform } from '@angular/core';
import { MetricEditorFormGroup } from '../models/metricEditorFormGroup';
import { TableFormGroup } from '../models/tableFormGroup';

@Pipe({
  name: 'isTableFormGroup',
})
export class IsTableFormGroupPipe implements PipeTransform {
  transform(formGroup: MetricEditorFormGroup): formGroup is TableFormGroup {
    return formGroup instanceof TableFormGroup;
  }
}
