import { Pipe, PipeTransform } from '@angular/core';
import { MetricTableCalculationTypeDetailFormula } from '../../models';

@Pipe({
  name: 'metricTableTotalFormula',
})
export class MetricTableTotalFormulaPipe implements PipeTransform {
  transform(tableCalculationTypeDetailFormula: MetricTableCalculationTypeDetailFormula): string {
    let formula = '';

    formula += tableCalculationTypeDetailFormula.columns.join(' AND ');

    if (tableCalculationTypeDetailFormula.columns.length > 1) {
      formula = `${tableCalculationTypeDetailFormula.operation.toUpperCase()}=(${formula})`;
    } else {
      formula = `${tableCalculationTypeDetailFormula.operation.toUpperCase()}=${formula}`;
    }
    if (tableCalculationTypeDetailFormula.filters.length) {
      const formulaFilters = tableCalculationTypeDetailFormula.filters
        .map((filter) => `(${filter.column}=${filter.values.join(' OR ')})`)
        .join(' AND ');

      formula += ` WHERE ${formulaFilters}`;
    }

    return formula;
  }
}
