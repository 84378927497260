import { Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'th[lib-horizontal-resizable]',
  templateUrl: './horizontal-resizable.component.html',
  styleUrls: ['./horizontal-resizable.component.scss'],
})
export class HorizontalResizableComponent {
  @Input('lib-horizontal-resizable') isResizable: boolean = false;

  @HostBinding('style.width.px') width: number | string | null = 'auto';

  public onResize(width: number) {
    this.width = width;
  }
}
