import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import { ApplicationApiDefinition, ItemType } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class AdminAssetsService {
  apiName: keyof ApplicationApiDefinition = 'documents';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
    private http: HttpClient,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.documents.resources.assets;
  }

  getDoc(itemType: ItemType, storageFilename: string): Observable<any> {
    let category = '';
    switch (itemType) {
      case ItemType.docs_doc:
        category = 'documents';
        break;
      case ItemType.library_peers_report:
        category = 'resources';
        break;
      default:
        throw new Error(`Document category not supported: ${itemType}`);
    }
    const apiUrl: string = this.apiService.getBaseUrl();
    return this.http.get(`${apiUrl}${this.servicePath}/${this.resource}/${category}/v1/${storageFilename}`, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    });
  }

  uploadDocument(formData: FormData, reportProgress = false): Observable<any> {
    return this.apiService.upload(`${this.servicePath}/${this.resource}/resources/v1/`, formData, reportProgress);
  }
}
