import { MetricUtils } from '../../../classes';
import {
  Metric,
  isValueDefinition,
  isValueDefinitionGroup,
  MetricFieldVisibilityPerFiscalYear,
  MetricTableDefinition,
  MetricTableColumnDefinition,
  MetricTableColumn,
} from '../../../models';
import { MetricStructureSelectable, MetricTableGroup } from '../../models';

export interface VisibleFieldIds {
  valueDefinitionIds: string[];
  metricTableDefinitionIds: string[];
}
export class MetricStructureUtils {
  public static isEntityFromCoreRefV2Metric(
    metricStructureSelectable?: MetricStructureSelectable,
    metric?: Metric,
  ): boolean {
    if (metric && MetricUtils.isRefV2Metric(metric)) {
      return (
        (isValueDefinition(metricStructureSelectable) && Boolean(metricStructureSelectable.core_value_definition_id)) ||
        (isValueDefinitionGroup(metricStructureSelectable) &&
          Boolean(metricStructureSelectable.core_value_definition_group_id))
      );
    }
    return false;
  }

  public static getFieldIdsVisibleInAtLeastOneFy(
    fieldVisibilityPerFy: MetricFieldVisibilityPerFiscalYear[],
  ): VisibleFieldIds {
    let visibleVdIds: string[] = [];
    let visibleMtdIds: string[] = [];
    fieldVisibilityPerFy.forEach((fvByFy) => {
      visibleVdIds = visibleVdIds.concat(
        fvByFy.field_visibility
          .filter((fv) => fv.value_definition_id && !visibleVdIds.includes(fv.value_definition_id) && fv.visibility)
          .map((fv) => String(fv.value_definition_id)),
      );

      visibleMtdIds = visibleMtdIds.concat(
        fvByFy.field_visibility
          .filter(
            (fv) =>
              fv.metric_table_definition_id && !visibleMtdIds.includes(fv.metric_table_definition_id) && fv.visibility,
          )
          .map((fv) => String(fv.metric_table_definition_id)),
      );
    });
    return { valueDefinitionIds: visibleVdIds, metricTableDefinitionIds: visibleMtdIds };
  }

  public static isCoreTableOfRefV2OrTPMInPlatform(
    metric: Metric,
    table: MetricTableGroup | MetricTableDefinition | undefined,
    isCore: boolean,
  ): boolean {
    if (isCore || !table) {
      return false;
    }

    return MetricUtils.isRefV2OrTPM(metric) && !!table.core_metric_table_definition_id;
  }

  public static isCoreTableColumnOfRefV2OrTPMInPlatform(
    metric: Metric,
    column: MetricTableColumnDefinition | MetricTableColumn | undefined,
    isCore: boolean,
  ): boolean {
    if (isCore || !column) {
      return false;
    }

    return MetricUtils.isRefV2OrTPM(metric) && !!column.core_metric_table_column_definition_id;
  }
}
