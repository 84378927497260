import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-list-more-with-tooltip-menu',
  templateUrl: './list-more-with-tooltip-menu.component.html',
  styleUrls: ['./list-more-with-tooltip-menu.component.scss'],
})
export class ListMoreWithTooltipMenuComponent {
  @Input() textItem: string = '';
  @Input() additionalTextItems: string[] = [];
}
