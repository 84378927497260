import { User } from '../entities';
import { PaginationData } from '../pagination';

export enum UserGroupStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface UserGroup {
  id: string;
  name: string;
  description: string;
  active: boolean;
  users: User[];
}

export interface UserGroupsFilteringOptions extends PaginationData {
  search_term?: string;
  active?: boolean | null;
}

export interface UserGroupRequest {
  name: string;
  description: string;
}

export interface UserGroupNameCheck {
  available: boolean;
}
