import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableComponent } from './data-table/data-table.component';
import { MaterialModule } from '../material/material.module';
import { CdkModule } from '../material/cdk.module';
import { ComponentsModule } from '../components';
import { RouterModule } from '@angular/router';
import { SearchModule } from '../search';
import { DataTableRemoteDataComponent } from './data-table-remote-data/data-table-remote-data.component';
import { ExpandedRowDirective } from './data-table-remote-data/directives/expanded-row.directive';
import { DataTableExpandedRowComponent } from './data-table-remote-data/data-table-expanded-row/data-table-expanded-row.component';
import { DirectivesModule } from '../directives';
import { DataTableEmptyResultsComponent } from './empty-result/data-table-empty-results.component';
import { PipesModule } from '../pipes';
import { HorizontalResizableComponent } from './data-table-remote-data/resize/horizontal-resizable/horizontal-resizable.component';
import { HorizontalResizableDirective } from './data-table-remote-data/resize/horizontal-resizable/horizontal-resizable.directive';
import { VerticalResizableComponent } from './data-table-remote-data/resize/vertical-resizable/vertical-resizable.component';
import { VerticalResizableDirective } from './data-table-remote-data/resize/vertical-resizable/vertical-resizable.directive';
import { VerticalResizableContentComponent } from './data-table-remote-data/resize/vertical-resizable/vertical-resizable-content/vertical-resizable-content.component';
import { SortableHorizontalResizableComponent } from './data-table-remote-data/resize/horizontal-resizable/sortable-horizontal-resizable/sortable-horizontal-resizable.component';
import { AreSelectedPipe } from './data-table-remote-data/pipes/are-selected.pipe';

@NgModule({
  declarations: [
    DataTableComponent,
    DataTableRemoteDataComponent,
    ExpandedRowDirective,
    DataTableExpandedRowComponent,
    DataTableEmptyResultsComponent,
    HorizontalResizableComponent,
    HorizontalResizableDirective,
    SortableHorizontalResizableComponent,
    VerticalResizableComponent,
    VerticalResizableDirective,
    VerticalResizableContentComponent,
    AreSelectedPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    CdkModule,
    ComponentsModule,
    TranslateModule,
    RouterModule,
    SearchModule,
    DirectivesModule,
    PipesModule,
  ],
  exports: [DataTableComponent, DataTableRemoteDataComponent],
})
export class DataTableModule {}
