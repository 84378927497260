import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, UntypedFormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { SearchService } from '../../../search';
import { ActionItem, MetricCategory, SearchOptionFilters, SearchOptions } from '../../../models';
import { RequireMatch } from '../../../classes';

@Component({
  selector: 'lib-metric-selector-bar',
  templateUrl: './metric-selector-bar.component.html',
  styleUrls: ['./metric-selector-bar.component.scss'],
})
export class MetricSelectorBarComponent implements OnInit, OnDestroy {
  @Input() referenceV2?: boolean;
  @Input() category?: MetricCategory;
  @Output() handleChange: EventEmitter<string> = new EventEmitter<string>();

  public inputControl: FormControl = new FormControl('');

  itemType: string = 'metrics_template';
  searchOptions: SearchOptions;
  minMenuScrollItems: number = 10;

  filters: {
    [key: string]: {
      items: ActionItem[];
      filteredItems?: Observable<ActionItem[]>;
      control?: UntypedFormControl;
    };
  } = {};

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;

  private inputSubscription: Subscription;
  private readonly DEBOUNCE_TIME = 500;
  private readonly referenceV2Filter = (referenceV2: boolean | undefined = this.referenceV2) => ({
    id: 'referenceV2',
    title: 'referenceV2',
    item: referenceV2 ?? false,
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
  ) {
    this.registerItems(['framework'], false);
    this.registerItems(['topic', 'industry'], true);
    this.searchOptions = {
      item_type: this.itemType,
      query: {
        keywords: '',
      },
      filters: {},
    };

    this.inputSubscription = this.inputControl.valueChanges.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe(() => {
      this.emitChanges();
    });
  }

  public get filterCount(): number {
    return Object.keys(this.searchOptions.filters).filter((x) => x !== 'category').length;
  }

  // Register items and controls

  registerItems(keys: string[], registerControl: boolean): void {
    for (const key of keys) {
      this.registerItem(key, registerControl);
    }
  }

  registerItem(key: string, registerControl: boolean): void {
    this.filters[key] = {
      items: [],
    };
    if (registerControl) {
      this.filters[key].control = new UntypedFormControl('', [RequireMatch]);
      this.filters[key].filteredItems = this.filters[key].control!.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.title)),
        map((value) => this.searchService.filterItems(this.filters[key].items, String(value))),
      );
    }
  }

  // Lifecycle events

  ngOnInit(): void {
    if (this.category) {
      this.searchOptions.filters.category = {
        id: this.category,
        title: this.category,
      };
    }
    this.searchOptions.filters.referenceV2 = this.referenceV2Filter();
    this.emitChanges();
  }

  ngOnDestroy(): void {
    this.inputSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.initFilters();
  }

  initItem(key: string): void {
    this.filters[key].items = [];
    if (this.filters[key].control) {
      this.filters[key].control!.setValue('');
    }
  }

  initFilters(): void {
    for (const item of ['topic', 'industry', 'framework']) {
      this.initItem(item);
      this.searchService.searchResources(item, this.itemType).subscribe((items) => {
        this.filters[item].items = items;
        if (this.category === MetricCategory.THIRD_PARTY) {
          if (item === 'framework') {
            this.filters[item].items = items.filter((x) => ['cdp', 'sp'].includes(String(x.item.code)));
          }
        }
      });
    }
  }

  resetFilters(): void {
    for (const key of ['topic', 'industry']) {
      this.filters[key].control?.setValue('');
    }
    this.searchOptions.filters = { referenceV2: this.referenceV2Filter() };
    if (this.category) {
      this.searchOptions.filters.category = {
        id: this.category,
        title: this.category,
      };
    }

    this.emitChanges();
  }

  // Set items and emit changes

  emitChanges(): void {
    this.handleChange.emit(JSON.stringify(this.searchOptions));
  }

  search(): void {
    this.emitChanges();
  }

  clearSearch(): void {
    this.searchOptions.query.keywords = '';
    this.search();
  }

  setItem(key: keyof SearchOptionFilters, item?: ActionItem): void {
    if (item && item.id) {
      this.searchOptions.filters[key] = item;
    } else {
      delete this.searchOptions.filters[key];
    }
    this.filters[key].control?.setValue('');
    this.emitChanges();
  }

  applyItem(key: keyof SearchOptionFilters, event?: MouseEvent): void {
    if (event) {
      if (this.filters[key].control!.invalid) {
        event.stopPropagation();
      }
    }
    const val = this.filters[key].control!.value;
    if (val === '') {
      delete this.searchOptions.filters[key];
      this.emitChanges();
    } else if (val && typeof val === 'object' && val.id) {
      this.searchOptions.filters[key] = val;
      this.emitChanges();
    }
  }

  applyItems(keys: (keyof SearchOptionFilters)[], event?: MouseEvent): void {
    if (event) {
      for (const key of keys) {
        if (this.filters[key].control!.invalid) {
          event.stopPropagation();
        }
      }
    }
    for (const key of keys) {
      const val = this.filters[key].control!.value;
      if (val === '') {
        delete this.searchOptions.filters[key];
      } else if (val && typeof val === 'object' && val.id) {
        this.searchOptions.filters[key] = val;
      }
    }
    this.emitChanges();
  }

  applySort(item: ActionItem): void {
    if (item.id !== this.searchOptions.sort?.id) {
      this.searchOptions.sort = item;
      this.emitChanges();
    }
  }
}
