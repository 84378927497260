import { Component, Input } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActionItem, ResourceType } from '../models';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientTagsService } from '../services/client';
import { SearchService } from '../search';
import { TranslateService } from '../services/common';

@Component({
  selector: 'lib-tags-multi-select-input',
  templateUrl: './tags-multi-select-input.component.html',
  styleUrls: ['./tags-multi-select-input.component.scss'],
})
export class TagsMultiSelectInputComponent {
  @Input({ required: true }) control!: UntypedFormControl;
  @Input() hint: string = this.translateService.instant('Start typing to see tags, hit enter to create a new tag');

  tagOptions$: Observable<ActionItem[]> = of([]);

  readonly tagMaxLength: number = 60;
  readonly messages: ValidationErrors = {
    maxlength: this.translateService.instant('{maxLength} characters maximum', { maxLength: this.tagMaxLength }),
  };

  constructor(
    private tagsService: ClientTagsService,
    private searchService: SearchService,
    private translateService: TranslateService,
  ) {
    this.tagOptions$ = this.searchService.searchResources(ResourceType.tag);
  }

  public addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value === '') {
      return;
    }
    this.tagsService.createTag(value.trim()).subscribe((result) => {
      const tag: ActionItem = {
        id: result.data.id,
        title: result.data.label,
        item: result.data,
      };
      this.tagOptions$ = zip(this.tagOptions$, of(tag)).pipe(map(([options, newTag]) => [...options, newTag]));
      this.control.setValue([...((this.control.value as ActionItem[] | null) ?? []), tag]);
    });
  }
}
