import { ItemType } from '../../models';
import { DataRequestInfo } from './dataRequestInfo';

export interface DocumentContext {
  itemType: ItemType;
  dataRequestInfo?: DataRequestInfo;
}

export const DEFAULT_DOCUMENT_CONTEXT: DocumentContext = {
  itemType: ItemType.metrics_indicator,
};
