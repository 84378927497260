import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PlatformIntegrationType } from '../../models';

export { IntegrationsLabel };

const IntegrationsLabel = {
  [PlatformIntegrationType.S_AND_P]: _('S&P Global'),
  [PlatformIntegrationType.GREEN_PROJECT]: _('Green Project'),
  [PlatformIntegrationType.EDCI]: _('EDCi'),
  [PlatformIntegrationType.TABLEAU]: _('Tableau'),
  [PlatformIntegrationType.CDP]: _('CDP'),
  [PlatformIntegrationType.MINIMUM]: _('Minimum'),
};
