import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { FiscalYearPeriod, Period } from '../models';

export class FiscalYearPeriodBuilder {
  private static monthPeriodCounter = 1;
  private static quarterPeriodCounter = 1;
  private readonly _fiscalYearPeriod: FiscalYearPeriod;

  constructor(periodType: 'month' | 'quarter' = 'quarter') {
    const baseDate =
      periodType === 'month'
        ? dayjs(`2023-04-01`).add(FiscalYearPeriodBuilder.monthPeriodCounter - 1, 'month')
        : dayjs(`2023-04-01`).add((FiscalYearPeriodBuilder.quarterPeriodCounter - 1) * 3, 'month');
    const endDate = periodType === 'month' ? baseDate.endOf('month') : baseDate.add(3, 'month').endOf('month');

    this._fiscalYearPeriod = {
      id: uuidv4(),
      name: 'a_period',
      start: baseDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD'),
      period: periodType === 'month' ? this.getNextMonthPeriod() : this.getNextQuarterPeriod(),
      fiscal_year_id: uuidv4(),
      created: new Date(),
      updated: new Date(),
      active: true,
    };
  }

  private getNextMonthPeriod(): Period {
    const period = `M${FiscalYearPeriodBuilder.monthPeriodCounter}` as Period;
    FiscalYearPeriodBuilder.monthPeriodCounter += 1;
    return period;
  }

  private getNextQuarterPeriod(): Period {
    const period = `Q${FiscalYearPeriodBuilder.quarterPeriodCounter}` as Period;
    FiscalYearPeriodBuilder.quarterPeriodCounter = (FiscalYearPeriodBuilder.quarterPeriodCounter % 4) + 1;
    return period;
  }

  static resetPeriods() {
    FiscalYearPeriodBuilder.quarterPeriodCounter = 1;
    FiscalYearPeriodBuilder.monthPeriodCounter = 1;
  }

  id(id: string): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.id = id;
    return this;
  }

  name(name: string): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.name = name;
    return this;
  }

  start(start: string): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.start = start;
    return this;
  }

  end(end: string): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.end = end;
    return this;
  }

  period(period: Period): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.period = period;
    return this;
  }

  fiscalYearId(fiscalYearId: string): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.fiscal_year_id = fiscalYearId;
    return this;
  }

  created(created: Date | string): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.created = created;
    return this;
  }

  createdBy(createdBy: string): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.created_by = createdBy;
    return this;
  }

  updated(updated: Date | string): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.updated = updated;
    return this;
  }

  updatedBy(updatedBy: string): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.updated_by = updatedBy;
    return this;
  }

  active(active: boolean): FiscalYearPeriodBuilder {
    this._fiscalYearPeriod.active = active;
    return this;
  }

  build(): FiscalYearPeriod {
    return this._fiscalYearPeriod;
  }
}
