export class ConditionalTriggerUtils {
  static compareValues(valueA?: string, valueB?: string, operator?: string): boolean {
    if (!valueA || !valueB) {
      return false;
    }

    const a: number = Number(valueA);
    const b: number = Number(valueB);

    switch (operator) {
      case '<':
        return a < b;
      case '≤':
        return a <= b;
      case '≥':
        return a >= b;
      case '>':
        return a > b;
      case '=':
        return a === b;
      default:
        return false;
    }
  }
}
