import { Injectable } from '@angular/core';
import { DataRequestToolPanelVisibilityType, ItemType } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class FileUploadSelectorService {
  public canShowDocumentSelector(
    publicFileVisibility: DataRequestToolPanelVisibilityType,
    privateFileVisibility: DataRequestToolPanelVisibilityType,
    itemType: ItemType,
  ): boolean {
    if (
      publicFileVisibility === DataRequestToolPanelVisibilityType.NONE &&
      privateFileVisibility === DataRequestToolPanelVisibilityType.NONE
    ) {
      return false;
    }

    if (
      publicFileVisibility === DataRequestToolPanelVisibilityType.ALL &&
      privateFileVisibility === DataRequestToolPanelVisibilityType.ALL
    ) {
      return true;
    }

    const accessibleVisibilityTypes = [
      DataRequestToolPanelVisibilityType.INTERNAL_ONLY,
      DataRequestToolPanelVisibilityType.ALL,
    ];
    if (
      itemType === ItemType.data_requests_request &&
      accessibleVisibilityTypes.includes(publicFileVisibility) &&
      accessibleVisibilityTypes.includes(privateFileVisibility)
    ) {
      return true;
    }

    if (itemType === ItemType.public_data_requests_request) {
      if (
        publicFileVisibility === DataRequestToolPanelVisibilityType.ALL ||
        privateFileVisibility === DataRequestToolPanelVisibilityType.ALL
      ) {
        return true;
      }

      if (
        [DataRequestToolPanelVisibilityType.NONE, DataRequestToolPanelVisibilityType.INTERNAL_ONLY].includes(
          publicFileVisibility,
        )
      ) {
        return false;
      }
      return true;
    }

    return true;
  }

  public canShowStatusFilter(
    publicFileVisibility: DataRequestToolPanelVisibilityType,
    privateFileVisibility: DataRequestToolPanelVisibilityType,
    itemType: ItemType,
  ): boolean {
    if (
      publicFileVisibility === DataRequestToolPanelVisibilityType.NONE &&
      privateFileVisibility === DataRequestToolPanelVisibilityType.NONE
    ) {
      return false;
    }

    if (
      publicFileVisibility === DataRequestToolPanelVisibilityType.ALL &&
      privateFileVisibility === DataRequestToolPanelVisibilityType.ALL
    ) {
      return true;
    }

    if (itemType === ItemType.public_data_requests_request) {
      return false;
    }

    const visibleVisbibilityOptions = [
      DataRequestToolPanelVisibilityType.ALL,
      DataRequestToolPanelVisibilityType.INTERNAL_ONLY,
    ];
    if (
      visibleVisbibilityOptions.includes(publicFileVisibility) &&
      visibleVisbibilityOptions.includes(privateFileVisibility)
    ) {
      return true;
    }

    return false;
  }
}
