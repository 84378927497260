import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from '../../common/api/api.service';
import { ApiResponse, ApplicationApiDefinition, ListFavoriteOrgsRequest, Org, SearchOptions } from '../../../models';
import { BaseClientPeersService } from './base-client-peers.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ClientPeersService extends BaseClientPeersService {
  apiName: keyof ApplicationApiDefinition = 'platform';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    super();

    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.platform.resources.global;
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Org[]>> {
    return this.listFavoriteOrgs({
      filters: { industries: searchOptions.filters.industry?.id ? [searchOptions.filters.industry.id] : undefined },
      search_term: searchOptions.query.keywords,
    });
  }

  listFavoriteOrgs(listFavoriteOrgsRequest?: ListFavoriteOrgsRequest): Observable<ApiResponse<Org[]>> {
    const params = this.getlistFavoriteOrgsHttpParams(listFavoriteOrgsRequest);

    return this.apiService.get(`${this.servicePath}${this.resource}/favorite_orgs`, { params });
  }

  listFavoriteOrgsWithValue(report_id: string, industry_id?: string, name?: string): Observable<ApiResponse<Org[]>> {
    let params = new HttpParams();
    if (industry_id) {
      params = params.append('industry_ids', industry_id);
    }
    if (name) {
      params = params.append('search_term', name);
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/favorite_orgs/with_values/${report_id}`, {
      params,
    });
  }

  public getFavoriteOrg(org_id: string) {
    return this.apiService.get(`${this.servicePath}${this.resource}/favorite_orgs/${org_id}`);
  }

  public addFavoriteOrg(org_id: string): Observable<ApiResponse<Org[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/favorite_orgs/${org_id}`, null);
  }

  public removeFavoriteOrg(org_id: string): Observable<ApiResponse<Org[]>> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/favorite_orgs/${org_id}`);
  }
}
