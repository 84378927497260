import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { SearchOptions, AdminUserRoles } from '../../../models';

import { UsersManagementService, CacheService, AuthService, ApiService } from '../../common';

const AllowedUserRolesInSearch: string[] = [
  AdminUserRoles.editor,
  AdminUserRoles.admin,
  AdminUserRoles.analyst,
  AdminUserRoles.dataManager,
];

@Injectable({
  providedIn: 'root',
})
export class AdminUsersService extends UsersManagementService {
  constructor(apiService: ApiService, cacheService: CacheService, auth: AuthService) {
    super(apiService, cacheService, auth);
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): HttpParams {
    let params = new HttpParams();
    if (searchOptions.query.keywords) {
      params = params.append('query', searchOptions.query.keywords);
    }
    if (searchOptions.filters.role) {
      params = params.append('roles', searchOptions.filters.role.id);
    } else {
      AllowedUserRolesInSearch.forEach((role) => {
        params = params.append('roles', role);
      });
    }
    if (searchOptions.filters.status) {
      params = params.append('active', searchOptions.filters.status.id === 'active');
    }
    if (searchOptions.sort?.id === 'name') {
      params = params.append('order_by', 'first_name');
      params = params.append('order_by_direction', 'asc');
    } else {
      params = params.append('order_by', 'updated');
      params = params.append('order_by_direction', 'desc');
    }
    return params;
  }
}
