import { Component, Input } from '@angular/core';

import { Presentation } from '../../../models';

@Component({
  selector: 'lib-card-empty',
  templateUrl: './card-empty.component.html',
  styleUrls: ['./card-empty.component.scss'],
})
export class CardEmptyComponent {
  @Input({ required: true }) presentation?: Presentation;
  @Input() link: string = '';
  @Input() linkText: string = '';

  ePresentation = Presentation;
}
