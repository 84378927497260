import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EmptyResults, TableColumn } from '../../models';
import { TranslateService } from '../../services/common';
import {
  DataTableExpandedRowConfig,
  DataTableHeaderConfig,
  DataTableHorizontalPaginatorConfiguration,
  DataTablePaginatorConfiguration,
  DEFAULT_PAGE_SIZE,
  PAGINATION_OPTIONS,
} from './models';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'lib-data-table-remote-data',
  templateUrl: './data-table-remote-data.component.html',
  styleUrls: ['./data-table-remote-data.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataTableRemoteDataComponent<T> implements OnInit {
  public readonly SELECTED_ROW_PROPERTY = '__isSelected';

  @Input() tableColumns: TableColumn<any>[] = [];

  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  @Input() defaultEmptyValue = '';
  @Input() isSortable = false;
  @Input() isExpandable = false;
  @Input() isChildren = false;
  @Input() isPaginable = true;
  @Input() expandAllOption = true;
  @Input() selectAllOption = true;
  @Input() headerConfig: DataTableHeaderConfig = { items: [] };
  @Input() paginationConfig: DataTablePaginatorConfiguration = {
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
    currentPage: 0,
  };
  @Input() horizontalPaginationConfig?: DataTableHorizontalPaginatorConfiguration;
  @Input() defaultPaginationOptions: number[] = PAGINATION_OPTIONS;
  @Input() emptyResult: EmptyResults = {
    title: this.translateService.instant('No result found'),
  };
  @Input() expandedRowConfig?: DataTableExpandedRowConfig;
  @Input() enableRowSelection = false;
  @Input() isShowingLoadingAnimation = false;
  @Input() showRowSelection = false;
  @Input() isResizable: boolean = false;
  @Input() withHeader: boolean = true;
  @Input() selectionDataKey: string = 'id';
  @Input() selections: Record<string, boolean | T> = {};
  @Input() rowSpans: Record<string, Record<string, number> | undefined> = {};
  @Input() withPadding: boolean = true;

  @Output() sortChanged: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() selectRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() horizontalPageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() checkChanged = new EventEmitter<{ all?: boolean; selection?: string }>();
  @Output() scrolledToBottom: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatPaginator) matPaginator?: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort?: MatSort;

  public tableDataSource = new MatTableDataSource<any>([]);
  public headerColumns: string[] = [];
  public displayedColumns: string[] = [];

  public isAllRowsExpanded: boolean = false;
  enforcedRowExpansion: Set<string> = new Set();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.headerColumns = this.headerConfig.items.map((header) => this.headerKey(header.key));
    this.displayedColumns = this.tableColumns.map((tableColumn: TableColumn<any>) => this.columnKey(tableColumn));

    if (this.isExpandable) {
      this.displayedColumns.unshift('expandable');
    }

    if (this.enableRowSelection) {
      this.displayedColumns.unshift('check');
    }
  }

  persistIsExpandedValue(data: any[], previous_data: MatTableDataSource<any>): any[] {
    if (data.length === 0 || !('isExpanded' in data[0]) || previous_data['filteredData'].length === 0) {
      return data;
    }
    const previousExpandedValues: { [id: string]: any } = {};

    previous_data['filteredData'].forEach((rowData) => {
      previousExpandedValues[rowData.id] = rowData.isExpanded;
    });

    return data.reduce((acc_rows, current_row) => {
      const newRow = { ...current_row };
      newRow['isExpanded'] = previousExpandedValues[current_row['id']];
      acc_rows = acc_rows.concat(newRow);
      return acc_rows;
    }, []);
  }

  setTableDataSource(data: any[]): void {
    const inputData: any[] = this.persistIsExpandedValue(data, this.tableDataSource);
    this.tableDataSource = new MatTableDataSource<any>(inputData);

    if (this.matSort) {
      this.tableDataSource.sort = this.matSort;
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  sortTable(sortParameters: Sort): void {
    sortParameters.active =
      (this.tableColumns.find((column: TableColumn<any>) => column.name === sortParameters.active)
        ?.dataKey as string) ?? '';
    this.sortChanged.emit(sortParameters);
  }

  onPageChanged(pageEvent: PageEvent): void {
    this.pageChanged.emit(pageEvent);
  }

  onSelectRow(row: any): void {
    if (this.showRowSelection) {
      this.tableDataSource.data.forEach((item) => {
        item[this.SELECTED_ROW_PROPERTY] = false;
      });
      row[this.SELECTED_ROW_PROPERTY] = true;
    }

    if (!this.isExpandable) {
      this.selectRow.emit(row);
    }

    this.enforcedRowExpansion.add(row['id'] as string);
  }

  public isExpansionEnforced(id: string): boolean {
    return this.enforcedRowExpansion.has(id);
  }

  public isRowExpanded(row: any): boolean {
    if (this.isExpansionEnforced(row['id'] as string)) {
      return row.isExpanded;
    } else {
      return this.isAllRowsExpanded;
    }
  }

  public headerKey(headerKey: string): string {
    return `header-${headerKey}`;
  }

  public columnKey(tableColumn: TableColumn<any>): string {
    return String(
      tableColumn.key ?? (typeof tableColumn.dataKey === 'string' ? tableColumn.dataKey : tableColumn.name),
    );
  }

  public trackId = (i: number, tableColumn: TableColumn<any>): string => this.columnKey(tableColumn);

  public checkToggle(selection: string) {
    this.checkChanged.emit({ selection });
  }

  public horizontalPagechange(change: 1 | -1): void {
    if (this.horizontalPaginationConfig) {
      this.horizontalPageChanged.emit(this.horizontalPaginationConfig.currentIndex + change);
    }
  }

  public masterToggle(event: MatCheckboxChange) {
    this.checkChanged.emit({ all: event.checked });
  }

  public toggleAllRowsExpanded() {
    this.isAllRowsExpanded = !this.isAllRowsExpanded;
    this.enforcedRowExpansion.clear();
  }

  public indeterminateSelection([key, selection]: [string, boolean | T], rowKey?: string): boolean {
    return Boolean(selection && rowKey && key.startsWith(rowKey));
  }
}
