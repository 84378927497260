import { isMetricTableGroup, ValueDefinitionGroup } from '../../../models';
import { MetricTableGroup } from '../../models';

export class ValueDefinitionGroupUtils {
  public static isGroupOrTableFromCore(groupOrTable: ValueDefinitionGroup | MetricTableGroup): boolean {
    if (isMetricTableGroup(groupOrTable)) {
      return Boolean(groupOrTable.core_metric_table_definition_id);
    }
    return Boolean(groupOrTable.core_value_definition_group_id);
  }
}
