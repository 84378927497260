import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkModule } from '../material/cdk.module';
import { MaterialModule } from '../material/material.module';
import { ComponentsModule } from '../components';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directives';
import { MetricEditorFormComponent } from './metric-editor-form.component';
import { MetricEditorFieldHandlerComponent } from './components/metric-editor-field-handler/metric-editor-field-handler.component';
import { MetricEditorTextFieldComponent } from './components/metric-editor-text-field/metric-editor-text-field.component';
import { MetricEditorGroupHandlerComponent } from './components/metric-editor-group-handler/metric-editor-group-handler.component';
import { MetricEditorTipComponent } from './components/metric-editor-tip/metric-editor-tip.component';
import { IconsModule } from '../icons/icons.module';
import { MetricEditorSubtitleComponent } from './components/metric-editor-subtitle/metric-editor-subtitle.component';
import { MetricEditorTextAreaFieldComponent } from './components/metric-editor-text-area-field/metric-editor-text-area-field.component';
import { MetricEditorDocumentLinkComponent } from './components/metric-editor-document-link/metric-editor-document-link.component';
import { MetricEditorChoiceFieldComponent } from './components/metric-editor-choice-field/metric-editor-choice-field.component';
import { MetricEditorCheckboxFieldComponent } from './components/metric-editor-choice-field/metric-editor-checkbox-field/metric-editor-checkbox-field.component';
import { MetricEditorRadioFieldComponent } from './components/metric-editor-choice-field/metric-editor-radio-field/metric-editor-radio-field.component';
import { MetricEditorFileAttachmentComponent } from './components/metric-editor-file-attachment/metric-editor-file-attachment.component';
import { MetricEditorAttachFileDialogComponent } from './components/metric-editor-file-attachment/metric-editor-attach-file-dialog/metric-editor-attach-file-dialog.component';
import { MetricEditorBooleanComponent } from './components/metric-editor-boolean/metric-editor-boolean.component';
import { MetricEditorNumericFieldComponent } from './components/metric-editor-numeric-field/metric-editor-numeric-field.component';
import { MetricEditorDateFieldComponent } from './components/metric-editor-date-field/metric-editor-date-field.component';
import { TranslateModule } from '@ngx-translate/core';
import { MetricEditorRichTextComponent } from './components/metric-editor-rich-text/metric-editor-rich-text.component';
import { MetricEditorMetricLinkComponent } from './components/metric-editor-metric-link/metric-editor-metric-link.component';
import { MetricEditorTableHandlerComponent } from './components/metric-editor-table-handler/metric-editor-table-handler.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { IsValueGroupFormGroupPipe } from './pipes/is-value-group-form-group.pipe';
import { IsTableFormGroupPipe } from './pipes/is-table-form-group.pipe';
import { RepeatableGroupsCountPipe } from './pipes/repeatable-groups-count.pipe';
import { MetricEditorTableLabelFieldComponent } from './components/metric-editor-table-handler/metric-editor-table-label-field/metric-editor-table-label-field.component';
import { ValueDefinitionFieldSizePipe } from './pipes/value-definition-field-size.pipe';
import { DisplayValueGroupLabelPipe } from './pipes/display-value-group-label.pipe';
import { SearchModule } from '../search';
import { DialogsModule } from '../dialogs';
import { PanelsModule } from '../panels';
import { IsActiveFieldDirective } from './directives/isActiveField/is-active-field.directive';
import { TableTotalFormulaPipe } from './pipes/table-total-formula/table-total-formula.pipe';
import { MetricEditorFileAttachmentV2Component } from './components/metric-editor-file-attachment-v2/metric-editor-file-attachment-v2.component';
import { MetricEditorFileCardComponent } from './components/metric-editor-file-attachment-v2/metric-editor-file-card/metric-editor-file-card.component';
import { FindFileDocumentPipe } from './pipes/find-file-document/find-file-document.pipe';
import { MetricEditorFileUploadCardComponent } from './components/metric-editor-file-attachment-v2/metric-editor-file-upload-card/metric-editor-file-upload-card.component';
import { FileLabelPipe } from './pipes/file-label/file-label.pipe';
import { FeatureFlagModule } from '../feature-flag';
import { FieldActionsComponent } from './components/metric-editor-field-handler/field-actions/field-actions.component';
import { FieldCommentCounterComponent } from './components/metric-editor-field-handler/field-comment-counter/field-comment-counter.component';
import { MetricEditorErrorDialogComponent } from './components/metric-editor-error-dialog/metric-editor-error-dialog.component';
import { DocumentCardComponent } from './components/metric-editor-file-attachment/document-search/document-card/document-card.component';
import { DataRequestsDocumentSearchComponent } from './components/metric-editor-file-attachment/document-search/data-requests-document-search/data-requests-document-search.component';
import { MetricsLibraryDocumentSearchComponent } from './components/metric-editor-file-attachment/document-search/metrics-library-document-search/metrics-library-document-search.component';
import { IsFileGroupDeletablePipe } from './pipes/is-file-group-deletable.pipe';
import { IsShowAddGroupButtonPipe } from './pipes/is-show-add-group-button.pipe';
import { MetricEditorFrequencyFieldComponent } from './components/metric-editor-frequency-field/metric-editor-frequency-field.component';
import { ScrollerModule } from 'primeng/scroller';
import { IsCalculatedFieldInRepeatableGroup } from './pipes/is-calculated-field-in-repeatable-group/is-calculated-field-in-repeatable-group.pipe';
import { MetricEditorFrequencyHandlerComponent } from './components/metric-editor-frequency-handler/metric-editor-frequency-handler.component';
import { FrequencyFieldLabelPipe } from './components/metric-editor-frequency-field/pipes/frequency-field-label.pipe';
import { FrequencyFieldPeriodPipe } from './components/metric-editor-frequency-field/pipes/frequency-field-period.pipe';
import { FrequencyEnabledPipe } from './components/metric-editor-group-handler/pipes/frequency-enabled.pipe';

@NgModule({
  declarations: [
    MetricEditorFormComponent,
    MetricEditorFieldHandlerComponent,
    MetricEditorTextFieldComponent,
    MetricEditorGroupHandlerComponent,
    MetricEditorTipComponent,
    MetricEditorSubtitleComponent,
    MetricEditorTextAreaFieldComponent,
    MetricEditorDocumentLinkComponent,
    MetricEditorChoiceFieldComponent,
    MetricEditorCheckboxFieldComponent,
    MetricEditorFileAttachmentComponent,
    MetricEditorAttachFileDialogComponent,
    MetricEditorBooleanComponent,
    MetricEditorNumericFieldComponent,
    MetricEditorDateFieldComponent,
    MetricEditorRadioFieldComponent,
    MetricEditorRichTextComponent,
    MetricEditorMetricLinkComponent,
    MetricEditorTableHandlerComponent,
    MetricEditorFrequencyFieldComponent,
    IsValueGroupFormGroupPipe,
    IsTableFormGroupPipe,
    RepeatableGroupsCountPipe,
    MetricEditorTableLabelFieldComponent,
    ValueDefinitionFieldSizePipe,
    IsActiveFieldDirective,
    TableTotalFormulaPipe,
    MetricEditorFileAttachmentV2Component,
    MetricEditorFileCardComponent,
    FindFileDocumentPipe,
    MetricEditorFileUploadCardComponent,
    FileLabelPipe,
    DisplayValueGroupLabelPipe,
    FieldActionsComponent,
    FieldCommentCounterComponent,
    MetricEditorErrorDialogComponent,
    DataRequestsDocumentSearchComponent,
    MetricsLibraryDocumentSearchComponent,
    DocumentCardComponent,
    IsFileGroupDeletablePipe,
    IsShowAddGroupButtonPipe,
    IsCalculatedFieldInRepeatableGroup,
    MetricEditorFrequencyHandlerComponent,
    FrequencyFieldLabelPipe,
    FrequencyFieldPeriodPipe,
    FrequencyEnabledPipe,
  ],
  imports: [
    CommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdkModule,
    MaterialModule,
    IconsModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    GridModule,
    SearchModule,
    DialogsModule,
    PanelsModule,
    FeatureFlagModule,
    ComponentsModule,
    ScrollerModule,
  ],
  exports: [MetricEditorFormComponent],
  providers: [DecimalPipe],
})
export class MetricEditorFormModule {}
