import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { map } from 'rxjs';

import { OptionListsApiService } from '../../services/api-services';
import { OptionList, OptionListType, UpsertOptionListRequest } from '../../models';
import { UniquenessValidator, WhitespaceValidators } from '../../validators';

type OptionListFormModel = {
  active: FormControl<boolean>;
  description: FormControl<string>;
  explanationLabel: FormControl<string>;
  categoryId: FormControl<string | null>;
  isEditing: FormControl<boolean>;
  name: FormControl<string>;
  public: FormControl<boolean>;
  type: FormControl<OptionListType | null>;
};

export class OptionListForm extends FormGroup<OptionListFormModel> {
  constructor(
    readonly optionListsApiService: OptionListsApiService,
    withTypeField: boolean,
    optionList?: OptionList,
    fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      active: fb.control(optionList?.active ?? true, { nonNullable: true }),
      categoryId: fb.control(
        optionList?.selection_set_category_id || optionList?.core_option_list_category_id || null,
        { validators: [Validators.required] },
      ),
      description: fb.control(optionList?.description || '', {
        nonNullable: true,
        validators: [Validators.maxLength(255)],
      }),
      explanationLabel: fb.control(optionList?.explanation_label || 'Please explain', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(255)],
      }),
      isEditing: fb.control(Boolean(optionList), { nonNullable: true }),
      name: fb.control(optionList?.name || '', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(200), WhitespaceValidators.hasOnlyWhitespace()],
        asyncValidators: UniquenessValidator.validate(
          (value: string) => optionListsApiService.checkOptionListName(value).pipe(map((res) => res.data.available)),
          optionList ? [optionList.name] : [],
        ),
      }),
      public: fb.control(optionList?.public ?? false, { nonNullable: true }),
      type: fb.control(optionList?.type || null, { validators: withTypeField ? [Validators.required] : [] }),
    });

    if (this.controls.isEditing.value) {
      this.controls.categoryId.disable();
      this.controls.type.disable();
    }
  }

  public toModel(): UpsertOptionListRequest {
    return {
      active: this.controls.active.value,
      core_option_list_category_id: this.controls.categoryId.value || '',
      description: this.controls.description.value,
      explanation_label: this.controls.explanationLabel.value,
      name: this.controls.name.value.trim(),
      public: this.controls.public.value,
      selection_set_category_id: this.controls.categoryId.value || '',
      type: this.controls.type.value || undefined,
    };
  }
}
