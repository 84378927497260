import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ValueDefinitionSize } from '../../../models';

@Component({
  selector: 'lib-slide-toggle',
  templateUrl: './slide-toggle.component.html',
})
export class SlideToggleComponent implements OnInit {
  @Input() checked?: boolean;
  @Input() disabled: boolean = false;
  @Input() label = '';
  @Input() control?: UntypedFormControl;
  @Input() hint?: string;
  @Input() id: string = '';
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;

  @Output() sliderToggle: EventEmitter<boolean> = new EventEmitter();

  public uncontrolled: boolean = false;

  public ngOnInit(): void {
    this.uncontrolled = typeof this.checked === 'boolean';
  }
}
