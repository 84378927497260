import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlagModule } from '../feature-flag';
import { DirectivesModule } from '../directives';
import { ComponentsModule } from '../components';
import { FormsModule } from '@angular/forms';
import { OptionListsComponent } from './option-lists.component';
import { OptionListFormComponent } from './option-list-form/option-list-form.component';
import { SearchModule } from '../search';
import { LayoutModule } from '../layout';
import { OptionListComponent } from './option-list/option-list.component';
import { OptionListItemFormComponent } from './option-list-item-form/option-list-item-form.component';
import { TablesModule } from '../tables';
import { OptionListCategoryDefaultsComponent } from './option-list-category-defaults/option-list-category-defaults.component';
import { OptionListResourceListsComponent } from './option-list-resource-lists/option-list-resource-lists.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    PipesModule,
    TranslateModule,
    FeatureFlagModule,
    DirectivesModule,
    ComponentsModule,
    FormsModule,
    TablesModule,
    SearchModule,
    LayoutModule,
  ],
  declarations: [
    OptionListComponent,
    OptionListsComponent,
    OptionListFormComponent,
    OptionListItemFormComponent,
    OptionListCategoryDefaultsComponent,
    OptionListResourceListsComponent,
  ],
  exports: [OptionListComponent, OptionListsComponent],
})
export class OptionListsModule {}
