import { Pipe, PipeTransform } from '@angular/core';
import { MetricStructureSelectable, MetricTableGroup } from '../../models';

@Pipe({
  name: 'isTable',
})
export class IsTablePipe implements PipeTransform {
  transform(metricStructureSelectable?: MetricStructureSelectable): metricStructureSelectable is MetricTableGroup {
    return metricStructureSelectable ? 'table_id' in metricStructureSelectable : false;
  }
}
