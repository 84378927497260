import {
  FieldValueComparisonTableExportFormat,
  Entity,
  FieldValueComparisonTableMetric,
  FieldValueComparisonTableValueDefinition,
  MetricValueDefinitionAttribute,
  Source,
  CreatedUpdatedEntity,
} from './entities';

export enum DataTableComparisonType {
  fiscal_year = 'fiscal_year',
  source = 'source',
}

export interface DataTableFrequencyCode extends CreatedUpdatedEntity {
  data_table_id: string;
  frequency_code: string;
  position: number;
}

export interface DataTable extends Entity {
  comparison_type: DataTableComparisonType;
  business_units: Source[];
  data_table_frequency_codes: DataTableFrequencyCode[];
  metrics: DataTableMetric[];
}

export interface DataTableValueDefinition extends FieldValueComparisonTableValueDefinition {
  id: string;
  attribute: MetricValueDefinitionAttribute;
}

export interface DataTableMetric extends FieldValueComparisonTableMetric {
  id: string;
  value_definitions: DataTableValueDefinition[];
}

export interface ExportDataTableRequest {
  name: string;
  export_format: FieldValueComparisonTableExportFormat;
}

export interface ListDataTableRequest {
  comparisonType?: DataTableComparisonType;
  businessUnitId?: string;
  frequencyCode?: string;
  orderBy?: string | undefined;
  page?: number;
  pageSize?: number;
  searchQuery?: string | undefined;
}

export interface DataTableValuesRequest {
  comparison_type: DataTableComparisonType;
  business_units: string[];
  frequency_codes: string[];
  metrics: DataTableMetric[];
  page: number;
  page_size: number;
}

export interface DataTableValuesResponseValue<T = unknown> {
  attribute: MetricValueDefinitionAttribute;
  business_unit_id: string;
  frequency_code: string;
  value_definition_id: string;
  values?: T[];
}

export interface DuplicateDataTableRequest {
  name: string;
}

export interface UpsertDataTableRequest {
  comparison_type: DataTableComparisonType;
  business_units: string[];
  frequency_codes: string[];
  metrics: DataTableMetric[];
  name: string;
}
