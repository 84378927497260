import { Component, Inject, OnInit } from '@angular/core';
import { StripNonPrintableCharactersService } from '../../../../services/common';
import {
  DisplayedValue,
  SOURCE_CONFIGURATION,
  Status,
  SubtitleTypeDetails,
  ValueDefinitionDisplayType,
  ValueDefinitionSize,
} from '../../../../models';
import { TemplateReportStructureStateService } from '../../../services/template-report-structure-state.service';
import { TemplateReportStructureDisplayedValuesForm } from './template-report-structure-displayed-values-form';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface TemplateReportStructureDisplayedValuesDialogData {
  templateId: string;
  versionId: string;
  sectionId: string;
  metricId: string;
  metricCode: string;
  sourceConfiguration: SOURCE_CONFIGURATION;
}
@Component({
  selector: 'lib-template-report-structure-displayed-values-dialog',
  templateUrl: './template-report-structure-displayed-values-dialog.component.html',
})
export class TemplateReportStructureDisplayedValuesDialogComponent implements OnInit {
  public form?: TemplateReportStructureDisplayedValuesForm;
  sourceConfiguration: SOURCE_CONFIGURATION;
  readonly eValueDefinitionSize = ValueDefinitionSize;
  readonly eValueDefinitionDisplayType = ValueDefinitionDisplayType;

  public displayedValuesById: Record<string, DisplayedValue> = {};
  constructor(
    private dialogRef: MatDialogRef<TemplateReportStructureDisplayedValuesDialogComponent>,
    private stripNonPrintableCharactersService: StripNonPrintableCharactersService,
    private templateReportStructureStateService: TemplateReportStructureStateService,
    @Inject(MAT_DIALOG_DATA) public templateData: TemplateReportStructureDisplayedValuesDialogData,
  ) {
    this.sourceConfiguration = templateData.sourceConfiguration;
  }

  ngOnInit() {
    this.templateReportStructureStateService.displayedValues$.subscribe(
      (displayedValues: DisplayedValue[] | undefined) => {
        if (displayedValues) {
          this.form = new TemplateReportStructureDisplayedValuesForm(displayedValues);
          this.createDisplayedValueRecord(displayedValues);
          this.form.computeIfCheckedValue();
        }
      },
    );
  }

  createDisplayedValueRecord(displayedValues: DisplayedValue[]) {
    this.displayedValuesById = displayedValues.reduce((acc: Record<string, DisplayedValue>, v) => {
      acc[v.value_definition_id] = v;
      return acc;
    }, {});
  }

  getSubtitleValue(valueDefinitionId: string) {
    return (this.displayedValuesById[valueDefinitionId].type_details as SubtitleTypeDetails).value;
  }

  handleCheckboxChange(value: boolean, control: FormControl) {
    control.setValue(value);
    this.form?.computeIfCheckedValue();
  }

  save() {
    if (this.form) {
      const obs = this.templateReportStructureStateService.saveDisplayedValues(
        this.form.toModel(),
        this.templateData.templateId,
        this.templateData.versionId,
        this.templateData.sectionId,
        this.templateData.metricId,
      );
      obs.subscribe(() => {
        this.dialogRef.close({ status: Status.SUCCESS });
      });
    }
  }

  resetAll() {
    if (this.form) {
      this.form.resetToCheckedAndEmpty();
    }
  }

  closeDialog(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }
}
