import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionItem } from '../../../models';

@Component({
  selector: 'lib-filter-with-category',
  templateUrl: './filter-with-category.component.html',
  styleUrl: './filter-with-category.component.scss',
})
export class FilterWithCategoryComponent implements OnInit {
  @Input({ required: true }) filterCategoryTranslations!: Record<string, string>;
  @Input() filteredOptions: ActionItem[] = [];
  @Input() filterFunction?: (option: ActionItem, value: any) => boolean;
  @Input() activeOptionId?: string;

  @Output() optionSelected = new EventEmitter<ActionItem>();

  filterCategories: string[] = [];

  ngOnInit(): void {
    this.filterCategories = Object.keys(this.filterCategoryTranslations);
  }
}
