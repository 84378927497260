import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, Observable, switchMap, tap } from 'rxjs';
import { Metric, MetricFieldVisibilityPerFiscalYear } from '../models';
import { MetricApiService } from '../services/types';

export interface MetricStructureState {
  fieldsVisibility: MetricFieldVisibilityPerFiscalYear[];
  metric: Metric | undefined;
}

@Injectable()
export class MetricStructureStore extends ComponentStore<MetricStructureState> {
  private static readonly DEFAULT_STATE: MetricStructureState = {
    fieldsVisibility: [],
    metric: undefined,
  };

  public readonly fieldsVisibility$: Observable<MetricFieldVisibilityPerFiscalYear[]> = this.select(
    (state) => state.fieldsVisibility,
  );
  public readonly metric$: Observable<Metric | undefined> = this.select((state) => state.metric);

  constructor(private metricsService: MetricApiService) {
    super(MetricStructureStore.DEFAULT_STATE);
  }

  private readonly updateFieldsVisibility = this.updater(
    (state, fieldsVisibility: MetricFieldVisibilityPerFiscalYear[]): MetricStructureState => ({
      ...state,
      fieldsVisibility,
    }),
  );

  public readonly updateMetric = this.updater(
    (state: MetricStructureState, metric: Metric | undefined): MetricStructureState => ({
      ...state,
      metric,
    }),
  );

  public readonly updateFieldsVisibilityEffect = this.effect(() =>
    this.metric$.pipe(
      filter((metric) => Boolean(metric?.id)),
      switchMap((metric) => this.metricsService.getMetricFieldVisibility(metric?.id ?? '')),
      map((response) => response.data),
      tap((fieldsVisibility) => this.updateFieldsVisibility(fieldsVisibility)),
    ),
  );
}
