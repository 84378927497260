import { ComponentRef, Directive, EventEmitter, Type, ViewChild } from '@angular/core';
import { DynamicHostDirective } from './dynamic-host.directive';

@Directive({
  selector: '[libDynamicComponentLoader]',
})
export class DynamicComponentLoaderDirective {
  @ViewChild(DynamicHostDirective, { static: true }) dynamicHost!: DynamicHostDirective;

  protected loadDynamicComponent<T>(component: Type<T>, componentProperties?: Partial<T>): void {
    const viewContainerRef = this.dynamicHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef: ComponentRef<T> = viewContainerRef.createComponent<T>(component);

    DynamicComponentLoaderDirective.setDynamicComponentProperties(componentRef.instance, componentProperties);

    this.dynamicHost.changeDetectorRef.detectChanges();
  }

  protected destroyDynamicComponent(): void {
    this.dynamicHost.viewContainerRef.clear();
  }

  private static setDynamicComponentProperties<T>(component: T, componentProperties?: Partial<T>): void {
    for (const componentProperty in componentProperties) {
      const property = componentProperties[componentProperty];
      if (typeof property !== 'undefined') {
        if (component[componentProperty] instanceof EventEmitter) {
          // Subscribe on dynamic component's Outputs
          (component[componentProperty] as unknown as EventEmitter<unknown>).subscribe((eventEmitterParams) => {
            if (eventEmitterParams) {
              (componentProperties[componentProperty] as unknown as (param: unknown) => void)(eventEmitterParams);
            } else {
              (componentProperties[componentProperty] as unknown as () => void)();
            }
          });
        } else {
          // Set dynamic component's Inputs
          component[componentProperty] = property as T[Extract<keyof T, string>];
        }
      }
    }
  }
}
