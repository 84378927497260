import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayPropertyExtraction',
})
export class ArrayPropertyExtractionPipe implements PipeTransform {
  transform(arrayToExtractProperty?: any[], property?: string): unknown[] {
    if (arrayToExtractProperty?.length && property) {
      return arrayToExtractProperty.map((itemToExtractProperty) => itemToExtractProperty[property]);
    }
    return arrayToExtractProperty as unknown[];
  }
}
