<ng-container *ngIf="presentation === ePresentation.card">
  <div class="card mb-1 bkg-white" data-testid="card">
    <div class="card-header">
      <div class="mat-caption rounded-corners mb-1 bkg-blink w-33">&nbsp;</div>
    </div>
    <div class="card-content">
      <div class="w-100">
        <div class="body-2 rounded-corners mb-1 bkg-blink w-100">&nbsp;</div>
        <div class="body-2 rounded-corners mb-1 bkg-blink w-100">&nbsp;</div>
        <div class="body-2 rounded-corners mb-1 bkg-blink w-100">&nbsp;</div>
        <div class="body-2 rounded-corners mb-1 bkg-blink w-50">&nbsp;</div>
      </div>
    </div>
    <div class="card-footer">
      <div class="bkg-blink rounded-corners w-25">&nbsp;</div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.doc">
  <div class="card presentation-doc bkg-white" data-testid="doc">
    <div class="card-content">
      <div class="w-100 mr-3">
        <div class="caption2 mb-1 rounded-corners bkg-blink w-50">&nbsp;</div>
        <div class="mat-body-1 mb-1 rounded-corners bkg-blink w-100">&nbsp;</div>
        <div class="mat-body-1 rounded-corners bkg-blink w-100" style="margin-bottom: 1.3rem">&nbsp;</div>
      </div>
      <div class="file-icon bkg-blink">&nbsp;</div>
    </div>
    <div class="card-footer">
      <div class="caption2 mt-2 rounded-corners bkg-blink w-50">&nbsp;</div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.doclink">
  <div class="card bkg-white h-100" data-testid="doclink">
    <div class="card-content">
      <div class="w-100 mr-3">
        <div class="caption2 mb-2 rounded-corners bkg-blink w-50">&nbsp;</div>
        <div class="mat-body-1 mb-3 rounded-corners bkg-blink w-100">&nbsp;</div>
        <div class="rounded-corners bkg-blink w-50">&nbsp;</div>
      </div>
      <div class="file-icon bkg-blink">&nbsp;</div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.highlights">
  <div data-testid="highlights">
    <h3 class="mat-h3 rounded-corners bkg-blink w-50 mb-2">&nbsp;</h3>
    <div class="bkg-blink rounded-corners mb-4 w-25">&nbsp;</div>
    <div *ngFor="let i of [1, 2, 3, 4]" class="bkg-blink rounded-corners ml-4 mt-2 w-75">&nbsp;</div>
  </div>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.table">
  <div class="card bkg-white" style="width: 20rem; height: 6rem" data-testid="table">
    <div class="card-header">
      <div class="mat-caption rounded-corners mb-1 bkg-blink w-33">&nbsp;</div>
    </div>
    <div class="card-footer">
      <div class="bkg-blink rounded-corners w-25">&nbsp;</div>
    </div>
  </div>
  <ng-container *ngFor="let x of [1, 2, 3, 4]">
    <div class="card bkg-white" style="width: 20rem; height: 12rem">
      <div class="card-header">
        <div class="mat-caption rounded-corners mb-1 bkg-blink w-100">&nbsp;</div>
      </div>
      <div class="card-content">
        <div class="w-100">
          <div class="rounded-corners mb-1 bkg-blink w-100">&nbsp;</div>
          <div class="rounded-corners mb-1 bkg-blink w-100">&nbsp;</div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.matTable">
  <table
    data-testid="matTable"
    mat-table
    [dataSource]="DUMMY_ELEMENT_DATA"
    matSort
    class="mat-elevation-z0 w-100 custom-table bkg-white"
  >
    <ng-container *ngFor="let column of dummyDataTableDisplayedColumns" matColumnDef="{{ column }}">
      <th mat-header-cell *matHeaderCellDef class="w-25">
        <div class="rounded-corners bkg-blink w-25">&nbsp;</div>
      </th>
      <td mat-cell *matCellDef="let element" class="w-25">
        <div class="rounded-corners bkg-blink w-25">&nbsp;</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dummyDataTableDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: dummyDataTableDisplayedColumns"></tr>
  </table>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.cardLink">
  <div class="card card-link w-25 bkg-blink">&nbsp;</div>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.page">
  <div class="panel-content pb-3">
    <div class="panel-dummies-container mb-2">
      <div class="h-100 item-dummies">
        <div id="context-bar" class="fx-col m-2">
          <div class="caption2 mb-1 mt-2 rounded-corners bkg-blink w-25">&nbsp;</div>
          <div class="mat-body-2 rounded-corners bkg-blink w-100 mb-1">&nbsp;</div>
        </div>

        <div class="loading-page-container">
          <div id="collection" class="loading-collection">
            <div class="repeat bkg-white w-100 h-100">
              <div *ngFor="let i of [].constructor(numberOfDummies ?? 16)" class="mb-2">
                <div class="caption2 mb-1 rounded-corners bkg-blink w-50">&nbsp;</div>
                <div class="mat-body-2 rounded-corners bkg-blink w-100">&nbsp;</div>
              </div>
            </div>
          </div>

          <div id="item" class="loading-item ml-2 mr-4">
            <div class="repeat bkg-white w-100 h-100">
              <div class="fx-col m-2">
                <div class="caption2 mb-1 mt-2 rounded-corners bkg-blink w-25">&nbsp;</div>
                <div class="mat-body-2 rounded-corners bkg-blink w-100 mb-3">&nbsp;</div>
              </div>
              <div *ngFor="let i of [].constructor(numberOfDummies ?? 10)" class="mb-2">
                <div class="caption2 mb-1 rounded-corners bkg-blink w-50">&nbsp;</div>
                <div class="mat-body-2 rounded-corners bkg-blink w-100">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.form">
  <div *ngFor="let i of [].constructor(numberOfDummies ?? 2)">
    <div class="caption2 mb-2 rounded-corners bkg-blink w-25">&nbsp;</div>
    <div class="mat-body-2 mb-3 rounded-corners bkg-blink w-75">&nbsp;</div>
  </div>
</ng-container>
