import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { NumberMaskPipe } from './number-mask.pipe';

@NgModule({
  declarations: [NumberMaskPipe],
  providers: [provideNgxMask(), NgxMaskPipe],
  imports: [CommonModule, NgxMaskDirective, NgxMaskPipe],
  exports: [NumberMaskPipe],
})
export class NumberMaskModule {}
