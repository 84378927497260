import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directives';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../components';
import { ModulePageComponent } from './module-page/module-page.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageHeaderSubtitlesComponent } from './page-header-subtitles/page-header-subtitles.component';
import { TabsComponent } from './tabs/tabs.component';
import { FeatureFlagModule } from '../feature-flag';
import { PageContextBarComponent } from './page-context-bar/page-context-bar.component';

@NgModule({
  declarations: [
    ModulePageComponent,
    PageHeaderComponent,
    PageHeaderSubtitlesComponent,
    TabsComponent,
    PageContextBarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    ComponentsModule,
    FeatureFlagModule,
  ],
  exports: [
    ModulePageComponent,
    PageHeaderComponent,
    PageHeaderSubtitlesComponent,
    TabsComponent,
    PageContextBarComponent,
  ],
})
export class LayoutModule {}
