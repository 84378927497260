import { Pipe, PipeTransform } from '@angular/core';
import { TableActionMenuItemEvent } from '../../../../models';

@Pipe({
  name: 'doesActionMenuHasItems',
})
export class DoesActionMenuHasItemsPipe implements PipeTransform {
  transform<T>(value: T, actionMenuItems?: TableActionMenuItemEvent<T>[]): boolean {
    if (!actionMenuItems) {
      return false;
    }

    for (const actionMenuItem of actionMenuItems) {
      if (!actionMenuItem.showCondition || actionMenuItem.showCondition(value)) {
        return true;
      }
    }

    return false;
  }
}
