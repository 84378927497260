import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { startAuthentication, startRegistration } from '@simplewebauthn/browser';
import {
  AuthenticationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON,
} from '@simplewebauthn/types';

@Injectable({
  providedIn: 'root',
})
export class WebAuthnService {
  startRegistration(requestOptionsJSON: PublicKeyCredentialCreationOptionsJSON): Observable<RegistrationResponseJSON> {
    return from(startRegistration(requestOptionsJSON));
  }

  startAuthentication(
    requestOptionsJSON: PublicKeyCredentialRequestOptionsJSON,
    useBrowserAutofill?: boolean,
  ): Observable<AuthenticationResponseJSON> {
    return from(startAuthentication(requestOptionsJSON, useBrowserAutofill));
  }

  isPasskeyAvailable(): Observable<boolean> {
    return from(PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable());
  }
}
