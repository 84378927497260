import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(arrayToJoin?: unknown[], separator: string = ', '): string {
    return arrayToJoin?.join(separator) ?? '';
  }
}
