import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApplicationApiDefinition,
  AttributeCheckResponse,
  BaseSourceCreateRequest,
  BaseSourceUpdateRequest,
  ConvertBusinessUnitValidation,
  ListSourceRequest,
  SearchOptions,
  Source,
} from '../../../models';
import { ApiService } from '../../common';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientSourcesV2Service {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.businessUnitsV2;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): HttpParams {
    let params = new HttpParams();

    if (searchOptions.sort) {
      params = params.append('order_by', searchOptions.sort.id);
      if (searchOptions.sort.id === 'updated') {
        params = params.append('order_by_direction', 'desc');
      }
    }
    if (searchOptions.filters?.type) {
      params = params.append('business_unit_type', searchOptions.filters.type.id);
    }

    if (searchOptions.custom_filters?.company_id) {
      params = params.append('company_id', searchOptions.custom_filters.company_id as string);
    }

    if (searchOptions.query.keywords) {
      params = params.append('query', searchOptions.query.keywords);
    }

    return params;
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Source[]>> {
    const params: any = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.get<ApiResponse<Source[]>>(`${this.servicePath}${this.resource}/business_units`, { params });
  }

  public listSources(requestParams: ListSourceRequest): Observable<ApiResponse<Source[]>> {
    let params = new HttpParams();

    if (requestParams.page) {
      params = params.append('page', requestParams.page);
    }

    if (requestParams.pageSize) {
      params = params.append('page_size', requestParams.pageSize);
    }

    if (requestParams.searchQuery) {
      params = params.append('query', requestParams.searchQuery);
    }

    if (requestParams.orderBy) {
      params = params.append('order_by', requestParams.orderBy);
    }

    if (requestParams.orderByDirection) {
      params = params.append('order_by_direction', requestParams.orderByDirection);
    }

    if (requestParams.businessUnitType) {
      params = params.append('business_unit_type', requestParams.businessUnitType);
    }

    if (requestParams.consolidation !== undefined) {
      params = params.append('consolidation', requestParams.consolidation);
    }

    if (requestParams.parent_id) {
      params = params.append('parent_id', requestParams.parent_id);
    }

    if (requestParams.max_level) {
      params = params.append('max_level', requestParams.max_level);
    }

    if (requestParams.level) {
      params = params.append('level', requestParams.level);
    }

    if (requestParams.with_ancestor) {
      params = params.append('with_ancestor', requestParams.with_ancestor);
    }

    return this.apiService.get<ApiResponse<Source[]>>(`${this.servicePath}${this.resource}/business_units`, { params });
  }

  createSource(payload: BaseSourceCreateRequest): Observable<ApiResponse<Source>> {
    return this.apiService.post<ApiResponse<Source>>(`${this.servicePath}${this.resource}/business_units`, payload);
  }

  updateSource(sourceId: string, updatedSource: BaseSourceUpdateRequest): Observable<ApiResponse<Source>> {
    return this.apiService.put<ApiResponse<Source>>(
      `${this.servicePath}${this.resource}/business_units/${sourceId}`,
      updatedSource,
    );
  }

  deleteSource(sourceId: string): Observable<ApiResponse> {
    return this.apiService.delete<ApiResponse>(`${this.servicePath}${this.resource}/business_units/${sourceId}`);
  }

  getSource(sourceId: string): Observable<ApiResponse<Source>> {
    return this.apiService.get<ApiResponse<Source>>(`${this.servicePath}${this.resource}/business_units/${sourceId}`);
  }

  listSourceChildren(parent_business_unit_id: string): Observable<ApiResponse<Source[]>> {
    return this.apiService.get<ApiResponse<Source[]>>(
      `${this.servicePath}${this.resource}/business_units/${parent_business_unit_id}/children`,
    );
  }

  checkSourceNameAvailable(sourceName: string): Observable<ApiResponse<AttributeCheckResponse>> {
    return this.apiService.get<ApiResponse<AttributeCheckResponse>>(
      `${this.servicePath}${this.resource}/business_units/name_check/${sourceName}`,
    );
  }

  public validateSourceConversion(business_unit_id: string): Observable<ApiResponse<ConvertBusinessUnitValidation>> {
    return this.apiService.get<ApiResponse<ConvertBusinessUnitValidation>>(
      `${this.servicePath}${this.resource}/business_units/${business_unit_id}/validate_source_conversion`,
    );
  }

  public convertSource(business_unit_id: string): Observable<ApiResponse<Source>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/business_units/${business_unit_id}/convert`);
  }
}
