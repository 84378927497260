import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionItem } from './ui';
import { BaseValue, isDataRequestValue } from './values';
import { NUMERIC_FIELD_TYPES } from './metrics';
import { SOURCE_CONFIGURATION } from './client';

export enum ConsolidationRules {
  sum = 'sum',
  average = 'average',
  manual = 'manual',
}

export const DefaultConsolidationRule: ConsolidationRules = ConsolidationRules.manual;

export enum ConsolidationTriggers {
  update_when_one_value = 'update_when_one_value',
  update_when_all_values = 'update_when_all_values',
}

export const ConsolidationRuleOptions = [
  { id: ConsolidationRules.sum, title: _('Sum (default)') },
  { id: ConsolidationRules.average, title: _('Average') },
  { id: ConsolidationRules.manual, title: _('No calculation - allow manual entry') },
];

export const CONSOLIDATION_TRIGGER_SUM_OPTIONS = [
  {
    id: ConsolidationTriggers.update_when_one_value,
    title: _('Sum as values are added to sources (default)'),
  },
  {
    id: ConsolidationTriggers.update_when_all_values,
    title: _('Sum only when all sources have values'),
  },
];
export const CONSOLIDATION_TRIGGER_AVG_OPTIONS = [
  {
    id: ConsolidationTriggers.update_when_one_value,
    title: _('Average as values are added to sources (default)'),
  },
  {
    id: ConsolidationTriggers.update_when_all_values,
    title: _('Average only when all sources have values'),
  },
];

export const QUANTITATIVE_CONSOLIDATION_RULES: ConsolidationRules[] = [
  ConsolidationRules.sum,
  ConsolidationRules.average,
];

export const CONSOLIDATED_DATA_REQUEST_INFO = {
  [ConsolidationRules.average]: {
    [ConsolidationTriggers.update_when_one_value]: _(
      'The consolidated average is calculated based on the sources having values. This value will not be updated until the request is imported.',
    ),
    [ConsolidationTriggers.update_when_all_values]: _(
      'The consolidated average is calculated only when all sources have values. This value will not be updated until the request is imported.',
    ),
  },
  [ConsolidationRules.sum]: {
    [ConsolidationTriggers.update_when_one_value]: _(
      'The consolidated sum is calculated as values are added to sources. This value will not be updated until the request is imported.',
    ),
    [ConsolidationTriggers.update_when_all_values]: _(
      'The consolidated sum is calculated only when all sources have values. This value will not be updated until the request is imported.',
    ),
  },
  [ConsolidationRules.manual]: {
    [ConsolidationTriggers.update_when_one_value]: '',
    [ConsolidationTriggers.update_when_all_values]: '',
  },
};

export const CONSOLIDATED_INFO = {
  [ConsolidationRules.average]: {
    [ConsolidationTriggers.update_when_one_value]: _(
      'The consolidated average is calculated based on the sources having values.',
    ),
    [ConsolidationTriggers.update_when_all_values]: _(
      'The consolidated average is calculated only when all sources have values.',
    ),
  },
  [ConsolidationRules.sum]: {
    [ConsolidationTriggers.update_when_one_value]: _(
      'The consolidated sum is calculated as values are added to sources.',
    ),
    [ConsolidationTriggers.update_when_all_values]: _(
      'The consolidated sum is calculated only when all sources have values.',
    ),
  },
  [ConsolidationRules.manual]: {
    [ConsolidationTriggers.update_when_one_value]: '',
    [ConsolidationTriggers.update_when_all_values]: '',
  },
};

export function isQuantitativeRule(rule?: ConsolidationRules): boolean {
  return QUANTITATIVE_CONSOLIDATION_RULES.some((quantitativeConsRule) => quantitativeConsRule === rule);
}

export function isFieldBypassConsolidation(
  value_bypass_consolidation_levels?: number[] | null,
  businessUnitLevel?: number,
): boolean {
  return businessUnitLevel !== undefined && !!value_bypass_consolidation_levels?.includes(businessUnitLevel);
}

export function consolidationTriggerFromRule(rule?: ConsolidationRules): ActionItem[] {
  switch (rule) {
    case ConsolidationRules.sum:
      return CONSOLIDATION_TRIGGER_SUM_OPTIONS;
    case ConsolidationRules.average:
      return CONSOLIDATION_TRIGGER_AVG_OPTIONS;
    default:
      return [];
  }
}

export function getConsolidatedInfo(value: BaseValue): string {
  if (!value.consolidation_trigger) {
    return '';
  }

  if (!NUMERIC_FIELD_TYPES.includes(value.type)) {
    return '';
  }

  if (isDataRequestValue(value)) {
    return CONSOLIDATED_DATA_REQUEST_INFO[value.consolidation_rule][value.consolidation_trigger];
  }
  return CONSOLIDATED_INFO[value.consolidation_rule][value.consolidation_trigger];
}

export function isDBConsolidationEnabled(sourceConfiguration: SOURCE_CONFIGURATION | undefined): boolean {
  return (
    sourceConfiguration === SOURCE_CONFIGURATION.single_group ||
    sourceConfiguration === SOURCE_CONFIGURATION.hierarchy ||
    sourceConfiguration === SOURCE_CONFIGURATION.groups
  );
}

export interface RunConsolidationV2Request {
  frequency_codes?: string[];
  depth?: number;
}
