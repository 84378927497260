<lib-form-field-label
  *ngIf="label && control"
  [label]="label"
  [control]="control"
  [for]="_inputId"
></lib-form-field-label>
<mat-radio-group
  *ngIf="control"
  [formControl]="control"
  [name]="_inputId"
  [attr.aria-labelledby]="_inputId"
  [id]="_inputId"
  color="primary"
  [ngClass]="{ 'fx-col': column }"
  data-testid="radio-group-test"
>
  <mat-radio-button
    *ngFor="let choice of choices"
    [value]="choice.value"
    class="radio-button"
    [ngClass]="{ 'mr-3': !column }"
    (change)="choiceSelection.emit($event.value)"
  >
    {{ choice.label }}
  </mat-radio-button>
</mat-radio-group>
