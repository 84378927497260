<lib-dialog-title [title]="'Discard Changes' | translate" (closeEvent)="close()"></lib-dialog-title>

<div mat-dialog-content class="body-text">
  {{ "Changes are not saved, do you want to continue without saving your changes?" | translate }}
</div>
<div mat-dialog-actions>
  <button lib-button buttonType="cancel_with_border" (click)="discard()">
    {{ "Discard" | translate }}
  </button>

  <button lib-button buttonType="cancel_with_border" class="ml-3" (click)="close()">
    {{ "Cancel" | translate }}
  </button>
</div>
