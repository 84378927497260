import { Observable } from 'rxjs';
import {
  ApiResponse,
  CollaboratorDataRequestWithSource,
  DataRequestNote,
  DataRequestSource,
  DataRequestSourceSubmission,
  DataRequestTransition,
  DataRequestValueGroupSet,
  DataRequestValueGroupSetStatus,
  LimitedUser,
} from '../../../models';

export abstract class BaseClientCollaboratorsService {
  abstract getListOfUserFromDataRequestSourceForCollaborator(
    dataRequestId: string,
    dataRequestSourceId: string,
  ): Observable<ApiResponse<LimitedUser[]>>;
  abstract getCollaboratorDataRequestWithSource(
    dataRequestId: string,
    dataRequestSourceId: string,
  ): Observable<ApiResponse<CollaboratorDataRequestWithSource>>;
  abstract postChangeStatusOfDataRequestVgset(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestVgsetId: string,
    status: DataRequestValueGroupSetStatus,
    note?: string,
  ): Observable<ApiResponse<DataRequestValueGroupSet>>;
  abstract postChangeStatusOfDataRequestSource(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestSourceSubmission: DataRequestSourceSubmission,
  ): Observable<ApiResponse>;
  abstract getDataRequestNotes(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestNoteIds: string[],
  ): Observable<ApiResponse<DataRequestNote[]>>;
  abstract getDataRequestSourceTransitions(
    dataRequestId: string,
    dataRequestSourceId: string,
  ): Observable<ApiResponse<DataRequestTransition[]>>;
  abstract getDataRequestValueGroupSetTransitions(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestVgsetId: string,
  ): Observable<ApiResponse<DataRequestTransition[]>>;
  abstract getDataRequestSourceTicket(
    dataRequestId: string,
    dataRequestSourceId: string,
  ): Observable<ApiResponse<string>>;
  abstract postDataRequestNoteReply(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestNoteId: string,
    replyText: string,
  ): Observable<ApiResponse<DataRequestNote>>;

  abstract postAutomaticImportDataRequestSourceV2(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestVgsetIds: string[],
  ): Observable<ApiResponse<DataRequestSource>>;
  abstract postChangeStatusOfDataRequestSourceV2(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestSourceSubmission: DataRequestSourceSubmission,
  ): Observable<ApiResponse>;
}
