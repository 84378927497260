import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthService, AlertsService } from '../../services/common';
import { AuthMethod } from '../../models';
import { TranslateService } from '../../services/common';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'lib-auth-refresh-dialog',
  templateUrl: './auth-refresh-dialog.component.html',
  styleUrls: ['./auth-refresh-dialog.component.scss'],
})
export class AuthRefreshDialogComponent {
  loading: boolean = false;
  pwdType = 'password';
  password = new FormControl<string | null>('');

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<AuthRefreshDialogComponent>,
    private authService: AuthService,
    private alertsService: AlertsService,
  ) {}

  togglePwdType() {
    if (this.pwdType === 'password') {
      this.pwdType = 'text';
    } else {
      this.pwdType = 'password';
    }
  }

  login(): void {
    if (this.authService.user && this.password.value) {
      let username: string = '';
      switch (this.authService.authMethod) {
        case AuthMethod.basic:
          username = this.authService.user.email;
          break;
        case AuthMethod.ldap:
          username = this.authService.user.username;
          break;
        default:
          throw new Error(`Authentication method not implemented ${this.authService.authMethod}`);
      }
      if (!username) {
        this.authService.logout();
      } else {
        this.loading = true;
        this.authService.login(username, this.password.value).subscribe(
          (result) => {
            this.loading = false;
            if (result) {
              this.dialogRef.close();
            } else {
              this.showError();
            }
          },
          () => {
            this.loading = false;
            this.showError();
          },
        );
      }
    }
  }

  logout(): void {
    this.authService.logout();
    this.dialogRef.close();
  }

  showError() {
    this.alertsService.error(this.translateService.instant('Please check your information'));
  }
}
