<div class="text-with-tooltip-menu">
  <span>{{ textItem }}</span>
  <ng-container *ngIf="additionalTextItems.length > 0">
    <button (click)="$event.stopPropagation()" lib-button [matMenuTriggerFor]="menu" class="menu-trigger">
      +{{ additionalTextItems.length }}<mat-icon [svgIcon]="'arrow-head-down'"> </mat-icon>
    </button>
  </ng-container>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let item of additionalTextItems">{{ item }}</button>
  </mat-menu>
</div>
