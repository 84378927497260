import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[libScrollToBottom]',
})
export class ScrollToBottomDirective {
  @Input() percentageThreshold: number = 0.9; // 90%

  @Output('libScrollToBottom')
  scrollToBottom: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('scroll', ['$event']) private onScroll(event: Event): void {
    const target = event.target;
    if (
      target instanceof Element &&
      target.scrollTop + target.clientHeight >= target.scrollHeight * this.percentageThreshold
    ) {
      this.scrollToBottom.emit();
    }
  }
}
