import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-metric-structure-label',
  templateUrl: './metric-structure-label.component.html',
  styleUrls: ['./metric-structure-label.component.scss'],
})
export class MetricStructureLabelComponent {
  @Input() isTriggered = false;
  @Input() isTrigger = false;
  @Input() labelClass?: string;
  @Input() isInactive = false;
}
