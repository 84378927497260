import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isArrayWithMoreThanOneItem',
})
export class IsArrayWithMoreThanOneItemPipe implements PipeTransform {
  transform(value: unknown): boolean {
    return Array.isArray(value) && value.length > 1;
  }
}
