import { Component, Input } from '@angular/core';
import { ButtonProps, OptionProps } from '../../models';

@Component({
  selector: 'lib-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
})
export class FilterButtonComponent {
  @Input() optionProps?: OptionProps;
  @Input() buttonProps?: ButtonProps;
}
