import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  ApiResponse,
  ApplicationApiDefinition,
  IntegrationSetting,
  PlatformIntegrationType,
  IntegrationSettingValidation,
} from '../../../models';

import { ApiService } from '../../common';
import { HttpContext } from '@angular/common/http';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors';

@Injectable({
  providedIn: 'root',
})
export class ClientLicenseeIntegrationSettingsService {
  private apiName: keyof ApplicationApiDefinition = 'collect';
  private resource: string;
  private servicePath: string;

  constructor(private readonly apiService: ApiService) {
    this.resource = apiService.getServicePath(this.apiName);
    this.servicePath = this.apiService.apiConfig.apis.collect.resources.licenseeIntegrationSettings;
  }

  public getAllIntegrations(): Observable<ApiResponse<IntegrationSetting[]>> {
    return this.apiService.get(`${this.resource}${this.servicePath}/integrations`);
  }

  public getIntegration(type: PlatformIntegrationType): Observable<ApiResponse<IntegrationSetting>> {
    return this.apiService.get(`${this.resource}${this.servicePath}/integrations/${type}`);
  }

  public upsertIntegration(type: PlatformIntegrationType, payload: any): Observable<ApiResponse<IntegrationSetting>> {
    return this.apiService.post(`${this.resource}${this.servicePath}/integrations/${type}`, payload);
  }

  public isValidIntegration(type: PlatformIntegrationType): Observable<ApiResponse<IntegrationSettingValidation>> {
    return this.apiService.get(`${this.resource}${this.servicePath}/integrations/${type}/validate`);
  }

  public importIntegrationData(type: PlatformIntegrationType): Observable<ApiResponse<IntegrationSettingValidation>> {
    return this.apiService.post(
      `${this.resource}${this.servicePath}/integrations/${type}/data-pull`,
      null,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }
}
