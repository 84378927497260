import { AfterContentInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  ApiResponse,
  FilterBarOption,
  FilterBarSelection,
  FilterType,
  Metric,
  MetricTableColumnDefinition,
  MetricTableDefinition,
  MetricTableValueSelectionChoice,
  MetricValueDefinitionSelection,
  Presentation,
  ResourceType,
  TableColumn,
  Taxonomy,
  ValueDefinition,
  ValueDefinitionFieldType,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../../models';

import { ActionItemUtils, ObservableUtils, StringUtils } from '../../classes';
import { TranslateService } from '../../services/common';

import { BehaviorSubject, forkJoin, Observable, of, switchMap } from 'rxjs';
import { combineLatestWith, map, tap } from 'rxjs/operators';
import { ValueDefinitionFieldTypeToText } from './select-metric-value-definition.translation';
import { MetricApiService } from '../../services/types';
import { PartialMetricValueDefinitionSort } from '../../translations';

interface ValueDefinitionWithFieldTypeRow {
  id: string;
  metricTableDefinition?: MetricTableDefinition;
  valueDefinition?: ValueDefinition;
  label: string;
  fieldType: ValueDefinitionFieldType;
  fieldTypeText?: string;
  tableAction?: any;
  active?: boolean;
}

interface Filters {
  sort: string;
  taxonomy?: string;
}

@Component({
  selector: 'lib-select-metric-value-definition',
  templateUrl: './select-metric-value-definition.component.html',
  styleUrls: ['./select-metric-value-definition.component.scss'],
})
export class SelectMetricValueDefinitionComponent implements AfterContentInit {
  @ViewChild('valueDefinitionCell', { static: true }) valueDefinitionCell?: TemplateRef<unknown>;

  @Input() set metricId(metricId: string) {
    this.metricId$.next(metricId);
  }
  @Input() enableRowSelection = false;
  @Input() showInactiveFields = false;
  @Input() withTaxonomiesFilter = false;
  @Input() withSort = false;
  @Input() ignoreFields: string[] = [];
  @Input() selections: Record<string, MetricValueDefinitionSelection> = {};
  @Input() supportedFieldTypes: ValueDefinitionFieldType[] = [];
  @Input() metricTableValueSelection: MetricTableValueSelectionChoice = MetricTableValueSelectionChoice.tableColumn;
  @Output() rowSelected = new EventEmitter<MetricValueDefinitionSelection>();
  @Output() valueDefinitionSelected = new EventEmitter<ValueDefinition>();
  @Output() metricTableColumnDefinitionSelected = new EventEmitter<MetricTableColumnDefinition>();

  public data$: Observable<{
    metric: Metric;
    filteredValueDefinitionsWithFieldTypeRows: ValueDefinitionWithFieldTypeRow[];
    tableFilters?: FilterBarOption[];
  }>;
  private metricId$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  private searchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private filters$ = new BehaviorSubject<Filters>({ sort: PartialMetricValueDefinitionSort.position });
  private taxonomies$ = new BehaviorSubject<Taxonomy[] | null>(null);

  public valueDefinitionsWithFieldTypeRows: ValueDefinitionWithFieldTypeRow[] = [];
  public valueDefinitionTableColumns: TableColumn<ValueDefinitionWithFieldTypeRow>[] = [];
  public selectedTable?: MetricTableDefinition;

  public readonly eValueDefinitionFieldType = ValueDefinitionFieldType;
  public readonly eMetricTableValueSelectionChoice = MetricTableValueSelectionChoice;
  public readonly ePresentation = Presentation;

  private metricTables?: Record<string, ValueDefinitionWithFieldTypeRow>;

  constructor(
    private translateService: TranslateService,
    private readonly metricApiService: MetricApiService,
  ) {
    this.data$ = this.fetchMetric().pipe(
      combineLatestWith(this.searchQuery$, this.filters$, this.taxonomies$),
      switchMap(([metric, search, filters, taxonomies]) =>
        forkJoin({
          metric: of(metric),
          filteredValueDefinitionsWithFieldTypeRows: this.fetchValueDefinitionWithFieldTypeRows(
            metric,
            search,
            filters,
          ),
          tableFilters: of(this.getFilterOptions(taxonomies)),
        }),
      ),
    );
  }

  ngAfterContentInit(): void {
    this.setupColumns();
  }

  public onSearchChanged(value: string): void {
    this.searchQuery$.next(value);
  }

  public onFilterChange(options: FilterBarSelection[]): void {
    const sort = options.find((o) => o.id === ResourceType.sort)?.selection[0]?.id;
    const taxonomy = options.find((o) => o.id === ResourceType.taxonomy_code)?.selection[0]?.id;
    this.filters$.next({ sort: String(sort), taxonomy: taxonomy === '-1' ? undefined : taxonomy });
  }

  public checkChanged({ selection }: { selection: string }, rows: ValueDefinitionWithFieldTypeRow[]): void {
    const row = rows.find((r) => r.id === selection);
    const rowSelection: MetricValueDefinitionSelection = {};

    if (row?.fieldType === ValueDefinitionFieldType.table) {
      rowSelection.tableId = row.id;
    } else {
      rowSelection.valueDefinitionId = row?.id;
    }

    this.rowSelected.emit(rowSelection);
  }

  public selectValueDefinition(valueDefinitionWithFieldType: ValueDefinitionWithFieldTypeRow) {
    if (valueDefinitionWithFieldType.fieldType == ValueDefinitionFieldType.table) {
      this.selectedTable = valueDefinitionWithFieldType.metricTableDefinition;
    } else {
      this.valueDefinitionSelected.emit(valueDefinitionWithFieldType.valueDefinition);
    }
  }

  public selectValueDefinitionFromTableCell(valueDefinition: ValueDefinition | undefined) {
    this.valueDefinitionSelected.emit(valueDefinition);
  }

  public selectTableInputColumn(metricTableColumnDefinition: MetricTableColumnDefinition) {
    this.metricTableColumnDefinitionSelected.emit(metricTableColumnDefinition);
  }

  private metricValueDefinitionGroupsToRows(
    metricValueDefinitionGroups?: ValueDefinitionGroup[],
    metricTables?: Record<string, ValueDefinitionWithFieldTypeRow>,
  ): ValueDefinitionWithFieldTypeRow[] {
    return (
      metricValueDefinitionGroups?.reduce((acc: ValueDefinitionWithFieldTypeRow[], vdg: ValueDefinitionGroup) => {
        if (vdg.repeatable) {
          const repeateableValueDefs =
            vdg.value_definitions?.map((value_definition) => ({
              id: value_definition.id,
              valueDefinition: value_definition,
              fieldType: ValueDefinitionFieldType.repeatable_group,
              label: value_definition.label ?? '',
              tableAction: { type: ValueDefinitionFieldType.repeatable_group },
            })) ?? [];

          acc.push(...repeateableValueDefs);
        } else if (vdg.table_id) {
          if (metricTables?.[vdg.table_id]) {
            acc.push({ ...metricTables[vdg.table_id] });
            delete metricTables[vdg.table_id];
          }
          if (vdg.is_calculation) {
            const valueDef =
              vdg.value_definitions?.map((value_definition) => this.convertValueDefinition(value_definition)) || [];

            acc.push(...valueDef);
          }
        } else {
          const valueDef =
            vdg.value_definitions?.map((value_definition) => this.convertValueDefinition(value_definition)) || [];

          acc.push(...valueDef);
        }
        return acc;
      }, []) ?? []
    );
  }

  private convertValueDefinition(valueDefinition: ValueDefinition): ValueDefinitionWithFieldTypeRow {
    const textarea = ValueDefinitionFieldType.textarea;
    const rich_text = ValueDefinitionFieldType.rich_text;
    const has_table_calculation_id = valueDefinition.metric_table_calculation_field_definition_id != null;
    let fieldType = this.convert(valueDefinition.type);

    if (fieldType === ValueDefinitionFieldType.calculated && has_table_calculation_id) {
      fieldType = ValueDefinitionFieldType.table_total;
    } else if (textarea in valueDefinition.type_details && valueDefinition.type_details[textarea]) {
      fieldType = valueDefinition.type_details[rich_text] ? rich_text : textarea;
    }

    return {
      id: valueDefinition.id,
      valueDefinition,
      fieldType,
      label: valueDefinition.label ?? '',
    };
  }

  private setupColumns(): void {
    this.valueDefinitionTableColumns = [
      {
        name: this.translateService.instant('Field Name'),
        dataKey: 'label',
        width: '80%',
      },
      {
        name: this.translateService.instant('Field Type'),
        dataKey: 'fieldTypeText',
        cellTemplate: this.valueDefinitionCell,
        width: '20%',
      },
    ];
  }

  private convert(valueDefinitionType: ValueDefinitionType) {
    return ValueDefinitionFieldType[ValueDefinitionType[valueDefinitionType] as keyof typeof ValueDefinitionFieldType];
  }

  private filterFields(
    valueDefinitionsWithFieldType: ValueDefinitionWithFieldTypeRow[],
    filters: Filters,
  ): ValueDefinitionWithFieldTypeRow[] {
    const rows = valueDefinitionsWithFieldType.filter(
      (valueDefinitionWithFieldType: ValueDefinitionWithFieldTypeRow) =>
        !this.ignoreFields.includes(valueDefinitionWithFieldType.id) &&
        this.supportedFieldTypes.includes(valueDefinitionWithFieldType.fieldType) &&
        (this.showInactiveFields ||
          valueDefinitionWithFieldType.valueDefinition?.active ||
          valueDefinitionWithFieldType.metricTableDefinition?.active) &&
        this.applyFilters(valueDefinitionWithFieldType, filters),
    );

    if (filters.sort === 'description') {
      rows.sort((a, b) => a.label.localeCompare(b.label));
    }

    return rows;
  }

  private applyFilters(row: ValueDefinitionWithFieldTypeRow, filters: Filters): boolean {
    const taxonomies = [...(row.valueDefinition?.taxonomies || []), ...(row.metricTableDefinition?.taxonomies || [])];
    return !filters.taxonomy || taxonomies.map((t) => t.id).includes(filters.taxonomy);
  }

  private getFilterOptions(taxonomies: Taxonomy[] | null): FilterBarOption[] | undefined {
    if (!taxonomies) {
      return;
    }

    const filters: FilterBarOption[] = [];

    if (this.withTaxonomiesFilter) {
      filters.push({
        title: this.translateService.instant('Framework Taxonomy Code'),
        id: ResourceType.taxonomy_code,
        optionType: FilterType.list,
        displayAll: true,
        options: ActionItemUtils.resourcesToActionItem(Object.values(taxonomies), ResourceType.taxonomy_code),
      });
    }

    if (this.withSort) {
      filters.push({
        icon: 'sort',
        title: this.translateService.instant('Sort'),
        id: ResourceType.sort,
        optionType: FilterType.list,
        displayAll: false,
        options: this.translateService.listResources(PartialMetricValueDefinitionSort),
        defaultValue: PartialMetricValueDefinitionSort.position,
      });
    }

    return filters;
  }

  private getMetricTablesRowsFromVDG$(metric: Metric): Observable<Record<string, ValueDefinitionWithFieldTypeRow>> {
    const dataTablesSet = new Set<string>();

    metric.value_definition_groups?.forEach((vdg: ValueDefinitionGroup) => {
      if (vdg.table_id && !this.ignoreFields.includes(vdg.table_id)) {
        dataTablesSet.add(vdg.table_id);
      }
    });

    if (dataTablesSet.size == 0) {
      return of({});
    }

    const getMetricTableRequests: Observable<MetricTableDefinition>[] = [];

    dataTablesSet.forEach((tableId) => {
      getMetricTableRequests.push(
        this.metricApiService
          .getMetricTable(metric.id, tableId, false, this.withTaxonomiesFilter)
          .pipe(map((response: ApiResponse<MetricTableDefinition>) => response.data)),
      );
    });

    return forkJoin(getMetricTableRequests).pipe(
      map((metricTableDefinitions: MetricTableDefinition[]) => {
        const metricTables: Record<string, ValueDefinitionWithFieldTypeRow> = {};

        metricTableDefinitions.forEach((metricTableDefinition) => {
          metricTables[metricTableDefinition.id] = {
            id: metricTableDefinition.id,
            metricTableDefinition,
            fieldType: ValueDefinitionFieldType.table,
            label: metricTableDefinition.title,
            tableAction: { type: ValueDefinitionFieldType.table },
          };
        });

        return metricTables;
      }),
    );
  }

  private fetchMetric(): Observable<Metric> {
    return this.metricId$.pipe(
      ObservableUtils.filterNullish(),
      switchMap((metricId: string) =>
        this.metricApiService
          .getMetric(metricId, {
            load_value_definition_groups: true,
            load_value_definitions: true,
            load_leaf_taxonomies: this.withTaxonomiesFilter,
          })
          .pipe(
            map((response: ApiResponse<Metric>) => {
              response.data.value_definition_groups?.sort((a, b) => a.position - b.position);
              response.data.value_definition_groups?.forEach((vdg) => {
                vdg.value_definitions?.sort((a, b) => a.position - b.position);
              });
              return response.data;
            }),
            tap(() => {
              this.selectedTable = undefined;
            }),
          ),
      ),
    );
  }

  private fetchValueDefinitionWithFieldTypeRows(
    metric: Metric,
    searchQuery: string,
    filters: Filters,
  ): Observable<ValueDefinitionWithFieldTypeRow[]> {
    let obs: Observable<Record<string, ValueDefinitionWithFieldTypeRow>>;

    if (this.metricTables) {
      obs = of(this.metricTables);
    } else if (this.supportedFieldTypes.includes(ValueDefinitionFieldType.table)) {
      obs = this.getMetricTablesRowsFromVDG$(metric);
    } else {
      obs = of({});
    }

    return obs.pipe(
      tap((metricTables: Record<string, ValueDefinitionWithFieldTypeRow>) => {
        this.metricTables = metricTables;
      }),

      map((metricTables: Record<string, ValueDefinitionWithFieldTypeRow>) =>
        this.metricValueDefinitionGroupsToRows(metric.value_definition_groups, { ...metricTables }),
      ),

      map((valueDefinitionsWithFieldType) => this.filterFields(valueDefinitionsWithFieldType, filters)),

      tap((valueDefinitionsWithFieldType) => {
        if (!this.taxonomies$.value) {
          const taxonomies: Record<string, Taxonomy> = {};

          if (this.withTaxonomiesFilter) {
            valueDefinitionsWithFieldType.forEach((v) => {
              [...(v.metricTableDefinition?.taxonomies || []), ...(v.valueDefinition?.taxonomies || [])].forEach(
                (taxonomy) => {
                  taxonomies[taxonomy.id] = taxonomy;
                },
              );
            });
          }

          this.taxonomies$.next(Object.values(taxonomies));
        }
      }),

      map((valueDefinitionsWithFieldType) =>
        valueDefinitionsWithFieldType.filter((valueDefinitionsWithFieldType: ValueDefinitionWithFieldTypeRow) =>
          StringUtils.includesSubstring(String(valueDefinitionsWithFieldType.label), searchQuery),
        ),
      ),

      map((valueDefinitionsWithFieldTypes: ValueDefinitionWithFieldTypeRow[]) =>
        valueDefinitionsWithFieldTypes.map((row: ValueDefinitionWithFieldTypeRow) => ({
          ...row,
          fieldTypeText: ValueDefinitionFieldTypeToText[row.fieldType],
        })),
      ),
    );
  }
}
