import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DashboardWidget } from '../../models';

@Component({
  selector: 'lib-value-definition-target-widget',
  templateUrl: './value-definition-target-widget.component.html',
  styleUrls: ['./value-definition-target-widget.component.scss'],
})
export class ValueDefinitionTargetWidgetComponent {
  @Input({ required: true }) widget!: DashboardWidget;
  @Input() showEditMenu: boolean = false;
  @Input() metricDetailsUrl: string = '';

  @Output() remove: EventEmitter<DashboardWidget> = new EventEmitter<DashboardWidget>();
  @Output() edit: EventEmitter<DashboardWidget> = new EventEmitter<DashboardWidget>();

  removeItem(item: DashboardWidget): void {
    this.remove.emit(item);
  }

  editItem(item: DashboardWidget): void {
    this.edit.emit(item);
  }
}
