import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../models';
import { UsersService } from '../../services/common';

@Component({
  selector: 'lib-profile-navigation-menu',
  templateUrl: './profile-navigation-menu.component.html',
  styleUrls: ['./profile-navigation-menu.component.scss', '../navigation.scss'],
})
export class ProfileNavigationMenuComponent implements OnInit {
  @Input({ required: true }) user!: User;
  @Input() profileRouterLink?: string;
  @Input() passwordRouterLink?: string;

  @Output() logout: EventEmitter<string> = new EventEmitter<string>();

  public userFirstLetter: string = '';
  public userDescription: string = '';
  public passwordManageOptionEnabled: boolean = false;

  constructor(private usersService: UsersService) {}

  ngOnInit(): void {
    this.userFirstLetter = this.user.first_name.charAt(0).toUpperCase();
    this.userDescription = this.usersService.getDescription(this.user);
    this.passwordManageOptionEnabled = this.user.allow_basic_auth;
  }

  public onLogout(): void {
    this.logout.emit('Logout - Client - manual deconnection from profile menu');
  }
}
