import { Icon } from '../icons/icons.model';
import { TipTypeDetailsIcon } from './type_details';

export interface TipIconConfig {
  icon: Icon;
  color: string;
}

export const TipIcon: Record<TipTypeDetailsIcon, TipIconConfig> = {
  [TipTypeDetailsIcon.tip]: { icon: 'guidance', color: 'success' },
  [TipTypeDetailsIcon.info]: { icon: 'info', color: 'primary' },
  [TipTypeDetailsIcon.warning]: { icon: 'warning', color: 'warning' },
  [TipTypeDetailsIcon.none]: { icon: '', color: 'other' },
};
