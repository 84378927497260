<div
  class="card mb-1 selectable"
  [ngClass]="{ selected: selected, deactivated: deactivated }"
  (click)="cardSelect.emit()"
  data-testid="metricStatusCardContainer"
>
  <div class="fx-row justify-space-between pb-1">
    <div *ngIf="displayIndicatorCode" class="fx-row">
      <div [title]="indicatorCodeOverride ?? indicator?.code" class="color-grey-800 pr-1">
        {{ indicatorCodeOverride ?? indicator?.code }}
      </div>
      <mat-icon *ngIf="important" class="icon-md color-error" svgIcon="status" data-testid="importantFlag"></mat-icon>
    </div>
    <div *ngIf="!displayIndicatorCode" class="fx-row item-title mat-body-1 color-primary-900">
      {{ indicatorDescriptionOverride ?? indicator?.description }}
    </div>

    <div class="fx-row align-center color-grey-800" *ngIf="deactivated; else status">
      <span>{{ "deactivated" | translate }}</span>
    </div>

    <ng-template #status>
      <div class="fx-row align-center color-grey-800" *ngIf="vgsetStatusValue" [ngClass]="[vgsetStatusValue]">
        <mat-icon
          class="icon-md mr-1"
          [svgIcon]="vgsetStatusIcon"
          [title]="vgsetStatusIcon"
          data-testid="statusIcon"
        ></mat-icon>
        <span>{{ vgsStatusToText[vgsetStatusValue] }}</span>
      </div>
    </ng-template>
  </div>
  <div class="fx-row justify-space-between align-center">
    <div *ngIf="displayIndicatorCode" class="fx-row justify-start item-title mat-body-2 color-primary-900">
      {{ indicatorDescriptionOverride ?? indicator?.description }}
    </div>
    <div class="fx-row justify-end locked-icon" [class.w-100]="!displayIndicatorCode">
      <mat-icon
        *ngIf="isLocked"
        data-testid="lockedIcon"
        svgIcon="locked"
        title="{{ 'This metric is locked' | translate }}"
        aria-label="locked"
      ></mat-icon>
    </div>
  </div>
</div>
