import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, IntegrationPartnerLogin } from '../../../models';

import { Observable } from 'rxjs';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class AdminIntegrationPartnerLoginService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;
  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.integration_partner_logins;
  }

  public listIntegrationPartnerLogins(): Observable<ApiResponse<IntegrationPartnerLogin[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/integration_partner_logins`);
  }

  public updateIntegrationPartnerLogin(
    payload: Partial<IntegrationPartnerLogin>,
  ): Observable<ApiResponse<IntegrationPartnerLogin>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/integration_partner_logins`, payload);
  }
}
