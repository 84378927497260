import { Component, DoCheck, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Observable, of, pairwise, Subject, takeUntil } from 'rxjs';
import { ValueFormControl } from '../../models/valueFormControl';
import {
  ChoiceFieldWidgetType,
  ChoiceTypeDetails,
  ConfirmationDialogConfig,
  DialogResult,
  Indicator,
  MetricCategory,
  ReportIntegrationType,
  OptionListItem,
  Status,
  ValueDefinitionSize,
} from '../../../models';
import {
  MultiSelectChipInputComponent,
  SearchableSelectInputComponent,
  SearchableSelectInputParameters,
} from '../../../components';
import { MetricEditorCheckboxFieldComponent } from './metric-editor-checkbox-field/metric-editor-checkbox-field.component';
import { MetricEditorRadioFieldComponent } from './metric-editor-radio-field/metric-editor-radio-field.component';
import { ChoiceFormGroup } from './forms/metric-editor-choice-field-form';
import { MatChipInputEvent } from '@angular/material/chips';
import { StringUtils } from '../../../classes';
import { startWith, tap } from 'rxjs/operators';
import { ValueDefinitionConstants } from '../../../constants';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { TranslateService } from '../../../services/common';
import { FeatureFlagService } from '../../../feature-flag';
import { OptionListsApiService } from '../../../services/api-services';

@Component({
  selector: 'lib-metric-editor-choice-field',
  templateUrl: './metric-editor-choice-field.component.html',
  styleUrls: ['./metric-editor-choice-field.component.scss'],
})
export class MetricEditorChoiceFieldComponent implements OnInit, OnDestroy, DoCheck {
  @Input({ required: true }) valueFormControl!: ValueFormControl<ChoiceTypeDetails>;
  @Input() isPublic: boolean = false;
  @Input() indicator?: Indicator;
  @Input() integrationType: ReportIntegrationType | null = null;

  @ViewChild('inputFieldRef') inputFieldRef!:
    | SearchableSelectInputComponent<string>
    | MetricEditorCheckboxFieldComponent
    | MultiSelectChipInputComponent
    | MetricEditorRadioFieldComponent;

  readonly eChoiceFieldWidgetType = ChoiceFieldWidgetType;

  private destroy$ = new Subject<void>();

  choiceFormGroup?: ChoiceFormGroup;

  hint: string = '';
  label: string = '';
  widgetType: ChoiceFieldWidgetType = ChoiceFieldWidgetType.single_select;
  multiChoice: boolean = false;
  choicesList$: Observable<string[]> = of([]);
  filteredChoiceList: string[] = [];
  displayExplanation: boolean = false;
  explanationLabel: string = '';
  explanationRequired: boolean = false;
  explanationMaxLength: number = ValueDefinitionConstants.DEFAULT_EXPLANATION_MAX_LENGTH;
  allowAddOption: boolean = false;
  size: ValueDefinitionSize = ValueDefinitionSize.large;
  optionListItems: OptionListItem[] = [];
  optionListShowFFEnabled: boolean = false;

  constructor(
    private readonly featureFlagService: FeatureFlagService,
    private dialogsService: DialogsService,
    private translateService: TranslateService,
    private optionListsApiService: OptionListsApiService,
  ) {
    this.optionListShowFFEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled(['option_list_shown_enabled']);
  }

  ngOnInit(): void {
    this.initProperties();
    this.choiceFormGroup = new ChoiceFormGroup(this.valueFormControl, {
      multiChoice: this.multiChoice,
      displayExplanation: this.displayExplanation,
      explanationRequired: this.explanationRequired,
    });

    this.initChoiceList();

    if (this.optionListShowFFEnabled && this.indicator?.category === MetricCategory.THIRD_PARTY) {
      this.subscribeToValuesControlChanges();
    }
  }

  ngOnDestroy(): void {
    this.updateField();
    this.choiceFormGroup?.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngDoCheck() {
    // TODO: Replace with subscription when this is merged :
    // https://github.com/angular/angular/issues/10887
    this.choiceFormGroup?.syncTouchedStatus();
  }

  setFocus(): void {
    this.inputFieldRef.setFocus();
  }

  private initChoiceList(): void {
    const typeDetails = this.valueFormControl.valueRef.type_details;
    const selectionSetId = typeDetails.selection_set_id;
    if (selectionSetId) {
      const applyCDPTagsFiltering = this.integrationType == ReportIntegrationType.CDP;
      this.choicesList$ = this.optionListsApiService
        .listOptionListItemsFromSource(
          selectionSetId,
          { order_by: 'position', order_by_direction: 'asc' },
          typeDetails.selection_set_source,
          this.isPublic,
          applyCDPTagsFiltering,
        )
        .pipe(
          map((res) => res.data),
          tap((items) => {
            this.optionListItems = items;
            if (this.optionListShowFFEnabled && this.indicator?.category === MetricCategory.THIRD_PARTY) {
              this.updateChoiceFormGroupWithSelectionSetItems();
            }
          }),
          map((items) => items.map((item) => item.name)),
          tap((items) => {
            this.filteredChoiceList = items;
          }),
        );
    }
  }

  private initProperties() {
    this.hint = this.valueFormControl.valueRef.hint ?? '';
    this.label = this.valueFormControl.valueRef.label ?? '';
    this.widgetType = this.valueFormControl.valueRef.type_details.widget_type ?? ChoiceFieldWidgetType.single_select;
    if (this.optionListShowFFEnabled && this.indicator?.category === MetricCategory.THIRD_PARTY) {
      this.displayExplanation = false;
      this.explanationRequired = false;
    } else {
      this.displayExplanation = this.valueFormControl.valueRef.type_details.display_explanation ?? false;
      this.explanationRequired = this.valueFormControl.valueRef.type_details.explanation_required ?? false;
    }
    this.explanationLabel = this.valueFormControl.valueRef.type_details.explanation_label ?? '';
    this.size = this.valueFormControl.valueRef.size;
    this.multiChoice = this.valueFormControl.valueRef.type_details.multi_choices ?? false;
    this.allowAddOption = this.valueFormControl.valueRef.type_details.allow_add_option ?? false;
  }

  private subscribeToValuesControlChanges(): void {
    this.choiceFormGroup?.valuesControl.valueChanges
      .pipe(
        startWith(this.choiceFormGroup.valuesControl.value),
        pairwise(),
        map(
          ([previousValue, currentValue]): {
            previousValue: string | string[];
            currentValue: string | string[];
            displayExplanation: boolean;
          } => {
            const safeCurrentValue =
              typeof currentValue === 'string' || Array.isArray(currentValue) ? currentValue : '';

            return {
              previousValue,
              currentValue: safeCurrentValue,
              displayExplanation: this.checkIfDisplayExplanationEnabledForSelectedOptions(safeCurrentValue),
            };
          },
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(({ previousValue, displayExplanation }) => {
        this.updateTextFormControl(displayExplanation, previousValue);
      });
  }

  private updateChoiceFormGroupWithSelectionSetItems(): void {
    const selectedValues: string | string[] = this.choiceFormGroup?.valuesControl.value;
    const displayExplanation = this.checkIfDisplayExplanationEnabledForSelectedOptions(selectedValues);
    if (displayExplanation) {
      this.choiceFormGroup?.addAdditionalTextControl(String(this.valueFormControl.value?.additional_text ?? ''));
      this.choiceFormGroup?.handleTextFormControl(String(this.valueFormControl.value?.additional_text ?? ''));
      this.choiceFormGroup?.updateValueFormControl(true);
    }
  }

  private checkIfDisplayExplanationEnabledForSelectedOptions(selectedValues: string | string[]): boolean {
    const valuesArray = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    return valuesArray.some((value) =>
      this.optionListItems.some((item) => item.name === value && item.display_explanation),
    );
  }

  private confirmTextRemovalDialog(previousValue: string | string[]): void {
    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Change selection'),
          primaryBtn: this.translateService.instant('OK'),
          warningMsg: this.translateService.instant('Are you sure you want to update this option?'),
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result?: DialogResult) => {
        if (result?.status === Status.CONFIRMED) {
          this.choiceFormGroup?.removeAdditionalTextControl();
          this.choiceFormGroup?.updateValueFormControl(true);
        } else {
          this.choiceFormGroup?.valuesControl.setValue(previousValue, { emitEvent: true });
          this.choiceFormGroup?.updateValueFormControl(true);
        }
      });
  }

  private updateTextFormControl(displayExplanation: boolean, previousValue?: string | string[]): void {
    if (displayExplanation) {
      const textValue: string = this.choiceFormGroup?.valuesControl?.value?.additional_text ?? '';
      if (!this.choiceFormGroup?.additionalTextControl) {
        this.choiceFormGroup?.addAdditionalTextControl(textValue);
      }
    } else {
      if (this.choiceFormGroup?.additionalTextControl?.value && previousValue) {
        this.confirmTextRemovalDialog(previousValue);
      } else {
        this.choiceFormGroup?.removeAdditionalTextControl();
      }
    }
  }

  public addValue(event: MatChipInputEvent): void {
    const value = event.value;
    const currentValues = this.choiceFormGroup?.valuesControl.value as string[] | null;
    this.choiceFormGroup?.valuesControl.setValue([...(currentValues ?? []), value]);
    this.choiceFormGroup?.valuesControl.markAsTouched();
    this.choiceFormGroup?.valuesControl.markAsDirty();
  }

  public filterChoices(choiceList: string[], params?: SearchableSelectInputParameters): void {
    this.filteredChoiceList = choiceList.filter((choice) =>
      StringUtils.includesSubstring(choice, params?.searchValue ?? ''),
    );
  }

  public updateField(): void {
    this.choiceFormGroup?.blurAdditionalTextControl();
  }
}
