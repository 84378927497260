import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { TrimMethod } from '../../../directives/trim-whitespace/trim-whitespace.model';
import { ValueDefinitionSize } from '../../../models';
import { ValidationMessageService } from '../../../services/common';
import { WhitespaceValidators } from '../../../validators';
import { SubscriptSizing } from '@angular/material/form-field';

let nextId = 0;

@Component({
  selector: 'lib-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnChanges {
  @Input() label = '';
  @Input() control?: UntypedFormControl;
  @Input() messages: ValidationErrors = {};
  @Input() hint?: string;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() clearText?: boolean;
  @Input() maxCharacterCount?: number;
  @Input() type: 'text' | 'email' | 'password' | 'number' = 'text';
  @Input() togglePassword?: boolean;
  @Input() placeholder = '';
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() readonly = false;
  @Input() autofocus = false;
  @Input() removePadding = false;
  @Input() trimWhitespaceMethod: TrimMethod = TrimMethod.both;
  @Input() autocomplete: string = '';
  @Input() labelPosition: 'top' | 'left' = 'top';
  @Input() subscriptSizing: SubscriptSizing = 'fixed';

  @Output() handleBlur = new EventEmitter<void>();

  @ViewChild('input') inputElement: ElementRef<HTMLInputElement> | undefined;

  readonly _inputId = `text-input-${nextId++}`;

  required: boolean = false;
  errorMessages: ValidationErrors = {};

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnChanges(): void {
    this.initializeInput();
  }

  private initializeInput() {
    this.control?.addValidators(WhitespaceValidators.hasOnlyWhitespace());
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  togglePwdType() {
    this.type = this.type === 'password' ? 'text' : 'password';
  }

  setFocus(): void {
    this.inputElement?.nativeElement.focus();
  }

  setBlur(): void {
    this.inputElement?.nativeElement.blur();
  }
}
