import { Pipe, PipeTransform } from '@angular/core';

import lodashSome from 'lodash/some';

@Pipe({ name: 'some' })
export class SomePipe implements PipeTransform {
  transform<T, V>(
    options: T[] | null | undefined,
    some?: string | [string, unknown] | ((option: T, value?: V) => boolean),
    value?: V,
  ): boolean {
    return lodashSome(
      Object.values(options || []),
      typeof some === 'function' ? (option) => some(option, value) : some,
    );
  }
}
