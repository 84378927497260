<div class="loading-container" #loader role="alert" aria-label="Loading" id="spinner">
  <div class="logo-wrapper">
    <div class="spinner"></div>
    <div class="logo">
      <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path
            d="M5,8 L5,16 L1,16 L1,11.2092486 L5,8 Z M9.2578125,0 L9.28515625,0.0280537697 C11.0442708,0.0280537697 12.4296875,0.598480421 13.4505208,1.73933372 C14.4622396,2.88018703 14.9908854,4.39509059 15,6.29339568 L15,6.29339568 L15,16 L10.8710938,16 L10.8710938,6.86382233 C10.8710938,5.94739918 10.6158854,5.21800117 10.0963542,4.64757452 C9.57682292,4.07714787 8.89322917,3.79661017 8.06380208,3.79661017 C7.8359375,3.79661017 7.6171875,3.81531268 7.41666667,3.86206897 C7.34375,3.87142022 7.25260417,3.89012274 7.125,3.93687902 C6.95182292,3.99298656 6.78776042,4.06779661 6.6328125,4.15195792 C5.7578125,4.59146698 4.09895833,5.65751023 1,8.17299825 L1,8.17299825 L1,0.345996493 L4.81901042,0.345996493 L4.81901042,1.76738749 C5.95833333,0.598480421 7.43489583,0 9.2578125,0 L9.2578125,0 Z"
            fill="currentColor"
          ></path>
        </g>
      </svg>
    </div>
  </div>
  <div class="text">{{ message }}</div>
</div>
