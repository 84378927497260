<ng-container [ngSwitch]="typeDetails.widget_type">
  <ng-container *ngSwitchCase="eChoiceWidgetType.single_select">
    <lib-metric-structure-label
      [isTriggered]="isTriggered"
      [isTrigger]="isTrigger"
      [isInactive]="valueDefinition | isDeactivated"
      >{{ valueDefinition.label }}</lib-metric-structure-label
    >
    <mat-form-field data-testid="single-select" [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }">
      <mat-select>
        <mat-option *ngFor="let option of options$ | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
      <mat-hint>{{ valueDefinition.hint }}</mat-hint>
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="eChoiceWidgetType.multi_chip">
    <lib-metric-structure-label
      [isTriggered]="isTriggered"
      [isTrigger]="isTrigger"
      [isInactive]="valueDefinition | isDeactivated"
      >{{ valueDefinition.label }}</lib-metric-structure-label
    >
    <div
      data-testid="multi-chip"
      class="fx-col w-100"
      [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }"
    >
      <lib-multi-select-chip-input [control]="dummyControl" [hint]="valueDefinition.hint"></lib-multi-select-chip-input>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="eChoiceWidgetType.radio_buttons">
    <lib-metric-structure-label
      [isTriggered]="isTriggered"
      [isTrigger]="isTrigger"
      [isInactive]="valueDefinition | isDeactivated"
      >{{ valueDefinition.label }}</lib-metric-structure-label
    >
    <div
      data-testid="radio-btn"
      class="fx-col w-100"
      [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }"
    >
      <mat-hint class="mb-2">{{ valueDefinition.hint }} </mat-hint>
      <mat-radio-group color="primary" *ngFor="let option of options$ | async">
        <mat-radio-button [value]="option" class="mat-body-2">
          {{ option }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="eChoiceWidgetType.checkboxes">
    <lib-metric-structure-label
      [isTriggered]="isTriggered"
      [isTrigger]="isTrigger"
      [isInactive]="valueDefinition | isDeactivated"
      >{{ valueDefinition.label }}</lib-metric-structure-label
    >
    <div
      data-testid="checkboxes"
      class="fx-col w-100"
      [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }"
    >
      <mat-hint class="mb-2">{{ valueDefinition.hint }} </mat-hint>
      <mat-checkbox color="primary" *ngFor="let option of options$ | async" [value]="option" class="mat-body-2">
        {{ option }}
      </mat-checkbox>
    </div>
  </ng-container>
</ng-container>
