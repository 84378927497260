import { Component, HostBinding, Input } from '@angular/core';
import { TableColumn } from '../../../../../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'th[lib-sortable-horizontal-resizable]',
  templateUrl: './sortable-horizontal-resizable.component.html',
  styleUrls: ['./sortable-horizontal-resizable.component.scss', '../horizontal-resizable.component.scss'],
})
export class SortableHorizontalResizableComponent {
  @Input('lib-sortable-horizontal-resizable') isResizable: boolean = false;
  @Input({ required: true }) tableColumn!: TableColumn<any>;

  @HostBinding('style.width.px') width: number | string | null = 'auto';

  public onResize(width: number) {
    this.width = width;
  }
}
