import { Pipe, PipeTransform } from '@angular/core';
import { UnitsService } from '../../units';
import { Unit } from '../../models';

const DEFAULT_UNIT = 'default';

@Pipe({
  name: 'unitSymbol',
})
export class UnitSymbolPipe implements PipeTransform {
  constructor(private readonly unitsService: UnitsService) {}

  transform(unitCode?: string, withParenthesis = false): string {
    if (!unitCode) {
      return '';
    }

    const unit: Unit | undefined = this.unitsService.getUnit(unitCode);
    if (!unit) {
      return '';
    }

    const unitSymbol = unit.symbol ?? unit.code;
    return unitSymbol === DEFAULT_UNIT ? '' : withParenthesis ? `(${unitSymbol})` : unitSymbol;
  }
}
