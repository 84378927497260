import { Component, Renderer2 } from '@angular/core';
import { take } from 'rxjs';
import { SearchOptions } from '../../../../../models';
import { SearchService } from '../../../../../search/services/search.service';
import { DocumentSearchDirective } from '../document-search.directive';

@Component({
  selector: 'lib-metrics-library-doc-search',
  templateUrl: './metrics-library-document-search.component.html',
  styleUrls: ['./metrics-library-document-search.component.scss'],
})
export class MetricsLibraryDocumentSearchComponent extends DocumentSearchDirective {
  constructor(
    readonly renderer: Renderer2,
    private searchService: SearchService,
  ) {
    super(renderer);
  }

  search(): void {
    if (this.searchOptions) {
      const searchOptions = <SearchOptions>JSON.parse(this.searchOptions);
      this.noData = !Object.keys(searchOptions.filters).length && !searchOptions.query.keywords;
      this.loaded = false;
      this.searchService
        .search(searchOptions)
        .pipe(take(1))
        .subscribe((result) => {
          this.itemCollection = result;
          if (!this.itemCollection.items.length || this.itemCollection.items.length >= this.itemCollection.count) {
            this.hideSpinner();
          } else {
            // wait 0.5s for the dummies to fade out
            // spinner element is not available for observer before items become visible
            setTimeout(() => {
              this.scrollObserver.observe(this.spinner.nativeElement);
            }, 500);
          }
          this.loaded = true;
        });
    }
  }

  loadMore(): void {
    const searchOptions = <SearchOptions>JSON.parse(this.searchOptions);
    searchOptions.from = this.itemCollection!.items.length;
    this.searchService.search(searchOptions).subscribe((result) => {
      this.itemCollection!.items = this.itemCollection!.items.concat(result.items);
      if (this.itemCollection!.items.length >= this.itemCollection!.count) {
        this.scrollObserver.disconnect();
        this.hideSpinner();
      }
    });
  }
}
