<lib-dialog-title [title]="'Link a document' | translate" (closeEvent)="close()"></lib-dialog-title>

<div mat-dialog-content class="fit-lib-search">
  <lib-search
    [layout]="layout"
    [presentation]="presentation"
    [itemType]="itemTypeDoc"
    [properties]="searchProperties"
    [showActions]="false"
    (action)="handleSearchAction($event)"
    [searchPlaceholder]="'Search by name' | translate"
    [mode]="'select'"
    grid="3"
  >
    <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
  </lib-search>
</div>

<div mat-dialog-actions>
  <a class="btn btn-success mr-2" [ngClass]="{ 'disabled btn-disabled': !selectedDoc }" (click)="linkDocument()">
    {{ "Link a document" | translate }}
  </a>
  <button lib-button buttonType="link" (click)="close()">{{ "Cancel" | translate }}</button>
</div>
