import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { DummyLoaderComponent } from './dummy-loader.component';

@NgModule({
  declarations: [LoaderComponent, DummyLoaderComponent],
  imports: [CommonModule, TranslateModule],
  exports: [LoaderComponent, DummyLoaderComponent],
})
export class LoaderModule {}
