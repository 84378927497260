import { Injectable } from '@angular/core';
import { TranslateService } from '../translate/translate.service';
import { ActionItem, FiscalYear, FiscalYearStatus } from '../../../models';
import { FiscalYearsStatusTranslation } from '../../../translations/search/fiscal-years.translation';

@Injectable({
  providedIn: 'root',
})
export class FiscalYearsUtilService {
  private currentYear = new Date().getFullYear();

  constructor(private readonly translateService: TranslateService) {}

  public appendFiscalYearStatus(fiscalYearItem: ActionItem<FiscalYear>): ActionItem<FiscalYear> {
    return {
      ...fiscalYearItem,
      title:
        fiscalYearItem.item?.status === FiscalYearStatus.CLOSED ||
        fiscalYearItem.item?.status === FiscalYearStatus.DRAFT
          ? `${fiscalYearItem.title} (${this.translateService.instant(
              FiscalYearsStatusTranslation[fiscalYearItem.item.status],
            )})`
          : fiscalYearItem.title,
    };
  }

  public getDefaultFiscalYear(
    fiscalYears: ActionItem<FiscalYear>[],
    fiscalYearItem?: ActionItem<FiscalYear>,
  ): ActionItem<FiscalYear> {
    let defaultFiscalYear = fiscalYearItem ?? fiscalYears.find((year) => year.id === this.currentYear.toString());
    if (!defaultFiscalYear) {
      defaultFiscalYear = FiscalYearsUtilService.getLatestFiscalYear(fiscalYears);
    }
    return defaultFiscalYear;
  }

  public static getLatestFiscalYear(fiscalYearsActionItems: ActionItem<FiscalYear>[]): ActionItem<FiscalYear> {
    return [...fiscalYearsActionItems].sort((y1, y2) => y2.item!.year - y1.item!.year)[0];
  }

  public static getPastYearsRangeOptions(years: ActionItem<FiscalYear>[]): ActionItem<number>[] {
    const ascSortedFiscalYearsActionItems = [...years].sort((y1, y2) => y2.item!.year - y1.item!.year);
    const startYear: number = +ascSortedFiscalYearsActionItems.slice(-1)[0].title;
    const endYear: number = +ascSortedFiscalYearsActionItems[0].title;

    if (startYear > endYear) {
      throw new Error('Start year cannot be greater than end year.');
    }

    const pastYearsRangeOptions: ActionItem<number>[] = [];

    for (let year = startYear; year <= endYear; year++) {
      const yearDiff = endYear - year + 1;

      pastYearsRangeOptions.push({
        title: yearDiff.toString(),
        id: yearDiff.toString(),
        item: yearDiff,
      });
    }

    return pastYearsRangeOptions;
  }

  public static getNthMostRecentFiscalYear(
    nth: number,
    fiscalYearActionItems: ActionItem<FiscalYear>[],
  ): ActionItem<FiscalYear> {
    return [...fiscalYearActionItems].sort((y1, y2) => y2.item!.year - y1.item!.year)[nth - 1];
  }
}
