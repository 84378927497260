import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionItem } from '../../../models';
import { ButtonProps, OptionProps } from '../../models';

@Component({
  selector: 'lib-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss'],
})
export class MultiSelectFilterComponent implements OnInit {
  @Input() optionProps?: OptionProps;
  @Input() buttonProps?: ButtonProps;

  @Output() optionsSelected = new EventEmitter<ActionItem[]>();

  buttonTitle: string = '';
  private selectedValues: ActionItem[] | undefined = [];

  ngOnInit(): void {
    this.selectedValues = this.optionProps?.options?.filter((menuitem) => menuitem.selected);

    this.initializeDefaultValues();

    this.buttonTitle = this.computeButtonTitle();
  }

  onMenuClosed() {
    this.optionsSelected.emit(this.selectedValues);
  }

  onValueSelect() {
    this.selectedValues = this.optionProps?.options?.filter((menuitem) => menuitem.selected);
    this.buttonTitle = this.computeButtonTitle();
  }

  computeButtonTitle(): string {
    if (this.selectedValues) {
      if (this.selectedValues.length == 0) {
        return this.optionProps?.title ?? '';
      }
      if (this.selectedValues.length > 1) {
        return `${this.selectedValues[0].title} (${this.selectedValues.length})`;
      }

      return this.selectedValues[0].title;
    }

    return this.optionProps?.title ?? '';
  }

  initializeDefaultValues(): void {
    if (this.selectedValues?.length == 0) {
      this.selectedValues = this.optionProps?.options?.filter((menuitem) =>
        this.optionProps?.multiSelectDefaultValues?.includes(menuitem.id),
      );

      this.optionProps?.options?.forEach((menuItem) => {
        if (this.optionProps?.multiSelectDefaultValues?.includes(menuItem.id)) {
          menuItem.selected = true;
        }
      });
    }
  }
}
