import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '../../../services/common';

@Component({
  selector: 'lib-rich-text-card',
  templateUrl: './rich-text-card.component.html',
  styleUrls: ['./rich-text-card.component.scss'],
})
export class RichTextCardComponent {
  @Input() selected: boolean = false;
  @Input() control?: UntypedFormControl;
  @Input() label: string = '';
  @Input() id: string = '';
  @Input() placeholder?: string = this.translateService.instant('Type message here...');

  @Output() fieldSelect = new EventEmitter<string>();
  @Output() fieldBlur = new EventEmitter<string>();

  constructor(private translateService: TranslateService) {}
  select() {
    this.fieldSelect.emit(this.id);
  }

  blur() {
    this.fieldBlur.emit(this.id);
  }
}
