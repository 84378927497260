import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { combineLatestWith, filter, switchMap, take } from 'rxjs/operators';
import { map, Observable, of } from 'rxjs';
import { provideComponentStore } from '@ngrx/component-store';
import { OptionListStore } from './option-list.store';
import {
  ActionItem,
  ConfirmationDialogConfig,
  FilterBarSelection,
  OptionList,
  OptionListCategory,
  OptionListItem,
  Permission,
  Presentation,
  Status,
  TableColumn,
  TableActionMenuItemEvent,
  TablePageEvent,
  TagType,
  EmptyResults,
  ApiResponse,
  DialogResult,
} from '../../models';
import { OptionListForm } from '../option-list-form/option-list-form';
import { OptionListsApiService } from '../../services/api-services';
import { AuthService, TranslateService } from '../../services/common';
import { OptionListTypeTranslations } from '../../translations';
import { OptionListItemForm } from '../option-list-item-form/option-list-item-form';
import { ConfirmationDialogComponent, DialogsService } from '../../dialogs';
import { FormGroup } from '@angular/forms';

interface Data {
  filtered: boolean;
  isLoading: boolean;
  optionList: OptionList;
  optionListCategories: ActionItem<OptionListCategory>[];
  optionListCategory?: OptionListCategory;
  optionListItems: OptionListItem[];
  total: number;
}

@Component({
  selector: 'lib-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss'],
  providers: [provideComponentStore(OptionListStore)],
})
export class OptionListComponent implements OnInit, AfterContentInit {
  @ViewChild('booleanCell', { static: true }) booleanCell?: TemplateRef<{ element: string }>;

  @Input({ required: true }) managePermission!: Permission;
  @Input({ required: true }) optionListId!: string;
  @Input() withPublicField: boolean = false;
  @Input() withResourceLists: boolean = false;
  @Input() withTagFields: boolean = false;
  @Input() withTypeField: boolean = false;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  public readonly eTagType = TagType;
  public readonly ePresentation = Presentation;
  public readonly eOptionListTypeTranslations: Record<string, string> = OptionListTypeTranslations;
  public readonly emptyResults: EmptyResults = { title: this.translateService.instant('No result found') };

  public canManageOptionLists: boolean = false;
  public data$?: Observable<Data>;
  public isOptionListSidebarVisible: boolean = false;
  public isOptionListItemSidebarVisible: boolean = false;
  public optionListForm?: OptionListForm;
  public optionListItemForm?: OptionListItemForm;
  public optionListItemTableColumns: TableColumn<OptionListItem>[] = [];

  public optionListOptionActionMenu: TableActionMenuItemEvent<OptionListItem>[] = [
    {
      label: this.translateService.instant('View'),
      icon: 'eye',
      onClick: (optionListItem: OptionListItem) => this.setOptionListItemSidebarVisible(true, optionListItem),
    },
    {
      isSeparator: true,
    },
    {
      label: this.translateService.instant('Delete'),
      icon: 'trash',
      onClick: (optionListItem: OptionListItem) => this.deleteOptionListItem(optionListItem),
    },
  ];

  constructor(
    private readonly authService: AuthService,
    private readonly dialogsService: DialogsService,
    private readonly optionListsApiService: OptionListsApiService,
    private readonly optionListStore: OptionListStore,
    private readonly translateService: TranslateService,
  ) {
    this.data$ = optionListStore.optionListItems$.pipe(
      combineLatestWith(
        optionListStore.filterState$,
        optionListStore.optionList$,
        optionListStore.optionListCategories$,
        optionListStore.optionListCategory$,
        optionListStore.isLoading$,
        optionListStore.total$,
      ),
      map(([optionListItems, filterState, optionList, optionListCategories, optionListCategory, isLoading, total]) => ({
        filtered: Boolean(filterState.active || filterState.searchQuery),
        isLoading,
        optionList,
        optionListCategories,
        optionListCategory,
        optionListItems,
        total,
      })),
    );
  }

  public ngOnInit(): void {
    this.canManageOptionLists = Boolean(this.authService.user?.permissions.includes(this.managePermission));
    this.optionListStore.optionList$.pipe(take(1)).subscribe((optionList) => {
      this.initializeOptionListForm(optionList);
    });
    this.optionListStore.initialize(this.optionListId);
  }

  public ngAfterContentInit(): void {
    this.setupColumns();
  }

  public deleteOptionListItem(optionListItem: OptionListItem): void {
    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Delete option'),
          warningMsg: this.translateService.instant('Are you sure you want to delete this option?'),
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result?.status === Status.CONFIRMED),
        switchMap(() => this.optionListsApiService.deleteOptionListItem(this.optionListId, optionListItem.id)),
      )
      .subscribe(() => {
        this.optionListStore.fetchOptionListItems();
      });
  }

  public onOrderChange = (optionListItem: OptionListItem, newPosition: number): void => {
    this.optionListsApiService.moveOptionListItem(this.optionListId, optionListItem.id, newPosition).subscribe();
  };

  public onPageChange = (event: TablePageEvent): void => {
    this.optionListStore.updatePaginationState({ currentPage: event.currentPage, pageSize: event.pageSize });
  };

  public onSearchChange(searchQuery?: string): void {
    this.optionListStore.updateSearchQuery(searchQuery);
  }

  public onFilterChange(filters: FilterBarSelection[]): void {
    this.optionListStore.updateFilters(filters);
  }

  public saveOptionListItem(optionList: OptionList): void {
    if (!this.optionListItemForm) {
      return;
    }

    const payload = this.optionListItemForm.toModel();
    const optionListItemId = this.optionListItemForm.controls.optionListItemId.value;
    let obs: Observable<ApiResponse<OptionListItem>>;
    let validationObs: Observable<boolean>;

    if (optionListItemId) {
      obs = this.optionListsApiService.editOptionListItem(optionList.id, optionListItemId, payload);
      validationObs = this.optionListsApiService
        .validateEditOptionListItem(optionList.id, optionListItemId, payload)
        .pipe(map((validation) => validation.is_used));
    } else {
      obs = this.optionListsApiService.addOptionListItem(optionList.id, payload);
      validationObs = of(false);
    }

    validationObs
      .pipe(
        switchMap((isUsed) => {
          if (isUsed) {
            return this.dialogsService
              .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
                data: {
                  title: this.translateService.instant('Update option'),
                  primaryBtn: this.translateService.instant('Proceed'),
                  warningMsg: this.translateService.instant(
                    'This option is saved in your Metric Library for one or more choice fields. Changing the name of this option will reset the selected values. Do you want to proceed?',
                  ),
                },
              })
              .afterClosed()
              .pipe(
                filter((result?: DialogResult) => Boolean(result)),
                map((result?: DialogResult) => result?.status === Status.CONFIRMED),
                switchMap((confirmed) => (confirmed ? obs : of(null))),
              );
          }

          return obs;
        }),
      )
      .subscribe((res) => {
        if (res) {
          this.optionListItemForm?.reset();
          this.optionListStore.fetchOptionListItems();
          this.setOptionListItemSidebarVisible(false);
        }
      });
  }

  public setOptionListItemSidebarVisible(isOptionListItemSidebarVisible = false, item?: OptionListItem): void {
    if (isOptionListItemSidebarVisible !== this.isOptionListItemSidebarVisible) {
      this.checkChanges(isOptionListItemSidebarVisible, this.optionListItemForm).subscribe((confirmed) => {
        if (confirmed) {
          this.optionListItemForm = new OptionListItemForm(this.optionListsApiService, this.optionListId, item);
          this.isOptionListItemSidebarVisible = isOptionListItemSidebarVisible;
        }
      });
    }
  }

  public setOptionListSidebarVisible(isOptionListSidebarVisible = false, optionList: OptionList): void {
    if (isOptionListSidebarVisible !== this.isOptionListSidebarVisible) {
      this.checkChanges(isOptionListSidebarVisible, this.optionListForm).subscribe((confirmed) => {
        if (confirmed) {
          this.isOptionListSidebarVisible = isOptionListSidebarVisible;
          this.initializeOptionListForm(optionList);
        }
      });
    }
  }

  public updateOptionList(optionList: OptionList): void {
    const payload = this.optionListForm?.toModel();

    if (payload) {
      this.optionListsApiService.editOptionList(optionList.id, payload).subscribe((res) => {
        this.optionListStore.updateOptionListState(res.data);
        this.initializeOptionListForm(res.data);
        this.isOptionListSidebarVisible = false;
      });
    }
  }

  private checkChanges(visible: boolean, form?: FormGroup): Observable<boolean> {
    let obs = of(true);

    if (form?.touched && !visible) {
      obs = this.dialogsService
        .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
          data: {
            primaryBtn: this.translateService.instant('Discard'),
            title: this.translateService.instant('Discard changes'),
            warningMsg: this.translateService.instant('Are you sure you want to discard your changes?'),
          },
        })
        .afterClosed()
        .pipe(map((result) => result?.status === Status.CONFIRMED));
    }

    return obs;
  }

  private setupColumns(): void {
    this.optionListItemTableColumns = [
      {
        name: this.translateService.instant('Name'),
        dataKey: 'name',
      },
      {
        name: this.translateService.instant('Show explanation'),
        dataKey: 'display_explanation',
        cellTemplate: this.booleanCell,
        width: '15rem',
      },
      {
        name: this.translateService.instant('Explanation required'),
        dataKey: 'explanation_required',
        cellTemplate: this.booleanCell,
        width: '17rem',
      },
    ];
  }

  private initializeOptionListForm(optionList: OptionList): void {
    this.optionListForm = new OptionListForm(this.optionListsApiService, this.withTypeField, optionList);
  }
}
