<button mat-menu-item class="menu-title" disabled>{{ menuTitle }}</button>
<hr />
<button mat-menu-item (click)="setItem.emit()">{{ "All" | translate }}</button>
<hr class="hr-light" />
<button
  *ngFor="let item of filterItems"
  mat-menu-item
  [ngClass]="{ active: selectedFilterItemId === item.id }"
  (click)="setItem.emit(item)"
>
  {{ item.title }}
</button>
