import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ApplicationApiDefinition, UserSettings, UserSettingsRequest } from '../../../models';
import { ApiService } from '../../common/api/api.service';

@Injectable({ providedIn: 'root' })
export class ClientUserSettingsService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private readonly apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.userSettings;
  }

  getSelfUserSettings(): Observable<ApiResponse<UserSettingsRequest>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/settings`);
  }

  updateSelfUserSettings(userSettings: UserSettings): Observable<ApiResponse<UserSettingsRequest>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/settings`, userSettings);
  }

  getAllUserSettings(): Observable<ApiResponse<UserSettingsRequest[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/settings/all`);
  }

  updateUserSettings(userId: string, userSettings: UserSettings): Observable<ApiResponse<UserSettingsRequest>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/settings/${userId}`, userSettings);
  }
}
