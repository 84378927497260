import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UserRoles, AdminUserRoles } from '../../models';

export const UserRoleLabel = {
  [UserRoles.admin]: _('Admin'),
  [UserRoles.manager]: _('Manager'),
  [UserRoles.editor]: _('Editor'),
  [UserRoles.viewer]: _('Viewer'),
  [UserRoles.collaborator]: _('Request Participant'),
};

export const AdminUserRoleLabel = {
  [AdminUserRoles.admin]: _('Admin'),
  [AdminUserRoles.dataManager]: _('Data Manager'),
  [AdminUserRoles.analyst]: _('Analyst'),
  [AdminUserRoles.editor]: _('Document Processor'),
};
