import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OauthProvider, Token } from '../../../models/entities';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { ApplicationApiDefinition } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class OauthService {
  _providers?: OauthProvider[];
  apiName: keyof ApplicationApiDefinition = 'auth';
  resource: string;
  servicePath: string;

  public get api_url(): string {
    if (this.apiService.apiConfig.minivisto) {
      return this.apiService.getBaseUrl() + ':' + this.apiService.apiConfig.minivistoPort;
    } else {
      return this.apiService.getBaseUrl();
    }
  }

  public get callback_url(): string {
    return this.document.location.protocol + '//' + this.document.location.host.toLowerCase() + '/oauth/callback';
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private auth: AuthService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.auth.resources.oauth;
  }

  listProviders(): Observable<OauthProvider[]> {
    if (this._providers) {
      return of(this._providers);
    } else {
      return this.apiService.get(`${this.servicePath}${this.resource}/providers`).pipe(
        map((result) => {
          this._providers = result.data;
          return this._providers || [];
        }),
      );
    }
  }

  login(code: string, state: string): Observable<boolean> {
    return this.apiService.get(`${this.servicePath}${this.resource}/callback?code=${code}&state=${state}`).pipe(
      map((result: Token) => {
        if (result.redirect_uri != null) {
          window.location.replace(result.redirect_uri);
          return false;
        }
        return this.auth.saveUser(result);
      }),
    );
  }
}
