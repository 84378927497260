import { Pipe, PipeTransform } from '@angular/core';
import { DocumentTypeDetails, ValueDefinition } from '../../../../models';

@Pipe({
  name: 'documentTitle',
})
export class DocumentTitlePipe implements PipeTransform {
  transform(valueDefinition?: ValueDefinition<DocumentTypeDetails>, documentName?: string): string {
    return valueDefinition?.type_details?.display_name || documentName || valueDefinition?.label || '';
  }
}
