import { Component, Input } from '@angular/core';
import { TableActionMenuItemEvent, TableColumn } from '../../../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[lib-table-body-row]',
  templateUrl: './table-body-row.component.html',
  styleUrls: ['../table-grid.component.scss', './table-body-row.component.scss'],
})
export class TableBodyRowComponent<T> {
  @Input({ required: true }) columns: TableColumn<T>[] = [];
  @Input({ required: true }) rowData!: T;
  @Input() isExpandable = false;
  @Input() isExpanded = false;
  @Input() isRowReorderable = false;
  @Input() actionMenuItems?: TableActionMenuItemEvent<T>[];
  @Input() defaultEmptyValue = '';
  @Input() isWrapping: boolean = false;
  @Input() rowgroup: boolean = false;
  @Input() rowspan?: number;
  @Input() rowGroupingProperties?: string[];
}
