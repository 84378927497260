import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {
  ARROWS_ICONS,
  EDITOR_ICONS,
  FILTER_ICONS,
  HOME_ICON,
  Icon,
  LOGOS_ICONS,
  NAV_MENU_ICONS,
  NAV_PRIMARY_ICONS,
  NAV_SECONDARY_ICONS,
  OTHER_ICONS,
  PARTNER_LOGIN_ICONS,
  REGISTER_ICONS,
  SSO_ICONS,
  STATUS_ICONS,
  TABS_ICONS,
  TYPES_ICONS,
  WIDGETS,
} from './icons.model';

@NgModule({
  imports: [HttpClientModule],
  exports: [],
})
export class IconsModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.registerIcons();
  }

  private _registerIcons(cat: string, icons: readonly Icon[]): void {
    for (const icon of icons) {
      this._registerIcon(cat, icon);
    }
  }

  private _registerIcon(cat: string, icon: Icon): void {
    this.iconRegistry.addSvgIcon(
      icon,
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/' + cat + '/' + icon + '.svg'),
    );
  }

  private registerIcons() {
    this._registerIcon('home', HOME_ICON);
    this._registerIcons('actions', REGISTER_ICONS);
    this._registerIcons('arrows', ARROWS_ICONS);
    this._registerIcons('editor', EDITOR_ICONS);
    this._registerIcons('filters', FILTER_ICONS);
    this._registerIcons('nav/menu', NAV_MENU_ICONS);
    this._registerIcons('nav/primary', NAV_PRIMARY_ICONS);
    this._registerIcons('nav/secondary', NAV_SECONDARY_ICONS);
    this._registerIcons('nav/partner-login', PARTNER_LOGIN_ICONS);
    this._registerIcons('other', OTHER_ICONS);
    this._registerIcons('status', STATUS_ICONS);
    this._registerIcons('tabs', TABS_ICONS);
    this._registerIcons('types', TYPES_ICONS);
    this._registerIcons('sso', SSO_ICONS);
    this._registerIcons('logos', LOGOS_ICONS);
    this._registerIcons('widgets', WIDGETS);
  }
}
