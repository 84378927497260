<lib-dialog-title [title]="'Add Novisto related fields' | translate" (closeEvent)="closeDialog()"></lib-dialog-title>

<div mat-dialog-content>
  <lib-stepper
    (selectedIndexChange)="selectedIndexChange($event)"
    [selectedIndex]="selectedIndex"
    [steps]="[
      { content: step1, customLabel: step1CustomLabel },
      { content: step2, disabled: !form.controls.metric.value, title: 'Select fields' | translate },
    ]"
  ></lib-stepper>
</div>

<div mat-dialog-actions>
  <button lib-button buttonType="link" (click)="closeDialog()">{{ "Cancel" | translate }}</button>
  <button
    *ngIf="selectedIndex === eAddRelatedFieldDialogTabs.STEP1"
    lib-button
    buttonType="primary"
    class="ml-2"
    (click)="selectedIndexChange(eAddRelatedFieldDialogTabs.STEP2)"
    [disabled]="!form.controls.metric.value"
  >
    {{ "Next" | translate }}
  </button>
  <ng-container *ngIf="selectedIndex === eAddRelatedFieldDialogTabs.STEP2">
    <button
      lib-button
      buttonType="cancel_with_border"
      class="ml-2"
      (click)="selectedIndexChange(eAddRelatedFieldDialogTabs.STEP1)"
    >
      {{ "Back to Step 1" | translate }}
    </button>
    <button
      lib-button
      buttonType="primary"
      class="ml-2"
      (click)="save()"
      [disabled]="!form.controls.items.value.length"
    >
      {{ "Save" | translate }}
    </button>
  </ng-container>
</div>

<ng-template #step1>
  <div class="pt-2 h-100 full-content">
    <lib-metric-search
      [withInfiniteScroll]="true"
      [withTopicColumn]="false"
      [withTopicFilter]="true"
      [withCategoryFilter]="false"
      [withStandardCodeFilter]="false"
      [withTaxonomyFilter]="false"
      [withTagFilter]="false"
      [withMetricGroupFilter]="false"
      [withSort]="true"
      [withRelatedToColumn]="false"
      [isCheckable]="false"
      [showRowSelection]="true"
      (indicatorClick)="selectMetric($event)"
      [filters]="{ category: { id: eMetricCategory.THIRD_PARTY, title: eMetricCategory.THIRD_PARTY } }"
    ></lib-metric-search>
  </div>
</ng-template>

<ng-template #step2>
  <div class="pt-2 full-content">
    <lib-select-metric-value-definition
      *ngIf="form.controls.metric.value"
      [metricId]="form.controls.metric.value.id"
      [showInactiveFields]="true"
      [supportedFieldTypes]="supportedFieldTypes"
      [metricTableValueSelection]="eMetricTableValueSelectionChoice.tableCell"
      [enableRowSelection]="true"
      [withTaxonomiesFilter]="true"
      [selections]="selections"
      [withSort]="true"
      [ignoreFields]="ignoreFields"
      (rowSelected)="rowSelected($event)"
    ></lib-select-metric-value-definition>
  </div>
</ng-template>

<ng-template #step1CustomLabel>
  <div class="custom-label">
    <div>{{ "Select metric" | translate }}</div>
    <div *ngIf="form.controls.metric.value" class="custom-label-subtitle pt-1">
      {{ form.controls.metric.value.code }}
    </div>
  </div>
</ng-template>
