<ng-container *ngIf="valueGroupSetForm && valueGroupSet.value_groups?.length; else emptyMetric">
  <div class="fx-row align-center pb-4" *ngIf="disabled && disabledReason">
    <mat-icon svgIcon="info" class="tip-icon color-grey-800 mr-2"></mat-icon>
    <div class="text mat-body color-grey-900">
      {{ disabledReason }}
    </div>
  </div>

  <form [formGroup]="valueGroupSetForm" class="metric-editor-form">
    <div #topSpinner [ngClass]="{ 'hide-group-loading-spinner': groupsOffset <= 0 }">
      <lib-spinner [diameter]="20"></lib-spinner>
    </div>

    <div class="floating-button--top">
      <button lib-button *ngIf="groupsOffset > 0" [buttonType]="'primary'" (click)="goToTop()">
        {{ "Go to top" | translate }}
        <mat-icon class="ml-1" svgIcon="arrow-full-up"></mat-icon>
      </button>
    </div>

    <ng-container *ngFor="let formGroup of displayedGroups; trackBy: formGroupTrackBy; let index = index">
      <ng-container *ngIf="index >= groupsOffset && index < groupsOffset + TOTAL_SHOWN_GROUPS">
        <lib-metric-editor-group-handler
          *ngIf="formGroup | isValueGroupFormGroup"
          data-testid="group-handler"
          [class]="'metric-editor-form-group form-group-' + $any(formGroup).valueGroupRef.indent"
          [valueGroupFormGroup]="$any(formGroup)"
          [documentContext]="documentContext"
          [repeatableGroupCount]="formGroup | repeatableGroupsCount: valueGroupSetForm.groupFormGroups()"
          [indicatorId]="valueGroupSet.indicator_id"
          [indicator]="indicator"
          [vgsetId]="valueGroupSet.id"
          [displayFieldActions]="displayFieldActions"
          [collaboratorResponsibility]="collaboratorResponsibility"
          [valueGroupSetStatus]="valueGroupSet.status"
          [dataRequestSourceStatus]="dataRequestSourceStatus"
          [sourceConfiguration]="sourceConfiguration"
          [integrationType]="integrationType"
          [disableFrequencyFields]="disableFrequencyFields"
          (update)="onUpdateValueGroup($event)"
          (resetValue)="onResetValue($event)"
          (addGroup)="handleAddValueGroup($event)"
          (moveGroup)="handleMoveValueGroup($event)"
          (deleteGroup)="handleDeleteValueGroup($event)"
          (metricLinkEdit)="metricLinkEdit.emit($event)"
        ></lib-metric-editor-group-handler>

        <lib-metric-editor-table-handler
          *ngIf="formGroup | isTableFormGroup"
          data-testid="table-handler"
          [tableFormGroup]="$any(formGroup)"
          [isConsolidatedBU]="valueGroupSet.consolidated ? valueGroupSet.consolidated : !!isSourceConsolidated"
          [displayFieldActions]="displayFieldActions"
          [userResponsibility]="collaboratorResponsibility"
          [valueGroupSetStatus]="valueGroupSet.status"
          [dataRequestSourceStatus]="dataRequestSourceStatus"
          [documentContext]="documentContext"
          [indicatorId]="valueGroupSet.indicator_id"
          [sourceConfiguration]="sourceConfiguration"
          [vgsetId]="valueGroupSet.id"
          (update)="onUpdateValueGroup($event)"
          (resetValue)="onResetValue($event)"
        >
        </lib-metric-editor-table-handler>
      </ng-container>
    </ng-container>

    <div class="floating-button--bottom">
      <button
        lib-button
        *ngIf="groupsOffset < displayedGroups.length - TOTAL_SHOWN_GROUPS"
        [buttonType]="'primary'"
        (click)="goToEnd()"
      >
        {{ "Go to end" | translate }}
        <mat-icon class="ml-1" svgIcon="arrow-full-down"></mat-icon>
      </button>
    </div>

    <div
      #bottomSpinner
      [ngClass]="{ 'hide-group-loading-spinner': groupsOffset >= displayedGroups.length - TOTAL_SHOWN_GROUPS }"
    >
      <lib-spinner [diameter]="20"></lib-spinner>
    </div>
  </form>
</ng-container>

<ng-template #emptyMetric>
  <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
</ng-template>
