<lib-date-input
  [control]="valueFormControl"
  [label]="label"
  [hint]="hint"
  [placeholder]="placeholder"
  [size]="size"
  [maxDate]="dateMax"
  [minDate]="dateMin"
  (libBeforeUnload)="updateValue()"
></lib-date-input>
