import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Taxonomy } from '../../../models';
import { ExpandableCardComponent } from '../../../components';

@Component({
  selector: 'lib-taxonomies-card',
  templateUrl: './taxonomies-card.component.html',
  styleUrls: ['./taxonomies-card.component.scss'],
})
export class TaxonomiesCardComponent {
  @ViewChild(ExpandableCardComponent) expandableCard?: ExpandableCardComponent;

  @Input() enableAdd: boolean = false;
  @Input() enableDelete: boolean = false;
  @Input({ required: true }) taxonomies: Taxonomy[] = [];

  @Output() handleAdd = new EventEmitter<void>();
  @Output() handleDelete = new EventEmitter<Taxonomy>();

  public readonly expandableCardMaxContentHeight = 250;
}
