import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  DueDateNotification,
  DueDateNotificationRelativeToType,
  DueDateNotificationSchedule,
  DueDateNotificationScheduleType,
  DueDateNotificationTimeframeType,
} from '../../../models';
import {
  DEFAULT_SCHEDULE,
  EVERY_DAY_PAST_DUE_DATE,
  ONE_DAY_AFTER,
  ONE_DAY_BEFORE,
  ONE_WEEK_BEFORE,
  ON_DUE_DATE,
} from './schedule-constants';
import isEqual from 'lodash/isEqual';

export interface DueDateAutomaticReminderFormModel {
  source_status: FormControl<DataRequestSourceStatus | 'any'>;
  participants: FormControl<'all' | 'custom'>;
  customParticipants: FormControl<DataRequestUserResponsibility[]>;
  schedule: FormControl<DueDateNotificationScheduleType>;
  scheduleFrequency: FormControl<number>;
  scheduleTimeFrame: FormControl<DueDateNotificationTimeframeType>;
  scheduleRelativeTo: FormControl<DueDateNotificationRelativeToType>;
}

export class DueDateAutomaticReminderForm extends FormGroup<DueDateAutomaticReminderFormModel> {
  constructor(
    notification?: DueDateNotification,
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      source_status: fb.nonNullable.control(notification?.source_status ?? 'any', {
        validators: [Validators.required],
      }),
      participants: fb.nonNullable.control(
        notification?.participants && notification.participants.length > 0 ? 'custom' : 'all',
        {
          validators: [Validators.required],
        },
      ),
      schedule: fb.nonNullable.control(
        DueDateAutomaticReminderForm.scheduleObjectToId(notification?.schedule) ??
          DueDateNotificationScheduleType.ONE_WEEK_BEFORE,
        {
          validators: [Validators.required],
        },
      ),
      customParticipants: fb.nonNullable.control(notification?.participants ?? []),
      scheduleFrequency: fb.nonNullable.control(notification?.schedule.frequency ?? 0),
      scheduleTimeFrame: fb.nonNullable.control(
        notification?.schedule.timeframe ?? DueDateNotificationTimeframeType.CALENDAR_DAYS,
      ),
      scheduleRelativeTo: fb.nonNullable.control(
        notification?.schedule.relative_to ?? DueDateNotificationRelativeToType.BEFORE,
      ),
    });

    this.controls.schedule.valueChanges.subscribe((currentValue) => {
      if (currentValue == DueDateNotificationScheduleType.CUSTOM) {
        this.controls.scheduleFrequency.setValidators([Validators.required, Validators.min(1), Validators.max(365)]);
        this.controls.scheduleTimeFrame.setValidators([Validators.required]);
        this.controls.scheduleRelativeTo.setValidators([Validators.required]);
        this.controls.scheduleFrequency.updateValueAndValidity();
      } else {
        this.controls.customParticipants.setValidators([]);
      }
    });
  }

  public static scheduleObjectToId(
    schedule?: DueDateNotificationSchedule,
  ): DueDateNotificationScheduleType | undefined {
    if (!schedule) {
      return undefined;
    }

    if (isEqual(schedule, ONE_DAY_AFTER)) {
      return DueDateNotificationScheduleType.ONE_DAY_AFTER;
    }

    if (isEqual(schedule, ONE_DAY_BEFORE)) {
      return DueDateNotificationScheduleType.ONE_DAY_BEFORE;
    }

    if (isEqual(schedule, ONE_WEEK_BEFORE)) {
      return DueDateNotificationScheduleType.ONE_WEEK_BEFORE;
    }

    if (isEqual(schedule, ON_DUE_DATE)) {
      return DueDateNotificationScheduleType.ON_DUE_DATE;
    }

    if (isEqual(schedule, EVERY_DAY_PAST_DUE_DATE)) {
      return DueDateNotificationScheduleType.EVERY_DAY_PAST_DUE_DATE;
    }

    return DueDateNotificationScheduleType.CUSTOM;
  }

  public toModel(): DueDateNotification {
    return {
      source_status: this.controls.source_status.value === 'any' ? undefined : this.controls.source_status.value,
      participants: this.controls.participants.value === 'all' ? undefined : this.controls.customParticipants.value,
      schedule: this.scheduleIdToObject(this.controls.schedule.value),
    };
  }

  private scheduleIdToObject(schedule: DueDateNotificationScheduleType): DueDateNotificationSchedule {
    switch (schedule) {
      case DueDateNotificationScheduleType.CUSTOM:
        return {
          frequency: this.controls.scheduleFrequency.value,
          timeframe: this.controls.scheduleTimeFrame.value,
          relative_to: this.controls.scheduleRelativeTo.value,
        };
      case DueDateNotificationScheduleType.ONE_DAY_AFTER:
        return ONE_DAY_AFTER;
      case DueDateNotificationScheduleType.ONE_DAY_BEFORE:
        return ONE_DAY_BEFORE;
      case DueDateNotificationScheduleType.ONE_WEEK_BEFORE:
        return ONE_WEEK_BEFORE;
      case DueDateNotificationScheduleType.ON_DUE_DATE:
        return ON_DUE_DATE;
      case DueDateNotificationScheduleType.EVERY_DAY_PAST_DUE_DATE:
        return EVERY_DAY_PAST_DUE_DATE;
      default:
        return DEFAULT_SCHEDULE;
    }
  }
}
