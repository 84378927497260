import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { AlertsService, AuthService, EventsService, TranslateService } from '../../services/common';
import {
  CalculateValuesPayload,
  CDPReportPayload,
  DataRequestPrefillPayload,
  DataRequestSourcePrefillPayload,
  ExportPayload,
  IntegrationDataImportPayload,
  Message,
  MESSAGE_TYPES,
  MessageContext,
  ReportCompletePayload,
  SPReportPayload,
  TemplateReportExportMessagePayload,
  WebsocketService,
} from '../../websocket';
import { ApiResponse, CategoryRouteMap, EventType, FiscalYear, ItemType } from '../../models';
import { switchMap } from 'rxjs/operators';
import { ClientDocumentsService } from '../../services/client/client-documents/client-documents.service';

@Directive({
  selector: '[libWebsocket]',
})
export class WebsocketDirective {
  userId: string | undefined;

  constructor(
    public translateService: TranslateService,
    public router: Router,
    public eventsService: EventsService,
    public websocketService: WebsocketService,
    public alertsService: AlertsService,
    public documentsService: ClientDocumentsService,
    public authService: AuthService,
  ) {
    this.userId = authService.user?.user_id;

    this.websocketService.messages$.subscribe((result) => {
      this.handleWebSocketNotification(result);
    });
  }

  private handleWebSocketNotification(
    result: (Message<unknown> & { context?: MessageContext & ApiResponse['meta'] }) | undefined,
  ): void {
    if (result?.context?.type === 'background_task' && result.context.user_id === this.userId) {
      // this is because the WebsocketService.message$ Observable is not multicast. Instead of subscribing
      // to it in the job queue, this is used to trigger the job queue reload.
      this.eventsService.setMessage(EventType.reload_job_queue, result.type);
      switch (result.type) {
        case MESSAGE_TYPES.REPORT_CREATED:
        case MESSAGE_TYPES.REPORT_ERROR:
        case MESSAGE_TYPES.REPORT_START:
          this.handleReportCompleteNotification(result as Message<ReportCompletePayload>);
          break;
        case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_PREFILL_START:
        case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_PREFILL_DONE:
        case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_PREFILL_ERROR:
          this.handleDataRequestPrefillNotification(result as Message<DataRequestPrefillPayload>);
          break;
        case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_SOURCE_PREFILL_START:
        case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_SOURCE_PREFILL_DONE:
        case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_SOURCE_PREFILL_ERROR:
          this.handleDataRequestSourcePrefillNotification(result as Message<DataRequestSourcePrefillPayload>);
          break;
        case MESSAGE_TYPES.PLATFORM_EXPORT_INDICATOR_CREATE_START:
        case MESSAGE_TYPES.PLATFORM_EXPORT_INDICATOR_CREATE_ERROR:
        case MESSAGE_TYPES.PLATFORM_EXPORT_INDICATOR_CREATE_DONE:
        case MESSAGE_TYPES.PLATFORM_EXPORT_METRIC_CREATE_START:
        case MESSAGE_TYPES.PLATFORM_EXPORT_METRIC_CREATE_ERROR:
        case MESSAGE_TYPES.PLATFORM_EXPORT_METRIC_CREATE_DONE:
        case MESSAGE_TYPES.PLATFORM_EXPORT_FIELD_AUDIT_CREATE_START:
        case MESSAGE_TYPES.PLATFORM_EXPORT_FIELD_AUDIT_CREATE_ERROR:
        case MESSAGE_TYPES.PLATFORM_EXPORT_FIELD_AUDIT_CREATE_DONE:
          this.handleExportNotification(result as Message<ExportPayload>, ItemType.metric_data_exports);
          break;
        case MESSAGE_TYPES.PLATFORM_REPORT_EXPORT_START:
        case MESSAGE_TYPES.PLATFORM_REPORT_EXPORT_DONE:
        case MESSAGE_TYPES.PLATFORM_REPORT_EXPORT_ERROR:
          this.handleExportNotification(result as Message<ExportPayload>, ItemType.report_exports);
          break;
        case MESSAGE_TYPES.PLATFORM_BENCHMARK_V2_EXPORT_START:
        case MESSAGE_TYPES.PLATFORM_BENCHMARK_V2_EXPORT_DONE:
        case MESSAGE_TYPES.PLATFORM_BENCHMARK_V2_EXPORT_ERROR:
          this.handleExportNotification(result as Message<ExportPayload>, ItemType.benchmark_v2_exports);
          break;
        case MESSAGE_TYPES.PLATFORM_SOURCE_COMPARISON_EXPORT_START:
        case MESSAGE_TYPES.PLATFORM_SOURCE_COMPARISON_EXPORT_DONE:
        case MESSAGE_TYPES.PLATFORM_SOURCE_COMPARISON_EXPORT_ERROR:
          this.handleExportNotification(result as Message<ExportPayload>, ItemType.source_comparison_v1_exports);
          break;
        case MESSAGE_TYPES.PLATFORM_DATA_TABLE_V2_EXPORT_START:
        case MESSAGE_TYPES.PLATFORM_DATA_TABLE_V2_EXPORT_DONE:
        case MESSAGE_TYPES.PLATFORM_DATA_TABLE_V2_EXPORT_ERROR:
          this.handleExportNotification(result as Message<ExportPayload>, ItemType.data_table_v2_exports);
          break;
        case MESSAGE_TYPES.PLATFORM_SP_REPORT_SUBMIT_START:
        case MESSAGE_TYPES.PLATFORM_SP_REPORT_SUBMIT_DONE:
        case MESSAGE_TYPES.PLATFORM_SP_REPORT_SUBMIT_ERROR:
          this.handleSPReportSubmission(result as Message<SPReportPayload>);
          break;
        case MESSAGE_TYPES.PLATFORM_TEMPLATE_REPORT_EXPORT_START:
        case MESSAGE_TYPES.PLATFORM_TEMPLATE_REPORT_EXPORT_DONE:
        case MESSAGE_TYPES.PLATFORM_TEMPLATE_REPORT_EXPORT_ERROR:
          this.handleTemplateReportExport(result as Message<TemplateReportExportMessagePayload>);
          break;
        case MESSAGE_TYPES.PLATFORM_CDP_REPORT_SUBMIT_START:
        case MESSAGE_TYPES.PLATFORM_CDP_REPORT_SUBMIT_DONE:
        case MESSAGE_TYPES.PLATFORM_CDP_REPORT_SUBMIT_ERROR:
          this.handleCDPReportSubmission(result as Message<CDPReportPayload>);
          break;
        case MESSAGE_TYPES.PLATFORM_JOB_QUEUED:
        case MESSAGE_TYPES.REPORT_QUEUED:
        case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_PREFILL_QUEUED:
        case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_SOURCE_PREFILL_QUEUED:
        case MESSAGE_TYPES.PLATFORM_EXPORT_INDICATOR_CREATE_QUEUED:
        case MESSAGE_TYPES.PLATFORM_EXPORT_METRIC_CREATE_QUEUED:
        case MESSAGE_TYPES.PLATFORM_SP_REPORT_SUBMIT_QUEUED:
        case MESSAGE_TYPES.PLATFORM_REPORT_EXPORT_QUEUED:
        case MESSAGE_TYPES.PLATFORM_CDP_REPORT_SUBMIT_QUEUED:
        case MESSAGE_TYPES.PLATFORM_EXPORT_FIELD_AUDIT_CREATE_QUEUED:
          this.defaultJobQueuedNotification();
          break;
        case MESSAGE_TYPES.PLATFORM_BENCHMARK_V2_EXPORT_QUEUED:
        case MESSAGE_TYPES.PLATFORM_SOURCE_COMPARISON_EXPORT_QUEUED:
        case MESSAGE_TYPES.PLATFORM_DATA_TABLE_V2_EXPORT_QUEUED:
        case MESSAGE_TYPES.PLATFORM_TEMPLATE_REPORT_EXPORT_QUEUED:
          this.handleExportQueuedNotification(result as Message<ExportPayload>);
          break;
        case MESSAGE_TYPES.PLATFORM_INTEGRATION_DATA_IMPORT_DONE:
        case MESSAGE_TYPES.PLATFORM_INTEGRATION_DATA_IMPORT_START:
        case MESSAGE_TYPES.PLATFORM_INTEGRATION_DATA_IMPORT_ERROR:
          this.handleIntegrationDataImportNotifications(result as Message<IntegrationDataImportPayload>);
          break;
        case MESSAGE_TYPES.PLATFORM_FISCAL_YEAR_FRAMEWORK_TAXONOMY_PUBLISH_IN_PROGRESS:
        case MESSAGE_TYPES.PLATFORM_FISCAL_YEAR_FRAMEWORK_TAXONOMY_PUBLISH_SUCCESS:
        case MESSAGE_TYPES.PLATFORM_FISCAL_YEAR_FRAMEWORK_TAXONOMY_PUBLISH_ERROR:
          this.handleFiscalYearFrameworkTaxonomyNotifications(result as Message<FiscalYear>);
          break;
        case MESSAGE_TYPES.PLATFORM_CALCULATE_VALUES_START:
        case MESSAGE_TYPES.PLATFORM_CALCULATE_VALUES_DONE:
        case MESSAGE_TYPES.PLATFORM_CALCULATE_VALUES_ERROR:
          this.handleCalculateValuesNotifications(result as Message<CalculateValuesPayload>);
          break;
        default:
          return;
      }
    }
  }

  private defaultJobQueuedNotification(): void {
    this.alertsService.success(this.translateService.instant('Background task queued'));
  }

  private handleExportQueuedNotification(result: Message<ExportPayload>): void {
    this.alertsService.success(
      this.translateService.instant('Background task to export "{docName}" is queued', {
        docName: result.data.document_name,
      }),
    );
  }

  private handleIntegrationDataImportNotifications(result: Message<IntegrationDataImportPayload>): void {
    switch (result.type) {
      case MESSAGE_TYPES.PLATFORM_INTEGRATION_DATA_IMPORT_START:
        this.alertsService.success(
          this.translateService.instant(
            'Started background task to import data from Carbon Management Automation. This action might take a while to process.',
          ),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_INTEGRATION_DATA_IMPORT_DONE:
        this.alertsService.success(
          this.translateService.instant('Successful data import from Carbon Management Automation.'),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_INTEGRATION_DATA_IMPORT_ERROR:
        this.alertsService
          .open(
            this.translateService.instant('Data import from Carbon Management Automation failed.'),
            this.translateService.instant('Check Job queue for details'),
            'error',
            5000,
          )
          ?.onAction()
          .subscribe(() => {
            this.router.navigateByUrl('/app/settings/job-queue-grid');
          });
        break;
      default:
        return;
    }
  }

  private handleTemplateReportExport(result: Message<TemplateReportExportMessagePayload>): void {
    switch (result.type) {
      case MESSAGE_TYPES.PLATFORM_TEMPLATE_REPORT_EXPORT_START:
        this.alertsService.success(
          this.translateService.instant(
            'Started background task to export {documentName}. This action might take a while to process',
            {
              documentName: result.data.document_name,
            },
          ),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_TEMPLATE_REPORT_EXPORT_DONE:
        if (result.data.document_id) {
          const type = ItemType.docs_doc;
          this.alertsService
            .open(
              this.translateService.instant('Finished exporting {documentName}', {
                documentName: result.data.document_name,
              }),
              this.translateService.instant('Download'),
              'success',
              5000,
            )
            ?.onAction()
            .subscribe(() => {
              if (result.data.document_id) {
                this.documentsService.downloadDocumentById(result.data.document_id, type);
              }
            });
        } else {
          this.alertsService.success(
            this.translateService.instant('Finished exporting {documentName} export is .', {
              documentName: result.data.document_name,
            }),
          );
        }
        return;

      case MESSAGE_TYPES.PLATFORM_TEMPLATE_REPORT_EXPORT_ERROR:
        this.alertsService.open(
          this.translateService.instant('Failed to create the export "{documentName}"', {
            documentName: result.data.document_name,
          }),
          '',
          'error',
          5000,
        );
        break;
      default:
        return;
    }
  }

  private handleDataRequestPrefillNotification(result: Message<DataRequestPrefillPayload>): void {
    switch (result.type) {
      case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_PREFILL_START:
        break;
      case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_PREFILL_DONE:
        this.onDataRequestPrefillDone(result.data.data_request_id);
        break;
      case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_PREFILL_ERROR:
        this.alertsService.open(`Failed to pre-fill and start the data request.`, '', 'error', 5000);
        break;
      default:
        break;
    }
  }

  private handleFiscalYearFrameworkTaxonomyNotifications(result: Message<FiscalYear>): void {
    switch (result.type) {
      case MESSAGE_TYPES.PLATFORM_FISCAL_YEAR_FRAMEWORK_TAXONOMY_PUBLISH_IN_PROGRESS:
        this.alertsService.success(
          this.translateService.instant('Background task to import metrics for Fiscal Year {year} is in progress', {
            year: result.data.frequency_code,
          }),
        );
        break;

      case MESSAGE_TYPES.PLATFORM_FISCAL_YEAR_FRAMEWORK_TAXONOMY_PUBLISH_SUCCESS:
        this.alertsService.success(
          this.translateService.instant('Background task to import metrics for Fiscal Year {year} is successful', {
            year: result.data.frequency_code,
          }),
        );
        break;

      case MESSAGE_TYPES.PLATFORM_FISCAL_YEAR_FRAMEWORK_TAXONOMY_PUBLISH_ERROR:
        this.alertsService
          .open(
            this.translateService.instant('Background task to import metrics for Fiscal Year {year} failed', {
              year: result.data.frequency_code,
            }),
            this.translateService.instant('View'),
            'error',
            5000,
          )
          ?.onAction()
          .subscribe(() => {
            this.router.navigateByUrl('/app/settings/job-queue-grid');
          });
        break;

      default:
        break;
    }
  }

  private handleCalculateValuesNotifications(result: Message<CalculateValuesPayload>): void {
    switch (result.type) {
      case MESSAGE_TYPES.PLATFORM_CALCULATE_VALUES_START:
        this.alertsService.open(
          this.translateService.instant(
            'Started task to calculate values for "{metricCode}": "{valueDefinitionLabel}"',
            {
              metricCode: result.data.metric_code,
              valueDefinitionLabel: result.data.value_definition_label,
            },
          ),
          '',
          'success',
          7000,
        );
        return;
      case MESSAGE_TYPES.PLATFORM_CALCULATE_VALUES_DONE:
        this.alertsService.open(
          this.translateService.instant(
            'Completed task to calculate values for "{metricCode}": "{valueDefinitionLabel}"',
            {
              metricCode: result.data.metric_code,
              valueDefinitionLabel: result.data.value_definition_label,
            },
          ),
          '',
          'success',
          7000,
        );
        return;
      case MESSAGE_TYPES.PLATFORM_CALCULATE_VALUES_ERROR:
        this.alertsService
          .open(
            this.translateService.instant('Calculation of values failed for "{metricCode}": "{valueDefinitionLabel}"', {
              metricCode: result.data.metric_code,
              valueDefinitionLabel: result.data.value_definition_label,
            }),
            this.translateService.instant('Check Job queue for details'),
            'error',
            7000,
          )
          ?.onAction()
          .subscribe(() => {
            this.router.navigateByUrl('/app/settings/job-queue-grid');
          });
        break;
      default:
        return;
    }
  }

  private handleDataRequestSourcePrefillNotification(result: Message<DataRequestSourcePrefillPayload>): void {
    switch (result.type) {
      case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_SOURCE_PREFILL_START:
        break;
      case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_SOURCE_PREFILL_DONE:
        this.onDataRequestSourcePrefillDone(result.data.data_request_id, result.data.data_request_source_id);
        break;
      case MESSAGE_TYPES.PLATFORM_DATA_REQUEST_SOURCE_PREFILL_ERROR:
        this.alertsService.open(`Failed to pre-fill and start the source.`, '', 'error', 5000);
        break;
      default:
        break;
    }
  }

  private onDataRequestPrefillDone(data_request_id: string): void {
    const message = this.translateService.instant('Finished pre-filling the sources and starting the request.');

    if (this.router.url.includes(`${data_request_id}/edit`)) {
      this.eventsService.setMessage(EventType.data_request_source_prefill_done, 'true');
      this.alertsService.open(message, '', 'success', 5000);
    } else {
      this.alertsService
        .open(message, data_request_id ? 'View' : '', 'success', 5000)
        ?.onAction()
        .pipe(
          switchMap(() => from(this.router.navigateByUrl('/', { skipLocationChange: true }))),
          switchMap(() => from(this.router.navigate([`/app/metrics/requests/${data_request_id}/edit/structure`]))),
        )
        .subscribe();
    }
  }

  private onDataRequestSourcePrefillDone(data_request_id: string, data_request_source_id: string): void {
    const message = this.translateService.instant('Finished pre-filling and starting the source.');

    if (this.router.url.includes(`${data_request_id}/edit/sources?dataRequestSourceId=${data_request_source_id}`)) {
      this.eventsService.setMessage(EventType.data_request_source_prefill_done, 'true');
      this.alertsService.open(message, '', 'success', 5000);
    } else {
      this.alertsService
        .open(message, data_request_id ? 'View' : '', 'success', 5000)
        ?.onAction()
        .pipe(
          switchMap(() => from(this.router.navigateByUrl('/', { skipLocationChange: true }))),
          switchMap(() =>
            from(
              this.router.navigate([`/app/metrics/requests/${data_request_id}/edit/sources`], {
                queryParams: { dataRequestSourceId: data_request_source_id },
              }),
            ),
          ),
        )
        .subscribe();
    }
  }

  private handleReportCompleteNotification(result: Message<ReportCompletePayload> | undefined): void {
    if (result) {
      switch (result.type) {
        case MESSAGE_TYPES.REPORT_START:
          this.alertsService.open(
            `Started background task to create template "${result.data.report_name}".`,
            '',
            'success',
            3000,
          );
          break;
        case MESSAGE_TYPES.REPORT_ERROR:
          this.alertsService.open(`Failed to create template "${result.data.report_name}".`, '', 'error', 5000);
          break;
        case MESSAGE_TYPES.REPORT_CREATED:
          this.alertsService
            .open(
              `Finished creating template "${result.data.report_name}".`,
              result.data.report_id ? 'View' : '',
              'success',
              5000,
            )
            ?.onAction()
            .subscribe(() => {
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() =>
                  this.router.navigate([
                    `/app/templates/${CategoryRouteMap[result.data.report_category]}/${result.data.report_id}`,
                  ]),
                );
            });
          break;
      }
    }
  }

  private handleExportNotification(result: Message<ExportPayload>, itemType: ItemType): void {
    switch (result.type) {
      case MESSAGE_TYPES.PLATFORM_EXPORT_FIELD_AUDIT_CREATE_START:
        this.alertsService.success(
          this.translateService.instant('Audit trail export started. This action might take a while to process.'),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_EXPORT_INDICATOR_CREATE_START:
      case MESSAGE_TYPES.PLATFORM_EXPORT_METRIC_CREATE_START:
        this.alertsService.success(
          this.translateService.instant(
            'Started background task to export "{documentName}". This action might take a while to process',
            {
              documentName: result.data.document_name,
            },
          ),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_BENCHMARK_V2_EXPORT_START:
      case MESSAGE_TYPES.PLATFORM_SOURCE_COMPARISON_EXPORT_START:
      case MESSAGE_TYPES.PLATFORM_DATA_TABLE_V2_EXPORT_START:
      case MESSAGE_TYPES.PLATFORM_REPORT_EXPORT_START:
        this.alertsService.success(
          this.translateService.instant(
            'Started background task to export {documentName}. This action might take a while to process',
            {
              documentName: result.data.document_name,
            },
          ),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_EXPORT_INDICATOR_CREATE_DONE:
      case MESSAGE_TYPES.PLATFORM_EXPORT_METRIC_CREATE_DONE:
      case MESSAGE_TYPES.PLATFORM_EXPORT_FIELD_AUDIT_CREATE_DONE:
        if (result.data.document_id) {
          this.alertsService
            .open(
              this.translateService.instant('Finished exporting "{documentName}"', {
                documentName: result.data.document_name,
              }),
              this.translateService.instant('Download'),
              'success',
              5000,
            )
            ?.onAction()
            .subscribe(() => {
              if (result.data.document_id) {
                this.documentsService.downloadDocumentById(result.data.document_id, itemType);
              }
            });
        }
        break;
      case MESSAGE_TYPES.PLATFORM_BENCHMARK_V2_EXPORT_DONE:
      case MESSAGE_TYPES.PLATFORM_SOURCE_COMPARISON_EXPORT_DONE:
      case MESSAGE_TYPES.PLATFORM_DATA_TABLE_V2_EXPORT_DONE:
      case MESSAGE_TYPES.PLATFORM_REPORT_EXPORT_DONE:
        this.alertsService
          .open(
            this.translateService.instant('Finished exporting {documentName}', {
              documentName: result.data.document_name,
            }),
            this.translateService.instant('Download'),
            'success',
            5000,
          )
          ?.onAction()
          .subscribe(() => {
            if (result.data.document_id) {
              this.documentsService.downloadDocumentById(result.data.document_id, itemType);
            }
          });
        break;
      case MESSAGE_TYPES.PLATFORM_EXPORT_FIELD_AUDIT_CREATE_ERROR:
        this.alertsService
          .open(
            this.translateService.instant('"{documentName}" export file has failed. ', {
              documentName: result.data.document_name,
            }),
            this.translateService.instant('Check the Job queue for details.'),
            'error',
            5000,
          )
          ?.onAction()
          .subscribe(() => {
            this.router.navigateByUrl('/app/settings/job-queue-grid');
          });
        break;
      case MESSAGE_TYPES.PLATFORM_BENCHMARK_V2_EXPORT_ERROR:
      case MESSAGE_TYPES.PLATFORM_SOURCE_COMPARISON_EXPORT_ERROR:
      case MESSAGE_TYPES.PLATFORM_DATA_TABLE_V2_EXPORT_ERROR:
      case MESSAGE_TYPES.PLATFORM_EXPORT_INDICATOR_CREATE_ERROR:
      case MESSAGE_TYPES.PLATFORM_EXPORT_METRIC_CREATE_ERROR:
      case MESSAGE_TYPES.PLATFORM_REPORT_EXPORT_ERROR:
        this.alertsService.open(
          this.translateService.instant('Failed to create the export "{documentName}"', {
            documentName: result.data.document_name,
          }),
          '',
          'error',
          5000,
        );
        break;
      default:
        return;
    }
  }

  private handleSPReportSubmission(result: Message<SPReportPayload>): void {
    switch (result.type) {
      case MESSAGE_TYPES.PLATFORM_SP_REPORT_SUBMIT_START:
        this.alertsService.success(
          this.translateService.instant(
            '{questionnaireName} submission is in progress. It may take a while to process',
            {
              questionnaireName: result.data.report_name,
            },
          ),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_SP_REPORT_SUBMIT_DONE:
        this.alertsService.success(
          this.translateService.instant('{questionnaireName} successfully sent to S&P.', {
            questionnaireName: result.data.report_name,
          }),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_SP_REPORT_SUBMIT_ERROR:
        this.alertsService
          .open(
            this.translateService.instant('{questionnaireName} submission failed.', {
              questionnaireName: result.data.report_name,
            }),
            this.translateService.instant('Check Job queue for details'),
            'error',
            5000,
          )
          ?.onAction()
          .subscribe(() => {
            this.router.navigateByUrl('/app/settings/job-queue-grid');
          });
        break;
      default:
        return;
    }
  }

  private handleCDPReportSubmission(result: Message<CDPReportPayload>): void {
    switch (result.type) {
      case MESSAGE_TYPES.PLATFORM_CDP_REPORT_SUBMIT_START:
        this.alertsService.success(
          this.translateService.instant(
            '{questionnaireName} submission is in progress. This may take a few minutes. Check the job queue for completion.',
            {
              questionnaireName: result.data.report_name,
            },
          ),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_CDP_REPORT_SUBMIT_DONE:
        this.alertsService.success(
          this.translateService.instant('Successful {questionnaireName} submission.', {
            questionnaireName: result.data.report_name,
          }),
        );
        return;
      case MESSAGE_TYPES.PLATFORM_CDP_REPORT_SUBMIT_ERROR:
        this.alertsService
          .open(
            this.translateService.instant('{questionnaireName} submission failed.', {
              questionnaireName: result.data.report_name,
            }),
            this.translateService.instant('Check Job queue for details'),
            'error',
            5000,
          )
          ?.onAction()
          .subscribe(() => {
            this.router.navigateByUrl('/app/settings/job-queue-grid');
          });
        break;
      default:
        return;
    }
  }
}
