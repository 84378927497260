import { Component, Input } from '@angular/core';
import { TableActionMenuItemEvent } from '../../../../models/tables';

@Component({
  selector: 'lib-table-body-row-action-cell',
  templateUrl: './table-body-row-action-cell.component.html',
  styleUrls: ['./table-body-row-action-cell.component.scss'],
})
export class TableBodyRowActionCellComponent<T> {
  @Input({ required: true }) rowData!: T;
  @Input() actionMenuItems?: TableActionMenuItemEvent<T>[];
}
