import { Injectable } from '@angular/core';

import { AppRoutes } from '../shared/routes/app.routes';

@Injectable({ providedIn: 'root' })
export class AuthPathResolverService {
  private appBasePath: string = AppRoutes.AUTH;

  public auth(): string {
    return `/${this.appBasePath}`;
  }
}
