import { Pipe, PipeTransform } from '@angular/core';
import { ValueDefinitionService } from '../services/value-definition.service';
import { Value, ValueDefinitionSize } from '../../models';

@Pipe({
  name: 'valueDefinitionFieldSize',
})
export class ValueDefinitionFieldSizePipe implements PipeTransform {
  constructor(private valueDefinitionService: ValueDefinitionService) {}

  transform(value: Value): ValueDefinitionSize {
    return this.valueDefinitionService.getValueDefinitionSize(value);
  }
}
