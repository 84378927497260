import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-expandable-card',
  templateUrl: './expandable-card.component.html',
  styleUrls: ['../cards.component.scss', './expandable-card.component.scss'],
})
export class ExpandableCardComponent {
  private readonly CLOSED_CARD_HEIGHT: string = '0px';
  private readonly UNSET_MAX_HEIGHT: string = 'unset';

  @Input({ required: true }) cardTitle!: string;
  @Input() cardSubtitle?: string;
  @Input() maxHeight?: number;

  @ViewChild('cardContentElement') cardContentElement?: ElementRef<Element>;
  @ViewChild('cardActionsElement') cardActionsElement?: ElementRef<Element>;

  public isExpanded: boolean = false;
  public contentMaxHeight: string = this.CLOSED_CARD_HEIGHT;

  public toggleExpand(forceOpen: boolean = false): void {
    this.isExpanded = !this.isExpanded || forceOpen;

    if (this.isExpanded) {
      setTimeout(() => {
        if (this.maxHeight && this.maxHeight > 0) {
          this.contentMaxHeight = this.maxHeight + (this.cardActionsElement?.nativeElement.scrollHeight ?? 0) + 'px';
        } else {
          this.contentMaxHeight = this.UNSET_MAX_HEIGHT;
        }
      });
    } else {
      this.contentMaxHeight = this.CLOSED_CARD_HEIGHT;
    }
  }
}
