import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  transform(array: unknown[], item: unknown): boolean {
    return array.includes(item);
  }
}
