import { fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface LocalStorageItem<T> {
  data: T;
  expiryDate: Date | undefined;
}

export class LocalStorageUtils {
  /**
   * Adds or updates a raw value to storage based on a key.
   *
   * @param key the key used to map the storage value
   * @param value raw value which will be automatically be converted with JSON.stringify before being stored
   * @param expiryDate optional param which is used as part of {@link getFromStorage} and {@link watchKeyChange} to
   * check if the date is due, automatically removing the entry from the storage if expired.
   */
  static addToStorage(key: string, value: unknown, expiryDate?: Date): void {
    const storageData: LocalStorageItem<unknown> = {
      data: value,
      expiryDate,
    };

    const stringValue: string = JSON.stringify(storageData);
    localStorage.setItem(key, stringValue);
    dispatchEvent(new StorageEvent('storage', { key, newValue: stringValue }));
  }

  /**
   * Removes the entry from the storage based on a key.
   *
   * @param key the key used to identify the storage value
   */
  static removeFromStorage(key: string): void {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
      dispatchEvent(new StorageEvent('storage', { key, newValue: null }));
    }
  }

  /**
   * Fetches the storage value based on the provided key and automatically parses it to the provided Type.
   * Alternatively, it also checks the expiry date of the entry and removes it from the storage if it's due.
   *
   * @param key the key used to identify the storage value
   * @return the parsed value or undefined if the value contains an expiryDate which is due
   */
  static getFromStorage<T>(key: string): T | undefined {
    return this.processValue(key, localStorage.getItem(key));
  }

  /**
   * Listens to all changes on a local storage key (add/update/delete), emitting the new value only.
   *
   * @param key the key used to identify the storage value
   * @return the newValue emitted or undefined if the value contains an expiryDate which is due
   */
  static watchKeyChange<T>(key: string): Observable<T | undefined> {
    return fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((event: StorageEvent) => event.key === key),
      map((event: StorageEvent) => this.processValue(key, event.newValue)),
    );
  }

  private static removeIfExpired(key: string, item: LocalStorageItem<unknown>): boolean {
    if (item.expiryDate && new Date(item.expiryDate).getTime() < new Date().getTime()) {
      this.removeFromStorage(key);
      return true;
    }

    return false;
  }

  private static processValue<T>(key: string, value: string | null): T | undefined {
    if (!value) {
      return;
    }

    const parsedValue: LocalStorageItem<T> = JSON.parse(value) as LocalStorageItem<T>;

    if (this.removeIfExpired(key, parsedValue)) {
      return;
    }

    return parsedValue.data;
  }
}
