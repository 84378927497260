<div *ngFor="let filterCategory of filterCategories">
  <ng-container *ngIf="filteredOptions | filter: filterFunction : filterCategory as optionsByCategory">
    <ng-container *ngIf="optionsByCategory.length > 0">
      <hr class="hr-light" />
      <button mat-menu-item class="menu-title" disabled>
        {{ filterCategoryTranslations[filterCategory] | translate }}
      </button>
      <button
        mat-menu-item
        *ngFor="let option of optionsByCategory; let i = index"
        (click)="optionSelected.emit(option)"
        [ngClass]="{ active: activeOptionId === option.id }"
        [title]="option.text"
      >
        <span>{{ option.title | uppercase }}</span>
      </button>
    </ng-container>
  </ng-container>
</div>
