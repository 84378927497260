import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'lib-single-checkbox-input',
  templateUrl: './single-checkbox-input.component.html',
  styleUrls: ['./single-checkbox-input.component.scss'],
})
export class SingleCheckboxInputComponent implements OnChanges {
  @Input() label: string = '';
  @Input() control?: UntypedFormControl;

  public required: boolean = false;

  ngOnChanges(): void {
    this.required = this.control?.hasValidator(Validators.requiredTrue) ?? false;
  }
}
