import { Component, Input } from '@angular/core';
import { PasswordConfirmationForm } from './password-confirmation.form';

@Component({
  selector: 'lib-password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.scss'],
})
export class PasswordConfirmationComponent {
  @Input() passwordLabel: string = '';
  @Input() confirmPasswordLabel: string = '';
  @Input({ required: true }) passwordConfirmationForm!: PasswordConfirmationForm;

  constructor() {}
}
