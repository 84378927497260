<div class="panel-search layout-list presentation-card" [ngClass]="{ loaded: loaded, 'item-selected': selectedItemID }">
  <div class="panel-collection">
    <div class="collection collection-dummies">
      <lib-dummy *ngFor="let dummy of dummies" [presentation]="ePresentation.card"></lib-dummy>
    </div>
    <div class="h-100" [ngClass]="{ 'collection collection-items': itemCollection?.items?.length }">
      <div
        [attr.title]="item.item.imported ? ('This metric is already created' | translate) : null"
        *ngFor="let item of itemCollection?.items"
        class="card-container"
        [ngClass]="{ active: activeItem === item || selectedItem === item, selected: item.item.imported }"
        (mouseover)="onMouseOver(item)"
        (mouseout)="onMouseOut()"
      >
        <lib-card
          [item]="item"
          mode="select"
          [presentation]="ePresentation.card"
          (action)="selectItem($event)"
        ></lib-card>
      </div>
      <div *ngIf="itemCollection?.items?.length === 0" class="h-100">
        <div *ngIf="noData" class="h-100">
          <ng-content></ng-content>
        </div>
        <div *ngIf="!noData" class="h-100 collection-empty">
          <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
        </div>
      </div>
      <div #spinner class="spinner">
        <lib-spinner [diameter]="20"></lib-spinner>
      </div>
      <div class="grid-end"></div>
    </div>
  </div>
</div>
