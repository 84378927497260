import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CdkModule } from '../material/cdk.module';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directives';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { SearchModule } from '../search';
import { ComponentsModule } from '../components';
import { NumberMaskModule } from '../number-mask';
import { PanelDetailsComponent } from './panel-details/panel-details.component';
import { PanelToggleComponent } from './panel-toggle/panel-toggle.component';
import { PanelToolComponent } from './panel-tool/panel-tool.component';
import { PanelGuidanceComponent } from './panel-guidance/panel-guidance.component';
import { PanelDetailsItemsComponent } from './panel-details/panel-details-items/panel-details-items.component';

@NgModule({
  declarations: [
    PanelDetailsComponent,
    PanelToggleComponent,
    PanelToolComponent,
    PanelGuidanceComponent,
    PanelDetailsItemsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CdkModule,
    MaterialModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SearchModule,
    NumberMaskModule,
    ComponentsModule,
  ],
  providers: [provideNgxMask()],
  exports: [
    PanelDetailsComponent,
    PanelToggleComponent,
    PanelToolComponent,
    PanelGuidanceComponent,
    PanelDetailsItemsComponent,
  ],
})
export class PanelsModule {}
