import pickBy from 'lodash/pickBy';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ApplicationApiDefinition, LoginEventFilteringOption, LoginEventRequest } from '../../../models';
import { ApiService } from '../../common/api/api.service';

@Injectable({ providedIn: 'root' })
export class ClientLoginEventsService {
  private apiName: keyof ApplicationApiDefinition = 'auth';
  private resource: string;
  private servicePath: string;

  constructor(private readonly apiService: ApiService) {
    this.resource = apiService.getServicePath(this.apiName);
    this.servicePath = this.apiService.apiConfig.apis.auth.resources.loginEvents;
  }

  public getLoginEvents(options: LoginEventFilteringOption): Observable<ApiResponse<LoginEventRequest[]>> {
    const params = {
      ['limit']: options.pagination?.limit,
      ['offset']: options.pagination?.offset,
      ['order_by']: options.ordering?.order_by,
      ['order_by_direction']: options.ordering?.order_by_direction,
      ['user_ids']: options.filters?.user_ids || [],
      ['user_roles']: options.filters?.user_roles || [],
      ['event_types']: options.filters?.event_types || [],
      ['event_status']: options.filters?.event_status || [],
    };
    return this.apiService.get(`${this.resource}${this.servicePath}/login_events`, {
      params: pickBy(params, (param: unknown) => param != null),
    });
  }
}
