import { Injectable } from '@angular/core';
import { Unit } from '../models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CoreApiService } from '../services/core-api-service/core-api.service';

@Injectable()
export class UnitsService {
  private initialized: boolean = false;
  private _units$ = new BehaviorSubject<Unit[]>([]);

  public readonly units$ = this._units$.asObservable();

  constructor(private readonly coreApiService: CoreApiService) {}

  public initialize(): Observable<void> {
    if (!this.initialized) {
      return this.coreApiService.listUnits().pipe(
        map((response) => response.data),
        tap((units: Unit[]) => {
          this._units$.next(units);
          this.initialized = true;
        }),
        map(() => undefined),
      );
    }
    return of(undefined);
  }

  public getUnit(code: string): Unit | undefined {
    const units = this._units$.getValue();
    return units.find((unit) => unit.code === code);
  }
}
