import { Pipe, PipeTransform } from '@angular/core';
import { MetricTableCalculationDefinition, MetricTableCalculationTypeDetailFormula, Value } from '../../../models';

@Pipe({
  name: 'tableTotalFormula',
})
export class TableTotalFormulaPipe implements PipeTransform {
  transform(
    tableCalculationDefinitions: MetricTableCalculationDefinition[],
    tableTotal: Value,
  ): MetricTableCalculationTypeDetailFormula {
    return tableCalculationDefinitions.find((calDef) => calDef.label === tableTotal.label)?.type_details
      .formula as MetricTableCalculationTypeDetailFormula;
  }
}
