import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FieldInformationRequest } from '../../../../../models';

type FieldInformationFormModel = {
  technicalProtocol: FormControl<string | null | undefined>;
};

export class FieldInformationForm extends FormGroup<FieldInformationFormModel> {
  constructor(
    technicalProtocol: string | null | undefined,
    private showTechnicalProtocolForm: boolean = false,
    readonly fb: FormBuilder = new FormBuilder(),
  ) {
    super({
      technicalProtocol: fb.control(showTechnicalProtocolForm ? technicalProtocol : null),
    });
  }

  public toModel(): FieldInformationRequest {
    return {
      technical_protocol: this.controls.technicalProtocol.value ?? undefined,
    };
  }
}
