import { Pipe, PipeTransform } from '@angular/core';
import { BaseTemplateReport } from '../../models';

@Pipe({
  name: 'templateName',
})
export class TemplateNamePipe implements PipeTransform {
  transform(templateReport: BaseTemplateReport): string {
    return templateReport.name ?? templateReport.version ?? '';
  }
}
