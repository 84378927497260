import { Injectable } from '@angular/core';

import { combineLatestWith, filter } from 'rxjs/operators';
import { ComponentStore, OnStoreInit, tapResponse } from '@ngrx/component-store';
import { EMPTY, Observable, switchMap } from 'rxjs';

import { AdminTaxonomiesService } from '../../../services/admin';
import { ApiResponse, FilterBarSelection, Taxonomy } from '../../../models';
import { DEFAULT_PAGE_SIZE } from '../../../data-table';

export interface PaginationState {
  currentPage: number;
  pageSize: number;
}

export interface FilterState {
  orderBy?: string;
  searchTerm?: string;
  taxonomyId?: string;
}

export interface LoadingState {
  isLoading: boolean;
  isLoadingNextPage: boolean;
}

export interface AddTaxonomiesDialogState {
  clientSelectableTaxonomies: Taxonomy[];
  count: number;
  loadingState: LoadingState;
  filterState: FilterState;
  paginationState: PaginationState;
  taxonomies: Taxonomy[];
  total: number;
}

@Injectable()
export class AddTaxonomiesDialogStore extends ComponentStore<AddTaxonomiesDialogState> implements OnStoreInit {
  private static readonly DEFAULT_STATE: AddTaxonomiesDialogState = {
    clientSelectableTaxonomies: [],
    count: 0,
    filterState: { orderBy: 'code', taxonomyId: '' },
    loadingState: { isLoading: true, isLoadingNextPage: false },
    paginationState: { currentPage: 1, pageSize: DEFAULT_PAGE_SIZE },
    taxonomies: [],
    total: 0,
  };

  public readonly clientSelectableTaxonomies$: Observable<Taxonomy[]> = this.select(
    (state) => state.clientSelectableTaxonomies,
  );
  public readonly count$: Observable<number> = this.select((state) => state.count);
  public readonly filterState$: Observable<FilterState> = this.select((state) => state.filterState);
  public readonly loadingState$: Observable<LoadingState> = this.select((state) => state.loadingState);
  public readonly paginationState$: Observable<PaginationState> = this.select((state) => state.paginationState);
  public readonly taxonomies$: Observable<Taxonomy[]> = this.select((state) => state.taxonomies);
  public readonly total$: Observable<number> = this.select((state) => state.total);

  private taxonomiesToIgnore: string[] = [];

  constructor(private readonly adminTaxonomiesService: AdminTaxonomiesService) {
    super(AddTaxonomiesDialogStore.DEFAULT_STATE);
  }

  public ngrxOnStoreInit(): void {
    this.adminTaxonomiesService
      .getClientSelectableTaxonomies({
        complete_frameworks: false,
        order_by: 'code',
        order_by_direction: 'asc',
        taxonomy_ids: [],
      })
      .subscribe((res) => {
        this.patchState({ clientSelectableTaxonomies: res.data });
      });
  }

  public initialize(taxonomiesToIgnore: string[]): void {
    this.taxonomiesToIgnore = taxonomiesToIgnore;
    this.fetchTaxonomies();
  }

  public updateFilters(filters: FilterBarSelection[]): void {
    const newFilterState: FilterState = {};
    for (const filter of filters) {
      if (filter.selection[0].id !== '-1') {
        newFilterState[filter.id as keyof FilterState] = filter.selection[0].id;
      } else {
        newFilterState[filter.id as keyof FilterState] = undefined;
      }
    }
    this.updateFiltersState(newFilterState);
  }

  public loadNextPage(): void {
    this.updatePaginationState(1);
  }

  private completeLoading(): void {
    this.patchState({ loadingState: { isLoading: false, isLoadingNextPage: false } });
  }

  private readonly updateFiltersState = this.updater(
    (state: AddTaxonomiesDialogState, newFilter: FilterState): AddTaxonomiesDialogState => ({
      ...AddTaxonomiesDialogStore.DEFAULT_STATE,
      clientSelectableTaxonomies: state.clientSelectableTaxonomies,
      filterState: { ...state.filterState, ...newFilter },
    }),
  );

  private readonly updatePaginationState = this.updater(
    (state: AddTaxonomiesDialogState, pageChange: number): AddTaxonomiesDialogState => ({
      ...state,
      paginationState: {
        currentPage: state.paginationState.currentPage + pageChange,
        pageSize: state.paginationState.pageSize,
      },
      loadingState: { isLoading: false, isLoadingNextPage: true },
    }),
  );

  public readonly updateSearchTermState = this.updater(
    (state: AddTaxonomiesDialogState, searchTerm?: string): AddTaxonomiesDialogState => ({
      ...AddTaxonomiesDialogStore.DEFAULT_STATE,
      clientSelectableTaxonomies: state.clientSelectableTaxonomies,
      filterState: { ...state.filterState, searchTerm },
    }),
  );

  private readonly updateTaxonomiesState = this.updater(
    (state: AddTaxonomiesDialogState, taxonomiessResponse: ApiResponse<Taxonomy[]>): AddTaxonomiesDialogState => {
      const taxonomies = taxonomiessResponse.data.filter((t) => !this.taxonomiesToIgnore.includes(t.id));
      return {
        ...state,
        taxonomies: [...state.taxonomies, ...taxonomies],
        count: state.count + (taxonomiessResponse.meta.count ?? taxonomiessResponse.data.length),
        total: taxonomiessResponse.meta.total_count ?? 0,
      };
    },
  );

  private readonly fetchTaxonomies = this.effect((trigger$) =>
    trigger$.pipe(
      combineLatestWith(this.paginationState$, this.filterState$, this.clientSelectableTaxonomies$),
      filter(([_, _paginationState, _filterState, clientSelectableTaxonomies]) =>
        Boolean(clientSelectableTaxonomies.length),
      ),
      switchMap(([_, paginationState, filterState, clientSelectableTaxonomies]) =>
        this.adminTaxonomiesService.getLeafTaxonomies({
          taxonomy_ids: filterState.taxonomyId ? [filterState.taxonomyId] : clientSelectableTaxonomies.map((t) => t.id),
          page: paginationState.currentPage,
          page_size: paginationState.pageSize,
          search_term: filterState.searchTerm,
          order_by: filterState.orderBy,
          order_by_direction: filterState.orderBy === 'code' ? 'asc' : 'desc',
          complete_frameworks: true,
        }),
      ),
      tapResponse(
        (apiResponse: ApiResponse<Taxonomy[]>) => {
          this.updateTaxonomiesState(apiResponse);
          this.completeLoading();
        },
        (_err) => {
          this.completeLoading();
          return EMPTY;
        },
      ),
    ),
  );
}
