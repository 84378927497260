import { Component, Input } from '@angular/core';

import { ActionItem, OptionListCategory } from '../../models';
import { OptionListForm } from './option-list-form';
import { OptionListTypeTranslations } from '../../translations';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-option-list-form',
  templateUrl: './option-list-form.component.html',
  styleUrls: ['./option-list-form.component.scss'],
})
export class OptionListFormComponent {
  @Input({ required: true }) optionListCategories!: ActionItem<OptionListCategory>[];
  @Input({ required: true }) form!: OptionListForm;
  @Input() withPublicField: boolean = false;
  @Input() withTypeField: boolean = false;

  public optionListTypes = this.translateService.listResources(OptionListTypeTranslations);

  constructor(private readonly translateService: TranslateService) {}
}
