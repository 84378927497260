import { Component, Input, OnChanges } from '@angular/core';
import { ValueDefinition, ValueDefinitionGroup, ValueDefinitionType, isContextColumn } from '../../../../../models';
import { MetricTableGroup } from '../../../../models';
import { partition } from 'lodash';

@Component({
  selector: 'lib-metric-structure-table-details',
  templateUrl: './metric-structure-table-details.component.html',
  styleUrls: ['./metric-structure-table-details.component.scss'],
})
export class MetricStructureTableDetailsComponent implements OnChanges {
  @Input({ required: true }) metricTableGroup!: MetricTableGroup;
  @Input() isDeactivated?: boolean = false;

  tableDataSource: ValueDefinitionGroup[] = [];
  contextHeaderValues: ValueDefinition[] = [];
  inputHeaderValues: ValueDefinition[] = [];

  constructor() {}

  ngOnChanges(): void {
    this.setupTableDataSource();
    this.configureHeaderColumns();
  }

  private setupTableDataSource(): void {
    this.tableDataSource = this.metricTableGroup.valueDefinitionGroups.filter(
      (group) =>
        group.value_definitions?.length &&
        !group.value_definitions.some((vd) => vd.type === ValueDefinitionType.calculated),
    );
  }

  private configureHeaderColumns(): void {
    [this.contextHeaderValues, this.inputHeaderValues] = partition(
      this.metricTableGroup.valueDefinitionGroups[0].value_definitions,
      (vd: ValueDefinition) => isContextColumn(vd),
    );
  }

  public filterLabelTypeValue(vd: ValueDefinition): boolean {
    return isContextColumn(vd);
  }

  public filterValuesExceptLabelType(vd: ValueDefinition): boolean {
    return !isContextColumn(vd);
  }
}
