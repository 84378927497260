import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadDocumentService {
  public downloadAction(fileName: string, doc: Blob): void {
    const blob: Blob = new Blob([doc], { type: 'application/octet-stream' });
    const file = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = file;
    a.download = fileName;
    a.click();
  }
}
