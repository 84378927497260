import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import {
  ActionItem,
  ChipGroupItem,
  Indicator,
  IndicatorRelatedMetrics,
  RelatedMetricSource,
  RelatedMetricsResourceType,
  StandardCodes,
} from '../../../../models';

type Resource = ChipGroupItem & { item: Indicator | StandardCodes; type: RelatedMetricsResourceType };

@Component({
  selector: 'lib-metric-card-related-metrics',
  templateUrl: './metric-card-related-metrics.component.html',
  styleUrls: ['./metric-card-related-metrics.component.scss'],
})
export class MetricCardRelatedMetricsComponent implements OnInit {
  @Input() metricActionItem?: ActionItem<Indicator>;
  @Input() maxItems: number = 1;
  @Input() small? = false;
  @Output() handleSelect = new EventEmitter<ActionItem<Indicator | StandardCodes>>();

  allRelatedResources: Resource[] = [];
  relatedMetricsResourceType: typeof RelatedMetricsResourceType = RelatedMetricsResourceType;

  resourcesToShow: { type: RelatedMetricsResourceType; item: Indicator | StandardCodes }[] = [];
  remainingRelatedMetrics: { type: RelatedMetricsResourceType; item: Indicator | StandardCodes }[] = [];

  ngOnInit(): void {
    this.initItems();
  }

  public onChipsClick(event: MouseEvent, item: Indicator | StandardCodes, id: string, title?: string): void {
    this.stopDefaultClickAction(event);
    this.handleSelect.emit({ id, item, title: title || '' });
  }

  public stopDefaultClickAction(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public handleClick(index: number): void {
    const resource = this.allRelatedResources[index];
    this.handleSelect.emit({ id: resource.type, item: resource.item, title: this.metricActionItem?.item?.code || '' });
  }

  private initItems(): void {
    const relatedMetrics: IndicatorRelatedMetrics | undefined = this.metricActionItem?.item?.related_metrics;
    const standardCodes: StandardCodes[] = this.metricActionItem?.item?.standard_codes ?? [];
    this.allRelatedResources = standardCodes.map((standardCode) => ({
      text: standardCode.code,
      item: standardCode,
      type: RelatedMetricsResourceType.standard_code,
      variant: 'primary',
    }));

    if (relatedMetrics?.length) {
      relatedMetrics.forEach((relatedMetric) => {
        const isCore = relatedMetric.source === RelatedMetricSource.core;
        const related: Resource = {
          text: String(relatedMetric.equivalent_metric.code),
          outline: false,
          item: relatedMetric.equivalent_metric,
          type: isCore ? RelatedMetricsResourceType.core_metric : RelatedMetricsResourceType.imported_metric,
          variant: isCore ? 'light' : 'primary',
        };
        this.allRelatedResources.push(related);
      });
    }
    this.remainingRelatedMetrics = JSON.parse(JSON.stringify(this.allRelatedResources));
    this.resourcesToShow = this.remainingRelatedMetrics.splice(0, this.maxItems);
  }
}
