import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const AlphabeticKeys = {
  a: _('a'),
  b: _('b'),
  c: _('c'),
  d: _('d'),
  e: _('e'),
  f: _('f'),
  g: _('g'),
  h: _('h'),
  i: _('i'),
  j: _('j'),
  k: _('k'),
  l: _('l'),
  m: _('m'),
  n: _('n'),
  o: _('o'),
  p: _('p'),
  q: _('q'),
  r: _('r'),
  s: _('s'),
  t: _('t'),
  u: _('u'),
  v: _('v'),
  w: _('w'),
  x: _('x'),
  y: _('y'),
  z: _('z'),
};
