import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionItem, ChipType } from '../../models';
import { MetricSearchStateService } from '../metric-search-state.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lib-show-more-chip-cards',
  templateUrl: './show-more-chip-cards.component.html',
  styleUrls: ['./show-more-chip-cards.component.scss'],
})
export class ShowMoreChipCardsComponent implements OnInit, OnDestroy {
  @Input() chipItems: ActionItem[] = [];
  @Input() maxItems: number = 1;
  @Input() small? = false;
  @Input() disableChipAction = false;

  @Output() chipSelect: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();

  private searchServiceSubscription: Subscription;

  readonly eChipType: typeof ChipType = ChipType;
  chipsToShow: ActionItem[] = [];
  remainingChipItems: ActionItem[] = [];
  searchKeywords?: string;

  constructor(private metricSearchStateService: MetricSearchStateService) {
    this.searchServiceSubscription = this.metricSearchStateService.searchOptions$
      .pipe(map((searchOptions) => searchOptions?.query.keywords))
      .subscribe((keywords) => {
        if (keywords) {
          this.searchKeywords = keywords;
        }
      });
  }

  ngOnInit(): void {
    this.initItems();
  }

  ngOnDestroy(): void {
    this.searchServiceSubscription.unsubscribe();
  }

  private initItems(): void {
    this.remainingChipItems = [...this.chipItems];
    this.handleChipsToShow();
  }

  private handleChipsToShow(): void {
    if (this.searchKeywords) {
      const indexOfMatchingChip = this.remainingChipItems.findIndex((chip) => chip.title === this.searchKeywords);
      if (indexOfMatchingChip !== -1) {
        const matchingChip = this.remainingChipItems.splice(indexOfMatchingChip, 1)[0];
        this.remainingChipItems.unshift(matchingChip);
      }
    }
    this.chipsToShow = this.remainingChipItems.splice(0, this.maxItems);
  }

  public stopDefaultClickAction(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public onChipsClick(event: MouseEvent, item: ActionItem): void {
    if (this.disableChipAction) {
      return;
    }
    this.stopDefaultClickAction(event);
    this.chipSelect.emit(item);
  }
}
