import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ActionItem, Permission, TemplateReportSection } from '../../../models';
import { TemplateReportSectionForm } from './forms/template-report-section-form';
import { TemplateReportsApiService } from '../../../services/api-services';
import { finalize, map } from 'rxjs/operators';
import { TemplateReportStructureStateService } from '../../services/template-report-structure-state.service';

@Component({
  selector: 'lib-template-report-panel-section-form',
  templateUrl: './template-report-panel-section-form.component.html',
  styleUrls: ['./template-report-panel-section-form.component.scss'],
})
export class TemplateReportPanelSectionFormComponent implements OnChanges {
  @Input() managePermissions: Permission[] = [];
  @Input() templateReportId?: string;
  @Input() templateReportVersionId?: string;
  @Input() templateReportSection?: TemplateReportSection;
  @Input() editingSection: boolean = false;
  @Input() sectionsLabelById?: Map<string, TemplateReportSection>;

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  public sectionForm?: TemplateReportSectionForm;
  public parentOptions: ActionItem[] = [];
  public isLoading: boolean = false;

  constructor(
    private readonly templateReportsApiService: TemplateReportsApiService,
    private readonly templateReportStructureStateService: TemplateReportStructureStateService,
  ) {}

  ngOnChanges(): void {
    this.refreshForm();
    this.setupParentOptions();
  }

  public close(): void {
    this.refreshForm();
    this.closePanel.emit();
  }

  public saveSection() {
    if (!this.sectionForm) {
      return;
    }

    this.isLoading = true;

    const sectionSaveCall = this.editingSection
      ? this.templateReportsApiService.editTemplateReportSection(
          this.templateReportId as string,
          this.templateReportSection?.id as string,
          this.sectionForm.toModel(),
          this.templateReportVersionId,
        )
      : this.templateReportsApiService.createTemplateReportSection(
          this.templateReportId as string,
          this.sectionForm.toModel(),
          this.templateReportVersionId,
        );

    sectionSaveCall
      .pipe(
        map((response) => response.data),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (section: TemplateReportSection) => {
          this.editingSection
            ? this.templateReportStructureStateService.editSection(section)
            : this.templateReportStructureStateService.addSection(section);
        },
        complete: () => {
          this.closePanel.emit();
        },
      });
  }

  private refreshForm() {
    const sectionParent = this.editingSection
      ? this.sectionsLabelById?.get(this.templateReportSection?.parent_id ?? '')
      : this.sectionsLabelById?.get(this.templateReportSection?.id ?? '');
    this.sectionForm = new TemplateReportSectionForm(
      this.editingSection ? this.templateReportSection : undefined,
      sectionParent?.id,
    );
  }

  private setupParentOptions(): void {
    const sectionIds: Set<string> = new Set(
      this.getFlattenedSectionHierarchy(this.templateReportSection ? [this.templateReportSection] : []).map(
        (section) => section.id,
      ),
    );

    this.parentOptions = Array.from(this.sectionsLabelById?.values() ?? [])
      .map((section) => ({ id: section.id, title: section.label, item: section }) as ActionItem<TemplateReportSection>)
      .filter((section) => !this.editingSection || !sectionIds.has(section.id));
  }

  private getFlattenedSectionHierarchy(sections: TemplateReportSection[]): TemplateReportSection[] {
    return sections.concat(sections.map((section) => this.getFlattenedSectionHierarchy(section.children ?? [])).flat());
  }
}
