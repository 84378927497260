import { ChangeDetectorRef, Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[libDynamicHost]',
})
export class DynamicHostDirective {
  constructor(
    public viewContainerRef: ViewContainerRef,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}
}
