import { Pipe, PipeTransform } from '@angular/core';
import { ActionItem, Indicator, ItemType } from '../../../models';

@Pipe({
  name: 'getRelatedMetricItems',
})
export class GetRelatedMetricItemsPipe implements PipeTransform {
  transform(indicator: Indicator): ActionItem<Indicator> {
    indicator = { ...indicator, standard_codes: [] };
    return {
      id: indicator.id,
      title: indicator.description,
      item: indicator,
      item_type: ItemType.metrics_indicator,
    };
  }
}
