import { Component, Input, OnInit } from '@angular/core';
import { ValueFormControl } from '../../models/valueFormControl';
import { SubtitleTypeDetails } from '../../../models';

@Component({
  selector: 'lib-metric-editor-subtitle',
  templateUrl: './metric-editor-subtitle.component.html',
  styleUrls: ['./metric-editor-subtitle.component.scss'],
})
export class MetricEditorSubtitleComponent implements OnInit {
  @Input({ required: true }) valueFormControl!: ValueFormControl<SubtitleTypeDetails>;

  subtitleText: string = '';

  ngOnInit(): void {
    this.subtitleText = this.valueFormControl.valueRef.type_details.value;
  }
}
