import { Component } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { TranslateService } from '../../../services/common';

@Component({
  selector: 'lib-content-not-available-page',
  templateUrl: './content-not-available-page.component.html',
  styleUrls: ['./content-not-available-page.component.scss'],
})
export class ContentNotAvailablePageComponent {
  public errorMessage: string = this.translateService.instant('Oops! Looks like this content is unavailable.');

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
  ) {
    const navigation: Navigation | null = this.router.getCurrentNavigation();
    const state: { [p: string]: any } | undefined = navigation?.extras.state;

    if (state?.errorMessage) {
      this.errorMessage = state.errorMessage as string;
    }
  }
}
