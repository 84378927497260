import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  ApiResponse,
  ApplicationApiDefinition,
  UserGroupRequest,
  UserGroup,
  UserGroupNameCheck,
  UserGroupsFilteringOptions,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientUserGroupsService {
  private apiName: keyof ApplicationApiDefinition = 'collect';
  private resource: string;
  private servicePath: string;

  constructor(private readonly apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.userGroups;
  }

  public getUserGroup(userGroupId: string): Observable<ApiResponse<UserGroup>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/user_groups/${userGroupId}`);
  }

  public getUserGroups(filteringOptions: UserGroupsFilteringOptions): Observable<ApiResponse<UserGroup[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/user_groups`, { params: filteringOptions });
  }

  public createUserGroup(userGroupRequest: UserGroupRequest): Observable<ApiResponse<UserGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/user_groups`, userGroupRequest);
  }

  public updateUserGroup(userGroupId: string, userGroupRequest: UserGroupRequest): Observable<ApiResponse<UserGroup>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/user_groups/${userGroupId}`, userGroupRequest);
  }

  public isUserGroupNameAvailable(userGroupName: string): Observable<ApiResponse<UserGroupNameCheck>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/user_groups/name_check/${userGroupName}`);
  }

  public activateUserGroup(userGroupId: string): Observable<ApiResponse<UserGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/user_groups/${userGroupId}/activate`);
  }

  public deactivateUserGroup(userGroupId: string): Observable<ApiResponse<UserGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/user_groups/${userGroupId}/deactivate`);
  }

  public associateUserToUserGroup(userGroupId: string, userId: string): Observable<ApiResponse<UserGroup>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/user_groups/${userGroupId}/users/${userId}`);
  }

  public dissociateUserFromUserGroup(userGroupId: string, userId: string): Observable<ApiResponse<UserGroup>> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/user_groups/${userGroupId}/users/${userId}`);
  }
}
