import { Component, Input, OnInit } from '@angular/core';
import { ActionItem, EmptyResults, Indicator, StandardCodes } from '../../models';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-panel-guidance',
  templateUrl: './panel-guidance.component.html',
  styleUrls: ['./panel-guidance.component.scss'],
})
export class PanelGuidanceComponent implements OnInit {
  @Input() indicator?: Indicator;

  selectedCode?: StandardCodes;
  sortedStandardCodeOptions: ActionItem[] = [];

  readonly guidanceEmptyResults: EmptyResults = {
    title: this.translateService.instant('No guidance'),
    subtitle: this.translateService.instant('There is no guidance for the selected metric at this time.'),
    image: 'laptop-neutral',
  };

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.sortedStandardCodeOptions = this.getSortedStandardCodeOptions();
  }

  get guidance(): string | undefined {
    return this.selectedCode ? this.selectedCode?.guidance : this.indicator?.guidance;
  }

  setGuidance(guidanceAction: ActionItem): void {
    if (guidanceAction.id === this.indicator?.id) {
      this.selectedCode = undefined;
    } else {
      this.selectedCode = this.indicator?.standard_codes?.find(
        (standardCode: StandardCodes) => standardCode.id === guidanceAction.id,
      );
    }
  }

  private getSortedStandardCodeOptions(): ActionItem[] {
    return (this.indicator?.standard_codes ?? [])
      .map((code: StandardCodes) => ({ id: code.id, title: code.code }))
      .sort((a: ActionItem, b: ActionItem) => a.title.localeCompare(b.title));
  }
}
