export class CopyToClipboardUtils {
  static copyRichTextToClipboard(value: string): boolean {
    let result: boolean = false;
    const listener = (event: ClipboardEvent) => {
      event.clipboardData?.setData('text/html', value);
      event.preventDefault();
      return event;
    };

    document.addEventListener('copy', listener);
    result = document.execCommand('copy');
    document.removeEventListener('copy', listener);
    return result;
  }
}
