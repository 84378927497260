import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ReportCategoryType, ReportIntegrationType, ReportStatusEnum } from '../../models';

export {
  QuestionnaireStatus,
  ReportsReportCategories,
  ReportsReportStatus,
  ReportsTemplateCategories,
  ReportTemplateIntegrationsTranslation,
};

const ReportsReportCategories = {
  [ReportCategoryType.questionnaire]: _('Questionnaire'),
  [ReportCategoryType.data_table]: _('Data table'),
  [ReportCategoryType.detailed]: _('Detailed report'),
  [ReportCategoryType.template_framework]: _('Framework template'),
};

const ReportsReportStatus = {
  [ReportStatusEnum.draft]: _('In progress'),
  [ReportStatusEnum.completed]: _('Completed'),
  [ReportStatusEnum.archived]: _('Archived'),
};

const QuestionnaireStatus = {
  [ReportStatusEnum.draft]: _('In progress'),
  [ReportStatusEnum.completed]: _('Complete'),
  [ReportStatusEnum.archived]: _('Closed'),
};

const ReportsTemplateCategories = {
  [ReportCategoryType.questionnaire]: _('Questionnaire'),
  [ReportCategoryType.data_table]: _('Data table'),
  [ReportCategoryType.detailed]: _('Detailed report'),
  [ReportCategoryType.template_framework]: _('Framework template'),
};

const ReportTemplateIntegrationsTranslation: Record<ReportIntegrationType, string> = {
  [ReportIntegrationType.S_AND_P]: _('S&P'),
  [ReportIntegrationType.CDP]: _('CDP'),
};
