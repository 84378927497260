type Falsy = 0 | 0n | '' | false | null | undefined;

export class ArrayUtils {
  static toCommaString<T>(items: T[] | undefined | null, propertyName: keyof T): string {
    if (items?.length) {
      return items
        .map((item) => item[propertyName])
        .sort()
        .join(', ');
    }

    return '';
  }

  static ExcludeFalsy = Boolean as unknown as <T>(x: T | Falsy) => x is T;
}
