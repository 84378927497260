<div
  data-testid="drop-area"
  class="formfield"
  [ngClass]="{
    error: valueFormControl.errors?.length,
    disabled: disabled,
  }"
  (drop)="dropFile($event)"
  (dragover)="preventEventDefault($event)"
>
  <lib-form-field-label
    [label]="valueFormControl | fileLabel: uploading"
    [control]="valueFormControl"
  ></lib-form-field-label>
  <a
    class="card card-dashed align-center p-0 justify-center"
    tabindex="1"
    data-testid="file-drop-card"
    [ngClass]="{ disabled: disabled }"
  >
    <div class="mat-body-2 color-grey-800" *ngIf="!uploading; else uploadAction">
      {{ "Drag files to import, or" | translate }}
      <button lib-button buttonType="browse" *ngIf="!customBrowseDialog; else customBrowse">
        {{ "browse" | translate }}<input type="file" data-testid="fileInput" (change)="selectFileFromBrowser($event)" />
      </button>
      <ng-template #customBrowse>
        <button lib-button buttonType="browse" (click)="launchCustomBrowseDialog()">
          {{ "browse" | translate }}
        </button>
      </ng-template>
    </div>
    <ng-template #uploadAction>
      <div class="fx-row align-center">
        <div class="mr-4 mat-body-2 color-grey-800">{{ "Uploading file. Please wait..." | translate }}</div>
        <div class="spinner">
          <lib-spinner ariaLabel="upload-spinner" [diameter]="20"></lib-spinner>
        </div>
      </div>
    </ng-template>
  </a>
  <mat-hint *ngIf="valueFormControl.untouched || valueFormControl.valid" class="fx-row justify-space-between">
    <div>{{ hint }}</div>
    <div>{{ "150MB" | translate }}</div>
  </mat-hint>
  <ng-container *ngIf="valueFormControl.touched && valueFormControl.invalid">
    <mat-error *ngFor="let error of valueFormControl.errors | errorKeys"> {{ messages?.[error] }}</mat-error>
  </ng-container>
</div>
