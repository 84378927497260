import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../common/api/api.service';
import { ApiResponse, ApplicationApiDefinition, SearchOptions, StandardCodes } from '../../../models';
import { HttpParams } from '@angular/common/http';

interface SearchStandardCodesFilters {
  start?: string;
  industries?: string[];
  topics?: string[];
  topic_categories?: string[];
  topic_groups?: string[];
  standard_codes?: string[];
}

interface SearchStandardCodesPayload {
  filters: SearchStandardCodesFilters;
  order_by: 'code' | 'updated';
  order_by_direction?: 'desc';
  load_topics: boolean;
  load_industries: boolean;
  from: number;
  size: number;
  keywords?: string;
}

export interface GetStandardCodeWithMetricsOptions {
  load_framework?: boolean;
  load_tags?: boolean;
  load_topics?: boolean;
  load_industries?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ClientStandardCodeService {
  apiName: keyof ApplicationApiDefinition = 'platform';
  resource: string;
  servicePath: string;
  platformResource: string;

  DEFAULT_GET_STANDARD_CODE_OPTIONS: Required<GetStandardCodeWithMetricsOptions> = {
    load_framework: false,
    load_tags: false,
    load_topics: false,
    load_industries: false,
  };

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.platform.resources.core;
    this.platformResource = this.apiService.apiConfig.apis.platform.resources.global;
  }

  public getStandardCodesWithMetrics(
    idStandardCode: string,
    options: GetStandardCodeWithMetricsOptions = {},
  ): Observable<ApiResponse<StandardCodes>> {
    const assembledOptions = { ...this.DEFAULT_GET_STANDARD_CODE_OPTIONS, ...options };

    let params = new HttpParams();
    params = params.append('load_framework', assembledOptions.load_framework);
    params = params.append('load_tags', assembledOptions.load_tags);
    params = params.append('load_topics', assembledOptions.load_topics);
    params = params.append('load_industries', assembledOptions.load_industries);

    return this.apiService.get(`${this.servicePath}${this.platformResource}/standard_code_metrics/${idStandardCode}`, {
      params,
    });
  }

  public listStandardCodes(searchOptions: SearchOptions): Observable<ApiResponse<StandardCodes[]>> {
    const payload = this.standardCodesPayloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resource}/standard_codes/list`, payload);
  }

  private standardCodesPayloadFromSearchOptions(searchOptions: SearchOptions) {
    const payload: SearchStandardCodesPayload = {
      filters: {},
      order_by: 'code',
      load_topics: true,
      load_industries: true,
      from: searchOptions.from || 0,
      size: searchOptions.size || this.apiService.apiConfig.pageSize,
    };

    if (searchOptions.query.keywords) {
      payload.keywords = searchOptions.query.keywords;
    }

    if (searchOptions.filters.topic) {
      if (searchOptions.filters.topic.action === 'category') {
        payload.filters.topic_categories = [searchOptions.filters.topic.id];
      } else if (searchOptions.filters.topic.action === 'group') {
        payload.filters.topic_groups = [searchOptions.filters.topic.id];
      } else {
        payload.filters.topics = [searchOptions.filters.topic.id];
      }
    }

    if (searchOptions.filters.industry) {
      payload.filters.industries = [searchOptions.filters.industry.id];
    }

    if (searchOptions.filters.standard_codes) {
      payload.filters.standard_codes = [searchOptions.filters.standard_codes.id];
    }

    if (searchOptions.filters.start) {
      payload.filters.start = searchOptions.filters.start.item.split('T')[0];
    }

    if (searchOptions.sort) {
      switch (searchOptions.sort.id) {
        case 'updated':
          payload.order_by = 'updated';
          payload.order_by_direction = 'desc';
          break;
        default:
          break;
      }
    }

    return payload;
  }
}
