import { Component, Input } from '@angular/core';
import { ValueFormControl } from '../../../models/valueFormControl';
import { LabelTypeDetails } from '../../../../models';

@Component({
  selector: 'lib-metric-editor-table-label-field',
  templateUrl: './metric-editor-table-label-field.component.html',
  styleUrls: ['./metric-editor-table-label-field.component.scss'],
})
export class MetricEditorTableLabelFieldComponent {
  @Input() control?: ValueFormControl<LabelTypeDetails>;
}
