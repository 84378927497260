import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionItem, Indicator, Industry, StandardCodes, Topic } from '../../../../../lib/models';

@Component({
  selector: 'lib-standard-codes-card-footer',
  templateUrl: './standard-codes-card-footer.component.html',
  styleUrls: ['./standard-codes-card-footer.component.scss'],
})
export class StandardCodesCardFooterComponent implements OnInit {
  @Input() actionItem?: ActionItem<Indicator>;
  @Input() limitedCardSize = false;
  @Input() hideDate = false;
  @Output() footerSelect = new EventEmitter<ActionItem<Indicator | StandardCodes>>();

  remainingTopics: Topic[] = [];
  remainingIndustries: Industry[] = [];

  ngOnInit(): void {
    this.getRemainingDefaultItems();
  }

  public onChipsClick(event: ActionItem<Indicator | StandardCodes>): void {
    this.footerSelect.emit(event);
  }

  public stopDefaultClickAction(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private getRemainingDefaultItems(): void {
    if (this.actionItem?.item?.topics?.length) {
      this.remainingTopics = JSON.parse(JSON.stringify(this.actionItem.item.topics));
      this.remainingTopics.splice(0, 1);
    }
    if (this.actionItem?.item?.industries?.length) {
      this.remainingIndustries = JSON.parse(JSON.stringify(this.actionItem.item.industries)) ?? [];
      this.remainingIndustries.splice(0, 1);
    }
  }
}
