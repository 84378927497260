export interface SPCompany {
  business_id: number;
  company_id: number;
  long_name: string;
}

export interface SPQuestionnaire {
  id: number;
  cam_id: number;
  cam_name: string;
  start_date: Date;
  end_date: Date;
  editable: boolean;
  will_expire_soon: boolean;
  prefill_blocked: boolean;
  avg_status: number;
  validity: string;
  progress: string;
  enable_start_questionnaire: boolean;
}

export interface SPReportSubmmisionPayload {
  report_name: string;
  participation_name: string;
  username: string;
  password: string;
  company_id: number;
  participation_id: number;
}

export interface CDPReportSubmissionPayload {
  report_name: string;
}

export enum ReportTemplateIntegrationType {
  S_AND_P = 's_and_p',
  CDP = 'cdp',
}
