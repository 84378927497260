import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[libAutoFocusInput]',
})
export class AutoFocusInputDirective implements AfterViewInit {
  @Input() libAutoFocusInput: boolean = false;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.libAutoFocusInput) {
      setTimeout(() => this.elementRef.nativeElement.focus());
    }
  }
}
