import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItemConfig } from '../../../models';

@Component({
  selector: 'lib-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
})
export class MenuItemsComponent {
  @Input() items: MenuItemConfig[] = [];

  @Output() itemClick: EventEmitter<MenuItemConfig> = new EventEmitter<MenuItemConfig>();

  public onItemClick(itemConfig: MenuItemConfig): void {
    this.itemClick.emit(itemConfig);
  }
}
