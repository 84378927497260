import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'castAsKeyOf' })
export class CastAsKeyOfPipe implements PipeTransform {
  // This is not tested. Typing doesn't exist in runtime, only in compile time, so it can't be tested.
  // This pipe was created to fix a typing issue in compile time using a variable coming from a template context
  // as a key for an enum.
  transform<T>(value: unknown, type: T): keyof T {
    return value as keyof typeof type;
  }
}
