<lib-form-field-label
  [label]="valueFormControl | fileLabel: false"
  [control]="valueFormControl"
  *ngIf="(!attachmentEnabled && attachmentOptional) || maxFilesLimitReached"
></lib-form-field-label>

<lib-metric-editor-file-upload-card
  [valueFormControl]="valueFormControl"
  [documentContext]="documentContext"
  [messages]="messages"
  [disabled]="valueFormControl.disabled || addingNewFile"
  (addFileDoc)="addFileDoc($event)"
  (addFileValue)="addFileValue($event)"
  *ngIf="!maxFilesLimitReached && attachmentEnabled"
></lib-metric-editor-file-upload-card>

<ng-container *ngIf="filesLoaded; else loading">
  <div class="file-cards-container" @fadeIn>
    <lib-metric-editor-file-card
      *ngFor="let fileValue of fileValues; let i = index"
      [fileValue]="fileValue"
      [valueFormControl]="valueFormControl"
      [fileDoc]="fileValue | findFileDocument: fileDocumentsList"
      [documentContext]="documentContext"
      [status]="valueFormControl.status"
      [index]="i"
      (deleteFileValue)="deleteFileValue($event)"
      (disableValueFormControl)="handleValueFromControlDisablingAndErrors($event)"
    ></lib-metric-editor-file-card>
  </div>
</ng-container>

<ng-container *ngIf="!maxFilesLimitReached && attachmentOptional">
  <div class="mt-4 mb-n-1" *ngIf="attachmentEnabled">
    <strong>{{ "OR" | translate }}</strong>
  </div>

  <div class="mv-4">
    <button
      lib-button
      buttonType="link"
      class="pl-0"
      (click)="addFileURLValue()"
      [ngClass]="{ disabled: valueFormControl.disabled || addingNewFile }"
    >
      {{ "+ Add URL" | translate }}
    </button>
  </div>
</ng-container>

<ng-container *ngIf="maxFilesLimitReached">
  <div class="mv-4">
    <lib-info-banner type="warning" [message]="'Maximum number of attachments have been reached.' | translate">
    </lib-info-banner>
  </div>
</ng-container>

<ng-template #loading>
  <div class="dummies-container w-100 h-100 fx-col" @fadeOut>
    <div class="h-100 pt-2" data-testid="loadingDummies">
      <lib-dummy *ngFor="let i of [1, 2]" [presentation]="ePresentation.cardLink"></lib-dummy>
    </div>
  </div>
</ng-template>
