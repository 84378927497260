import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { ValidationMessageService } from '../../../../services/common';
import { RadioButtonOption } from '../../../../components';

@Component({
  selector: 'lib-metric-editor-radio-field',
  templateUrl: './metric-editor-radio-field.component.html',
  styleUrls: ['./metric-editor-radio-field.component.scss'],
})
export class MetricEditorRadioFieldComponent implements OnInit, OnChanges {
  @Input({ required: true }) control!: UntypedFormControl;
  @Input() options: string[] = [];
  @Input() messages?: ValidationErrors;
  @Input() hint?: string;

  @ViewChild('focusElement') focusElement!: ElementRef;

  required: boolean = false;
  errorMessages: ValidationErrors = {};
  radioChoicesList: RadioButtonOption[] = [];

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnInit(): void {
    this.radioChoicesList = this.toRadioOption(this.options);
  }

  ngOnChanges(): void {
    this.initializeInput();
  }

  private initializeInput() {
    this.required = this.control.hasValidator(Validators.required);
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  public setFocus(): void {
    this.focusElement.nativeElement.focus();
  }

  private toRadioOption(choicesList: string[]): RadioButtonOption[] {
    return choicesList.map((choice) => ({ value: choice, label: choice }));
  }
}
