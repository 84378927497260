import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  AfterViewInit,
} from '@angular/core';
import {
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  EmptyResults,
  Indicator,
  ReportIntegrationType,
  SOURCE_CONFIGURATION,
  ValueGroupSet,
} from '../models';
import { ValueGroupSetForm } from './models/valueGroupSetForm';
import { ResetValueEvent, ResetValueEventWithoutVgsetId } from './models/resetValueEvent';
import { UpsertValueGroup, UpsertValueGroupSet } from './models/upsertValue';
import { MoveValueGroupEvent } from './models/moveValueGroupEvent';
import { MetricEditorGroupHandlerComponent } from './components/metric-editor-group-handler/metric-editor-group-handler.component';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from './models/documentContext';
import { MetricEditorFormGroup } from './models/metricEditorFormGroup';
import { delay, startWith, Subject, takeUntil } from 'rxjs';
import { waitForNextUpdate } from './utils/operators';
import { BaseMetricEditorFormStateService } from './services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import { HtmlElementUtils } from '../classes/HtmlElementUtils/html-element-utils';
import { TranslateService } from '../services/common/translate/translate.service';

@Component({
  selector: 'lib-metric-editor-form',
  templateUrl: './metric-editor-form.component.html',
  styleUrls: ['./metric-editor-form.component.scss'],
})
export class MetricEditorFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  public readonly GROUPS_PAGE_INCREMENT = 5;
  public readonly TOTAL_SHOWN_GROUPS = 15;
  private readonly TOP_SPINNER_SIZE = 50;

  @Input({ required: true }) valueGroupSet!: ValueGroupSet;
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Input() indicator?: Indicator;
  @Input() isSourceConsolidated?: boolean;

  @Input() disabled = false;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() areConsolidationRulesEnabled = false;
  @Input() disabledReason?: string;
  @Input() displayFieldActions: boolean = false;
  @Input() collaboratorResponsibility?: DataRequestUserResponsibility;
  @Input() dataRequestSourceStatus!: DataRequestSourceStatus;
  @Input() integrationType: ReportIntegrationType | null = null;
  @Input() disableFrequencyFields: boolean = false;

  @Output() update: EventEmitter<UpsertValueGroupSet> = new EventEmitter<UpsertValueGroupSet>();
  @Output() moveGroup: EventEmitter<MoveValueGroupEvent> = new EventEmitter<MoveValueGroupEvent>();
  @Output() deleteGroup: EventEmitter<string> = new EventEmitter<string>();
  @Output() resetValue: EventEmitter<ResetValueEvent> = new EventEmitter<ResetValueEvent>();
  @Output() metricLinkEdit: EventEmitter<string> = new EventEmitter<string>();
  @Output() isVgsetFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(MetricEditorGroupHandlerComponent)
  metricEditorGroupHandlerComponents!: QueryList<MetricEditorGroupHandlerComponent>;

  @ViewChild('topSpinner') topSpinner?: ElementRef<HTMLElement>;
  @ViewChild('bottomSpinner') bottomSpinner?: ElementRef<HTMLElement>;

  public valueGroupSetForm?: ValueGroupSetForm;
  public groupsOffset = 0;
  public displayedGroups: MetricEditorFormGroup[] = [];

  private unsubscribe$ = new Subject<void>();
  private updateValueGroupSubject = new Subject<UpsertValueGroup>();

  private topSpinnerScrollObserver: IntersectionObserver = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      this.moveGroupsUp();
    }
  });

  private bottomSpinnerScrollObserver: IntersectionObserver = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      this.moveGroupsDown();
    }
  });

  readonly emptyResults: EmptyResults = {
    title: 'No fields',
    subtitle: this.translateService.instant('No fields are currently visible for data collection.'),
    image: 'laptop-neutral',
  };

  constructor(
    private readonly baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
    private readonly metricFormElementRef: ElementRef,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.valueGroupSetForm == null) {
      this.initializeValueGroupSetForm();
    }
  }

  ngAfterViewInit(): void {
    if (this.topSpinner) {
      this.topSpinnerScrollObserver.observe(this.topSpinner.nativeElement);
    }
    if (this.bottomSpinner) {
      this.bottomSpinnerScrollObserver.observe(this.bottomSpinner.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valueGroupSet) {
      if (this.valueGroupSetForm == null) {
        this.initializeValueGroupSetForm();
      } else {
        const previousValueGroupSetId = changes.valueGroupSet.previousValue.id as string | undefined;
        const currentValueGroupSetId = changes.valueGroupSet.currentValue.id as string | undefined;

        if (previousValueGroupSetId != null && previousValueGroupSetId !== currentValueGroupSetId) {
          this.initializeValueGroupSetForm();
        } else {
          this.valueGroupSetForm.updateValueGroupSet(this.valueGroupSet);
          this.setAvailability(this.valueGroupSetForm);
        }
      }
    } else {
      this.valueGroupSetForm && this.setAvailability(this.valueGroupSetForm);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeValueGroupSetForm(): void {
    this.valueGroupSetForm = new ValueGroupSetForm(this.valueGroupSet);
    // this.setAvailability needs to stay immediately after the creation of the form because it setups
    // the conditional triggers, and we need them for everything after.
    this.setAvailability(this.valueGroupSetForm);
    this.unsubscribe$.next();
    this.baseMetricEditorFormStateService.setDefaultFocusField(this.valueGroupSet);
    this.setStatusChangeSubscription(this.valueGroupSetForm);
    this.setupUpdateValueGroupSubscription(this.valueGroupSetForm);
  }

  private setAvailability(vgsForm: ValueGroupSetForm): void {
    if (this.disabled) {
      vgsForm.disable({ emitEvent: false });
    } else {
      vgsForm.enable({ emitEvent: false });
      vgsForm.applyConditionalTriggers();
      if (this.areConsolidationRulesEnabled) {
        vgsForm.applyConsolidationRules();
      }
    }
    this.displayedGroups = vgsForm.allDisplayedFormGroups();
  }

  private setupUpdateValueGroupSubscription(valueGroupSetForm: ValueGroupSetForm): void {
    this.updateValueGroupSubject
      .pipe(waitForNextUpdate(valueGroupSetForm), takeUntil(this.unsubscribe$))
      .subscribe((upsertValueGroup) => {
        this.handlePendingCreation();
        this.update.emit(this.valueGroupSetForm?.toUpsertValueGroupSet([upsertValueGroup]));
      });
  }

  private setStatusChangeSubscription(valueGroupSetForm: ValueGroupSetForm): void {
    valueGroupSetForm.statusChanges
      .pipe(startWith(valueGroupSetForm.status), delay(0), takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        this.isVgsetFormValid.emit(status !== 'INVALID');
      });
  }

  public onUpdateValueGroup(upsertValueGroup: UpsertValueGroup): void {
    this.updateValueGroupSubject.next(upsertValueGroup);
  }

  public onResetValue(event: ResetValueEventWithoutVgsetId): void {
    this.resetValue.emit({ ...event, valueGroupSetId: this.valueGroupSet.id });
  }

  public handleAddValueGroup(upsertValueGroups: UpsertValueGroup[]): void {
    this.update.emit(this.valueGroupSetForm?.toUpsertValueGroupSet(upsertValueGroups));
  }

  public handleMoveValueGroup(moveValueGroupEvent: MoveValueGroupEvent): void {
    this.moveGroup.emit(moveValueGroupEvent);
  }

  public handleDeleteValueGroup(valueGroupId: string): void {
    this.deleteGroup.emit(valueGroupId);
  }

  public formGroupTrackBy(index: number, formGroup: MetricEditorFormGroup): string {
    return `${formGroup.definitionId}, ${formGroup.position}, ${formGroup.subposition}, ${formGroup.id}`;
  }

  public setFocus(focusId: string): void {
    const metricFieldHandler = this.metricEditorGroupHandlerComponents
      .map((groupHandler) =>
        groupHandler.metricEditorFieldHandlerComponents.find((fieldHandler) => fieldHandler.focusId === focusId),
      )
      .find((fieldHandlers) => fieldHandlers);
    metricFieldHandler?.setFocus();
  }

  public get isValidOrDisabled(): boolean {
    return !!this.valueGroupSetForm?.isValidOrDisabled();
  }

  public triggerValidations(): void {
    this.valueGroupSetForm?.triggerValidations();
  }

  private handlePendingCreation(): void {
    if (this.valueGroupSetForm?.valueGroupSet.id == null) {
      this.valueGroupSetForm?.waitForNextUpdate();
    }
  }

  public goToTop(): void {
    this.groupsOffset = 0;
    const metricEditorFormElement = this.metricFormElementRef.nativeElement as HTMLElement;
    const closestScrollingElement = HtmlElementUtils.findClosestScrollingContainer(metricEditorFormElement);
    if (closestScrollingElement) {
      closestScrollingElement.scrollTop = 0;
    }
  }

  public goToEnd(): void {
    this.groupsOffset = this.displayedGroups.length - this.GROUPS_PAGE_INCREMENT;
  }

  private moveGroupsUp(): void {
    if (this.groupsOffset > 0) {
      if (this.groupsOffset >= this.GROUPS_PAGE_INCREMENT) {
        this.groupsOffset -= this.GROUPS_PAGE_INCREMENT;
      } else {
        this.groupsOffset = 0;
      }

      const metricEditorFormElement = this.metricFormElementRef.nativeElement as HTMLElement;
      const closestScrollingElement = HtmlElementUtils.findClosestScrollingContainer(metricEditorFormElement);
      if (closestScrollingElement) {
        closestScrollingElement.scrollTop = metricEditorFormElement.offsetTop + this.TOP_SPINNER_SIZE;
      }
    }
  }

  private moveGroupsDown(): void {
    this.groupsOffset +=
      this.groupsOffset + this.TOTAL_SHOWN_GROUPS < this.displayedGroups.length ? this.GROUPS_PAGE_INCREMENT : 0;
  }
}
