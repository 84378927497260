<div
  *ngIf="control"
  libFormField
  [size]="size"
  [required]="required"
  [control]="control"
  [labelPosition]="labelPosition"
  [dataTestId]="'dropdown-input'"
  [class]="'p-field'"
>
  <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>

  <p-dropdown
    [ariaLabelledBy]="_labelId"
    [options]="options"
    [formControl]="control"
    [id]="_labelId"
    [filter]="filter"
    [filterBy]="filterBy"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [optionDisabled]="optionDisabled"
    [group]="group"
    [optionGroupLabel]="optionGroupLabel"
    [optionGroupChildren]="optionGroupChildren"
    [autoDisplayFirst]="autoDisplayFirst"
    [showClear]="showClear"
    [tooltip]="tooltip"
    [required]="required"
    [appendTo]="'body'"
    tooltipPosition="bottom"
    [ngClass]="{ invalid: control.invalid && (control.dirty || control.touched), disabled: control.disabled }"
    [panelStyle]="{ width: inputWidth }"
    #focusElement
    #dropdownSelect
    libFocusState
  >
    <ng-template pTemplate="item" let-item>
      <span [pTooltip]="item.title" tooltipPosition="bottom">{{ item.title }}</span>
    </ng-template>
  </p-dropdown>

  <div class="subscript-wrapper">
    <ng-container *ngIf="(hint && control.valid) || control.untouched">
      <p class="message">
        {{ hint }}
      </p>
    </ng-container>

    <ng-container *ngIf="control.dirty || control.touched">
      <p *ngFor="let error of control.errors | errorKeys" class="p-error message">
        {{ errorMessages[error] }}
      </p>
    </ng-container>
  </div>
</div>
