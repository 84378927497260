/**
 * Define some regex patterns for matching boundaries.
 *
 * Ported by [xiaochao.k@gmail.com](https://github.com/nonoroazoro)
 */
export var NON_ALPHA_NUMERIC_REGEX = /[^a-zA-Z0-9]/;
export var WHITESPACE_REGEX = /\s/;
export var LINEBREAK_REGEX = /[\r\n]/;
export var BLANKLINE_END_REGEX = /\n\r?\n$/;
export var BLANKLINE_START_REGEX = /^\r?\n\r?\n/;
