<gridster [options]="gridsterConfig">
  <gridster-item *ngFor="let dashboardWidget of widgets" [item]="dashboardWidget | dashboardWidgetToGridsterItem">
    <ng-container [ngSwitch]="dashboardWidget.widget_type">
      <lib-chart-widget
        *ngSwitchCase="eWidgetType.CHART_WIDGET"
        [widget]="dashboardWidget"
        [metricDetailsUrl]="metricUrls.get(dashboardWidget.id) ?? ''"
      ></lib-chart-widget>
      <lib-single-data-point-widget
        *ngSwitchCase="eWidgetType.DATA_POINT_WIDGET"
        [widget]="dashboardWidget"
        [fiscalYearsItem]="fiscalYearItems"
        [metricDetailsUrl]="metricUrls.get(dashboardWidget.id) ?? ''"
      ></lib-single-data-point-widget>
      <lib-value-definition-target-widget
        *ngSwitchCase="eWidgetType.VALUE_DEFINITION_TARGET_WIDGET"
        [widget]="dashboardWidget"
        [metricDetailsUrl]="metricUrls.get(dashboardWidget.id) ?? ''"
      ></lib-value-definition-target-widget>
    </ng-container>
  </gridster-item>
</gridster>
