import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiResponse, ApplicationApiDefinition, Client } from '../../../models';
import { ApiService, BaseService } from '../../common';

@Injectable({
  providedIn: 'root',
})
export class AdminClientsService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.clients;
  }

  public listClients(): Observable<ApiResponse<Client[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/clients`);
  }

  public getClient(clientId: string): Observable<ApiResponse<Client>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/clients/${clientId}`);
  }

  public updateClient(clientId: string, payload: Partial<Client>): Observable<ApiResponse<Client>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/clients/${clientId}`, payload);
  }
}
