import { Component, ContentChild, Input, TemplateRef, SimpleChanges, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'lib-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent<T> {
  @Input() showMasterExpandControls: boolean = false;
  @Input() items: T[] = [];
  @Input() expandIndices?: number[];
  @Input() cardHeight: string = 'auto';

  @ContentChild('header', { static: false }) headerTemplateRef?: TemplateRef<unknown>;
  @ContentChild('content', { static: false }) contentTemplateRef?: TemplateRef<unknown>;
  @ViewChild('accordion') accordion?: MatAccordion;

  expandedPanelsCount: number = 0;
  expandExtraPanels: boolean = false;

  onExpandedChange(expanded: boolean): void {
    setTimeout(() => (expanded ? this.expandedPanelsCount++ : this.expandedPanelsCount--));
  }

  ngOnChanges(changes: SimpleChanges) {
    // Keep accordion expansion on items change
    if (
      this.expandedPanelsCount === changes.items.previousValue?.length &&
      changes.items.previousValue !== changes.items.currentValue
    ) {
      this.expandExtraPanels = true;
    }
  }

  ngAfterViewChecked() {
    if (this.expandExtraPanels) {
      // We use setTimeout to ensure the rendering is finished before to expand
      setTimeout(() => this.accordion?.openAll());
      this.expandExtraPanels = false;
    }
  }

  resetExpansionPanelCount() {
    this.expandedPanelsCount = 0;
  }
}
