import { Component, Input } from '@angular/core';
import { Indicator, LimitedUser, User, ValueGroupSet } from '../../../models';
import { ValueGroupSetStatusToText } from '../../../translations';

@Component({
  selector: 'lib-indicator-header-title',
  templateUrl: './indicator-header-title.component.html',
  styleUrls: ['./indicator-header-title.component.scss'],
})
export class IndicatorHeaderTitleComponent {
  @Input() indicator?: Indicator;
  @Input() indicatorCodeOverride?: string;
  @Input() indicatorDescriptionOverride?: string;
  @Input() important: boolean = false;
  @Input() valueGroupSet?: ValueGroupSet;
  @Input() users: (LimitedUser | User)[] = [];
  @Input() updating: boolean | null = false;
  @Input() updateFailed: boolean | null = false;
  @Input() prefilledFrequency?: string;
  @Input() displayUpdatedBy: boolean = true;
  @Input() displayIndicatorCode: boolean = true;

  readonly vgsStatusToText: { [key: string]: string } = ValueGroupSetStatusToText;
}
