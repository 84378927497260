<ng-container *ngIf="data$ | async as data">
  <ng-container *ngIf="isAdmin || settingsOptionListsEnabled; else legacyOptionList">
    <lib-info-banner
      *ngIf="legacySelection"
      class="mv-2"
      [message]="'Editing this list requires selecting from the lists in Option List Settings.' | translate"
      type="warning"
    ></lib-info-banner>

    <lib-select-input
      *ngIf="!isLoading"
      class="formfield mt-2"
      [label]="'Option list category' | translate"
      [control]="selectionListCategoryFormControl"
      [options]="data.optionListCategories"
    >
    </lib-select-input>

    <div *ngIf="selectionListCategoryFormControl.value || legacyCoreSelecitonWithoutCategory">
      <div class="fx-row align-center">
        <lib-select-input
          class="formfield option-lists-dropdown"
          [label]="'Option list' | translate"
          [control]="selectionListFormControl"
          [options]="data.optionLists"
        >
        </lib-select-input>

        <ng-container *libPermission="[ePermission.SELECTION_SETS_MANAGE, ePermission.OPTION_LISTS_MANAGE]">
          <button
            lib-button
            *ngIf="
              selectionListFormControl.value &&
              !legacySelection &&
              (!valueDefinition.type_details?.selection_set_source ||
                (!isAdmin && valueDefinition.type_details?.selection_set_source === eChoiceFieldSource.platform))
            "
            lib-button
            class="mh-2 option-lists-btn"
            [buttonType]="'primary'"
            (click)="redirectToOptionLists()"
          >
            {{ "See options" | translate }}
          </button>
        </ng-container>
      </div>

      <p *ngIf="hint" class="color-grey-800 caption2 m-0">{{ hint }}</p>
    </div>
  </ng-container>

  <ng-template #legacyOptionList>
    <div class="fx-row align-center">
      <lib-select-input
        class="formfield option-lists-dropdown"
        [label]="'Option list' | translate"
        [control]="selectionListFormControl"
        [options]="data.optionLists"
        [messages]="{ invalidChoices: 'Please add choices by clicking the edit button' | translate }"
        (selectionChangeEvent)="updateAnswers()"
      >
      </lib-select-input>

      <button
        *ngIf="canCreateCustomChoices"
        lib-button
        class="ml-2"
        [disabled]="selectionListFormControl.disabled"
        [buttonType]="'primary'"
        (click)="launchCustomSelectionDialog()"
      >
        {{ "Edit" | translate }}
      </button>
    </div>
  </ng-template>
</ng-container>
