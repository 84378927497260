import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-passkey-button',
  templateUrl: './passkey-button.component.html',
  styleUrls: ['./passkey-button.component.scss'],
})
export class PasskeyButtonComponent {
  @Input() isLoading: boolean | null = false;
}
