<div class="tip mb-4">
  <div class="fx-row align-center">
    <mat-icon
      data-testid="icon"
      [svgIcon]="iconConfig.icon"
      class="tip-icon color-{{ iconConfig.color }} mr-2"
    ></mat-icon>
  </div>

  <div class="text tip-text tip-text-{{ iconConfig.color }} mat-body color-grey-900 pl-3">
    <ng-content></ng-content>
  </div>
</div>
