import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApplicationApiDefinition,
  FieldRelatedFieldWithValue,
  FiscalYear,
  MetricTableDefinition,
  RelatedFieldType,
  Taxonomy,
} from '../../../models';
import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicPlatformService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.platform;
  }

  public listFiscalYears(searchArguments?: { [key: string]: any }): Observable<ApiResponse<FiscalYear[]>> {
    let params = new HttpParams();

    if (searchArguments?.businessUnitId) {
      params = params.append('business_unit_id', searchArguments.businessUnitId as string);
    }

    const fiscalYears$ = this.apiService
      .get(`${this.servicePath}${this.resource}/fiscal_years`, { params })
      .pipe(map((fiscalYear) => fiscalYear.data));

    const data$ = fiscalYears$.pipe(
      map((data) =>
        data.map((element: { id: string; year: string; start: string; end: string; business_unit_id: string }) => ({
          id: element.id,
          year: element.end.substring(0, 4),
          start: element.start,
          end: element.end,
          unit_id: element.business_unit_id,
        })),
      ),
    );
    return data$.pipe(map((x) => ({ meta: { count: x.length }, errors: [], data: x })));
  }

  public getFieldRelatedFieldsWithValues(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    business_unit_id: string,
    frequency_code: string,
    relatedFieldType?: RelatedFieldType,
  ): Observable<ApiResponse<FieldRelatedFieldWithValue[]>> {
    let params = new HttpParams();
    params = params.append('business_unit_id', business_unit_id);
    params = params.append('frequency_code', frequency_code);
    if (relatedFieldType) {
      params = params.append('relation_type', relatedFieldType);
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/field_relations_with_values`,
      { params },
    );
  }

  public getFieldTaxonomies(
    metricId: string,
    vdgId: string,
    vdId: string,
    completeFrameworks = false,
    frequency_code?: string,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>> {
    let params = new HttpParams().append('complete_frameworks', completeFrameworks);
    if (order_by) {
      params = params.append('order_by', order_by);
    }
    if (order_by_direction) {
      params = params.append('order_by_direction', order_by_direction);
    }
    if (frequency_code) {
      params = params.append('frequency_code', frequency_code);
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${vdgId}/value_definitions/${vdId}/taxonomies`,
      { params },
    );
  }

  public getMetricTableTaxonomies(
    metricId: string,
    metricTableDefinitionId: string,
    completeFrameworks = false,
    frequency_code?: string,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>> {
    let params = new HttpParams().append('complete_frameworks', completeFrameworks);
    if (order_by) {
      params = params.append('order_by', order_by);
    }
    if (order_by_direction) {
      params = params.append('order_by_direction', order_by_direction);
    }
    if (frequency_code) {
      params = params.append('frequency_code', frequency_code);
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/taxonomies`,
      { params },
    );
  }

  public getMetricTable(
    metricId: string,
    metricTableDefinitionId: string,
    checkValues = false,
  ): Observable<ApiResponse<MetricTableDefinition>> {
    const params = new HttpParams().append('checkValues', checkValues);

    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}`,
      { params },
    );
  }
}
