import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[libDeactivableMetricStructureEditor]',
})
export class DeactivableMetricStructureEditorDirective {
  @Input({ alias: 'libDeactivableMetricStructureEditor', required: true }) disabled!: boolean;

  @HostBinding('class') get disablingClass(): string {
    return this.disabled ? 'disabled-interactions' : '';
  }
}
