export class StringUtils {
  static stripTagsAndNewlines(str: string): string {
    if (!str || str === '') {
      return str;
    }

    return str
      .replace(/&nbsp;/g, ' ') // remove non breakable space
      .replace(/<\/?[^>]+(>|$)/g, '') // remove tags
      .replace(/\r?\n|\r/g, ' ') // remove newlines
      .replace(/\s+/g, ' '); // remove multiple spaces
  }

  static includesSubstring(baseString: string, substring: string): boolean {
    return baseString.toLowerCase().includes(substring.toLowerCase());
  }

  static htmlDecode(htmlString: string) {
    const textArea = document.createElement('textarea');
    const html = htmlString.replace(/\r/g, String.fromCharCode(0xe000));
    textArea.innerHTML = html;
    const result = textArea.value;

    return result.replace(new RegExp(String.fromCharCode(0xe000), 'g'), '\r');
  }
}
