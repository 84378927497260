import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { TableGroup, Value, ValueDefinitionType, ValueGroup } from '../../models';
import { ValueGroupFormGroup } from './valueGroupFormGroup';
import { MetricEditorFormGroup } from './metricEditorFormGroup';
import { ValueGroupService } from '../services/value-group.service';
import { ValueGroupSetForm } from './valueGroupSetForm';
import { ValueFormControl } from './valueFormControl';
export class TableFormGroup extends MetricEditorFormGroup {
  public tableGroupRef: TableGroup;

  get id(): string {
    return this.tableGroupRef.id;
  }

  get definitionId(): string {
    return this.tableGroupRef.table_id;
  }

  get position(): number {
    return this.tableGroupRef.position;
  }

  get subposition(): number {
    return 1;
  }

  override waitForNextUpdate() {
    super.waitForNextUpdate();
    this.groupFormGroups().forEach((group) => group.waitForNextUpdate());
  }

  constructor(
    initialTableGroup: TableGroup,
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
  ) {
    super(fb.group({}).controls);

    this.tableGroupRef = initialTableGroup;
    this.addValueGroupForms(this.tableGroupRef.valueGroups);
  }

  public businessUnitLevel(): number | undefined {
    const rootControl = this.root;

    if (this.isValueGroupSetForm(rootControl)) {
      return rootControl.businessUnitLevel();
    }
    return undefined;
  }

  public groupFormGroups(): ValueGroupFormGroup[] {
    return (Object.values(this.controls) as ValueGroupFormGroup[]).sort(
      TableFormGroup.sortValueGroupFormsByPositionAndSubPosition,
    );
  }

  public getMetricId(): string {
    return this.tableGroupRef.valueGroups.find((vg) => vg.metric_id)?.metric_id ?? '';
  }

  public getTableTotals(): Value[] {
    return this.groupFormGroups()
      .filter((vg) => vg.value)
      .flatMap((vg) =>
        (vg.valueGroupRef.values as Value[]).filter((val) => val.type === ValueDefinitionType.calculated),
      )
      .sort((a, b) => (a?.position && b?.position ? a.position - b.position : 0));
  }

  public getGroupFormGroups(): ValueGroupFormGroup[] {
    return this.groupFormGroups().filter((grp) => grp.valueGroupRef.values?.length);
  }

  public getGroupFormGroupsExceptTableTotals(): ValueGroupFormGroup[] {
    return this.groupFormGroups().filter(
      (grp) =>
        grp.valueGroupRef.values?.length &&
        !grp.valueGroupRef.values?.some((v) => v.type === ValueDefinitionType.calculated),
    );
  }
  public getGroupFormGroupTableTotals(): ValueGroupFormGroup {
    return this.groupFormGroups().filter(
      (grp) =>
        grp.valueGroupRef.values?.length &&
        grp.valueGroupRef.values.some((v) => v.type === ValueDefinitionType.calculated),
    )[0];
  }

  public updateGroup(tg: TableGroup): void {
    this.tableGroupRef = tg;
    this._isWaitingForNextUpdate$.next(false);
    this.tableGroupRef.valueGroups.forEach((updatedValueGroup) => {
      const existingValueGroup = this.groupFormGroups().find(
        (gfg) => gfg.valueGroupRef.value_definition_group_id === updatedValueGroup.value_definition_group_id,
      );
      if (existingValueGroup) {
        existingValueGroup.updateGroup(updatedValueGroup);
      } else {
        this.addValueGroupForm(updatedValueGroup);
      }
    });
  }

  public valueGroupFormControls(): ValueGroupFormGroup[] {
    return (Object.values(this.controls) as ValueGroupFormGroup[]).sort(
      TableFormGroup.sortValueGroupFormsByPositionAndSubPosition,
    );
  }

  public valueFormControls(): ValueFormControl[] {
    const valueFormControls = this.valueGroupFormControls().reduce<ValueFormControl[]>(
      (accumulator, valueGroupFormControl) => [
        ...accumulator,
        ...(Object.values(valueGroupFormControl.controls) as ValueFormControl[]),
      ],
      [],
    );
    return valueFormControls.sort(TableFormGroup.sortValueFormControlsByPosition);
  }

  private addValueGroupForms(valueGroups: ValueGroup[]): void {
    valueGroups.forEach((group) => this.addValueGroupForm(group));
  }

  private addValueGroupForm(valueGroup: ValueGroup): void {
    this.addControl(`group-${ValueGroupService.getUniqueIdentifier(valueGroup)}`, new ValueGroupFormGroup(valueGroup));
  }

  private isValueGroupSetForm(control: AbstractControl): control is ValueGroupSetForm {
    return 'valueGroupSet' in control;
  }

  private static sortValueGroupFormsByPositionAndSubPosition(a: ValueGroupFormGroup, b: ValueGroupFormGroup): number {
    return (
      a.valueGroupRef.position - b.valueGroupRef.position ||
      (a.valueGroupRef.subposition ?? 1) - (b.valueGroupRef.subposition ?? 1)
    );
  }

  private static sortValueFormControlsByPosition(a: ValueFormControl, b: ValueFormControl): number {
    return a.valueRef.position - b.valueRef.position;
  }
}
