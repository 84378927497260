import { Injectable } from '@angular/core';

import { AuthService } from '../../../services/common/auth/auth.service';
import { TranslateService } from '../../../services/common/translate/translate.service';
import { ActionItem, Dashboard, LimitedUser, Permission, User } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class DashboardsService {
  constructor(
    private readonly authService: AuthService,
    private readonly translateService: TranslateService,
  ) {}

  public isAuthorizedToUpdate(dashboard: Dashboard): boolean {
    if (this.authService.user?.permissions.includes(Permission.DASHBOARDS_ADMIN)) {
      return true;
    }

    return dashboard.created_by === this.authService.user?.user_id;
  }

  public updateCurrentUserNameOptions(users: ActionItem<LimitedUser>[]): ActionItem<LimitedUser>[] {
    const currentUser = this.authService.user;

    if (!currentUser) {
      return users;
    }

    const modifiedUsers: ActionItem<LimitedUser>[] = users.map((user) => {
      if (user.id === currentUser.user_id) {
        return {
          ...user,
          title: this.translateService.instant('Me'),
        };
      }
      return user;
    });

    return this.moveCurrentUserOptionAsFirst(modifiedUsers, currentUser);
  }

  private moveCurrentUserOptionAsFirst(
    userOptions: ActionItem<LimitedUser>[],
    currentUser: User,
  ): ActionItem<LimitedUser>[] {
    return userOptions.sort(function (x, y) {
      return x.id == currentUser.user_id ? -1 : y.id == currentUser.user_id ? 1 : 0;
    });
  }
}
