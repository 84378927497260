import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getItemById',
})
export class GetItemByIdPipe implements PipeTransform {
  transform<T>(items: T[], itemId: string): T | undefined {
    return items.find((item) => String(item['id' as keyof T]) === itemId);
  }
}
