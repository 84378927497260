<div *ngIf="curtainIsOpen$ | async" class="curtain" role="dialog" aria-label="curtain">
  <div class="curtain-content">
    <div class="logo-wrapper">
      <lib-spinner [strokeWidth]="5" class="spinner" [diameter]="85"></lib-spinner>
      <div class="logo">
        <img src="assets/img/logo-n-white.svg" alt="Novisto Logo" />
      </div>
    </div>
    <h3 *ngIf="curtainMessage$ | async as message" class="curtain-message">{{ message }}</h3>
  </div>
</div>
