enum SOURCE_CONFIGURATION {
  single_source = 'single_source',
  single_group = 'single_group',
  hierarchy = 'hierarchy',
  groups = 'groups',
}

const DEFAULT_SOURCE_CONFIGURATION = SOURCE_CONFIGURATION.single_group;

export { SOURCE_CONFIGURATION, DEFAULT_SOURCE_CONFIGURATION };
