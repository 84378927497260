import { Pipe, PipeTransform } from '@angular/core';
import { ActionItem, ChipType, Indicator, MinimalTaxonomyTree } from '../../../models';

@Pipe({
  name: 'getTaxonomyChipItems',
})
export class GetTaxonomyChipItemsPipe implements PipeTransform {
  transform(indicator: Indicator): ActionItem<MinimalTaxonomyTree>[] {
    return (
      indicator?.taxonomies?.lowest_visible_taxonomies?.map((taxonomy) => ({
        id: taxonomy.id,
        title: taxonomy.label,
        item: taxonomy,
        item_type: ChipType.stroked,
      })) ?? []
    );
  }
}
