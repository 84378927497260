import { Component, Input, OnChanges, QueryList, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActionItem } from '../../../models';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { TranslateService } from '../../../services/common';
import { DEFAULT_SELECT_ALL_VALUE } from '../../../classes';

let nextId = 0;

/**
 * Note: If "All" option is selected, for consistency between DOM and form values, the control will contain that value.
 * It's the caller responsibility to filter it before sending the data to BE.
 * That can be achieved by using the utility {@link FormUtils.removeSelectAllValue}
 */
@Component({
  selector: 'lib-listbox-input',
  templateUrl: './listbox-input.component.html',
  styleUrls: ['./listbox-input.component.scss'],
})
export class ListboxInputComponent implements OnChanges {
  @ViewChild('selectAllOption') selectAllOption?: MatListOption;

  @Input() label = '';
  @Input() control?: UntypedFormControl;
  @Input() options: ActionItem[] = [];
  @Input() withSelectAllOption: boolean = false;
  @Input() sourceConfiguration!: string;

  required: boolean = false;

  readonly _labelId = `select-input-${nextId++}`;
  readonly selectAllItem: ActionItem = {
    id: DEFAULT_SELECT_ALL_VALUE,
    title: this.translateService.instant('All'),
  };

  constructor(public translateService: TranslateService) {}

  ngOnChanges(): void {
    this.initializeInput();
    this.handleDefaultSelection();
  }

  public onOptionSelected(selectionList: MatSelectionList): void {
    if (this.selectAllOption) {
      if (selectionList.selectedOptions.selected.length === this.options.length) {
        this.selectAllOption.toggle();
      }
    }
  }

  public onSelectAll(selectionList: MatSelectionList): void {
    if (this.selectAllOption) {
      this.changeSelection(selectionList.options, this.selectAllOption.selected);
    }
  }

  private initializeInput(): void {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
  }

  private changeSelection(options: QueryList<MatListOption>, selected: boolean): void {
    options.forEach((option) => {
      if (!option.disabled) {
        option.selected = selected;
      }
    });
  }

  private handleDefaultSelection(): void {
    const hasDefaultValue =
      this.control &&
      this.withSelectAllOption &&
      this.control.value instanceof Array &&
      this.control.value.includes(this.selectAllItem.id);

    if (!hasDefaultValue) {
      return;
    }

    // select everything
    this.control?.setValue(this.options.concat(this.selectAllItem).map((option) => option.id));
  }
}
