import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiResponse, ApplicationApiDefinition, ListTaxonomiesRequest, Taxonomy } from '../../../models';
import { ApiService } from '../../common';

@Injectable({ providedIn: 'root' })
export class AdminTaxonomiesService {
  private readonly apiName: keyof ApplicationApiDefinition = 'admin';
  private resource: string;
  private servicePath: string;

  constructor(private readonly apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.taxonomies;
  }

  public getLeafTaxonomies(payload: ListTaxonomiesRequest): Observable<ApiResponse<Taxonomy[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/taxonomies/leaves`, payload);
  }

  public getClientSelectableTaxonomies(payload: ListTaxonomiesRequest): Observable<ApiResponse<Taxonomy[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/taxonomies/client_selectable`, payload);
  }
}
