<div class="label {{ labelClass }}" [ngClass]="{ red: isTriggered }">
  <mat-icon *ngIf="isTriggered" class="icon-md" svgIcon="condition-trigger"></mat-icon>
  <div class="fx-row">
    <span class="mr-1 status-chip" *ngIf="isInactive">{{ "Inactive" | translate }}</span>
    <span [ngClass]="{ inactive: isInactive }">
      <ng-content></ng-content>
    </span>
  </div>
  <mat-icon *ngIf="isTrigger" class="icon-md ml-1 color-error" svgIcon="condition"></mat-icon>
</div>
