<div
  *ngIf="control"
  libFormField
  [size]="size"
  [required]="required"
  [control]="control"
  [labelPosition]="labelPosition"
  [dataTestId]="'multi-select-dropdown'"
>
  <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>
  <p-multiSelect
    #multiselect
    [attr.aria-labelledby]="_labelId"
    [options]="options"
    [formControl]="control"
    [showToggleAll]="withSelectAllOption"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [optionDisabled]="optionDisabled"
    [readonly]="control.disabled"
    [appendTo]="'body'"
    [maxSelectedLabels]="maxSelectedLabels"
    [group]="group"
    [optionGroupLabel]="optionGroupLabel"
    [optionGroupChildren]="optionGroupChildren"
    [required]="required"
    [virtualScroll]="virtualScroll"
    [virtualScrollItemSize]="virtualScrollItemSize"
    (onChange)="handleSelectChange($event)"
    [panelStyle]="{ width: inputWidth }"
    [ngClass]="{ disabled: control.disabled }"
  >
    <ng-template pTemplate="item" let-item>
      <span [pTooltip]="item.title" tooltipPosition="bottom">{{ item.title }}</span>
    </ng-template>
  </p-multiSelect>
</div>
