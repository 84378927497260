import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ApiResponse,
  User,
  ActionItem,
  SearchOptions,
  ApplicationApiDefinition,
  CreatePublicUserPayload,
  ProfileInfo,
  UserInfo,
  CreateUser,
  UserEmailCheck,
  ListUsersSearchOptions,
  ListLimitedUsersSearchOptions,
  LimitedUser,
} from '../../../models';
import { ApiService } from '../../common';
import { CacheService } from '../../common';
import { AuthService } from '../../common';

@Injectable({
  providedIn: 'root',
})
export abstract class UsersManagementService {
  protected readonly apiName: keyof ApplicationApiDefinition = 'auth';
  protected readonly resource: string = this.apiService.apiConfig.apis.auth.resources.users;

  protected get servicePath(): string {
    return this.apiService.getServicePath(this.apiName);
  }
  protected constructor(
    protected apiService: ApiService,
    protected cacheService: CacheService,
    protected auth: AuthService,
  ) {}

  abstract payloadFromSearchOptions(searchOptions: SearchOptions): HttpParams;

  search(searchOptions: SearchOptions): Observable<ApiResponse<User[]>> {
    const params = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.get(`${this.servicePath}${this.resource}/users`, { params });
  }

  listUsers(searchArguments?: ListUsersSearchOptions): Observable<ApiResponse<User[]>> {
    return this.fetchUsers(searchArguments, false);
  }

  listLimitedUsers(searchArguments: ListLimitedUsersSearchOptions = {}): Observable<ApiResponse<LimitedUser[]>> {
    const cacheKey: string = `listLimitedUsers:${JSON.stringify(searchArguments)}`;
    const cacheValue = this.cacheService.get<ApiResponse<LimitedUser[]>>(cacheKey);

    if (cacheValue) {
      return of(cacheValue);
    }

    return this.fetchUsers(searchArguments).pipe(
      tap((res) => {
        this.cacheService.set(cacheKey, res, '1h');
      }),
    );
  }

  getUser(userId: string): Observable<ApiResponse<User>> {
    const users = <ActionItem<User>[]>this.cacheService.getCategory('user');
    const user = users.find((x) => x.id === userId)?.item;
    if (user) {
      return of({ data: user, errors: [], meta: {} });
    } else {
      return this.apiService.get(`${this.servicePath}${this.resource}/users/${userId}`);
    }
  }

  getUserByEmail(email: string): Observable<ApiResponse<User>> {
    const users = <ActionItem<User>[]>this.cacheService.getCategory('user');
    const user = users.find((x) => x.item?.email === email)?.item;
    if (user) {
      return of({ data: user, errors: [], meta: {} });
    } else {
      return this.apiService.get(`${this.servicePath}${this.resource}/users/emails/${email}`);
    }
  }

  getSelf(): Observable<ApiResponse<User>> {
    const self = <ActionItem<User>>this.cacheService.getCategory('self');
    if (self.item) {
      return of({ data: self.item, errors: [], meta: {} });
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/users/self`);
  }

  getCurrentUser(): Observable<ApiResponse<User> | null> {
    if (!this.auth.user) {
      return of(null);
    } else {
      return this.getSelf();
    }
  }

  getUserInfo(user: User): string {
    const role = user.roles[0];
    let info = role.charAt(0).toUpperCase() + role.slice(1);
    if (user.title) {
      info = `${info} - ${user.title}`;
    }
    if (user.department) {
      info = `${info} - ${user.department}`;
    }
    return info;
  }

  updateCurrentUser(profileInfo: ProfileInfo): Observable<ApiResponse<User>> {
    this.cacheService.clearCategory('user');
    return this.apiService.put(`${this.servicePath}${this.resource}/users/self`, profileInfo);
  }

  createUser(payload: CreateUser): Observable<ApiResponse<User>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users`, payload).pipe(
      tap(() => {
        this.cacheService.clearCategory('users_user');
      }),
    );
  }

  updateUser(userId: string, payload: UserInfo): Observable<ApiResponse<User>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/users/${userId}`, payload).pipe(
      tap((userResponse: ApiResponse<User>) => {
        this.cacheService.clearCategory('users_user');
        this.updateUserCache(userResponse.data);
      }),
    );
  }

  activateUser(userId: string): Observable<ApiResponse<User>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users/${userId}/activate`, {}).pipe(
      tap(() => {
        this.cacheService.clearCategory('users_user');
        this.cacheService.clearCategory('user'); // clear user resources cache
      }),
    );
  }

  deactivateUser(userId: string): Observable<ApiResponse<User>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users/${userId}/deactivate`, {}).pipe(
      tap(() => {
        this.cacheService.clearCategory('users_user');
        this.cacheService.clearCategory('user'); // clear user resources cache
      }),
    );
  }

  createPublicUser(payload: CreatePublicUserPayload): Observable<ApiResponse<User>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/public_users`, payload);
  }

  userEmailCheck(email: string): Observable<ApiResponse<UserEmailCheck>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users/email_check`, { email });
  }

  private fetchUsers<T extends LimitedUser>(
    searchArguments: ListLimitedUsersSearchOptions | ListUsersSearchOptions = {},
    limited = true,
  ): Observable<ApiResponse<T[]>> {
    let params = new HttpParams();

    params = searchArguments.active ? params.append('active', searchArguments.active) : params;
    params = searchArguments.order_by ? params.append('order_by', searchArguments.order_by) : params;
    params = searchArguments.type ? params.append('type', searchArguments.type) : params;
    searchArguments.user_ids?.forEach((userId: string) => {
      params = params.append('user_ids', userId);
    });

    return this.apiService.get(`${this.servicePath}${this.resource}/users${limited ? '/limited' : ''}`, { params });
  }

  private updateUserCache(user: User): void {
    const users = <ActionItem<User>[]>this.cacheService.getCategory('user');
    const userIndex = users.findIndex((u) => u.id === user.id);
    users[userIndex] = { id: user.id, title: `${user.first_name} ${user.last_name}`, item: user };
    this.cacheService.set('user', users);
  }

  disableUser2FA(userId: string): Observable<ApiResponse> {
    return this.apiService.post(`${this.servicePath}${this.resource}/users/${userId}/2fa/disable`);
  }
}
