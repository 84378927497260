import { Component, Input, OnInit } from '@angular/core';

import { ActionItem, Permission } from '../../models';

@Component({
  selector: 'lib-section-primary',
  templateUrl: './section-primary.component.html',
  styleUrls: ['./section-primary.component.scss'],
})
export class SectionPrimaryComponent implements OnInit {
  @Input() title: string = '';
  @Input() link: string = '';
  @Input() linkText: string = '';
  @Input() items: ActionItem[] = [];

  allPermissions: Permission[] = [];

  ngOnInit(): void {
    this.allPermissions = this.items.reduce((permissions: Permission[], item: ActionItem) => {
      if (item.permissions) {
        return [...permissions, ...item.permissions];
      }
      return permissions;
    }, []);
  }
}
