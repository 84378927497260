<div class="participant card-container">
  <div>
    <lib-user-badge [fullName]="user | fullName" size="large"></lib-user-badge>
    <div class="participant-info">
      <b class="user-name">{{ user | fullName }}</b>

      <div class="email-section">
        <div class="secondary-user-info" libEllipsifyMe [maxCharCount]="30">{{ user.email }}</div>
        <button
          lib-button
          [matTooltip]="'Copy email' | translate"
          buttonType="link"
          aria-label="Copy email button"
          (click)="copyEmail(user.email)"
        >
          <mat-icon svgIcon="copy" class="icon-sm"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Temporary removed in https://novisto.atlassian.net/browse/NF-11130
<ng-template #userTooltip>
  <div class="user-tooltip-container" data-testid="user-info">
    <lib-user-badge [fullName]="user | fullName" size="very-large"></lib-user-badge>

    <div class="user-info">
      <b class="user-name">{{ user | fullName }}</b>
      <div class="fx-row">
        <div class="secondary-user-info" libEllipsifyMe>{{ jobPosition }}</div>
      </div>
      <div class="email-section">
        <div class="secondary-user-info" libEllipsifyMe [maxCharCount]="21">{{ user.email }}</div>
        <button
          lib-button
          [matTooltip]="'Copy email' | translate"
          buttonType="link"
          aria-label="Copy email button"
          (click)="copyEmail(user.email)"
        >
          <mat-icon svgIcon="copy" class="icon-sm"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template> -->
