import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[libTitleOnEllipsis]',
})
export class TitleOnEllipsisDirective {
  @Input() tolerance: number = 0;

  constructor(private elementRef: ElementRef) {}

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    setTimeout(() => {
      const element: HTMLElement = this.elementRef.nativeElement as HTMLElement;

      if (
        element.textContent &&
        (element.offsetHeight < element.scrollHeight - this.tolerance ||
          element.offsetWidth < element.scrollWidth - this.tolerance)
      ) {
        element.title = element.textContent;
      } else if (element.title) {
        element.removeAttribute('title');
      }
    });
  }
}
