import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SearchOptions, StandardCodes } from '../models';
import { CoreApiService } from '../services/core-api-service/core-api.service';

const DEFAULT_PAGE_SIZE = 25;
export interface SearchStandardCodesPayload {
  from: number;
  size: number;
  keywords?: string;
  order_by_direction?: 'asc' | 'desc';
  load_topics?: boolean;
  load_industries?: boolean;
  order_by?: 'code' | 'framework_id' | 'active' | 'start' | 'end' | 'updated';
  filters?: Record<string, unknown>;
}
@Injectable()
export class StandardCodesService {
  constructor(private readonly coreApiService: CoreApiService) {}

  public search(searchOptions: SearchOptions): Observable<StandardCodes[]> {
    return this.coreApiService
      .searchStandardCodes(this.getSearchStandardCodesPayload(searchOptions))
      .pipe(map((response) => response.data));
  }

  private getSearchStandardCodesPayload(searchOptions: SearchOptions): SearchStandardCodesPayload {
    const payload: SearchStandardCodesPayload = {
      from: searchOptions.from || 0,
      size: searchOptions.size || DEFAULT_PAGE_SIZE,
    };

    if (searchOptions.query.keywords) {
      payload.keywords = searchOptions.query.keywords;
    }

    return payload;
  }
}
