<lib-dialog-title [title]="title" (closeEvent)="close()" [error]="error"></lib-dialog-title>

<div mat-dialog-content class="pb-1">
  <div class="warning-message-container">
    <div class="warning-icon-container">
      <mat-icon class="warning-icon" svgIcon="warning"></mat-icon>
    </div>
    <div class="warning-message" *ngIf="body" [innerHTML]="body | sanitizeHtml"></div>
  </div>

  <div *ngIf="error" [innerHTML]="error | sanitizeHtml"></div>
</div>

<div mat-dialog-actions>
  <button lib-button buttonType="success" class="btn ml-3" (click)="confirm()">
    {{ button }}
  </button>

  <button lib-button buttonType="cancel_with_border" class="btn ml-3" (click)="close()">
    {{ "Cancel" | translate }}
  </button>
</div>
