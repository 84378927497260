import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActionItem, Taxonomy } from '../../../models';
import { FrameworkTaxonomyCategoryTranslations } from '../../../translations';
import { TaxonomyFilterByCategory } from '../../classes/TaxonomyFilterByCategory/TaxonomyFilterByCategory';

@Component({
  selector: 'lib-search-bar-taxonomy-menu',
  templateUrl: './search-bar-taxonomy-menu.component.html',
  styleUrl: './search-bar-taxonomy-menu.component.scss',
})
export class SearchBarTaxonomyMenuComponent {
  @Input() control?: UntypedFormControl;
  @Input() filteredItems?: Observable<ActionItem<Taxonomy>[]>;
  @Input() items: ActionItem<Taxonomy>[] = [];
  @Input() minMenuScrollItems: number = 10;
  @Input() selectedItemId?: string;

  @Output() setItem = new EventEmitter<ActionItem<Taxonomy> | undefined>();

  public readonly frameworkTaxonomyCategoryTranslations = FrameworkTaxonomyCategoryTranslations;
  filterTaxonomies = TaxonomyFilterByCategory.filterTaxonomies;
}
