import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { ValueDefinitionSize } from '../../../models';
import { ValidationMessageService } from '../../../services/common';

let nextId = 0;

@Component({
  selector: 'lib-cascade-select',
  templateUrl: './cascade-select.component.html',
  styleUrls: ['./cascade-select.component.scss'],
})
export class CascadeSelectComponent implements OnChanges {
  @Input({ required: true }) control!: UntypedFormControl;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() hint: string = '';
  @Input() options: any[] = [];
  @Input() label: string = '';
  @Input() showClear: boolean = true;
  @Input() messages: ValidationErrors = {};
  @Input() labelPosition: 'top' | 'left' = 'top';
  @Input({ required: true }) groupLabel!: string;
  @Input({ required: true }) optionLabel!: string;
  @Input() optionGroupChildren: string[] = [];

  readonly _labelId: string = `cascade-select-input-${nextId++}`;

  public required: boolean = false;
  public errorMessages: ValidationErrors = {};

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnChanges(): void {
    this.initializeInput();
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
    if (!this.optionGroupChildren.length) {
      this.optionGroupChildren = this.extractArrayKeysOrderedByDepth(this.options);
    }
  }

  private extractArrayKeysOrderedByDepth(
    options: any[],
    depth: number = 1,
    depthKeyMap: Map<number, string> = new Map(),
  ): string[] {
    options.forEach((option) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(option).forEach((key: string) => {
        const value = option[key];
        if (Array.isArray(value)) {
          // Ensure only one key exists per depth and it must be the same key
          if (depthKeyMap.has(depth)) {
            if (depthKeyMap.get(depth) !== key) {
              throw new Error(
                `Inconsistent key detected at depth ${depth}. Expected key "${depthKeyMap.get(depth)}", but found key "${key}". Ensure that only one unique key exists per depth level.`,
              );
            }
          } else {
            depthKeyMap.set(depth, key);
          }
          // Search within the array if it's an array of CascadeGroup
          if (value.length > 0 && typeof value[0] === 'object') {
            this.extractArrayKeysOrderedByDepth(value, depth + 1, depthKeyMap);
          }
        }
      });
    });

    return Array.from(depthKeyMap.keys())
      .sort((a, b) => a - b)
      .map((depth) => depthKeyMap.get(depth)!);
  }

  public setToTouched() {
    this.control?.markAsTouched();
  }
}
