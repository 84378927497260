import { Observable } from 'rxjs';
import { ApiResponse, ListFavoriteOrgsRequest, Org } from '../../../models';
import { HttpParams } from '@angular/common/http';

export abstract class BaseClientPeersService {
  abstract listFavoriteOrgs(listFavoriteOrgsRequest?: ListFavoriteOrgsRequest): Observable<ApiResponse<Org[]>>;

  getlistFavoriteOrgsHttpParams(listFavoriteOrgsRequest?: ListFavoriteOrgsRequest): HttpParams {
    let params = new HttpParams();

    if (listFavoriteOrgsRequest?.filters?.industries?.[0]) {
      params = params.append('industry_ids', listFavoriteOrgsRequest.filters.industries[0]);
    }
    if (listFavoriteOrgsRequest?.search_term) {
      params = params.append('search_term', listFavoriteOrgsRequest.search_term);
    }
    if (listFavoriteOrgsRequest?.page) {
      params = params.append('page', listFavoriteOrgsRequest.page);
    }
    if (listFavoriteOrgsRequest?.page_size) {
      params = params.append('page_size', listFavoriteOrgsRequest.page_size);
    }
    if (listFavoriteOrgsRequest?.order_by) {
      params = params.append('order_by', listFavoriteOrgsRequest.order_by);
    }
    if (listFavoriteOrgsRequest?.order_by_direction) {
      params = params.append('order_by_direction', listFavoriteOrgsRequest.order_by_direction);
    }

    return params;
  }
}
