import { Type } from '@angular/core';

export interface DataTableHeaderConfig {
  items: { colspan: number; key: string; name: string }[];
}

export interface DataTablePaginatorConfiguration {
  currentPage: number;
  pageSize: number;
  total: number;
}

export interface DataTableHorizontalPaginatorConfiguration {
  currentIndex: number;
  pageSize: number;
  total: number;
}

export interface DataTableExpandedRowConfig {
  component: Type<unknown>;
  componentProperties: DataTableExpandedRowProperty[];
}

export interface DataTableExpandedRowProperty {
  inputName: string;
  rowPropertyName?: string;
  value?: unknown;
}

export const DEFAULT_PAGE_SIZE: number = 25;
export const DEFAULT_HORIZONTAL_PAGE_SIZE: number = 2;
export const PAGINATION_OPTIONS: number[] = [10, DEFAULT_PAGE_SIZE, 50];
