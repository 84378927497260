import { Component, Input } from '@angular/core';
import { TagType } from '../../../models';

@Component({
  selector: 'lib-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() value: string = '';
  @Input() tagType: TagType = TagType.INFO;
}
