import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { StandardCodeCategory } from '../../models';
import { SortingTranslation } from '../sorting.translation';

export { StandardCodeCategoryTranslations, StandardCodesSort };

const StandardCodesSort = {
  code: SortingTranslation.alphabetical,
  updated: SortingTranslation.last_updated,
};

const StandardCodeCategoryTranslations: Record<StandardCodeCategory, string> = {
  [StandardCodeCategory.RATING]: _('Ratings'),
  [StandardCodeCategory.REGULATION]: _('Regulations'),
  [StandardCodeCategory.REPORTING_FRAMEWORK]: _('Reporting Frameworks'),
  [StandardCodeCategory.REPORTING_STANDARD]: _('Reporting Standards'),
};
