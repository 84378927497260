import { Injectable } from '@angular/core';

import { ValueFormControl } from '../../models/valueFormControl';

export interface DisplayAdditionalText {
  required: boolean;
  show: boolean;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class MetricEditorBooleanService {
  public getAdditionalTextDisplayInfo(
    valueFormControl?: ValueFormControl,
    booleanValue?: string,
  ): DisplayAdditionalText {
    const promptOnTrue = Boolean(valueFormControl?.valueRef.type_details.prompt_on_true);
    const promptOnFalse = Boolean(valueFormControl?.valueRef.type_details.prompt_on_false);
    const requiredOnTrue = Boolean(valueFormControl?.valueRef.type_details.on_true_text_required);
    const requiredOnFalse = Boolean(valueFormControl?.valueRef.type_details.on_false_text_required);

    booleanValue = booleanValue ?? String(valueFormControl?.value?.value);
    if (promptOnTrue && booleanValue == 'true') {
      const label: string = valueFormControl?.valueRef.type_details.label_prompt_true;
      return { required: requiredOnTrue, show: true, label };
    } else if (promptOnFalse && booleanValue == 'false') {
      const label = valueFormControl?.valueRef.type_details.label_prompt_false;
      return { required: requiredOnFalse, show: true, label };
    }
    return { required: false, show: false, label: '' };
  }
}
