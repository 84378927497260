import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  Indicator,
  MetricTableColumnDefinition,
  MetricTableDefinition,
  TableColumn,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../../models';
import { TranslateService } from '../../services/common';

interface TableContextRow {
  contextColumnOption: string;
  position: number;
}

interface TableInputRow {
  inputColumnOption: string;
  position: number;
}

@Component({
  selector: 'lib-select-metric-table-cell-value-definition',
  templateUrl: './select-metric-table-cell-value-definition.component.html',
  styleUrls: ['./select-metric-table-cell-value-definition.component.scss'],
})
export class SelectMetricTableCellValueDefinitionComponent implements OnChanges {
  @Input({ required: true }) metricTableDefinition!: MetricTableDefinition;
  @Input({ required: true }) metric!: Indicator;

  @Output() metricTableValueDefinitionSelected = new EventEmitter<ValueDefinition>();
  @Output() cancel = new EventEmitter<void>();

  public contextRows: TableContextRow[] = [];
  public inputRows: TableInputRow[] = [];
  public contextColumnOption: TableContextRow | undefined = undefined;
  public tableContextColumns = '';
  public contextColumns: TableColumn<string>[] = [
    {
      name: this.translateService.instant('Select a table context column option'),
      dataKey: 'contextColumnOption',
    },
  ];
  public inputColumns: TableColumn<string>[] = [
    {
      name: this.translateService.instant('Select a table input column'),
      dataKey: 'inputColumnOption',
    },
  ];

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {
    this.contextColumnOption = undefined;
    this.tableContextColumns = this.metricTableDefinition.column_definitions
      .filter((columnDefinition: MetricTableColumnDefinition) => columnDefinition.type === ValueDefinitionType.label)
      .map((columnDefinition: MetricTableColumnDefinition) => columnDefinition.label)
      .join(', ');

    // resetting previously chosen value definition
    this.metricTableValueDefinitionSelected.emit(undefined);
    this.createContextRows();
    this.createInputRows();
  }

  public backToFields(): void {
    this.contextColumnOption = undefined;
    this.cancel.emit();
  }

  public handleSelectContextRow(event: TableContextRow): void {
    this.contextColumnOption = event;
  }

  public backToContextColumns(): void {
    this.contextColumnOption = undefined;
    this.createInputRows(); // this is to reset the selected row.
    this.metricTableValueDefinitionSelected.emit(undefined);
  }

  public handleSelectInputRow(event: TableInputRow): void {
    if (this.contextColumnOption && this.metric.value_definition_groups) {
      const valueDefinitionGroups = this.metric.value_definition_groups.filter(
        (vdf: ValueDefinitionGroup) => vdf.table_id == this.metricTableDefinition.id,
      );
      const valueDefinitionGroup: ValueDefinitionGroup = valueDefinitionGroups[this.contextColumnOption.position - 1];
      if (valueDefinitionGroup.value_definitions) {
        const valueDefinition: ValueDefinition = valueDefinitionGroup.value_definitions[event.position - 1];
        valueDefinition.value_definition_group = valueDefinitionGroup;
        this.metricTableValueDefinitionSelected.emit(valueDefinition);
      }
    }
  }

  private generateCombinations(
    index: number,
    currentCombination: string[],
    combinations: string[],
    arrays: string[][],
  ): void {
    if (index === arrays.length) {
      combinations.push(currentCombination.join(', '));
      return;
    }

    for (let i = 0; i < arrays[index].length; i++) {
      this.generateCombinations(index + 1, [...currentCombination, arrays[index][i]], combinations, arrays);
    }
  }

  private createInputRows(): void {
    this.inputRows = this.metricTableDefinition.column_definitions
      .filter((columnDefinition: MetricTableColumnDefinition) => columnDefinition.type != ValueDefinitionType.label)
      .map((columnDefinition: MetricTableColumnDefinition) => ({
        inputColumnOption: columnDefinition.label,
        position: columnDefinition.position,
      }));
  }

  private createContextRows(): void {
    // making an array of string arrays and filter out the empty results
    const arrays: string[][] = this.metricTableDefinition.column_definitions
      .filter((columnDefinition: MetricTableColumnDefinition) => columnDefinition.type == ValueDefinitionType.label)
      .map((columnDefinition: MetricTableColumnDefinition) => columnDefinition.options);

    const combinations: string[] = [];

    this.generateCombinations(0, [], combinations, arrays);

    this.contextRows = combinations.map((combination: string, index: number) => ({
      contextColumnOption: combination,
      position: index + 1,
    }));
  }
}
