import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, DataRequestIndicator } from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { Observable } from 'rxjs';
import { UpsertValueGroupSet } from '../../../metric-editor-form';
import { HttpContext, HttpParams } from '@angular/common/http';
import { BaseClientDataRequestIndicatorsService } from './base-client-data-request-indicator';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors';

@Injectable({
  providedIn: 'root',
})
export class ClientDataRequestIndicatorsService extends BaseClientDataRequestIndicatorsService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    super();

    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dataRequestIndicators;
  }

  public getDataRequestIndicator(
    dataRequestIndicatorId: string,
    dataRequestId?: string,
    businessUnitId?: string,
  ): Observable<ApiResponse<DataRequestIndicator>> {
    let params = new HttpParams();

    if (dataRequestId) {
      params = params.append('data_request_ids', dataRequestId);
    }
    if (businessUnitId) {
      params = params.append('business_unit_ids', businessUnitId);
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_request_indicators/${dataRequestIndicatorId}`,
      { params },
    );
  }

  public getDataRequestIndicators(
    dataRequestId?: string,
    businessUnitId?: string,
  ): Observable<ApiResponse<DataRequestIndicator[]>> {
    let params = new HttpParams();

    if (dataRequestId) {
      params = params.append('data_request_ids', dataRequestId);
    }
    if (businessUnitId) {
      params = params.append('business_unit_ids', businessUnitId);
    }

    return this.apiService.get(`${this.servicePath}${this.resource}/data_request_indicators/`, { params });
  }

  public upsertDataRequestIndicatorValues(
    dataRequestIndicatorId: string,
    upsertValueGroupSet: UpsertValueGroupSet,
  ): Observable<ApiResponse<DataRequestIndicator>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/data_request_indicators/${dataRequestIndicatorId}/values`,
      upsertValueGroupSet,
      undefined,
      undefined,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public moveDataRequestIndicatorValueGroup(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string,
    position: number,
  ): Observable<ApiResponse<DataRequestIndicator>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_request_indicators/${indicatorId}/value_group_sets/${valueGroupSetId}/value_groups/${valueGroupId}/move/${position}`,
      {},
    );
  }

  public deleteDataRequestIndicatorValueGroup(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string,
  ): Observable<ApiResponse<DataRequestIndicator>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/data_request_indicators/${indicatorId}/value_group_sets/${valueGroupSetId}/value_groups/${valueGroupId}`,
    );
  }

  public deleteDataRequestIndicatorValue(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string,
    valueId: string,
  ): Observable<ApiResponse<DataRequestIndicator>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/data_request_indicators/${indicatorId}/value_group_sets/${valueGroupSetId}/value_groups/${valueGroupId}/values/${valueId}`,
    );
  }

  public getCollectIndicatorInDataRequest(
    dataRequestId: string,
    dataRequestSourceId: string,
    indicatorId: string,
    frequencyCode: string,
  ): Observable<ApiResponse<DataRequestIndicator>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/indicators/${indicatorId}/collect/frequencies/${frequencyCode}`,
    );
  }
}
