import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from '../components';
import { DirectivesModule } from '../directives';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes';
import { SearchModule } from '../search';
import { TranslateModule } from '@ngx-translate/core';

import { DataListRemoteDataComponent } from './data-list-remote-data/data-list-remote-data.component';

@NgModule({
  declarations: [DataListRemoteDataComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ComponentsModule,
    TranslateModule,
    RouterModule,
    SearchModule,
    DirectivesModule,
    PipesModule,
  ],
  exports: [DataListRemoteDataComponent],
})
export class DataListModule {}
