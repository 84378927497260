import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TemplateReportSectionMetricForm } from './forms/template-report-section-metric-form';
import {
  ActionItem,
  ApiResponse,
  Permission,
  ReportCategoryType,
  SOURCE_CONFIGURATION,
  SectionMetricDisplay,
  TemplateReport,
  TemplateReportSection,
  TemplateReportSectionMetric,
  UpdateTemplateReportSectionMetricPayload,
} from '../../../models';
import { TemplateReportsApiService } from '../../../services/api-services';
import { TemplateReportStructureStateService } from '../../services/template-report-structure-state.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { isEqual } from 'lodash';

@Component({
  selector: 'lib-template-report-panel-section-metric-form',
  templateUrl: './template-report-panel-section-metric-form.component.html',
  styleUrls: ['./template-report-panel-section-metric-form.component.scss'],
})
export class TemplateReportPanelSectionMetricFormComponent implements OnChanges {
  @Input() managePermissions: Permission[] = [];
  @Input() templateReportId?: string;
  @Input() templateReportVersionId?: string;
  @Input() templateReportSectionMetric?: TemplateReportSectionMetric;
  @Input() editingMetric: boolean = false;
  @Input() sectionsLabelById: Map<string, TemplateReportSection> = new Map();
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  public isLoading: boolean = false;
  public sectionMetricForm?: TemplateReportSectionMetricForm;
  public sectionOptions: ActionItem[] = [];
  public template$: Observable<TemplateReport | undefined> | undefined;
  readonly eReportCategoryType: typeof ReportCategoryType = ReportCategoryType;

  constructor(
    private readonly templateReportsApiService: TemplateReportsApiService,
    private readonly templateReportStructureStateService: TemplateReportStructureStateService,
  ) {
    this.template$ = this.templateReportStructureStateService.templateReport$;
  }

  public ngOnChanges(): void {
    this.sectionMetricForm = new TemplateReportSectionMetricForm(
      this.templateReportSectionMetric?.metric,
      this.getSectionMetricDisplay(
        this.templateReportSectionMetric?.section,
        this.templateReportSectionMetric?.metric.id,
      ),
      this.templateReportSectionMetric?.section.id,
    );

    this.sectionOptions = Array.from(this.sectionsLabelById.values()).map<ActionItem<TemplateReportSection>>(
      (section) => ({ id: section.id, title: section.label, item: section }),
    );
  }

  private getSectionMetricDisplay(
    section?: TemplateReportSection,
    metricId?: string,
  ): SectionMetricDisplay | undefined {
    return section?.display?.find((display) =>
      display.display_metrics?.some((metric) => metric.metric_id === metricId),
    );
  }

  public saveMetric(): void {
    if (!this.sectionMetricForm || !this.templateReportSectionMetric?.section || !this.templateReportId) {
      return;
    }

    const templateReportId = String(this.templateReportId);
    const templateReportSectionMetric: TemplateReportSectionMetric = this.templateReportSectionMetric;
    const sectionMetricPayload = this.sectionMetricForm.toModel();

    if (this.editingMetric) {
      if (sectionMetricPayload.sectionId !== templateReportSectionMetric.section.id) {
        this.removeMetric(
          templateReportId,
          templateReportSectionMetric.section.id,
          templateReportSectionMetric.metric.id,
        )
          .pipe(
            switchMap(() =>
              this.addMetric(
                templateReportId,
                sectionMetricPayload.sectionId,
                templateReportSectionMetric.metric.id,
                sectionMetricPayload.placeholder_code,
                sectionMetricPayload.placeholder_description,
              ),
            ),
          )
          .subscribe({
            next: () => {
              if (this.templateReportSectionMetric) {
                this.completeSectionMetricModification(
                  this.templateReportSectionMetric,
                  sectionMetricPayload.sectionId,
                  templateReportSectionMetric.metric.id,
                  templateReportSectionMetric.section.id,
                );
              }
            },
          });
      } else {
        this.updateSectionMetric(
          templateReportId,
          sectionMetricPayload.sectionId,
          templateReportSectionMetric.metric.id,
          sectionMetricPayload.placeholder_code,
          sectionMetricPayload.placeholder_description,
        ).subscribe({
          next: (res) => {
            this.editSection(sectionMetricPayload.sectionId, res.data);
            this.closePanel.emit();
          },
        });
      }
    } else {
      this.addMetric(
        templateReportId,
        sectionMetricPayload.sectionId,
        templateReportSectionMetric.metric.id,
        sectionMetricPayload.placeholder_code,
        sectionMetricPayload.placeholder_description,
      ).subscribe({
        next: (res) => {
          if (this.templateReportSectionMetric) {
            this.completeSectionMetricModification(
              this.templateReportSectionMetric,
              sectionMetricPayload.sectionId,
              templateReportSectionMetric.metric.id,
              templateReportSectionMetric.section.id,
            );
          }
          this.editSection(sectionMetricPayload.sectionId, res.data);
        },
      });
    }
  }

  private editSection(sectionId: string, sections: TemplateReportSection[]): void {
    const section = sections.find((s) => s.id === sectionId);
    if (section) {
      this.templateReportStructureStateService.editSection(section);
    }
  }

  private removeMetric(
    templateReportId: string,
    sectionId: string,
    metricId: string,
  ): Observable<ApiResponse<TemplateReportSection[]>> {
    return this.templateReportsApiService.removeMetricFromTemplateReportSection(
      templateReportId,
      sectionId,
      metricId,
      this.templateReportVersionId,
    );
  }

  private addMetric(
    templateReportId: string,
    sectionId: string,
    metricId: string,
    placeholderCode?: string | null,
    placeholderDescription?: string | null,
  ): Observable<ApiResponse<TemplateReportSection[]>> {
    return this.templateReportsApiService.addMetricToTemplateReportSection(
      templateReportId,
      sectionId,
      metricId,
      placeholderCode,
      placeholderDescription,
      this.templateReportVersionId,
    );
  }

  private updateSectionMetric(
    templateReportId: string,
    sectionId: string,
    metricId: string,
    placeholderCode?: string | null,
    placeholderDescription?: string | null,
  ): Observable<ApiResponse<TemplateReportSection[]>> {
    const updateSectionMetricPayload: UpdateTemplateReportSectionMetricPayload = {
      placeholder_code: placeholderCode,
      placeholder_description: placeholderDescription,
    };
    return this.templateReportsApiService.updateMetricToTemplateReportSection(
      templateReportId,
      sectionId,
      metricId,
      updateSectionMetricPayload,
      this.templateReportVersionId,
    );
  }

  private completeSectionMetricModification(
    newMetric: TemplateReportSectionMetric,
    newSectionId: string,
    metricId: string,
    oldSectionId?: string,
  ) {
    this.templateReportStructureStateService.addMetricOnSection(newMetric, newSectionId, metricId, oldSectionId);
    this.closePanel.emit();
  }

  public hasFormDataChanged(): boolean {
    if (this.editingMetric) {
      const cMetricDisplay = this.getSectionMetricDisplay(
        this.templateReportSectionMetric?.section,
        this.templateReportSectionMetric?.metric.id,
      );
      const cMetricDisplayModel = {
        sectionId: String(this.templateReportSectionMetric?.section.id),
        placeholder_code: cMetricDisplay?.code || null,
        placeholder_description: cMetricDisplay?.description || null,
      };
      const currentFormValue = this.sectionMetricForm?.toModel();

      return !isEqual(cMetricDisplayModel, currentFormValue);
    }

    return true;
  }
}
