import { MetricTableGroup } from '../../metric-structure/models';
import { ValueDefinition, ValueDefinitionGroup } from '../../models';

export class DeactivationUtils {
  static isDeactivated(element?: ValueDefinition | ValueDefinitionGroup | MetricTableGroup): boolean {
    if (element && typeof element.active == 'boolean') {
      return !element.active;
    }
    return false;
  }
}
