import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '../translate/translate.service';
import { LocalStorageUtils } from '../../../classes/StorageUtils/local-storage-utils';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.user) {
      // We store the redirectURL in localstorage for SSO purposes,
      // when logging in with SSO. We lose the Router State and don't have access to the url that the user came from
      LocalStorageUtils.addToStorage('redirectURL', state.url);
      this.authService.logout(this.translateService.instant('Unauthorized request'), state.url);
      return false;
    }
    return true;
  }
}
