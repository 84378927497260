import { Pipe, PipeTransform } from '@angular/core';
import { MetricEditorFormGroup } from '../models/metricEditorFormGroup';
import { ValueGroupFormGroup } from '../models/valueGroupFormGroup';

@Pipe({
  name: 'isValueGroupFormGroup',
})
export class IsValueGroupFormGroupPipe implements PipeTransform {
  transform(formGroup: MetricEditorFormGroup): formGroup is ValueGroupFormGroup {
    return formGroup instanceof ValueGroupFormGroup;
  }
}
