import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Value } from '../../../models';
import { MetricViewFileDocumentInterface } from '../metric-view.component';
import { ValueDefinitionConstants } from '../../../constants/value-definition-constants';

@Component({
  selector: 'lib-metric-view-file-v2',
  templateUrl: './metric-view-file-v2.component.html',
  styleUrls: ['./metric-view-file-v2.component.scss'],
})
export class MetricViewFileV2Component {
  public readonly DEFAULT_EXPLANATION_LABEL: string = ValueDefinitionConstants.DEFAULT_EXPLANATION_LABEL;

  @Input() value?: Value;
  @Input() documents: MetricViewFileDocumentInterface[] = [];

  @Output() downloadFile: EventEmitter<MetricViewFileDocumentInterface> =
    new EventEmitter<MetricViewFileDocumentInterface>();
}
