import { PaginationData } from '../pagination';
import { Entity, User } from '../entities';
import { FiscalYear } from '../fiscal-years';

export enum FiscalYearAuditTrailStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  REOPEN = 'reopen',
  DRAFT = 'draft',
  TAXONOMY_PUBLISHED = 'taxonomy_published',
}

export interface FiscalYearAuditTrail extends Entity {
  notes: string;
  fiscal_year: FiscalYear;
  event_type: FiscalYearAuditTrailStatus;
  created_by_user: User;
}

export interface FiscalYearAuditTrailRow {
  year: number;
  event_type: string;
  user_name: string;
  timestamp: string;
  notes: string;
}

export interface FiscalYearAuditTrailFilteringOptions {
  filters?: FiscalYearAuditTrailFilteringData;
  pagination: PaginationData;
}

export interface FiscalYearAuditTrailFilteringData {
  fiscal_year?: string;
  event_type?: FiscalYearAuditTrailStatus | null;
}
