import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActionItem, MetricCategory } from '../../models';

@Component({
  selector: 'lib-metric-selector',
  templateUrl: './metric-selector.component.html',
  styleUrls: ['./metric-selector.component.scss'],
})
export class MetricSelectorComponent {
  @Input() category?: MetricCategory;
  @Input() referenceV2?: boolean;

  @Output() action: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();

  public searchOptions: string = '';

  public applyAction(action: ActionItem): void {
    this.action.emit(action);
  }

  public change(searchOptions: string): void {
    this.searchOptions = searchOptions;
  }
}
