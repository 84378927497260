import { Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';

import {
  ActionItem,
  DialogResult,
  Doc,
  DocumentTypeDetails,
  FileDocumentInterface,
  Status,
  ValueDefinition,
} from '../../../models';
import { DownloadDocumentService, TranslateService } from '../../../services/common';

import {
  LinkDocumentDialogComponent,
  LinkDocumentDialogData,
} from '../link-document-dialog/link-document-dialog.component';
import { DialogsService } from '../../../dialogs';
import { BaseStandardDocumentsService } from '../../../documents';
import { Observable } from 'rxjs';
import { DocumentTitlePipe } from '../metric-structure-document-field/pipes/document-title.pipe';
import { BaseMetricStructureService } from '../../services/base-metric-structure/base-metric-structure.service';

enum LinkAction {
  change = 'change',
  download = 'download',
}

@Component({
  selector: 'lib-metric-structure-document-field-properties',
  templateUrl: './metric-structure-document-field-properties.component.html',
  styleUrls: ['./metric-structure-document-field-properties.component.scss'],
  providers: [DocumentTitlePipe],
})
export class MetricStructureDocumentFieldPropertiesComponent {
  @Input({ required: true }) linkedDocumentControl!: UntypedFormControl;
  @Input({ required: true }) documentDisplayControl!: UntypedFormControl;
  @Input({ required: true }) valueDefinition!: ValueDefinition<DocumentTypeDetails>;

  @Output() updateResource: EventEmitter<Doc> = new EventEmitter<Doc>();
  @ViewChild(MatMenuTrigger) contextMenu?: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };
  linkActions: ActionItem[] = [
    {
      id: LinkAction.change,
      title: this.translateService.instant('Change'),
      icon: 'edit',
    },
    {
      id: LinkAction.download,
      title: this.translateService.instant('Download'),
      icon: 'download',
    },
  ];

  constructor(
    private translateService: TranslateService,
    private dialogsService: DialogsService,
    private baseMetricStructureService: BaseMetricStructureService,
    private baseStandardDocumentsService: BaseStandardDocumentsService,
    private downloadDocumentService: DownloadDocumentService,
    private documentTitlePipe: DocumentTitlePipe,
    private viewContainerRef: ViewContainerRef,
  ) {}

  public getDocumentDisplayValue(): string {
    const doc: Doc = this.linkedDocumentControl.value;
    return doc ? `${doc.name.split(' ').join('_')}.${doc.format}` : '';
  }

  public handleLinkAction(event: MouseEvent): void {
    if (this.linkedDocumentControl.value) {
      this.onContextMenu(event);
    } else {
      this.launchLinkDocDialog();
    }
  }

  public applyLinkAction(action: ActionItem): void {
    switch (action.id) {
      case LinkAction.change:
        this.launchLinkDocDialog();
        break;
      case LinkAction.download:
        this.downloadDocument();
        break;
      default:
        break;
    }
  }

  public launchLinkDocDialog(): void {
    this.dialogsService
      .open<LinkDocumentDialogComponent, undefined, DialogResult<LinkDocumentDialogData>>(LinkDocumentDialogComponent, {
        viewContainerRef: this.viewContainerRef,
      })
      .afterClosed()
      .subscribe((response?: DialogResult<LinkDocumentDialogData>) => {
        if (response && response.status === Status.SUCCESS) {
          this.linkedDocumentControl.setValue(response.data?.doc);
          this.linkedDocumentControl.markAsDirty();
          this.updateResource.emit(response.data?.doc);
        }
      });
  }

  private downloadDocument(): void {
    const doc = this.linkedDocumentControl.value as FileDocumentInterface;
    if (this.valueDefinition.type_details?.document_id) {
      this.getDocumentToDownload().subscribe((docBlob) =>
        this.downloadDocumentService.downloadAction(
          `${this.documentTitlePipe.transform(this.valueDefinition, doc.name).split(' ').join('_')}.${doc.format}`,
          docBlob,
        ),
      );
    }
  }

  private getDocumentToDownload(): Observable<Blob> {
    const doc = this.linkedDocumentControl.value as FileDocumentInterface;
    if (this.valueDefinition.type_details?.document_host_env === 'core') {
      return this.baseMetricStructureService.getCoreDocument(`${doc.id}.${doc.format}`);
    }
    return this.baseStandardDocumentsService.getDocument(`${doc.id}.${doc.format}`);
  }

  private onContextMenu(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.contextMenuPosition.x = `${event.clientX}px`;
    this.contextMenuPosition.y = `${event.clientY}px`;
    if (this.contextMenu) {
      this.contextMenu.menu?.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }
}
