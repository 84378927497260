import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ActionItem, BulkUpsertOptionListRequest, OptionList, OptionListItem } from '../../models';

type OptionListResourceListsModel = {
  resourceListItems: FormControl<string[]>;
  resourceList: FormControl<OptionList | null>;
};

export class OptionListResourceListsForm extends FormGroup<OptionListResourceListsModel> {
  constructor(fb: FormBuilder = new FormBuilder()) {
    super({
      resourceListItems: fb.control([], { nonNullable: true }),
      resourceList: fb.control(null),
    });
  }

  public toModel(resourceListItems: Record<string, ActionItem<OptionListItem>>): BulkUpsertOptionListRequest {
    return {
      option_list_items: this.controls.resourceListItems.value.map((resourceListItemId) => {
        const resourceListItem = resourceListItems[resourceListItemId].item;
        return {
          name: resourceListItem?.name || '',
          active: Boolean(resourceListItem?.active),
          display_explanation: Boolean(resourceListItem?.display_explanation),
          explanation_required: Boolean(resourceListItem?.explanation_required),
        };
      }),
    };
  }
}
