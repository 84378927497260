import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentTypeDetails, FileDocumentInterface, ValueDefinition } from '../../../models';
import { SearchService } from '../../../search';
import { ValueDefinitionTemplateType } from '../../models';
import { MetricStructureStateService } from '../../services/metric-structure-state.service';

@Component({
  selector: 'lib-metric-structure-document-field',
  templateUrl: './metric-structure-document-field.component.html',
  styleUrls: ['./metric-structure-document-field.component.scss'],
})
export class MetricStructureDocumentFieldComponent implements OnInit {
  @Input() valueDefinition?: ValueDefinition<DocumentTypeDetails>;
  documentId: string = '';
  documents$?: Observable<FileDocumentInterface[]>;

  readonly eValueDefinitionTemplateType: typeof ValueDefinitionTemplateType = ValueDefinitionTemplateType;
  constructor(
    private searchService: SearchService,
    private metricStructureStateService: MetricStructureStateService,
  ) {}

  ngOnInit(): void {
    this.documentId = this.valueDefinition?.type_details?.document_id ?? '';
    this.documents$ = this.metricStructureStateService.cachedDocumentList$;
  }

  public getDocumentIcon(format?: string): string {
    return format ? this.searchService.getFileFormatIcon(format) : '';
  }
}
