import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResult, Status } from '../../models';

@Component({
  selector: 'lib-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss'],
})
export class UnsavedChangesDialogComponent {
  constructor(private readonly dialogRef: MatDialogRef<UnsavedChangesDialogComponent, DialogResult>) {}

  discard() {
    this.dialogRef.close({ status: Status.CONFIRMED });
  }

  close() {
    this.dialogRef.close({ status: Status.CANCEL });
  }
}
