import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-dummy-loader',
  templateUrl: './dummy-loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class DummyLoaderComponent {
  @Input() message: string = '';

  constructor() {}
}
