<div class="fx-col">
  <mat-checkbox
    color="primary"
    *ngFor="let option of options"
    [checked]="control.value?.includes(option)"
    [value]="option"
    (change)="onCheckboxToggle($event, option)"
    [disabled]="control.disabled"
    class="mat-body-2"
    (click)="$event.stopPropagation()"
  >
    {{ option }}
  </mat-checkbox>
</div>
