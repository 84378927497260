<div class="field-position-relative">
  <lib-metric-editor-frequency-field
    data-testid="frequency-numeric-input"
    [control]="valueFormControl"
    [frequenciesControl]="frequenciesControls"
    [sourceConfiguration]="sourceConfiguration"
    [valueGroup]="valueGroup"
    [valueGroupFormGroup]="valueGroupFormGroup"
  ></lib-metric-editor-frequency-field>
</div>
