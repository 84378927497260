import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RichTextInputComponent } from '../../../components';
import { ValueFormControl } from '../../models/valueFormControl';
import { METRIC_EDITOR_AUTO_SAVE_INTERVAL_MS } from '../../../constants';
import { ValidationMessageService } from '../../../services/common';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'lib-metric-editor-rich-text',
  templateUrl: './metric-editor-rich-text.component.html',
  styleUrls: ['./metric-editor-rich-text.component.scss'],
})
export class MetricEditorRichTextComponent implements OnInit {
  @Input() valueFormControl?: ValueFormControl;

  @Output() editorBlur = new EventEmitter<void>();

  @ViewChild(RichTextInputComponent) inputFieldRef!: RichTextInputComponent;

  hint: string = '';
  label: string = '';
  placeholder: string = '';
  autoSaveInterval: number = METRIC_EDITOR_AUTO_SAVE_INTERVAL_MS;
  showDummyControl: boolean = true;
  errorMessages: ValidationErrors = {};

  constructor(private validationMessageService: ValidationMessageService) {}

  public ngOnInit(): void {
    if (this.valueFormControl) {
      this.hint = this.valueFormControl.valueRef.hint ?? '';
      this.label = this.valueFormControl.valueRef.label ?? '';
      this.placeholder = this.valueFormControl.valueRef.type_details.placeholder ?? '';
    }
    this.errorMessages = this.validationMessageService.validationMessages;
  }

  public setFocus(): void {
    this.inputFieldRef.setFocus();
  }

  public onBlur(): void {
    this.editorBlur.emit();
    this.showDummyControl = true;
  }

  public switchToActualControl(): void {
    if (!this.valueFormControl?.disabled) {
      this.showDummyControl = false;
    }
  }
}
