import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import debounce from 'lodash/debounce';

@Directive({
  selector: '[libDebounceClick]',
})
export class DebounceClickDirective {
  @Input() debounceTime = 500;
  @Output() libDebounceClick = new EventEmitter();

  @HostListener('click', ['$event'])
  debouncedClick = debounce(
    (event: Event) => {
      this.libDebounceClick.emit(event);
    },
    this.debounceTime,
    { leading: true, trailing: false },
  );
}
