import { DestroyRef, Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WebAuthnService } from '../../webauthn';

@Directive({
  selector: '[libPasskeyReady]',
})
export class PasskeyReadyDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private webAuthnService: WebAuthnService,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit() {
    this.webAuthnService
      .isPasskeyAvailable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (available) => {
          if (available) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        },
      });
  }
}
