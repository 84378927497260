import { Injectable } from '@angular/core';
import iso3166_2_db from 'iso3166-2-db/i18n/en.json';
import { Country, CountryState, Iso3166Countries, Iso3166Country } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private readonly countries: Country[] = [];
  private readonly states: CountryState[] = [];
  private readonly compareByName = (a: any, b: any) => (a.name > b.name ? 1 : -1);

  constructor() {
    this.countries = this.getCountriesDataSource();
    this.states = this.getStatesDataSource();
  }

  getAllCountries(): Country[] {
    return this.countries;
  }

  getAllStates(): CountryState[] {
    return this.states;
  }

  getStatesOfCountry(countryCode?: string): CountryState[] {
    if (!countryCode) {
      return [];
    }

    return this.states.filter((s) => s.countryIso === countryCode);
  }

  getCountryByCode(countryCode?: string): Country | undefined {
    if (!countryCode) {
      return undefined;
    }

    return this.countries.find((c) => c.iso === countryCode);
  }

  getStateByCode(stateCode?: string): CountryState | undefined {
    if (!stateCode) {
      return undefined;
    }

    return this.states.find((c) => c.iso === stateCode);
  }

  /**
   * The countries data entry point. This is the place to change if the countries JSON is going to be replaced.
   */
  private getCountriesDataSource(): Country[] {
    const dataSource: Iso3166Countries = iso3166_2_db as unknown as Iso3166Countries;
    return Object.values<Iso3166Country>(dataSource)
      .map(
        (country: Iso3166Country): Country => ({
          name: country.name,
          iso: country.iso,
        }),
      )
      .sort(this.compareByName);
  }

  /**
   * The states data entry point. This is the place to change if the states JSON is going to be replaced.
   */
  private getStatesDataSource(): CountryState[] {
    const dataSource: Iso3166Countries = iso3166_2_db as unknown as Iso3166Countries;
    return Object.values<Iso3166Country>(dataSource)
      .reduce((acc: CountryState[], country: Iso3166Country) => {
        acc.push(
          ...Object.values<any>(country.regions).map((state) => ({
            name: state.name,
            iso: state.iso,
            countryIso: country.iso,
          })),
        );
        return acc;
      }, [])
      .sort(this.compareByName);
  }
}
