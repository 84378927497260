<div class="mv-3">
  <div class="fx-row expand-all-wrapper" *ngIf="showMasterExpandControls">
    <button *ngIf="expandedPanelsCount !== items.length" lib-button (click)="accordion.openAll()">
      {{ "Expand All" | translate }}
      <mat-icon class="ml-1" svgIcon="arrow-head-down"></mat-icon>
    </button>
    <button *ngIf="expandedPanelsCount === items.length" lib-button (click)="accordion.closeAll()">
      {{ "Collapse All" | translate }}
      <mat-icon class="ml-1" svgIcon="arrow-head-up"></mat-icon>
    </button>
  </div>

  <mat-accordion multi #accordion="matAccordion">
    <mat-expansion-panel
      (expandedChange)="onExpandedChange($event)"
      [expanded]="expandIndices?.includes(i)"
      class="mat-expansion-cards-panel"
      *ngFor="let item of items; let i = index"
    >
      <mat-expansion-panel-header class="ph-4" [collapsedHeight]="cardHeight" [expandedHeight]="cardHeight">
        <ng-container
          *ngIf="headerTemplateRef"
          [ngTemplateOutlet]="headerTemplateRef"
          [ngTemplateOutletContext]="{ $implicit: item, index: i }"
        >
        </ng-container>
      </mat-expansion-panel-header>

      <div class="ph-4">
        <hr class="m-0 light" />

        <div class="pv-3">
          <ng-container
            *ngIf="contentTemplateRef"
            [ngTemplateOutlet]="contentTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-container>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
