<div mat-dialog-title [ngClass]="{ 'error-header': error, 'm-0': removeMargin }">
  <div class="fx-row justify-space-between w-100">
    <header class="title-header">
      <a *ngIf="hasGoBackButton" (click)="onGoBack()" class="go-back-button" data-testid="go-back-button">
        <mat-icon svgIcon="arrow-full-left" class="go-back-icon"></mat-icon>
      </a>
      <div role="heading" class="header-text mat-h3 mat-primary" [style]="{ maxWidth: maxWidth }">{{ title }}</div>
    </header>

    <a class="pl-1" *ngIf="!modal && !isOfflineDialog" (click)="onClose()" data-testid="close-button">
      <mat-icon svgIcon="close"></mat-icon>
    </a>
  </div>

  <div class="tabs mat-body-1">
    <a
      [attr.data-testid]="'tab-' + tab.id"
      *ngFor="let tab of tabs"
      (click)="switchTab(tab)"
      [ngClass]="{ active: selectedTab === tab }"
      >{{ tab.title }}</a
    >
  </div>
</div>
