import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RelatedField } from '../../../../../models';
import { MetricStructureSelectable } from '../../../../models';

@Component({
  selector: 'lib-field-information-related-field',
  templateUrl: './field-information-related-field.component.html',
})
export class FieldInformationRelatedFieldComponent implements OnInit {
  @Input({ required: true }) public currentField!: MetricStructureSelectable;
  @Input({ required: true }) public relatedField!: RelatedField;
  @Input() enableDelete: boolean = false;

  @Output() deleteRelatedField: EventEmitter<void> = new EventEmitter<void>();

  public toMetricCode?: string;
  public toFieldLabel?: string;

  public ngOnInit(): void {
    this.setToMetricCode();
    this.setToFieldLabel();
  }

  private setToFieldLabel(): void {
    if (
      this.relatedField.from_metric_table_definition_id === this.currentField.id ||
      this.relatedField.from_value_definition_id === this.currentField.id
    ) {
      this.toFieldLabel = this.relatedField.to_value_definition
        ? this.relatedField.to_value_definition.label
        : this.relatedField.to_metric_table_definition?.title;
    } else if (
      this.relatedField.to_metric_table_definition_id === this.currentField.id ||
      this.relatedField.to_value_definition_id === this.currentField.id
    ) {
      this.toFieldLabel = this.relatedField.from_value_definition
        ? this.relatedField.from_value_definition.label
        : this.relatedField.from_metric_table_definition?.title;
    }
  }

  private setToMetricCode(): void {
    if (
      this.relatedField.from_metric_table_definition_id === this.currentField.id ||
      this.relatedField.from_value_definition_id === this.currentField.id
    ) {
      this.toMetricCode = this.relatedField.to_metric.code;
    } else {
      this.toMetricCode = this.relatedField.from_metric.code;
    }
  }
}
