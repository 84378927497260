import { Pipe, PipeTransform } from '@angular/core';
import { ValueDefinitionType } from '../../../../../models';

@Pipe({
  name: 'isSupportedTypeAsVariable',
})
export class IsSupportedTypeAsVariablePipe implements PipeTransform {
  transform(type: ValueDefinitionType): boolean {
    return [ValueDefinitionType.number, ValueDefinitionType.calculated].includes(type);
  }
}
