import { Pipe, PipeTransform } from '@angular/core';
import { ReportIntegrationType } from '../../models';
import { TranslateService } from '../../services/common';
import { ReportTemplateIntegrationsTranslation } from '../../translations';

@Pipe({
  name: 'integrationType',
})
export class IntegrationTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(reportIntegrationType: ReportIntegrationType | null): string {
    return reportIntegrationType
      ? this.translateService.instant(ReportTemplateIntegrationsTranslation[reportIntegrationType])
      : '-';
  }
}
