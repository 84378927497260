import { Pipe, PipeTransform } from '@angular/core';
import { LimitedUser, User } from '../../models';
import { TranslateService } from '../../services/common';

@Pipe({
  name: 'updatedByUser',
})
export class UpdatedByUserPipe implements PipeTransform {
  static readonly UNKNOWN_USER_NAME = 'Unknown';
  static readonly NOVISTO_SYSTEM_ADMIN_USER_NAME = 'Novisto System Admin';

  constructor(private translateService: TranslateService) {}

  transform(updateByUserId: string | undefined, users: (LimitedUser | User)[]): string {
    if (!updateByUserId) {
      return this.translateService.instant(UpdatedByUserPipe.NOVISTO_SYSTEM_ADMIN_USER_NAME);
    }
    const updatedByUser: LimitedUser | User | undefined = users.find(
      (user: LimitedUser | User) => user.id === updateByUserId,
    );
    return updatedByUser
      ? UpdatedByUserPipe.getUserFullName(updatedByUser)
      : this.translateService.instant(UpdatedByUserPipe.UNKNOWN_USER_NAME);
  }

  private static getUserFullName(user: LimitedUser | User): string {
    return `${user.first_name} ${user.last_name}`;
  }
}
