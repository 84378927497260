import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  ApiResponse,
  ApplicationApiDefinition,
  SearchOptions,
  Org,
  Source,
  FiscalYear,
  AttributeCheckResponse,
  CDPSector,
  WDIIndustry,
  UpsertPeerPayload,
  ListPeersRequest,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class AdminPeerService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.orgs;
  }

  orgsPayloadFromSearchOptions(searchOptions: SearchOptions): ListPeersRequest {
    const payload: ListPeersRequest = {};

    payload.order_by = 'name';
    payload.load_industries = true;
    payload.filters = {};

    payload.page_size = searchOptions.size || this.apiService.apiConfig.pageSize;
    payload.page = Math.floor((searchOptions.from ?? 0) / payload.page_size) + 1;

    if (searchOptions.query.keywords) {
      payload.keywords = searchOptions.query.keywords;
    }

    if (searchOptions.filters.industry) {
      payload.filters.industries = [searchOptions.filters.industry.id];
    }

    if (searchOptions.filters.status) {
      payload.filters.active = searchOptions.filters.status.id === 'true';
    }

    if (searchOptions.custom_filters) {
      if (!payload.filters) {
        payload.filters = {};
      }
      for (const filterKey of Object.keys(searchOptions.custom_filters)) {
        payload.filters[filterKey] = searchOptions.custom_filters[filterKey];
      }
    }

    return payload;
  }

  public search(searchOptions: SearchOptions): Observable<ApiResponse<Org[]>> {
    const payload = this.orgsPayloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resource}/orgs/list`, payload);
  }

  public createPeer(peer: UpsertPeerPayload): Observable<ApiResponse<Org>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/orgs`, peer);
  }

  public checkOrgName(name: string): Observable<ApiResponse<AttributeCheckResponse>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/orgs/name_check/${name}`);
  }

  public getCDPSectors(): Observable<ApiResponse<CDPSector[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/cdp_sectors`);
  }

  public getPeer(orgId: string): Observable<ApiResponse<Org>> {
    let params = new HttpParams();
    params = params.append('load_industries', true);
    params = params.append('load_fiscal_year_description', true);
    return this.apiService.get(`${this.servicePath}${this.resource}/orgs/${orgId}`, { params });
  }

  public getWDIIndustries(): Observable<ApiResponse<WDIIndustry[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/wdi_industries`);
  }

  public updatePeer(orgId: string, payload: UpsertPeerPayload): Observable<ApiResponse<Org>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/orgs/${orgId}`, payload);
  }

  public activatePeer(orgId: string): Observable<ApiResponse<Org>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/orgs/${orgId}/activate`, {});
  }

  public deactivatePeer(orgId: string): Observable<ApiResponse<Org>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/orgs/${orgId}/deactivate`, {});
  }

  public listBusinessUnits(orgId?: string): Observable<ApiResponse<Source[]>> {
    const params = new HttpParams();
    if (orgId) {
      params.append('org_id', orgId);
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/business_units`, { params });
  }

  public listPeers(payload: ListPeersRequest = {}): Observable<ApiResponse<Org[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/orgs/list`, payload);
  }

  public listFiscalYears(orgId?: string): Observable<ApiResponse<FiscalYear[]>> {
    let params = new HttpParams();
    if (orgId) {
      params = params.append('org_id', orgId);
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/fiscal_years`, { params });
  }
}
