import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const FileExtensions = {
  pdf: _('PDF Document'),
  xlsx: _('Spreadsheet'),
  xls: _('Spreadsheet'),
  ppt: _('Powerpoint Presentation'),
  pptx: _('Powerpoint Presentation'),
  doc: _('Word Document'),
  docx: _('Word Document'),
};

export const FileExtensionErrors = {
  unsupported: _('The file format you have selected is not supported by Novisto.'),
};
