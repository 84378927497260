<lib-dialog-title
  [title]="'Add Novisto framework taxonomy' | translate"
  (closeEvent)="closeDialog()"
></lib-dialog-title>

<div mat-dialog-content>
  <ng-container *ngIf="data$ | async as data">
    <lib-filter-bar
      *ngIf="data.tableFilters.length"
      [secondaryFilters]="data.tableFilters"
      [withSearchBar]="true"
      [searchBarPlaceholder]="'Search framework taxonomies' | translate"
      (onSearchChanged)="onSearchChange($event)"
      (onFilterChanged)="onFilterChange($event)"
    ></lib-filter-bar>

    <div class="full-content">
      <lib-data-table-remote-data
        *ngIf="!data.isLoading; else dummyTable"
        [tableColumns]="tableColumns"
        [tableData]="data.taxonomies"
        [paginationConfig]="data.paginationConfig"
        [isPaginable]="false"
        [isShowingLoadingAnimation]="data.isLoadingNextPage"
        [enableRowSelection]="true"
        [selectAllOption]="false"
        [selections]="selections"
        (checkChanged)="onCheckChanged($event)"
        (scrolledToBottom)="onLoadNextPage(data.isLoadingNextPage, data.paginationConfig.total, data.count)"
      >
      </lib-data-table-remote-data>
    </div>
  </ng-container>
</div>

<div mat-dialog-actions>
  <button lib-button buttonType="link" (click)="closeDialog()">{{ "Cancel" | translate }}</button>
  <button
    lib-button
    buttonType="primary"
    class="ml-2"
    (click)="save()"
    [disabled]="!form.controls.taxonomies.value.length"
  >
    {{ "Add codes" | translate }}
  </button>
</div>

<ng-template #dummyTable>
  <lib-dummy class="w-100" [presentation]="ePresentation.matTable" data-testid="dummy-matTable"></lib-dummy>
</ng-template>
