import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataRequestValueGroupSetStatus, Indicator, PlatformValueGroupSetStatus } from '../../../models';
import { ValueGroupSetStatusToText } from '../../../translations';

const mappedVgsStatusIcon: Record<DataRequestValueGroupSetStatus | PlatformValueGroupSetStatus, string> = {
  [DataRequestValueGroupSetStatus.COMPLETED]: 'check',
  [DataRequestValueGroupSetStatus.ACCEPTED]: 'pass',
  // [DataRequestValueGroupSetStatus.NOT_STARTED]: 'warning', // breaks with typescript 5.4 with duplicate key
  // [DataRequestValueGroupSetStatus.IN_PROGRESS]: 'warning', // breaks with typescript 5.4 with duplicate key
  [DataRequestValueGroupSetStatus.REJECTED]: 'reject',
  [DataRequestValueGroupSetStatus.VALIDATE]: 'warning',
  [DataRequestValueGroupSetStatus.APPROVED]: 'saved-filled',
  [PlatformValueGroupSetStatus.IN_PROGRESS]: 'warning',
  [PlatformValueGroupSetStatus.NOT_STARTED]: 'warning',
  [PlatformValueGroupSetStatus.COMPLETE]: 'check',
};

@Component({
  selector: 'lib-metric-status-card',
  templateUrl: './metric-status-card.component.html',
  styleUrls: ['./metric-status-card.component.scss'],
})
export class MetricStatusCardComponent {
  @Input() indicator?: Indicator;
  @Input() indicatorCodeOverride?: string;
  @Input() indicatorDescriptionOverride?: string;
  @Input() selected: boolean = false;
  @Input() important: boolean = false;
  @Input() isLocked: boolean = false;
  @Input() displayIndicatorCode: boolean = true;
  @Input() set vgsetStatus(vgsetStatus: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus | undefined) {
    this.vgsetStatusValue = vgsetStatus;
    this.vgsetStatusIcon = this.vgsetStatusValue ? mappedVgsStatusIcon[this.vgsetStatusValue] : '';
  }
  @Input() deactivated: boolean = false;

  @Output() cardSelect: EventEmitter<void> = new EventEmitter<void>();

  readonly vgsStatusToText = ValueGroupSetStatusToText;

  vgsetStatusIcon: string = '';
  vgsetStatusValue?: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus;
}
