<div class="card-container" data-testid="conditional-trigger-card-container">
  <a class="card card-condensed mb-1" [ngClass]="{ disabled: disabled }" data-testid="conditional-trigger-card">
    <div class="pr-2">
      <div class="item-title mat-body-2 color-primary-900 mb-1">{{ conditionalTriggerValuesText }}</div>
      <div class="item-subtitle caption2 color-grey-800">
        {{ "Show {label}" | translate: { label: conditionalTriggerTargetLabels } }}
      </div>
    </div>
  </a>
  <a
    class="context-menu"
    [ngClass]="{ disabled: disabled }"
    data-testid="conditional-trigger-card-menu"
    (click)="menuClick.emit($event)"
  >
    <mat-icon class="icon-sm" svgIcon="more"></mat-icon>
  </a>
</div>
