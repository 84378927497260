<div class="search-container">
  <div class="search-keywords">
    <mat-icon class="search-icon" svgIcon="search"></mat-icon>
    <input
      type="text"
      [placeholder]="searchBarPlaceholder"
      [ngModel]="searchQuery"
      (ngModelChange)="onSearchQueryChanged($event)"
      (keyup.enter)="onSearchQueryChanged(undefined, true)"
    />
  </div>
  <a *ngIf="searchQuery" class="clear-search" (click)="clearSearch()" role="button">
    <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
  </a>
</div>
