import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CurtainStateService } from '../services/common';

@Component({
  selector: 'lib-app-curtain',
  templateUrl: './curtain.component.html',
  styleUrls: ['./curtain.component.scss'],
})
export class CurtainComponent implements OnInit {
  curtainIsOpen$?: Observable<boolean>;
  curtainMessage$?: Observable<string>;

  constructor(private curtainStateService: CurtainStateService) {}

  ngOnInit(): void {
    this.curtainIsOpen$ = this.curtainStateService.curtainIsOpen$;
    this.curtainMessage$ = this.curtainStateService.curtainMessage$;
  }
}
