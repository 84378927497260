import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CdkModule } from '../material/cdk.module';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directives';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ComponentsModule } from '../components';
import { NumberMaskModule } from '../number-mask';
import { AuthRefreshDialogComponent } from './auth-refresh-dialog/auth-refresh-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { DialogInfoActionsComponent } from './dialog-info-actions/dialog-info-actions.component';
import { TagCannotBeDeletedDialogComponent } from './tag-cannot-be-deleted-dialog/tag-cannot-be-deleted-dialog.component';
import { DialogTitleComponent } from './dialog-title/dialog-title.component';
import { DialogWarningComponent } from './dialog-warning/dialog-warning.component';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog.component';
import { InfoDialogV2Component } from './info-dialog-v2/info-dialog-v2.component';
import { PasskeyNameDialogComponent } from './passkey-name-dialog/passkey-name-dialog.component';

@NgModule({
  declarations: [
    AuthRefreshDialogComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    DialogInfoActionsComponent,
    TagCannotBeDeletedDialogComponent,
    DialogTitleComponent,
    DialogWarningComponent,
    UnsavedChangesDialogComponent,
    InfoDialogV2Component,
    PasskeyNameDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CdkModule,
    MaterialModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ComponentsModule,
    NumberMaskModule,
  ],
  providers: [provideNgxMask()],
  exports: [
    AuthRefreshDialogComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    DialogInfoActionsComponent,
    TagCannotBeDeletedDialogComponent,
    DialogTitleComponent,
    DialogWarningComponent,
    PasskeyNameDialogComponent,
  ],
})
export class DialogsModule {}
