import { Component, OnInit, Input } from '@angular/core';

import { SearchService } from '../../search';
import { Collection, Presentation, SearchOptions } from '../../models';

@Component({
  selector: 'lib-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit {
  @Input({ required: true }) presentation?: Presentation;
  @Input() title: string = '';
  @Input() link: string = '';
  @Input() linkPosition: 'before' | 'after' = 'before';
  @Input() linkText: string = '';
  @Input() emptyLink: string = '';
  @Input() emptyLinkText: string = '';
  @Input() searchOptions: string = '';
  @Input() cache?: string;

  loaded: boolean = false;
  dummies = new Array(4);
  itemCollection?: Collection<any>;
  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    this.search();
  }

  search(): void {
    const searchOptions: SearchOptions = JSON.parse(this.searchOptions);
    this.loaded = false;
    this.searchService.search(searchOptions, this.cache).subscribe((result) => {
      this.itemCollection = result;
      this.loaded = true;
    });
  }
}
