import { SortingTranslation } from '../sorting.translation';

export { SelectionSetSort, SelectionSetItemSort };

const SelectionSetSort = {
  updated: SortingTranslation.last_updated,
  name: SortingTranslation.alphabetical,
};

const SelectionSetItemSort = {
  position: SortingTranslation.position,
  name: SortingTranslation.alphabetical,
};
