import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UserType } from '../../models';
import { SortingTranslation } from '../sorting.translation';

export { UsersUserStatus, UsersUserType, UsersSort };

const UsersUserStatus = {
  active: _('Active'),
  inactive: _('Inactive'),
};

const UsersUserType = {
  [UserType.STANDARD]: _('Novisto user'),
  [UserType.PUBLIC]: _('External user'),
};

const UsersSort = {
  start: SortingTranslation.last_updated,
  name: SortingTranslation.alphabetical,
};
