import { AbstractControl, ValidatorFn } from '@angular/forms';

import dayjs from 'dayjs';

export class TemplateReportVersionValidators {
  public static validateDates(
    previousVersionEndDate: Date | string | null = '',
    previousVersionStartDate: Date | string | null = '',
    subsequentVersionStartDate: Date | string | null = '',
  ): ValidatorFn {
    return (control: AbstractControl) => {
      const end = String(control.get('end')?.value);
      const start = String(control.get('start')?.value);

      if (this.invalidDates(start, end)) {
        return { endRangeError: true };
      } else if (this.invalidDates(previousVersionEndDate, start)) {
        return { previousEndRangeError: true };
      } else if (this.invalidDates(previousVersionStartDate, start, 1)) {
        return { previousStartRangeError: true };
      } else if (this.invalidDates(end, subsequentVersionStartDate)) {
        return { subsequentStartRangeError: true };
      }

      return null;
    };
  }

  private static invalidDates(prior?: string | Date | null, subsequent?: string | Date | null, days = 0): boolean {
    const date1 = dayjs(prior).add(days, 'days');
    const date2 = dayjs(subsequent);
    return Boolean(+date1 && +date2 && date2.diff(date1, 'day') <= 0);
  }
}
