import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MetricStructurePropertiesValidators } from '../validators/metric-structure-properties-validators';
import { ValueDefinitionGroup } from '../../../../models';
import { startWith, takeUntil } from 'rxjs/operators';
import { ValidatorsUtils } from '../../../../classes/FormUtils/validators-utils';
import { TranslateService } from '../../../../services/common';
import { Subject } from 'rxjs';

export class MetricStructureGroupPropertiesForm extends UntypedFormGroup {
  private destroy$ = new Subject<void>();
  readonly labelControl = this.get('label') as UntypedFormControl;
  readonly toggleSubtitleControl = this.get('toggleSubtitle') as UntypedFormControl;
  readonly indentControl = this.get('indent') as UntypedFormControl;
  readonly toggleRepeatableControl = this.get('toggleRepeatable') as UntypedFormControl;
  readonly repeatLimitControl = this.get('repeatLimit') as UntypedFormControl;

  constructor(
    readonly translateService: TranslateService,
    valueDefinitionGroup?: ValueDefinitionGroup,
    groupLabelMaxCharLimit: number = 500,
    disabledProperties: string[] = [],
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
  ) {
    super({
      label: fb.control({ value: valueDefinitionGroup?.label, disabled: disabledProperties.includes('label') }, [
        Validators.maxLength(groupLabelMaxCharLimit),
        ValidatorsUtils.isNotOnlySpaces,
      ]),
      toggleSubtitle: fb.control({
        value: valueDefinitionGroup?.show_label_as_subtitle,
        disabled: disabledProperties.includes('toggleSubtitle'),
      }),
      indent: fb.control({
        value: valueDefinitionGroup?.indent.toString() ?? '0',
        disabled: disabledProperties.includes('indent'),
      }),
      toggleRepeatable: fb.control({
        value: valueDefinitionGroup?.repeatable,
        disabled: disabledProperties.includes('toggleRepeatable'),
      }),
      repeatLimit: fb.control(
        {
          value: valueDefinitionGroup?.repeatable ? valueDefinitionGroup.group_max_repetition ?? 0 : undefined,
          disabled: disabledProperties.includes('repeatLimit'),
        },
        [
          Validators.required,
          MetricStructurePropertiesValidators.isNumber,
          MetricStructurePropertiesValidators.is0OrGreaterThan1,
        ],
      ),
    });
    this.assignRepeatLimitModifier(valueDefinitionGroup);
  }

  public destroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private assignRepeatLimitModifier(valueDefinitionGroup?: ValueDefinitionGroup): void {
    this.toggleRepeatableControl.valueChanges
      .pipe(startWith(valueDefinitionGroup?.repeatable), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (!value) {
          this.repeatLimitControl.setValue(undefined);
          this.repeatLimitControl.disable();
        } else {
          this.repeatLimitControl.enable();
        }
      });
  }

  toModel(): Partial<ValueDefinitionGroup> {
    return {
      label: this.labelControl.value?.trim(),
      show_label_as_subtitle: !!this.toggleSubtitleControl.value,
      indent: this.indentControl.value,
      repeatable: !!this.toggleRepeatableControl.value,
      group_max_repetition:
        this.repeatLimitControl.enabled && this.repeatLimitControl.value > 1
          ? this.repeatLimitControl.value
          : undefined,
    };
  }
}
