import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  DataRequest,
  DocumentHostEnv,
  FileDocumentInterface,
  MetricTableDefinition,
  Value,
  ValueGroup,
  ValueGroupSet,
  FocusedFieldAction,
} from '../../../models';
import { ValueDefinitionService } from '../value-definition.service';

@Injectable()
export abstract class BaseMetricEditorFormStateService {
  private _focusedField$: BehaviorSubject<Value | undefined> = new BehaviorSubject<Value | undefined>(undefined);
  private _focusedFieldValueGroup$: BehaviorSubject<ValueGroup | undefined> = new BehaviorSubject<
    ValueGroup | undefined
  >(undefined);
  private _focusFieldUniqueId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _focusedFieldAction$: BehaviorSubject<FocusedFieldAction | undefined> = new BehaviorSubject<
    FocusedFieldAction | undefined
  >(undefined);

  public focusedField$: Observable<Value | undefined>;
  public focusedFieldValueGroup$: Observable<ValueGroup | undefined>;
  public focusFieldUniqueId$: Observable<string>;
  public currentFocusedField: Value | undefined;
  public focusedFieldAction$: Observable<FocusedFieldAction | undefined>;

  // Temporary fix until requests, workflow and reports have field specific side panel items
  abstract enableFocus: boolean;
  protected showTableTotalComputation: boolean = true;

  private _includedInDataRequests$: BehaviorSubject<DataRequest[] | undefined> = new BehaviorSubject<
    DataRequest[] | undefined
  >(undefined);
  public includedInDataRequests$: Observable<DataRequest[] | undefined>;

  constructor(protected valueDefinitionService: ValueDefinitionService) {
    this.focusedField$ = this._focusedField$.asObservable();
    this.focusedFieldValueGroup$ = this._focusedFieldValueGroup$.asObservable();
    this.focusFieldUniqueId$ = this._focusFieldUniqueId$.asObservable();
    this.includedInDataRequests$ = this._includedInDataRequests$.asObservable();
    this.focusedFieldAction$ = this._focusedFieldAction$.asObservable();
  }

  public getShowTableTotalComputation(): boolean {
    return this.showTableTotalComputation;
  }

  public setDefaultFocusField(vgset: ValueGroupSet): void {
    let focusField: Value | undefined;
    vgset.value_groups?.some((vg) => {
      focusField = vg.values?.find((val) => this.valueDefinitionService.isFocusableDisplayField(val));
      if (focusField) {
        this.setUniqueFocusFieldId(vg, focusField);
      }
      return focusField;
    });
    this.setFocusField(focusField);
  }

  public refreshFocusedField(newVgset: ValueGroupSet | undefined | null): void {
    let newFocusField: Value | undefined;
    const initialFocusField = this._focusedField$.getValue();
    newVgset?.value_groups?.some((vg) => {
      newFocusField = vg.values?.find(
        (val) =>
          val.value_group_id === initialFocusField?.value_group_id &&
          val.value_definition_id === initialFocusField?.value_definition_id &&
          val.value_definition_group_id === initialFocusField.value_definition_group_id &&
          val.position === initialFocusField.position,
      );
      if (newFocusField) {
        this.setFocusField(newFocusField);
      }
    });
  }

  public setFocusFieldAndUniqueId(focusId: string, value: Value, group: ValueGroup): void {
    this.setFocusField(value);
    this.setFocusFieldUniqueId(focusId);
    this.setFocusedFieldAction(undefined);
    this.setFocusFieldValueGroup(group);
  }

  public setIncludedInDataRequests(dataRequests: DataRequest[]): void {
    this._includedInDataRequests$.next(dataRequests);
  }

  public addToDataRequestInclusionList(dataRequest: DataRequest[]): void {
    const oldValues: DataRequest[] = this._includedInDataRequests$.getValue() ?? [];
    this.setIncludedInDataRequests(oldValues.concat(dataRequest));
  }

  public setFocusedFieldAction(focusedFieldAction: FocusedFieldAction | undefined): void {
    this._focusedFieldAction$.next(
      focusedFieldAction ? (String(focusedFieldAction) as FocusedFieldAction) : focusedFieldAction,
    );
  }

  private setFocusField(value?: Value): void {
    this._focusedField$.next(value);
  }

  private setFocusFieldUniqueId(focusId: string): void {
    this._focusFieldUniqueId$.next(focusId);
  }

  private setFocusFieldValueGroup(group: ValueGroup): void {
    this._focusedFieldValueGroup$.next(group);
  }

  private setUniqueFocusFieldId(group: ValueGroup, value: Value): void {
    const fields: (string | number)[] = [
      group.value_definition_group_id,
      group.position,
      group.subposition ?? 1,
      value.value_definition_id,
      value.position,
    ];
    this.setFocusFieldUniqueId(fields.join('-'));
    this.setFocusFieldValueGroup(group);
  }

  abstract getMetricTableDefinition(metricId: string, tableId: string): Observable<MetricTableDefinition>;
  abstract getDocumentLink(
    doc: FileDocumentInterface,
    hostEnv: DocumentHostEnv,
    valueDefinitionId?: string,
  ): Observable<Blob>;
  abstract getDocument(doc: FileDocumentInterface): Observable<Blob>;
  abstract getDocumentLinkMetadata(
    documentIds: string[],
    hostEnv: DocumentHostEnv,
    valueDefinitionId?: string,
  ): Observable<FileDocumentInterface[]>;
  abstract getDocumentMetadata(documentIds: string[], valueDefinitionId?: string): Observable<FileDocumentInterface[]>;
  abstract uploadDocument(
    file: File,
    formData?: FormData,
    reportProgress?: boolean,
    bypassInterceptors?: boolean,
  ): Observable<FileDocumentInterface>;
}
