export { LoginEventTypes, LoginEventStatus };

enum LoginEventTypes {
  login_success = 'login_success',
  login_fail_invalid_credentials = 'login_fail_invalid_credentials',
  login_fail_invalid_2fa = 'login_fail_invalid_2fa',
  login_fail_password_expired = 'login_fail_password_expired',
  login_fail_user_inactive = 'login_fail_user_inactive',
  account_lock = 'account_lock',
  password_reset = 'password_reset',
}

enum LoginEventStatus {
  success = 'success',
  fail = 'fail',
  other = 'other',
}
