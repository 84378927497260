import { Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';

import {
  BaseValue,
  BooleanValue,
  ConfirmationDialogConfig,
  DataFormatTemplate,
  DialogResult,
  ItemType,
  Status,
  Unit,
  UpdateDataFormatPayload,
  Validator,
  Value,
  ValueDefinitionDisplayType,
} from '../../models';
import { TranslateService } from '../../services/common';
import { ConfirmationDialogComponent, DialogsService } from '../../dialogs';
import { DateValidators } from '../../validators';

export const PICK_FORMATS = {
  parse: { dateInput: 'DD/MM/YYYY' },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Injectable()
class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'DD/MM/YYYY') {
      return formatDate(date, 'dd/MM/yyyy', String(this.locale));
    } else if (displayFormat === 'MM/DD/YYYY') {
      return formatDate(date, 'MM/dd/yyyy', String(this.locale));
    } else if (displayFormat === 'YYYY-MM-DD') {
      return formatDate(date, 'yyyy-MM-dd', String(this.locale));
    } else {
      return date.toDateString();
    }
  }

  parse(value: unknown) {
    if (typeof value !== 'string' || value.indexOf('/') <= -1 || value.indexOf('-') <= -1) {
      return null;
    }
    const str = value.split('/');
    if (str[0]) {
      if (PICK_FORMATS.parse.dateInput === 'DD/MM/YYYY') {
        const date = new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]));
        if (date.getDate() === Number(str[0])) {
          return date;
        }
      } else if (PICK_FORMATS.parse.dateInput === 'MM/DD/YYYY') {
        const date = new Date(Number(str[2]), Number(str[0]) - 1, Number(str[1]));
        if (date.getDate() === Number(str[1])) {
          return date;
        }
      } else if (PICK_FORMATS.parse.dateInput === 'YYYY-MM-DD') {
        const str = value.split('-');
        const date = new Date(Number(str[0]), Number(str[1]) - 1, Number(str[2]));
        if (date.getDate() === Number(str[2])) {
          return date;
        }
      }
    }
    return null;
  }
}

@Component({
  selector: 'lib-data-formats',
  templateUrl: './data-formats.component.html',
  styleUrls: ['./data-formats.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
  ],
})
export class DataFormatsComponent implements OnInit {
  @Input()
  set data(i: DataFormatTemplate<BaseValue>) {
    if (i) {
      this._data = i;
    }
  }

  get data(): DataFormatTemplate<BaseValue> {
    return this._data;
  }

  @Input()
  set units(i: Unit[]) {
    if (i) {
      this._units = i;
    }
  }

  get units(): Unit[] {
    return this._units;
  }

  @Input() errorMsg?: string = '';
  @Input() itemType: ItemType = ItemType.metrics_indicator;

  @Output() changed: EventEmitter<UpdateDataFormatPayload> = new EventEmitter<{
    id: string;
    value: any;
    error?: boolean;
  }>();

  @Output() hasError: EventEmitter<{
    id: string;
    valid: boolean;
  }> = new EventEmitter<{ id: string; valid: boolean }>();

  @Output() delete: EventEmitter<{ value_id: string }> = new EventEmitter<{ value_id: string }>();
  @ViewChild('searchableSelectInput') searchableSelectInput!: ElementRef<HTMLInputElement>;

  numberValidationErrorMsg = this.translateService.instant('Please enter a valid');
  numberControl = new UntypedFormControl('');
  editorControl = new UntypedFormControl('');
  formGroup = new UntypedFormGroup({
    textAreaControl: new UntypedFormControl(''),
    textAreaWithValidation: new UntypedFormControl(''),
    textInputFormat: new UntypedFormControl(''),
    numberControl: this.numberControl,
    dateControl: new UntypedFormControl(''),
    editorControl: this.editorControl,
    booleanControl: new UntypedFormControl(''),
    conditionalInputControl: new UntypedFormControl(''),
    selectControl: new UntypedFormControl(''),
    fileControl: new UntypedFormControl(''),
  });
  requiredErrorMsg = this.translateService.instant('missing value');
  maxlengthErrorMsg = this.translateService.instant('maximum length exceeded');
  minlengthErrorMsg = this.translateService.instant('minimum length check failed');
  maxCharacterCount = '';
  symbol?: string | null;
  file?: any;
  dateRequiredError: boolean = false;
  valueDefinitionType: typeof ValueDefinitionDisplayType = ValueDefinitionDisplayType;
  itemTypeEnum: typeof ItemType = ItemType;
  private _data!: DataFormatTemplate<BaseValue>;
  private _units: Unit[] = [];

  constructor(
    private translateService: TranslateService,
    private dialogsService: DialogsService,
  ) {}

  ngOnInit(): void {
    this.setControlValues();
    if (
      ![
        ValueDefinitionDisplayType.choice_searchable,
        ValueDefinitionDisplayType.choice_multiple,
        ValueDefinitionDisplayType.file,
      ].includes(this.data.dataFormatType)
    ) {
      this.formGroup.valueChanges.subscribe(() => {
        this.formGroup.markAllAsTouched();
        this.returnValues();
      });
    }
  }

  public isChoiceField(): boolean {
    return [
      ValueDefinitionDisplayType.choice,
      ValueDefinitionDisplayType.choice_multiple,
      ValueDefinitionDisplayType.choice_searchable,
      ValueDefinitionDisplayType.choice_radio,
    ].includes(this.data.dataFormatType);
  }

  // date format
  public validateDate = (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value) {
      const date: Date = control.value;
      if (!+date) {
        return { invalidDate: true };
      }
    } else {
      return { invalidDate: true };
    }
    return null;
  };

  // number format
  public validateNumber = (control: AbstractControl): { notNumeric: boolean } | null => {
    const value: string = control.value ? String(control.value).split(',').join('') : '';
    if (control.value) {
      return parseFloat(value) == parseInt(value) && !isNaN(Number(value)) && control.value.indexOf('.') < 0
        ? null
        : {
            notNumeric: true,
          };
    }
    return null;
  };

  roundOff(event: any): void {
    const val = event.srcElement.value;
    if (this.data.data.type_details?.max_decimals) {
      event.srcElement.value =
        val.indexOf('.') >= 0
          ? val.substr(0, val.indexOf('.')) + val.substr(val.indexOf('.'), this.data.data.type_details.max_decimals + 1)
          : val;
      this.formGroup.controls.numberControl.setValue(event.srcElement.value);
    }
    this.formGroup.markAllAsTouched();
    this.returnValues();
  }

  // file
  dropFile(event: any): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.dataTransfer) {
      // data from drop event
      this.file = event.dataTransfer.files[0];
    }
    // data from browse
    else {
      this.file = event.target.files[0];
    }
    this.formGroup.controls.fileControl.setValue(this.file);
    this.returnValues();
  }

  onDragOver(e: Event): void {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  onFileFocus(): void {
    this.formGroup.controls.fileControl.markAsTouched();
  }

  browseFiles(): void {
    this.changed.emit({ id: this.data.id, value: '', type: 'browse_file' });
  }

  // boolean
  selectionChange(event?: MatSelectChange, value?: BooleanValue): void {
    if (event ? event.value : value?.value) {
      // If selected value is true
      if (this.data.data.type_details?.on_true_text_required) {
        this.formGroup.controls.conditionalInputControl.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.conditionalInputControl.clearValidators();
      }
    } else if (event ? event.value === false : value?.value == false) {
      // If selected value is false
      if (this.data.data.type_details?.on_false_text_required) {
        this.formGroup.controls.conditionalInputControl.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.conditionalInputControl.clearValidators();
      }
    }
    if (
      (this.data.data.type_details?.prompt_on_true && this.formGroup.controls['booleanControl'].value == true) ||
      (this.data.data.type_details?.prompt_on_false && this.formGroup.controls['booleanControl'].value == false)
    ) {
      this.formGroup.controls.conditionalInputControl.enable({ emitEvent: false });
    } else {
      this.formGroup.controls.conditionalInputControl.disable({ emitEvent: false });
    }
  }

  // generic methods

  returnValues(): void {
    switch (this.data.dataFormatType) {
      case ValueDefinitionDisplayType.text_area: {
        this.changed.emit({
          id: this.data.id,
          value: this.formGroup.controls.textAreaControl.value,
          error: this.formGroup.controls.textAreaControl.invalid,
        });
        break;
      }
      case ValueDefinitionDisplayType.text_area_validation: {
        this.changed.emit({
          id: this.data.id,
          value: this.formGroup.controls.textAreaWithValidation.value,
          error: this.formGroup.controls.textAreaWithValidation.invalid,
        });
        break;
      }
      case ValueDefinitionDisplayType.text_rich: {
        const value = this.formGroup.controls.editorControl.value;
        // TODO: for syncfusion editor, direct value seems to work
        // if (value.content) {
        //   value = value.content[0].content[0].text;
        // }
        this.changed.emit({ id: this.data.id, value, error: this.formGroup.controls.editorControl.invalid });
        break;
      }
      case ValueDefinitionDisplayType.text_simple: {
        this.changed.emit({
          id: this.data.id,
          value: this.formGroup.controls.textInputFormat.value,
          error: this.formGroup.controls.textInputFormat.invalid,
        });
        break;
      }
      case ValueDefinitionDisplayType.number: {
        this.changed.emit({
          id: this.data.id,
          value: parseFloat(
            String(this.formGroup.controls.numberControl.value ?? '')
              .split(',')
              .join(''),
          ),
          error: this.formGroup.controls.numberControl.invalid,
          unit: this.data.data.unit ? this.data.data.unit : this.data.data.type_details.units,
        });
        break;
      }
      case ValueDefinitionDisplayType.choice:
      case ValueDefinitionDisplayType.choice_radio:
      case ValueDefinitionDisplayType.choice_multiple:
      case ValueDefinitionDisplayType.choice_searchable: {
        let controlValue: string = this.formGroup.controls.selectControl.value;
        if (
          this.data.dataFormatType === ValueDefinitionDisplayType.choice_searchable &&
          !this.data.data.type_details.multi_choices
        ) {
          controlValue = (this.formGroup.controls.selectControl.value as string[])[0];
        }
        this.changed.emit({
          id: this.data.id,
          value: controlValue,
          error: this.formGroup.controls.selectControl.invalid,
        });
        break;
      }
      case ValueDefinitionDisplayType.boolean: {
        this.changed.emit({
          id: this.data.id,
          value: {
            value: this.formGroup.controls.booleanControl.value,
            additional_text: this.formGroup.controls.conditionalInputControl.enabled
              ? this.formGroup.controls.conditionalInputControl.value
              : null,
          },
          error:
            this.formGroup.controls.booleanControl.invalid || this.formGroup.controls.conditionalInputControl.invalid,
        });
        break;
      }
      case ValueDefinitionDisplayType.date: {
        const date: Date | '' = this.formGroup.controls.dateControl.value;
        let value = '';
        if (date) {
          if (this.data.data.type_details?.format === 'MM/DD/YYYY') {
            value = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
          } else if (this.data.data.type_details?.format === 'DD/MM/YYYY') {
            value = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
          } else if (this.data.data.type_details?.format === 'YYYY-MM-DD') {
            value = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
          }
        }
        this.changed.emit({
          id: this.data.id,
          value: value ? value : '',
          error: this.formGroup.controls.dateControl.invalid,
        });
        break;
      }
      case ValueDefinitionDisplayType.file: {
        this.changed.emit({ id: this.data.id, value: this.file, type: 'file' });
        break;
      }
    }
  }

  setControlValues(emitEvent = true): void {
    switch (this.data.dataFormatType) {
      case ValueDefinitionDisplayType.text_area: {
        const validators: ValidatorFn[] = [];
        if (this.data.data.required) {
          validators.push(Validators.required);
        }
        this.data.data.validators?.forEach((validator: Validator) => {
          switch (validator.validator_type) {
            case 'max_length': {
              validators.push(Validators.maxLength(parseInt(String(validator.instructions))));
              break;
            }
            case 'min_length': {
              validators.push(Validators.minLength(parseInt(String(validator.instructions))));
            }
          }
        });
        this.formGroup.controls.textAreaControl.setValidators(validators);
        this.formGroup.controls.textAreaControl.setValue(this.data.data.value);
        break;
      }
      case ValueDefinitionDisplayType.text_area_validation: {
        const data = this.data.data.value;
        const validators: ValidatorFn[] = [];
        if (this.data.data.required) {
          validators.push(Validators.required);
        }
        this.data.data.validators?.forEach((validator: Validator) => {
          switch (validator.validator_type) {
            case 'max_length': {
              this.maxCharacterCount = String(validator.instructions);
              validators.push(Validators.maxLength(parseInt(String(validator.instructions))));
              break;
            }
            case 'min_length': {
              validators.push(Validators.minLength(parseInt(String(validator.instructions))));
              break;
            }
          }
        });
        this.formGroup.controls.textAreaWithValidation.setValidators(validators);
        if (this.data.data.value) {
          this.formGroup.controls.textAreaWithValidation.setValue(data);
        }
        break;
      }
      case ValueDefinitionDisplayType.text_rich: {
        const validators: ValidatorFn[] = [];
        if (this.data.data.required) {
          validators.push(Validators.required);
        }
        this.data.data.validators?.forEach((validator: Validator) => {
          switch (validator.validator_type) {
            case 'max_length': {
              validators.push(Validators.maxLength(parseInt(String(validator.instructions))));
              break;
            }
            case 'min_length': {
              validators.push(Validators.minLength(parseInt(String(validator.instructions))));
              break;
            }
          }
        });
        this.formGroup.controls.editorControl.setValidators(validators);
        this.formGroup.controls.editorControl.setValue(this.data.data.value);
        break;
      }
      case ValueDefinitionDisplayType.text_simple: {
        const validators: ValidatorFn[] = [];
        if (this.data.data.required) {
          validators.push(Validators.required);
        }
        this.data.data.validators?.forEach((validator: Validator) => {
          switch (validator.validator_type) {
            case 'max_length': {
              validators.push(Validators.maxLength(parseInt(String(validator.instructions))));
              break;
            }
            case 'min_length': {
              validators.push(Validators.minLength(parseInt(String(validator.instructions))));
              break;
            }
          }
        });
        this.formGroup.controls.textInputFormat.setValidators(validators);
        this.formGroup.controls.textInputFormat.setValue(this.data.data.value);
        break;
      }
      case ValueDefinitionDisplayType.number:
      case ValueDefinitionDisplayType.calculated: {
        const validators: ValidatorFn[] = [];
        if (this.data.data.required) {
          validators.push(Validators.required);
        }
        this.data.data.validators?.forEach((validator: Validator) => {
          switch (validator.validator_type) {
            case 'max_length': {
              validators.push(Validators.maxLength(parseFloat(String(validator.instructions))));
              break;
            }
            case 'min_length': {
              validators.push(Validators.minLength(parseFloat(String(validator.instructions))));
              break;
            }
            case 'min_val': {
              validators.push(Validators.min(parseFloat(String(validator.instructions))));
              break;
            }
            case 'max_val': {
              validators.push(Validators.max(parseFloat(String(validator.instructions))));
            }
          }
        });
        validators.push(Validators.pattern('[-]?(?:[0-9]+,)*[0-9]+(?:.[0-9]+)?$'));
        const code = this.data.data.unit ? this.data.data.unit : this.data.data.type_details.units;
        this.symbol = this.units.find((x) => x.code === code)?.symbol;
        this.formGroup.controls.numberControl.setValidators(validators);
        this.formGroup.controls.numberControl.setValue(this.data.data.value);
        break;
      }
      case ValueDefinitionDisplayType.choice:
      case ValueDefinitionDisplayType.choice_radio: {
        this.formGroup.controls.selectControl.setValue(this.data.data.value, { emitEvent });
        break;
      }

      case ValueDefinitionDisplayType.choice_multiple:
      case ValueDefinitionDisplayType.choice_searchable: {
        if (!this.data.data.value) {
          this.data.data.value = [];
        } else if (typeof this.data.data.value === 'string') {
          this.data.data.value = [this.data.data.value];
        }
        this.formGroup.controls.selectControl.setValue(this.data.data.value, { emitEvent });
        break;
      }
      case ValueDefinitionDisplayType.boolean: {
        const validators: ValidatorFn[] = [];
        if (this.data.data.required) {
          validators.push(Validators.required);
        }
        this.formGroup.controls.booleanControl.setValidators(validators);
        this.formGroup.controls.booleanControl.setValue(this.data.data.value?.value, { emitEvent });
        this.formGroup.controls.conditionalInputControl.setValue(this.data.data.value?.additional_text, {
          emitEvent,
        });
        this.selectionChange(undefined, this.data.data.value as BooleanValue);
        break;
      }
      case ValueDefinitionDisplayType.date: {
        const validators: ValidatorFn[] = [];
        if (this.data.data.required) {
          this.dateRequiredError = true;
          validators.push(Validators.required);
        }
        this.data.data.validators?.forEach((validator: Validator) => {
          switch (validator.validator_type) {
            case 'max_date_range': {
              validators.push(DateValidators.maxDateRange(String(validator.instructions)));
              break;
            }
            case 'min_date_range': {
              validators.push(DateValidators.minDateRange(String(validator.instructions)));
              break;
            }
          }
        });
        validators.push(this.validateDate);
        this.formGroup.controls.dateControl.setValidators(validators);
        PICK_FORMATS.display.dateInput = this.data.data.type_details?.format;
        PICK_FORMATS.parse.dateInput = this.data.data.type_details?.format;
        let date: Date | '' = new Date();
        if (this.data.data.value) {
          const str = this.data.data.value.toString().split('/');
          if (PICK_FORMATS.parse.dateInput === 'DD/MM/YYYY') {
            date = new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]));
          } else if (PICK_FORMATS.parse.dateInput === 'MM/DD/YYYY') {
            date = new Date(Number(str[2]), Number(str[0]) - 1, Number(str[1]));
          } else if (PICK_FORMATS.parse.dateInput === 'YYYY-MM-DD') {
            const str = this.data.data.value.toString().split('-');
            date = new Date(Number(str[0]), Number(str[1]) - 1, Number(str[2]));
          }
        } else {
          date = '';
        }
        this.formGroup.controls.dateControl.setValue(date, { emitEvent });
        break;
      }
      case ValueDefinitionDisplayType.file: {
        if (this.data.data.required) {
          this.formGroup.controls.fileControl.setValidators([Validators.required]);
        }
        this.formGroup.controls.fileControl.setValue(this.data.data.value);
      }
    }
    this.hasError.emit({ id: this.data.id, valid: this.formGroup.valid });
  }

  updateFormControls(value: Value, emitEvent = true): void {
    this.data.data = { ...value };
    switch (this.data.dataFormatType) {
      case ValueDefinitionDisplayType.text_area:
        this.formGroup.controls.textAreaControl.setValue(value.value, { emitEvent });
        break;
      case ValueDefinitionDisplayType.text_area_validation:
        this.formGroup.controls.textAreaWithValidation.setValue(value.value, { emitEvent });
        break;
      case ValueDefinitionDisplayType.text_rich:
        this.formGroup.controls.editorControl.setValue(value.value, { emitEvent });
        break;
      case ValueDefinitionDisplayType.text_simple:
        this.formGroup.controls.textInputFormat.setValue(value.value, { emitEvent });
        break;
      case ValueDefinitionDisplayType.number:
        this.formGroup.controls.numberControl.setValue(value.value, { emitEvent });
        break;
      case ValueDefinitionDisplayType.choice:
      case ValueDefinitionDisplayType.choice_radio:
        this.formGroup.controls.selectControl.setValue(value.value, { emitEvent });
        break;
      case ValueDefinitionDisplayType.choice_multiple:
      case ValueDefinitionDisplayType.choice_searchable:
        let newValue = value.value;
        if (!value.value) {
          newValue = [];
        } else if (typeof value.value === 'string') {
          newValue = [value.value];
        }
        this.formGroup.controls.selectControl.setValue(newValue, { emitEvent });
        this.returnValues();
        break;
      case ValueDefinitionDisplayType.boolean:
        this.formGroup.controls.booleanControl.setValue(value.value?.value, { emitEvent });
        this.formGroup.controls.conditionalInputControl.setValue(value.value?.additional_text, {
          emitEvent,
        });
        if (
          (this.data.data.type_details?.prompt_on_true && this.formGroup.controls['booleanControl'].value == true) ||
          (this.data.data.type_details?.prompt_on_false && this.formGroup.controls['booleanControl'].value == false)
        ) {
          this.formGroup.controls.conditionalInputControl.enable({ emitEvent });
        } else {
          this.formGroup.controls.conditionalInputControl.disable({ emitEvent });
        }
        break;
      case ValueDefinitionDisplayType.date:
        this.data.data.value = value.value;
        this.setControlValues(emitEvent);
        break;
    }
  }

  deleteValue(): void {
    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          warningMsg: this.translateService.instant(
            'Are you sure you want to reset the value of this field? This cannot be undone',
          ),
          primaryBtn: this.translateService.instant('Reset'),
        },
      })
      .afterClosed()
      .subscribe((result?: DialogResult) => {
        if (result && result.status === Status.CONFIRMED) {
          this.delete.emit({ value_id: this.data.data.id });
        }
      });
  }
}
