import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Presentation,
  TableColumn,
  TableHorizontalPaginatorConfiguration,
  TableActionMenuItemEvent,
  TableGridExpandedRowConfig,
  TablePageEvent,
  TableRowOrderEvent,
  TableSortEvent,
} from '../../models';
import { LazyLoadEvent, SortEvent } from 'primeng/api';
import { TableRowReorderEvent } from 'primeng/table';
import { TableRowSelectEvent } from 'primeng/table/table.interface';

@Component({
  selector: 'lib-table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.scss'],
})
export class TableGridComponent<T> {
  @Input({ required: true }) values: T[] = [];
  @Input({ required: true }) columns: TableColumn<any>[] = [];
  @Input() isExpandable = false;
  @Input() isResizable = true;
  @Input() isRowReorderable = false;
  @Input() isCustomSort = true;
  @Input() expandedRowConfig?: TableGridExpandedRowConfig; // Can't be used at same time as rowGroupingProperties
  @Input() defaultEmptyValue = '';
  @Input() uniquenessRowProperty = 'id';
  @Input() withHeader: boolean = true;
  @Input() tableTitle?: string;
  @Input() withGridLines: boolean = true;
  @Input() actionMenuItems?: TableActionMenuItemEvent<T>[];
  @Input() isPaginable: boolean = true;
  @Input() totalItemCount?: number;
  @Input() isWrapping: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() rowGroupingProperties?: string[]; // Can't be used at same time as expandedRowConfig
  @Input() horizontalPaginationConfig?: TableHorizontalPaginatorConfiguration;
  @Input() pageSize?: number;

  @Output() sortChanged: EventEmitter<TableSortEvent> = new EventEmitter<TableSortEvent>();
  @Output() rowOrderChanged: EventEmitter<TableRowOrderEvent> = new EventEmitter<TableRowOrderEvent>();
  @Output() pageChanged: EventEmitter<TablePageEvent> = new EventEmitter<TablePageEvent>();
  @Output() rowSelected: EventEmitter<T> = new EventEmitter<T>();
  @Output() horizontalPageChanged: EventEmitter<number> = new EventEmitter<number>();

  readonly ePresentation = Presentation;

  public sortColumn(event: SortEvent): void {
    if (!this.isPaginable && this.isCustomSort && event.field && event.order) {
      this.emitSortChanged(event.field, event.order);
    }
  }

  public sortPaginatedColumn(event: LazyLoadEvent): void {
    if (this.isPaginable && this.isCustomSort && event.sortField && event.sortOrder) {
      this.emitSortChanged(event.sortField, event.sortOrder);
    }
  }

  public reorderRow(event: TableRowReorderEvent): void {
    this.rowOrderChanged.emit({ previousIndex: Number(event.dragIndex), newIndex: Number(event.dropIndex) });
  }

  public clickRow(event: TableRowSelectEvent): void {
    this.rowSelected.emit(event.data as T);
  }

  private emitSortChanged(field: string, sortOrder: number) {
    this.sortChanged.emit({ field, direction: sortOrder > 0 ? 'asc' : 'desc' });
  }
}
