import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateUtils } from '../classes/DateUtils/date-utils';

export class DateValidators {
  static realisticDate = (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value) {
      const date = new Date(String(control.value));
      const year = date.getFullYear();

      if (!+date || year < 1000 || year > 9999) {
        return { invalidDate: true };
      }
    }

    return null;
  };

  static compareDates(startDateFieldName: string = 'start', endDateFieldName: string = 'end'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let response = null;
      const start = control.get(startDateFieldName);
      const end = control.get(endDateFieldName);
      if (start?.value && end?.value) {
        const startValue = DateUtils.dateParse(start.value as string);
        const endValue = DateUtils.dateParse(end.value as string);

        response = startValue.getTime() > endValue.getTime() ? { RangeError: true } : null;
      }
      return response;
    };
  }

  static maxDateRange(maxDate: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !maxDate) {
        return null;
      }

      const inputDate = control.value;
      const max = DateUtils.dateParse(maxDate);

      if (inputDate > max) {
        return { maxDateRange: { maxDate } };
      }
      return null;
    };
  }

  static minDateRange(minDate: string | null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value || !minDate) {
        return null;
      }
      const inputDate = control.value;
      const min = DateUtils.dateParse(minDate);
      if (inputDate < min) {
        return { minDateRange: { minDate } };
      }
      return null;
    };
  }
}
