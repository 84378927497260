export {
  ExportMetricPayload,
  ExportCategory,
  ExportFormat,
  ExportMetricColumns,
  ExportTableFormat,
  ExportMetricsCurrentViewPayload,
  ExportMetricsStructureCurrentViewPayload,
  SearchIndicatorPayload,
  ExportMetricAuditTrailPayload,
};

interface ExportMetricPayload {
  file_name: string;
  output_format: ExportFormat;
  columns?: string[];
  include_consolidated?: boolean;
  skip_frequencies_no_values?: boolean;
  skip_metric_no_values?: boolean;
  skip_business_unit_no_values?: boolean;
  frequencies?: string[];
  business_unit_ids?: string[];
  table_format: ExportTableFormat | null;
  skip_reset_values?: boolean;
}

interface ExportMetricsCurrentViewPayload extends ExportMetricPayload {
  search_indicator_v1_request?: SearchIndicatorPayload;
}

interface ExportMetricAuditTrailPayload {
  file_name: string;
  output_format: ExportFormat;
  frequency_codes?: string[];
  business_unit_ids?: string[];
  indicator_ids?: string[];
}

interface ExportMetricsStructurePayload {
  file_name: string;
  output_format: ExportFormat;
  columns?: string[];
}

interface ExportMetricsStructureCurrentViewPayload extends ExportMetricsStructurePayload {
  search_indicator_v1_request?: SearchIndicatorPayload;
}

export interface ListIndicatorsParams {
  load_related_equivalent?: boolean;
  load_related_core_equivalent?: boolean;
  load_framework?: boolean;
  load_tags?: boolean;
  load_industries?: boolean;
  load_topics?: boolean;
  load_standard_codes?: boolean;
  load_value_group_sets?: boolean;
  load_value_groups?: boolean;
  load_values?: boolean;
  frequencies?: string[];
}

export interface IndicatorSearchFilterCriteria {
  tags?: string[];
  topics?: string[];
  active?: boolean | null;
  input?: boolean;
  industries?: string[];
  standard_code_ids?: string[];
  min_date?: string;
  max_date?: string;
  framework_ids?: string[];
  taxonomy_ids?: string[];
  metric_ids?: string[];
  metric_group_ids?: string[];
  core_metric_ids?: string[];
  categories?: string[];
  types?: string[];
  topic_categories?: string[];
  topic_groups?: string[];
  customFilterkey?: string;
}

interface SearchIndicatorPayload extends ListIndicatorsParams {
  keywords?: string | null;
  sort?: [{ field?: string; direction?: string }];
  filters?: IndicatorSearchFilterCriteria;
  business_unit_id?: string;
  fiscal_year?: string | number;
  excludes?: {
    framework_ids?: string[];
    categories?: string[];
  };
}

enum ExportCategory {
  DATA_REQUEST = 'data_request',
  METRIC_GROUP = 'metric_group',
  METRIC_LIBRARY = 'metric_library',
}

enum ExportFormat {
  CSV = 'csv',
  EXCEL = 'excel',
}

enum ExportMetricColumns {
  CODE = 'metric code',
  DESCRIPTION = 'metric description',
  TOPIC_CATEGORIES = 'topic categories',
  TOPIC_GROUPS = 'topic groups',
  TOPICS = 'topics',
  INDUSTRIES = 'industries',
  STANDARD_CODES = 'standard codes',
  ASSOCIATED_METRICS = 'associated metrics',
  COMPANY = 'company',
  SOURCE = 'source',
  SOURCE_TYPE = 'source type',
  PERIOD = 'fiscal year',
  VGSET_STATUS = 'status',
  VGSET_CONTEXT = 'metric value context',
  TYPE = 'type',
  REPEATABLE = 'part of repeatable group/table',
  POSITION = 'metric field position',
  REQUIRED = 'mandatory field',
  UNIT = 'unit of measure',
  LABEL = 'label',
  SHORT_LABEL = 'short label',
  VALUE = 'value',
  TAGS = 'tags',
  TAXONOMY = 'framework taxonomy',
  DEACTIVATED = 'metric is deactivated',
}

enum ExportTableFormat {
  SUBTABLE = 'subtable',
  TABLEAU = 'tableau',
}

export const DEFAULT_METRIC_COLUMNS = [
  ExportMetricColumns.CODE,
  ExportMetricColumns.DESCRIPTION,
  ExportMetricColumns.LABEL,
  ExportMetricColumns.TYPE,
  ExportMetricColumns.SOURCE,
  ExportMetricColumns.SOURCE_TYPE,
  ExportMetricColumns.PERIOD,
  ExportMetricColumns.VGSET_STATUS,
  ExportMetricColumns.VGSET_CONTEXT,
  ExportMetricColumns.VALUE,
  ExportMetricColumns.UNIT,
  ExportMetricColumns.REPEATABLE,
  ExportMetricColumns.REQUIRED,
  ExportMetricColumns.POSITION,
  ExportMetricColumns.STANDARD_CODES,
  ExportMetricColumns.ASSOCIATED_METRICS,
  ExportMetricColumns.TAXONOMY,
  ExportMetricColumns.TAGS,
  ExportMetricColumns.TOPIC_CATEGORIES,
  ExportMetricColumns.TOPIC_GROUPS,
  ExportMetricColumns.TOPICS,
];

export const DEFAULT_METRIC_STRUCTURE_COLUMNS = [
  ExportMetricColumns.CODE,
  ExportMetricColumns.DESCRIPTION,
  ExportMetricColumns.LABEL,
  ExportMetricColumns.TYPE,
  ExportMetricColumns.UNIT,
  ExportMetricColumns.REPEATABLE,
  ExportMetricColumns.REQUIRED,
  ExportMetricColumns.POSITION,
  ExportMetricColumns.STANDARD_CODES,
  ExportMetricColumns.ASSOCIATED_METRICS,
  ExportMetricColumns.TAXONOMY,
  ExportMetricColumns.TAGS,
  ExportMetricColumns.TOPIC_CATEGORIES,
  ExportMetricColumns.TOPIC_GROUPS,
  ExportMetricColumns.TOPICS,
];

export const DEFAULT_METRIC_COLUMNS_REF_METRICS_V2 = [
  ExportMetricColumns.CODE,
  ExportMetricColumns.DESCRIPTION,
  ExportMetricColumns.LABEL,
  ExportMetricColumns.TYPE,
  ExportMetricColumns.SOURCE,
  ExportMetricColumns.SOURCE_TYPE,
  ExportMetricColumns.PERIOD,
  ExportMetricColumns.VGSET_STATUS,
  ExportMetricColumns.VGSET_CONTEXT,
  ExportMetricColumns.VALUE,
  ExportMetricColumns.UNIT,
  ExportMetricColumns.REPEATABLE,
  ExportMetricColumns.REQUIRED,
  ExportMetricColumns.POSITION,
  ExportMetricColumns.TAXONOMY,
  ExportMetricColumns.TAGS,
  ExportMetricColumns.TOPIC_CATEGORIES,
  ExportMetricColumns.TOPIC_GROUPS,
  ExportMetricColumns.TOPICS,
];

export const DEFAULT_METRIC_STRUCTURE_COLUMNS_REF_METRICS_V2 = [
  ExportMetricColumns.CODE,
  ExportMetricColumns.DESCRIPTION,
  ExportMetricColumns.LABEL,
  ExportMetricColumns.TYPE,
  ExportMetricColumns.UNIT,
  ExportMetricColumns.REPEATABLE,
  ExportMetricColumns.REQUIRED,
  ExportMetricColumns.POSITION,
  ExportMetricColumns.TAXONOMY,
  ExportMetricColumns.TAGS,
  ExportMetricColumns.TOPIC_CATEGORIES,
  ExportMetricColumns.TOPIC_GROUPS,
  ExportMetricColumns.TOPICS,
];

export enum ExportType {
  DATA = 'data',
  METRIC_STRUCTURE = 'metric_structure',
}
