import { DOCUMENT } from '@angular/common';
import { HttpBackend, HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AbstractApiService } from './abstract-api.service';
import { APIConfig, AppConfig, ApplicationApiDefinition, SupportedEnvironment } from '../../../models';
import { ContextService } from '../context/context.service';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors/error-interceptor/bypass-error-constant';

@Injectable({ providedIn: 'root' })
export class ApiService extends AbstractApiService {
  apiConfig: APIConfig;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    httpBackend: HttpBackend,
    config: AppConfig,
    private contextService: ContextService,
  ) {
    super(httpBackend);
    this.apiConfig = config.apiConfig;
  }

  getServicePath(apiName: keyof ApplicationApiDefinition): string {
    if (this.apiConfig.minivisto) {
      return `:${this.apiConfig.minivistoPort}${this.apiConfig.apis[apiName].api}`;
    } else {
      return this.apiConfig.apis[apiName].api;
    }
  }

  getProtocol(): string {
    if (this.document.location.hostname === 'localhost') {
      return this.apiConfig.minivisto && !this.apiConfig.minivistoSSL ? 'http://' : 'https://';
    }
    return this.document.location.protocol + '//';
  }

  getBaseUrl(highchartExport: boolean = false): string {
    return this.getProtocol() + this.getApiDomain(highchartExport);
  }

  getWebsocketBaseUrl(): string {
    if (this.document.location.protocol == 'https:') {
      return 'wss://' + this.getApiDomain();
    } else {
      return 'ws://' + this.getApiDomain();
    }
  }

  setApiDomainPrefix(apiDomain: string, highchartExport: boolean = false): string {
    return `${highchartExport ? this.HIGHCHARTS_EXPORT_PREFIX : this.API_PREFIX}.${apiDomain}`;
  }

  getApiDomain(highchartExport: boolean = false): string {
    const host: string = this.document.location.host.toLowerCase();
    let apiDomain: string = host;
    switch (this.contextService.environment) {
      case SupportedEnvironment.experiment:
        apiDomain = host.replace(SupportedEnvironment.experiment, SupportedEnvironment.dev);
        break;
      case SupportedEnvironment.localhost:
        if (this.apiConfig.minivisto) {
          apiDomain =
            this.document.location.port === '4200'
              ? `${SupportedEnvironment.minivisto}.${this.apiConfig.localClientCode}.novisto.local`
              : `${SupportedEnvironment.minivisto}.core.novisto.local`;
        } else {
          apiDomain =
            this.document.location.port === '4200'
              ? `${SupportedEnvironment.dev}.${this.apiConfig.localClientCode}.novisto.net`
              : `${SupportedEnvironment.dev}.core.novisto.net`;
        }
        break;
      default:
        break;
    }
    return this.setApiDomainPrefix(apiDomain, highchartExport);
  }

  getUrl(path: string, highchartExport: boolean = false): string {
    return this.getBaseUrl(highchartExport) + path;
  }

  get<T = any>(path: string, options?: object, bypassInterceptors?: boolean): Observable<T> {
    const url = this.getUrl(path);
    const http: HttpClient = bypassInterceptors ? this.httpBackendClient : this.http;
    return options ? http.get<T>(url, options) : this.http.get<T>(url);
  }

  post<T = any>(
    path: string,
    payload?: any,
    params?: HttpParams,
    bypassInterceptors?: boolean,
    context?: HttpContext,
    headers?: HttpHeaders,
  ): Observable<T> {
    const http: HttpClient = bypassInterceptors ? this.httpBackendClient : this.http;
    return http.post<T>(this.getUrl(path), payload ?? null, {
      params,
      headers: headers ?? this.defaultHeaders.headers,
      context,
    });
  }

  upload<T = any>(
    path: string,
    payload: FormData,
    reportProgress: boolean = false,
    bypassInterceptors: boolean = false,
  ): Observable<T> {
    const context = new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, bypassInterceptors);
    let options = {};
    if (reportProgress) {
      options = { reportProgress: true, observe: 'events' };
    }
    return this.http.post<T>(this.getUrl(path), payload, { ...options, context });
  }

  put<T = any>(
    path: string,
    payload: any,
    params?: HttpParams,
    bypassInterceptors?: boolean,
    context?: HttpContext,
  ): Observable<T> {
    const http: HttpClient = bypassInterceptors ? this.httpBackendClient : this.http;
    return http.put<T>(this.getUrl(path), payload, {
      params,
      headers: this.defaultHeaders.headers,
      context,
    });
  }

  patch<T = any>(path: string, payload: any, bypassInterceptors?: boolean): Observable<T> {
    const http: HttpClient = bypassInterceptors ? this.httpBackendClient : this.http;
    return http.patch<T>(this.getUrl(path), payload);
  }

  delete<T = any>(
    path: string,
    payload?: any,
    params?: HttpParams,
    bypassInterceptors?: boolean,
    context?: HttpContext,
  ): Observable<T> {
    const http: HttpClient = bypassInterceptors ? this.httpBackendClient : this.http;
    return http.delete<T>(this.getUrl(path), {
      body: payload,
      headers: this.defaultHeaders.headers,
      params,
      context,
    });
  }
}
