import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Metric, MetricCategory, ValueDefinition, ValueDefinitionGroup } from '../../../../models';
import { MetricStructureSelectable, ValueDefinitionFormat } from '../../../models';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { MetricApiService } from '../../../../services/types';
import { ValueDefinitionUtils } from '../../../classes/ValueDefinitionUtils/value-definition-utils';
import { MetricUtils } from '../../../../classes';

@Component({
  selector: 'lib-metric-structure-group-form-details',
  templateUrl: './metric-structure-group-form-details.component.html',
  styleUrls: ['./metric-structure-group-form-details.component.scss'],
})
export class MetricStructureGroupFormDetailsComponent implements OnInit {
  @Input({ required: true }) valueDefinitionGroup!: ValueDefinitionGroup;
  @Input({ required: true }) index!: number;
  @Input({ required: true }) metric!: Metric;
  @Input() canMoveUp: boolean = false;
  @Input() canMoveDown: boolean = false;
  @Input() overlayDisabled: boolean = false;
  @Input() isCoreValueDefinitionGroupOfReferenceV2Metric: boolean = false;
  @Input() selectedItemId: string = '';
  @Input() indexOffSet: number = 0;

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;
  isAdmin: boolean = this.metricStructureService.isAdmin;
  isRefV2MetricInPlatform: boolean = false;

  constructor(
    private metricsService: MetricApiService,
    private metricStructureService: MetricStructureStateService,
  ) {}

  ngOnInit() {
    this.isRefV2MetricInPlatform = MetricUtils.isRefV2Metric(this.metric) && !this.isAdmin;
  }

  public isElementTriggered(element: ValueDefinition | ValueDefinitionGroup): boolean {
    return element.conditional_triggers ? element.conditional_triggers.length > 0 : false;
  }

  public getValueDefinitionFormat(valueDefinition: ValueDefinition): ValueDefinitionFormat {
    return ValueDefinitionUtils.getValueDefinitionFormat(valueDefinition);
  }

  public setSelectedItem(item?: MetricStructureSelectable): void {
    this.metricStructureService.updateSelectedItem(item);
  }

  public moveGroupDown(event: MouseEvent): void {
    const currentDisplayPosition = this.index + 1 + this.indexOffSet;
    event.stopPropagation();
    this.updateFieldStatus(true);
    if (this.canMoveDown) {
      this.metricsService
        .moveGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id, currentDisplayPosition + 1)
        .pipe(finalize(() => this.updateFieldStatus(false)))
        .subscribe((response) => {
          this.updateMetricAfterPositionChange(
            response.data,
            response.data.value_definition_groups![currentDisplayPosition],
          );
        });
    }
  }

  public moveGroupUp(event: MouseEvent): void {
    const currentDisplayPosition: number = this.index + 1 + this.indexOffSet;
    event.stopPropagation();
    this.updateFieldStatus(true);
    if (this.canMoveUp) {
      this.metricsService
        .moveGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id, currentDisplayPosition - 1)
        .pipe(finalize(() => this.updateFieldStatus(false)))
        .subscribe((response) => {
          this.updateMetricAfterPositionChange(
            response.data,
            response.data.value_definition_groups![currentDisplayPosition - 2],
          );
        });
    }
  }

  public deleteGroup(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.valueDefinitionGroup.value_definitions?.length) {
      this.updateFieldStatus(true);
      this.metricsService
        .deleteGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id)
        .pipe(finalize(() => this.updateFieldStatus(false)))
        .subscribe((response) => {
          if (this.selectedItemId === this.valueDefinitionGroup.id) {
            this.setSelectedItem(undefined);
          }
          this.metricStructureService.updateMetric(response.data);
        });
    }
  }

  private updateFieldStatus(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }

  private updateMetricAfterPositionChange(metric: Metric, vdg: ValueDefinitionGroup): void {
    this.metricStructureService.updateMetric(metric);
    this.setSelectedItem(vdg);
  }
}
