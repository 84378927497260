import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TagType } from '../../../models';

@Component({
  selector: 'lib-status-accordion',
  templateUrl: './status-accordion.component.html',
  styleUrls: ['./status-accordion.component.scss'],
})
export class StatusAccordionComponent implements OnInit, OnChanges {
  @Input() id: string = '';
  @Input() title: string = '';
  @Input() status: string = '';
  @Input() statusType: TagType = TagType.GREY;
  @Input() isExpanded: boolean = false;

  @Output() panelToggle: EventEmitter<string> = new EventEmitter();

  protected isPanelExpanded: boolean = false;

  ngOnInit() {
    this.isPanelExpanded = this.isExpanded;
  }

  togglePanel() {
    this.isPanelExpanded = !this.isPanelExpanded;
    this.panelToggle.emit(this.id);
  }

  ngOnChanges() {
    this.isPanelExpanded = this.isExpanded;
  }

  protected readonly TagType = TagType;
}
