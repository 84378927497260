import { Component, DoCheck, ElementRef } from '@angular/core';

@Component({
  selector: 'lib-vertical-resizable-content',
  templateUrl: './vertical-resizable-content.component.html',
  styleUrls: ['./vertical-resizable-content.component.scss'],
})
export class VerticalResizableContentComponent implements DoCheck {
  private readonly LINE_PADDING: number = 35;
  private readonly LINE_HEIGHT: number = 13;

  public lineClamp: number = 1;

  private element?: any;

  constructor(protected readonly el: ElementRef) {
    this.element = this.el.nativeElement;
  }

  public ngDoCheck(): void {
    const height = this.element?.offsetHeight;
    const clamp = Math.floor((height - this.LINE_PADDING) / this.LINE_HEIGHT);
    this.lineClamp = clamp > 0 ? clamp : 1;
  }
}
