import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

type KeysFrom<T, U> = { [K in keyof U]: K extends keyof T ? U[K] : never };

export interface DynamicFormFieldConfig {
  validators?: ValidatorFn[];
}

export type DynamicFormConfig<T> = Partial<{
  [controlName in keyof T]: DynamicFormFieldConfig;
}>;

export type DynamicFormControls<T> = Partial<{
  [controlName in keyof T]: UntypedFormControl;
}>;

export class DynamicForm<T> extends UntypedFormGroup {
  private _propertiesControls: DynamicFormControls<T> = {};
  readonly propertiesControls: DynamicFormControls<T> = this._propertiesControls;

  constructor(
    private dynamicFormConfig: DynamicFormConfig<T>,
    private dynamicFormDefault?: any,
    private readonly isFormDisabled = false,
    private readonly enabledControls: string[] = [],
    private readonly alwaysDisabledControls: (keyof T)[] = [],
    private readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
  ) {
    super({});
    this.initializeControls();
  }

  toModel<M extends KeysFrom<T, M>>(): M {
    const model: Partial<M> = {};
    Object.keys(this.dynamicFormConfig).forEach((key) => {
      model[key as keyof M] = this.propertiesControls[key as keyof T]?.value;
    });
    return model as M;
  }

  private initializeControls() {
    const formKeys: (keyof T)[] = Object.keys(this.dynamicFormConfig) as (keyof T)[];
    formKeys.forEach((key) => {
      this.addControl(
        String(key),
        this.fb.control(
          {
            value: this.dynamicFormDefault ? this.dynamicFormDefault[key] : null,
            disabled: this.alwaysDisabledControls.includes(key)
              ? true
              : this.isFormDisabled
                ? !this.enabledControls.includes(String(key))
                : false,
          },
          this.dynamicFormConfig[key]?.validators,
        ),
      );
      this._propertiesControls[key] = this.get(String(key)) as UntypedFormControl;
    });
  }
}
