<div
  *ngIf="control"
  class="formfield"
  data-testid="textAreaFormField"
  [ngClass]="{ required: required, disabled: control.disabled }"
>
  <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
  <mat-form-field class="w-100 superscript-hint" floatLabel="auto">
    <span *ngIf="prefix" class="color-grey-800 mr-1" matPrefix>{{ prefix }}</span>
    <textarea
      [rows]="rows"
      matInput
      [formControl]="control"
      class="color-grey-900"
      [cdkTextareaAutosize]="cdkTextareaAutosize"
      [cdkAutosizeMinRows]="rows"
      [cdkAutosizeMaxRows]="cdkAutosizeMaxRows"
      [id]="_inputId"
      [placeholder]="placeholder"
      [attr.aria-label]="_inputId"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keyup)="updateValue($event)"
      #focusElement
      libFocusState
      libStripNonPrintableCharacters
    ></textarea>
    <mat-hint *ngIf="hint || maxLength" class="fx-row justify-space-between w-100">
      <span *ngIf="hint">{{ hint }}</span>
      <span *ngIf="maxLength"> {{ control.value?.length ?? 0 }}/{{ maxLength }}</span>
    </mat-hint>
    <lib-spinner *ngIf="control.status === 'PENDING'" [diameter]="16" matSuffix></lib-spinner>
    <mat-error *ngFor="let error of control.errors | errorKeys">
      {{ errorMessages[error] }}
      {{
        error === "maxlength" && maxLength
          ? ("({length}/{maxLength})" | translate: { length: control.value?.length ?? 0, maxLength })
          : ""
      }}
    </mat-error>
  </mat-form-field>
</div>
