import { Pipe, PipeTransform } from '@angular/core';
import { HtmlToStringService } from '../../services/common';

@Pipe({
  name: 'htmlToString',
})
export class HtmlToStringPipe implements PipeTransform {
  constructor(private readonly htmlToStringService: HtmlToStringService) {}

  transform(value: string, length: number = 1000): string | undefined {
    return this.htmlToStringService.convertTipRichTextToPlain(value, length);
  }
}
