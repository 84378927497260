import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Icon } from '../../icons/icons.model';
import {
  ActionItem,
  ApiResponse,
  Benchmark,
  Collection,
  Company,
  copyableReportTypes,
  DataRequest,
  DataRequestSource,
  DataRequestStatus,
  Dataset,
  Doc,
  FiscalYear,
  Framework,
  Indicator,
  IntegrationSetting,
  ItemType,
  Metric,
  Org,
  Permission,
  Platforms,
  Report,
  ReportCategoryType,
  ReportTemplate,
  ResourceType,
  SearchOptions,
  SelectionSet,
  SelectionSetItem,
  Source,
  StandardCodes,
  Tag,
  UnitGroup,
  UnitGroupItem,
  User,
  UserRoles,
} from '../../models';
import { AdminSearchService } from '../../services/admin';
import { ClientSearchService, ClientUsersService } from '../../services/client';
import {
  AuthService,
  CacheService,
  ContextService,
  FiscalYearsUtilService,
  FullNameService,
  TranslateService,
} from '../../services/common';
import { ApiService } from '../../services/common/api/api.service';
import { BaseService } from '../../services/common/base/base.service';
import { IntegrationsLabel, ReportsReportCategories, UserRoleLabel } from '../../translations';
import { SearchResultsActionItem } from '../models';
import { ActionItemUtils } from '../../classes';

type SearchActionItemTypes =
  | Doc
  | Org
  | Metric
  | Indicator
  | Report
  | ReportTemplate
  | Benchmark
  | Dataset
  | SelectionSet
  | SelectionSetItem
  | UnitGroup
  | UnitGroupItem
  | Tag
  | Framework
  | StandardCodes
  | User
  | Company
  | DataRequest
  | Source
  | DataRequestSource
  | IntegrationSetting;

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  pageSize: number;
  PURPLE: string = 'purple';
  BLUE: string = 'blue';
  RED: string = 'red';

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
    private authService: AuthService,
    private contextService: ContextService,
    private cacheService: CacheService,
    private translateService: TranslateService,
    private clientSearchService: ClientSearchService,
    private adminSearchService: AdminSearchService,
    private usersService: ClientUsersService,
    private fullNameService: FullNameService,
    private fiscalYearsUtilService: FiscalYearsUtilService,
  ) {
    this.pageSize = this.apiService.apiConfig.pageSize;
  }

  getFileFormatIcon(format: string) {
    const file_format = format.length >= 3 ? format.substr(0, 3) : 'web';
    return ['doc', 'xls', 'ppt', 'pdf'].indexOf(file_format) < 0 ? 'web' : file_format;
  }

  getColor(item: ActionItem, maxColorNum: number): number {
    let ret: number = 0;
    for (let index = 0; index < item.id.length; index++) {
      ret += item.id.charCodeAt(index);
    }
    return 1 + (ret % maxColorNum);
  }

  getPattern(item: ActionItem): number {
    const created: string = item.created ? item.created.toString() : this.baseService.newGuid().toString();
    let ret: number = 0;
    for (let index = 0; index < created.length; index++) {
      ret += created.charCodeAt(index);
    }
    return 1 + this.luhn(ret);
  }

  generateColorAndPattern(item: ActionItem, color: string, maxColorNum: number): string {
    return `${this.getPattern(item)}:${color}-${this.getColor(item, maxColorNum)}`;
  }

  luhn(val: number): number {
    if (val < 10) {
      return val;
    }
    const strVal: string = val.toString();
    let ret: number = 0;
    for (let index = 0; index < strVal.length; index++) {
      const char = strVal.charAt(index);
      ret += Number(char);
    }
    return this.luhn(ret);
  }

  private getEntityActionItem<T>(
    id: string,
    title: string,
    item_type: string,
    created?: string | Date,
    updated?: string | Date,
    item?: T,
    start?: Date | string,
  ): ActionItem<T> {
    return {
      id,
      title,
      item_type,
      created,
      updated,
      item,
      ...(start && { start: typeof start === 'string' ? new Date(start) : start }),
    };
  }

  entity2ActionItem(item: unknown, type?: string): ActionItem<SearchActionItemTypes> {
    switch (type) {
      case ItemType.docs_doc:
      case ItemType.metric_data_exports:
        const doc = <Doc>item;
        const docActionItem: ActionItem<Doc> = {
          ...this.getEntityActionItem<Doc>(doc.id, doc.name, type, doc.created, doc.updated, doc, doc.start),
          icon: this.getFileFormatIcon(doc.format),
          subtitle: doc.category,
          children: [
            {
              id: SearchResultsActionItem.preview,
              title: this.translateService.instant('Preview'),
              icon: 'show',
              permissions: [Permission.DOCUMENTS_VIEW],
            },
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('See details'),
              icon: 'info',
              permissions: [Permission.DOCUMENTS_VIEW],
            },
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
              permissions: [Permission.DOCUMENTS_EDIT],
            },
            {
              id: SearchResultsActionItem.separator,
              title: '',
              permissions: [Permission.DOCUMENTS_DELETE],
            },
            {
              id: SearchResultsActionItem.delete,
              title: this.translateService.instant('Delete'),
              icon: 'trash',
              permissions: [Permission.DOCUMENTS_DELETE],
            },
          ],
        };
        if (doc.updated_by) {
          this.usersService.getLimitedUser(doc.updated_by).subscribe((user) => {
            docActionItem.updated_by = this.translateService.instant('by {fullName}', {
              fullName: this.fullNameService.formatFullName(user.data.first_name, user.data.last_name),
            });
          });
        } else {
          docActionItem.updated_by = '';
        }
        return docActionItem;
      case ItemType.docs_report:
      case ItemType.docs_resource:
        const resource = <Doc>item;
        const resourceActionItem: ActionItem<Doc> = {
          ...this.getEntityActionItem(
            resource.id,
            resource.name,
            type,
            resource.created,
            resource.updated,
            resource,
            resource.start,
          ),
          icon: this.getFileFormatIcon(resource.format),
          subtitle: resource.org_name,
        };
        if (resource.updated_by) {
          this.usersService.getLimitedUser(resource.updated_by).subscribe((user) => {
            resourceActionItem.updated_by = this.translateService.instant('by {fullName}', {
              fullName: this.fullNameService.formatFullName(user.data.first_name, user.data.last_name),
            });
          });
        } else {
          resourceActionItem.updated_by = '';
        }
        return resourceActionItem;
      case ItemType.library_peers_report:
        const peerDoc = <Doc>item;
        const peerDocActionItem: ActionItem<Doc> = {
          ...this.getEntityActionItem(
            peerDoc.id,
            peerDoc.name,
            type,
            peerDoc.created,
            peerDoc.updated,
            peerDoc,
            peerDoc.start,
          ),
          icon: this.getFileFormatIcon(peerDoc.format),
          subtitle: peerDoc.org_name,
          children: [
            {
              id: SearchResultsActionItem.preview,
              title: this.translateService.instant('Preview'),
              icon: 'show',
            },
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('See details'),
              icon: 'info',
            },
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
              permissions: [Permission.ORG_MANAGE],
            },
            {
              id: SearchResultsActionItem.separator,
              title: '',
            },
            {
              id: SearchResultsActionItem.delete,
              title: this.translateService.instant('Delete'),
              icon: 'trash',
              permissions: [Permission.ORG_MANAGE],
            },
          ],
        };
        if (peerDoc.updated_by) {
          this.usersService.getLimitedUser(peerDoc.updated_by).subscribe((user) => {
            peerDocActionItem.updated_by = this.translateService.instant('by {fullName}', {
              fullName: this.fullNameService.formatFullName(user.data.first_name, user.data.last_name),
            });
          });
        } else {
          peerDocActionItem.updated_by = '';
        }
        return peerDocActionItem;
      case ItemType.library_peers_peer:
        const org = <Org>item;
        return {
          ...this.getEntityActionItem(org.id, org.name, type, org.created, org.updated, org),
          disabled: !org.active,
          children: [
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('View data'),
              icon: 'show',
            },
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
            },
          ],
        };
      case ItemType.metrics_metric:
        const metric = <Metric>item;
        const metricActionItem: ActionItem<Metric> = {
          ...this.getEntityActionItem(metric.id, metric.name, type, metric.created, metric.updated, metric),
          subtitle: metric.code,
          title: metric.description,
          updated: metric.updated,
        };
        return metricActionItem;
      case ItemType.reports_indicators:
      case ItemType.metrics_indicator:
        const indicator = <Indicator>item;
        const indicatorActionItem: ActionItem<Indicator> = {
          ...this.getEntityActionItem(
            indicator.id,
            indicator.name,
            type,
            indicator.created,
            indicator.updated,
            indicator,
            indicator.start,
          ),
          subtitle: indicator.code,
          title: indicator.description,
          updated: indicator.updated,
          children: [
            {
              id: SearchResultsActionItem.preview,
              title: this.translateService.instant('Preview metric'),
              icon: 'show',
              item: indicator,
            },
          ],
        };
        indicatorActionItem.updated_by = '';

        // This is a massive performance issue and should be removed at some point.
        if (indicator.updated_by) {
          this.usersService.getLimitedUser(indicator.updated_by).subscribe((user) => {
            indicatorActionItem.updated_by = this.translateService.instant('by {fullName}', {
              fullName: this.fullNameService.formatFullName(user.data.first_name, user.data.last_name),
            });
          });
        } else {
          indicatorActionItem.updated_by = '';
        }
        return indicatorActionItem;
      case ItemType.metrics_template:
        const metricTemplate: Metric = <Metric>item;
        return {
          ...this.getEntityActionItem(
            metricTemplate.id,
            metricTemplate.name,
            type,
            metricTemplate.created,
            metricTemplate.updated,
            metricTemplate,
          ),
          subtitle: metricTemplate.code,
          title: metricTemplate.description,
        };

      case ItemType.reports_report:
        const report: Report = <Report>item;

        const reportActionItem: ActionItem<Report> = {
          ...this.getEntityActionItem(
            report.id,
            report.name,
            type,
            report.created,
            report.updated,
            report,
            report.start,
          ),
          subtitle: `${this.translateService.instant(ReportsReportCategories[report.category])}`,
        };
        switch (report.status) {
          case 'draft':
            reportActionItem.children = [
              {
                id: SearchResultsActionItem.edit,
                title: this.translateService.instant('Edit'),
                icon: 'edit',
              },
              {
                id: SearchResultsActionItem.details,
                title: this.translateService.instant('See details'),
                icon: 'info',
              },
            ];

            if (copyableReportTypes.includes(report.category)) {
              reportActionItem.children.push({
                id: SearchResultsActionItem.copy,
                title: this.translateService.instant('Make a copy'),
                icon: 'copy',
              });
            }

            reportActionItem.children.push(
              ...[
                {
                  id: SearchResultsActionItem.separator,
                  title: '',
                },
                {
                  id: SearchResultsActionItem.complete,
                  title: this.translateService.instant('Mark as complete'),
                  icon: 'check' as Icon,
                },
                {
                  id: SearchResultsActionItem.separator,
                  title: '',
                },
                {
                  id: SearchResultsActionItem.delete,
                  title: this.translateService.instant('Delete'),
                  icon: 'trash' as Icon,
                },
              ],
            );
            break;
          case 'completed':
            reportActionItem.children = [
              {
                id: SearchResultsActionItem.edit,
                title: this.translateService.instant('Review'),
                icon: 'show',
              },
              {
                id: SearchResultsActionItem.copy,
                title: this.translateService.instant('Save as new draft'),
                icon: 'copy',
                disabled: true,
              },
              {
                id: SearchResultsActionItem.separator,
                title: '',
              },
              {
                id: SearchResultsActionItem.archive,
                title: this.translateService.instant('Archive'),
                icon: 'archive',
              },
              {
                id: SearchResultsActionItem.separator,
                title: '',
              },
              {
                id: SearchResultsActionItem.delete,
                title: this.translateService.instant('Delete'),
                icon: 'trash',
              },
            ];
            break;
          case 'archived':
            reportActionItem.children = [
              {
                id: SearchResultsActionItem.edit,
                title: this.translateService.instant('Review'),
                icon: 'show',
              },
              {
                id: SearchResultsActionItem.separator,
                title: '',
              },
              {
                id: SearchResultsActionItem.unarchive,
                title: this.translateService.instant('Unarchive'),
                icon: 'archive',
              },
              {
                id: SearchResultsActionItem.separator,
                title: '',
              },
              {
                id: SearchResultsActionItem.delete,
                title: this.translateService.instant('Delete'),
                icon: 'trash',
              },
            ];
            break;
          default:
            break;
        }
        switch (report.category) {
          case ReportCategoryType.questionnaire:
            reportActionItem.image = this.generateColorAndPattern(reportActionItem, this.PURPLE, 7);
            break;
          case ReportCategoryType.data_table:
            reportActionItem.image = this.generateColorAndPattern(reportActionItem, this.RED, 6);
            break;
          case ReportCategoryType.detailed:
            reportActionItem.image = this.generateColorAndPattern(reportActionItem, this.BLUE, 7);
            break;
          case ReportCategoryType.template_framework:
            reportActionItem.image = this.generateColorAndPattern(reportActionItem, this.PURPLE, 7);
            break;
          default:
            break;
        }
        if (report.updated_by) {
          this.usersService.getLimitedUser(report.updated_by).subscribe((user) => {
            reportActionItem.updated_by = this.translateService.instant('by {fullName}', {
              fullName: this.fullNameService.formatFullName(user.data.first_name, user.data.last_name),
            });
          });
        } else {
          reportActionItem.updated_by = '';
        }
        return reportActionItem;
      case ItemType.reports_template:
        const reportTemplate = <ReportTemplate>item;
        const reportTemplateActionItem: ActionItem<ReportTemplate> = {
          ...this.getEntityActionItem(
            reportTemplate.id,
            reportTemplate.name,
            type,
            reportTemplate.created,
            reportTemplate.updated,
            reportTemplate,
            reportTemplate.start,
          ),
          children: [
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('See details'),
              icon: 'info',
            },
            {
              id: SearchResultsActionItem.template,
              title: this.translateService.instant('Use template'),
              icon: 'build',
            },
          ],
        };
        switch (reportTemplate.category) {
          case ReportCategoryType.questionnaire:
            reportTemplateActionItem.image = this.generateColorAndPattern(reportTemplateActionItem, this.PURPLE, 2);
            break;
          case ReportCategoryType.data_table:
            reportTemplateActionItem.image = this.generateColorAndPattern(reportTemplateActionItem, this.RED, 2);
            break;
          case ReportCategoryType.detailed:
            reportTemplateActionItem.image = this.generateColorAndPattern(reportTemplateActionItem, this.BLUE, 2);
            break;
          case ReportCategoryType.template_framework:
            reportTemplateActionItem.image = this.generateColorAndPattern(reportTemplateActionItem, this.PURPLE, 2);
            break;
          default:
            break;
        }
        if (reportTemplate.updated_by) {
          this.usersService.getLimitedUser(reportTemplate.updated_by).subscribe((user) => {
            reportTemplateActionItem.updated_by = this.translateService.instant('by {fullName}', {
              fullName: this.fullNameService.formatFullName(user.data.first_name, user.data.last_name),
            });
          });
        } else {
          reportTemplateActionItem.updated_by = '';
        }
        return reportTemplateActionItem;
      case ItemType.benchmarks_benchmark:
        const benchmark: Benchmark = <Benchmark>item;
        const benchmarkActionItem: ActionItem<Benchmark> = {
          ...this.getEntityActionItem(
            benchmark.id,
            benchmark.name,
            type,
            benchmark.created,
            benchmark.updated,
            benchmark,
          ),
          children: [
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
            },
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('See details'),
              icon: 'info',
              disabled: true,
            },
            {
              id: SearchResultsActionItem.separator,
              title: '',
            },
            {
              id: SearchResultsActionItem.delete,
              title: this.translateService.instant('Delete'),
              icon: 'trash',
            },
          ],
        };
        benchmarkActionItem.image = `${this.getPattern(benchmarkActionItem)}:green-${this.getColor(
          benchmarkActionItem,
          6,
        )}`;

        if (benchmark.updated_by) {
          this.usersService.getLimitedUser(benchmark.updated_by).subscribe((user) => {
            benchmarkActionItem.updated_by = this.translateService.instant('by {fullName}', {
              fullName: this.fullNameService.formatFullName(user.data.first_name, user.data.last_name),
            });
          });
        } else {
          benchmarkActionItem.updated_by = '';
        }
        return benchmarkActionItem;
      case ItemType.analyze_dataset:
        const dataset: Dataset = <Dataset>item;
        const metrics = dataset.metrics.length || 0;
        const sources: string =
          dataset.sources == undefined
            ? ''
            : dataset.sources.length == 0
              ? this.translateService.instant(', all sources')
              : this.translateService.instant(`, {length} sources`, { length: dataset.sources.length });
        const period =
          dataset.to_year != dataset.from_year
            ? `${dataset.from_year}-${dataset.to_year}`
            : dataset.from_year.toString();
        const datasetActionItem: ActionItem<Dataset> = {
          ...this.getEntityActionItem(dataset.id, dataset.name, type, dataset.created, dataset.updated, dataset),
          subtitle: `${metrics} metrics${sources}, ${period}`,
          children: [
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('See details'),
              icon: 'info',
            },
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit structure'),
              icon: 'template',
            },
            {
              id: SearchResultsActionItem.copy,
              title: this.translateService.instant('Make a copy'),
              icon: 'copy',
            },
            {
              id: SearchResultsActionItem.separator,
              title: '',
            },
            {
              id: SearchResultsActionItem.delete,
              title: this.translateService.instant('Delete'),
              icon: 'trash',
            },
          ],
        };
        return datasetActionItem;
      case ItemType.selection_set:
        const selectionSet: SelectionSet = <SelectionSet>item;
        return {
          ...this.getEntityActionItem(
            selectionSet.id,
            selectionSet.name,
            type,
            selectionSet.created,
            selectionSet.updated,
            selectionSet,
          ),
          disabled: !selectionSet.active,
          children: [
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
            },
          ],
        };
      case ItemType.selection_set_item:
        const selectionSetItem: SelectionSetItem = <SelectionSetItem>item;
        const selectionSetItemActionItem: ActionItem<SelectionSetItem> = {
          ...this.getEntityActionItem(
            selectionSetItem.id,
            selectionSetItem.name,
            type,
            selectionSetItem.created,
            selectionSetItem.updated,
            selectionSetItem,
          ),
          disabled: !selectionSetItem.active,
          children: [
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
            },
            {
              id: SearchResultsActionItem.separator,
              title: '',
              permissions: [Permission.SELECTION_SETS_MANAGE],
            },
            {
              id: SearchResultsActionItem.moveUp,
              title: this.translateService.instant('Move up'),
              icon: 'arrow-full-up',
              permissions: [Permission.SELECTION_SETS_MANAGE],
            },
            {
              id: SearchResultsActionItem.moveDown,
              title: this.translateService.instant('Move down'),
              icon: 'arrow-full-down',
              permissions: [Permission.SELECTION_SETS_MANAGE],
            },
            {
              id: SearchResultsActionItem.separator,
              title: '',
              permissions: [Permission.SELECTION_SETS_MANAGE],
            },
          ],
        };
        if (selectionSetItem.active) {
          selectionSetItemActionItem.children?.push({
            id: SearchResultsActionItem.delete,
            title: this.translateService.instant('Deactivate'),
            icon: 'empty',
            permissions: [Permission.SELECTION_SETS_MANAGE],
          });
        } else {
          selectionSetItemActionItem.children?.push({
            id: SearchResultsActionItem.deactivate,
            title: this.translateService.instant('Activate'),
            icon: 'check',
            permissions: [Permission.SELECTION_SETS_MANAGE],
          });
        }
        return selectionSetItemActionItem;
      case ItemType.unit_group:
        const unitGroup: UnitGroup = <UnitGroup>item;
        const unitGroupActionItem: ActionItem<UnitGroup> = {
          ...this.getEntityActionItem(
            unitGroup.id,
            unitGroup.name,
            type,
            unitGroup.created,
            unitGroup.updated,
            unitGroup,
          ),
          disabled: !unitGroup.active,
          children: [
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
            },
          ],
        };
        return unitGroupActionItem;
      case ItemType.unit_group_item:
        const unitGroupItem: UnitGroupItem = item as UnitGroupItem;
        const unitGroupItemActionItem: ActionItem<UnitGroupItem> = {
          ...this.getEntityActionItem(
            unitGroupItem.id,
            unitGroupItem.name,
            type,
            unitGroupItem.created,
            unitGroupItem.updated,
            unitGroupItem,
          ),
          disabled: !unitGroupItem.active,
          children: [
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
            },
            {
              id: SearchResultsActionItem.separator,
              title: '',
              permissions: [Permission.UNIT_GROUPS_MANAGE],
            },
            {
              id: SearchResultsActionItem.moveUp,
              title: this.translateService.instant('Move up'),
              icon: 'arrow-full-up',
              permissions: [Permission.UNIT_GROUPS_MANAGE],
            },
            {
              id: SearchResultsActionItem.moveDown,
              title: this.translateService.instant('Move down'),
              icon: 'arrow-full-down',
              permissions: [Permission.UNIT_GROUPS_MANAGE],
            },
            {
              id: SearchResultsActionItem.separator,
              title: '',
              permissions: [Permission.UNIT_GROUPS_MANAGE],
            },
          ],
        };
        if (unitGroupItem.active) {
          unitGroupItemActionItem.children?.push({
            id: SearchResultsActionItem.delete,
            title: this.translateService.instant('Deactivate'),
            icon: 'empty',
            permissions: [Permission.UNIT_GROUPS_MANAGE],
          });
        } else {
          unitGroupItemActionItem.children?.push({
            id: SearchResultsActionItem.activate,
            title: this.translateService.instant('Activate'),
            icon: 'check',
            permissions: [Permission.UNIT_GROUPS_MANAGE],
          });
        }
        return unitGroupItemActionItem;
      case ItemType.tags_tag:
        const tag = <Tag>item;
        return {
          ...this.getEntityActionItem(tag.id, tag.name, type, tag.created, tag.updated, tag),
          children: [
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('See Details'),
              icon: 'info',
            },
            {
              id: SearchResultsActionItem.delete,
              title: this.translateService.instant('Delete'),
              icon: 'trash',
            },
          ],
        };
      case ItemType.peers_peer:
        const peerOrg = <Org>item;
        return {
          ...this.getEntityActionItem(peerOrg.id, peerOrg.name, type, peerOrg.created, peerOrg.updated, peerOrg),
          children: [
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('See Details'),
              icon: 'info',
            },
            {
              id: SearchResultsActionItem.delete,
              title: this.translateService.instant('Delete'),
              icon: 'trash',
            },
          ],
        };
      case ItemType.metrics_framework:
      case ItemType.standard_codes_framework:
        const framework: Framework = <Framework>item;
        const frameworkActionItem: ActionItem<Framework> = {
          ...this.getEntityActionItem(
            framework.id,
            framework.name,
            type,
            framework.created,
            framework.updated,
            framework,
          ),
          subtitle: framework.code,
        };

        frameworkActionItem.image = `${this.getPattern(frameworkActionItem)}:grey-${this.getColor(
          frameworkActionItem,
          2,
        )}`;
        return frameworkActionItem;
      case ItemType.admin_standard_codes:
      case ItemType.standard_codes:
        const standardCodeItem: StandardCodes = <StandardCodes>item;
        return {
          ...this.getEntityActionItem(
            standardCodeItem.id,
            standardCodeItem.description,
            type,
            standardCodeItem.created,
            standardCodeItem.updated,
            standardCodeItem,
            standardCodeItem.start,
          ),
          subtitle: standardCodeItem.code,
          disabled: !standardCodeItem.active,
        };
      case ItemType.framework_metric:
        const metricItem: Metric = <Metric>item;

        const metricItemActionItem: ActionItem<Metric> = {
          ...this.getEntityActionItem(
            metricItem.id,
            metricItem.name,
            type,
            metricItem.created,
            metricItem.updated,
            metricItem,
          ),
          subtitle: metricItem.code,
          title: metricItem.description,
          disabled: !metricItem.active,
          children: [
            {
              id: SearchResultsActionItem.preview,
              title: this.translateService.instant('Preview'),
              icon: 'show',
            },
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
              permissions: [Permission.METRICS_MANAGE],
            },
          ],
        };

        return metricItemActionItem;
      case ItemType.peer_metric:
        const peerMetricItem: Metric = <Metric>item;
        return {
          ...this.getEntityActionItem(
            peerMetricItem.id,
            peerMetricItem.name,
            type,
            peerMetricItem.created,
            peerMetricItem.updated,
            peerMetricItem,
          ),
          subtitle: peerMetricItem.code,
          title: peerMetricItem.description,
          disabled: !peerMetricItem.active,
          children: [
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit values'),
              icon: 'edit',
            },
            {
              id: SearchResultsActionItem.export,
              title: this.translateService.instant('Export values'),
              icon: 'download',
              disabled: true,
            },
          ],
        };
      case ItemType.admin_templates_metrics:
        const templateMetricItem: Metric = <Metric>item;
        return {
          ...this.getEntityActionItem(
            templateMetricItem.id,
            templateMetricItem.name,
            type,
            templateMetricItem.created,
            templateMetricItem.updated,
            templateMetricItem,
          ),
          subtitle: templateMetricItem.code,
          title: templateMetricItem.description,
          disabled: !templateMetricItem.active,
          children: [
            {
              id: SearchResultsActionItem.preview,
              title: this.translateService.instant('Preview'),
              icon: 'show',
            },
          ],
        };
      case ItemType.core_orgs:
        const coreOrg = <Org>item;
        return this.getEntityActionItem(coreOrg.id, coreOrg.name, type, coreOrg.created, coreOrg.updated, coreOrg);
      case ItemType.users_user:
        const user: User = <User>item;
        return {
          ...this.getEntityActionItem(user.id, user.name, type, user.created, user.updated, user),
          title: this.fullNameService.formatFullName(user.first_name, user.last_name),
          children: [
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('See Details'),
              icon: 'show',
            },
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
            },
          ],
          deactivated: !user.active,
        };
      case ItemType.data_requests_request:
        const dataRequest = item as DataRequest;
        const dataRequestActionItem: ActionItem<DataRequest> = {
          ...this.getEntityActionItem(
            dataRequest.id,
            dataRequest.name,
            type,
            dataRequest.created,
            dataRequest.updated,
            dataRequest,
          ),
          subtitle: `FY${dataRequest.frequency_code}`,
          children: [
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('See request'),
              icon: 'edit',
            },
            {
              id: SearchResultsActionItem.details,
              title: this.translateService.instant('See details'),
              icon: 'show',
            },
            {
              id: SearchResultsActionItem.copy,
              title: this.translateService.instant('Make a copy'),
              icon: 'copy',
            },
          ],
        };

        if (dataRequest.status === DataRequestStatus.DRAFT) {
          dataRequestActionItem.id = `../${dataRequest.id}/edit`;
        } else {
          dataRequestActionItem.id = `../${dataRequest.id}/edit/sources`;
        }

        if (
          [DataRequestStatus.DRAFT, DataRequestStatus.ACTIVE, DataRequestStatus.INACTIVE].includes(dataRequest.status)
        ) {
          dataRequestActionItem.children?.push({
            id: SearchResultsActionItem.separator,
            title: '',
          });

          switch (dataRequest.status) {
            case DataRequestStatus.DRAFT:
              dataRequestActionItem.children?.push({
                id: SearchResultsActionItem.delete,
                title: this.translateService.instant('Delete'),
                icon: 'trash' as Icon,
              });
              break;
            case DataRequestStatus.ACTIVE:
              dataRequestActionItem.children?.push({
                id: SearchResultsActionItem.deactivate,
                title: this.translateService.instant('Deactivate'),
                icon: 'empty' as Icon,
              });
              break;
            case DataRequestStatus.INACTIVE:
              dataRequestActionItem.children?.push({
                id: SearchResultsActionItem.activate,
                title: this.translateService.instant('Activate'),
                icon: 'check' as Icon,
              });
              dataRequestActionItem.deactivated = true;
              break;
          }
        }

        if (dataRequest.updated_by) {
          this.usersService.getLimitedUser(dataRequest.updated_by).subscribe((u) => {
            dataRequestActionItem.updated_by = this.translateService.instant('by {fullName}', {
              fullName: this.fullNameService.formatFullName(u.data.first_name, u.data.last_name),
            });
          });
        }
        return dataRequestActionItem;
      case ItemType.sources_source:
        const source = <Source>item;
        return {
          ...this.getEntityActionItem(source.id, source.name, type, source.created, source.updated, source),
          children: [
            {
              id: SearchResultsActionItem.edit,
              title: this.translateService.instant('Edit'),
              icon: 'edit',
            },
          ],
        };

      case ItemType.data_requests_source:
        const dataRequestSource = <DataRequestSource>item;
        return {
          ...this.getEntityActionItem(
            dataRequestSource.id,
            dataRequestSource.name,
            type,
            dataRequestSource.created,
            dataRequestSource.updated,
            dataRequestSource,
          ),
          title: dataRequestSource.business_unit.name,
          children: [
            {
              id: SearchResultsActionItem.delete,
              title: this.translateService.instant('Delete'),
              icon: 'trash',
            },
          ],
        };

      case ItemType.related_metrics:
        const relatedMetric = <Metric>item;
        return {
          ...this.getEntityActionItem(
            relatedMetric.id,
            relatedMetric.code || '',
            type,
            relatedMetric.created,
            relatedMetric.updated,
            relatedMetric,
          ),
        };
      case ItemType.integration_settings:
        const integration = item as IntegrationSetting;
        return {
          ...this.getEntityActionItem(
            integration.type,
            this.translateService.instant(IntegrationsLabel[integration.type]),
            type,
            integration.created,
            integration.updated,
            integration,
          ),
        };
      default:
        throw new Error(`Entity type not supported: ${type ?? ''}`);
    }
  }

  apiResponse2Collection(response: ApiResponse<unknown[]>, type?: string): Collection<SearchActionItemTypes> {
    const collection: Collection<SearchActionItemTypes> = {
      count: response.meta.total_count || 0,
      items: [],
    };
    for (const item of response.data) {
      collection.items.push(this.entity2ActionItem(item, type));
    }
    return collection;
  }

  filterItems<T>(input: ActionItem<T>[], value: string): ActionItem<T>[] {
    const filterValue = value.toLowerCase();
    return input.filter((item) => item.title.toLowerCase().includes(filterValue));
  }

  resource2ActionItem(response: ApiResponse<any[]>, itemType: string, withEmptyOption: boolean = false): ActionItem[] {
    const actionItems: ActionItem[] = [];

    for (const item of response.data) {
      switch (itemType) {
        // handling complex scenarios which require dependencies
        case ResourceType.role:
          const role = item as UserRoles;
          actionItems.push({
            id: role,
            title: this.translateService.instant(UserRoleLabel[role]),
            item: role,
          });
          break;
        case ResourceType.metrics_indicator:
          actionItems.push(this.entity2ActionItem(item, ItemType.metrics_indicator));
          break;
        case ResourceType.year:
          const fiscalYearActionItem: ActionItem<FiscalYear> = ActionItemUtils.resourceToActionItem(
            item,
            itemType as ResourceType,
          ) as ActionItem<FiscalYear>;
          actionItems.push(this.fiscalYearsUtilService.appendFiscalYearStatus(fiscalYearActionItem));
          break;
        default:
          // handling simple static scenarios
          const result = ActionItemUtils.resourceToActionItem(item, itemType as ResourceType);
          if (result instanceof Array) {
            actionItems.push(...result);
          } else {
            actionItems.push(result);
          }
      }
    }

    if (withEmptyOption) {
      actionItems.unshift({ id: '', title: this.translateService.instant('None'), action: 'category' });
    }

    return actionItems;
  }

  search(searchOptions: SearchOptions, cache?: string): Observable<Collection<any>> {
    const cacheKey: string = `${searchOptions.item_type}:${JSON.stringify(searchOptions)}`;
    if (cache !== undefined) {
      const cacheValue: any = this.cacheService.get(cacheKey);
      if (cacheValue) {
        return of(cacheValue);
      }
    }
    switch (this.contextService.platform) {
      case Platforms.CLIENT:
        return this.clientSearchService.search(searchOptions).pipe(
          map((result) => {
            const response = this.apiResponse2Collection(result, searchOptions.item_type);
            if (cache !== undefined) {
              if (cache === '0') {
                cache = '1h';
              } // limit cache to one hour
              this.cacheService.set(cacheKey, response, cache);
            }
            return response;
          }),
        );
      case Platforms.ADMIN:
        return this.adminSearchService.search(searchOptions).pipe(
          map((result) => {
            const response = this.apiResponse2Collection(result, searchOptions.item_type);
            if (cache !== undefined) {
              if (cache === '0') {
                cache = '1h';
              } // limit cache to one hour
              this.cacheService.set(cacheKey, response, cache);
            }
            return response;
          }),
        );
      default:
        throw new Error('Platform not supported. Supported platforms: client, admin');
    }
  }

  searchResources<T = any>(
    resourceType: string,
    itemType?: string,
    withEmptyOption: boolean = false,
    searchArguments: { [key: string]: any } = {},
  ): Observable<ActionItem<T>[]> {
    let cacheKey: string = '';
    let cacheValue: any = {};
    let item: string | undefined = undefined;
    switch (this.contextService.platform) {
      case 'client':
        switch (resourceType) {
          case 'source':
            switch (itemType) {
              case 'metrics_metric':
              case 'metrics_indicator':
              case 'reports_report':
              case 'docs_doc':
              case 'metric_data_exports':
                item = 'unit';
                break;
              case 'docs_report':
              case 'docs_resource':
                item = 'org';
                break;
              default:
                item = 'unit';
                break;
            }
            break;
          case 'category':
            switch (itemType) {
              case 'metrics_metric':
              case 'metrics_indicator':
                item = 'metric';
                break;
              case 'docs_report':
              case 'docs_resource':
              case 'docs_doc':
              case 'metric_data_exports':
                item = 'doc';
                break;
              case 'reports_template':
              case 'reports_report':
                item = 'report';
                break;
              case 'data_requests_request':
                item = itemType;
                break;
            }
            break;
          case 'standard_codes':
          case 'status':
          case 'sort':
          case 'unit':
            item = itemType == 'public_data_requests_request' ? 'public_data_request' : itemType;
            break;
          case 'selection_set':
          case 'selection_set_item':
          case 'selection_set_item_public':
          case 'template':
          case 'type':
          case 'role':
          case 'metric_group':
            item = itemType;
            break;
        }
        cacheKey = `${resourceType}:${item}:${withEmptyOption}:${JSON.stringify(searchArguments)}`;
        cacheValue = this.cacheService.get(cacheKey);
        if (cacheValue) {
          return of(cacheValue);
        } else {
          return this.clientSearchService.searchResources(resourceType, item, searchArguments).pipe(
            map((result) => {
              const response = this.resource2ActionItem(result, resourceType, withEmptyOption);
              this.cacheService.set(cacheKey, response, '0');
              return response;
            }),
          );
        }
      case 'admin':
        item = itemType;
        cacheKey = `${resourceType}:${item}:${withEmptyOption}`;
        cacheValue = this.cacheService.get(cacheKey);
        if (cacheValue) {
          return of(cacheValue);
        } else {
          return this.adminSearchService.searchResources(resourceType, itemType).pipe(
            map((result) => {
              const response = this.resource2ActionItem(result, resourceType);
              this.cacheService.set(cacheKey, response, '0');
              return response;
            }),
          );
        }
      default:
        throw new Error('Platform not supported. Supported platforms: client, admin');
    }
  }
}
