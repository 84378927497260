import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AddFieldRelationsRequest, Metric, MetricValueDefinitionSelection } from '../../../../../models';

type AddRelatedFieldDialogFormModel = {
  items: FormControl<MetricValueDefinitionSelection[]>;
  metric: FormControl<null | Metric>;
};

export class AddRelatedFieldDialogForm extends FormGroup<AddRelatedFieldDialogFormModel> {
  constructor(fb: FormBuilder = new FormBuilder()) {
    super({
      items: fb.control([], { nonNullable: true, validators: [Validators.required] }),
      metric: fb.control(null, { validators: [Validators.required] }),
    });
  }

  public toModel(): AddFieldRelationsRequest {
    return {
      field_relations: this.controls.items.value.map((i) => ({
        to_metric_table_definition_id: i.tableId,
        to_value_definition_id: i.valueDefinitionId,
      })),
    };
  }
}
