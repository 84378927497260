<p-menu #actionMenu [model]="actionMenuItems" [popup]="true" appendTo="body" [autoZIndex]="false" [baseZIndex]="800">
  <ng-template pTemplate="item" let-item>
    <ng-container
      *libFeatureFlag="{ featureFlags: item.featureFlag, hiddenWhenEnabled: item.featureFlagHiddenWhenEnabled }"
    >
      <ng-container *libPermission="item.permissions">
        <ng-container *ngIf="!item.showCondition || (rowData | executeFunctionOnValue: item.showCondition)">
          <p-button
            *ngIf="!item.isSeparator; else menuSeparator"
            class="w-100"
            [text]="true"
            [icon]="'pi pi-' + item.icon"
            [label]="item.label"
            severity="secondary"
            (click)="item.onClick(rowData)"
          >
          </p-button>

          <ng-template #menuSeparator>
            <lib-divider></lib-divider>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</p-menu>

<button
  *ngIf="rowData | doesActionMenuHasItems: actionMenuItems"
  type="button"
  pButton
  pRipple
  class="p-button-text p-button-rounded p-button-plain"
  [icon]="'pi pi-ellipsis-v'"
  (click)="actionMenu.toggle($event)"
  data-testid="moreActions"
></button>
