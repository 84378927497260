import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() dismissible: boolean = false;
  @Input() primaryBtn?: string;
  @Input() primaryBtnDisabled?: boolean = false;
  @Input() secondaryBtn?: string;
  @Input() showCloseIcon: boolean = true;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input({ required: true }) title!: string;
  @Input({ required: true }) visible!: boolean;

  @Output() primaryClick = new EventEmitter<void>();
  @Output() secondaryClick = new EventEmitter<void>();
  @Output() visibleChange = new EventEmitter<boolean>();
}
