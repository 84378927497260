import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-simple-text-card',
  templateUrl: './simple-text-card.component.html',
  styleUrls: ['../cards.component.scss', './simple-text-card.component.scss'],
})
export class SimpleTextCardComponent {
  @Input({ required: true }) cardTitle!: string;
  @Input() text: string = '';
  @Input() actionIcon?: string;
  @Input() numberOfLineToShow: number = 1;

  @Output() actionClick: EventEmitter<void> = new EventEmitter<void>();
}
