import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BtnTypeCssClass, EmptyResults, ViewerActionType } from '../../models';

@Component({
  selector: 'lib-empty-results',
  templateUrl: './empty-results.component.html',
  styleUrls: ['./empty-results.component.scss'],
})
export class EmptyResultsComponent {
  @Input() displayData?: EmptyResults;

  @Output() action: EventEmitter<void> = new EventEmitter<void>();

  viewerActionType: typeof ViewerActionType = ViewerActionType;
  btnClass: typeof BtnTypeCssClass = BtnTypeCssClass;

  public onBtnClick(): void {
    this.action.emit();
  }
}
