import { AfterViewInit, Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { ValueDefinitionSize } from '../../../models';
import { UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[libFormField]',
})
export class FormFieldDirective implements AfterViewInit {
  @Input() control?: UntypedFormControl;
  @Input() size: string = ValueDefinitionSize.large;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() removePadding: boolean = false;
  @Input() dataTestId: string = '';
  @Input() labelPosition: 'top' | 'left' = 'top';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.setLabelPosition();
  }

  private setLabelPosition(): void {
    if (this.labelPosition === 'top') {
      return;
    }

    if (this.el.nativeElement.children.length > 0) {
      const firstChild = this.el.nativeElement.children[0];

      this.renderer.setStyle(firstChild, 'box-sizing', 'border-box');
      this.renderer.setStyle(firstChild, 'padding', '1.05rem');
      this.renderer.setStyle(firstChild, 'background-color', '#F3F4F6');
      this.renderer.setStyle(firstChild, 'display', 'flex');
      this.renderer.setStyle(firstChild, 'align-items', 'center');
      this.renderer.setStyle(firstChild, 'white-space', 'nowrap');
      this.renderer.setStyle(firstChild, 'height', '3.5rem');
      this.renderer.setStyle(firstChild, 'border-top-left-radius', '0.3rem');
      this.renderer.setStyle(firstChild, 'border-bottom-left-radius', '0.3rem');
      this.renderer.setStyle(firstChild, 'border', '1px solid #D1D5DB');
      this.renderer.setStyle(firstChild, 'margin-right', '-0.5px');
      this.renderer.setStyle(firstChild, 'z-index', '3');
    }
  }

  @HostBinding('class') get classes(): string {
    return `formfield ${this.size}`;
  }

  @HostBinding('class.required') get isRequired(): boolean {
    return this.required;
  }

  @HostBinding('class.disabled') get isDisabled(): boolean {
    return this.control?.disabled || this.readonly;
  }

  @HostBinding('class.no-padding') get hasNoPadding(): boolean {
    return this.removePadding;
  }

  @HostBinding('attr.data-testid') get getDataTestId(): string {
    return this.dataTestId;
  }

  @HostBinding('style.flex-direction') get flexDirection(): string {
    return this.labelPosition === 'left' ? 'row' : 'column';
  }
}
